import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { QuestionSquareFill } from 'react-bootstrap-icons';

const Help = ({ placement, text }) => (
  <OverlayTrigger
    placement={placement}
    delay={{ show: 250, hide: 400 }}
    overlay={<Tooltip>{text}</Tooltip>}
  >
    <QuestionSquareFill size={14} className="text-secondary ml-2" />
  </OverlayTrigger>
);

Help.defaultProps = {
  placement: 'top',
  text: 'Help',
};

Help.propTypes = {
  placement: PropTypes.string,
  text: PropTypes.string,
};

export default Help;
