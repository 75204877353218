import React from 'react';
import randomColor from 'randomcolor';
import PropTypes from 'prop-types';

import Line from './handlers/line';
import Point from './handlers/point';
import Circle from './handlers/circle';
import Polygon from './handlers/polygon';
import Rectangle from './handlers/rectangle';
import canvasHandler from './handlers/canvasHandler';
import { scaleShape, distance, isPointInPath, isPointNearLine, orthoProject, isPointInShape, isPointEqual, ios } from '../utils';
import { segmentPropType, obstaclePropType, treePropType } from '../props';

const tools = { Line, Point, Circle, Polygon, Rectangle };

const linesStyle = {
  Eave: '#39D86F',
  Ridge: '#F30A0A',
  Rake: '#57519C',
  Hip: '#07FFF0',
  Valley: '#D825BB',
};

const colorPalette = randomColor({
  hue: 'random',
  alpha: 0.5,
  format: 'rgba',
  luminosity: 'random',
  count: 50,
});

/**
 * @componentName DrawCanvas
 * @description: This is a component to draw shapes on HTML5 canvas
 */

class DrawCanvas extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // undoData: [],
      // redoData: [],
      data: [],
      // canvasData: [],
      toolId: canvasHandler.uuid(),
      // mode: "draw",
      // isTouchEnabled: false,
      isEditing: false,
      editingSegment: '',
      editingNode: -1,
      focusedSegment: null,
      focusedLine: null,
    };
  }

  componentDidMount() {
    const { initialData, tool, size, scale } = this.props;
    this.ctx = this.canvas.getContext('2d');
    if (tool) {
      this.tool = tools[tool];
      this.tool.ctx = this.ctx;
      // this.setState({ mode: this.props.mode });
      this.tool.resetState();
    }
    // this.canvas.style.cursor = "crosshair";
    this.canvas.height = size;
    this.canvas.width = size;

    // draw on canvas if initialData available
    if (initialData) {
      const data = initialData.map((shape) => scaleShape(shape, scale));
      this.loadDraw(data, true);
      this.initDataOrder(data);
    }
    console.log('canvas mounted');
  }

  componentDidUpdate(prevProps) {
    const { initialData, tool, mode, scale, size, polyEdit } = this.props;
    if (prevProps.tool !== tool) {
      if (tool) {
        this.tool = tools[tool];
        this.tool.ctx = this.ctx;
        this.tool.resetState();
      }
    }
    if (prevProps.initialData !== initialData
      || ([1, 2].includes(prevProps.polyEdit) && polyEdit === -1)) {
      const data = initialData.map((shape) => scaleShape(shape, scale));
      this.loadDraw(data, true);
      this.initDataOrder(data);
    }
    if (prevProps.mode !== mode) {
      let cursor = 'default';
      if (mode) {
        cursor = mode === 'draw' ? 'crosshair' : 'pointer';
      }
      this.canvas.style.cursor = cursor;
      this.tool.resetState();
    }
    if (prevProps.scale !== scale) {
      this.updateScale(scale, size);
    }
  }

  updateScale = (scale, size) => {
    const { initialData } = this.props;
    // handle canvas and data rescaling
    this.ctx.save();
    this.ctx.scale(scale, scale);
    this.canvas.height = size;
    this.canvas.width = size;

    const data = initialData.map((shape) => scaleShape(shape, scale));
    this.loadDraw(data, true);
    // this.setState({ data });
    // this.drawShapes(data);
    console.log('scale: ', scale);
  }

  getNeighbours = (data, p) => {
    // const { moveMode } = this.props;
    const neighbours = [];

    this.editingNodes.forEach((n) => {
      const [pi, vi] = n.split('-').map(Number);
      const segment = data[pi];
      const [x, y] = segment.geometry[vi];
      if (isPointEqual([x, y], p)) {
        const vPrevious = (vi + segment.geometry.length - 1) % segment.geometry.length;
        const vNext = (vi + 1) % segment.geometry.length;
        neighbours.push(segment.geometry[vPrevious]);
        neighbours.push(segment.geometry[vNext]);
      }
    });

    const result = neighbours.map(([x, y]) => `${x}-${y}`).filter((v, i, a) => a.indexOf(v) === i).map((pnt) => {
      const sp = pnt.split('-');
      return [Number(sp[0]), Number(sp[1])];
    });

    return result;
  }

  initDataOrder = (data) => {
    let newOrder = [];
    if (data) {
      const heights = data.map((s) => Number(s.height));
      newOrder = data.map((_, i) => i);
      newOrder.sort((a, b) => (heights[a] > heights[b] ? 1 : -1));
    }
    this.setState({ order: newOrder });
    setTimeout(() => this.drawShapes(data, newOrder), 0);
  }

  bringToFront = (p) => {
    const { data, order } = this.state;
    const newOrder = order ? data.map((_, j) => (order[j] >= 0 ? order[j] : j)) : data.map((_, j) => j);
    data.some((item, i) => {
      if (isPointInShape(p, item.geometry)) {
        const index = newOrder.indexOf(i);
        if (index !== -1) newOrder.splice(index, 1);
        newOrder.push(i);
        this.focusedPolygonToEdit = i;
        this.setState({ order: newOrder });
        return true;
      }
      return false;
    });
  }

  onMouseDown = (e) => {
    const { x, y } = this.getCursorPosition(e);
    const data = Array.from(this.state.data);
    const { mode, polyEdit, moveMode } = this.props;
    const { order } = this.state;
    if (e.button !== 0 && mode !== 'edit') return;
    if (mode === 'edit') {
      const addPointByRightClick = e.button === 2;
      if (polyEdit === -1 && !addPointByRightClick) {
        this.editingNodes = [];
        let editingSegment;
        let editingNode;
        let isEditing = false;
        data.forEach((_, idx) => {
          const i = !order ? idx : order[idx];
          const item = data[i];
          if (item.shape === 'Circle') {
            const center = item.geometry[0];
            const radius = Math.round(distance(item.geometry[0], item.geometry[1]));
            if (Math.round(distance([x, y], center)) - radius <= 5) {
              editingSegment = i;
              editingNode = 1;
              isEditing = true;
            }
          } else {
            item.geometry.forEach((p, j) => {
              // if (x === p[0] && y === p[1]) {
              // do need to round ? w/ rounding makes dist <= 2
              if (Math.round(distance(p, [x, y])) < 2.5) {
                if (moveMode !== 'single') this.editingNodes.push(`${i}-${j}`);
                editingSegment = i;
                editingNode = j;
                isEditing = true;
              }
            });
          }
        });
        if (isEditing) {
          this.setState({ editingSegment, editingNode, isEditing });
          if (moveMode === 'single') this.editingNodes.push(`${editingSegment}-${editingNode}`);
          this.editingNeighbours = this.getNeighbours(data, [x, y]);
        } else {
          this.bringToFront([x, y]);
        }
      } else if (polyEdit === 1 || addPointByRightClick) {
        // add polygon point
        const { focusedPolygonToEdit, addPointToPolygon } = this.props;
        const focusedSegment = addPointByRightClick ? this.focusedPolygonToEdit : focusedPolygonToEdit;
        const focusedLine = data[focusedSegment] ? isPointInPath([x, y], data[focusedSegment].geometry) : -1;
        if (focusedLine === -1) return;
        addPointToPolygon(focusedSegment, focusedLine, [x, y]);
      } else if (polyEdit === 2) {
        // remove point from polygon
        const { focusedPolygonToEdit: focusedSegment, removePointFromPolygon } = this.props;
        data[focusedSegment].geometry.some((p, j) => {
          if (distance([x, y], p) < 2) {
            removePointFromPolygon(focusedSegment, j);
            return true;
          }
          return false;
        });
      }
    } else if (mode === 'label') {
      const { focusedSegment, focusedLine } = this.state;
      const { onLineLabel } = this.props;
      if (focusedSegment >= 0 && focusedLine >= 0) {
        const segment = data[focusedSegment];
        const { linesLabel: label } = this.props;

        // set selected line's label
        if (!segment.lines) segment.lines = Array(segment.geometry.length);
        segment.lines[focusedLine] = label;

        data[focusedSegment] = segment;
        this.setState({ data });
        onLineLabel(focusedSegment, focusedLine, segment.lines);
      } else {
        this.bringToFront([x, y]);
      }
    } else if (mode === 'draw' && this.tool) {
      const { lineWidth, lineColor, shadowColor, tool, onFinishDraw } = this.props;
      const { toolId } = this.state;

      // add an entry for new data, and set id to currentKey
      this.createNewToolInitialData(tool);
      this.setState({ currentKey: toolId });

      this.tool.onMouseDown(
        this.getCursorPosition(e),
        { lineWidth, lineColor, shadowColor, tool },
        () => {
          this.setState({
            toolId: canvasHandler.uuid(),
            currentKey: null,
          });
          onFinishDraw(data[data.length - 1]);
          this.loadDraw(data[data.length - 1]);
        },
      );
    } else if (mode === 'delete') {
      const { deleteShapeHandler } = this.props;
      data.forEach((item, i) => {
        if (item.shape === 'Circle') {
          const center = item.geometry[0];
          const radius = Math.round(distance(item.geometry[0], item.geometry[1]));
          if (Math.round(distance([x, y], center)) - radius <= 5) {
            deleteShapeHandler(item.id);
          }
        } else if (item.shape === 'Point') {
          const p = item.geometry[0];
          if (distance([x, y], p) < 1) {
            deleteShapeHandler(item.id);
          }
        } else if (isPointInShape([x, y], item.geometry)) {
          deleteShapeHandler(item.id);
        }
      });
    }
  }

  onTouchStart = (e) => {
    e.preventDefault();
    const { x, y } = this.getTouchPosition(e);
    if (this.props.mode === 'edit') {
      if (this.props.polyEdit === -1) {
        this.state.data.forEach((item, i) => {
          if (item.shape === 'Circle') {
            const center = item.geometry[0];
            const radius = Math.round(distance(item.geometry[0], item.geometry[1]));
            if (Math.round(distance([x, y], center)) - radius <= 5) {
              this.setState({
                editingSegment: i,
                editingNode: 1,
                isEditing: true,
              });
            }
          } else {
            item.geometry.forEach((p, j) => {
              if (x === p[0] && y === p[1]) {
                this.setState({
                  editingSegment: i,
                  editingNode: j,
                  isEditing: true,
                });
              }
            });
          }
        });
      }
      else if (this.props.polyEdit === 1) {
        // add polygon point

        let focusedLine;
        let focusedSegment;
        const lineIndex = isPointInPath([x, y], this.state.data[this.props.focusedPolygonToEdit].geometry);
        if (lineIndex !== -1) {
          [focusedLine, focusedSegment] = [lineIndex, this.props.focusedPolygonToEdit];
        }
        console.log(focusedLine, focusedSegment, "drawCanvas");
        if (focusedSegment === this.props.focusedPolygonToEdit && focusedLine >= 0) {
          let pointToAdd = [x, y];
          this.props.addPointToPolygon(focusedSegment, focusedLine, pointToAdd);
        }

      } else if (this.props.polyEdit === 2) {
        // TODO ()
        // implement remove polygon from point

        this.state.data[this.props.focusedPolygonToEdit].geometry.forEach((p, j) => {
          let aTmp = x - p[0];
          let bTmp = y - p[1];

          let eucDist = Math.sqrt(aTmp * aTmp + bTmp * bTmp)
          if (eucDist < 2) {
            let pointToRemove = [x, y];
            let pointIdxInGeometry = j;
            this.props.removePointFromPolygon(this.props.focusedPolygonToEdit, pointIdxInGeometry);
          }
        });
      }
    } else if (this.props.mode === 'label') {
      const { focusedSegment, focusedLine } = this.state;
      if (focusedSegment >= 0 && focusedLine >= 0) {
        const data = Array.from(this.state.data);
        const segment = data[focusedSegment];
        const label = this.props.linesLabel;
        if (!segment.lines) segment.lines = Array(segment.geometry.length);

        // Make "Eave" a unique label for any given roof
        const reset_idx = label === 'Eave' ? segment.lines.indexOf(label) : -1;

        // set selected line's label
        segment.lines[focusedLine] = segment.lines[focusedLine]
          ? null
          : this.props.linesLabel;

        // reset duplicated index
        if (reset_idx !== -1) segment.lines[reset_idx] = null;

        data[focusedSegment] = segment;
        this.setState({ data });
        this.props.onLineLabel(focusedSegment, focusedLine, segment.lines);
      }
    } else if (this.props.mode === 'draw' && this.tool) {
      const { lineWidth, lineColor, shadowColor, tool } = this.props;

      // add an entry for new data, and set id to currentKey
      this.createNewToolInitialData(tool);
      this.setState({ currentKey: this.state.toolId });

      this.tool.onTouchStart(
        this.getTouchPosition(e),
        { lineWidth, lineColor, shadowColor, tool },
        () => {
          this.setState({
            toolId: canvasHandler.uuid(),
            currentKey: null,
          });
          const data = Array.from(this.state.data);
          this.props.onFinishDraw(data[data.length - 1]);
          this.loadDraw(data[data.length - 1]);
        },
      );
    }
  }

  onMouseMove = (e) => {
    const {
      data, isEditing, editingSegment, editingNode, order,
    } = this.state;
    const {
      tool, mode, polyEdit, moveMode, focusedPolygonToEdit,
    } = this.props;

    this.drawShapes(data);

    const circleTool = tool === 'Circle';

    if (mode === 'edit' && isEditing) {
      if (moveMode === 'single') {
        // single moveMode
        // this.tool.onMouseMove(this.getCursorPosition(e));
        const { x, y } = this.getCursorPosition(e, !circleTool);
        let newData = data[editingSegment];
        if (!circleTool || distance(newData.geometry[0], [x, y]) > 5) {
          newData = {
            ...newData,
            geometry: newData.geometry.map((p, j) => (j === editingNode ? [x, y] : p)),
          };
        }
        this.loadDraw(newData);
      } else {
        // group moveMode
        const { x, y } = this.getCursorPosition(e, !circleTool);
        const newData = data;
        const currentPos = data[editingSegment].geometry[editingNode];
        // console.log(currentPos);
        newData.forEach((segment, i) => {
          if (i === editingSegment) {
            if (!circleTool || distance(segment.geometry[0], [x, y]) > 5) segment.geometry[editingNode] = [x, y];
            segment.geometry.forEach((p, j) => {
              if (j !== editingNode && p[0] === currentPos[0] && p[1] === currentPos[1]) {
                segment.geometry[j] = [x, y];
              }
            });
          } else {
            segment.geometry.forEach((p, j) => {
              if (p[0] === currentPos[0] && p[1] === currentPos[1]) {
                segment.geometry[j] = [x, y];
              }
            });
          }
        });
        this.loadDraw(newData, true);
      }
    } else if (mode === 'edit' && polyEdit === 1) {
      // Check if Mouse is hovering sth
      // Change hovered segment/line color when editing polygons
      const { x, y } = this.getCursorPosition(e);
      let focusedLine;

      const lineIndex = isPointInPath([x, y], data[focusedPolygonToEdit].geometry);
      if (lineIndex !== -1) {
        focusedLine = lineIndex;
      }

      // update state, to be used in drawShapes()
      this.setState({ focusedSegment: focusedPolygonToEdit, focusedLine });
    } else if (mode === 'label') {
      // Check if Mouse is hovering sth
      const { x, y } = this.getCursorPosition(e);
      let focusedLine;
      let focusedSegment;
      const usingOrder = order && data.length === order.length;
      const reverseOrder = usingOrder ? order.map((_, k) => order[order.length - k - 1]) : order;
      const sortedData = usingOrder ? reverseOrder.map((k) => data[k]) : data;
      sortedData.some((item, idx) => {
        const index = usingOrder ? reverseOrder[idx] : idx;
        // if (isPointInShape([x, y], item.geometry))
        // focusedSegment = index;
        const lineIndex = isPointInPath([x, y], item.geometry);
        if (lineIndex !== -1) {
          [focusedLine, focusedSegment] = [lineIndex, index];
          return true;
        }
        return false;
      });
      // update state, to be used in drawShapes()
      this.setState({ focusedSegment, focusedLine });
    } else if (mode === 'draw' && this.tool) {
      this.tool.onMouseMove(this.getCursorPosition(e));
    }
  }

  onTouchMove = (e) => {
    e.preventDefault();
    this.drawShapes(this.state.data);
    if (this.props.mode === 'edit' && this.state.isEditing) {
      if (this.props.moveMode == 'single') {
        // single moveMode
        const { x, y } = this.getTouchPosition(e);
        let newData = this.state.data[this.state.editingSegment];
        newData = {
          ...newData,
          geometry: newData.geometry.map((p, j) => {
            return j === this.state.editingNode ? [x, y] : p;
          }),
        };
        this.loadDraw(newData);
      } else {
        // group moveMode
        const { x, y } = this.getTouchPosition(e);
        let newData = this.state.data;
        const currentPos = this.state.data[this.state.editingSegment].geometry[this.state.editingNode];
        // console.log(currentPos);
        newData.map((segment, i) => {
          if (i === this.state.editingSegment) {
            segment.geometry[this.state.editingNode] = [x, y];
            segment.geometry.map((p, j) => {
              if (j !== this.state.editingNode && p[0] === currentPos[0] && p[1] === currentPos[1]) {
                segment.geometry[j] = [x, y];
              }
            });
          } else {
            segment.geometry.map((p, j) => {
              if (p[0] === currentPos[0] && p[1] === currentPos[1]) {
                segment.geometry[j] = [x, y];
              }
            });
          }
        });
        this.loadDraw(newData, true);
      }
    } else if (this.props.mode === 'edit' && this.props.polyEdit === 1) {
      // Check if Mouse is hovering sth
      // Change hovered segment/line color when editing polygons
      const { x, y } = this.getTouchPosition(e);
      let focusedLine;

      const lineIndex = isPointInPath([x, y], this.state.data[this.props.focusedPolygonToEdit].geometry);
      if (lineIndex !== -1) {
        focusedLine = lineIndex;
      }

      // update state, to be used in drawShapes()
      this.setState({ focusedSegment: this.props.focusedPolygonToEdit, focusedLine: focusedLine });

    } else if (this.props.mode === 'label') {
      // Check if Mouse is hovering sth
      const { x, y } = this.getTouchPosition(e);
      let focusedLine, focusedSegment;
      this.state.data.forEach((item, index) => {
        //if (isPointInShape([x, y], item.geometry))
        //focusedSegment = index;
        const lineIndex = isPointInPath([x, y], item.geometry);
        if (lineIndex !== -1) {
          [focusedLine, focusedSegment] = [lineIndex, index];
          return;
        }
      });
      // update state, to be used in drawShapes()
      this.setState({ focusedSegment, focusedLine });
    } else if (this.props.mode === 'draw' && this.tool) {
      this.tool.onTouchMove(this.getTouchPosition(e));
    }
  }

  onMouseUp = (e) => {
    this.editingNeighbours = null;
    this.editingNodes = [];
    const { mode, moveMode, polyEdit, onFinishEdit } = this.props;
    const { isEditing, data, } = this.state;
    if (mode === 'edit' && isEditing) {
      const { editingSegment } = this.state;
      if (moveMode === 'single') {
        this.setState({
          isEditing: false,
          editingNode: -1,
          editingSegment: '',
        });
        onFinishEdit(data[editingSegment]);
      } else {
        // Group moveMode
        this.setState({
          isEditing: false,
          editingNode: -1,
          editingSegment: '',
        });
        data.forEach((item) => {
          onFinishEdit(item);
        });
      }
    } else if (mode === 'edit' && polyEdit === -1) {
      this.setState({
        focusedSegment: -1,
        focusedLine: -1,
      });
      this.drawShapes(data);
    } else if (mode === 'draw' && this.tool) {
      const newData = this.tool.onMouseUp(this.getCursorPosition(e), () => {
        this.setState({
          toolId: canvasHandler.uuid(),
          currentKey: null,
        });
      });
      this.updateData(newData);
    }
  }

  onTouchEnd = (e) => {
    e.preventDefault();
    if (this.props.mode === 'edit' && this.state.isEditing) {
      if (this.props.moveMode === 'single') {
        const editIndex = this.state.editingSegment;
        this.setState({
          isEditing: false,
          editingNode: -1,
          editingSegment: '',
        });
        const data = Array.from(this.state.data);
        this.props.onFinishEdit(data[editIndex]);
      } else {
        const editIndex = this.state.editingSegment;
        this.setState({
          isEditing: false,
          editingNode: -1,
          editingSegment: '',
        });
        const data = Array.from(this.state.data);
        data.forEach((_, j) => {
          this.props.onFinishEdit(data[j]);
        });
      }
    } else if (this.props.mode === 'edit' && this.props.polyEdit === -1) {
      this.setState({
        focusedSegment: -1,
        focusedLine: -1,
      });
      this.drawShapes(this.state.data);
    } else if (this.props.mode === 'draw' && this.tool) {
      const newData = this.tool.onTouchEnd(this.getTouchPosition(e), () => {
        this.setState({
          toolId: canvasHandler.uuid(),
          currentKey: null,
        });
      });
      this.updateData(newData);
    }
  }

  onKeyDown = (e) => {
    // cancel current drawing
    if (e.key === 'Escape') this.removeShape(this.state.currentKey);
  }

  getCursorPosition = (e, magnetize = true) => {
    const { data, editingSegment, editingNode } = this.state;
    // top and left of canvas
    const { top, left } = this.canvas.getBoundingClientRect();
    // clientY and clientX coordinate inside the element that the event occur.
    let x = Math.round(e.clientX - left);
    let y = Math.round(e.clientY - top);
    if (!data || !magnetize) return { x, y };

    // magnetic action for points and lines
    const threshold = 9;
    const lineThreshold = 0.45;

    const points = [];
    const pointToPoint = []; // to keep index of the ending points of lines
    data.forEach((item, segIdx) => {
      item.geometry.forEach((p, idx) => {
        if (this.editingNodes && this.editingNodes.includes(`${segIdx}-${idx}`)) points.push([-threshold, -threshold]);
        else points.push(p);
        if (idx === item.geometry.length - 1) {
          // pointToPoint.push(points.indexOf(item.geometry[0])); // less optimized
          pointToPoint.push(points.length - item.geometry.length);
        } else {
          pointToPoint.push(points.length);
        }
      });
    });

    // const points = this.state.data.map(({ geometry }) => geometry).flat();
    points.every((p) => {
      if (distance(p, [x, y]) < threshold) {
        [x, y] = p;
        return false;
      }
      return true;
    });
    points
      .every((p, idx) => {
        // if (this.editingNeighbours) {
        //   const p2 = points[pointToPoint[idx]];
        //   for (let i = 0; i < this.editingNeighbours.length; i += 1) {
        //     const n = this.editingNeighbours[i];
        //     if ((p[0] === n[0] && p[1] === n[1] && distance([x, y], p2) < threshold)
        //     || (p2[0] === n[0] && p2[1] === n[1] && distance([x, y], p) < threshold)) return true;
        //   }
        // }
        const q = points[pointToPoint[idx]];
        if (p[0] >= 0 && q[0] >= 0 && isPointNearLine([x, y], p, q, lineThreshold)) {
          [x, y] = orthoProject(p, q, [x, y]);
          return false;
        }
        return true;
      });
    return { x, y };
  };

  getTouchPosition = (e) => {
    // top and left of canvas
    const { top, left } = this.canvas.getBoundingClientRect();

    // clientY and clientX coordinate inside the element that the event occur.
    let x = Math.round(e.changedTouches[0].clientX - left);
    let y = Math.round(e.changedTouches[0].clientY - top);

    if (!this.state.data) return { x, y };

    const points = [];
    this.state.data.forEach((item) => {
      item.geometry.forEach((p) => {
        points.push(p);
      });
    });

    // magnetic action for points
    const threshold = 10;
    points.forEach((p) => {
      if (distance(p, [x, y]) < threshold) [x, y] = p;
    });
    return { x, y };
  }

  createNewToolInitialData = (tool) => {
    const id = this.state.toolId;
    if (this.state.data.filter((shape) => shape.id === id).length > 0) return;

    // creates new data entry if no match was found
    this.setState({
      data: [...this.state.data, { id, shape: tool, geometry: [] }],
    });
  }

  updateData = (dataFromTool) => {
    // TODO: Refactor, this code to a DRY version
    if (dataFromTool) {
      const { data } = this.state;
      const lastKey = data.length - 1;
      const currentData = data[lastKey];

      const dataToUpdate = currentData.shape === 'Circle'
        ? dataFromTool.data : [...currentData.geometry, dataFromTool.data];

      data[lastKey].geometry = dataToUpdate;
      this.setState({ data }, () => {
      });
      // Create new label in Annotator for Point or Circle
      if (this.tool !== Polygon) {
        const shape = this.state.data.slice(-1).pop();
        this.props.onFinishDraw(shape);
      }
    }
  }

  cleanCanvas = () => {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.setState({ data: [] });
  }

  drawShapes = (pathData, pOrder = null) => {
    if (!this.canvas) return;
    const { focusedSegment, focusedLine, order } = this.state;
    const usingOrder = order && pathData.length === order.length;
    const nOrder = pOrder || order;
    const sortedPathData = usingOrder ? nOrder.map((k) => pathData[k]) : pathData;
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    sortedPathData.forEach(({ shape, geometry }, i) => {
      const idx = usingOrder ? nOrder[i] : i;
      // shape fill
      const path = new Path2D();
      this.ctx.beginPath();
      if (geometry === undefined) return;
      if (!Array.isArray(geometry[0])) return; // to prevent exceptional conditions
      const startPoint = geometry[0];
      if (shape === 'Circle' && geometry.length === 2) {
        const radius = distance(startPoint, geometry[1]);
        path.arc(startPoint[0], startPoint[1], radius, 0, 2 * Math.PI);
      } else {
        path.moveTo(startPoint[0], startPoint[1]);
        geometry.forEach((_, jdx) => {
          const position = jdx !== geometry.length - 1 ? geometry[jdx + 1] : geometry[0];
          path.lineTo(position[0], position[1]);
        });
      }
      this.ctx.closePath();
      // TODO: use 'transparent' for edit mode
      const segStyle = this.props.mode === 'edit' ? 'transparent' : colorPalette[idx];
      this.ctx.fillStyle = focusedSegment === idx ? this.props.focusedSegmentStyle : segStyle;
      this.ctx.fill(path);

      // shape labels
      let center = [0, 0];
      geometry.forEach((p) => { center = [center[0] + p[0], center[1] + p[1]]; });
      center = [center[0] / geometry.length, center[1] / geometry.length];
      this.ctx.strokeStyle = '#FFFFFF';
      this.ctx.lineWidth = 1;
      this.ctx.font = '14px sans-serif';
      switch (geometry.length) {
        case 1: // Point
          this.ctx.strokeText(idx + 1, center[0] - 3, center[1] - 6);
          break;
        case 2: // Circle
          this.ctx.strokeText(idx + 1, geometry[0][0], geometry[0][1]);
          break;
        case 3: // Triangle
          this.ctx.strokeText(idx + 1, center[0], center[1] + 6);
          break;
        default:
          this.ctx.strokeText(idx + 1, center[0], center[1]);
          break;
      }
    });
    sortedPathData.forEach(({ shape, geometry, lines, dsm }, i) => {
      const idx = !nOrder ? i : nOrder[i];
      lines = lines || [];

      if (!geometry) return;
      // shape strokes
      if (shape === 'Circle' && geometry.length === 2) {
        const center = geometry[0];
        const position = geometry[1];
        const radius = distance(center, position);
        // draw circle shadow
        this.ctx.beginPath();
        this.ctx.lineCap = 'round';
        this.ctx.strokeStyle = dsm === 'Keep' ? this.props.shadowColor : '#EFEFEF';
        this.ctx.lineWidth = dsm === 'Keep' ? this.props.lineWidth + 1 : this.props.lineWidth + 5;
        this.ctx.arc(center[0], center[1], radius, 0, 2 * Math.PI);
        this.ctx.stroke();

        // draw circle
        this.ctx.beginPath();
        this.ctx.lineCap = 'round';
        this.ctx.strokeStyle = dsm === 'Keep' ? this.props.lineColor : '#880000';
        this.ctx.lineWidth = dsm === 'Keep' ? this.props.lineWidth : 4;
        this.ctx.arc(center[0], center[1], radius, 0, 2 * Math.PI);
        this.ctx.stroke();
      } else {
        const path = geometry;
        path.forEach((_, jdx) => {
          const start = path[jdx];
          const position = jdx !== path.length - 1 ? path[jdx + 1] : path[0];
          // draw lines
          this.ctx.beginPath();
          this.ctx.lineCap = 'round';
          this.ctx.lineWidth = this.props.lineWidth;
          this.ctx.strokeStyle = this.props.lineColor;
          if (
            shape === 'Polygon' &&
            focusedSegment === idx &&
            focusedLine === jdx
          ) {
            this.ctx.strokeStyle = this.props.focusedLineStyle;
            this.ctx.lineWidth += 2;
          } else if (shape === 'Polygon' && lines[jdx]) {
            this.ctx.strokeStyle = linesStyle[lines[jdx]];
            this.ctx.lineWidth += 2;
          } else this.ctx.strokeStyle = this.props.lineColor;
          this.ctx.moveTo(start[0], start[1]);
          this.ctx.lineTo(position[0], position[1]);
          this.ctx.stroke();
        });

        // draw points after lines are drawn so that point do not get overlapped by lines
        path.forEach((_, jdx) => {
          const start = path[jdx];
          const position = jdx !== path.length - 1 ? path[jdx + 1] : path[0];
          // draw dots shadows
          this.ctx.beginPath();
          this.ctx.lineCap = 'round';
          this.ctx.strokeStyle = '#FFFFFF';
          this.ctx.arc(
            start[0],
            start[1],
            this.props.pointLineWidth + 1,
            0,
            2 * Math.PI,
          );
          this.ctx.stroke();

          // draw dots
          this.ctx.beginPath();
          this.ctx.lineCap = 'round';
          this.ctx.strokeStyle = '#000000';
          this.ctx.arc(
            start[0],
            start[1],
            this.props.pointLineWidth,
            0,
            2 * Math.PI,
          );
          this.ctx.stroke();
        });
      }
    });
  }

  // TODO: refactor this function to canvas handle
  loadDraw = (data, byPassReset = false) => {
    // clean the canvas
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    if (!byPassReset) {
      const currentData = this.state.data;
      const index = currentData.map(({ id }) => id).indexOf(data.id);
      currentData[index] = data;
      data = currentData;
    }
    this.drawShapes(data);

    // update state
    if (this.props.mode === 'draw' && this.tool) this.tool.resetState();
    this.setState({ data });
  }

  removeShape = (key) => {
    if (!key) return;
    const newData = this.state.data.filter(({ id }) => id !== key); // history.cancel moved here
    this.setState({ data: newData, currentKey: null }, () => {
      this.loadDraw(newData, true); // re-draw the entire canvas
    });
  }

  render() {
    const { size, imgSrc } = this.props;

    const isMobile = ios();
    const handlers = isMobile ? {
      onPointerDown: this.onMouseDown,
      onPointerMove: this.onMouseMove,
      onPointerUp: this.onMouseUp,
    } : {
      onMouseDown: this.onMouseDown,
      onMouseMove: this.onMouseMove,
      onMouseUp: this.onMouseUp,
    };

    return (
      <div className="position-relative" style={{ display: (this.props.visible ? 'flex' : 'none') }}>
        <canvas
          tabIndex={0}
          style={{
            backgroundImage: `url(${imgSrc})`,
            backgroundSize: '100% 100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            userSelect: 'none',
          }}
          ref={(canvas) => { this.canvas = canvas; }}
          className="translate-middle"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...handlers}
          onKeyDown={this.onKeyDown}
          onContextMenu={(e) => { e.preventDefault(); }}
        />
      </div>
    );
  }
}

DrawCanvas.defaultProps = {
  initialData: [],
  mode: undefined,
  size: 1024,
  // brushSize: 2,
  // color: '#000000',
  // canUndo: true,
  tool: 'Polygon',

  lineWidth: 1,
  pointLineWidth: 5,
  lineColor: '#ffffff',
  shadowColor: '#00AA00',
  focusedSegmentStyle: '#145374aa',
  focusedLineStyle: '#fffc97',
};

DrawCanvas.propTypes = {
  lineWidth: PropTypes.number,
  pointLineWidth: PropTypes.number,
  lineColor: PropTypes.string,
  shadowColor: PropTypes.string,
  focusedSegmentStyle: PropTypes.string,
  focusedLineStyle: PropTypes.string,
  initialData: PropTypes.oneOfType([segmentPropType, obstaclePropType, treePropType]),
  size: PropTypes.number,
  scale: PropTypes.number.isRequired,
  tool: PropTypes.oneOf(['Point', 'Circle', 'Polygon']),
  mode: PropTypes.string,
  moveMode: PropTypes.string.isRequired,
  polyEdit: PropTypes.number.isRequired,
  focusedPolygonToEdit: PropTypes.number.isRequired,
  linesLabel: PropTypes.string.isRequired,
  // canUndo: PropTypes.bool,
  onFinishDraw: PropTypes.func.isRequired,
  onFinishEdit: PropTypes.func.isRequired,
  onLineLabel: PropTypes.func.isRequired,
  addPointToPolygon: PropTypes.func.isRequired,
  removePointFromPolygon: PropTypes.func.isRequired,
  deleteShapeHandler: PropTypes.func.isRequired,
};

export default DrawCanvas;
