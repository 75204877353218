import { toPng } from 'html-to-image';

const getImages = async (ids) => {
  const images = Object();

  await Promise.all(ids.map((id) => {
    const node = document.getElementById(id);
    return toPng(node, {
      skipFonts: true, // Skip embedding fonts
      filter: (node) => {
        // Skip loading remote stylesheets
        if (node.tagName === 'LINK' && node.getAttribute('rel') === 'stylesheet') {
          return false;
        }
        return true;
      }
    }).then((dataUrl) => {
      images[id] = dataUrl;
    }).catch((error) => {
      // console.error('error in capture image!', error);
    });
  }));

  return images;
};

export default getImages;
