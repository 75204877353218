import React, { CSSProperties, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'charts.css';
import { Chart, Legend, Header, Data, ColumnChart } from 'charts-css-react';

function SolarPowerChartCSS({ annualProduction }) {
  const [data, setData] = useState([]);
  const monthLabels = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  useEffect(() => {
    if (annualProduction) {
      let d = [];
      let start = 0.0;
      annualProduction.map((el, idx) => {
        d.push(
          <tr>
            <th>{monthLabels[idx]}</th>
            <Data
              start={start}
              size={el / (Math.max(...annualProduction) + 100)}
              toolTip={el.toFixed(2)}
              value={el.toFixed(2)}
            />
          </tr>
        );
        start += 0.05;
      });
      setData(d);
    }
  }, [annualProduction]);

  return (
    <div id="myChart">
      <Chart
        style={{
          backgroundColor: '#222831',
          color: '#EEEEEE',
          fontSize: '0.7rem',
          fontWeight: '600',
        }}
        type="column"
        label={true}
        multiple={true}
        showDataAxes={true}
        showSecondaryAxes={1}
        showPrimaryAxis={true}
      >
        {data}
      </Chart>
    </div>
  );
}

SolarPowerChartCSS.propTypes = {
  annualProduction: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default SolarPowerChartCSS;
