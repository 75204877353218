import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Card, Button, Form } from 'react-bootstrap';
import { LeadContext } from 'Leads/Details/hooks/context';
import ValidatedForm, { FloatInput, FloatSelect } from 'components/Form/Inputs';

/* TODO: re-factor so that OtherDetails accepts nested group with fields that will be rendered
* This will be more efficient than calling ALL data for every instance of OtherDetails,
* and then filtering out the data that will be rendered.
* Alternatively, we can introduce a query param so that only the data needed to be rendered
* will be requested from the API */

const OtherDetails = ({
  type,
  tab = '',
  editable = true,
  changeCallback = null,
  defaultValues = {},
  postfix,
  title,
  group,
  pid = null
}) => {
  const { lead, fieldsAPI, namevaluesAPI } = useContext(LeadContext);
  const [{ groups, fields }, setFields] = useState({ groups: [], fields: [] });
  const [values, setValues] = useState({});

  const loadFieldAndGroups = () => {
    fieldsAPI.list().then((result) => {
      const { groups, fields } = result;
      const grp = groups.filter((g) => (g.id > 3 || !!group) && (!group || g.name === group) && (g.fixed === false || !editable || !!group) && g.type === type);
      const grpNames = grp.map((g) => g.name);
      const flds = fields.filter((f) => f.id >= 20 && (!editable || !f.fixed) && grpNames.includes(f.group));
      setFields({ groups: grp, fields: flds });
    }).catch((result) => console.log(result));
  };

  const setDefaults = ((fields) => {
    fields.filter(f => f.type == 'Dropdown' && !!f.defaultOption).forEach(f => setValue(f.name, f.defaultOption, f.group));
  });

  const setValue = (fieldName, value, groupName, opt = null) => {
    const field = fields.find((f) => f.name === fieldName);

    const n = postfix ? `${fieldName}!${postfix}` : fieldName;
    const key = editable || (n in values && !(`${groupName}!${n}` in values)) ? n : `${groupName}!${n}`;

    if (field.type === 'Single Radio Button') {
      fields.filter((f) => f.group === groupName && f.type === 'Single Radio Button').forEach((item) => {
        values[item.name] = false;
      });
      values[key] = value;
    } else if (field.type === 'Radio Buttons') {
      values[key] = opt;
    } else if (field.type === 'Check Box(es)') {
      let val = values[key] || '';
      if (value) val = `${val};${opt}`;
      const uniques = [...new Set(val.split(';'))];
      const newValues = uniques.filter((u) => u.trim() !== '' && (value || u !== opt)).join(';');
      values[key] = newValues;
    } else {
      values[key] = value;
    }

    setValues(() => ({ ...values }));
    if (changeCallback) changeCallback({ [key]: values[key] });
  };

  const getValue = (group, name) => {
    let v = '';
    const n = postfix ? `${name}!${postfix}` : name;
    if (`${group}!${n}` in values) v = values[`${group}!${n}`];
    else if (n in values) v = values[n];
    return v?.toString() ?? '';
  };

  const checkForExistValue = (value, opt) => {
    return value && String(value).split(';').filter((s) => s === opt).length > 0;
  };

  const onSave = (groupName) => {
    return namevaluesAPI.create({ uid: lead.uid, proposal_id: pid === null ? null : pid, group: groupName, values });
  };

  const loadValues = (initialValues = {}) => {
    const values = {...initialValues};
    if (pid === null) {
      lead.values.forEach((v) => { values[v.name] = v.value; });
      setValues(() => ({ ...values, ...defaultValues }));
    } else {
      lead.values.forEach((v) => {
        if (v?.proposal_id === pid) values[v.name] = v.value;
      });
      setValues(() => ({ ...values, ...defaultValues }));
    }
    
    if (changeCallback) changeCallback({ ...values, ...defaultValues });
  };

  useEffect(() => {
    loadFieldAndGroups();
  }, []);

  useEffect(() => {
    setDefaults(fields);
    loadValues(values);
  }, [fields]);

  const RenderField = (g, field) => {
    const {
      name, type, options = [], defaultOption = '',
    } = field;

    // Render field according to field type
    switch (type) {
      case 'Number':
        return (
          <FloatInput
            type="number"
            label={name}
            value={getValue(group, name).substring(0, 10)}
            onChange={(e) => setValue(name, e.target.value, g.name)}
          />
        );
      case 'Currency':
        return (
          <FloatInput
            type="number"
            label={name}
            append="$"
            value={getValue(group, name).substring(0, 10)}
            onChange={(e) => setValue(name, e.target.value, g.name)}
          />
        );
      case 'Date':
        return (
          <FloatInput
            type="date"
            label={name}
            value={getValue(group, name).substring(0, 10)}
            onChange={(e) => setValue(name, e.target.value, g.name)}
          />
        );
      case 'Date and time':
        return (
          <FloatInput
            type="datetime-local"
            label={name}
            value={getValue(group, name).substring(0, 16)}
            onChange={(e) => setValue(name, e.target.value, g.name)}
          />
        );
      case 'Dropdown':
        return (
          <FloatSelect
            label={name}
            value={getValue(group, name) || defaultOption}
            options={options}
            allowNull
            onChange={(e) => setValue(name, e.target.value, g.name)}
          />
        );
      case 'Single Radio Button':
        return (
          <Form.Check
            type="radio"
            name={'opt' + g.name}
            id={name}
            label={name}
            checked={getValue(group, name)}
            onClick={(e) => setValue(name, e.target.checked, g.name)}
          />
        );
      case 'Single Check Box':
        return (
          <Form.Check
            type="checkbox"
            id={name}
            label={name}
            checked={getValue(group, name)}
            onChange={(e) => setValue(name, e.target.checked, g.name)}
          />
        );
      case 'Radio Buttons':
        return (
          <>
            <Form.Label className="mt-2">{name}</Form.Label>
            <br />
            {options && options.map((opt) => (
              <Form.Check
                key={`${name};${opt}`}
                type="radio"
                name={name}
                id={`${name};${opt}`}
                label={opt}
                checked={getValue(group, name) === opt}
                onClick={(e) => setValue(name, e.target.checked, g.name, opt)}
                inline
              />
            ))}
          </>
        );
      case 'Check Box(es)':
        return (
          <>
            <Form.Label className="mt-2">{name}</Form.Label>
            <br />
            {options && options.map((opt) => (
              <Form.Check
                key={`${name};${opt}`}
                type="checkbox"
                id={`${name};${opt}`}
                label={opt}
                checked={checkForExistValue(getValue(group, name), opt)}
                onChange={(e) => setValue(name, e.target.checked, g.name, opt)}
                inline
              />
            ))}
          </>
        );
      default:
        return (
          <FloatInput
            label={name}
            value={getValue(group, name)}
            onChange={(e) => setValue(name, e.target.value, g.name)}
          />
        );
    }
  };

  if (!fields.length) return null;

  return (
    groups.map((g) => (
      <Row key={g.name}>
        <Card className="shadow-sm mb-2 w-100 m-0">
          <Card.Body className="px-4 px-lg-5 py-3">
            <h4 className="my-2 font-weight-bold">{title ?? g.name}</h4>
            <ValidatedForm onSave={() => onSave(g.name)}>
              <Row xs={1} sm={2} className="my-2">
                {fields.filter(({ group }) => group === g.name).map((field, index) => (
                  <Col key={field.id} className={index % 2 === 0 ? 'pl-lg-3 mb-2' : 'pr-lg-3 mb-2'}>
                    {RenderField(g, field)}
                  </Col>
                ))}
              </Row>
              {editable && (
              <Row className="my-2 float-right">
                <Col>
                  <Button type="submit">Save</Button>
                </Col>
              </Row>
              )}
            </ValidatedForm>
          </Card.Body>
        </Card>
      </Row>
    ))
  );
};

export default OtherDetails;
