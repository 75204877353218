import * as THREE from 'three';

import {
  getVerticesGroups,
  addMissedVertices,
  projectTo3DFromOrgVertices,
  segmentProperties,
  calculatePolygonZdim,
  pointInConcavePolygon,
  unbalanceCost,
  fitPlane,
  fitData,
  truncData,
  getDSMPoints,
  setAzimuthFromLineLabel,
} from './geometry.js';

import { copyStateProps } from './3DReconstruction.js';

// [{
//   name: 'name of pattern',
//   level: 'level for multi-story roofs (* default|0|1)',
//   type: 'neighbours or sequence',
//   skip: 'can skip next edges? (true|false default)',
//   centerVertex: { // for neighbours patterns
//     vertexPositionType: '* default|internal|external',
//     vertexAngleType: '* default|Q0|Q1|Q2|Q3|Q4',
//     vertexAngle: '* default|Q0|Q1|Q2|Q3|Q4',
//     vertexCount: 'number of vertex in the position (0 default|1|2|...)',
//     vertexPatterns: 'name and index of patterns which the vertex is a member (name of pattern-index)',
//   },
//   edges: [{
//     vertexPositionType: '*',
//     vertexAngleType: '*',
//     vertexAngle: '*',
//     vertexCount: '*',
//     vertexPatterns: [],
//     edgeType: '* default|Ridge|Eave|Valley|Hip|Rake|?',
//     edgeCount: 'number of edges that are overlay',
//     edgeLength: '* default|verysmall|small|medium|large|verylarge',
//     edgePositionType: '* default|internal|external',
//     edgePatterns: 'name and index of patterns which the edge is a member (name of pattern-index)',
//     edgeAngleToOrientation: 'for multi-stair roofs, align relative to ridge of downstair, (* default|Q0|Q1|Q2|Q3|Q4)',
//     edgeAlignedWithOrientation: 'is edge aligned to house orientation?, (* default|true|false)',
//     angleToNextEdge: '* default|Q0|Q1|Q2|Q3|Q4',
//     angleToPreviousEdge: '* default|Q0|Q1|Q2|Q3|Q4',
//   }],
//   predict: 'Ridge|Eave|Valley|Hip|Rake',
//   predictScore: 'score of pattern to predicted edge type',
//   scoreScaledBy: 'default 1|maxlen|minlen',
//   boostEdgeType: 'true|false', only for sequence pattern
// }];

export const patterns = [
  {
    name: 'external->eave',
    type: 'sequence',
    skip: false,
    edges: [{
      edgeType: '?',
      edgePositionType: 'external',
    }],
    predict: 'Eave',
    predictScore: 1,
    scoreScaledBy: 'maxlen',
  },
  {
    name: 'external->rake',
    type: 'sequence',
    skip: false,
    edges: [{
      edgeType: '?',
      edgePositionType: 'external',
    }],
    predict: 'Rake',
    predictScore: 1,
    scoreScaledBy: 'maxlen',
  },
  {
    name: 'orientation->ridge',
    type: 'sequence',
    skip: false,
    edges: [{
      edgeType: '?',
      edgePositionType: 'internal',
      edgeCount: 2,
      edgeAlignedWithOrientation: true,
    }],
    predict: 'Ridge',
    predictScore: 1,
    scoreScaledBy: 'maxlen',
  },
  {
    name: 'eave->ridge',
    type: 'sequence',
    skip: true,
    edges: [{
      edgeType: 'Eave',
      edgePositionType: 'external',
    },
    {
      edgeType: '?',
      edgePositionType: 'internal',
      angleToPreviousEdge: 'Q2',
    }],
    predict: 'Ridge',
    predictScore: 1,
    scoreScaledBy: 'maxlen',
    boostEdgeType: false,
  },
  {
    name: 'rake->ridge',
    type: 'sequence',
    skip: true,
    edges: [{
      edgeType: 'Rake',
      edgePositionType: 'external',
    },
    {
      edgeType: '?',
      edgePositionType: 'internal',
      angleToPreviousEdge: 'Q1',
    }],
    predict: 'Ridge',
    predictScore: 1,
    scoreScaledBy: 'maxlen',
    boostEdgeType: false,
  },
  {
    name: 'ridge->rake',
    type: 'neighbours',
    skip: false,
    centerVertex: { vertexPositionType: '*' },
    edges: [{
      edgeType: '*',
    },
    {
      edgeType: 'Ridge',
      edgePositionType: 'internal',
      angleToPreviousEdge: 'Q1',
    },
    {
      edgeType: '?',
      angleToPreviousEdge: 'Q1',
    }],
    predict: 'Rake',
    predictScore: 1,
    scoreScaledBy: 'maxlen',
    byDSM: true,
  },
  {
    name: 'ridge->valley',
    type: 'neighbours',
    skip: true,
    centerVertex: { vertexPositionType: 'internal' },
    edges: [{
      edgeType: 'Ridge',
      edgePositionType: 'internal',
    },
    {
      edgeType: '?',
      angleToPreviousEdge: 'Q0-Q1',
    }],
    predict: 'Valley',
    predictScore: 1,
    byDSM: true,
  },
  {
    name: 'ridge->hip',
    type: 'neighbours',
    skip: true,
    centerVertex: { vertexPositionType: 'internal' },
    edges: [{
      edgeType: 'Ridge',
      edgePositionType: 'internal',
    },
    {
      edgeType: '?',
      angleToPreviousEdge: 'Q1-Q2',
    }],
    predict: 'Hip',
    predictScore: 1,
    byDSM: true,
  },
  {
    name: 'valley->rake',
    type: 'neighbours',
    skip: false,
    centerVertex: { vertexPositionType: '*' },
    edges: [{
      edgeType: 'Valley',
      edgePositionType: 'internal',
    },
    {
      edgeType: '?',
      angleToPreviousEdge: 'Q0-Q1',
    }],
    predict: 'Rake',
    predictScore: 1,
  },
  {
    name: 'valley->eave',
    type: 'neighbours',
    skip: false,
    centerVertex: { vertexPositionType: '*' },
    edges: [{
      edgeType: 'Valley',
      edgePositionType: 'internal',
    },
    {
      edgeType: '?',
      angleToPreviousEdge: 'Q1-Q2',
    }],
    predict: 'Eave',
    predictScore: 1,
  },
  {
    name: 'hip->rake',
    type: 'neighbours',
    skip: false,
    centerVertex: { vertexPositionType: '*' },
    edges: [{
      edgeType: 'Hip',
      edgePositionType: 'internal',
    },
    {
      edgeType: '?',
      angleToPreviousEdge: 'Q1-Q2',
    }],
    predict: 'Rake',
    predictScore: 1,
  },
  {
    name: 'hip->eave',
    type: 'neighbours',
    skip: false,
    centerVertex: { vertexPositionType: '*' },
    edges: [{
      edgeType: 'Hip',
      edgePositionType: 'internal',
    },
    {
      edgeType: '?',
      angleToPreviousEdge: 'Q0-Q1',
    }],
    predict: 'Eave',
    predictScore: 1,
  },
  {
    name: 'eave->valley',
    type: 'neighbours',
    skip: false,
    centerVertex: { vertexPositionType: '*' },
    edges: [{
      edgeType: 'Eave',
    },
    {
      edgeType: '?',
      angleToPreviousEdge: 'Q1-Q2',
    }],
    predict: 'Valley',
    predictScore: 1,
    byDSM: true,
  },
  {
    name: 'eave->hip',
    type: 'neighbours',
    skip: false,
    centerVertex: { vertexPositionType: '*' },
    edges: [{
      edgeType: 'Eave',
    },
    {
      edgeType: '?',
      angleToPreviousEdge: 'Q0-Q1',
    }],
    predict: 'Hip',
    predictScore: 1,
    byDSM: true,
  },
  {
    name: 'rake->valley',
    type: 'neighbours',
    skip: false,
    centerVertex: { vertexPositionType: '*' },
    edges: [{
      edgeType: 'Rake',
    },
    {
      edgeType: '?',
      angleToPreviousEdge: 'Q0-Q1',
    }],
    predict: 'Valley',
    predictScore: 1,
  },
  {
    name: 'rake->hip',
    type: 'neighbours',
    skip: false,
    centerVertex: { vertexPositionType: '*' },
    edges: [{
      edgeType: 'Rake',
    },
    {
      edgeType: '?',
      angleToPreviousEdge: 'Q1-Q2',
    }],
    predict: 'Hip',
    predictScore: 1,
  },
  {
    name: 'rake->ridge',
    type: 'neighbours',
    skip: false,
    centerVertex: {
      vertexPositionType: '*',
      vertexAngleType: 'Q2',
    },
    edges: [{
      edgeType: 'Rake',
    },
    {
      edgeType: '?',
      edgePositionType: 'internal',
      angleToPreviousEdge: 'Q1',
    }],
    predict: 'Ridge',
    predictScore: 1,
  },
  // {
  //   name: 'hip-hip->ridge',
  //   type: 'neighbours',
  //   skip: false,
  //   centerVertex: {
  //     vertexPositionType: 'internal',
  //   },
  //   edges: [{
  //     edgeType: 'Hip',
  //   },
  //   {
  //     edgeType: 'Hip',
  //     angleToPreviousEdge: 'Q0-Q1',
  //   },
  //   {
  //     edgeType: '?',
  //     angleToPreviousEdge: 'Q1-Q2',
  //   }],
  //   predict: 'Ridge',
  //   predictScore: 1000,
  // },
  {
    name: 'fix:valley->eave->rake',
    type: 'sequence',
    skip: false,
    edges: [{
      edgeType: 'Valley',
    },
    {
      edgeType: 'Eave',
    },
    {
      edgeType: '?',
      angleToPreviousEdge: 'Q1',
    }],
    predict: 'Rake',
    predictScore: 1,
    scoreScaledBy: 'maxlen',
  },
  {
    name: 'fix:hip->eave->rake',
    type: 'sequence',
    skip: false,
    edges: [{
      edgeType: 'Hip',
    },
    {
      edgeType: 'Eave',
    },
    {
      edgeType: '?',
      angleToPreviousEdge: 'Q1',
    }],
    predict: 'Rake',
    predictScore: 1,
    scoreScaledBy: 'maxlen',
  },
  {
    name: 'fix:valley->ridge->rake',
    type: 'sequence',
    skip: false,
    edges: [{
      edgeType: 'Valley',
    },
    {
      edgeType: 'Ridge',
    },
    {
      edgeType: '?',
      angleToPreviousEdge: 'Q1',
    }],
    predict: 'Rake',
    predictScore: 1,
    scoreScaledBy: 'maxlen',
  },
  {
    name: 'fix:hip->ridge->rake',
    type: 'sequence',
    skip: false,
    edges: [{
      edgeType: 'Hip',
    },
    {
      edgeType: 'Ridge',
    },
    {
      edgeType: '?',
      angleToPreviousEdge: 'Q1',
    }],
    predict: 'Rake',
    predictScore: 1,
    scoreScaledBy: 'maxlen',
  },
  {
    name: 'multi-stari->ridge',
    type: 'sequence',
    skip: false,
    level: 1,
    edges: [{
      edgeType: '?',
      edgePositionType: 'internal',
    }],
    predict: 'Ridge',
    predictScore: 1,
    edgeAngleToOrientation: 'Q1',
  },
  {
    name: 'multi-stari->valley',
    type: 'sequence',
    skip: false,
    level: 1,
    edges: [{
      edgeType: '?',
      edgePositionType: 'external',
    }],
    predict: 'Valley',
    predictScore: 1,
    edgeAngleToOrientation: 'Q0-Q1',
  },
  {
    name: 'multi-stari->eave',
    type: 'sequence',
    skip: false,
    level: 1,
    edges: [{
      edgeType: '?',
      edgePositionType: 'external',
    }],
    predict: 'Eave',
    predictScore: 1,
    edgeAngleToOrientation: 'Q1',
  },
  {
    name: 'multi-stari->rake',
    type: 'sequence',
    skip: false,
    level: 1,
    edges: [{
      edgeType: '?',
      edgePositionType: 'external',
    }],
    predict: 'Rake',
    predictScore: 1,
    edgeAngleToOrientation: 'Q0',
  },
];

export const getVertexGroupNeighbours = (roofVertices, indices) => {
  const strIndices = {};
  indices.forEach((group, groupIndex) => group.forEach(([pi, vi]) => { strIndices[`${pi}-${vi}`] = groupIndex; }));
  const result = indices.map((group) => {
    const neighbours = [];
    group.forEach(([pi, vi]) => {
      const len = roofVertices[pi].length;
      const vib = (vi + len - 1) % len;
      const via = (vi + 1) % len;
      neighbours.push(strIndices[`${pi}-${via}`]);
      neighbours.push(strIndices[`${pi}-${vib}`]);
    });
    const distinctNeighbours = neighbours.filter((value, index, self) => self.indexOf(value) === index);
    return distinctNeighbours;
  });
  return result;
};

export const sortNeighbours = (roofVertices, indices, neighbours) => neighbours.map((ns, ni) => {
  const [pi, vi] = indices[ni][0];
  const center = roofVertices[pi][vi];
  const x = new THREE.Vector3(1, 0, 0);
  const angles = ns.map((n) => {
    const [pj, vj] = indices[n][0];
    const v2 = roofVertices[pj][vj].clone().sub(center);
    let alpha = x.angleTo(v2);
    if (v2.y < 0) alpha = 2 * Math.PI - alpha;
    return alpha;
  });
  const sortedIndices = new Array(angles.length);
  for (let i = 0; i < angles.length; i += 1) sortedIndices[i] = i;
  sortedIndices.sort((a, b) => angles[a] < angles[b] ? -1 : (angles[a] > angles[b] ? 1 : 0));
  const result = sortedIndices.map((i) => ns[i]);
  return result;
});

export const getVerticesType = (roofVertices, indices) => {
  const vertexType = [];
  indices.forEach((groups) => {
    if (groups.length === 1) vertexType.push('S');
    else {
      let angle = 0;
      groups.forEach(([pi, vi]) => {
        const count = roofVertices[pi].length;
        const via = (vi + 1) % count;
        const vib = (vi + count - 1) % count;
        const v1 = roofVertices[pi][via].clone().sub(roofVertices[pi][vi]);
        const v2 = roofVertices[pi][vib].clone().sub(roofVertices[pi][vi]);
        let a = v1.angleTo(v2);
        if (v2.clone().cross(v1).z < 0) {
          a = 2 * Math.PI - a;
        }
        angle += a;
      });
      angle = ((angle * 180) / Math.PI);
      if (angle < 180 - 25) vertexType.push('Q1');
      else if (angle < 180 + 25) vertexType.push('Q2');
      else if (angle < 360 - 25) vertexType.push('Q3');
      else vertexType.push('Q4');
    }
  });
  return vertexType;
};

const addMissedVerticesToSegments = (segments, state, center, scale, updateStateFromSegments) => {
  let indices;
  let missDetect = false;
  do {
    segments.forEach((s) => { s.lines = s.geometry.map(() => ''); });
    indices = getVerticesGroups(segments, state);
    missDetect = addMissedVertices(segments, state, center, scale, indices);
    if (missDetect) updateStateFromSegments(segments, state, null, center[0], center[1]);
  } while (missDetect);

  return indices;
};

export const getSegmentsOrientation = (roofVertices, borders) => {
  const x = new THREE.Vector3(1, 0, 0);
  const angles = borders.map(([si, vi1, vi2]) => {
    const v1 = roofVertices[si][vi1];
    const v2 = roofVertices[si][vi2];
    const v = v2.clone().sub(v1);
    const w = v.length();
    let angle = v.angleTo(x);
    if (v.y > 0) angle = 2 * Math.PI - angle;
    if (angle > Math.PI) angle -= Math.PI;
    return [w, angle];
  });

  const t = (3 / 180) * Math.PI;
  const scores = new Array(angles.length).fill(0);
  angles.forEach(([, a1], ai) => {
    angles.forEach(([w2, a2]) => {
      const diff = Math.abs(a1 - a2) % (Math.PI / 2);
      if (diff < t || ((Math.PI / 2) - diff) < t) {
        scores[ai] += w2;
      }
    });
  });

  const indices = new Array(scores.length);
  for (let i = 0; i < scores.length; i += 1) indices[i] = i;
  indices.sort((a, b) => scores[a] < scores[b] ? 1 : scores[a] > scores[b] ? -1 : 0);
  const orientationIndex = indices[0];
  const [, orientation] = angles[orientationIndex];
  return orientation;
};

const commonEdges = (g1, g2, indices, pIndices = null) => {
  if (pIndices === null) pIndices = indices.map((group) => group.map(([pi]) => pi));
  const commons = pIndices[g1].filter((n) => pIndices[g2].indexOf(n) !== -1);
  const edges = indices[g1]
    .filter(([pi]) => commons.includes(pi))
    .map(([pi, vi]) => {
      const [[, vj]] = indices[g2].filter(([pj]) => pj === pi);
      return [pi, vi, vj];
    });
  return edges;
};

const extractNeighbourPattern = (roofVertices, indices, gi, ns, edgeTypeScores) => {
  const pIndices = indices.map((group) => group.map(([pi]) => pi));

  // const commonEdges = (g1, g2) => {
  //   const commons = pIndices[g1].filter((n) => pIndices[g2].indexOf(n) !== -1);
  //   const edges = indices[g1]
  //     .filter(([pi]) => commons.includes(pi))
  //     .map(([pi, vi]) => {
  //       const [[, vj]] = indices[g2].filter(([pj]) => pj === pi);
  //       return [pi, vi, vj];
  //     });
  //   return edges;
  // };

  const mergeEdgePattern = (gj) => {
    const edges = commonEdges(gi, gj, indices, pIndices);
    const edgesPattern = edges.map(([pi, vi, vj]) => {
      const piLen = roofVertices[pi].length;
      const fromCenter = (vi + 1) % piLen === vj;
      const edge = fromCenter ? edgeTypeScores[pi][vi].edge : edgeTypeScores[pi][vj].edge;
      let ea = edge.edgeAngle;
      if (!fromCenter) ea += edge.edgeAngle > Math.PI ? -Math.PI : Math.PI;
      return [edge, fromCenter, ea];
    });

    if (edgesPattern.length === 1) {
      const [mergedPattern,, ea] = edgesPattern[0];
      const changedMergedPattern = { ...mergedPattern };
      changedMergedPattern.edgeAngle = ea;
      return [changedMergedPattern, edgesPattern];
    } else if (edgesPattern.length === 0) {
      return [[], edgesPattern];
    }

    const [mergedPattern] = edgesPattern.reduce(([a, fc1, ea1], [b, fc2, ea2]) => ([{
      edgeCount: fc1 ? a.edgeCount : b.edgeCount,
      edgePositionType: fc1 ? a.edgePositionType : b.edgePositionType,
      edgePatterns: a.edgePatterns.concat(b.edgePatterns),
      edgeAngleToOrientation: fc1 ? ea1 : ea2,
      edgeAlignedWithOrientation: fc1 ? a.edgeAlignedWithOrientation : b.edgeAlignedWithOrientation,
      edgeLength: fc1 ? a.edgeLength : b.edgeLength,
      edgeAngle: fc1 ? a.edgeAngle : b.edgeAngle,
      edgeTypeScores: {
        eave: Math.max(a.edgeTypeScores.eave, b.edgeTypeScores.eave),
        ridge: Math.max(a.edgeTypeScores.ridge, b.edgeTypeScores.ridge),
        valley: Math.max(a.edgeTypeScores.valley, b.edgeTypeScores.valley),
        hip: Math.max(a.edgeTypeScores.hip, b.edgeTypeScores.hip),
        rake: Math.max(a.edgeTypeScores.rake, b.edgeTypeScores.rake),
      },
    }, fc1 || fc2]));
    return [mergedPattern, edgesPattern];
  };

  const getVertexPattern = (gbi, gai) => {
    const pks = pIndices[gi]
      .filter((n) => pIndices[gbi].indexOf(n) !== -1)
      .filter((n) => pIndices[gai].indexOf(n) !== -1);
    if (pks.length) {
      const [pk] = pks;
      const [[pj, vj]] = indices[gi].filter(([pi]) => pk === pi);
      return edgeTypeScores[pj][vj].vertex;
    }
    return null;
  };

  try {

    const neighbourPatterns = [+1, -1].map((dir) => {
      const ngs = [...ns];
      if (dir === -1) ngs.reverse();
      return ngs.map((n, ni) => {
        const m = ngs[(ni + 1) % ngs.length];
        const [edgePattern, edgesPattern] = mergeEdgePattern(n);
        let vertexPattern = getVertexPattern(n, m);
        if (!vertexPattern) {
          const totalAngle = indices[gi].map(([pi, vi]) => edgeTypeScores[pi][vi].vertex.vertexAngle).reduce((a, b) => a + b);
          const [[pi, vi]] = indices[gi];
          vertexPattern = { ...edgeTypeScores[pi][vi].vertex };
          vertexPattern.vertexAngle = 2 * Math.PI - totalAngle;
        }
        return { edge: edgePattern, vertex: vertexPattern, edges: edgesPattern };
      });
    });

    // TODO vertex angle in cancave, edgeAngleToOrientation when not aligned
    return neighbourPatterns;

  } catch{ return []; }
};

const extractSequencePattern = (roof, roofIndex, edgeTypeScores) => {
  const sequencePatterns = [+1, -1].map((dir) => {
    const vIndices = roof.map((_, vi) => vi);
    if (dir === -1) vIndices.reverse();
    return vIndices.map((v, vi) => {
      const vj = dir === 1 ? v : vIndices[(vi + 1) % vIndices.length];
      const edgePattern = edgeTypeScores[roofIndex][vj].edge;
      const vertexPattern = edgeTypeScores[roofIndex][v].vertex;
      if (dir === 1) return { edge: edgePattern, vertex: vertexPattern };
      const changedEdgePattern = { ...edgePattern };
      changedEdgePattern.edgeAngle += edgePattern.edgeAngle > Math.PI ? -Math.PI : Math.PI;
      return { edge: changedEdgePattern, vertex: vertexPattern };
    });
  });

  return sequencePatterns;
};

const matchAngle = (angle, anglePattern) => {
  if (anglePattern === '*') return true;
  const dAngle = (angle * 180) / Math.PI;
  const t = 15;
  switch (anglePattern) {
    case 'Q0': return dAngle >= 0 - t && dAngle < 0 + t;
    case 'Q0-Q1': return dAngle >= 0 + t && dAngle < 90 - t;
    case 'Q1': return dAngle >= 90 - t && dAngle < 90 + t;
    case 'Q1-Q2': return dAngle >= 90 + t && dAngle < 180 - t;
    case 'Q2': return dAngle >= 180 - t && dAngle < 180 + t;
    case 'Q2-Q3': return dAngle >= 180 + t && dAngle < 270 - t;
    case 'Q3': return dAngle >= 270 - t && dAngle < 270 + t;
    case 'Q3-Q4': return dAngle >= 270 + t && dAngle < 360 - t;
    case 'Q4': return dAngle >= 360 - t && dAngle < 360 + t;
    default: return false;
  }
};

const matchProperty = (sMatching, sPattern, prop) => !sPattern[prop] || sPattern[prop] === '*' || sPattern[prop] === sMatching[prop];

const isMatch = (edgePattern, vertexPattern, matchingPattern, angleToPrevious = null) => {
  const ep = edgePattern;
  if (ep.angleToPreviousEdge && angleToPrevious === null) return [false, 0];
  const vp = vertexPattern;
  const v = matchingPattern.vertex;
  const e = matchingPattern.edge;
  let match = matchProperty(v, vp, 'vertexPositionType');
  match = match && matchProperty(v, vp, 'vertexAngleType');
  match = match && matchProperty(v, vp, 'vertexCount');
  if (vp.vertexAngle) match = match && matchAngle(v.vertexAngle, vp.vertexAngle);
  if (edgePattern.vertexPatterns) match = match && edgePattern.vertexPatterns.every((pt) => matchingPattern.vertex.vertexPatterns.include(pt));
  match = match && matchProperty(e, ep, 'edgeCount');
  match = match && matchProperty(e, ep, 'edgeLength');
  match = match && matchProperty(e, ep, 'edgePositionType');
  match = match && matchProperty(e, ep, 'edgeAlignedWithOrientation');
  if (edgePattern.edgePatterns) match = match && edgePattern.edgePatterns.every((pt) => matchingPattern.edge.edgePatterns.include(pt));
  if (ep.edgeAngleToOrientation) match = match && matchAngle(e.edgeAngleToOrientation, ep.edgeAngleToOrientation);
  if (ep.angleToNextEdge) match = match && matchAngle(v.vertexAngle, ep.angleToNextEdge);
  if (match && ep.angleToPreviousEdge) match = match && matchAngle(angleToPrevious, ep.angleToPreviousEdge);

  let score = 0;
  if (match) {
    const et = edgePattern.edgeType.toLowerCase();
    if (et === '*') score = 1; // Math.max(...Object.keys(e.edgeTypeScores).map((k) => e.edgeTypeScores[k]));
    else if (et !== '?') score = e.edgeTypeScores[et];
    else if (!score) score = 1;
    match = score > 0;
  }
  return [match, score];
};

const matchPattern = (vertexEdgeSamples, pattern, matches, previousMatches) => {
  const neighbourBased = pattern.type === 'neighbours';
  const getAngle = (i, j) => {
    const a1 = vertexEdgeSamples[i].edge.edgeAngle;
    const a2 = vertexEdgeSamples[j].edge.edgeAngle;
    const angle = Math.abs(a1 - a2);
    if (angle > Math.PI) return 2 * Math.PI - angle;
    return angle;
  };
  const verticesPattern = neighbourBased ? pattern.centerVertex : pattern.edges;
  const edgesPattern = pattern.edges;
  const neighbourEdgeCount = vertexEdgeSamples.length;
  const patternEdgeCount = edgesPattern.length;
  const matchedEdgeCount = matches.length;
  const begin = matchedEdgeCount ? (matches[matchedEdgeCount - 1] + 1) % neighbourEdgeCount : 0;
  const end = matchedEdgeCount ? matches[0] : 0;
  const vertexPattern = verticesPattern[matchedEdgeCount] || verticesPattern;
  const edgePattern = edgesPattern[matchedEdgeCount];
  const strMatches = matches.join('-');

  let angleToPrevious = null;
  let i = begin;
  do {
    const matchingPattern = vertexEdgeSamples[i];
    const strMatch = matchedEdgeCount ? `${strMatches}-${i}` : `${i}`;
    if (!previousMatches.includes(strMatch)) {
      if (matchedEdgeCount) angleToPrevious = getAngle(matches[matchedEdgeCount - 1], i);
      const [matchings, matchScore] = isMatch(edgePattern, vertexPattern, matchingPattern, angleToPrevious);
      if (matchings) {
        matches.push(i);
        if (matches.length === patternEdgeCount) return matchScore;
        const nextsScore = matchPattern(vertexEdgeSamples, pattern, matches, previousMatches);
        if (nextsScore) return Math.min(matchScore, nextsScore);
        matches.pop();
      }
    }
    if (!pattern.skip && matchedEdgeCount) break;
    // skip = pattern.skip && matchedEdgeCount;
    i = (i + 1) % neighbourEdgeCount;
  } while (i !== begin && i !== end);

  return 0;
};

const matchPatterns = (vertexEdgeSamples, pattern) => {
  const allMatches = [];
  const allScores = [];
  let matches = [];
  let score = 0;
  const previousMatches = [];
  do {
    matches = [];
    score = matchPattern(vertexEdgeSamples, pattern, matches, previousMatches);
    if (score) {
      allMatches.push([...matches]);
      allScores.push(score);
      previousMatches.push(matches.join('-'));
    }
  } while (score > 0 && matches.length);
  return [allMatches, allScores];
};

const edgeLengthTypes = ['verysmall', 'small', 'medium', 'large', 'verylarge'];
const edgeLengthScales = [0.5, 0.75, 1, 1.5, 2];
const edgeLengthRanges = [0.5, 2, 4, 10, 1E3];

const getMatchScoreScale = (pattern, vertexEdgePattern, matches) => {
  let scoreScale = 1;
  if (pattern.scoreScaledBy && ['maxlen', 'minlen'].includes(pattern.scoreScaledBy)) {
    const edgesLength = matches.map((m) => {
      if (!vertexEdgePattern[m].edge.edgeLength) return 1;
      const el = vertexEdgePattern[m].edge.edgeLength;
      return edgeLengthScales[edgeLengthTypes.indexOf(el)];
    });
    if (pattern.scoreScaledBy === 'maxlen') scoreScale = Math.max(...edgesLength);
    else scoreScale = Math.min(...edgesLength);
  }
  return scoreScale;
};

const lable3dEdge = (newState, vertices3D, pi, vi, getEdgeCount, valleyHipThreshold = 0.1, ridgeEaveAngleThreshold = 5) => {
  const { orgVertices, verticesType } = newState;
  const roofVertices = orgVertices.filter((p, si) => verticesType[si] === 'roof');
  const poly = roofVertices[pi];
  const poly3d = vertices3D[pi];
  const { normal: normal1 } = segmentProperties(poly3d);
  const vi2 = (vi + 1) % poly.length;
  const v1 = orgVertices[pi][vi];
  const v2 = orgVertices[pi][vi2];
  const v3di1 = poly3d[vi];
  const v3di2 = poly3d[vi2];
  const [edgeCount, pj, vj1, vj2] = getEdgeCount(pi, vi, vi2);

  const isNormalAliged = (e, n1, n2) => {
    const d = n1.clone().normalize().cross(n2.clone().normalize());
    const a = Math.abs(d.angleTo(e));
    return Math.min(a, Math.PI - a) < Math.PI / 24;
  };

  const sameHeight = Math.abs(Math.atan2(Math.abs(v3di1.z - v3di2.z), v1.clone().sub(v2).length())) < (ridgeEaveAngleThreshold / 180) * Math.PI;
  let topHeight = false;
  if (sameHeight) {
    const e = v2.clone().sub(v1);
    const elen = e.length();
    e.normalize();
    const vc = v1.clone().add(e.clone().multiplyScalar(elen / 2));
    const n = normal1.clone().setZ(0).normalize().multiplyScalar(1);
    const testPoint = vc.clone().add(n);
    topHeight = pointInConcavePolygon(orgVertices[pi], testPoint);
    // const polyz = poly3d.map((v3d) => v3d.z);
    // const minz = Math.min(...polyz);
    // const maxz = Math.max(...polyz);
    // const middlez = minz + (maxz - minz) / 2;
    // topHeight = v3di1.z > middlez;
    // if (v3di1.z < middlez) segments[pi].lines[svi] = 'Eave';
    // else segments[pi].lines[svi] = 'Ridge';
  }
  if (edgeCount === 2) {
    const poly3d2 = vertices3D[pj];
    if (poly3d2) {
      const v3dj1 = poly3d2[vj1];
      const v3dj2 = poly3d2[vj2];
      const { normal: normal2 } = segmentProperties(poly3d2);
      const ev1 = v3di2.clone().sub(v3di1);
      const vcen1 = v3di1.clone().add(ev1.clone().multiplyScalar(0.5));
      const ev2 = v3dj2.clone().sub(v3dj1);
      const vcen2 = v3dj1.clone().add(ev2.clone().multiplyScalar(0.5));
      const vl = ev1.clone().cross(normal1.clone());
      const vr = ev2.clone().cross(normal2.clone().multiplyScalar(-1));
      vl.normalize();
      vr.normalize();
      const vol = vcen1.clone().add(vl.multiplyScalar(1));
      const vor = vcen2.clone().add(vr.multiplyScalar(1));
      const vldir = vol.clone().sub(vcen1);
      const vrdir = vor.clone().sub(vcen2);
      const zdiff = vldir.clone().add(vrdir).z;
      const alignNormal = isNormalAliged(ev1, normal1, normal2) && isNormalAliged(ev2, normal1, normal2);
      if (sameHeight && topHeight && alignNormal && vrdir.z < 0) return 'Ridge';
      if (sameHeight && !topHeight && ((vcen1.z - vcen2.z > 0 && !alignNormal) || (vcen1.z - vcen2.z > 2 && alignNormal))) return 'Eave';
      if (zdiff > valleyHipThreshold && Math.abs(vldir.z) > valleyHipThreshold) return 'Valley';
      if (zdiff < -valleyHipThreshold && Math.abs(vldir.z) > valleyHipThreshold) return 'Hip';
      if (vcen1.z > vcen2.z) return 'Rake';
      return 'Valley';
      // const startMarkerGeometry = new THREE.SphereGeometry(0.5, 15, 15);
      // const startMarkerMaterial = new THREE.MeshBasicMaterial({ color: 'red' });
      // const marker = new THREE.Mesh(startMarkerGeometry, startMarkerMaterial);
      // marker.position.set(vcen1.x, vcen1.z, vcen1.y);
      // sceneRef.current.add(marker);
      // const startMarkerGeometry2 = new THREE.SphereGeometry(0.5, 15, 15);
      // const startMarkerMaterial2 = new THREE.MeshBasicMaterial({ color: 'blue' });
      // const marker2 = new THREE.Mesh(startMarkerGeometry2, startMarkerMaterial2);
      // marker2.position.set(vcen2.x, vcen2.z, vcen2.y);
      // sceneRef.current.add(marker2);
      // const startMarkerGeometry3 = new THREE.SphereGeometry(0.5, 15, 15);
      // const startMarkerMaterial3 = new THREE.MeshBasicMaterial({ color: 'red' });
      // const marker3 = new THREE.Mesh(startMarkerGeometry3, startMarkerMaterial3);
      // marker3.position.set(vol.x, vol.z, vol.y);
      // sceneRef.current.add(marker3);
      // const startMarkerGeometry4 = new THREE.SphereGeometry(0.5, 15, 15);
      // const startMarkerMaterial4 = new THREE.MeshBasicMaterial({ color: 'blue' });
      // const marker4 = new THREE.Mesh(startMarkerGeometry4, startMarkerMaterial4);
      // marker4.position.set(vor.x, vor.z, vor.y);
      // sceneRef.current.add(marker4);
    }
  } else if (edgeCount === 1) {
    if (sameHeight && !topHeight) return 'Eave';
    if (sameHeight && topHeight) return 'Ridge';
    return 'Rake';
  }
};

const mapTo3d = (segments, state, center, updateStateFromSegments, getEdgeCount, labelEdge = true) => {
  const newState = {
    orgVertices: [], vertices: [], vertices3D: [], verticesType: [], eaves: [], pitches: [], rolls: [], eaveHeights: [], UV: [], changeClockwise: [], ranges: [], parents: [],
  };

  copyStateProps(state, newState);
  updateStateFromSegments(segments, newState, null, center[0], center[1]);
  newState.pitches.forEach((p, pi) => { newState.pitches[pi] = 20; newState.eaveHeights[pi] = 10; });
  projectTo3DFromOrgVertices(newState, false);

  if (labelEdge) {
    const { vertices3D, changeClockwise } = newState;
    segments.forEach((s, si) => {
      const poly = newState.orgVertices[si];
      let angleThreshold = 7.5;
      let foundRidgeOrEave = false;
      while (!foundRidgeOrEave && angleThreshold <= 20) {
        const threshold = angleThreshold;
        poly.forEach((v, vi) => {
          const svi = changeClockwise[si] ? poly.length - vi - 1 : vi;
          const label = lable3dEdge(newState, vertices3D, si, vi, getEdgeCount, 0.1, threshold);
          segments[si].lines[svi] = label;
        });
        angleThreshold += 5;
        foundRidgeOrEave = s.lines.some((l) => l === 'Eave' || l === 'Ridge');
      }
    });
  }

  return newState;
};

const getCandidateEdges = (roofVertices, edgeTypeScores) => {
  const candidates = roofVertices.map(() => []);

  const sameOrientation = (edgeType1, edgeType2, orientation1, orientation2) => {
    let deltaO = Math.abs(orientation1 - orientation2);
    if (edgeType1 !== edgeType2) deltaO += Math.PI;
    return Math.min(deltaO, Math.abs(2 * Math.PI - deltaO)) < Math.PI / 6;
  };

  const updateCandidates = (segmentIndex, edgeIndex1, edgeType1, score1, orientation1) => {
    const newCandidates = candidates[segmentIndex].filter(([, edgeType2, score2, orientation2, isFixed2]) => !sameOrientation(edgeType1, edgeType2, orientation1, orientation2) || score2 > score1 || isFixed2);
    if (newCandidates.every(([, edgeType2, score2, orientation2]) => !sameOrientation(edgeType1, edgeType2, orientation1, orientation2) || score1 > score2)) newCandidates.push([edgeIndex1, edgeType1, score1, orientation1, false]);
    candidates[segmentIndex] = newCandidates;
  };

  const getSegmentCandidates = (s, si, onlyMaxScore, byLength) => {
    s.forEach((v1, vi) => {
      const scores = edgeTypeScores[si][vi].edge.edgeTypeScores;
      const maxScore = Math.max(...Object.keys(scores).map((k) => scores[k]));
      const eaveScore = edgeTypeScores[si][vi].edge.edgeTypeScores.eave;
      const ridgeScore = edgeTypeScores[si][vi].edge.edgeTypeScores.ridge;
      const orientation = edgeTypeScores[si][vi].edge.edgeAngle;
      const edgeLength = edgeTypeScores[si][vi].edge.edgeActualLength;
      if (eaveScore && (!onlyMaxScore || eaveScore === maxScore)) updateCandidates(si, vi, 'Eave', byLength ? edgeLength : eaveScore, orientation);
      if (ridgeScore && (!onlyMaxScore || ridgeScore === maxScore)) updateCandidates(si, vi, 'Ridge', byLength ? edgeLength : ridgeScore, orientation);
    });
  };

  roofVertices.forEach((s, si) => {
    getSegmentCandidates(s, si, true, true);
    if (!candidates[si].length) {
      // if (candidates[si].length) candidates[si][0][4] = true;
      getSegmentCandidates(s, si, false, true);
    }
    candidates[si] = candidates[si].sort(([, edgeType1, score1], [, edgeType2, score2]) => {
      if (edgeType1 === 'Eave' && edgeType2 === 'Ridge') return -1;
      if (edgeType1 === 'Ridge' && edgeType2 === 'Eave') return +1;
      if (score1 > score2) return -1;
      if (score1 < score2) return +1;
      return 0;
    });
  });

  const candidateIndices = roofVertices.map(() => 0);
  return [candidates, candidateIndices];
};

const applyPredictorByMaxScore = (segments, state, edgeTypeScores) => {
  segments.forEach((s, si) => {
    const edgeTypes = edgeTypeScores[si].map((v) => {
      const keys = Object.keys(v.edge.edgeTypeScores);
      const scores = keys.map((k) => v.edge.edgeTypeScores[k]);
      const maxScore = Math.max(...scores);
      if (maxScore > 0) {
        const edgeIndex = scores.indexOf(maxScore);
        let edgeName = keys[edgeIndex];
        edgeName = edgeName.charAt(0).toUpperCase() + edgeName.slice(1);
        return edgeName;
      }
      return '';
    });
    if (state.changeClockwise[si]) edgeTypes.reverse();
    s.lines = [...edgeTypes];
  });
};

const applyPredictor = (segments, state, candidateList, candidateIndice) => {
  segments.forEach((s, si) => {
    const ci = candidateIndice[si];
    const [edgeIndex, edgeType] = candidateList[si][ci];
    s.lines = s.geometry.map(() => '');
    if (state.changeClockwise[si]) s.lines[s.lines.length - edgeIndex - 1] = edgeType;
    else s.lines[edgeIndex] = edgeType;
  });
};

const pointsMeanZ = (points, pointPolyFunc) => {
  const pz = points.map((p) => pointPolyFunc(p));
  pz.sort();
  const pz4l = Math.floor(pz.length / 4);
  const pz4 = pz.slice(pz4l, pz4l * 3);
  const pzMedian = pz4.reduce((a, b) => a + b) / pz4.length;
  return pzMedian;
};

const edgeSidePoints = (orgVertices, pointPolyFunc) => {
  const z = new THREE.Vector3(0, 0, 1);
  const stepSize = 0.25;
  const stepOffset = 5;
  const stepBorderOffset = 0.5;
  const insideHeights = [];
  const outsideHeights = [];
  const borderHeights = [];
  orgVertices.forEach((s) => {
    const insidePoints = [];
    const outsidePoints = [];
    const borderPoints = [];
    s.forEach((v1, vi) => {
      const inPoints = [];
      const outPoints = [];
      const bPoints = [];
      const vj = (vi + 1) % s.length;
      const v2 = s[vj];
      const e = v2.clone().sub(v1);
      const len = e.length();
      e.normalize();
      const n = e.clone().cross(z).normalize().multiplyScalar(Math.min(len, stepOffset));
      const nb = e.clone().cross(z).normalize().multiplyScalar(stepBorderOffset);
      const step = e.clone().multiplyScalar(stepSize);
      const inPoint = v1.clone().add(n).add(step);
      const exPoint = v1.clone().sub(n).add(step);
      const biPoint = v1.clone().add(nb).add(step);
      const bxPoint = v1.clone().sub(nb).add(step);
      const inPointEnd = v2.clone().add(n).sub(step);
      const exPointEnd = v2.clone().sub(n).sub(step);
      const biPointEnd = v2.clone().add(nb).sub(step);
      const bxPointEnd = v2.clone().sub(nb).sub(step);
      inPoints.push(inPoint);
      outPoints.push(exPoint);
      bPoints.push(biPoint);
      bPoints.push(bxPoint);
      inPoints.push(inPointEnd);
      outPoints.push(exPointEnd);
      bPoints.push(biPointEnd);
      bPoints.push(bxPointEnd);
      const pointCount = Math.max(0, len / stepSize - 1);
      for (let i = 0; i < pointCount - 1; i += 1) {
        const sv = step.clone().multiplyScalar(i + 1);
        const newInPoint = inPoint.clone().add(sv);
        const newExPoint = exPoint.clone().add(sv);
        const newbiPoint = biPoint.clone().add(sv);
        const newbxPoint = bxPoint.clone().add(sv);
        inPoints.push(newInPoint);
        outPoints.push(newExPoint);
        bPoints.push(newbiPoint);
        bPoints.push(newbxPoint);
      }
      insidePoints.push(pointsMeanZ(inPoints, pointPolyFunc));
      outsidePoints.push(pointsMeanZ(outPoints, pointPolyFunc));
      borderPoints.push(pointsMeanZ(bPoints, pointPolyFunc));
    });
    insideHeights.push(insidePoints);
    outsideHeights.push(outsidePoints);
    borderHeights.push(borderPoints);
  });
  return [insideHeights, outsideHeights, borderHeights];
};

const setScoreByDetectedEdge = (state, segments, edgeTypeScores) => {
  edgeTypeScores.forEach((score, si) => {
    const s = segments[si];
    score.forEach(({ edge }, edgeIndex) => {
      let edgeType;
      if (state.changeClockwise[si]) edgeType = s.lines[s.lines.length - edgeIndex - 1];
      else edgeType = s.lines[edgeIndex];
      if (edgeType) edge.edgeTypeScores[edgeType.toLowerCase()] = edge.edgeActualLength;
    });
  });
};

export const detectEdgeTypesByDSM = (segments, state, center, scale, resolution, dsmRaster, minDSM, dmsOffset, mask, getEdgeCount) => {
  const { orgVertices, changeClockwise } = state;

  const planesPoints = getDSMPoints(segments, [], state, center, scale, resolution, dsmRaster, minDSM, dmsOffset, mask);

  let iters = 0;
  const ics = [];
  const models = [];
  const projectedData = planesPoints.map((points, pi) => {
    const truncedPoints = truncData(points, 5000);
    const [model, mean, it, ic] = fitPlane(truncedPoints);
    const roofVertices = orgVertices[pi].map((p) => [p.x, p.y, p.z]);
    const projectedPoints = fitData(roofVertices, model, mean);

    ics.push(ic);
    models.push([model, mean]);
    iters += it;

    return projectedPoints;
  });

  const vertices3D = projectedData.map((poly) => poly.map(([x, y, z]) => new THREE.Vector3(x, y, z)));

  segments.forEach((s, si) => {
    s.lines = s.geometry.map(() => '');
    const poly = orgVertices[si];
    let angleThreshold = 5;
    let foundRidgeOrEave = false;
    while (!foundRidgeOrEave && angleThreshold <= 20) {
      const threshold = angleThreshold;
      poly.forEach((_, vi) => {
        const svi = changeClockwise[si] ? poly.length - vi - 1 : vi;
        const label = lable3dEdge(state, vertices3D, si, vi, getEdgeCount, 0.1, threshold);
        s.lines[svi] = label;
      });
      angleThreshold += 5;
      foundRidgeOrEave = s.lines.some((l) => l === 'Eave' || l === 'Ridge');
    }
  });

  // return;

  // const pointPolyFunc = ({ x, y }) => {
  //   const [ox, oy] = dmsOffset;
  //   const px = Math.floor(x / scale + center[0]);
  //   const py = Math.floor(y / scale + center[1]);
  //   const index = py * (width * 4) + px * 4;
  //   const si = maskData.data[index] - 1;
  //   if (si >= 0 && si < segments.length) {
  //     const dsmIndex = (py + oy) * width + (px + ox);
  //     const z = (dsmRaster[dsmIndex] - minDSM) * (100 / resolution) * scale;
  //     return z;
  //     // const [model, mean] = models[si];
  //     // const [[,, cz]] = fitData([[x, y]], model, mean);
  //     // return cz;
  //   }
  //   return 0;
  // };

  // const pointsToLabels = (s, si, projectedPoints, insidePoints, outsidePoints, borderInPoints, borderOutPoints) => {
  //   const oneMeterUnits = (100 / resolution) * scale;
  //   const meanZ = projectedPoints.map((p) => p[2]).reduce((a, b) => a + b) / projectedPoints.length;

  //   const insideEdge = (v3d1, v3d2) => {
  //     const { normal: normal1 } = segmentProperties(projectedPoints);
  //     const v1 = v3d1.clone().setZ(0);
  //     const v2 = v3d2.clone().setZ(0);
  //     const e = v2.clone().sub(v1);
  //     const elen = e.length();
  //     e.normalize();
  //     const vc = v1.clone().add(e.clone().multiplyScalar(elen / 2));
  //     const n = normal1.clone().setZ(0).normalize().multiplyScalar(1);
  //     const testPoint = vc.clone().add(n);
  //     const isRidge = pointInConcavePolygon(state.orgVertices[si], testPoint);
  //     return isRidge;
  //   };

  //   let angleThreshold = 5;
  //   let foundRidgeOrEave = false;
  //   while (!foundRidgeOrEave && angleThreshold <= 20) {
  //     const threshold = angleThreshold;
  //     projectedPoints.forEach((v1, vi) => {
  //       const vj = (vi + 1) % projectedPoints.length;
  //       const v2 = projectedPoints[vj];
  //       const d = ((v1[0] - v2[0]) ** 2 + (v1[1] - v2[1]) ** 2) ** 0.5;
  //       const h = Math.abs(v1[2] - v2[2]);
  //       const alpha = Math.abs(Math.atan2(h, d) * 180) / Math.PI;
  //       const inZ = insidePoints[si][vi];
  //       const outZ = outsidePoints[si][vi];
  //       const bInZ = borderInPoints[si][vi];
  //       // const boutZ = borderOutPoints[si][vi];
  //       let eaveType = '';
  //       if (alpha < threshold) {
  //         if (((v1[2] + v2[2]) / 2) > meanZ) eaveType = 'Ridge';
  //         else if (inZ - outZ > oneMeterUnits) eaveType = 'Eave';
  //         else if (bInZ > inZ && bInZ > outZ) eaveType = 'Hip';
  //         else eaveType = 'Valley';
  //       } else if (inZ - outZ > oneMeterUnits) eaveType = 'Rake';
  //       else if (bInZ > inZ && bInZ > outZ) eaveType = 'Hip';
  //       else eaveType = 'Valley';
  //       if (state.changeClockwise[si]) s.lines[s.lines.length - vi - 1] = eaveType;
  //       else s.lines[vi] = eaveType;
  //     });
  //     angleThreshold += 5;
  //     foundRidgeOrEave = s.lines.some((l) => l === 'Eave' || l === 'Ridge');
  //   }
  // };

  // const [insidePoints, outsidePoints, borderPoints] = edgeSidePoints(orgVertices, pointPolyFunc);
  // segments.forEach((s, si) => {
  //   const projectedPoints = projectedData[si];
  //   s.lines = s.geometry.map(() => '');
  //   pointsToLabels(s, si, projectedPoints, insidePoints, outsidePoints, borderPoints);
  // });

  // console.log(iters, ics);

  // return projectedData;
};

export const detectEdgeTypes = (segments, state, center, scale, resolution, updateStateFromSegments, getBorders, byDSM = false, dsmRaster = null, minDSM = null, dmsOffset = null, mask = null) => {
  segments.forEach((s) => { s.lines = s.geometry.map(() => ''); });

  let indices;
  if (!byDSM) indices = addMissedVerticesToSegments(segments, state, center, scale, updateStateFromSegments);
  else indices = getVerticesGroups(segments, state);
  const { parents, orgVertices, verticesType } = state;
  const roofVertices = orgVertices.filter((p, pi) => verticesType[pi] === 'roof');
  const vertexType = getVerticesType(roofVertices, indices);

  const getVertexAngleType = (si, vi) => {
    const vgroupIndex = indices.map((group) => group.some(([si2, vi2]) => si2 === si && vi2 === vi)).indexOf(true);
    return [vertexType[vgroupIndex], vgroupIndex];
  };

  const getEdgeCount = (si, vi1, vi2) => {
    const [, gindex1] = getVertexAngleType(si, vi1);
    const [, gindex2] = getVertexAngleType(si, vi2);
    const set1 = indices[gindex1].map(([sj]) => sj);
    const set2 = indices[gindex2].map(([sj]) => sj);
    const commonSegments = set1.filter((value) => set2.includes(value));
    const otherSegments = commonSegments.filter(((value) => value !== si));
    if (otherSegments.length) {
      const [pj] = otherSegments;
      const [vj1, vj2] = [gindex1, gindex2].map((gindex) => indices[gindex].filter(([pk]) => pk === pj).map(([, vk]) => vk)[0]);
      return [commonSegments.length, pj, vj1, vj2];
    }
    return [commonSegments.length, null, null, null];
  };

  const getEdgeLength = (si, vi) => {
    const roof = roofVertices[si];
    const vj = (vi + 1) % roof.length;
    let len = roof[vj].clone().sub(roof[vi]).length();
    len = ((len / scale) * resolution) / 100;
    const lenIndex = edgeLengthRanges.map((l) => len < l).indexOf(true);
    const lenName = edgeLengthTypes[lenIndex];
    return [lenName, len];
  };

  const borders = getBorders(state, roofVertices, false);
  const orientation = getSegmentsOrientation(roofVertices, borders);
  const x = new THREE.Vector3(1, 0, 0);
  const getAngleToOrientation = (si, vi, pure = false) => {
    const v1 = roofVertices[si][vi];
    const v2 = roofVertices[si][(vi + 1) % roofVertices[si].length];
    const v = v2.clone().sub(v1);
    let edgeAzimuth = v.angleTo(x);
    if (v.y > 0) edgeAzimuth = 2 * Math.PI - edgeAzimuth;
    if (pure) return edgeAzimuth;
    if (edgeAzimuth > Math.PI) edgeAzimuth -= Math.PI;
    const angle = Math.abs(edgeAzimuth - orientation);
    return Math.min(angle, Math.abs(Math.PI - angle), Math.abs(Math.PI / 2 - angle));
  };

  const getVertexAngle = (si, vi) => {
    const len = roofVertices[si].length;
    const v = roofVertices[si][vi];
    const va = roofVertices[si][(vi + 1) % len];
    const vb = roofVertices[si][(vi + len - 1) % len];
    const v1 = va.clone().sub(v);
    const v2 = vb.clone().sub(v);
    const concave = v1.clone().cross(v2).z > 0;
    const angle = concave ? 2 * Math.PI - v1.angleTo(v2) : v1.angleTo(v2);
    return angle;
  };

  const strIndices = {};
  indices.forEach((group, groupIndex) => group.forEach(([pi, vi]) => { strIndices[`${pi}-${vi}`] = groupIndex; }));
  const getVertexCount = (si, vi) => {
    const gi = strIndices[`${si}-${vi}`];
    return indices[gi].length;
  };

  const bordersDic = roofVertices.map(() => []);
  borders.forEach(([si, v1, v2]) => {
    bordersDic[si].push([v1, v2]);
  });

  const neighbours = getVertexGroupNeighbours(roofVertices, indices);
  const sortedNeighbours = sortNeighbours(roofVertices, indices, neighbours);

  const edgeTypeScores = roofVertices.map((s, si) => s.map((v, vi) => {
    const vi2 = (vi + 1) % s.length;
    const vertexPositionType = bordersDic[si].some(([v1, v2]) => vi === v1 || vi === v2) ? 'external' : 'internal';
    const [vertexAngleType] = getVertexAngleType(si, vi);
    const vertexAngle = getVertexAngle(si, vi);
    const vertexCount = getVertexCount(si, vi);
    const [edgeCount] = getEdgeCount(si, vi, vi2);
    const [edgeLength, edgeActualLength] = getEdgeLength(si, vi);
    const edgePositionType = bordersDic[si].some(([v1]) => vi === v1) ? 'external' : 'internal';
    const edgeAngle = getAngleToOrientation(si, vi, true);
    const edgeAngleToOrientation = getAngleToOrientation(si, vi);
    const edgeAlignedWithOrientation = edgeAngleToOrientation < (10 / 180) * Math.PI;
    return ({
      index: [si, vi],
      vertex: {
        vertexPositionType,
        vertexAngleType,
        vertexAngle,
        vertexCount,
        vertexPatterns: [],
      },
      edge: {
        edgeCount,
        edgeLength,
        edgeActualLength,
        edgePositionType,
        edgePatterns: [],
        edgeAngle,
        edgeAngleToOrientation,
        edgeAlignedWithOrientation,
        edgeTypeScores: {
          eave: 0, ridge: 0, valley: 0, hip: 0, rake: 0,
        },
      },
      predicts: [],
    });
  }));

  if (byDSM) {
    detectEdgeTypesByDSM(segments, state, center, scale, resolution, dsmRaster, minDSM, dmsOffset, mask, getEdgeCount);
    setScoreByDetectedEdge(state, segments, edgeTypeScores);
  }

  const isProcessed = roofVertices.map(() => false);
  let level = 0;
  while (isProcessed.some((v) => !v)) {
    const levelRoofIndices = [];
    roofVertices.forEach((s, si) => {
      const oldProcessedState = isProcessed[si];
      isProcessed[si] = parents[si].every((pi) => isProcessed[pi]);
      if (isProcessed[si] && !oldProcessedState) {
        levelRoofIndices.push(si);
      }
    });
    const levelRoofs = levelRoofIndices.map((si) => roofVertices[si]);
    const levelIndicesMask = indices.map((g) => g.some(([pi]) => levelRoofIndices.includes(pi)));
    const levelIndices = indices.filter((_, i) => levelIndicesMask[i]);

    const lv = level;
    patterns.forEach((pattern) => {
      if ((!pattern.level || pattern.level === '*' || pattern.level === lv) && (!byDSM || pattern.byDSM)) {
        if (pattern.type === 'neighbours') {
          sortedNeighbours.forEach((ns, gi) => {
            if (levelIndicesMask[gi]) {
              const vertexEdgePatterns = extractNeighbourPattern(levelRoofs, indices, gi, ns, edgeTypeScores);
              vertexEdgePatterns.forEach((vertexEdgePattern) => {
                const [matches, matchesScores] = matchPatterns(vertexEdgePattern, pattern);
                matches.forEach((match, mi) => {
                  const matchScore = matchesScores[mi];
                  const queryIndex = pattern.edges.map((e) => e.edgeType === '?').indexOf(true);
                  const vertexEdgeIndex = match[queryIndex];
                  const matchedEdges = vertexEdgePattern[vertexEdgeIndex].edges;
                  matchedEdges.forEach(([matchedEdge]) => {
                    const scoreScale = getMatchScoreScale(pattern, vertexEdgePattern, match);
                    matchedEdge.edgeTypeScores[pattern.predict.toLowerCase()] += matchScore * pattern.predictScore * scoreScale;
                  });
                });
              });
            }
          });
        } else if (pattern.type === 'sequence') {
          levelRoofIndices.forEach((ri) => {
            const roof = roofVertices[ri];
            const vertexEdgePatterns = extractSequencePattern(roof, ri, edgeTypeScores);
            vertexEdgePatterns.forEach((vertexEdgePattern) => {
              const [matches, matchesScores] = matchPatterns(vertexEdgePattern, pattern);
              matches.forEach((match, mi) => {
                const matchScore = matchesScores[mi];
                const queryIndex = pattern.edges.map((e) => e.edgeType === '?').indexOf(true);
                const vertexEdgeIndex = match[queryIndex];
                const matchedEdge = vertexEdgePattern[vertexEdgeIndex].edge;
                const scoreScale = getMatchScoreScale(pattern, vertexEdgePattern, match);
                matchedEdge.edgeTypeScores[pattern.predict.toLowerCase()] += matchScore * pattern.predictScore * scoreScale;
              });
            });
          });
          if (pattern.boostEdgeType) {
            const pIndices = indices.map((group) => group.map(([pi]) => pi));
            indices.forEach((group, gi) => {
              neighbours[gi].forEach((neighbourIndex) => {
                const edges = commonEdges(gi, neighbourIndex, indices, pIndices);
                if (edges.length > 1) {
                  const scores = edges.map(([pi, vi, vj]) => {
                    const vk = (vi + 1) % roofVertices[pi].length === vj ? vi : vj;
                    return edgeTypeScores[pi][vk].edge.edgeTypeScores[pattern.predict.toLowerCase()];
                  });
                  const maxScore = Math.max(...scores);
                  edges.forEach(([pi, vi, vj]) => {
                    const vk = (vi + 1) % roofVertices[pi].length === vj ? vi : vj;
                    edgeTypeScores[pi][vk].edge.edgeTypeScores[pattern.predict.toLowerCase()] = maxScore;
                  });
                }
              });
            });
          }
        }
      }
    });

    level += 1;
  }

  if (byDSM) {
    applyPredictorByMaxScore(segments, state, edgeTypeScores);
  } else {
    const [candidates, candidateIndices] = getCandidateEdges(roofVertices, edgeTypeScores);
    applyPredictor(segments, state, candidates, candidateIndices);
    setAzimuthFromLineLabel(state, segments, true);
    let newState = mapTo3d(segments, state, center, updateStateFromSegments, getEdgeCount, false);
    let bestCost = unbalanceCost(segments, newState, resolution, scale);
    let found = false;
    let bestSegmentIndex = 0;
    let bestCandidateIndex = 0;
    let bestState = state;
    let nextState = state;
    do {
      found = false;
      for (let si = 0; si < segments.length; si += 1) {
        for (let ci = 1; ci < candidates[si].length; ci += 1) {
          const oldIndex = candidateIndices[si];
          candidateIndices[si] = ci;
          applyPredictor(segments, nextState, candidates, candidateIndices);
          newState = mapTo3d(segments, nextState, center, updateStateFromSegments, getEdgeCount, false);
          const cost = unbalanceCost(segments, newState, resolution, scale);
          if (cost < bestCost) {
            bestCost = cost;
            bestSegmentIndex = si;
            bestCandidateIndex = ci;
            bestState = newState;
            found = true;
          }
          candidateIndices[si] = oldIndex;
        }
      }
      if (found) {
        candidateIndices[bestSegmentIndex] = bestCandidateIndex;
        nextState = bestState;
      }
    } while (found);

    applyPredictor(segments, nextState, candidates, candidateIndices);
    mapTo3d(segments, nextState, center, updateStateFromSegments, getEdgeCount, true);
  }
};

export default patterns;
