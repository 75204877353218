import React, {
  useState, useMemo, useRef, useEffect, useContext, forwardRef, useImperativeHandle,
} from 'react';

import * as THREE from 'three';
import { DesignContext } from '../contexts/designContext';
import Shader from '../algorithms/shader';

const useShader = ({
  canvasPool,
  imgSrc,
  dsmTexture,
  minDSM,
  maxDSM,
  displacementBias,
  center,
  scale,
  coordinates,
  resolution,
  ranges,
  materials,
  weather,
}) => {
  const [currentShadingType, setShadingType] = useState('TSRF');
  const [shader] = useState(new Shader(resolution, scale, minDSM, maxDSM, displacementBias, dsmTexture, ranges, weather, center));
  let [renderer, setRenderer] = useState(null);

  const resetRenderer = () => {
    if (renderer) renderer.dispose();
    const canvas = document.createElement('canvas')
    renderer = new THREE.WebGLRenderer({
      canvas, antialias: false, alpha: true
    });
    renderer.outputEncoding = THREE.LinearEncoding;
    renderer.toneMapping = THREE.LinearToneMapping;
    renderer.toneMappingExposure = 1.2;
    
    setRenderer(renderer);
    shader.setRenderer(renderer);
  };

  useEffect(() => {
    shader.resolution = resolution;
    shader.scale = scale;
    shader.minDSM = minDSM;
    shader.maxDSM = maxDSM;
    shader.displacementBias = displacementBias;
    shader.dsmTexture = dsmTexture;
    shader.ranges = ranges;
    shader.weather = weather;
    shader.center = center;
  }, [shader, resolution, scale, minDSM, maxDSM, displacementBias, dsmTexture, ranges, weather, center]);

  useEffect(() => {
    shader.setCanvasPool(canvasPool);
  }, [shader, canvasPool]);

  return ({
    currentShadingType,
    setShadingType,
    getRenderTimes: shader.getRenderTimes,
    resetRenderer,
    shade: shader.shade,
    shadeDiffuse: shader.shadeDiffuse,
    changeSceneMaterials: shader.changeSceneMaterials,
    calculateShadingFactor: shader.calculateShadingFactor,
    // calculateSolarAccessTexture: shader.calculateSolarAccessTexture,
    calculateAverageTextures: shader.calculateAverageTextures,
    exportScene: shader.exportScene,
    dataToTexture: shader.dataToTexture,
    calculateSolarAccessData: shader.calculateSolarAccessData,
    exportSunPositionAndIntensity: shader.exportSunPositionAndIntensity,
    downloadContent: shader.downloadContent,
    ranges: shader.ranges,
  });
};

export default useShader;
