/**
 * Net Metering Calculations Utility
 * All calculations return detailed data for verification
 */

const ensureNumber = (value, defaultValue = 0) => {
    if (typeof value === 'string') {
        const parsed = parseFloat(value);
        return isNaN(parsed) ? defaultValue : parsed;
    }
    const num = Number(value);
    return isNaN(num) ? defaultValue : num;
};

/**
 * Calculate monthly bill without solar
 * @param {number} consumption - Monthly consumption in kWh
 * @param {number} utilityRate - Utility rate in $/kWh
 * @param {number} fixedCost - Fixed monthly cost
 * @returns {number} Monthly bill amount
 */
const calculateMonthlyBillWithoutSolar = (consumption, utilityRate, fixedCost) => {
    const rate = ensureNumber(utilityRate);
    const fixed = ensureNumber(fixedCost);
    return (consumption * rate) + fixed;
};

/**
 * Calculate post solar utility cost
 * @param {Object} params - Parameters for calculation
 * @param {number} params.systemSize - System size in kW
 * @param {number} params.costPerKw - Cost per kW
 * @param {number} params.costCap - Maximum cost cap
 * @param {number} params.constantCost - Constant cost value (if using constant method)
 * @param {boolean} params.useConstantCost - Whether to use constant cost method
 * @returns {number} Post solar utility cost
 */
const calculatePostSolarUtilityCost = ({
    systemSize,
    costPerKw,
    costCap,
    constantCost,
    useConstantCost
}) => {
    if (useConstantCost) {
        return ensureNumber(constantCost);
    }
    return Math.min(
        ensureNumber(systemSize) * ensureNumber(costPerKw),
        ensureNumber(costCap)
    );
};

/**
 * Calculate annual net metering with true-up
 */
const calculateAnnualNetMetering = ({
    monthlyProduction,
    monthlyConsumption,
    utilityRate,
    fixedCharge,
    postSolarFixedCharge,
    trueUpMonth = 3, // Default to April (0-based)
    offset_savings = false, // Add offset_savings parameter
    exportRate // Add export rate parameter
}) => {
    // Calculate annual totals
    const totalProduction = monthlyProduction.reduce((sum, prod) => sum + prod, 0);
    const totalConsumption = monthlyConsumption.reduce((sum, cons) => sum + cons, 0);
    const offsetPercentage = (totalProduction / totalConsumption) * 100;

    // Track energy bank through the year
    let energyBank = 0;

    // Reorder months to start from month AFTER true-up month
    const orderedMonths = Array.from({ length: 12 }, (_, i) => {
        const monthIndex = (i + trueUpMonth + 1) % 12;
        return {
            monthIndex,
            production: monthlyProduction[monthIndex],
            consumption: monthlyConsumption[monthIndex]
        };
    });

    // Calculate monthly results
    const monthlyResults = orderedMonths.map((month, i) => {
        const { monthIndex, production, consumption } = month;

        // Calculate net energy (always production - consumption)
        const netEnergy = production - consumption;

        // Update energy bank
        energyBank += netEnergy;

        // Calculate energy costs based on offset_savings flag
        let energyCost = 0;
        let exportCredit = 0;

        if (energyBank < 0) {
            // If we have a shortage, charge at base rate
            energyCost = Math.abs(energyBank) * utilityRate;
        } else if (offset_savings && energyBank > 0) {
            // If we have excess and offset_savings is true, credit at export rate
            exportCredit = energyBank * (exportRate || utilityRate);
        }

        // Monthly bill is fixed charge plus energy cost minus export credits
        const monthlyBill = postSolarFixedCharge + energyCost - exportCredit;

        // Calculate pre-solar bill
        const preSolarBill = (consumption * utilityRate) + fixedCharge;

        // On true-up month
        let lostCredits = 0;
        if (monthIndex === trueUpMonth) {
            if (energyBank > 0) {
                lostCredits = offset_savings ? 0 : energyBank; // Only count as lost if not using offset_savings
            }
            // Reset bank after true-up
            energyBank = 0;
        }

        return {
            month: monthIndex + 1, // Convert to 1-based month
            production,
            consumption,
            netEnergy,
            rollingCredit: energyBank,
            costs: {
                fixed: postSolarFixedCharge,
                energy: energyCost,
                export: exportCredit,
                total: monthlyBill
            },
            rates: {
                base: utilityRate,
                fixed: fixedCharge,
                export: exportRate || utilityRate,
                cost_cap: postSolarFixedCharge
            },
            preSolarBill,
            postSolarBill: monthlyBill,
            savings: preSolarBill - monthlyBill,
            isAnnualTrueUp: monthIndex === trueUpMonth,
            lostCredits,
            offsetPercentage
        };
    });

    // Reorder months back to calendar order (January to December)
    const calendarMonths = [
        ...monthlyResults.slice(12 - (trueUpMonth + 1)),
        ...monthlyResults.slice(0, 12 - (trueUpMonth + 1))
    ];

    // Calculate annual totals
    const annualFixedCosts = postSolarFixedCharge * 12;
    const totalPostSolarCost = annualFixedCosts; // Only fixed costs in net metering
    const totalPreSolarCost = (totalConsumption * utilityRate) + (fixedCharge * 12);

    return {
        months: calendarMonths,
        annual: {
            totalProduction,
            totalConsumption,
            offset: offsetPercentage,
            rollingCredit: energyBank,
            costs: {
                withSolar: totalPostSolarCost,
                withoutSolar: totalPreSolarCost,
                fixed: annualFixedCosts,
                energy: 0, // No energy charges in net metering
                savings: totalPreSolarCost - totalPostSolarCost
            },
            lostCredits: offsetPercentage >= 100 ? totalProduction - totalConsumption : 0
        }
    };
};

/**
 * Calculate monthly net metering
 * @param {Object} params - Calculation parameters
 * @returns {Object} Monthly net metering results
 */
const calculateMonthlyNetMetering = ({
    monthlyProduction,
    monthlyConsumption,
    utilityRate,
    exportRate,
    fixedSolarCost,
    systemSize,
    postSolarParams
}) => {
    const postSolarCost = calculatePostSolarUtilityCost(postSolarParams);

    const months = monthlyProduction.map((production, i) => {
        const consumption = monthlyConsumption[i];
        const netEnergy = production - consumption;

        let energyCost = 0;
        let exportCredit = 0;

        if (netEnergy < 0) {
            // Consumption exceeds production
            energyCost = Math.abs(netEnergy) * utilityRate;
        } else {
            // Production exceeds consumption
            exportCredit = netEnergy * exportRate;
        }

        return {
            month: i + 1,
            production,
            consumption,
            netEnergy,
            costs: {
                fixed: fixedSolarCost,
                energy: energyCost,
                export: exportCredit,
                total: fixedSolarCost + energyCost - exportCredit
            }
        };
    });

    const totalProduction = monthlyProduction.reduce((sum, p) => sum + p, 0);
    const totalConsumption = monthlyConsumption.reduce((sum, c) => sum + c, 0);

    return {
        months,
        annual: {
            totalProduction,
            totalConsumption,
            offset: (totalProduction / totalConsumption) * 100,
            costs: {
                withSolar: months.reduce((sum, m) => sum + m.costs.total, 0),
                withoutSolar: monthlyConsumption.reduce((sum, c) =>
                    sum + calculateMonthlyBillWithoutSolar(c, utilityRate, fixedSolarCost), 0)
            }
        }
    };
};

/**
 * Calculate net billing
 * @param {Object} params - Calculation parameters
 * @returns {Object} Net billing results
 */
const calculateNetBilling = ({
    monthlyProduction,
    monthlyConsumption,
    utilityRate,
    exportRate,
    fixedSolarCost,
    threshold = 40,
    systemSize,
    postSolarParams
}) => {
    // Ensure all rates are properly parsed numbers
    const parsedUtilityRate = ensureNumber(utilityRate);
    const parsedExportRate = ensureNumber(exportRate);
    const parsedFixedCost = ensureNumber(fixedSolarCost);

    console.log('Starting calculateNetBilling with parsed params:', {
        monthlyProduction: monthlyProduction[0], // Just log first month for brevity
        monthlyConsumption: monthlyConsumption[0],
        parsedUtilityRate,
        parsedExportRate,
        parsedFixedCost,
        threshold,
        systemSize
    });

    // Determine the fixed cost for post-solar
    const postSolarCost = postSolarParams?.useConstantCost ?
        ensureNumber(postSolarParams.net_billing_fixed_cost || postSolarParams.costCap || parsedFixedCost) :
        calculatePostSolarUtilityCost(postSolarParams);

    console.log('Post solar cost determined:', postSolarCost);

    const months = monthlyProduction.map((production, i) => {
        const consumption = ensureNumber(monthlyConsumption[i]);
        const thresholdDecimal = threshold / 100;

        // Calculate the threshold amount of consumption that can be offset
        const maxOffsetConsumption = consumption * thresholdDecimal;

        // Calculate self-consumption (limited by threshold)
        const selfConsumption = Math.min(production, maxOffsetConsumption);

        // Calculate excess production beyond threshold
        const excessProduction = Math.max(0, production - selfConsumption);

        // Calculate remaining consumption that needs to be paid at full rate
        const remainingConsumption = consumption - selfConsumption;

        // Calculate costs
        const remainingCost = remainingConsumption * parsedUtilityRate;
        const exportCredit = excessProduction * parsedExportRate;
        const totalCost = postSolarCost + remainingCost - exportCredit;
        const withoutSolarCost = calculateMonthlyBillWithoutSolar(consumption, parsedUtilityRate, parsedFixedCost);
        const savings = Math.max(0, withoutSolarCost - totalCost); // Ensure savings are never negative

        console.log(`Month ${i + 1} calculations:`, {
            production,
            consumption,
            threshold,
            maxOffsetConsumption,
            selfConsumption,
            excessProduction,
            remainingConsumption,
            costs: {
                postSolarCost,
                remainingCost,
                exportCredit,
                totalCost,
                withoutSolarCost,
                savings
            }
        });

        return {
            month: i + 1,
            production,
            consumption,
            details: {
                selfConsumption,
                maxOffsetConsumption,
                excessProduction,
                remainingConsumption,
                thresholdPercentage: threshold
            },
            costs: {
                fixed: postSolarCost,
                remaining: remainingCost,
                exportCredit: exportCredit,
                total: totalCost,
                withoutSolar: withoutSolarCost,
                savings: savings
            },
            rates: {
                base: parsedUtilityRate,
                fixed: parsedFixedCost,
                export: parsedExportRate,
                // Add cost cap related fields to monthly rates
                cost_cap: postSolarParams.costCap,
                fixed_cost_type: postSolarParams.useConstantCost ? 'constant' : 'system_size',
                net_billing_fixed_cost: postSolarParams.net_billing_fixed_cost,
                cost_per_kw: postSolarParams.costPerKw,
                systemSize: postSolarParams.systemSize,
                threshold: threshold
            }
        };
    });

    const totalProduction = monthlyProduction.reduce((sum, p) => sum + ensureNumber(p), 0);
    const totalConsumption = monthlyConsumption.reduce((sum, c) => sum + ensureNumber(c), 0);

    const withSolarCost = months.reduce((sum, m) => sum + m.costs.total, 0);
    const withoutSolarCost = months.reduce((sum, m) => sum + m.costs.withoutSolar, 0);
    const annualSavings = Math.max(0, withoutSolarCost - withSolarCost);

    const annualResults = {
        months,
        annual: {
            totalProduction,
            totalConsumption,
            offset: (totalProduction / totalConsumption) * 100,
            costs: {
                withSolar: withSolarCost,
                withoutSolar: withoutSolarCost,
                savings: annualSavings
            }
        }
    };

    console.log('Final annual results:', {
        totalProduction,
        totalConsumption,
        offset: annualResults.annual.offset,
        withSolarCost,
        withoutSolarCost,
        annualSavings
    });

    return annualResults;
};

export const calculateNetMetering = ({ monthlyProduction, systemSize, utility }) => {
    try {
        const baseRate = ensureNumber(utility.effectiveRate);
        const fixedCharge = ensureNumber(utility.fixedCharge);
        const postSolarFixedCharge = utility.net_metering_type === 'net_metering' ?
            ensureNumber(utility.net_metering_fixed_cost || utility.cost_cap || 22) : // Default to $22 for net metering
            ensureNumber(utility.net_billing_fixed_cost || utility.cost_cap);
        const escalationRate = ensureNumber(utility.escalation_rate, 0.03); // Default to 3% if not specified

        const monthlyConsumption = Array.isArray(utility.usage)
            ? utility.usage.map(usage => ensureNumber(usage))
            : new Array(12).fill(ensureNumber(utility.totalUsage) / 12);

        // Calculate first year based on metering type
        let firstYearResults;
        if (utility.net_metering_type === 'net_metering') {
            firstYearResults = calculateAnnualNetMetering({
                monthlyProduction,
                monthlyConsumption,
                utilityRate: baseRate,
                fixedCharge,
                postSolarFixedCharge,
                trueUpMonth: utility.true_up_month ? parseInt(utility.true_up_month) - 1 : 3,
                offset_savings: utility.offset_savings,
                exportRate: utility.export_rate
            });
        } else {
            // Net billing calculation remains the same
            firstYearResults = calculateNetBilling({
                monthlyProduction,
                monthlyConsumption,
                utilityRate: baseRate,
                exportRate: utility.export_rate,
                fixedSolarCost: fixedCharge,
                threshold: utility.net_billing_threshold_value || 40,
                systemSize,
                postSolarParams: {
                    useConstantCost: utility.fixed_cost_type === 'constant',
                    net_billing_fixed_cost: utility.net_billing_fixed_cost,
                    costCap: utility.cost_cap || utility.net_billing_fixed_cost,
                    systemSize,
                    costPerKw: utility.cost_per_kw
                }
            });
        }

        // Transform first year results to match expected structure
        const firstYear = {
            year: 1,
            totalProduction: firstYearResults.annual.totalProduction,
            totalConsumption: firstYearResults.annual.totalConsumption,
            netMetered: firstYearResults.annual.totalProduction - firstYearResults.annual.totalConsumption,
            savings: firstYearResults.annual.costs.withoutSolar - firstYearResults.annual.costs.withSolar,
            months: firstYearResults.months.map(month => ({
                month: month.month,
                production: month.production,
                consumption: month.consumption,
                netMetered: month.netEnergy,
                rollingCredit: month.rollingCredit,
                costs: month.costs,
                rates: {
                    base: baseRate,
                    fixed: fixedCharge,
                    cost_cap: postSolarFixedCharge
                },
                preSolarBill: month.preSolarBill,
                postSolarBill: month.postSolarBill,
                savings: month.savings
            }))
        };

        // Initialize results structure
        const results = {
            annual: [firstYear],
            metadata: {
                systemSize: ensureNumber(systemSize),
                utilityRates: {
                    base: baseRate,
                    fixed: fixedCharge,
                    fixedCharge: fixedCharge,
                    export: utility.export_rate,
                    escalation: escalationRate,
                    // Add cost cap related fields
                    cost_cap: utility.cost_cap ? ensureNumber(utility.cost_cap) : null,
                    fixed_cost_type: utility.fixed_cost_type,
                    net_billing_fixed_cost: utility.net_billing_fixed_cost ? ensureNumber(utility.net_billing_fixed_cost) : null,
                    cost_per_kw: utility.cost_per_kw ? ensureNumber(utility.cost_per_kw) : null,
                    threshold: utility.net_billing_threshold_value ? ensureNumber(utility.net_billing_threshold_value) : null
                },
                meteringType: utility.net_metering_type,
                meteringPeriod: utility.metering_period,
                trueUpMonth: utility.true_up_month,
                calculationTimestamp: new Date().toISOString()
            }
        };

        let totalProduction = firstYear.totalProduction;
        let totalConsumption = firstYear.totalConsumption;
        let totalNetMetered = firstYear.netMetered;
        let totalSavings = firstYear.savings;

        // Calculate for remaining 29 years
        for (let year = 2; year <= 30; year++) {
            // Apply degradation to production (0.5% per year)
            const degradation = Math.pow(0.995, year - 1);
            const yearlyProduction = monthlyProduction.map(prod => prod * degradation);

            // Apply rate escalation
            const escalatedBaseRate = baseRate * Math.pow(1 + escalationRate, year - 1);
            const escalatedExportRate = utility.export_rate * Math.pow(1 + escalationRate, year - 1);

            // Calculate for this year
            let yearResults;
            if (utility.net_metering_type === 'net_metering') {
                yearResults = calculateAnnualNetMetering({
                    monthlyProduction: yearlyProduction,
                    monthlyConsumption,
                    utilityRate: escalatedBaseRate,
                    fixedCharge,
                    postSolarFixedCharge,
                    trueUpMonth: utility.true_up_month ? parseInt(utility.true_up_month) - 1 : 3,
                    offset_savings: utility.offset_savings,
                    exportRate: escalatedExportRate
                });
            } else {
                yearResults = calculateMonthlyNetMetering({
                    monthlyProduction: yearlyProduction,
                    monthlyConsumption,
                    utilityRate: escalatedBaseRate,
                    exportRate: escalatedExportRate,
                    fixedSolarCost: fixedCharge,
                    systemSize
                });
            }

            const yearData = {
                year,
                totalProduction: yearResults.annual.totalProduction,
                totalConsumption: yearResults.annual.totalConsumption,
                netMetered: yearResults.annual.totalProduction - yearResults.annual.totalConsumption,
                savings: yearResults.annual.costs.withoutSolar - yearResults.annual.costs.withSolar,
                months: yearResults.months.map(month => ({
                    month: month.month,
                    production: month.production,
                    consumption: month.consumption,
                    netMetered: month.netEnergy,
                    rates: {
                        base: escalatedBaseRate,
                        fixed: fixedCharge,
                        export: escalatedExportRate,
                        // Add cost cap related fields for future years
                        cost_cap: utility.cost_cap ? ensureNumber(utility.cost_cap) : null,
                        fixed_cost_type: utility.fixed_cost_type,
                        net_billing_fixed_cost: utility.net_billing_fixed_cost ? ensureNumber(utility.net_billing_fixed_cost) : null,
                        cost_per_kw: utility.cost_per_kw ? ensureNumber(utility.cost_per_kw) : null,
                        systemSize: systemSize,
                        threshold: utility.net_billing_threshold_value ? ensureNumber(utility.net_billing_threshold_value) : null
                    },
                    savings: month.costs ?
                        calculateMonthlyBillWithoutSolar(month.consumption, escalatedBaseRate, fixedCharge) - month.costs.total :
                        0
                }))
            };

            totalProduction += yearData.totalProduction;
            totalConsumption += yearData.totalConsumption;
            totalNetMetered += yearData.netMetered;
            totalSavings += yearData.savings;

            results.annual.push(yearData);
        }

        // Add lifetime totals
        results.lifetime = {
            totalProduction,
            totalConsumption,
            totalNetMetered,
            totalSavings,
            averageMonthlyProduction: totalProduction / (12 * 30),
            averageMonthlyConsumption: totalConsumption / (12 * 30)
        };

        return results;

    } catch (error) {
        console.error('Error in net metering calculation:', error);
        throw error;
    }
};

/**
 * Calculate detailed billing breakdown
 * @param {Object} netMeteringData - Results from calculateNetMetering
 * @param {Object} utility - Utility information
 * @returns {Object} Detailed billing breakdown
 */
export const calculateBilling = (netMeteringData, utility) => {
    console.log('Starting billing calculation with:', {
        netMeteringData,
        utility
    });

    try {
        // Ensure we have valid data
        if (!netMeteringData || !netMeteringData.annual || !Array.isArray(netMeteringData.annual) || !netMeteringData.annual[0]?.months) {
            throw new Error('Invalid net metering data structure');
        }

        const fixedCharge = ensureNumber(utility.fixedCharge);
        const baseRate = ensureNumber(utility.effectiveRate);
        const exportRate = ensureNumber(utility.export_rate);
        const isNetMetering = utility.net_metering_type === 'net_metering';
        const isAnnual = utility.metering_period === 'annual';
        const thresholdValue = utility.net_billing_threshold_value ?
            ensureNumber(utility.net_billing_threshold_value) : null;

        // Process first year's monthly data
        const firstYear = netMeteringData.annual[0];
        const processedMonths = firstYear.months.map(month => {
            let energyCost = 0;
            let exportCredit = 0;

            if (isNetMetering) {
                if (isAnnual && month.rollingCredit !== undefined) {
                    // For annual net metering, use rolling credit
                    energyCost = month.rollingCredit < 0 ?
                        Math.abs(month.rollingCredit) * baseRate : 0;
                } else {
                    // Monthly net metering
                    const netExport = month.netMetered;
                    energyCost = netExport < 0 ?
                        Math.abs(netExport) * baseRate : 0;
                    exportCredit = netExport > 0 ? netExport * baseRate : 0;
                }
            } else {
                // Net Billing Logic
                const production = month.production;
                const consumption = month.consumption;

                // Calculate self-consumption and exports
                const selfConsumed = Math.min(production, consumption);
                const exported = Math.max(0, production - consumption);
                const remainingConsumption = Math.max(0, consumption - selfConsumed);

                energyCost = remainingConsumption * baseRate;
                exportCredit = exported * exportRate;
            }

            const total = energyCost + fixedCharge - exportCredit;

            return {
                month: month.month,
                energy: energyCost,
                fixed: fixedCharge,
                exportCredit: exportCredit,
                total: total,
                details: {
                    production: month.production,
                    consumption: month.consumption,
                    netExport: month.netEnergy,
                    baseRate: baseRate,
                    exportRate: exportRate
                }
            };
        });

        // Create billing structure
        const billing = {
            withSolar: netMeteringData.annual.map(year => ({
                year: year.year,
                monthly: year.year === 1 ? processedMonths : undefined,
                annual: year.savings
            })),
            withoutSolar: netMeteringData.annual.map(year => ({
                year: year.year,
                monthly: year.year === 1 ? year.months.map(month => ({
                    month: month.month,
                    energy: month.consumption * baseRate,
                    fixed: fixedCharge,
                    total: (month.consumption * baseRate) + fixedCharge,
                    details: {
                        consumption: month.consumption,
                        rateApplied: baseRate
                    }
                })) : undefined,
                annual: year.totalConsumption * baseRate + (fixedCharge * 12)
            }))
        };

        return {
            billing,
            metadata: {
                calculationTimestamp: new Date().toISOString(),
                utilityInfo: {
                    meteringType: utility.net_metering_type,
                    meteringPeriod: utility.metering_period,
                    trueUpMonth: utility.true_up_month,
                    rates: {
                        base: baseRate,
                        fixed: fixedCharge,
                        export: exportRate
                    },
                    thresholdValue: thresholdValue
                }
            }
        };
    } catch (error) {
        console.error('Error in billing calculation:', error);
        throw error;
    }
};

/**
 * Validate calculation results
 * @param {Object} results - Results from either calculation function
 * @returns {Object} Validation results
 */
export const validateCalculations = (results) => {
    const validation = {
        isValid: true,
        warnings: [],
        errors: []
    };

    if (!results.metadata) {
        validation.errors.push('Missing metadata');
        validation.isValid = false;
    }

    if (!results.annual || !Array.isArray(results.annual)) {
        validation.errors.push('Missing or invalid annual data');
        validation.isValid = false;
    }

    if (results.annual && results.annual.some(year =>
        !year.months || !Array.isArray(year.months) || year.months.length !== 12
    )) {
        validation.errors.push('Invalid monthly data structure');
        validation.isValid = false;
    }

    return validation;
}; 