import {
  useState, useEffect, useContext,
} from 'react';

import * as THREE from 'three';

import { DesignContext } from '../contexts/designContext';

import {
  pitchMap,
  isClockWise,
  dist,
  counterClockWise,
  calculateRoll,
  expandPolygon,
  expandPolygonWithRoll,
  calculatePolygonZdim,
  pointInConvexPolygon,
  calculateRoofHeight,
  calculateRoofHeight2,
  optimizeVertexPosition,
  getEaveIndices,
  getEave,
  shrinkPolygon,
  getParentsPolygon,
  segmentProperties,
  pointInConcavePolygon,
  projectTo3DFromOrgVertices,
  calculateEave,
} from '../algorithms/geometry';

import {
  reconstruct3d,
  magnetizeVertices,
  updateStateFromSegments,
  getBorders,
  getSetbackList,
  getInchePerUnit,
  getFootPerUnit,
  sceneUnitToFoot,
  sceneUnitToInche,
  footToSceneUnit,
} from '../algorithms/3DReconstruction';

export const use3DReconstruction = (segments, obstacles, texture, visible, readonly, mode) => {
  const {
    resolution,
    panelType,
    buffer,
    setback,
    selectionMode,
    setSelectionMode,
    selectedObjects,
    removeFromSelectedObjects,
    addToSelectedObjects,
    clearSelection,
    deleteSelectedObjects,
    setHeight,
    setPitch,
    setSetback,
    setSegmentPosition,
    setPanelPlacement,
    moveRoofVertex,
    coordinates,
    setSegmentShadingFactors,
    accordion,
    setShadingTexture,
    setPanelsOrientation,
  } = useContext(DesignContext);

  const [{
    orgVertices, vertices, vertices3D, verticesType, eaves, pitches, azimuths, eaveHeights, UV, changeClockwise, ranges, parents,
  }, setVertices] = useState({
    orgVertices: [],
    vertices: [],
    vertices3D: [],
    verticesType: [],
    eaves: [],
    pitches: [],
    azimuths: [],
    eaveHeights: [],
    UV: [],
    changeClockwise: [],
    ranges: [],
    parents: [],
  });

  const scale = 0.2;
  const [[cx, cy, c], setCenter] = useState([0, 0, 1]);

  const inchePerUnit = getInchePerUnit(resolution);
  const footPerUnit = getFootPerUnit(resolution);
  const getFootPerSceneUnit = (unit) => sceneUnitToFoot(resolution, scale, unit);
  const getInchePerSceneUnit = (unit) => sceneUnitToInche(resolution, scale, unit);
  const getSceneUnitPerFoot = (unit) => footToSceneUnit(resolution, scale, unit);

  // const updateParents = (state) => {
  //   state.parents = [];
  //   state.orgVertices
  //     .forEach((poly, polyIndex) => {
  //       const isObstacle = state.verticesType[polyIndex] === 'obstacle';
  //       const parentSegs = getParentsPolygon(state.orgVertices, polyIndex, !isObstacle);
  //       state.parents.push(parentSegs);
  //     });
  // };

  useEffect(() => {
    const oldState = {
      orgVertices, vertices, vertices3D, verticesType, eaves, pitches, azimuths, eaveHeights, UV, changeClockwise, ranges, parents, center: [cx, cy, c], mode,
    };

    const { width, height } = texture?.image ?? { width: 0, height: 0 };

    const newState = reconstruct3d(segments, obstacles, height, width, scale, resolution, oldState);

    // magnetizeVertices(segments);
    // updateXYRanges(newState);
    // const [ncx, ncy, nc] = getCenter(newState);
    // const obstacleSegments = getObstacleSegments();
    // updateStateFromSegments(segments.concat(obstacleSegments), newState, oldState, ncx, ncy, nc);
    // projectTo3DFromOrgVertices(newState);
    // preserveOnlyChanges(newState, oldState);
    // updateZRanges(newState);
    setCenter(newState.center);
    setVertices(newState);
    if (!readonly) {
      setPanelsOrientation(segments);
    }
  }, [segments, obstacles, texture, resolution, visible, mode]);

  const getSetbacks = (poly, index) => {
    if (verticesType[index] === 'roof') return getSetbackList(segments, changeClockwise, setback, inchePerUnit, scale, poly, index);
    return (obstacles[index - segments.length].setback / inchePerUnit) * scale * (obstacles[index - segments.length].shape === 'Line' ? -1 : 1);
    // const setbacks = segments[index]?.setbacks?.map((sb) => ((sb / inchePerUnit) * scale)) ?? poly.map(() => (setback / inchePerUnit) * scale);
    // if (changeClockwise[index]) {
    //   // const temp = setbacks[setbacks.length - 1];
    //   // setbacks.pop();
    //   setbacks.reverse();
    //   // setbacks.push(temp);
    // }
    // return setbacks;
  };

  return (
    {
      verticesInfo: {
        orgVertices, vertices, vertices3D, verticesType, eaves, pitches, azimuths, eaveHeights, UV, changeClockwise, ranges, parents,
      },
      scale,
      cx,
      cy,
      c,
      inchePerUnit,
      footPerUnit,
      getFootPerSceneUnit,
      getInchePerSceneUnit,
      getSceneUnitPerFoot,
      getSetbacks,
      magnetizeVertices,
      updateStateFromSegments,
      getBorders,
    }
  );
};

export default use3DReconstruction;
