import React, {
  useState, useContext, useRef, useEffect, useMemo, forwardRef, useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { LeadContext } from 'Leads/Details/hooks/context';
import { AuthContext } from 'api/context';
import AuthManager, { client } from 'api/auth';
import DesignTool from 'Leads/Details/DesignTool';
import Loading from 'components/Loading';
import IFrameLogo from 'components/IFrameLogo';

const Designer = forwardRef(({ visible, callback, context, companyName, designFrame }, ref) => {
  const { uid, lead, newEndpoint, predictAPI } = useContext(LeadContext);
  const { displaySnack, getTaskStatus } = useContext(AuthContext);
  const [auth] = useState(AuthManager);

  const newLeadApi = newEndpoint('iframe/lead/');

  const designer = useRef();
  const history = useHistory();

  const [createLeadInterval, setCreateLeadInterval] = useState();
  const [runAiInterval, setRunAiInterval] = useState();
  const [fetchingImagery, setFetchingImagery] = useState();

  const designerCallback = (callbackType, data) => {
    if (window.parent) window.parent.postMessage({ callbackType, data }, '*');
    callback(callbackType, data);
  };

  const displayValidationErrors = (errors) => {
    const err = String(errors);
    let errMsg = err.split('At path:')[1];
    errMsg = errors.message.split('--')[1].trim();
    if (errors.key === 'coordinates') {
      errMsg = errMsg.replace('`/^((-?|\\+?)?\\d+(\\.\\d+)?),\\s*((-?|\\+?)?\\d+(\\.\\d+)?)$/`', '`lat, lng` format');
    }
    const msg = `${errors.key}: ${errMsg}`;
    // displaySnack({ variant: 'danger', message: msg });
    designerCallback('Error', { code: 11, message: msg });
  };

  const callbackReady = () => {
    if (window.parent) window.parent.postMessage({ callbackType: 'Ping', data: 'Pong' }, '*');
    callback('Ping', 'Pong');
  };

  const setLead = (leadId) => {
    localStorage.setItem('lead-id', leadId);
  };

  const getLead = () => {
    const leadId = localStorage.getItem('lead-id');
    return leadId;
  };

  const eraseLead = () => {
    localStorage.setItem('lead-id', null);
  };

  const trackTask = (taskId) => {
    if (taskId === undefined) return;
    getTaskStatus(taskId).then(({ state }) => {
      console.log(taskId, state);
      if (state === 'STARTED') return;
      setCreateLeadInterval((interval) => clearInterval(interval));
      setRunAiInterval((interval) => clearInterval(interval));
      if (state === 'SUCCESS') {
        // displaying lead id after the task has finished
        const leadId = getLead();
        // displaySnack({ variant: 'info', message: `Lead created: ${leadId}` });
        designerCallback('NewLead', leadId);
        eraseLead();
      } else { // PENDING / FAILURE
        // displaySnack({ variant: 'danger', message: 'An error was encountered.' });
        designerCallback('Error', { code: 20, message: 'Could not finish the design' });
      }
    });
  };

  const loadNewLead = (id, chain = false) => {
    // only used when NewLead is handled in iframe
    window.location.replace(`/iframe/${id}?chain=${chain ? 'true' : 'false'}`);
    // history.push(`/iframe/${id}`);
  };

  const loadLeadPanelPlacement = (id) => {
    history.push(`/iframe/${id}`);
  };

  const loadLead = (id) => {
    // used in designer iframe to load a lead
    window.location.replace(`/iframe/${id}?design=true`);
  };

  const checkCoverage = ({ coordinates }) => {
    const api_key = auth.getApiKey();
    const headers = {
      Authorization: `Api-Key ${api_key}`,
    };

    client.post('iframe/coverage/', {
      coords: coordinates,
    }, {
      headers,
    }).then((data) => {
      const res = data.data;
      designerCallback('coverage', res);
    }).catch((err) => {
      const { code, msg } = err.response.data;
      designerCallback('Error', { code, message: msg });
    });
  };

  const newLead = ({
    contacts, coordinates, electricity_usage, property_profile, utility, monthly_bill, type = 'AI', rgb = '', dsm = '', resolution = 7.5,
  }) => {
    console.log('inputs:', coordinates, electricity_usage);

    /**
     * This is where Api-Key is formatted in request header
     * The Api key is assumably arg of one of iFrame methods, and its value should be accessible here.
     */

    const api_key = auth.getApiKey();
    const headers = {
      Authorization: `Api-Key ${api_key}`,
    };

    setFetchingImagery(true);

    client.post('iframe/lead/', {
      contacts, coordinates, electricity_usage, utility, monthly_bill, type, rgb, dsm, resolution, property_profile
    }, {
      headers,
    })
      .then((data) => {
        setFetchingImagery(false);
        const res = data.data;
        setLead(res.lead);
        // display task-related UI
        if (res.task) {
          // displaySnack({ variant: 'info', message: 'Creating lead' });
          const interval = setInterval(() => trackTask(res.task), 1500);
          setCreateLeadInterval(interval);
        } else {
          const leadId = res.lead;
          // displaySnack({ variant: 'info', message: `Lead created: ${leadId}` });
          designerCallback('NewLead', leadId);
          setTimeout(loadLead(leadId), 1000);
        }
      }).catch((err) => {
        setFetchingImagery(false);
        // displaySnack({ variant: 'danger', message: 'Error in creating lead' });
        try {
          console.log(err.response);
          designerCallback('Error', { code: err.response.data.code, message: err.response.data.msg });
        } catch (responseErr) {
          console.log('after error in django:', responseErr);
        }
      });
  };

  const runPrediction = (params) => {
    const request = {
      uid: params.uid,
      design_type: 'A',
      no_refinement: 'F',
    };

    const api_key = auth.getApiKey();
    const headers = {
      Authorization: `Api-Key ${api_key}`,
    };

    client.post('crm/lead/predict/', request, { headers })
      .then((data) => {
        const res = data.data;
        setLead(params.uid);
        // display task-related UI
        if (res.task) {
          // displaySnack({ variant: 'info', message: 'Processing lead' });
          const interval = setInterval(() => trackTask(res.task), 1500);
          setRunAiInterval(interval);
        }
      }).catch(() => {
        // displaySnack({ variant: 'danger', message: 'Prediction not started.' });
      });
  };

  const handleMessage = (event) => {
    const { cmd, params = null } = event.data;
    switch (cmd) {
      case 'designer.LoadLead':
        if (params) {
          loadLead(params.uid);
        }
        break;
      case 'designer.NewLead':
        if (params) newLead(params);
        break;
      case 'designer.FullScreen':
        if (designer.current) designer.current.goFullScreen(true);
        break;
      case 'designer.2D':
        if (designer.current) designer.current.switchView('2D');
        break;
      case 'designer.3D':
        if (designer.current) designer.current.switchView('3D');
        break;
      case 'designer.3DJson':
        if (designer.current) designer.current.handleDownload3DJSON();
        break;
      case 'designer.PanelPlacement':
        if (designer.current) {
          designer.current.handleCurrentAccordion('revise');
          designer.current.handlePlacement(params);
        }
        break;
      case 'designer.Shading':
        if (designer.current) {
          designer.current.handleCurrentAccordion('shading');
          designer.current.handleShading();
        }
        break;
      case 'designer.Measure':
        if (designer.current) {
          designer.current.handleCurrentAccordion('segments');
          designer.current.handleMeasure();
        }
        break;
      case 'designer.EdgeLabeling':
        if (designer.current) {
          designer.current.handleCurrentAccordion('segments');
          designer.current.runEdgeDetectionHandler(true);
        }
        break;
      case 'designer.Optimize':
        if (designer.current) {
          designer.current.handleCurrentAccordion('segments');
          designer.current.runOptimizationHandler(false);
        }
        break;
      case 'designer.Clear':
        if (!designer.current) break;
        designer.current.handleCurrentAccordion('segments');
        designer.current.clearPanels();
        designer.current.clearMeasurment();
        designer.current.clearLabelsHandler();
        break;
      case 'designer.CreateProposal':
        if (!designer.current) break;
        designer.current.handleProposal(params);
        break;
      case 'designer.ShadingValues':
        if (!designer.current) break;
        designer.current.shadingValues();
        break;
      default:
        break;
    }
  };

  useImperativeHandle(ref, () => ({
    uid,
    lead,
    designer,
    handleMessage,
    checkCoverage,
    newLead,
    displayValidationErrors,
    callbackReady,
    loadLead,
    loadNewLead,
    loadLeadPanelPlacement,
    runPrediction,
    checkIsLoaded: designer.current?.checkIsLoaded,
    checkIsShadingRunning: designer.current?.checkIsShadingRunning,
    checkIsPVWattsHandled: designer.current?.checkIsPVWattsHandled,
    handleCurrentAccordion: designer.current?.handleCurrentAccordion,
    handlePlacement: designer.current?.handlePlacement,
    handlePVWatts: designer.current?.handlePVWatts,
    handleShading: designer.current?.handleShading,
    handleMeasure: designer.current?.handleMeasure,
    runEdgeDetectionHandler: designer.current?.runEdgeDetectionHandler,
    runOptimizationHandler: designer.current?.runOptimizationHandler,
    handleProposal: designer.current?.handleProposal,
  }));

  const iframeDesigner = useMemo(() => {
    if (!lead) return null;
    return (<DesignTool ref={designer} context={context} iframe designFrame={designFrame} companyName={companyName} callback={designerCallback} />);
  }, [uid, lead, context]);

  if (fetchingImagery || createLeadInterval || runAiInterval) {
    return (
      <>
        <Loading title="Processing..." />
        {(companyName !== 'Astrawatt') && (companyName !== 'demand-iq') && <IFrameLogo textColor="dark" />}
      </>
    );
  }
  if (!visible) return null;
  return iframeDesigner;
});

Designer.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default Designer;
