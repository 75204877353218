import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from './panel.module.css';

function Panel({ panel }) {
  return (
    <div className="mx-auto">
      <div className={styles.logo}>
        <img src={panel.manufacturer.logo} alt="logo" width="90%" />
      </div>
      <div className={styles.info}>
        <Row className="mt-3">
          <Col>
            <strong>Model: </strong>
            {panel.model}
          </Col>
        </Row>
        <Row className="">
          <Col>
            <strong>Power: </strong>
            {`${panel.power} W`}
          </Col>
        </Row>
        <Row className="">
          <Col>
            <strong>Dimensions: </strong>
            {`${panel.length}mm x ${panel.width}mm`}
          </Col>
        </Row>
        <Row className="">
          <Col>
            <strong>Efficiency: </strong>
            {`${panel.efficiency}%`}
          </Col>
        </Row>
        <Row className="">
          <Col>
            <strong>Degradation: </strong>
            {`${panel.degradation}%`}
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Panel;
