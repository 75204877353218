import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from '@reactchartjs/react-chart.js';

function PaybackChart({ graphData }) {
  // console.log(this.props.graphData);
  const data = {
    labels: Array(25).fill().map((_, i) => i + 1),
    datasets: [
      {
        label: 'savings',
        data: graphData,
        backgroundColor: 'black',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: 'Number of years',
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: 'Dollar Amount',
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        title: (item) => `Year ${item[0].label}`,
        label: (item) => item.yLabel.toFixed(2),
      },
    },
  };
  return (
    <div id="paybackChart">
      <Bar redraw={false} data={data} options={options} />
    </div>
  );
}

PaybackChart.propTypes = {
  graphData: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default PaybackChart;
