import * as THREE from 'three';

import {
  pitchMap,
  isClockWise,
  dist,
  counterClockWise,
  calculateRoll,
  expandPolygon,
  expandPolygonWithRoll,
  calculatePolygonZdim,
  pointInConvexPolygon,
  pointInConcavePolygon,
  calculateRoofHeight,
  optimizeVertexPosition,
  getEaveIndices,
  getEave,
  shrinkPolygon,
  borderPolygon,
  getParentsPolygon,
  segmentProperties,
  projectToLine,
  protectMethod,
} from '../algorithms/geometry.js';

const getSegmentRange = (orgVertices, eaves, scale, index) => {
  // const cw = changeClockwise[index];
  const z = new THREE.Vector3(0, 0, 1);
  const geo = orgVertices[index];
  const [ea, eb, et, ai, bi] = eaves[index];
  let a;
  let b;
  if (ai || bi) {
    a = geo[ai % geo.length]; // new THREE.Vector3(geo[ai][0], geo[ai][1], 0);
    b = geo[bi % geo.length]; // new THREE.Vector3(geo[bi][0], geo[bi][1], 0);
  } else if (et === 'eave') {
    a = ea;
    b = eb;
  } else {
    a = eb;
    b = ea;
  }
  const xdir = b.clone().sub(a);
  const ydir = xdir.clone().cross(z);
  const e = a.clone().add(ydir);
  xdir.normalize();
  ydir.normalize();
  let [minx, maxx, miny, maxy] = [0, 0, 0, 0];
  geo.forEach((p) => {
    // const p = new THREE.Vector3(x, y, 0);
    const [p1] = projectToLine([a, b], p);
    const l1 = p1.clone().sub(a).length();
    if (p1.clone().sub(a).dot(xdir) > 0) maxx = Math.max(l1, maxx);
    else minx = Math.max(l1, minx);
    const [p2] = projectToLine([a, e], p);
    const l2 = p2.clone().sub(a).length();
    if (p2.clone().sub(a).dot(ydir) < 0) maxy = Math.max(l2, maxy);
    else miny = Math.max(l2, miny);
  });
  return [minx / scale, maxx / scale, maxy / scale, miny / scale];
};

const cartesian = (...a) => a.reduce((i, b) => i.flatMap((d) => b.map((e) => [d, e].flat())));

const getRoofObstacles = (orgVertices, index, getSetbacks) => {
  const obstacleIndices = orgVertices
    .map((_, oi) => [oi, getParentsPolygon(orgVertices, oi, false).includes(index)])
    .filter(([, isChild]) => isChild).map(([oi]) => oi);
  if (obstacleIndices.length) {
    const regions = obstacleIndices.map((oi) => {
      const setback = getSetbacks(orgVertices[oi], oi);
      if (setback.length || setback == 0) return [];
      return borderPolygon(orgVertices[oi], Math.min(Math.abs(setback), 1), Math.abs(setback), setback > 0);
    }).flat();
    const obstacle = obstacleIndices.map((oi) => {
      const setback = getSetbacks(orgVertices[oi], oi);
      if (setback < 0) return null;
      return orgVertices[oi];
    }).filter((o) => o != null);
    return [...obstacle,...regions];
  }
  return [];
};

export const initializePanel = (s, pitch, tilt, panelType, resolution) => {
  const ph = panelType.length;
  const pw = panelType.width;
  const panelLength = (parseFloat(ph) / parseFloat(resolution)) / 10;
  const panelWidth = (parseFloat(pw) / parseFloat(resolution)) / 10;
  const theta = pitch + tilt
  const panelHScale = theta ? Math.sqrt(1 + Math.tan(theta * (Math.PI / 180.0)) ** 2) : 1;
  return (
    {
      id: s.id,
      azimuth: s.azimuth,
      tilt: tilt,
      horizontal:
      {
        points: [],
        height: panelWidth / panelHScale,
        width: panelLength,
        selected: [],
      },
      vertical:
      {
        points: [],
        height: panelLength / panelHScale,
        width: panelWidth,
        selected: [],
      },
    }
  );
};

export const initializePanels = (segments, pitches, tilt, panelType, resolution) => segments.map((s, si) => initializePanel(s, pitches[si], tilt, panelType, resolution));

export const getPanelData = (panels, segments, pitches, vertices, eaves, eaveHeights, cx, cy, scale) => {
  const c = 1.0;
  const getPoint = (x, y) => [(x - cx / c) * scale, (y - cy / c) * scale];
  const projectPanel = (pi, pts) => {
    if (!(pi in eaves && pi in pitches)) return [];
    const projectedPnts = expandPolygon(pts.map((v) => new THREE.Vector3(v[0], v[1], 0)), eaves[pi], pitches[pi]);
    return pts.map((v, vi) => [projectedPnts[vi].x, projectedPnts[vi].y, v[2], v[3], v[4]]);
  };

  const getPoints = (p) => {
    try{
      return p.horizontal.points.map(([x, y]) => [...getPoint(x, y), p.horizontal.height * scale, p.horizontal.width * scale, 'h'])
      .concat(p.vertical.points.map(([x, y]) => [...getPoint(x, y), p.vertical.height * scale, p.vertical.width * scale, 'v']));
    } catch (err) {
      return [];
    }
  };

  const newPanels = panels.map((p, pi) => {
    const tilt = p.tilt ?? 0;
    const theta = pitches[pi] + tilt;
    return ({
      id: p.id,
      azimuth: p.azimuth ?? 0,
      azimuthShift: (segments[pi]?.azimuth ?? 0) - (p.azimuth ?? 0),
      tilt: tilt,
      scale: theta ? Math.sqrt(1 + Math.tan(theta * (Math.PI / 180.0)) ** 2) : 1,
      points: getPoints(p),
      projectedPoints: [],
      points3D: [],
    });
  });

  const mapTo3D = (pi, pts) => {
    if (!(pi in eaves && pi in pitches)) return [];
    const [a, b,, ai, bi] = eaves[pi];
    if (!(ai in vertices[pi] && bi in vertices[pi])) return [];
    const azimuthDir = b.clone().sub(a).normalize();
    const edgeDir = vertices[pi][bi].clone().sub(vertices[pi][ai]).normalize();
    const alpha = Math.atan2(azimuthDir.y, azimuthDir.x) - Math.atan2(edgeDir.y, edgeDir.x);
    const mrotate = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(0, 0, 1), -alpha);
    const z = new THREE.Vector3(0, 0, 1);
    const eaveDir = eaves[pi][0].clone().sub(eaves[pi][1]).normalize();
    const ndir = eaveDir.clone().cross(z).normalize().negate();
    return pts.map((v, vi) => {
      const up = ndir.clone().multiplyScalar(newPanels[pi].points[vi][2]).applyMatrix4(mrotate);
      const left = eaveDir.clone().multiplyScalar(newPanels[pi].points[vi][3]).applyMatrix4(mrotate);
      const box = [
        new THREE.Vector3(v[0], v[1], 0),
        new THREE.Vector3(v[0], v[1], 0).add(up),
        new THREE.Vector3(v[0], v[1], 0).add(up).add(left),
        new THREE.Vector3(v[0], v[1], 0).add(left),
      ];
      return calculatePolygonZdim(box, eaves[pi], pitches[pi], eaveHeights[pi]);
    });
  };

  newPanels.forEach((pl, pli) => {
    pl.projectedPoints = projectPanel(pli, pl.points);
    pl.points3D = mapTo3D(pli, pl.points);
  });

  return newPanels;
};

const mmToSceneUnit = (mm, resolution) => Math.max(0.001, ((mm / 10) / Number(resolution))) || 0.001;

export const runPanelPlacement = (state, initData, getSetbacks, all = false, selectedSegments = [], fillMode = 3, layoutMode = 1) => {
  const {
    orgVertices,
    vertices3D,
    eaves,
    pitches,
    center,
  } = state;

  const {
    segments, panels, panelType, scale, resolution, buffer, rowSpacing, panelTilt,
  } = initData;

  const [cx, cy] = center;
  const c = 1.0;

  let newPanels = [...panels];
  const z = new THREE.Vector3(0, 0, 1);

  const rackBuffer = mmToSceneUnit(buffer, resolution);
  const rowSpace = mmToSceneUnit(rowSpacing * 25.4 /* inch to mm */, resolution);
  const stepx = 1.0;
  const stepy = 1.0;

  const initPanel = (i) => initializePanel(segments[i], pitches[i], panelTilt, panelType, resolution);
  const initPanels = () => segments.map((s, si) => initPanel(si));

  if (newPanels.length === 0) newPanels = initPanels();

  const roofIndices = all ? segments.map((seg, index) => index) : selectedSegments;

  roofIndices.forEach((index) => {
    if (typeof (index) === 'number') {
      const [,, eaveType, ai, bi] = eaves[index];
      if (!eaveType) return;
      const a = orgVertices[index][ai];
      const b = orgVertices[index][bi];
      const dir = b.clone().sub(a);
      const ydir = dir.clone().cross(z).normalize();
      const dirn = dir.clone().normalize();

      const [cl, cr, cb, ct] = getSegmentRange(orgVertices, eaves, scale, index/* ,index, a, dirn, ydir */);
      const setbacks = getSetbacks(vertices3D[index], index);
      const shrinkedPoly = shrinkPolygon(vertices3D[index], setbacks).filter((_, sind) => setbacks[sind] > 0);
      const triangleIndices = THREE.ShapeUtils.triangulateShape(orgVertices[index], []);
      const includedPolygons = triangleIndices
        .map((indices) => indices.map((ti) => orgVertices[index][ti]))
        .map((ti) => (THREE.ShapeUtils.isClockWise(ti) ? ti : ti.reverse()));
      const excludedPolygons = [...shrinkedPoly, ...getRoofObstacles(orgVertices, index, getSetbacks)];

      newPanels[index] = initPanel(index);
      const [ox, oy] = [rackBuffer, rackBuffer + rowSpace];

      const testPoint = (pnt, exPolygons) => includedPolygons.some((inP) => pointInConvexPolygon(inP, pnt, -1e-5)) && exPolygons.every((exP) => !pointInConvexPolygon(exP, pnt, -1e-5));
      const testPoly = (poly, exPolygons) => {
        let inside = poly.every((spVertex) => includedPolygons.some((inP) => pointInConvexPolygon(inP, spVertex, -1e-5)));
        if (inside) {
          inside = inside && exPolygons.every((exP) => exP.every((exPVertex) => !pointInConvexPolygon(poly, exPVertex, -1e-5)));
          if (inside) {
            const [v1, v2, v3, v4] = poly;
            const v12 = v1.clone().add(v2.clone().sub(v1).multiplyScalar(0.5));
            const v23 = v2.clone().add(v3.clone().sub(v2).multiplyScalar(0.5));
            const v34 = v3.clone().add(v4.clone().sub(v3).multiplyScalar(0.5));
            const v41 = v4.clone().add(v1.clone().sub(v4).multiplyScalar(0.5));

            const vcc = v1.clone().add(v2.clone()).add(v3.clone()).add(v4.clone())
              .multiplyScalar(0.25);

            const v123 = v12.clone().add(v23.clone().sub(v12).multiplyScalar(0.5));
            const v234 = v23.clone().add(v34.clone().sub(v23).multiplyScalar(0.5));
            const v134 = v34.clone().add(v41.clone().sub(v34).multiplyScalar(0.5));
            const v124 = v41.clone().add(v12.clone().sub(v41).multiplyScalar(0.5));

            const polyd = [v1, v12, v2, v23, v3, v34, v4, v41, v123, v234, v134, v124, vcc];
            inside = inside && polyd.every((spVertex) => exPolygons.every((exP) => !pointInConvexPolygon(exP, spVertex, -1e-5)));
          }
        }
        return inside;
      };
      const eaveYOffset = (setbacks[ai] / scale) + 0.01;

      const panelVW = newPanels[index].vertical.width;
      const panelVH = newPanels[index].vertical.height;
      const panelHW = newPanels[index].horizontal.width;
      const panelHH = newPanels[index].horizontal.height;

      const gridxSteps = new Array(7).fill(0).map((_, i) => i - 3);
      const gridySteps = new Array(7).fill(0).map((_, i) => i - 3);
      let fill = [['v', 'h'], ['h', 'v']];
      if (fillMode === 1) fill = [['h', '']];
      else if (fillMode === 2) fill = [['v', '']];
      else if (fillMode === 3) fill = [['v', 'h']];
      else if (fillMode === 4) fill = [['h', 'v']];
      let modes = [true, false];
      if (layoutMode == 2) modes = [false];
      else if (layoutMode == 3) modes = [true];
      const layoutModes = cartesian(fill, gridxSteps, gridySteps, modes);
      const layouts = layoutModes.map(([dr1, dr2, ofx, ofy, half]) => {
        const verticals = [];
        const horizontals = [];
        const exPolygons = [...excludedPolygons];

        let addedToSegment = 0;
        [dr1, dr2].forEach((dr) => {
          if (dr !== 'v' && dr !== 'h') return;
          const vw = dr === 'v' ? panelVW : panelHW;
          const vh = dr === 'v' ? panelVH : panelHH;
          const pw = Math.min(panelVW, panelHW);
          const ph = Math.min(panelVH, panelHH);

          const xcountLeft = (cl / vw);
          const xcountRight = (cr / vw);
          const ycountTop = ct / vh;
          const ycountBottom = cb / vh;

          let px = a.x / scale + (cx / c);
          let py = a.y / scale + (cy / c) + (ofy * (ph / gridySteps.length));
          px += ydir.x * ((vh + oy) * -(ycountBottom + 1) + eaveYOffset);
          py += ydir.y * ((vh + oy) * -(ycountBottom + 1) + eaveYOffset);

          for (let i = -ycountBottom; i < ycountTop; i += stepy) {
            px += ydir.x * ((vh + oy) * 1);
            py += ydir.y * ((vh + oy) * 1);
            let x = -(vw + ox) * xcountLeft + (vw / 2) * (half ? i % 2 : 0) + (ofx * (pw / gridxSteps.length));
            let addedToRow = 0;

            while (x < vw * xcountRight) {
              const pxl = px + dirn.x * x;
              const pyl = py + dirn.y * x;
              const v4 = new THREE.Vector3((pxl - (cx / c)) * scale, (pyl - (cy / c)) * scale, 0);

              let test = false;
              if (testPoint(v4, exPolygons)) {
                const pxr = pxl + dirn.x * vw;
                const pyr = pyl + dirn.y * vw;
                const pxtl = pxl + ydir.x * vh;
                const pytl = pyl + ydir.y * vh;
                const pxtr = pxr + ydir.x * vh;
                const pytr = pyr + ydir.y * vh;

                const v1 = new THREE.Vector3((pxr - (cx / c)) * scale, (pyr - (cy / c)) * scale, 0);
                const v2 = new THREE.Vector3((pxtr - (cx / c)) * scale, (pytr - (cy / c)) * scale, 0);
                const v3 = new THREE.Vector3((pxtl - (cx / c)) * scale, (pytl - (cy / c)) * scale, 0);

                const solarPanel = [v1, v2, v3, v4];
                test = testPoly(solarPanel, exPolygons);

                if (test) {
                  addedToRow += 1;
                  if (dr === 'v') verticals.push([pxr, pyr]);
                  else horizontals.push([pxr, pyr]);
                  if (fillMode >= 3) exPolygons.push([v1, v2, v3, v4]);
                  // if (fillMode != 3 && exPolygons.length > 50) exPolygons.shift();
                }
              }

              x += test ? (vw + ox) : stepx * (vw + ox);
            }

            addedToSegment += addedToRow;
          }
        });

        return [addedToSegment, verticals, horizontals];
      });

      let [cenx, ceny] = segments[index].geometry.reduce((pa, pb) => [pa[0] + pb[0], pa[1] + pb[1]], [0, 0]);
      cenx /= segments[index].geometry.length;
      ceny /= segments[index].geometry.length;
      const counts = layouts.map(([cnt]) => cnt);
      const maxCount = Math.max(...counts);
      if (maxCount > 1) {
        const indices = counts.flatMap((e, i) => (e === maxCount ? i : []));
        const distToCenter = indices.map((i) => {
          const [, verticals, horizontals] = layouts[i];
          const [vcx, vcy] = verticals.map(([px, py]) => [px - dirn.x * (panelVW / 2) + ydir.x * (panelVH / 2), py - dirn.y * (panelVW / 2) + ydir.y * (panelVH / 2)])
            .reduce((pa, pb) => [pa[0] + pb[0], pa[1] + pb[1]], [0, 0]);
          const [hcx, hcy] = horizontals.map(([px, py]) => [px - dirn.x * (panelHW / 2) + ydir.x * (panelHH / 2), py - dirn.y * (panelHW / 2) + ydir.y * (panelHH / 2)])
            .reduce((pa, pb) => [pa[0] + pb[0], pa[1] + pb[1]], [0, 0]);
          const pcx = (vcx + hcx) / maxCount;
          const pcy = (vcy + hcy) / maxCount;
          return ((pcx - cenx) ** 2 + (pcy - ceny) ** 2) ** 0.5;
        });
        const minDists = Math.min(...distToCenter);
        const minDistIndex = distToCenter.indexOf(minDists);
        const layoutIndex = indices[minDistIndex];
        if (layouts[layoutIndex]) {
          const [, verticals, horizontals] = layouts[layoutIndex];

          // const [vcx, vcy] = verticals.map(([px, py]) => [px - dirn.x * (panelVW / 2) + ydir.x * (panelVH / 2), py - dirn.y * (panelVW / 2) + ydir.y * (panelVH / 2)])
          //   .reduce((pa, pb) => [pa[0] + pb[0], pa[1] + pb[1]], [0, 0]);
          // const [hcx, hcy] = horizontals.map(([px, py]) => [px - dirn.x * (panelHW / 2) + ydir.x * (panelHH / 2), py - dirn.y * (panelHW / 2) + ydir.y * (panelHH / 2)])
          //   .reduce((pa, pb) => [pa[0] + pb[0], pa[1] + pb[1]], [0, 0]);
          // const pcx = (vcx + hcx) / maxCount;
          // const pcy = (vcy + hcy) / maxCount;

          // const pMarkerGeometry = new THREE.BufferGeometry();
          // pMarkerGeometry.setAttribute('position', new THREE.Float32BufferAttribute([0, 0, 0], 3));
          // const pMarkerMaterial = new THREE.PointsMaterial({ color: 'red', size: 5, sizeAttenuation: false });
          // const ppnt = new THREE.Points(pMarkerGeometry, pMarkerMaterial);
          // ppnt.position.set((pcx - center[0]) * scale, 2, (pcy - center[1]) * scale);
          // sceneRef.current.add(ppnt);

          // const sMarkerGeometry = new THREE.BufferGeometry();
          // sMarkerGeometry.setAttribute('position', new THREE.Float32BufferAttribute([0, 0, 0], 3));
          // const sMarkerMaterial = new THREE.PointsMaterial({ color: 'blue', size: 5, sizeAttenuation: false });
          // const spnt = new THREE.Points(sMarkerGeometry, sMarkerMaterial);
          // spnt.position.set((cenx - center[0]) * scale, 2, (ceny - center[1]) * scale);
          // sceneRef.current.add(spnt);

          verticals.forEach((point) => newPanels[index].vertical.points.push(point));
          horizontals.forEach((point) => newPanels[index].horizontal.points.push(point));
        }
      }
    }
  });

  return newPanels;
};

const getPanelWiringCost = (panelRecta, panelRectb, resolution) => {
  const perMeterCost = 10;
  const minDistances = panelRectb.map((v1) => Math.min(...panelRecta.map((v2) => v1.distanceTo(v2))));
  const minDistance = Math.min(...minDistances);
  const fitScore = minDistances.map((d) => (d < 5 ? 1 : 0)).reduce((a, b) => a + b, 0) ** 1.5 * (perMeterCost / 3);
  const cost = minDistance * resolution * perMeterCost;
  return [cost, fitScore];
};

const getWiringCost = (selectedPanels, candidatePanelRect, candidatePanelCenter, candidateRoofIdx, segmentCenters) => {
  if (selectedPanels.length === 0) return 0;
  const selectedPanelsInCandidateRoof = selectedPanels.filter(([ri]) => ri === candidateRoofIdx);
  if (selectedPanelsInCandidateRoof.length) {
    const costAndScores = selectedPanelsInCandidateRoof.map((panel) => {
      const [,,,,, rect] = panel;
      const [cost, fitscore] = getPanelWiringCost(rect, candidatePanelRect);
      return [cost, fitscore];
    });
    let fitScore = 0;
    let cost = 0;
    if (costAndScores.length > 1) {
      fitScore = costAndScores.map((a) => a[1]).reduce((a, b) => Math.max(a, b));
      cost = costAndScores.map((a) => a[0]).reduce((a, b) => a + b) / costAndScores.length;
    } else if (costAndScores.length) {
      [[cost, fitScore]] = costAndScores;
    }
    return cost - fitScore;
  }
  const [rcx, rcy] = segmentCenters[candidateRoofIdx];
  const [px, py] = candidatePanelCenter;
  const centerCost = ((rcx - px) ** 2 + (rcy - py) ** 2) ** 0.5;
  return centerCost / 10 + 50;
};

const sortPanelShadings = (design, panelkW, top, centers, selected = [], remaining = []) => {
  const selectedRoofs = selected.map(([ri]) => ri);
  while (remaining.length > 0 && selected.length < top) {
    remaining.sort((a, b) => {
      const [ria,,, cxa, cya, recta, psolaraccessa] = a;
      const [rib,,, cxb, cyb, rectb, psolaraccessb] = b;
      const productiona = panelkW * design[ria].acAnnual * psolaraccessa;
      const productionb = panelkW * design[rib].acAnnual * psolaraccessb;
      const wiringCosta = getWiringCost(selected, recta, [cxa, cya], ria, centers);
      const wiringCostb = getWiringCost(selected, rectb, [cxb, cyb], rib, centers);
      // if (selectedRoofs.length && !selectedRoofs.includes(ria)) wiringCosta += 100;
      // if (selectedRoofs.length && !selectedRoofs.includes(rib)) wiringCostb += 100;
      return (productionb - wiringCostb) - (productiona - wiringCosta);
    });
    const newSelected = remaining.shift();
    selectedRoofs.push(newSelected[0]);
    selected.push(newSelected);
  }

  return [selected, remaining];
};

export const optimalSelection = (design, panelkW, annualUsage, panelShadings) => {
  let usage = annualUsage;
  let selectedPanels = [];

  const lstPanelShadings = design.map((d, di) => panelShadings.filter(([ri]) => ri === di));
  let remaining = [...panelShadings];
  let selected = [];
  let top = 0;
  const centers = lstPanelShadings
    .map((psh) => psh.map((v) => [v[3], v[4]])
      .reduce((a, b) => [a[0] + b[0], a[1] + b[1]], [0, 0])
      .map((v) => (v !== 0 ? v / psh.length : 0)));

  while (usage >= 0 && remaining.length > 0) {
    selectedPanels = design.map((d) => d.panels.map(() => 0));
    usage = annualUsage;
    top += 5;
    [selected, remaining] = sortPanelShadings(design, panelkW, top, centers, selected, remaining);
    for (let i = 0; i < selected.length; i += 1) {
      const [ri,, pi,,,, psolaraccess] = selected[i];
      const roof = design[ri];
      const panelProduction = panelkW * roof.acAnnual * psolaraccess;
      if (usage > 0) {
        usage -= panelProduction;
        selectedPanels[ri][pi] = 1;
      } else break;
    }
  }

  return selectedPanels;
};
