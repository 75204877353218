import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Button, Form, ToggleButton, ToggleButtonGroup,
} from 'react-bootstrap';

import Panel from 'components/Hardware/Panel';
import { FloatSelect, FloatInput } from 'components/Form/Inputs';

// racking buffers in mm, labels in inches
const bufferOptions = [
  { value: 19.05, label: '3/4"' },
  { value: 12.7, label: '1/2"' },
];

const Design = ({
  currentPanel,
  currentInverter,
  buffer,
  rowSpacing,
  panelTilt,
  changePanelHardware,
  changeInverterHardware,
  placementHandler,
  bufferHandler,
  rowSpacingHandler,
  panelTiltHandler,
  solarPanels,
  inverters,
}) => {
  const [serverSide, setServerSide] = useState(false);
  const [selected, setSelected] = useState();
  const [selectedInverter, setSelectedInverter] = useState();
  const [fillMode, setFillMode] = useState(3);
  const [layoutMode, setLayoutMode] = useState(1);

  useEffect(() => {
    if (solarPanels && !selected && currentPanel) {
      const panel = solarPanels.find(({ id }) => id === currentPanel);
      if (panel) setSelected(panel);
    }
  }, [selected, solarPanels, currentPanel]);

  useEffect(() => {
    if (inverters && !selectedInverter && currentInverter) {
      const inverter = inverters.find(({ id }) => id === currentInverter);
      if (inverter) setSelectedInverter(inverter);
    }
  }, [selectedInverter, inverters, currentInverter]);

  const panelOptions = solarPanels
    ? solarPanels.map((pnl) => ({
      id: pnl.id,
      label: `${pnl.manufacturer.name}-${pnl.model}`,
    })) : [];


  const inverterOptions = inverters
    ? inverters.map((inv) => ({
      id: inv.id,
      label: `${inv.manufacturer?.name ?? 'Undefined'}-${inv.model}`,
    })) : [];;

  const handlePanelChoice = (e) => {
    const { value } = e.target;
    const panel = solarPanels.find(({ id }) => id === value);
    setSelected(panel);
    changePanelHardware(panel);
  };

  const handleInverterChoice = (e) => {
    const { value } = e.target;
    const inverter = inverters.find(({ id }) => id === value);
    setSelectedInverter(inverter);
    changeInverterHardware(inverter);
  };

  const handleBuffer = (e) => bufferHandler(+e.target.value);
  const handleRowSpacing = (e) => rowSpacingHandler(+e.target.value);
  const handlePanelTilt = (e) => panelTiltHandler(+e.target.value);

  return (
    <>
      {/* <Row className="mt-2">
        <Col>
          <Form.Check
            id="chk-panel-placement-server-side"
            type="switch"
            checked={serverSide}
            onChange={(e) => setServerSide(e.target.checked)}
            label="Run Server-Side"
          />
        </Col>
      </Row> */}
      <Row className="mb-2 mr-3">
        <Col sm="12">
          <FloatSelect
            label="Solar Panel"
            options={panelOptions}
            labelField="label"
            value={selected?.id || ''}
            onChange={handlePanelChoice}
          />
        </Col>
        <Col className='mt-1' sm="12">
          <FloatSelect
            label="Inverter"
            options={inverterOptions}
            labelField="label"
            value={selectedInverter?.id || ''}
            onChange={handleInverterChoice}
          />
        </Col>
      </Row>
      <Row className="my-4 mr-3">
        <Col sm="12">
          <FloatSelect
            label="Racking Buffer"
            options={bufferOptions}
            valueField="value"
            labelField="label"
            value={buffer}
            onChange={handleBuffer}
            nullValue="None"
            allowNull
          />
        </Col>
        <Col className='mt-1' sm="12">
          <FloatInput
            label="Row Spacing"
            type="number"
            append="in"
            value={rowSpacing}
            onChange={handleRowSpacing}
          />
        </Col>
        <Col className='mt-1' sm="12">
          <FloatInput
            label="Tilt"
            type="number"
            append="deg"
            value={panelTilt}
            onChange={handlePanelTilt}
          />
        </Col>
      </Row>
      <Row className='mr-3'>
        <Col>
          <ToggleButtonGroup type="checkbox" style={{ width: "100%" }}>
            <ToggleButton value={1} style={{ color: fillMode === 1 ? 'white' : 'gray' }} onClick={() => setFillMode(1)}>Horizontal</ToggleButton>
            <ToggleButton value={2} style={{ color: fillMode === 2 ? 'white' : 'gray' }} onClick={() => setFillMode(2)}>Vertical</ToggleButton>
            <ToggleButton value={3} style={{ color: fillMode === 3 ? 'white' : 'gray' }} onClick={() => setFillMode(3)}>Both <small>(First Vertically)</small></ToggleButton>
            <ToggleButton value={4} style={{ color: fillMode === 4 ? 'white' : 'gray' }} onClick={() => setFillMode(4)}>Both <small>(First Horizontally)</small></ToggleButton>
          </ToggleButtonGroup>
        </Col>
      </Row>
      <Row className="mt-1 mr-3">
        <Col>
          <ToggleButtonGroup type="checkbox" style={{ width: "100%" }}>
            <ToggleButton value={1} style={{ color: layoutMode === 1 ? 'white' : 'gray' }} onClick={() => setLayoutMode(1)}>Auto</ToggleButton>
            <ToggleButton value={2} style={{ color: layoutMode === 2 ? 'white' : 'gray' }} onClick={() => setLayoutMode(2)}>Grid-Mode</ToggleButton>
            <ToggleButton value={3} style={{ color: layoutMode === 3 ? 'white' : 'gray' }} onClick={() => setLayoutMode(3)}>Brick-Mode</ToggleButton>
          </ToggleButtonGroup>
        </Col>
      </Row>
      <Row>
        <Col className="mt-1">
          <Button
            onClick={(e) => placementHandler(e, serverSide, fillMode, layoutMode)}
            disabled={!selected}
          >
            Fill Panels
          </Button>
        </Col>
      </Row>
      <Row>

      </Row>

      {selected && (
        <Row className="my-3 mx-1 border">
          <Panel panel={selected} />
        </Row>
      )}
    </>
  );
};

Design.propTypes = {
  currentPanel: PropTypes.string.isRequired,
  buffer: PropTypes.number.isRequired,
  changePanelHardware: PropTypes.func.isRequired,
  placementHandler: PropTypes.func.isRequired,
  bufferHandler: PropTypes.func.isRequired,
  solarPanels: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Design;
