import * as THREE from 'three';

export const DrawingState = {
  ReadyForDraw: 'ReadyForDraw',
  Drawing: 'Drawing',
  ReadyForEdit: 'ReadyForEdit',
  Editing: 'Editing',
  ReadyForDelete: 'ReadyForDelete',
  Disabled: 'Disabled',
};

export const lineColors = {
  Eave: '#39D86F',
  Ridge: '#F30A0A',
  Rake: '#57519C',
  Hip: '#07FFF0',
  Valley: '#D825BB',
};

export const dotColors = {
  0: 'blue', 1: 'white', 90: 'red', 45: 'yellow',
};

export const projectPointToLine = (p1, p2, p3) => {
  const v = p2.clone().sub(p1);
  const w = p3.clone().sub(p1);
  const l = v.clone().dot(v);
  const t = v.clone().dot(w) / l;
  const projected = p1.clone().add(v.multiplyScalar(t));
  const d = projected.clone().sub(p3).length();
  v.normalize();
  return [projected, t, d, v, l ** 0.5];
};

export const lineIntersection = (line1, line2) => {
  const xdiff = [line1[0].x - line1[1].x, line2[0].x - line2[1].x];
  const ydiff = [line1[0].z - line1[1].z, line2[0].z - line2[1].z];
  const det = (a, b) => a[0] * b[1] - a[1] * b[0];
  const detx = (a, b) => a.x * b.z - a.z * b.x;
  const div = det(xdiff, ydiff);
  if (div === 0) return null;
  const d = [detx(line1[0], line1[1]), detx(line2[0], line2[1])];
  const x = det(d, xdiff) / div;
  const y = det(d, ydiff) / div;
  return new THREE.Vector3(x, 0, y);
};

export const getPolygonSnapLines = (poly, polyIndex, pnt, thresh, snaps, newPoly = false, snap45 = true, snap90 = true, snap180 = true) => {
  const flen = 4.0;
  const z = new THREE.Vector3(0, 1, 0);
  poly.forEach((ve, i) => {
    const vs = poly[(i - 1 + poly.length) % poly.length];
    if (newPoly && i === 0) return;
    const v1 = new THREE.Vector3(vs.x, 0, vs.z);
    const v2 = new THREE.Vector3(ve.x, 0, ve.z);
    const n = v2.clone().sub(v1);
    const ln = n.length();
    const v90 = n.clone().cross(z).normalize();
    const v45 = n.clone().normalize().add(v90).normalize();
    const vn45 = n.clone().negate().normalize().add(v90)
      .normalize();
    let testSets = [];
    if (snap180) {
      testSets = [
        [v1, v2, 1],
      ];
    }
    if (snap90) {
      testSets = [
        ...testSets,
        [v1, v1.clone().add(v90), 90], [v1, v1.clone().sub(v90), 90], [v2, v2.clone().add(v90), 90], [v2, v2.clone().sub(v90), 90],
      ];
    }
    if (snap45) {
      testSets = [
        ...testSets,
        [v1, v1.clone().add(v45), 45], [v1, v1.clone().sub(v45), 45], [v2, v2.clone().add(v45), 45], [v2, v2.clone().sub(v45), 45],
        [v1, v1.clone().add(vn45), 45], [v1, v1.clone().sub(vn45), 45], [v2, v2.clone().add(vn45), 45], [v2, v2.clone().sub(vn45), 45],
      ];
    }
    testSets.forEach(([vx1, vx2, ty]) => {
      const [projected, t, d, v, l] = projectPointToLine(vx1, vx2, pnt);
      const filter = ty === 1 ? (t < 0 && -t > flen) || (t > 1 && (t - 1) > flen) : Math.abs(t) > ln * flen;
      if (d < thresh && !filter) snaps.push([vx1, vx2, projected, t, d, v, l, ty, newPoly, polyIndex, i]);
    });
  });
};

export const getConnectedComponents = (allPolys, polys) => {
  if (polys.length === 0) return allPolys;
  const filteredPolys = allPolys.filter((ap) => ap.some((av) => polys.some((p) => p.some((v) => av.clone().setComponent(1, 0).sub(v.clone().setComponent(1, 0)).length() < 0.2))));
  if (filteredPolys.length === 0) return polys;
  if (filteredPolys.length !== polys.length || filteredPolys[0].length !== polys[0].length) return getConnectedComponents(allPolys, filteredPolys);
  return filteredPolys;
};

export const getSnapLines = (polys, newPoints, point, thresh = 2, onlyOneSnap = false, onlyOnLine = false, snap45 = true, snap90 = true, snap180 = true) => {
  const snaps = [];
  const pnt = new THREE.Vector3(point.x, 0, point.z);
  getPolygonSnapLines(newPoints, -1, pnt, thresh, snaps, true, snap45, snap90, snap180);
  polys.forEach((p, pi) => getPolygonSnapLines(p, pi, pnt, thresh, snaps, false, snap45, snap90, snap180));
  let snapLns = snaps.map((s) => {
    const [vs, ve, projected, t, d, v, l, ty, np, pi, ei] = s;
    if (onlyOnLine && ((t <= 0.05) || (t >= 0.95))) return null;
    if (t < 0) return [vs.clone(), vs.clone().add(v.clone().multiplyScalar(-t * l)), projected, d, ty, v, l, np, pi, ei];
    if (t > 1) return [ve.clone(), ve.clone().add(v.clone().multiplyScalar(t * l - l)), projected, d, ty, v, l, np, pi, ei];
    return [vs.clone(), ve.clone(), projected, d, 0, v, l, np, pi, ei];
  }).filter((sn) => sn != null);
  let snapPnt = null;
  if (snapLns.length === 1) {
    const [,, projected] = snapLns[0];
    snapPnt = projected;
  } else if (snapLns.length > 1) {
    let bestCost = 1e5;
    let bestType = 0;
    const scoreType = (ty) => {
      switch (ty) {
        case 1: return 2;
        case 90: return 1;
        default: return 0;
      }
    };
    if (onlyOneSnap && snapLns.length > 0) {
      const edges = snapLns.filter((a) => a[4] === 0);
      if (edges.length > 0) snapLns = edges;
      else snapLns = [snapLns.sort((a, b) => a[3] - b[3])[0]];
    }
    snapLns = snapLns.filter((si, i) => {
      const [,,,, tyi, vni, li, npi] = si;
      const foundSuperior = snapLns.some((sj, j) => {
        const [,,,, tyj, vnj, lj, npj] = sj;
        const lowerScore = !(npi && !npj) && scoreType(tyi) < scoreType(tyj);
        const eqScore = npi === npj && scoreType(tyi) === scoreType(tyj);
        if (i !== j && vni.dot(vnj) > 0.9 && (lowerScore || (eqScore && (li < lj || (li === lj && i < j))))) {
          return true;
        }
        return false;
      });
      return !foundSuperior;
    });
    snapLns.forEach((si, i) => {
      const [,, projected, d] = si;
      if (d < thresh && d < bestCost && bestType !== 2) {
        bestCost = d;
        bestType = 1;
        snapPnt = projected;
      }
      snapLns.forEach((sj, j) => {
        if (j > i) {
          const intersection = lineIntersection(si, sj);
          if (intersection) {
            const l = intersection.clone().sub(pnt).length();
            if (l < thresh && (l < bestCost || bestType === 1)) {
              bestCost = l;
              bestType = 2;
              snapPnt = intersection;
            }
          }
        }
      });
    });
  }
  return [snapPnt && new THREE.Vector3(snapPnt.x, point.y, snapPnt.z), snapLns];
};

export const argMax = (array) => [].reduce.call(array, (m, c, i, arr) => (c > arr[m] ? i : m), 0);

export const getPolygonEave = (poly, edgeCount) => {
  const eaveScores = poly.map((v, vi) => {
    const ec = edgeCount[vi];
    const ui = (vi - 1 + poly.length) % poly.length;
    const u = poly[ui];
    const uv = v.clone().sub(u);
    const len = uv.length();
    const z = Math.abs(uv.normalize().getComponent(1));
    const h = Math.max(u.y, v.y);
    return (1 / (1 + h)) / ec;
  });
  const vi = argMax(eaveScores);
  const ui = (vi - 1 + poly.length) % poly.length;
  return [ui, vi];
};
