import React, { useState, useEffect } from 'react';
import { Alert, Form } from 'react-bootstrap';

import FloatSelect from './FloatSelect';
import FloatInput from './FloatInput';
import styles from './snackbar.module.css';

const timeout = 2500;

const ValidatedForm = ({ ref, className, children, onSave }) => {
  const [validated, setValidated] = useState(false);
  const [snackbar, setSnack] = useState();

  const onSuccess = () => setSnack({ variant: 'success', message: 'Successfully saved.' });

  const onRejected = (errorMessage) => setSnack({ variant: 'danger', message: errorMessage });

  const submitForm = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      setTimeout(() => setValidated(false), timeout);
      onRejected('Fill in the required field');
      return;
    }

    onSave()
      .then(() => onSuccess())
      .catch(() => onRejected('Unable to save.'));
  };

  useEffect(() => {
    if (snackbar !== undefined) setTimeout(() => setSnack(undefined), timeout);
  }, [snackbar]);

  return (
    <Form ref={ref} className={className} onSubmit={submitForm} noValidate validated={validated}>
      {snackbar && (
        <Alert
          className={styles.snack}
          variant={snackbar.variant}
          onClose={() => setSnack(undefined)}
          dismissible
        >
          {snackbar.message}
        </Alert>
      )}
      {children}
    </Form>
  );
};

export default ValidatedForm;
export { FloatInput, FloatSelect };
