import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Button, Form,
} from 'react-bootstrap';
import { CircleFill } from 'react-bootstrap-icons';

import { DesignContext } from '../../Canvas3D/contexts/designContext';

const labels = {
  Eave: '#39D86F',
  Ridge: '#F30A0A',
  Rake: '#57519C',
  Hip: '#07FFF0',
  Valley: '#D825BB',
  // Other: '#D9E0E8',
};

function SegmentsLabelsTab({
  designType, selectedLabel, selectedLabelHandler, clearLabelsHandler,
}) {
  const { automaticAzimuth, automaticAzimuthMode } = useContext(DesignContext);

  const defaultStyle = {
    width: 100,
    backgroundColor: 'transparent',
    color: '#333F50',
  };

  const selectedStyle = (color) => ({
    width: 100,
    backgroundColor: color,
    textShadow: '1px 1px 2px black',
  });

  return (
    <div className="pt-3">
      <Row className="mt-4">
        <Col>
          <Button size="sm" className="m-1" onClick={clearLabelsHandler}>
            Clear Labels
          </Button>
          {(designType === 'A') && (
          <Form.Check
            id="chk-aut-azimuth"
            type="switch"
            checked={automaticAzimuth}
            onChange={(e) => automaticAzimuthMode(e.target.checked)}
            label="Auto Set for Azimuth"
          />
          )}
        </Col>
      </Row>
      {Object.entries(labels).map(([label, color]) => (
        <Row key={label} className="mt-2">
          <Col md={1} className="ml-3">
            <CircleFill style={{ color }} size={40} />
          </Col>
          <Col md={1} />
          <Col>
            <Button
              onClick={() => selectedLabelHandler(label)}
              style={selectedLabel === label ? selectedStyle(color) : defaultStyle}
            >
              {label}
            </Button>
            {label === 'Eave' && <span className="ml-2 text-muted ">(Required)</span>}
          </Col>
        </Row>
      ))}
    </div>
  );
}

SegmentsLabelsTab.propTypes = {
  designType: PropTypes.string.isRequired,
  selectedLabel: PropTypes.string.isRequired,
  selectedLabelHandler: PropTypes.func.isRequired,
  clearLabelsHandler: PropTypes.func.isRequired,
};

export default SegmentsLabelsTab;
