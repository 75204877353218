import { useState, useEffect, useContext, useLayoutEffect, useCallback } from 'react';
import { fromArrayBuffer } from 'geotiff';
import * as THREE from 'three';

import { DesignContext } from '../contexts/designContext';

import { downloadCanvas } from '../algorithms/geometry';

import {
  getDisplacementBias,
  getOffset,
  getTiff,
  updateTextureData,
  fillPolygon,
  getPointClouds,
} from '../algorithms/dsm';

let isLoadingDSM = false;
let lastSegments;
let tmDSM = 0;

export const useDSM = (canvasPool, dsmSrc, segments, trees, updateLoadingStatus) => {
  const { dsmParams, setDSMParams } = useContext(DesignContext);

  const [[orgTexture, orgDSMTexture, dsmTexture, minDSM, maxDSM, displacementBias], setDSMTexture] = useState([null, null, null, 0, 255, 0]);
  const [loadStatus, setLoadStatus] = useState('DSM.Loading');
  const [dsmOffset, setDSMOffset] = useState([0, 0]);
  const [[raster, rasterHeight, rasterWidth], setRaster] = useState([null, 0, 0]);

  const changeLoadStatus = useCallback((status) => {
    setLoadStatus(status);
    updateLoadingStatus(status);
  }, [updateLoadingStatus]);

  const changeOffset = (offset) => {
    setDSMOffset(offset);
    setDSMParams({ dsmOffset: offset, bias: displacementBias });
  };

  const calculateAndSetOffset = (borders, resolution, scale, center) => {
    const offset = getOffset(raster, rasterHeight, rasterWidth, borders, resolution, scale, center);
    changeOffset(offset);
    return offset;
  };

  useEffect(() => {
    if (dsmParams && dsmParams.dsmOffset && (dsmParams.dsmOffset[0] !== dsmOffset[0] || dsmParams.dsmOffset[1] !== dsmOffset[1])) {
      const offset = [dsmParams.dsmOffset[0], dsmParams.dsmOffset[1]];
      const [ox, oy] = offset;
      dsmOffset[0] = ox;
      dsmOffset[1] = oy;
      setDSMOffset(offset);
    }
  }, [dsmOffset, dsmParams]);

  const getDSMData = useCallback(() => {
    const rasterData = [raster, rasterHeight, rasterWidth];
    const dsmTextureData = [orgTexture, orgDSMTexture, dsmTexture, minDSM, maxDSM, displacementBias];
    const dsmParamsData = { bias: displacementBias, dsmOffset };
    const dsmData = { rasterData, dsmTextureData, dsmParamsData };
    return dsmData;
  }, [displacementBias, dsmOffset, dsmTexture, maxDSM, minDSM, orgDSMTexture, orgTexture, raster, rasterHeight, rasterWidth]);

  const loadedHandler = useCallback((dsmData) => {
    if (!dsmData) return;
    const { rasterData, dsmTextureData, dsmParamsData } = dsmData;
    setRaster(rasterData);
    setDSMTexture(dsmTextureData);
    if (dsmParamsData.bias && dsmParams.bias !== dsmParamsData.bias) {
      dsmParams.bias = dsmParamsData.bias;
      dsmParams.dsmOffset = dsmParamsData.dsmOffset;
      if (setDSMParams) setDSMParams({ ...dsmParams });
    }
    lastSegments = JSON.stringify(segments);
    changeLoadStatus('DSM.Loaded');
    isLoadingDSM = false;
  }, [changeLoadStatus, dsmParams, segments, setDSMParams]);

  const errorHandler = useCallback(() => {
    changeLoadStatus('DSM.Failed');
    isLoadingDSM = false;
  }, [changeLoadStatus]);

  const asyncUpdateDSMParams = useCallback(async (segmentJson) => {
    lastSegments = segmentJson;
    const dsmData = getDSMData();
    const dsmTextureData = updateTextureData(canvasPool, dsmData, segments, trees);
    const [,,,,, bias] = dsmTextureData;
    setDSMTexture(dsmTextureData);
    if ((!dsmParams || dsmParams.bias !== bias) && bias) {
      setDSMParams({ bias, dsmOffset });
    }
  }, [canvasPool, dsmOffset, dsmParams, getDSMData, segments, setDSMParams, trees]);

  const loadDSM = useCallback(() => {
    if (!isLoadingDSM && loadStatus !== 'Failed') {
      if (!orgTexture) {
        isLoadingDSM = true;
        getTiff(canvasPool, segments, trees, dsmSrc, dsmOffset, loadedHandler, errorHandler);
      } else {
        const segmentJson = JSON.stringify({ segments, trees, dsmOffset });
        if (lastSegments !== segmentJson) {
          clearTimeout(tmDSM);
          tmDSM = setTimeout(() => asyncUpdateDSMParams(segmentJson), 1000);
        }
      }
    }
  }, [loadStatus, orgTexture, canvasPool, segments, trees, dsmSrc, dsmOffset, loadedHandler, errorHandler, asyncUpdateDSMParams]);

  useLayoutEffect(() => {
    loadDSM(dsmSrc);
  }, [dsmSrc, segments, trees, dsmOffset, loadDSM]);

  return {
    orgDSMTexture,
    dsmTexture,
    minDSM,
    maxDSM,
    displacementBias,
    dsmOffset,
    loadStatus,
    dsmRaster: raster,
    dsmRasterHeight: rasterHeight,
    dsmRasterWidth: rasterWidth,
    fillPolygon,
    getPointClouds,
    calculateAndSetOffset,
    changeOffset,
    getDSMData,
  };
};

export default useDSM;
