import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Form, ButtonGroup } from 'react-bootstrap';
import { ArrowClockwise, Files, Dash } from 'react-bootstrap-icons';
import Slider from '@material-ui/core/Slider';

import { segmentPropType, panelsPropType } from '../props';

const Revise = ({
  selectedRoof,
  selectedPanels,
  panels,
  segments,
  panelAzimuthHandler,
  newPanelHandler,
  rotatePanelHandler,
  deletePanelHandler,
}) => {
  const segment = segments[selectedRoof];
  const panel = segment ? panels.find(({ id }) => id === segment.id) : null;

  if (!segment || !panel) {
    return (
      <div className="mt-2">
        Click on a roof to adjust azimuth, after creating a design.
      </div>
    );
  }

  return (
    <>
      <h6 className="font-weight-bold">Azimuth</h6>
      <Row>
        <Col sm={6}>
          <Form.Control value={Math.round(panel.azimuth * 2) / 2} readOnly />
        </Col>
      </Row>
      <Slider
        value={panel.azimuth}
        min={segment.azimuth - 7.5}
        max={segment.azimuth + 7.5}
        step={0.5}
        aria-labelledby="Azimuth"
        onChange={(e, azimuth) => panelAzimuthHandler(selectedRoof, +azimuth.toFixed(2))}
      />
      <ButtonGroup className="w-100 btn-group-sm">
        <Button
          variant="outline-primary"
          onClick={newPanelHandler}
        >
          <Files />
          Panel
        </Button>
        <Button
          variant="outline-primary"
          onClick={deletePanelHandler}
          disabled={!selectedPanels}
        >
          <Dash />
          Panel
        </Button>
        <Button
          variant="outline-primary"
          onClick={rotatePanelHandler}
          disabled={!selectedPanels}
        >
          <ArrowClockwise />
          Panel
        </Button>
      </ButtonGroup>
    </>
  );
};

Revise.propTypes = {
  selectedRoof: PropTypes.number.isRequired,
  selectedPanels: PropTypes.bool.isRequired,
  panels: panelsPropType.isRequired,
  segments: segmentPropType.isRequired,
  panelAzimuthHandler: PropTypes.func.isRequired,
  newPanelHandler: PropTypes.func.isRequired,
  rotatePanelHandler: PropTypes.func.isRequired,
  deletePanelHandler: PropTypes.func.isRequired,
};

export default Revise;
