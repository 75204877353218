import React, { Component } from 'react';
import { Stage, Layer, Rect, Group, Image } from 'react-konva';

class TogglePanels extends Component {
  state = {
    image: null,
    width: 480,
    height: 320,
    maxWidth: 480,
    maxHeight: 490,
    panels: [],
  };

  componentDidMount = () => {
    if (this.props.overlaySrc) {
      const overlay = new window.Image();
      overlay.src = this.props.overlaySrc;
      overlay.crossOrigin = 'Anonymous';
      overlay.onload = () => {
        this.setState({ overlay });
      };
    }
    const image = new window.Image();
    image.src = this.props.imgSrc;
    image.crossOrigin = 'Anonymous';
    image.onload = () => {
      this.setState({
        image,
        width: image.naturalWidth * this.props.scale,
        height: image.naturalHeight * this.props.scale,
        maxWidth: image.naturalWidth * 0.688,
        // maxHeight: image.naturalHeight * 0.688,
      });
    };
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.scale !== this.props.scale) {
      const { image } = this.state;
      this.setState({
        width: image.naturalWidth * this.props.scale,
        height: image.naturalHeight * this.props.scale,
      });
    }
  };

  handleClickOnPanel = (e) => {
    const[idx, jdx] = e.target.id().split("_");
    console.log(idx, jdx);
    this.props.handlePanelToggle(idx, jdx);
  };

  render() {
    const {
      visible,
      shadingTexture,
      selected,
      visibility,
      scale,
      panels,
      showOverlay,
    } = this.props;

    const spanels = panels.map((roof, idx) =>
      roof.panels.map(({
        x, y, width, height,
      }, jdx) => {
        const id = `${idx}_${jdx}`;
        const pselected = selected[idx] ? Boolean(selected[idx][jdx]) : false;
        // don't show panel if it visiblity is off and panel is unselected
        if (!visibility && !selected) return null;
        const panelOrientation = roof.panelOrientation || roof.azimuth;
        return (
          <Rect
            id={id}
            key={id}
            x={Math.round(x * scale)}
            y={Math.round(y * scale)}
            stroke={pselected ? 'gray' : 'black'}
            strokeWidth={1.5}
            fill={pselected ? 'black' : 'white'}
            opacity={pselected ? 0.8 : 0.6}
            width={Math.round(width * scale)}
            height={Math.round(height * scale)}
            rotation={panelOrientation}
            onClick={this.handleClickOnPanel}
          />
        );
      })).flat();

    return (
      <div className={`${visible ? 'd-flex' : 'd-none'} justify-content-center`}>
        <div>
          <Stage
            id="rgbImage"
            width={this.state.width}
            height={this.state.height}
            ref={(stage) => (this.stage = stage)}
            onMouseMove={this.handleMouseMove}
            onClick={this.handleClickOnStage}
          >
            <Layer>
              <Group>
                <Image
                  image={this.state.image}
                  width={this.state.width}
                  height={this.state.height}
                />
                {showOverlay && this.state.overlay && (
                  <Image
                    image={this.state.overlay}
                    width={this.state.width}
                    height={this.state.height}
                    opacity={0.8}
                  />
                )}
                {showOverlay && shadingTexture && (
                  <Image
                    image={shadingTexture.image}
                    visible
                    width={this.state.width}
                    height={this.state.height}
                    opacity={1}
                  />
                )}
                {spanels}
              </Group>
            </Layer>
          </Stage>
        </div>
      </div>
    );
  }
}

export default TogglePanels;
