import React, {
  useState, useMemo, useRef, useEffect, useContext,
} from 'react';
import * as THREE from 'three';

const Sky = () => {
  const skyMaterial = useMemo(() => new THREE.ShaderMaterial({
    uniforms: {
      color1: { value: new THREE.Color('#ededed') },
      color2: { value: new THREE.Color('#f4f5f8') },
      color3: { value: new THREE.Color('#ffffff') },
    },
    vertexShader: `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
    fragmentShader: `
        uniform vec3 color1;
        uniform vec3 color2;
        uniform vec3 color3;
        varying vec2 vUv;
        void main() {
          if (vUv.y < 0.5) {
            gl_FragColor = vec4(mix(color1, color2, vUv.y * 2.0), 1.0);
          } else {
            gl_FragColor = vec4(mix(color2, color3, (vUv.y - 0.5) * 2.0), 1.0);
          }
        }
      `,
    wireframe: false,
    side: THREE.DoubleSide,
  }), []);

  return (
    <>
      <mesh name="sky" material={skyMaterial}>
        <sphereGeometry attach="geometry" args={[600, 25, 25]} />
      </mesh>
    </>
  );
};

export default Sky;
