const canvasHandler = {};

// from http://blog.shkedy.com/2007/01/createing-guids-with-client-side.html
canvasHandler.uuid = () => {
  let result;
  let i;
  let j;
  result = '';
  for (j = 0; j < 8; j += 1) {
    if (j === 8 || j === 12 || j === 16 || j === 20) result += '-';
    i = Math.floor(Math.random() * 16)
      .toString(16)
      .toUpperCase();
    result += i;
  }
  return result;
};

export default canvasHandler;
