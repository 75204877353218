import React, {
  useState, useMemo, useRef, useEffect, useLayoutEffect, forwardRef, useContext,
} from 'react';

import {
  Canvas,
  extend,
  useFrame,
  useLoader,
  useThree
} from '@react-three/fiber';

import * as THREE from 'three';
// import { EffectComposer, GodRays } from '@react-three/postprocessing';
// import { BlendFunction, Resizer, KernelSize } from 'postprocessing';
import SunCalc from 'suncalc';
import { DesignContext } from '../contexts/designContext.js';
import { sunPosition, segmentProperties } from '../algorithms/geometry.js';

// import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
// import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
// import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass";

// extend({ EffectComposer, RenderPass, UnrealBloomPass });

const sunMarkers = [];

const Sun = () => {
  const {
    center,
    scale,
    ranges,
    showSunLight,
    coordinates,
    month,
    vertices3D,
    weather,
  } = useContext(DesignContext);

  const { scene } = useThree();

  const sunRiseToSunSet = true;

  const sunLightRef = useRef();
  const sunRef = useRef();
  const targetRef = useRef();
  const [{ azimuthAngle, elevateAngle }, setAngle] = useState({ azimuthAngle: 30, elevateAngle: 60 });
  const [times, setTimes] = useState();
  const [time, setTime] = useState(() => new Date(Date.UTC(new Date().getUTCFullYear(), month, 15)));

  const [segProps, setSegProps] = useState([]);
  const [lines, setLines] = useState([]);

  // useEffect(() => {
  //   const ps = [];
  //   vertices3D.forEach((seg) => ps.push(segmentProperties(seg)));
  //   setSegProps(ps);

  //   const ls = ps.map(({ center, normal }) => {
  //     const points = [];
  //     const end = center.clone().add(normal.clone().multiplyScalar(10));
  //     points.push(new THREE.Vector3(center.x, center.z, center.y));
  //     points.push(new THREE.Vector3(end.x, end.z, end.y));
  //     const geometry = new THREE.BufferGeometry().setFromPoints(points);
  //     return geometry;
  //   });
  //   setLines(ls);
  // }, [vertices3D]);

  // const [h, setH] = useState(0);
  // const [ir, setIr] = useState(0);
  // useEffect(() => {
  //   const data = weather.weatherData.dn && weather.weatherData.dn[weather.monthNames[month - 1]];
  //   if (data) setIr(data[15]);
  //   setH(0);
  // }, [month, coordinates, weather.weatherData]);

  // useFrame(() => {
  //   if (ir[Math.floor(h)] > 0) {
  //     const date = new Date(Date.UTC(new Date().getUTCFullYear(), month, 15));
  //     const sunTimes = SunCalc.getTimes(date, coordinates[0], coordinates[1]);
  //     const tm = new Date(sunTimes.nadir.getTime() + (h * 60 * 60 * 1000) + (30 * 60 * 1000));

  //     const d = 150;
  //     const [el, az] = sunPosition(tm, coordinates[0], coordinates[1]);
  //     setAngle({ azimuthAngle: az - Math.PI / 2, elevateAngle: el });

  //     const x = Math.cos(azimuthAngle) * d;
  //     const z = Math.sin(azimuthAngle) * d;
  //     sunLightRef.current.position.x = x + sunLightRef.current.target.position.x;
  //     sunLightRef.current.position.z = z + sunLightRef.current.target.position.z;
  //     sunLightRef.current.position.y = Math.sin(elevateAngle) * d;
  //     sunRef.current.position.set(sunLightRef.current.position.x, sunLightRef.current.position.y, sunLightRef.current.position.z);
  //     setH((oldh) => (oldh + 0.02) % 24);
  //   } else {
  //     setH((oldh) => (oldh + 1) % 24);
  //   }
  // });

  useEffect(() => {
    const date = new Date(Date.UTC(new Date().getUTCFullYear(), month, 15));
    const sunTimes = SunCalc.getTimes(date, coordinates[0], coordinates[1]);
    setTimes(sunTimes);
    setTime(new Date(sunTimes.sunrise.getTime()));
    // sunMarkers.forEach((marker) => scene.remove(marker));
    // sunMarkers.splice(0, sunMarkers.length);
  }, [month, coordinates]);

  useFrame(() => {
    if (showSunLight && sunLightRef && sunLightRef.current) {
      const d = 150;
      const [el, az] = sunPosition(time, coordinates[0], coordinates[1]);
      setAngle({ azimuthAngle: az - Math.PI / 2, elevateAngle: el });
      let newTime = new Date(time);
      if (sunRiseToSunSet && (newTime < times.sunrise || newTime > times.sunset)) {
        newTime = new Date(times.sunrise.getTime());
      }
      newTime.setTime(newTime.getTime() + (1 * 60 * 1000));
      if (!sunRiseToSunSet) newTime.setDate(time.getDate());
      setTime(newTime);
      const x = Math.cos(azimuthAngle) * Math.cos(elevateAngle) * d;
      const z = Math.sin(azimuthAngle) * Math.cos(elevateAngle) * d;
      sunLightRef.current.position.x = x + sunLightRef.current.target.position.x;
      sunLightRef.current.position.z = z + sunLightRef.current.target.position.z;
      sunLightRef.current.position.y = Math.sin(elevateAngle) * d;
      sunRef.current.position.set(sunLightRef.current.position.x, sunLightRef.current.position.y, sunLightRef.current.position.z);
    }

    // if (sunLightRef && sunLightRef.current) {
    //   segProps.forEach((segProp) => {
    //     const L = sunRef.current.position.clone().sub(segProp.center).normalize();
    //     segProp.L = L;
    //   });
    // }

    // const startMarkerGeometry = new THREE.SphereGeometry(0.5, 15, 15);
    // const startMarkerMaterial = new THREE.MeshBasicMaterial({ color: 'red' });
    // const marker = new THREE.Mesh(startMarkerGeometry, startMarkerMaterial);
    // marker.position.set(sunRef.current.position.x, sunRef.current.position.y, sunRef.current.position.z);
    // sunMarkers.push(marker);
    // scene.add(marker);
  });

  useEffect(() => {
    scene.add(sunLightRef.current.target);
    scene.add(targetRef.current);
    sunLightRef.current.target = targetRef.current;
  }, [scene, sunLightRef]);

  const [minx, miny, maxx, maxy] = ranges;
  const target = [(minx + (maxx - minx) / 2 - center[0]) * scale, 0, (miny + (maxy - miny) / 2 - center[1]) * scale];

  return (
    <>
      <group name="sun">
        <hemisphereLight name="ambientLight" skyColor={0xffffff} groundColor={0x000000} intensity={0.25} position={[target[0], 100, target[2]]} />
        <directionalLight
          name="sunLight"
          ref={sunLightRef}
          position={[100, 50, 100]}
          intensity={1.75}
          castShadow
          shadow-bias={0}
          shadow-radius={0}
          shadow-mapSize-height={1024 * 2}
          shadow-mapSize-width={1024 * 2}
          shadow-camera-far={500}
          shadow-camera-left={-150}
          shadow-camera-right={150}
          shadow-camera-top={90}
          shadow-camera-bottom={-90}
          shadow-camera-zoom={1}
        >
          <object3D ref={targetRef} attach="target" position={target} />
        </directionalLight>
        {/* <mesh
          visible
          position={target}
        >
          <sphereGeometry attach="geometry" args={[5, 10, 10]} />
          <meshBasicMaterial color="red" />
        </mesh> */}
        {/* <rectAreaLight
          intensity={1}
          rotation={[-Math.PI / 2, 0, 0]}
          position={[0, 50, 0]}
          width={100}
          height={100}
          color={0xffffff}
          lookAt={[0, 0, 0]}
          penumbra={1}
          castShadow
        /> */}
        <mesh
          name="sunSphere"
          ref={sunRef}
          visible={showSunLight}
          position={[100, 50, 100]}
        >
          <sphereGeometry attach="geometry" args={[5, 32, 32]} />
          <meshBasicMaterial color="yellow" />
        </mesh>
      </group>
      <group normal="vectors" visible={false}>
        {lines && lines.map((line) => (
          <line geometry={line}>
            <lineBasicMaterial color="green" />
          </line>
        ))}
      </group>
    </>
  );
};

// export const SunWithEffects = ({ showSunLight }) => {
//   const sunRef = useRef();
//   return (
//     <>
//       <Sun showSunLight ref={sunRef} />
//       {sunRef.current && (
//         <EffectComposer multisampling={0}>
//           <GodRays
//             sun={sunRef.current}
//             blendFunction={BlendFunction.Screen}
//             samples={30}
//             density={0.97}
//             decay={0.96}
//             weight={0.6}
//             exposure={0.4}
//             clampMax={1}
//             width={Resizer.AUTO_SIZE}
//             height={Resizer.AUTO_SIZE}
//             kernelSize={KernelSize.SMALL}
//             blur={true}
//           />
//         </EffectComposer>
//       )}
//     </>
//   );
// }

export default Sun;
