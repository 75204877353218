import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, InputGroup, FormControl, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

const labels = {
  Eave: '#39D86F',
  Ridge: '#F30A0A',
  Rake: '#57519C',
  Hip: '#07FFF0',
  Valley: '#D825BB',
  // Other: '#D9E0E8',
};

function Setback({
  fireSetbacks,
  setbackMode,
  setbackModeHandler,
  fireSetbackHandler,
}) {
  const handleRadioButtonChange = (value) => setbackModeHandler(value);
  const handleEdgeLabelSetback = (e) => fireSetbackHandler(+e.target.value, e.target.id);

  return (
    <div>
      <ToggleButtonGroup
        className="mb-3 btn-block"
        type="radio"
        name="options"
        defaultValue={0}
        onChange={handleRadioButtonChange}
      >
        <ToggleButton variant="primary" style={{ color: setbackMode === 0 ? 'white' : 'gray' }} value={0}>General Fire Setback</ToggleButton>
        <ToggleButton variant="primary" style={{ color: setbackMode === 1 ? 'white' : 'gray' }} value={1}>Edge Label Setbacks</ToggleButton>
      </ToggleButtonGroup>
      {(setbackMode === 0) && (
        <InputGroup size="sm" className="w-75">
          <FormControl
            id="general"
            type="number"
            min={0}
            defaultValue={fireSetbacks.general || 0}
            onBlur={handleEdgeLabelSetback}
          />
          <InputGroup.Append>
            <InputGroup.Text>inches</InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
      )}
      {(setbackMode === 1) && Object.entries(labels).map(([label, color]) => {
        const lbl = label.toLowerCase();
        return (
          <Row key={lbl}>
            <Col>
              <InputGroup size="sm" className="mb-2 w-75">
                <InputGroup.Prepend>
                  <InputGroup.Text
                    className="text-white text-shadow"
                    style={{ backgroundColor: color, width: 60 }}
                  >
                    {label}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  id={lbl}
                  type="number"
                  defaultValue={fireSetbacks[lbl] || 0}
                  onBlur={handleEdgeLabelSetback}
                />
                <InputGroup.Append>
                  <InputGroup.Text>inches</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Col>
          </Row>
        );
      })}
    </div>
  );
}

Setback.propTypes = {
  fireSetbacks: PropTypes.shape({
    general: PropTypes.number,
    eave: PropTypes.number,
    ridge: PropTypes.number,
    rake: PropTypes.number,
    hip: PropTypes.number,
    valley: PropTypes.number,
  }).isRequired,
  setbackMode: PropTypes.number.isRequired,
  setbackModeHandler: PropTypes.func.isRequired,
  fireSetbackHandler: PropTypes.func.isRequired,
};

export default Setback;
