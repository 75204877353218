import React, { useState, useEffect, useMemo, useRef, forwardRef, useImperativeHandle } from 'react';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { extend, useThree, useFrame } from '@react-three/fiber';
import * as THREE from 'three';

extend({ OrbitControls });

export default forwardRef(({ viewMode = '3D' }, forwardedRef) => {
  const orbitRef = useRef();
  const {
    size, camera, gl, set,
  } = useThree();

  const [isChanging, setIsChanging] = useState(false);

  useImperativeHandle(forwardedRef,
    () => ({
      setOrbitEnabled(isEnabled) {
        if (orbitRef.current.enabled !== isEnabled) {
          orbitRef.current.enabled = isEnabled;
          if (isEnabled) orbitRef.current.reset();
          else orbitRef.current.saveState();
        }
      },
      isChanging() { return isChanging; },
      getCamera() { return camera; },
    }));

  useFrame((state) => orbitRef.current.update());

  const { width, height } = size;

  const camera2D = useMemo(() => {
    const newCamera = new THREE.OrthographicCamera(width / -2, width / 2, height / 2, height / -2, -200, 1024);
    newCamera.position.set(0, 100, 0);
    newCamera.up.set(0, 1, 0);
    newCamera.lookAt(0, 0, 0);
    newCamera.zoom = 3;
    newCamera.updateProjectionMatrix();
    return newCamera;
  }, [width, height]);

  const camera3D = useMemo(() => {
    const newCamera = new THREE.PerspectiveCamera(60, width / height, 0.1, 1024);
    newCamera.position.set(0, 100, 200);
    newCamera.up.set(0, 1, 0);
    newCamera.lookAt(0, 0, 0);
    newCamera.fov = 60;
    newCamera.far = 1024;
    newCamera.updateProjectionMatrix();
    return newCamera;
  }, [width, height]);

  useEffect(() => {
    if (!orbitRef.current) return;
    const v2D = viewMode !== '3D';
    if (v2D) {
      camera2D.position.set(0, 100, 0);
      camera2D.up.set(0, 1, 0);
      camera2D.lookAt(0, 0, 0);
      camera2D.zoom = 3;
      camera2D.updateProjectionMatrix();
      set({ camera: camera2D });
    } else {
      set({ camera: camera3D });
    }
  }, [viewMode]);

  return (
    <orbitControls
      ref={orbitRef}
      args={[camera, gl.domElement]}
      autoRotate={false}
      enableDamping
      dampingFactor={0.05}
      screenSpacePanning
      maxZoom={40}
      minZoom={2}
      maxPolarAngle={Math.PI / 2 - 0.05}
      maxDistance={500}
      enableRotate={viewMode === '3D'}
    />
  );
});
