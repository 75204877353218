import React from 'react';
import PropTypes from 'prop-types';
import { Card, Table, Tabs, Tab } from 'react-bootstrap';
import { rad2deg } from './DesignTool/utils';

const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

function ShadeReport({ wattage, segments, panels, optPitchAndAzimuth }) {
  const annualData = segments.map(({ id, acAnnual, acMonthly, solarAccess, tsrf, tof }) => {
    const panel = panels.find((pnl) => pnl.id === id);
    // if (panel === undefined) return null;
    // acAnnual = acMonthly.reduce ? acMonthly.reduce((a, b) => a + b, 0) : 0;
    const annualTSRF = tsrf[12];
    const annualTOF = tof[12];
    const annualSolarAccess = solarAccess[12];
    // const annualTSRF = tsrf.filter((el, idx) => idx < 12).reduce((a, b) => a + b, 0) / 12;
    // const annualTOF = tof.filter((el, idx) => idx < 12).reduce((a, b) => a + b, 0) / 12;
    // const annualSolarAccess = annualTSRF / annualTOF;
    const each = (acAnnual * annualSolarAccess * wattage) / 1000;
    const number = panel ? panel.horizontal.points.length + panel.vertical.points.length : '--';
    const total = panel ? (each * number).toFixed(2) : '--';
    return {
      id, number, each, total, annualSolarAccess, annualTSRF, annualTOF,
    };
  }); // .filter((item) => Boolean(item));

  return (
    <Card className="mx-auto mt-5 shadow" body>
      <h1 className="mb-3 text-center font-weight-light">Design Summary</h1>
      <Table striped borderless hover size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Max. Panels</th>
            <th>Annual Production/Panel</th>
            <th>Total Annual Production</th>
            <th>Solar Access</th>
            <th>TSRF</th>
            <th>TOF</th>
          </tr>
        </thead>
        <tbody>
          {annualData.map(({
            id, number, each, total, annualSolarAccess, annualTSRF, annualTOF,
          }, index) => (
            <tr key={id}>
              <td>{index + 1}</td>
              <td>{number}</td>
              <td>{`${each?.toFixed(2)} kWh`}</td>
              <td>{`${total} kWh`}</td>
              <td>{annualSolarAccess?.toFixed(3)}</td>
              <td>{annualTSRF?.toFixed(3)}</td>
              <td>{annualTOF?.toFixed(3)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {optPitchAndAzimuth
        && <p>{`The TOF and TSRF values are based on optimal pitch ${optPitchAndAzimuth.pitch?.toFixed(1)}, and azimuth ${optPitchAndAzimuth.azimuth?.toFixed(1)}.`}</p>}
      <Tabs defaultActiveKey="access" id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey="access" title="Solar Access">
          <h5 className="mt-4">Monthly Solar Access Per Roof Segment</h5>
          <Table striped borderless hover size="sm" title="Solar Access">
            <thead>
              <tr>
                <th>#</th>
                {MONTHS.map((month) => (
                  <th key={month}>{month}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {segments.map((seg, index) => (
                <tr key={seg.id}>
                  <td>{index + 1}</td>
                  {seg.solarAccess.map((value, idx) => (
                    idx < 12 ? (<td key={`${seg.id}-${idx + 1}`}>{value?.toFixed(3)}</td>) : null
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </Tab>
        <Tab eventKey="tsrf" title="TSRF">
          <h5 className="mt-4">Monthly TSRF Per Roof Segment</h5>
          <Table striped borderless hover size="sm" title="TSRF">
            <thead>
              <tr>
                <th>#</th>
                {MONTHS.map((month) => (
                  <th key={month}>{month}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {segments.map((seg, index) => (
                <tr key={seg.id}>
                  <td>{index + 1}</td>
                  {seg.tsrf.map((value, idx) => (
                    idx < 12 ? (<td key={`${seg.id}-${idx + 1}`}>{value?.toFixed(3)}</td>) : null
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </Tab>
        <Tab eventKey="tof" title="TOF">
          <h5 className="mt-4">Monthly TOF Per Roof Segment</h5>
          <Table striped borderless hover size="sm" title="TOF">
            <thead>
              <tr>
                <th>#</th>
                {MONTHS.map((month) => (
                  <th key={month}>{month}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {segments.map((seg, index) => (
                <tr key={seg.id}>
                  <td>{index + 1}</td>
                  {seg.tof.map((value, idx) => (
                    idx < 12 ? (<td key={`${seg.id}-${idx + 1}`}>{value?.toFixed(3)}</td>) : null
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </Tab>
      </Tabs>

    </Card>
  );
}

ShadeReport.propTypes = {
  wattage: PropTypes.number.isRequired,
  // segments
};

export default ShadeReport;
