import React, {
  useState, useContext, useEffect, useLayoutEffect, useRef, useMemo, forwardRef, useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { Canvas, useFrame, useThree, createRoot } from '@react-three/fiber';
import * as THREE from 'three';
import { DesignContext } from '../contexts/designContext.js';

import Roof from './Roof.js';
import Wall from './Wall.js';
import Vertex from './Vertex.js';
import Tree from './Tree.js';
import Ground from './Ground.js';
import Sun from './Sun.js';
import Sky from './Sky.js';
import GridHelper from './GridHelper.js';
import Controls from './Controls.js';
import ObjectSelection from './SelectionBox.js';
import Annotations from './Annotations.js';
import Drawing from './Drawing.js';

export const Scene = forwardRef(({ data }, forwardedRef) => {
  const {
    segments,
    obstacles,
    trees,
    annotations,
    accordion,
    gwidth,
    gheight,
    canvas,
    controls,
    sceneRef,
    viewMode,
    coordinates,
    resolution,
    rgbTexture,
    texture,
    dsmTexture,
    orgDSMTexture,
    minDSM,
    maxDSM,
    displacementBias,
    dsmOffset,
    materials,
    month,
    weather,
    setback,
    insertObject,
    readonly,
    selectedObjects,
    segmentSelection,
    pointSelection,
    lineSelection,
    panelSelection,
    setbackSelection,
    panelVisibility,
    moveStatus,
    showGround,
    showRoofs,
    showTrees,
    showSunLight,
    showSetbacks,
    showDSM,
    showPanels,
    showDSMHD,
    showDSMHouse,
    cx,
    cy,
    c,
    center,
    scale,
    ranges,
    orgVertices,
    vertices,
    vertices3D,
    verticesType,
    parents,
    UV,
    eaves,
    pitches,
    azimuths,
    eaveHeights,
    treeData,
    panelData,
    getSetbacks,
    clearAllSelection,
    addToSelectedObjects,
    removeFromSelectedObjects,
    addSelectedPoint,
    removeSelectedPoint,
    moveSelection,
    movePanels,
    insertPanels,
    addShape,
    editShapes,
    mode,
    labels,
    changeClockwise,
    tool,
    viewState,
    iframeCallback,
    designFrame,
  } = data;

  const roofRefs = useRef([]);

  const [roofVertices, obstacleVertices, obstacleShapeTypes] = useMemo(() => {
    const roofs = vertices3D?.filter((s, si) => verticesType[si] === 'roof');
    const obs = vertices3D?.filter((s, si) => verticesType[si] === 'obstacle');
    const obsTypes = new Array(obstacles.length);
    obstacles.forEach((ob, obi) => {
      obsTypes[obi] = ob.shape;
      if (ob.shape === 'Point') {
        const geom = obs[obi];
        const cent = geom.reduce((acc, item) => acc.add(item), new THREE.Vector3(0, 0, 0)).multiplyScalar(1.0 / geom.length);
        obs[obi] = [cent, geom[0]];
      }
    });
    return [roofs, obs, obsTypes];
  }, [vertices3D, verticesType, obstacles]);

  const treeVertices = useMemo(() => treeData.map((t) => [new THREE.Vector3(...t.position), new THREE.Vector3(...t.end)]), [trees, treeData]);
  const shapeTypes = useMemo(() => {
    return vertices.map((_,index) => index < segments.length ? segments[index].shape : obstacles[index - segments.length].shape)
  }, [segments, obstacles]);

  const drivewayVertices = useMemo(() => {
    return annotations && annotations.filter((a) => a.objectType && a.objectType === 'Driveway').map((a) => a.geometry);
  }, [annotations]);

  const drivewayText = useMemo(() => {
    return annotations && annotations.filter((a) => a.objectType && a.objectType === 'Driveway').map((a) => a.text);
  }, [annotations]);

  const wiringVertices = useMemo(() => {
    return annotations && annotations.filter((a) => a.objectType && a.objectType === 'Wiring').map((a) => a.geometry);
  }, [annotations]);

  const metersVertices = useMemo(() => {
    return annotations && annotations.filter((a) => a.objectType && a.objectType === 'Meter').map((a) => a.geometry);
  }, [annotations]);

  const getParentRoof = (i) => {
    const pi = parents[i];
    if (pi) return roofRefs.current[pi[0]];
    return null;
  };

  useImperativeHandle(forwardedRef,
    () => ({
      getCamera() { return controls.current.getCamera(); },
    }));

  return (
    <DesignContext.Provider value={data}>
      <Sky />
      <Sun />
      <group name="roof" rotation={[Math.PI / 2.0, 0, 0]} castShadow receiveShadow visible={showRoofs}>
        {vertices.filter((poly) => poly !== null).map((poly, index) => (
          <Roof
            ref={(el) => roofRefs.current[index] = el}
            key={index}
            index={index}
            type={verticesType[index]}
            uv={UV[index]}
            vertices={poly}
            eave={eaves[index]}
            pitch={pitches[index]}
            azimuths={azimuths[index]}
            eaveHeight={eaveHeights[index]}
            isSelected={selectedObjects.roofSegments.includes(index)}
            selectable={(segmentSelection || accordion === 'revise') && verticesType[index] === 'roof' && !readonly && mode === 'select'}
            panels={panelData[index] ?? null}
            fireSetbacks={getSetbacks(poly, index)}
            shapeType={shapeTypes[index]}
            getParentRoof={getParentRoof}
          />
        ))}
      </group>
      <group name="floor" rotation={[Math.PI / 2.0, 0, 0]} castShadow receiveShadow visible={showRoofs}>
        {orgVertices.map((poly, index) => shapeTypes[index] != "Line" && (
          <Roof
            key={index}
            uv={UV[index]}
            vertices={poly}
            eave={eaves[index]}
            eaveHeight={0}
            selectable={false}
            type="floor"
            shapeType={shapeTypes[index]}
          />
        ))}
      </group>
      <group name="points" rotation={[Math.PI / 2.0, 0, 0]} castShadow receiveShadow visible={showRoofs}>
        {pointSelection && !readonly ? vertices3D.map((poly, pIndex) => (verticesType[pIndex] === 'roof' ? (
          poly.map((v, vIndex) => (
            <Vertex
              position={[v.x, v.y, -v.z]}
              key={`${pIndex}-${vIndex}`}
              index={[pIndex, vIndex]}
              addSelectedPoint={addSelectedPoint}
              removeSelectedPoint={removeSelectedPoint}
              isSelected={selectedObjects.roofVertices[pIndex]?.includes(vIndex) ?? false}
              selectable
            />
          ))) : null)) : null}
      </group>
      <group name="walls" rotation={[Math.PI / 2.0, 0, 0]} visible={showRoofs}>
        {vertices3D.map((poly, index) => poly && shapeTypes[index] != "Line" && (
          <Wall vertices={poly} />
        ))}
      </group>
      <group name="trees" castShadow receiveShadow>
        {treeData.map((tree) => (
          <Tree
            position={tree.position}
            height={tree.height}
            radius={tree.radius}
            treeType={tree.treeType}
            index={tree.index}
            key={tree.index}
            isSelected={false}
            selectable={false}
            visible={showTrees}
          />
        ))}
      </group>
      <group name="ground" rotation={[Math.PI / 2.0, 0, 0]} castShadow receiveShadow>
        <Ground
          showSunLight
          showDSM
          position={[(cx / c) * scale, (cy / c) * scale, 0]}
          width={gwidth * scale}
          height={gheight * scale}
          visible={showGround && showSunLight && showDSM}
        />
        <Ground
          showSunLight
          showDSM={false}
          position={[(cx / c) * scale, (cy / c) * scale, 0]}
          width={gwidth * scale}
          height={gheight * scale}
          visible={showGround && showSunLight && !showDSM}
        />
        <Ground
          showSunLight={false}
          showDSM
          position={[(cx / c) * scale, (cy / c) * scale, 0]}
          width={gwidth * scale}
          height={gheight * scale}
          visible={showGround && !showSunLight && showDSM}
        />
        <Ground
          showSunLight={false}
          showDSM={false}
          position={[(cx / c) * scale, (cy / c) * scale, 0]}
          width={gwidth * scale}
          height={gheight * scale}
          visible={showGround && !showSunLight && !showDSM}
        />
      </group>
      <group name="panel_points" rotation={[Math.PI / 2.0, 0, 0]}>
        {false && panelData ? panelData.map((poly, pIndex) => (
          poly.points3D.map((v, vIndex) => (
            v.map((vt, vti) => (
              <Vertex
                position={[vt.x, vt.y, -vt.z]}
                index={[pIndex, vIndex]}
                addSelectedPoint={addSelectedPoint}
                removeSelectedPoint={removeSelectedPoint}
                selectable={false}
                size={0.2}
              />
            ))
          )))) : null}
      </group>
      <group name="eaves" rotation={[Math.PI / 2.0, 0, 0]}>
        {false && eaves ? eaves.map(([a, b, type], pIndex) => (type ? (
          <>
            <Vertex
              position={[a.x, a.y, -15 + (-1 * pIndex)]}
              selectable={false}
              size={0.2}
              color="red"
            />
            <Vertex
              position={[b.x, b.y, -20 + (-1 * pIndex)]}
              selectable={false}
              size={0.2}
              color="blue"
            />
          </>
        ) : null)) : null}
      </group>
      <ObjectSelection
        canvas={canvas}
        controls={controls}
        vertices={vertices3D.filter((v3, v3i) => verticesType[v3i] === 'roof')}
        panels={panelData}
        moveSelection={moveSelection}
        moveFunction={panelSelection ? movePanels : null}
        insertObject={insertObject}
        insertFunction={panelSelection ? insertPanels : null}
        enabled
      />
      <Annotations
        canvas={canvas}
        controls={controls}
        enabled
      />
      <Drawing
        canvas={canvas}
        controls={controls}
        shapes={roofVertices}
        labels={labels}
        tool="Polygon"
        enabled={accordion === 'segments'}
        visible={(accordion === 'segments' || mode !== 'select') && showRoofs}
      />
      <Drawing
        canvas={canvas}
        controls={controls}
        shapes={obstacleVertices}
        shapeTypes={obstacleShapeTypes}
        tool={tool}
        maxPoints={tool == "Line" ? 2: 1e3}
        enabled={accordion === 'obstacles'}
        visible={(accordion === 'obstacles' || mode !== 'select') && showRoofs}
      />
      <Drawing
        canvas={canvas}
        controls={controls}
        shapes={treeVertices}
        tool="Circle"
        enabled={accordion === 'trees'}
        visible={(accordion === 'trees' || mode !== 'select') && showTrees}
      />
      <Drawing
        canvas={canvas}
        controls={controls}
        shapes={drivewayVertices}
        tool="Polygon"
        objectType="Driveway"
        enabled={accordion === 'annotations' && viewState == "driveway"}
        visible={accordion === 'annotations' && viewMode == "2D"}
        texts={drivewayText}
        backgroundColor="green"
        backgroundStyle="hashed"
        fontScale={2.0}
      />
      <Drawing
        canvas={canvas}
        controls={controls}
        shapes={wiringVertices}
        tool="Line"
        objectType="Wiring"
        enabled={accordion === 'annotations' && viewState == "wiring"}
        visible={accordion === 'annotations' && viewMode == "2D"}
        dots={true}
        ringScale={0.4}
      />
      <Drawing
        canvas={canvas}
        controls={controls}
        shapes={metersVertices}
        tool="Line"
        objectType="Meter"
        maxPoints={2}
        enabled={accordion === 'annotations' && viewState == "meter"}
        visible={accordion === 'annotations' && viewMode == "2D"}
        dots={true}
        lineColor="red"
        forceMeasurements={true}
        fontScale={2.0}
        hasSegments={true}
      />
      <GridHelper visible={viewMode === '3D'} />
      <Controls ref={controls} viewMode={viewMode} />
    </DesignContext.Provider>
  );
});

export default Scene;
