import React from 'react';
import { Table } from 'react-bootstrap';
import { formatCurrency } from 'Main/utils';

/**
 * Calculate annual net metering with monthly banking
 * @param {Object} params Parameters for calculation
 * @param {Array} params.monthlyProduction Array of monthly production values
 * @param {Array} params.monthlyConsumption Array of monthly consumption values
 * @param {number} params.systemSize System size in kW
 * @param {Object} params.utility Utility configuration
 * @param {Object} params.panel Panel configuration with degradation values
 * @returns {Object} Calculation results
 */
export const calculateAnnualNetMeteringV2 = ({
    monthlyProduction,
    monthlyConsumption,
    systemSize,
    utility,
    panel,
    escalation
}) => {
    // Get rates configuration
    const rates = {
        // Pre-solar rates - ensure all values are numbers
        preSolarFixed: Number(utility.fixed_charge || 0),
        preSolarRate: Number(utility.rate_amount || 0),
        // Post-solar rates from net_metering_specs
        postSolarFixed: Number(utility.net_metering_specs?.ufsc || 22),
        exportRate: Number(utility.net_metering_specs?.export_rate || 0),
        offsetSavings: Boolean(utility.net_metering_specs?.offset_savings),
        // Add fixed cost type and related fields
        fixedCostType: utility.net_metering_specs?.fixed_cost_type || 1,
        costCap: Number(utility.net_metering_specs?.cost_cap || 0),
        costPerKw: Number(utility.net_metering_specs?.cost_per_kw || 0)
    };

    // Get panel degradation values from panel object
    const firstYearDegradation = panel?.first_year_degradation ? Number(panel.first_year_degradation) / 100 : 0.02;
    const annualDegradation = panel?.degradation ? Number(panel.degradation) / 100 : 0.0025;

    // Get escalation rate (default to 3%)
    const escalationRate = typeof escalation === 'number' ? escalation : 0.03;

    console.log('Annual Net Metering Calculation - Using escalation rate:', {
        rawEscalation: escalation,
        escalationRate,
        isNumber: typeof escalation === 'number'
    });

    // Initialize results array for each year
    const yearlyResults = [];
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];

    // Get true-up month from specs (default to April)
    const trueUpMonth = utility.net_metering_specs?.trueup_month || "April";
    const trueUpMonthIndex = monthNames.indexOf(trueUpMonth);

    // Get current month from actual date
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const startMonthIndex = currentDate.getMonth();
    const currentMonth = monthNames[startMonthIndex];

    console.log('Calculation Parameters:', {
        currentMonth,
        trueUpMonth,
        escalationRate,
        startMonthIndex,
        currentYear
    });

    // Order months starting from current month
    const orderedMonths = [];
    for (let i = 0; i < 12; i++) {
        const monthIndex = (startMonthIndex + i) % 12;
        const yearOffset = monthIndex < startMonthIndex ? 1 : 0; // If we've wrapped around to next year
        orderedMonths.push({
            name: monthNames[monthIndex],
            originalIndex: monthIndex,
            calendarYear: currentYear + yearOffset,
            isTrueUpMonth: monthIndex === trueUpMonthIndex,
            isPreSolar: false // No pre-solar months when starting from current
        });
    }

    // Calculate for 30 years
    let creditsBank = 0; // Move credits bank outside the year loop to persist between years
    for (let year = 0; year < 30; year++) {
        // Apply rate escalations
        const yearRates = {
            preSolarFixed: rates.preSolarFixed * Math.pow(1 + escalationRate, year),
            preSolarRate: rates.preSolarRate * Math.pow(1 + escalationRate, year),
            postSolarFixed: rates.fixedCostType === 'constant' || rates.fixedCostType === 1
                ? rates.postSolarFixed * Math.pow(1 + escalationRate, year)
                : rates.postSolarFixed,
            exportRate: rates.exportRate * Math.pow(1 + escalationRate, year),
            offsetSavings: rates.offsetSavings,
            fixedCostType: rates.fixedCostType,
            costCap: rates.costCap,  // Cost cap doesn't escalate
            costPerKw: rates.costPerKw  // Cost per kW doesn't escalate
        };

        // Calculate post-solar fixed cost for this year
        const calculatePostSolarCost = () => {
            if (yearRates.fixedCostType === 'constant' || yearRates.fixedCostType === 1) {
                return yearRates.costCap || yearRates.postSolarFixed;
            }
            // For system size based cost, use reference system size from specs
            // const referenceSystemSize = utility.net_metering_specs?.system_size || systemSize;
            // Cap at reference system size if actual size is larger
            //const sizeToUse = Math.min(systemSize, referenceSystemSize);
            const sizeToUse = Number(systemSize);
            const calculatedCost = systemSize * yearRates.costPerKw;
            console.log('System Size Based Cost Calculation:', {
                actualSystemSize: systemSize,
                //referenceSystemSize,
                sizeUsedForCalc: sizeToUse,
                costPerKw: yearRates.costPerKw,
                calculatedCost,
                costCap: yearRates.costCap,
                finalCost: yearRates.costCap ? Math.min(calculatedCost, yearRates.costCap) : calculatedCost
            });
            return yearRates.costCap ? Math.min(calculatedCost, yearRates.costCap) : calculatedCost;
        };

        const postSolarFixedCost = calculatePostSolarCost();
        console.log('Post Solar Fixed Cost:', {
            fixedCostType: yearRates.fixedCostType,
            postSolarFixedCost,
            systemSize,
            costPerKw: yearRates.costPerKw,
            costCap: yearRates.costCap
        });

        // Apply degradation to production values using panel's actual degradation rates
        const degradationFactor = year === 0
            ? 1  // First year: no degradation
            : Math.pow(1 - annualDegradation, year - 1) * (1 - firstYearDegradation); // Year 2+: first year degradation, then annual compounding

        const monthlyResults = [];

        // Process each month in order
        orderedMonths.forEach((monthData, monthIndex) => {
            const consumption = Number(monthlyConsumption[monthData.originalIndex] || 0);
            // Apply degradation to production, but zero for pre-solar months in first year
            const production = monthData.isPreSolar && year === 0
                ? 0
                : Number(monthlyProduction[monthData.originalIndex] || 0) * degradationFactor;

            // Calculate basic values
            const remaining = production - consumption;

            // Check if next month is true-up month
            const nextMonth = orderedMonths[(monthIndex + 1) % 12];
            const isMonthBeforeTrueUp = nextMonth.isTrueUpMonth;

            // Initialize payment values
            let amountToPay = Number(postSolarFixedCost);
            let exportCredit = 0;
            let creditsLostAtTrueUp = 0;

            // For pre-solar months in first year, only charge pre-solar costs
            if (monthData.isPreSolar && year === 0) {
                amountToPay = Number(yearRates.preSolarFixed) +
                    (consumption * Number(yearRates.preSolarRate));
                exportCredit = 0;
                creditsBank = 0;
            } else {
                if (isMonthBeforeTrueUp && yearRates.offsetSavings && creditsBank > 0) {
                    // Month before true-up with offset_savings true
                    // First add this month's excess to bank if positive
                    if (remaining > 0) {
                        creditsBank += Number(remaining);
                    }
                    // Apply total accumulated credits at export rate
                    exportCredit = Number(creditsBank) * Number(yearRates.exportRate);
                    // Handle any shortage in this month
                    if (remaining < 0) {
                        amountToPay += Math.abs(Number(remaining)) * Number(yearRates.preSolarRate);
                    }
                } else if (monthData.isTrueUpMonth) {
                    // True-up month - only handle current month's production/consumption
                    if (remaining < 0) {
                        // Pay for shortage at pre-solar rate
                        amountToPay += Math.abs(Number(remaining)) * Number(yearRates.preSolarRate);
                    } else {
                        // Bank new credits if any
                        creditsBank = Number(remaining);
                    }
                } else {
                    // Normal month processing
                    if (remaining < 0) {
                        // We have a shortage
                        const shortage = Math.abs(Number(remaining));
                        const availableCredits = Number(creditsBank);

                        if (availableCredits > 0) {
                            // Use available credits first
                            const uncoveredShortage = Math.max(0, shortage - availableCredits);
                            if (uncoveredShortage > 0) {
                                // Pay for uncovered shortage at pre-solar rate
                                amountToPay += Number(uncoveredShortage) * Number(yearRates.preSolarRate);
                            }
                            creditsBank -= Math.min(availableCredits, shortage);
                        } else {
                            // No credits available, pay full shortage at pre-solar rate
                            amountToPay += Number(shortage) * Number(yearRates.preSolarRate);
                        }
                    } else if (remaining > 0) {
                        // Bank excess production
                        creditsBank += Number(remaining);
                    }
                }
            }

            // Calculate final amount after export credit
            const finalAmount = Math.max(0, Number(amountToPay) - Number(exportCredit));

            // Calculate payment details
            const energyCharge = Number(amountToPay) - Number(postSolarFixedCost);

            monthlyResults.push({
                month: monthData.name,
                calendarYear: monthData.calendarYear + year,  // Add year offset to base calendar year
                consumption: Number(consumption),
                production: Number(production),
                remaining: Number(remaining),
                creditsBank: Number(creditsBank),
                isTrueUpMonth: monthData.isTrueUpMonth,
                isMonthBeforeTrueUp: isMonthBeforeTrueUp,
                isPreSolar: monthData.isPreSolar && year === 0, // Only mark pre-solar in first year
                creditsLostAtTrueUp: Number(creditsLostAtTrueUp),
                postSolarFixed: Number(postSolarFixedCost),
                preSolarFixed: Number(yearRates.preSolarFixed),
                preSolarRate: Number(yearRates.preSolarRate),
                exportRate: Number(yearRates.exportRate),
                amountToPay: Number(finalAmount),
                exportCredit: Number(exportCredit),
                paymentDetails: {
                    fixedCharge: Number(postSolarFixedCost),
                    energyCharge: Number(energyCharge),
                    exportCredit: Number(exportCredit),
                    final: Number(finalAmount)
                }
            });
        });

        // Calculate annual totals (excluding pre-solar months for production/savings)
        const annualTotals = {
            totalProduction: monthlyResults.reduce((sum, m) =>
                sum + (m.isPreSolar ? 0 : m.production), 0),
            totalConsumption: monthlyResults.reduce((sum, m) => sum + m.consumption, 0),
            totalRemaining: monthlyResults.reduce((sum, m) =>
                sum + (m.isPreSolar ? 0 : m.remaining), 0),
            finalCreditsBank: creditsBank,
            totalCreditsLost: monthlyResults.reduce((sum, m) => sum + m.creditsLostAtTrueUp, 0),
            totalAmountToPay: monthlyResults.reduce((sum, m) => sum + m.amountToPay, 0),
            totalExportCredit: monthlyResults.reduce((sum, m) => sum + m.exportCredit, 0),
            isPartialYear: year === 0,
            activeMonths: monthlyResults.filter(m => !m.isPreSolar).length
        };

        // Calculate pre-solar costs for comparison
        const preSolarAnnualCost = (12 * yearRates.preSolarFixed) + // Annual fixed charges using pre-solar fixed charge
            (annualTotals.totalConsumption * yearRates.preSolarRate); // Energy charges using pre-solar rate

        // Calculate savings
        const annualSavings = preSolarAnnualCost - annualTotals.totalAmountToPay + annualTotals.totalExportCredit;

        // Add savings to totals
        annualTotals.preSolarCost = preSolarAnnualCost;
        annualTotals.savings = annualSavings;

        yearlyResults.push({
            year: year + 1,
            monthlyResults,
            annualTotals,
            rates: yearRates
        });
    }

    // Calculate cumulative savings
    let cumulativeSavings = 0;
    yearlyResults.forEach(year => {
        cumulativeSavings += year.annualTotals.savings;
        year.annualTotals.cumulativeSavings = cumulativeSavings;
    });

    return {
        yearlyResults,
        metadata: {
            currentMonth: monthNames[startMonthIndex],
            trueUpMonth,
            cycleStartMonth: monthNames[startMonthIndex], // Use actual start month
            escalationRate: escalationRate * 100, // Convert to percentage for display
            systemSize, // Add systemSize to metadata
            utility // Add utility to metadata for specs
        }
    };
};

/**
 * Annual Net Metering Table Component
 */
export const AnnualNetMeteringTable = ({ calculationResults }) => {
    if (!calculationResults?.yearlyResults) {
        return <div>No calculation data available</div>;
    }

    const { yearlyResults, metadata } = calculationResults;
    const [selectedYear, setSelectedYear] = React.useState(1);

    // Get current year's data
    const currentYear = yearlyResults.find(y => y.year === selectedYear);
    if (!currentYear) return null;

    const { monthlyResults, annualTotals, rates } = currentYear;
    const { systemSize, utility } = metadata; // Extract from metadata

    // Calculate 30-year totals
    const thirtyYearTotals = yearlyResults.reduce((acc, year) => ({
        totalProduction: acc.totalProduction + year.annualTotals.totalProduction,
        totalConsumption: acc.totalConsumption + year.annualTotals.totalConsumption,
        totalRemaining: acc.totalRemaining + year.annualTotals.totalRemaining,
        totalCreditsLost: acc.totalCreditsLost + year.annualTotals.totalCreditsLost,
        totalAmountToPay: acc.totalAmountToPay + year.annualTotals.totalAmountToPay,
        totalExportCredit: acc.totalExportCredit + year.annualTotals.totalExportCredit
    }), {
        totalProduction: 0,
        totalConsumption: 0,
        totalRemaining: 0,
        totalCreditsLost: 0,
        totalAmountToPay: 0,
        totalExportCredit: 0
    });

    return (
        <div className="table-responsive">
            <div className="mb-3">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <small className="text-muted">
                            {currentYear.annualTotals.isPartialYear ?
                                `Solar starts ${metadata.currentMonth} ${currentYear.monthlyResults[0].calendarYear}` :
                                `Year ${currentYear.year} (${currentYear.monthlyResults[0].calendarYear}-${currentYear.monthlyResults[11].calendarYear})`
                            }
                        </small>
                        <div className="mt-1">
                            <select
                                className="form-control form-control-sm"
                                value={selectedYear}
                                onChange={(e) => setSelectedYear(Number(e.target.value))}
                            >
                                {yearlyResults.map(year => {
                                    const firstMonth = year.monthlyResults[0];
                                    const lastMonth = year.monthlyResults[11];
                                    return (
                                        <option key={year.year} value={year.year}>
                                            Year {year.year} ({firstMonth.calendarYear}-{lastMonth.calendarYear})
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="text-right">
                        <small className="d-block">
                            <strong>Pre-Solar Fixed Charge:</strong> ${rates.preSolarFixed.toFixed(2)}/month
                        </small>
                        <small className="d-block">
                            <strong>Pre-Solar Rate:</strong> ${rates.preSolarRate.toFixed(4)}/kWh
                        </small>
                        <small className="d-block">
                            <strong>Post-Solar Fixed Charge:</strong> {rates.fixedCostType === 'constant' || rates.fixedCostType === 1 ?
                                `$${rates.postSolarFixed.toFixed(2)}/month` :
                                `$${rates.costPerKw.toFixed(2)}/kW (Cap: $${rates.costCap.toFixed(2)})`
                            }
                        </small>
                        {rates.exportRate > 0 && (
                            <small className="d-block">
                                <strong>Export Rate:</strong> ${rates.exportRate.toFixed(4)}/kWh
                            </small>
                        )}
                        <small className="d-block text-muted mt-1">
                            Escalation: {metadata.escalationRate}% per year
                        </small>
                    </div>
                </div>
            </div>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Month</th>
                        <th>Consumption (kWh)</th>
                        <th>Production (kWh)</th>
                        <th>Remaining (kWh)</th>
                        <th>Credits Banked (kWh)</th>
                        <th>Export Credit ($)</th>
                        <th>Pre-Solar Cost ($)</th>
                        <th>Post-Solar Cost ($)</th>
                        <th>Monthly Savings ($)</th>
                        <th>Cumulative Savings ($)</th>
                    </tr>
                </thead>
                <tbody>
                    {monthlyResults.map((month, index) => {
                        // Calculate monthly pre-solar cost using pre-solar fixed charge and rate
                        const monthlyPreSolarCost = Number(month.preSolarFixed) +
                            (Number(month.consumption) * Number(month.preSolarRate));

                        // Calculate monthly savings
                        const monthlySavings = monthlyPreSolarCost - Number(month.amountToPay) + Number(month.exportCredit);

                        // Calculate cumulative savings for this month
                        const cumulativeSavings = monthlyResults
                            .slice(0, index + 1)
                            .reduce((sum, m) => {
                                const mPreSolarCost = Number(m.preSolarFixed) + (Number(m.consumption) * Number(m.preSolarRate));
                                return sum + (mPreSolarCost - Number(m.amountToPay) + Number(m.exportCredit));
                            }, 0);

                        const preSolarTooltip = `
                            Fixed Charge: $${Number(month.preSolarFixed).toFixed(2)}
                            Energy Charge: $${(Number(month.consumption) * Number(month.preSolarRate)).toFixed(2)} (${Number(month.consumption).toFixed(2)} kWh × $${Number(month.preSolarRate).toFixed(4)}/kWh)
                            Total Pre-Solar: $${monthlyPreSolarCost.toFixed(2)}
                        `.trim();

                        const postSolarTooltip = `
                            ${rates.fixedCostType === 'constant' || rates.fixedCostType === 1 ?
                                `Fixed Charge: $${Number(month.postSolarFixed).toFixed(2)}` :
                                `System Size Based Charge: $${Number(month.postSolarFixed).toFixed(2)} (${rates.costPerKw.toFixed(2)}/kW × ${Math.min(systemSize, utility.net_metering_specs?.system_size || systemSize).toFixed(2)} kW)`
                            }
                            ${month.paymentDetails.energyCharge > 0 ? `\nEnergy Charge: $${Number(month.paymentDetails.energyCharge).toFixed(2)}` : ''}
                            ${month.exportCredit > 0 ? `\nExport Credit: -$${Number(month.exportCredit).toFixed(2)}` : ''}
                            \nFinal Amount: $${Number(month.amountToPay).toFixed(2)}
                        `.trim();

                        const savingsTooltip = `
                            Pre-Solar Cost: $${monthlyPreSolarCost.toFixed(2)}
                            Post-Solar Cost: -$${Number(month.amountToPay).toFixed(2)}
                            ${month.exportCredit > 0 ? `Export Credit: +$${Number(month.exportCredit).toFixed(2)}` : ''}
                            Total Savings: $${monthlySavings.toFixed(2)}
                        `.trim();

                        return (
                            <tr key={month.month}
                                style={{
                                    ...(month.isTrueUpMonth ? { backgroundColor: '#fff3cd' } : {}),
                                    ...(month.isPreSolar ? {
                                        backgroundColor: '#f5f5f5',
                                        color: '#999'
                                    } : {})
                                }}
                            >
                                <td>
                                    {month.month} {month.calendarYear}
                                    {month.isTrueUpMonth && <span className="ml-1 text-warning">*</span>}
                                    {month.isPreSolar && <span className="ml-1 text-muted">†</span>}
                                </td>
                                <td>{month.isPreSolar ? '-' : month.consumption.toFixed(2)}</td>
                                <td>{month.isPreSolar ? '-' : month.production.toFixed(2)}</td>
                                <td style={{ color: month.isPreSolar ? '#999' : (month.remaining >= 0 ? 'green' : 'red') }}>
                                    {month.isPreSolar ? '-' : month.remaining.toFixed(2)}
                                </td>
                                <td>
                                    {month.isPreSolar ? '-' : month.creditsBank.toFixed(2)}
                                    {!month.isPreSolar && month.isTrueUpMonth && month.creditsLostAtTrueUp > 0 && (
                                        <small className="text-warning ml-1">
                                            ({month.creditsLostAtTrueUp.toFixed(2)} lost)
                                        </small>
                                    )}
                                </td>
                                <td>
                                    {month.isPreSolar ? '-' :
                                        (month.exportCredit > 0 ? formatCurrency(month.exportCredit) : '-')}
                                </td>
                                <td title={preSolarTooltip}>
                                    {formatCurrency(monthlyPreSolarCost)}
                                </td>
                                <td title={postSolarTooltip}>
                                    {month.isPreSolar ? '-' : formatCurrency(month.amountToPay)}
                                </td>
                                <td title={savingsTooltip}
                                    style={{ color: month.isPreSolar ? '#999' : (monthlySavings >= 0 ? 'green' : 'red') }}>
                                    {month.isPreSolar ? '-' : formatCurrency(monthlySavings)}
                                </td>
                                <td style={{ color: cumulativeSavings >= 0 ? 'green' : 'red' }}>
                                    {formatCurrency(cumulativeSavings)}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <th>Annual Total</th>
                        <td>{annualTotals.totalConsumption.toFixed(2)}</td>
                        <td>{annualTotals.totalProduction.toFixed(2)}</td>
                        <td>{annualTotals.totalRemaining.toFixed(2)}</td>
                        <td>
                            {annualTotals.finalCreditsBank.toFixed(2)}
                            {annualTotals.totalCreditsLost > 0 && (
                                <small className="text-warning ml-1">
                                    (Total lost: {annualTotals.totalCreditsLost.toFixed(2)})
                                </small>
                            )}
                        </td>
                        <td>{formatCurrency(annualTotals.totalExportCredit)}</td>
                        <td>{formatCurrency(annualTotals.preSolarCost)}</td>
                        <td>{formatCurrency(annualTotals.totalAmountToPay)}</td>
                        <td style={{ color: annualTotals.savings >= 0 ? 'green' : 'red' }}>
                            {formatCurrency(annualTotals.savings)}
                        </td>
                        <td style={{ color: annualTotals.savings >= 0 ? 'green' : 'red' }}>
                            {formatCurrency(annualTotals.savings)}
                        </td>
                    </tr>
                </tfoot>
            </Table>
            <div className="mt-2">
                {metadata.trueUpMonth && (
                    <small className="text-warning d-block">
                        * True-up month: {rates.exportRate > 0 ? 'Excess credits sold at export rate' : 'Credits reset to zero'}
                    </small>
                )}
                {currentYear.annualTotals.isPartialYear && (
                    <small className="text-muted d-block">
                        † Pre-solar months (before {metadata.currentMonth})
                    </small>
                )}
            </div>
            <div className="mt-3">
                <h6>30-Year Summary</h6>
                <div className="row">
                    <div className="col-md-4">
                        <small className="d-block">
                            <strong>Total Production:</strong> {thirtyYearTotals.totalProduction.toFixed(2)} kWh
                        </small>
                        <small className="d-block">
                            <strong>Total Consumption:</strong> {thirtyYearTotals.totalConsumption.toFixed(2)} kWh
                        </small>
                        <small className="d-block">
                            <strong>Total Credits Lost:</strong> {thirtyYearTotals.totalCreditsLost.toFixed(2)} kWh
                        </small>
                    </div>
                    <div className="col-md-4">
                        <small className="d-block">
                            <strong>Total Export Credits:</strong> {formatCurrency(thirtyYearTotals.totalExportCredit)}
                        </small>
                        <small className="d-block">
                            <strong>Total Amount to Pay:</strong> {formatCurrency(thirtyYearTotals.totalAmountToPay)}
                        </small>
                        <small className="d-block">
                            <strong>Net Cost:</strong> {formatCurrency(thirtyYearTotals.totalAmountToPay - thirtyYearTotals.totalExportCredit)}
                        </small>
                    </div>
                    <div className="col-md-4">
                        <small className="d-block">
                            <strong>Pre-Solar 30-Year Cost:</strong> {formatCurrency(yearlyResults.reduce((sum, year) => sum + year.annualTotals.preSolarCost, 0))}
                        </small>
                        <small className="d-block">
                            <strong>Post-Solar 30-Year Cost:</strong> {formatCurrency(thirtyYearTotals.totalAmountToPay - thirtyYearTotals.totalExportCredit)}
                        </small>
                        <small className="d-block text-success">
                            <strong>Total 30-Year Savings:</strong> {formatCurrency(yearlyResults[yearlyResults.length - 1].annualTotals.cumulativeSavings)}
                        </small>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AnnualNetMeteringTable; 