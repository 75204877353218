import React, {
  useState, useContext, useRef, useEffect, useMemo, forwardRef, useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { LeadContext } from 'Leads/Details/hooks/context';
import Report from 'Leads/Details/Proposals/Report';

import {
  assert, string, object, number, nonempty, integer, optional, boolean, max, enums, define, size, min, defaulted, array, nullable, union, pattern,
} from 'superstruct';

const IFrameShadingReportScheme = optional(object({
  homeowner: optional(string()),
  client: optional(string()),
  address: optional(string()),
  coordinates: optional(pattern(nonempty(string()), /^((-?|\+?)?\d+(\.\d+)?),\s*((-?|\+?)?\d+(\.\d+)?)$/)),
  format: optional(string()),
}));

const Proposal = forwardRef(({ visible, pid, callback, context, companyName }, ref) => {
  const {
    uid, user, lead, proposalAPI, hardwareAPI, validUtility,
  } = useContext(LeadContext);

  const [hardware, setHardware] = useState(null);

  const proposal = useRef();
  const history = useHistory();

  const proposalCallback = (callbackType, data) => {
    if (window.parent) window.parent.postMessage({ callbackType, data }, '*');
    callback(callbackType, data);
  };

  const loadProposal = (id, proposalId) => {
    window.location.replace(`/iframe/${id}?id=${proposalId}`);
    // use this instead to eliminate reload after create proposal
    // history.push(`/iframe/${id}?id=${proposalId}`);
  };

  const handleMessage = (event) => {
    const { cmd, params = null } = event.data;
    switch (cmd) {
      case 'proposal.LoadProposal':
        if (params) loadProposal(params.uid, params.pid, params.api_key);
        break;
      case 'proposal.2D':
        if (proposal.current) proposal.current.switchView('2D');
        break;
      case 'proposal.3D':
        if (proposal.current) proposal.current.switchView('3D');
        break;
      case 'proposal.3DJson':
        if (proposal.current) proposal.current.handleDownload3DJSON();
        break;
      case 'proposal.ToggleShadingOverlay':
        if (proposal.current) proposal.current.toggleOverlay();
        break;
      case 'proposal.TogglePanelSelection':
        if (proposal.current) proposal.current.togglePanelSelection();
        break;
      case 'proposal.TogglePanelVisibility':
        if (proposal.current) proposal.current.toggleVisibility();
        break;
      case 'proposal.ShadingValues':
        if (proposal.current) proposal.current.shadingValues();
        break;
      case 'proposal.RoofOrientation':
        if (proposal.current) proposal.current.pitchAndAzimuith();
        break;
      case 'proposal.ProductionPerPanel':
        if (proposal.current) proposal.current.productionPerPanel();
        break;
      case 'proposal.ShadingValuesPerArray':
        if (proposal.current) proposal.current.shadingValuesPerArray();
        break;
      case 'proposal.HourlyProduction':
        if (proposal.current) proposal.current.hourlyProduction();
        break;
      case 'proposal.PanelOrientationPerArray':
        if (proposal.current) proposal.current.panelOrientation();
        break;
      case 'proposal.ToggleAllPanels':
        if (proposal.current) proposal.current.toggleAllPanels();
        break;
      case 'proposal.ToggleViewerMode':
        if (proposal.current) proposal.current.toggleViewerMode();
        break;
      case 'proposal.ProposalCosts':
        if (proposal.current) proposal.current.calcCosts();
        break;
      case 'proposal.Save':
        if (proposal.current) proposal.current.updateReport();
        break;
      case 'proposal.CaptureImage':
        if (proposal.current) {
          proposal.current.captureImage();
        }
        break;
      case 'proposal.CaptureThumbnail':
        if (proposal.current) {
          proposal.current.captureThumbnail();
        }
        break;
      case 'proposal.Copy':
        if (proposal.current) {
          if (params && params.pid) {
            proposal.current.copyProposal(params.pid);
          } else {
            proposal.current.copyProposal();
          }
        }
        break;
      case 'proposal.ShadingReport':
        if (proposal.current) {
          if (params) {
            assert(params, IFrameShadingReportScheme);
            console.log(`shading report with params: \n${params}`);
            proposal.current.downloadShadingReport(params);
          } else {
            console.log('shading report without params')
            proposal.current.downloadShadingReport();
          }
        }
        break;
      case 'proposal.SetOffset':
        if (proposal.current) {
          if (params) proposal.current.solarCalculationsWithOffset(params.offset);
        }
        break;
      case 'proposal.switchAnnotationMode':
        if (proposal.current) proposal.current.switchAnnotationMode();
        break;
      case 'proposal.setAnnotations':
        if (proposal.current) proposal.current.getAnnotations(params);
        break;
      case 'proposal.removeAnnotation':
        if (proposal.current && (params?.index || params?.index >=0)) proposal.current.removeAnnotation(params.index);
        break;
      case 'proposal.getAnnotations':
        if (proposal.current) proposal.current.getAnnotations();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    hardwareAPI.list().then((data) => { setHardware(data); });
  }, []);

  useImperativeHandle(ref, () => ({
    handleMessage,
    loadProposal,
    proposalCallback,
    uid,
    lead,
    proposal,
  }));

  const iframeProposal = useMemo(() => {
    if (!lead) return null;
    const fullAddress = `${lead.property.street}, ${lead.property.city}, ${lead.property.state}`;
    return (
      <Report
        ref={proposal}
        pid={pid}
        API={proposalAPI}
        hardware={hardware}
        currentUser={user}
        fullAddress={fullAddress}
        lead={lead}
        validUtility={validUtility}
        callback={proposalCallback}
        context={context}
        iframe
        companyName={companyName}
      />
    );
  }, [uid, lead, pid, hardware, context]);

  if (!visible || !hardware) return null;
  if (hardware) return iframeProposal;
});

Proposal.propTypes = {
  // pid: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default Proposal;
