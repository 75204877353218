import * as THREE from 'three';
import SVD from './svd.js';

export const pitchMap = [0, 5, 9, 14, 18, 23, 27, 30, 34, 37, 40, 43, 45, 47, 49, 51, 53];

const infernoPallete = [
  [0, 0, 4],
  [31, 12, 72],
  [85, 15, 109],
  [136, 34, 106],
  [186, 54, 85],
  [227, 89, 51],
  [249, 140, 10],
  [249, 201, 50],
  [252, 255, 164],
];

const mix = (c1, c2, ratio) => [c1[0] * (1 - ratio) + c2[0] * ratio, c1[1] * (1 - ratio) + c2[1] * ratio, c1[2] * (1 - ratio) + c2[2] * ratio];

export const gray2gradient = (gray) => {
  const p = infernoPallete;
  if (gray < 0.13) return mix(p[0], p[1], gray / 0.13);
  if (gray < 0.25) return mix(p[1], p[2], (gray - 0.13) / 0.12);
  if (gray < 0.38) return mix(p[2], p[3], (gray - 0.25) / 0.13);
  if (gray < 0.5) return mix(p[3], p[4], (gray - 0.38) / 0.12);
  if (gray < 0.63) return mix(p[4], p[5], (gray - 0.5) / 0.13);
  if (gray < 0.75) return mix(p[5], p[6], (gray - 0.63) / 0.12);
  if (gray < 0.88) return mix(p[6], p[7], (gray - 0.75) / 0.13);
  return mix(p[7], p[8], (gray - 0.88) / 0.12);
};

export const dist = (v1, v2) => Math.sqrt((v1[0] - v2[0]) ** 2 + (v1[1] - v2[1]) ** 2);

export const isClockWise = (v1, v2, v3) => v2.clone().sub(v1).clone().cross(v3.clone().sub(v2)).z >= 0;

export const counterClockWise = (geo, lines) => {
  let clockWisePower = 0;
  for (let i = 0; i < geo.length; i += 1) {
    const v1 = geo[i % geo.length];
    const v2 = geo[(i + 1) % geo.length];
    const v3 = geo[(i + 2) % geo.length];

    const p1 = new THREE.Vector3(v1[0], v1[1], 0);
    const p2 = new THREE.Vector3(v2[0], v2[1], 0);
    const p3 = new THREE.Vector3(v3[0], v3[1], 0);

    // const power = dist(v1, v2);
    if (isClockWise(p1, p2, p3)) clockWisePower += 1;
    else clockWisePower -= 1;
  }
  // const clockWisePower = THREE.ShapeUtils.area(geo);

  const geometry = geo.map(([x, y]) => [Math.round(x), Math.round(y)]);
  const lastVertex = geometry[geometry.length - 1];
  const isEqualStartEnd = geometry[0][0] === lastVertex[0] && geometry[0][1] === lastVertex[1];

  if (clockWisePower <= 0) {
    if (isEqualStartEnd) geometry.pop();
    return [geometry, lines.slice(), false];
  }

  if (isEqualStartEnd) geometry.shift();
  else geometry.push(geometry.shift());

  return [
    geometry.reverse(),
    lines.slice().reverse(), // lines.slice(0, geo.length - 1).reverse().concat(lines.slice(geo.length - 1)),
    true,
  ];
};

export const getSegmentCenterAndNormal = (sgement) => {
  const len = sgement.length;
  const center = sgement.reduce((a, b) => new THREE.Vector3(a.x + b.x, a.y + b.y, a.z + b.z)).multiplyScalar(1 / len);
  const vec1 = sgement[1].clone().sub(sgement[0]);
  const vec2 = sgement[2].clone().sub(sgement[1]);
  const normal = vec1.cross(vec2).normalize();
  if (normal.z < 0) normal.negate();
  return [center, normal];
};

export const getEaveIndex = (poly, eave) => [0, 1].map((i) => {
  try {
    const fi = poly.findIndex((v) => v.x === eave[i].x && v.y === eave[i].y);
    return fi;
  } catch {
    return 0;
  }
});

export const getEaveIndices = (state) => state.eaves
  .map((e, ei) => getEaveIndex(state.orgVertices[ei], e));

export const projectToPlane = (points, planeCenter, planeNormal) => {
  const projectedPoints = points.map((p) => {
    const v = p.clone().sub(planeCenter);
    const d = v.x * planeNormal.x + v.y * planeNormal.y + v.z * planeNormal.z;
    const projectedPoint = p.clone().sub(planeNormal.clone().multiplyScalar(d));
    return projectedPoint;
  });
  return projectedPoints;
};

export const projectZdimToPlane = (points, planeCenter, planeNormal) => {
  const projectedPoints = points.map((p) => {
    const z = (-planeNormal.x * (p.x - planeCenter.x) - planeNormal.y * (p.y - planeCenter.y) + planeNormal.z * planeCenter.z) / planeNormal.z;
    return new THREE.Vector3(p.x, p.y, z);
  });
  return projectedPoints;
};

export const calculateRoll = (eave, parent) => {
  const [p1, p2, eaveType] = eave;
  if (eaveType === 'valley') {
    const [center, normal] = getSegmentCenterAndNormal(parent);
    const [v1, v2] = projectZdimToPlane([p1, p2], center, normal);
    const d = p1.clone().sub(p2).length();
    const dz = v1.z - v2.z;
    const roll = (Math.atan2(dz, d) * 180) / Math.PI;
    return roll;
  }
  return 0;
};

export const calculateEave = (poly, azimuthDegree) => {
  const newEave = [];
  const z = new THREE.Vector3(0, 0, 1);
  const azimuth = (Number(azimuthDegree) * Math.PI) / 180;
  const x = Math.sin(azimuth);
  const y = -Math.cos(azimuth);
  const e = new THREE.Vector3(x, y, 0).cross(z);
  let [bestEaveEdge, bestEaveLength, bestEaveScore] = [0, 0, 0];
  let [bestRidgeEdge, bestRidgeLength, bestRidgeScore] = [0, 0, 0];
  for (let vi = 0; vi < poly.length; vi += 1) {
    const vj = (vi + 1) % poly.length;
    const edge = poly[vj].clone().sub(poly[vi]);
    const score = edge.clone().normalize().dot(e);
    if (score > bestEaveScore) {
      bestEaveLength = edge.length();
      bestEaveScore = score;
      bestEaveEdge = vi;
    }
    if (score < bestRidgeScore) {
      bestRidgeLength = edge.length();
      bestRidgeScore = score;
      bestRidgeEdge = vi;
    }
  }

  let azimuthVector;
  if (bestRidgeLength > bestEaveLength && bestRidgeScore < -0.85) {
    const ep1 = poly[bestRidgeEdge].clone();
    const ep2 = ep1.clone().sub(e);
    const nextEdge = (bestRidgeEdge + 1) % poly.length;
    newEave.push(ep2);
    newEave.push(ep1);
    newEave.push('ridge');
    newEave.push(bestRidgeEdge);
    newEave.push(nextEdge);
    const ep3 = poly[nextEdge].clone();
    azimuthVector = ep3.clone().sub(ep2).normalize().cross(z);
  } else {
    const ep1 = poly[bestEaveEdge].clone();
    const ep2 = ep1.clone().add(e);
    // const [,, et, e1, e2] = eave;
    const nextEdge = (bestEaveEdge + 1) % poly.length;
    newEave.push(ep1);
    newEave.push(ep2);
    newEave.push('eave');
    newEave.push(bestEaveEdge);
    newEave.push(nextEdge);
    const ep3 = poly[nextEdge].clone();
    azimuthVector = ep3.clone().sub(ep2).normalize().cross(z);
  }

  let orientation = (Math.atan2(azimuthVector.y, azimuthVector.x) / Math.PI) * 180 - 90;
  if (orientation < 0) orientation += 360;
  return [newEave, orientation];
};

const updateEavesByAzimuth = (state) => {
  state.orgVertices.forEach((poly, pi) => {
    const azimuth = state.azimuths[pi];
    const [eave] = calculateEave(poly, azimuth);
    state.eaves[pi] = eave;
  });
};

export const projectToLine = (eave, p) => {
  const [a, b] = eave;
  const ap = p.clone().sub(a);
  const ab = b.clone().sub(a);
  const projected = a.clone().add(ab.clone().multiplyScalar(ap.dot(ab) / ab.dot(ab)));
  const front = ab.clone().cross(ap).z < 0;
  return [projected, front];
};

export const expandPolygon = (poly, eave, pitch) => {
  const [a, b] = eave;
  const hasEave = a.clone().sub(b).length() > 0;
  if (hasEave) {
    const projectedPoly = poly.map((p) => {
      const [projected] = projectToLine(eave, p);
      // const ap = p.clone().sub(a);
      // const ab = b.clone().sub(a);
      // const projected = a.clone().add(ab.clone().multiplyScalar(ap.dot(ab) / ab.dot(ab)));
      const sc = Math.sqrt(1 + Math.tan(pitch * (Math.PI / 180.0)) ** 2);
      const v = p.clone().sub(projected).multiplyScalar(sc);
      return projected.clone().add(v);
    });
    return projectedPoly;
  }
  return poly;
};

export const expandPolygonWithRoll = (poly, eave, pitch, roll) => {
  const z = new THREE.Vector3(0, 0, 1);
  let [a, b] = eave;
  const eaveIndex = getEaveIndex(poly, eave);
  const u = b.clone().sub(a).cross(z);
  const projectedPolyOne = expandPolygon(poly, [a, u], roll);
  [a, b] = [projectedPolyOne[eaveIndex[0]], projectedPolyOne[eaveIndex[1]]];
  const projectedPolyTow = expandPolygon(projectedPolyOne, [a, b], pitch);
  return projectedPolyTow;
};

export const calcualteZdimOffset = (eave, p, pitch) => {
  const [,, eaveType] = eave;
  const [projected, front] = projectToLine(eave, p);
  const sc = Math.tan(pitch * (Math.PI / 180.0));
  const v = p.clone().sub(projected).multiplyScalar(sc);
  if (true || eaveType !== 'ridge') return front ? v.length() : -v.length();
  return front ? -v.length() : v.length();
};

export const calculatePolygonZdim = (poly, eave, pitch, height) => {
  const hasEave = eave[0].clone().sub(eave[1]).length() > 0;
  // const z = new THREE.Vector3(0, 0, 1);
  if (hasEave) {
    // const ev = eave.map((e) => poly.find((p) => e.x === p.x && e.y === p.y) ?? e).slice(0, 2);
    // ev.sort((e1, e2) => e2.z - e1.z);
    // const [a, b, eaveType] = eave;
    // const u = b.clone().sub(a).cross(z).normalize();
    const poly3d = poly.map((p) => {
      // const [projected, front] = projectToLine(eave, p);
      // // const ap = p.clone().sub(a);
      // // const ab = b.clone().sub(a);
      // // const front = ab.clone().cross(ap).z < 0;
      // // const projected = a.clone().add(ab.clone().multiplyScalar(ap.dot(ab) / ab.dot(ab)));
      // const sc = Math.tan(pitch * (Math.PI / 180.0));
      // const v = p.clone().sub(projected).multiplyScalar(sc);
      // if (eaveType !== 'ridge') return new THREE.Vector3(p.x, p.y, height + (front ? v.length() : -v.length()));
      // return new THREE.Vector3(p.x, p.y, height + (front ? -v.length() : v.length()));
      const v = calcualteZdimOffset(eave, p, pitch);
      // if (roll !== 0) v += calcualteZdimOffset([a, u, eaveType], p, roll);
      return new THREE.Vector3(p.x, p.y, height + v);
    });

    // if (eaveType === 'ridge') {
    //   const mh = Math.min(...poly3d.map((v) => v.z - height));
    //   poly3d.forEach((v) => { v.z += -mh; });
    // }

    // if (roll !== 0) {
    //   const u = b.clone().sub(a).cross(z).normalize();
    //   const q = new THREE.Quaternion().setFromAxisAngle(u, -roll * (Math.PI / 180.0));
    //   const M = new THREE.Matrix4().makeTranslation(-a.x, -a.y, -a.z).premultiply(
    //     new THREE.Matrix4().makeRotationFromQuaternion(q).premultiply(
    //       new THREE.Matrix4().makeTranslation(a.x, a.y, a.z),
    //     ),
    //   );
    //   poly3d.forEach((p) => p.applyMatrix4(M));
    // }

    // if (roll !== 0) {
    //   const u = b.clone().sub(a).cross(z).normalize();
    //   const q = new THREE.Quaternion().setFromAxisAngle(u, -roll);
    //   const M = new THREE.Matrix4().makeTranslation(-a.x, -a.y, -a.z).premultiply(
    //     new THREE.Matrix4().makeRotationFromQuaternion(q).premultiply(
    //       new THREE.Matrix4().makeTranslation(a.x, a.y, a.z + height),
    //     ),
    //   );
    //   poly3d.forEach((p) => p.applyMatrix4(M));
    // } else {
    //   poly3d.forEach((p) => p.z += height);
    // }

    return poly3d;
  }
  return poly.map((p) => new THREE.Vector3(p.x, p.y, height));
};

export const calculateRoofHeight = (poly3dPoint, parent3dPoint) => {
  if (parent3dPoint) {
    const [parentCenter, parentNormal] = getSegmentCenterAndNormal(parent3dPoint);
    const projectedPoly = projectZdimToPlane(poly3dPoint, parentCenter, parentNormal);
    const zdimDiff = poly3dPoint.map((p, pi) => projectedPoly[pi].z - p.z);
    return Math.max(...zdimDiff);
  }
  const zdimDiff = poly3dPoint.map((p) => p.z);
  return -Math.min(...zdimDiff);
};

const processedSegment = (state, poly, polyIndex, calculateProjectedPoly) => {
  const parent = state.parents[polyIndex].length > 0 ? state.vertices3D[state.parents[polyIndex][state.parents[polyIndex].length - 1]] : null;
  // const [,, et, e1, e2] = state.eaves[polyIndex];
  const eave = state.eaves[polyIndex];
  // if (et && e1 && e2) {
  //   eave[0] = state.orgVertices[polyIndex][e1].clone();
  //   eave[1] = state.orgVertices[polyIndex][e2].clone();
  // }
  const pitch = Number(state.pitches[polyIndex]);
  const segHeight = Number(state.eaveHeights[polyIndex]);
  // const roll = parent ? calculateRoll(eave, parent) : 0;
  if (calculateProjectedPoly) {
    const projectedPoly = expandPolygon(poly, eave, pitch);
    state.vertices[polyIndex] = projectedPoly;
  }
  const poly3D = calculatePolygonZdim(poly, eave, pitch, 0, 0);
  const h = calculateRoofHeight(poly3D, parent);
  // state.rolls[polyIndex] = roll;
  state.vertices3D[polyIndex] = poly3D;
  state.eaveHeights[polyIndex] = segHeight + h;
  state.vertices3D[polyIndex].forEach((p3d) => { p3d.z += segHeight + h; });
};

export const processSegmentsByLevelPriority = (polys, parents, callback) => {
  const processedSegments = [];
  let changeProcessed = true;
  while (changeProcessed) {
    changeProcessed = polys
      .some((poly, polyIndex) => {
        const parentIsProcessed = !processedSegments.includes(polyIndex) && parents[polyIndex].every((parentIndex) => processedSegments.includes(parentIndex));
        if (parentIsProcessed) {
          processedSegments.push(polyIndex);
          callback(poly, polyIndex);
          return true;
        }
        return false;
      });
  }
};

export const projectTo3DFromOrgVertices = (state, calculateProjectedPoly = true) => {
  state.vertices3D = state.orgVertices.map(() => null);
  state.vertices = state.orgVertices.map(() => null);
  state.oldEaveHeights = [...state.eaveHeights];

  processSegmentsByLevelPriority(state.orgVertices, state.parents, (poly, polyIndex) => {
    processedSegment(state, poly, polyIndex, calculateProjectedPoly);
  });

  // const processedSegments = [];
  // let changeProcessed = true;
  // while (changeProcessed) {
  //   changeProcessed = state.orgVertices
  //     .some((poly, polyIndex) => {
  //       const parentIsProcessed = !processedSegments.includes(polyIndex) && state.parents[polyIndex].every((parentIndex) => processedSegments.includes(parentIndex));
  //       if (parentIsProcessed) {
  //         processedSegments.push(polyIndex);
  //         processedSegment(state, poly, polyIndex, calculateProjectedPoly);
  //         return true;
  //       }
  //       return false;
  //     });
  // }

  state.orgVertices.forEach((poly, polyIndex) => {
    if (state.vertices3D[polyIndex] == null) {
      processedSegment(state, poly, polyIndex, calculateProjectedPoly);
    }
  });
};

// export const getEaveIndices = (state) => state.eaves
//   .map((e, ei) => [0, 1].map((i) => state.orgVertices[ei]
//     .findIndex((v) => v.x === e[i].x && v.y === e[i].y)));

export const getEave = (p, pi, oldEave, eavesIndex) => [
  eavesIndex[pi][0] !== -1 ? p[eavesIndex[pi][0]] : oldEave[0],
  eavesIndex[pi][1] !== -1 ? p[eavesIndex[pi][1]] : oldEave[1],
  oldEave[2],
];

const angleEqual = (v11, v12, v21, v22) => {
  const v1 = v11.clone().sub(v12).normalize();
  const v2 = v21.clone().sub(v22).normalize();
  const a = (v1.angleTo(v2) / Math.PI) * 180;
  return Math.min(Math.abs(a - 90), Math.abs(a)) <= 10;
};

export const getConstraintEdges = (state, segments) => {
  const edges = { }; // { eaves: {}, ridges: {} };

  segments.forEach((seg, si) => {
    const lines = [...seg.lines];
    if (state.changeClockwise[si]) lines.reverse();
    // edges.eaves[si] = [];
    // edges.ridges[si] = [];
    edges[si] = [];
    lines.forEach((lineType, li) => {
      if (['Eave', 'Rake', 'Ridge'].includes(lineType)) edges[si].push(li);
      // if (lineType === 'Eave' || lineType === 'Rake') edges.eaves[si].push(li);
      // else if (lineType === 'Ridge') edges.ridges[si].push(li);
    });
  });

  const constraintLines = [];

  for (let si = 0; si < segments.length; si += 1) {
    for (let ei = 0; ei < edges[si].length - 1; ei += 1) {
      const ei1 = edges[si][ei];
      const ei2 = (ei1 + 1) % state.orgVertices[si].length;
      const v11 = state.orgVertices[si][ei1];
      const v12 = state.orgVertices[si][ei2];

      for (let ej = 0; ej < edges[si].length; ej += 1) {
        const ej1 = edges[si][ej];
        if (ei1 !== ej1) {
          const ej2 = (ej1 + 1) % state.orgVertices[si].length;
          const v21 = state.orgVertices[si][ej1];
          const v22 = state.orgVertices[si][ej2];
          if (angleEqual(v11, v12, v21, v22)) constraintLines.push([[si, ei1, ei2], [si, ej1, ej2]]);
        }
      }

      for (let sj = si + 1; sj !== segments.length; sj += 1) {
        for (let ej = 0; ej < edges[sj].length - 1; ej += 1) {
          const ej1 = edges[sj][ej];
          const ej2 = (ej1 + 1) % state.orgVertices[sj].length;
          const v21 = state.orgVertices[sj][ej1];
          const v22 = state.orgVertices[sj][ej2];
          const match = [
            v11.x === v21.x && v11.y === v21.y,
            v11.x === v22.x && v11.y === v22.y,
            v12.x === v21.x && v12.y === v21.y,
            v12.x === v22.x && v12.y === v22.y,
          ].map((m) => (m ? 1 : 0));
          const matchCount = match.reduce((a, b) => a + b);
          if (matchCount === 1 && angleEqual(v11, v12, v21, v22)) {
            constraintLines.push([[si, ei1, ei2], [sj, ej1, ej2]]);
          }
        }
      }
    }
  }

  // state.orgVertices.forEach((seg, si) => {
  //   if (si in edges.eaves) {
  //     const e1i = edges.eaves[si];
  //     const e1j = (e1i + 1) % state.orgVertices[si].length;
  //     const e11 = state.orgVertices[si][e1i];
  //     const e12 = state.orgVertices[si][e1j];

  //     if (si in edges.ridges) {
  //       const ri = edges.ridges[si];
  //       const rj = (ri + 1) % state.orgVertices[si].length;
  //       constraintLines.push([[si, e1i, e1j], [si, ri, rj]]);
  //     }

  //     for (let sj = si + 1; sj !== segments.length; sj += 1) {
  //       if (sj in edges.eaves) {
  //         const e2i = edges.eaves[sj];
  //         const e2j = (e2i + 1) % state.orgVertices[sj].length;
  //         const e21 = state.orgVertices[sj][e2i];
  //         const e22 = state.orgVertices[sj][e2j];
  //         const match = [
  //           e11.x === e21.x && e11.y === e21.y,
  //           e11.x === e22.x && e11.y === e22.y,
  //           e12.x === e21.x && e12.y === e21.y,
  //           e12.x === e22.x && e12.y === e22.y,
  //         ].map((m) => (m ? 1 : 0));
  //         const matchCount = match.reduce((a, b) => a + b);
  //         if (matchCount === 1) {
  //           constraintLines.push([[si, e1i, e1j], [sj, e2i, e2j]]);
  //         }
  //       }
  //     }
  //   }
  // });

  return constraintLines;
};

const costCurve = (linearXRange, slope, exponent, expSlope, maxValue = -1) => {
  const func = (x) => {
    if (maxValue > 0 && x >= maxValue) return 1E6;
    if (x <= linearXRange) return x * slope;
    return (linearXRange * slope) + ((x - linearXRange) * expSlope + 1) ** exponent - 1;
  };
  return func;
};

const standardDeviation = (arr, usePopulation = false) => {
  const mean = arr.reduce((acc, val) => acc + val, 0) / arr.length;
  return Math.sqrt(
    arr.reduce((acc, val) => acc.concat((val - mean) ** 2), []).reduce((acc, val) => acc + val, 0)
      / (arr.length - (usePopulation ? 0 : 1)),
  );
};

export const calculateUnbalanceCost = (state, indices, orgFeatures, features, eavesIndex, selectedRoofs, orgrFeatures, rFeatures) => {
  state.orgVertices = features.map((op) => op.map((ov) => ov.clone()));
  const oldPitches = [...state.pitches];
  const oldHeights = [...state.eaveHeights];

  const vertexGroupId = state.orgVertices.map((s) => s.map(() => 0));
  indices.forEach((group, gi) => group.forEach(([pi, vi]) => { vertexGroupId[pi][vi] = gi; }));
  const groupSegmentIndex = indices.map((group) => group.map(([pi]) => pi));

  let cost = 0;
  // selectedRoofs.forEach((ri) => { [state.pitches[ri], state.eaveHeights[ri]] = rFeatures[ri]; });

  projectTo3DFromOrgVertices(state, false);
  state.eaveHeights = oldHeights;
  state.pitches = oldPitches;
  const features3D = state.vertices3D.map((op) => op.map((ov) => ov.clone()));
  selectedRoofs.forEach((ri) => {
    features3D[ri].forEach((v, vi) => {
      const vj = (vi + 1) % features3D[ri].length;
      const g1 = vertexGroupId[ri][vi];
      const g2 = vertexGroupId[ri][vj];
      const commonSegments = groupSegmentIndex[g1].filter((value) => groupSegmentIndex[g2].includes(value));
      if (commonSegments.length === 2) {
        const [[p11, v11], [p12, v12]] = indices[g1].filter(([pi]) => commonSegments.includes(pi));
        const [[p21, v21], [p22, v22]] = indices[g2].filter(([pi]) => commonSegments.includes(pi));
        const d1 = features3D[p11][v11].z - features3D[p12][v12].z;
        const d2 = features3D[p21][v21].z - features3D[p22][v22].z;
        cost += Math.min(25, ((p11 === p21 ? Math.abs(d1 - d2) : Math.abs(d1 + d2)) + 1) * (Math.abs(d1) + Math.abs(d2)));
      }
    });
  });

  return cost;
};

export const calculateCost = (state, indices, orgFeatures, features, eavesIndex, selectedRoofs, orgrFeatures, rFeatures, constraintLines, oldComponentCost = null, rawCost = false) => {
  state.orgVertices = features.map((op) => op.map((ov) => ov.clone()));
  const oldPitches = [...state.pitches];
  const oldAzimuths = [...state.azimuths];
  const oldHeights = [...state.eaveHeights];

  let cost = 0;

  const zCostCurve = costCurve(5, 10, 0.5, 10);
  const displacementCostCurve = costCurve(2, 2, 5, 10);
  const angleConstraintCostCurve = costCurve(10, 1, 0.5, 3);
  const heightDifferenceCostCurve = costCurve(1.5, 1, 2, 5);
  const pitchDifferenceCostCurve = costCurve(10, 4, 3, 2, 69);
  const azimuthDifferenceCostCurve = costCurve(10, 3, 2, 1);

  selectedRoofs.forEach((ri) => {
    [state.pitches[ri], state.eaveHeights[ri], state.azimuths[ri]] = rFeatures[ri];
    cost += pitchDifferenceCostCurve(Math.abs(rFeatures[ri][0] - orgrFeatures[ri][0]));
    cost += heightDifferenceCostCurve(Math.abs(rFeatures[ri][1] - orgrFeatures[ri][1]));
    cost += azimuthDifferenceCostCurve(Math.abs(rFeatures[ri][2] - orgrFeatures[ri][2]));
  });

  updateEavesByAzimuth(state);
  projectTo3DFromOrgVertices(state, false);
  state.eaveHeights = oldHeights;
  state.pitches = oldPitches;
  state.azimuths = oldAzimuths;
  let minz = 1E3;
  let maxz = -1E3;
  const features3D = state.vertices3D.map((op) => op.map((ov) => {
    minz = Math.min(minz, ov.z);
    maxz = Math.max(maxz, ov.z);
    return ov.clone();
  }));

  const componentCosts = [];
  let componentDiffPositive = 0;
  let componentDiffNegative = 0;

  indices.forEach((stack) => stack.forEach((row, rowi) => {
    let rowCost = 0;
    row.forEach(([pi, vi]) => {
      let n = 0;
      const costs = row.map(([pj, vj]) => {
        let vcost = 0;
        if (!(pi === pj && vi === vj)) {
          const area = (state.areas[pi] + state.areas[pj]) / 100;
          const zDiff = Math.abs(features3D[pi][vi].z - features3D[pj][vj].z);
          const zcost = zCostCurve(Math.abs(zDiff)) * (rawCost ? 1 : (1 + area) * (1 + ((Math.max(features3D[pi][vi].z, features3D[pj][vj].z) - minz) / (maxz - minz))));
          const dxcost = displacementCostCurve(Math.abs(orgFeatures[pi][vi].clone().sub(features3D[pi][vi].clone().setZ(0)).length()));
          const dycost = displacementCostCurve(Math.abs(orgFeatures[pj][vj].clone().sub(features3D[pj][vj].clone().setZ(0)).length()));
          n += 1;
          vcost += zcost;
          vcost += dxcost;
          vcost += dycost;
        }
        return vcost;
      });
      if (n > 0) rowCost += costs.reduce((a, b) => a + b) / n;
    });

    componentCosts.push(rowCost);
    if (oldComponentCost && oldComponentCost[rowi]) {
      const diffCost = oldComponentCost[rowi] - rowCost;
      if (diffCost >= 0) componentDiffPositive += diffCost;
      else componentDiffNegative -= diffCost;
    }

    cost += rowCost;
  }));

  const componentFactor = componentDiffPositive / (componentDiffNegative + 1E-6);

  constraintLines.forEach(([[si, e11, e12], [sj, e21, e22]]) => {
    const p11 = features3D[si][e11];
    const p12 = features3D[si][e12];
    const p21 = features3D[sj][e21];
    const p22 = features3D[sj][e22];
    let angle = Math.abs(p12.clone().sub(p11).angleTo(p22.clone().sub(p21)));
    if (angle > Math.PI) angle -= Math.PI;
    if (angle > Math.PI / 2) angle -= Math.PI / 2;
    if (angle >= (60 / 180) * Math.PI || angle <= (30 / 180) * Math.PI) {
      const alpha = (Math.min(angle, Math.abs(angle - Math.PI / 2)) / Math.PI) * 180;
      cost += angleConstraintCostCurve(alpha);
    }
  });

  return [cost, componentFactor, componentCosts];
};

const isEqualWithThresholdInXYPlane = (vec1, vec2, t = 0.2) => {
  const v1 = vec1.clone().setComponent(2, 0);
  const v2 = vec2.clone().setComponent(2, 0);
  return v1.sub(v2).length() < t;
};

export const commonEdgeType = (segments, state, pi, vi, pj, vj, returnOnlyIndices = false) => {
  if (pi === pj) return null;
  const polyi3d = state.vertices3D[pi] ?? state.orgVertices[pi];
  const polyj3d = state.vertices3D[pj] ?? state.orgVertices[pj];
  const vertIb = state.orgVertices[pi][vi];
  const vertJb = state.orgVertices[pj][vj];
  const vertIz = polyi3d[vi];
  const vertJz = polyj3d[vj];
  const vcountI = state.orgVertices[pi].length;
  const vcountJ = state.orgVertices[pj].length;
  if (isEqualWithThresholdInXYPlane(vertIb, vertJb) /* && Math.abs(vertIz.z - vertJz.z) < 40 */) {
    const ivertIa = (vi - 1 + vcountI) % vcountI;
    const ivertIc = (vi + 1) % vcountI;
    const ivertJa = (vj - 1 + vcountJ) % vcountJ;
    const ivertJc = (vj + 1) % vcountJ;
    const vertIa = state.orgVertices[pi][ivertIa];
    const vertIc = state.orgVertices[pi][ivertIc];
    const vertJa = state.orgVertices[pj][ivertJa];
    const vertJc = state.orgVertices[pj][ivertJc];
    const lineI = [...segments[pi].lines];
    const lineJ = [...segments[pj].lines];
    if (state.changeClockwise[pi]) lineI.reverse();
    if (state.changeClockwise[pj]) lineJ.reverse();
    let vi2 = null;
    let vj2 = null;
    if (isEqualWithThresholdInXYPlane(vertIa, vertJa)) [vi2, vj2] = [ivertIa, ivertJa];
    else if (isEqualWithThresholdInXYPlane(vertIa, vertJc)) [vi2, vj2] = [ivertIa, ivertJc];
    else if (isEqualWithThresholdInXYPlane(vertIc, vertJa)) [vi2, vj2] = [ivertIc, ivertJa];
    else if (isEqualWithThresholdInXYPlane(vertIc, vertJc)) [vi2, vj2] = [ivertIc, ivertJc];
    if (vi2 !== null && vj2 !== null) {
      if (returnOnlyIndices) return [vi2, vj2];
      const edgeIndexI = (vi < vi2 && !(vi === 0 && vi2 === (vcountI - 1))) || (vi2 === 0 && vi === (vcountI - 1)) ? lineI[vi] : lineI[vi2];
      const edgeIndexJ = (vj < vj2 && !(vj === 0 && vj2 === (vcountJ - 1))) || (vj2 === 0 && vj === (vcountJ - 1)) ? lineJ[vj] : lineJ[vj2];
      if (edgeIndexI === '' && edgeIndexJ !== '') return edgeIndexJ;
      if (edgeIndexI !== '' && edgeIndexJ === '') return edgeIndexI;
      if (edgeIndexI === edgeIndexJ) return edgeIndexI;
    } else {
      return "";
    }
  }
  return null;
};

export const getVerticesGroups = (segments, state, flat = true) => {
  const indices = [];
  const roofVertices = state.orgVertices.filter((p, pi) => state.verticesType[pi] === 'roof');
  roofVertices
    .map((p, pi) => p.map((v, vi) => [pi, vi]))
    .flat()
    .forEach(([pi, vi]) => {
      const index = [`${pi},${vi}`];
      roofVertices.forEach((p, pj) => p.forEach((vertJ, vj) => {
        const commonEdge = commonEdgeType(segments, state, pi, vi, pj, vj);
        if (['', 'Hip', 'Valley', 'Ridge'].includes(commonEdge)) index.push(`${pj},${vj}`);
      }));
      indices.push(index);
    });

  let isMergeFound;
  do {
    isMergeFound = false;
    for (let i = 0; i < indices.length; i += 1) {
      for (let j = indices.length - 1; j > i; j -= 1) {
        const r1 = indices[i];
        const r2 = indices[j];
        const r3 = r1.concat(r2.filter((item) => r1.indexOf(item) < 0));
        if (r3.length < r1.length + r2.length) {
          indices[i] = r3;
          indices.splice(j, 1);
          isMergeFound = true;
        }
      }
    }
  } while (isMergeFound);

  indices.forEach((v, i) => {
    indices[i] = v.map((pv) => pv.split(',').map(Number));
  });

  if (flat) return indices;

  const results = [];
  while (indices.length) {
    const result = [];
    const [pi, vi] = indices[0][0];
    const { x: refx, y: refy } = roofVertices[pi][vi];
    for (let j = indices.length - 1; j >= 0; j -= 1) {
      const [pj, vj] = indices[j][0];
      const { x, y } = roofVertices[pj][vj];
      if (refx === x && refy === y) {
        result.push(indices[j]);
        indices.splice(j, 1);
      }
    }
    results.push(result);
  }

  return results;
};

export const shuffle = (unshuffled) => {
  const shuffled = unshuffled
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
  return shuffled;
};

export const shuffleIndices = (maxIndex) => {
  const shuffled = shuffle(new Array(maxIndex).fill(0).map((_, i) => i));
  return shuffled;
};

export const randVector = (alpha) => {
  const theta = Math.random() * Math.PI;
  return [Math.cos(theta) * alpha, Math.sin(theta) * alpha];
};

export const directionalVector = (theta, alpha) => {
  const t = (theta / 180) * Math.PI;
  return [Math.cos(t) * alpha, Math.sin(t) * alpha];
};

export const toDegree = (r) => (r * 180) / Math.PI;
export const toRadian = (d) => (d * Math.PI) / 180;

const getSegmentFeatures = (segments, state, resolution, scale, selectedRoofs, selectedVertices, flatGroup = false) => {
  // vertices batch [poly index, vertex index]
  const indices = getVerticesGroups(segments, state, flatGroup);

  // selected batch for optimization
  const selectedVerticesRows = [];
  if (!flatGroup) {
    indices.forEach((_, i) => {
      if (indices[i].some((stack) => stack.some(([pi, vi]) => selectedVertices[pi].includes(vi)))) selectedVerticesRows.push(i);
    });
  } else {
    indices.forEach((group, gi) => {
      if (group.some(([pi, vi]) => selectedVertices[pi].includes(vi))) selectedVerticesRows.push(gi);
    });
  }

  const footPerUnit = parseFloat(resolution) / 30.48;
  state.eaveHeights = segments.map((seg) => (seg.height / footPerUnit) * scale);

  const newSegmentParents = [];
  const newSegmentArea = [];
  state.orgVertices
    .forEach((poly, polyIndex) => {
      const isObstacle = state.verticesType[polyIndex] === 'obstacle';
      const parentSegs = getParentsPolygon(state.orgVertices, polyIndex, !isObstacle);
      newSegmentParents.push(parentSegs);
      newSegmentArea.push(Math.abs(THREE.ShapeUtils.area(poly)));
    });
  state.parents = newSegmentParents;
  state.areas = newSegmentArea;

  const constraintLines = getConstraintEdges(state, segments);

  const orgrFeatures = selectedRoofs.map((ri) => [Number(segments[ri].pitch), Number(state.eaveHeights[ri]), Number(segments[ri].azimuth)]);
  const rFeatures = orgrFeatures.map((rf) => [...rf]);

  const eavesIndex = getEaveIndices(state);

  const roofVertices = state.orgVertices.filter((p, pi) => state.verticesType[pi] === 'roof');
  const orgvFeatures = roofVertices.map((op) => op.map((ov) => ov.clone()));
  const vFeatures = roofVertices.map((op) => op.map((ov) => ov.clone()));

  return {
    indices, orgvFeatures, vFeatures, eavesIndex, orgrFeatures, rFeatures, constraintLines, selectedVerticesRows,
  };
};

export const unbalanceCost = (segments, state, resolution, scale) => {
  const selectedRoofs = segments.map((s, si) => si);
  const selectedVertices = segments.map((s) => s.geometry.map((v, vi) => vi));
  const {
    indices, orgvFeatures, vFeatures, eavesIndex, orgrFeatures, rFeatures,
  } = getSegmentFeatures(segments, state, resolution, scale, selectedRoofs, selectedVertices, true);
  const cost = calculateUnbalanceCost(state, indices, orgvFeatures, vFeatures, eavesIndex, selectedRoofs, orgrFeatures, rFeatures);
  return cost;
};

export const getNeighbours = (segments, state) => {
  const segmentsNeighbours = {};
  segments.forEach((_, si) => {
    segmentsNeighbours[si] = new Set();
  });
  const indices = getVerticesGroups(segments, state);
  indices.forEach((group) => {
    group.forEach(([pi]) => {
      group.forEach(([pj]) => {
        if (pi !== pj) segmentsNeighbours[pi].add(pj);
      });
    });
  });
  Object.keys(segmentsNeighbours).forEach((ni) => { segmentsNeighbours[ni] = Array.from(segmentsNeighbours[ni]); });
  return segmentsNeighbours;
};

const clip = (v, minv, maxv, d) => Math.min(maxv, Math.max(minv, v));

const walkBetweenVertices = (v1, v2, stepSize = 1.0) => {
  const n = v2.clone().sub(v1);
  const len = n.length();
  n.normalize();
  const count = Math.floor(len / stepSize);
  const results = [];
  results.push([v1.x, v1.y, v1.z]);
  let vx = v1.clone();
  for (let i = 0; i < count; i += 1) {
    vx = vx.add(n.clone().multiplyScalar(stepSize));
    results.push([vx.x, vx.y, vx.z]);
  }
  return results;
};

const walkVertices = (vertices, stepSize = 1.0) => {
  const results = [];
  vertices.forEach((v, vi) => {
    let v1 = new THREE.Vector3(...v);
    const v2 = new THREE.Vector3(...vertices[(vi + 1) % vertices.length]);
    results.push(...walkBetweenVertices(v1, v2, stepSize));
  });
  return results;
};

export const optimizePlanes = (segments, state, resolution, scale, maxIter=1000, step=1.0) => {
  const { vertices3D, parents } = state;
  const indices = getVerticesGroups(segments, state);
  const neighbours = getNeighbours(segments, state);
  const eavesIndex = getEaveIndices(state);
  const segmentVerticesGroup = segments.map((_,i) => indices.map((g, gi) => g.filter(([pi]) => i == pi).map(([,vi]) => [gi, vi])).flat());
  segmentVerticesGroup.forEach((g, si) => g.sort(([,vi], [,vj]) => vi - vj));
  const newVertices3D = vertices3D.map((vs) => vs.map((v) => v.clone()));
  const footPerUnit = parseFloat(resolution) / 30.48;
  const allVertices = [];
  for (let i = 0; i <= 10; i += 1) {
    segmentVerticesGroup.forEach((g, si) => {
      try {
        const planeVertices = g.map(([gi]) => {
          const segmentVertexIndex = indices[gi].filter(([pi,vi]) => si == pi)[0];
          const segmentVertex = newVertices3D[si][segmentVertexIndex[1]];
          const stack = indices[gi].map(([pi,vi]) => newVertices3D[pi][vi]).filter((v) => v.clone().sub(segmentVertex).length() < 5.0);
          const weights = stack.map((v) => 1 / (0.2 + v.clone().sub(segmentVertex).length()));
          const sumWeights = weights.reduce((a,b) => a+b, 0); 
          const mv = stack.reduce((a,v,ind) => a.clone().add(v.clone().multiplyScalar(weights[ind])), new THREE.Vector3(0,0,0)).multiplyScalar(1.0 / sumWeights);
          return mv;
        }).map((v) => [v.x, v.y, v.z]);
        const samples = walkVertices(planeVertices, 1.0);
        const [model, mean] = fitPlane(samples, 10, 3);
        const projectedPoints = fitData(planeVertices, model);
        newVertices3D[si] = newVertices3D[si].map((v,vi) => new THREE.Vector3(...projectedPoints[vi]));
        newVertices3D[si] = newVertices3D[si].map((v,vi) => v.clone().setComponent(2, v.z + clip((projectedPoints[vi][2] - v.z) * step, -step, step))); 
        // allVertices.push(...samples);
        // allVertices.push(...projectedPoints);
      } catch { console.log('error in optimizePlanes'); }
    });
  }
  segments.forEach((_,si) => {
    const projectedPoints = newVertices3D[si].map((v) => [v.x, v.y, v.z]);
    // const samples = walkVertices(projectedPoints, 1.0);
    const [model, mean] = fitPlane(projectedPoints);
    const [pitch, azimuth, height] = getPitchAzimuthFromModel(model, projectedPoints);
    segments[si].pitch = Math.max(0, Math.min(69.99, pitch || 0));
    segments[si].azimuth = azimuth || 0;
    segments[si].height = (height / scale) * footPerUnit + 0.01;
  });
  segments.forEach((_,si) => {
    if (parents[si].length > 0) {
      // const maxParentHeight = Math.max(...parents[si].map((pi) => segments[pi].height));
      segments[si].height = 0;
    }
  });
  console.log('Optimize Planes')
  return allVertices;
};

// export const optimizePlanes = (segments, state, resolution, scale, maxIter=500, step=0.01) => {
//   const { vertices3D } = state;
//   const indices = getVerticesGroups(segments, state);
//   const neighbours = getNeighbours(segments, state);
//   const eavesIndex = getEaveIndices(state);
//   const segmentVerticesGroup = segments.map((_,i) => indices.map((g, gi) => g.filter(([pi]) => i == pi).map(([,vi]) => [gi, vi])).flat());
//   // const segmentVerticesGroup = segments.map((_,i) => indices.map((_,j)=>j).filter((j) => indices[j].some(([pi, vi]) => i == pi)));
//   const newVertices3D = vertices3D.map((vs) => vs.map((v) => v.clone()));
//   const footPerUnit = parseFloat(resolution) / 30.48;
//   // const allVertices = [];
//   for (let i = 0; i <= maxIter; i += 1) {
//     segmentVerticesGroup.forEach((sg, si) => {
//       sg.sort(([,vi], [,vj]) => vi - vj);
//       const planeVertices = sg.map(([g1, vi], gi) => {
//         const [g2, vj] = sg[(gi + 1) % sg.length];
//         const g1Segments = indices[g1].map(([pi]) => pi);
//         const g2Segments = indices[g2].map(([pi]) => pi);
//         const commonSegments = g1Segments.filter((value) => g2Segments.includes(value));
//         const commonEdges = commonSegments.map((c) => [c, indices[g1].find(([p]) => p == c)[1], indices[g2].find(([p]) => p == c)[1]]);
//         const commonEdgeVertices = commonEdges.map(([s, v1, v2]) => {
//           const sv1 = newVertices3D[s][v1];
//           const sv2 = newVertices3D[s][v2];
//           return walkBetweenVertices(sv1, sv2, 5);
//         }).flat();
//         // const commonEdgePositions = commonEdgeVertices.map((v) => [v.x, v.y, v.z]);
//         return commonEdgeVertices;
//         // const segmentVertexIndex = indices[gi].filter(([pi,vi]) => si == pi)[0];
//         // const segmentVertex = newVertices3D[si][segmentVertexIndex[1]];
//         // const stack = indices[g1].map(([pi,vi]) => newVertices3D[pi][vi]);// .filter((v) => v.clone().sub(segmentVertex).length() < 2.0);
//         // const mv = stack.reduce((a,v) => a.clone().add(v)).multiplyScalar(1.0 / stack.length);
//         // return mv;
//       }).flat();
//       // const samples = walkVertices(planeVertices);
//       // allVertices.push(...planeVertices);
//       const [model, mean] = fitPlane(planeVertices);
//       const projectedPoints = fitData(newVertices3D[si], model, mean);
//       newVertices3D[si] = newVertices3D[si].map((v,vi) => v.clone().setComponent(2, v.z + clip((projectedPoints[vi][2] - v.z) * step, -step, step, projectedPoints[vi][2]))); 
//     });
//   }
//   // return allVertices;
//   segments.forEach((_,si) => {
//     const projectedPoints = newVertices3D[si].map((v) => [v.x, v.y, v.z]);
//     const [model, mean] = fitPlane(projectedPoints);
//     const [pitch, azimuth, height] = getPitchAzimuthFromModel(model, projectedPoints);
//     segments[si].pitch = Math.max(0, Math.min(69.99, pitch || 0));
//     segments[si].azimuth = azimuth || 0;
//     segments[si].height = (height / scale) * footPerUnit + 0.01;
//   });
// };

export const optimizeVertexPosition = (segments, state, resolution, scale, selectedRoofs, selectedVertices, optimizePositionOnly = true, maxIter = 1000) => {
  const {
    indices, orgvFeatures, vFeatures, eavesIndex, orgrFeatures, rFeatures, constraintLines, selectedVerticesRows,
  } = getSegmentFeatures(segments, state, resolution, scale, selectedRoofs, selectedVertices);
  const vGradients = selectedVerticesRows.map(() => [0, 0]);
  const rGradients = selectedRoofs.map(() => [0, 0, 0]);
  const [cost, , oComponentCosts] = calculateCost(state, indices, orgvFeatures, vFeatures, eavesIndex, selectedRoofs, orgrFeatures, rFeatures, constraintLines);
  let componentCosts = oComponentCosts;

  let [bestCost, iter] = [cost, 0];

  const neighbours = getNeighbours(segments, state);

  // const changeGradiants = (gradients, p) => {
  //   const vindices = shuffleIndices(selectedRoofs.length);
  //   for (let r = 0; r < vindices.length; r += 1) {
  //     if (Math.random() <= p) {
  //       const ri = selectedRoofs[vindices[r]];
  //       const rj = neighbours[ri][Math.floor(Math.random() * neighbours[ri].length)];
  //       gradients[ri][1] = gradients[rj][1];
  //     }
  //   }
  // };

  const optimizePitchAndHeight = (sc) => {
    const alpha = (1 - (iter / maxIter) / (1 - 0.1)) * sc; // sc * (0.999 ** iter);
    const vindices = shuffleIndices(selectedRoofs.length);

    for (let r = 0; r < vindices.length; r += 1) {
      let bestFactor = 0.2;
      const ri = selectedRoofs[vindices[r]];
      const temp = [...rFeatures[ri]];
      rFeatures[ri][0] += rGradients[ri][0];
      rFeatures[ri][1] += rGradients[ri][1];
      rFeatures[ri][2] += rGradients[ri][2];
      let [newCost, newFactor] = calculateCost(state, indices, orgvFeatures, vFeatures, eavesIndex, selectedRoofs, orgrFeatures, rFeatures, constraintLines, componentCosts);
      if (newCost < bestCost) {
        rGradients[ri][0] *= 2;
        rGradients[ri][1] *= 2;
        rGradients[ri][2] *= 2;
      } else {
        let bestGradient = [0, 0, 0];
        let found = false;
        for (let t = 0; t < 10; t += 1) {
          const h = Math.random() * 2 - 1;
          const p = Math.random() * 2 - 1;
          const az = Math.random() * 2 - 1;
          rGradients[ri][0] = p * alpha;
          rGradients[ri][1] = (h / 1) * alpha;
          rGradients[ri][2] = (az / 1) * alpha;
          rFeatures[ri][0] = temp[0] + rGradients[ri][0];
          rFeatures[ri][1] = temp[1] + rGradients[ri][1];
          rFeatures[ri][2] = temp[2] + rGradients[ri][2];
          [newCost, newFactor] = calculateCost(state, indices, orgvFeatures, vFeatures, eavesIndex, selectedRoofs, orgrFeatures, rFeatures, constraintLines, componentCosts);
          if (newCost < bestCost || (!found && newFactor > bestFactor)) {
            bestGradient = [...rGradients[ri]];
            bestFactor = newFactor;
            found = newCost < bestCost;
          }
        }
        // for (let h = -1; h <= 1; h += 1) {
        //   for (let p = -1; p <= 1; p += 1) {
        //     if (h !== 0 || p !== 0) {
        //     }
        //   }
        // }
        rGradients[ri] = bestGradient;
      }
      rFeatures[ri] = temp;
    }

    // changeGradiants(rGradients, 0.3 - (iter / maxIter) * 0.3);

    for (let r = 0; r < vindices.length; r += 1) {
      const ri = selectedRoofs[vindices[r]];
      rFeatures[ri][0] += rGradients[ri][0];
      rFeatures[ri][1] += rGradients[ri][1];
      rFeatures[ri][2] += rGradients[ri][2];
    }

    const [acost,, acomponentCosts] = calculateCost(state, indices, orgvFeatures, vFeatures, eavesIndex, selectedRoofs, orgrFeatures, rFeatures, constraintLines, componentCosts);
    let allCost = acost;
    componentCosts = acomponentCosts;

    const mask = new Array(vindices.length).fill(false);
    for (let r = 0; r < vindices.length; r += 1) {
      const ri = selectedRoofs[vindices[r]];
      rFeatures[ri][0] -= rGradients[ri][0];
      rFeatures[ri][1] -= rGradients[ri][1];
      rFeatures[ri][2] -= rGradients[ri][2];
      const [newCost,, ncomponentCosts] = calculateCost(state, indices, orgvFeatures, vFeatures, eavesIndex, selectedRoofs, orgrFeatures, rFeatures, constraintLines, componentCosts);
      if (newCost < allCost) {
        componentCosts = ncomponentCosts;
        allCost = newCost;
      } else {
        mask[r] = true;
        rFeatures[ri][0] += rGradients[ri][0];
        rFeatures[ri][1] += rGradients[ri][1];
        rFeatures[ri][2] += rGradients[ri][2];
      }
    }

    bestCost = allCost;
  };

  const applyGradiant = (ri, gi, negative = false) => {
    indices[ri].forEach((stack) => stack.forEach(([pi, vi]) => {
      vFeatures[pi][vi].x += vGradients[gi][0] * (negative ? -1 : 1);
      vFeatures[pi][vi].y += vGradients[gi][1] * (negative ? -1 : 1);
    }));
  };

  const setFeatures = (ri, features) => {
    indices[ri].forEach((stack, ski) => stack.forEach(([pi, vi], index) => {
      vFeatures[pi][vi].x = features[ski][index].x;
      vFeatures[pi][vi].y = features[ski][index].y;
    }));
  };

  const optimizePosition = (sc) => {
    const angleStep = 90;
    const alpha = (1 - (iter / maxIter) / (1 - 0.1)) * sc;
    const vindices = shuffleIndices(selectedVerticesRows.length);

    for (let i = 0; i < vindices.length; i += 1) {
      let bestFactor = 0.2;
      const gi = vindices[i];
      const ri = selectedVerticesRows[gi];
      const temp = indices[ri].map((stack) => stack.map(([pi, vi]) => vFeatures[pi][vi].clone()));
      applyGradiant(ri, gi);
      const [newCost] = calculateCost(state, indices, orgvFeatures, vFeatures, eavesIndex, selectedRoofs, orgrFeatures, rFeatures, constraintLines, componentCosts);
      if (newCost < bestCost) {
        vGradients[gi] = [vGradients[gi][0] * 1.1, vGradients[gi][1] * 1.1];
      } else {
        let bestGradient = [0, 0];
        let found = false;
        for (let a = 0; a < 360 / angleStep; a += 1) {
          const theta = a * angleStep + Math.random() * 10;
          vGradients[gi] = directionalVector(theta, alpha);
          setFeatures(ri, temp);
          applyGradiant(ri, gi);
          const [gCost, newFactor] = calculateCost(state, indices, orgvFeatures, vFeatures, eavesIndex, selectedRoofs, orgrFeatures, rFeatures, constraintLines, componentCosts);
          if (gCost < bestCost || (!found && newFactor > bestFactor)) {
            bestGradient = [...vGradients[gi]];
            bestFactor = newFactor;
            found = newCost < bestCost;
          }
        }
        vGradients[gi] = bestGradient;
      }
      setFeatures(ri, temp);
    }

    for (let r = 0; r < vindices.length; r += 1) {
      const gi = vindices[r];
      const ri = selectedVerticesRows[gi];
      applyGradiant(ri, gi);
    }

    const [acost,, acomponentCosts] = calculateCost(state, indices, orgvFeatures, vFeatures, eavesIndex, selectedRoofs, orgrFeatures, rFeatures, constraintLines, componentCosts);
    let allCost = acost;
    componentCosts = acomponentCosts;

    for (let r = 0; r < vindices.length; r += 1) {
      const gi = vindices[r];
      const ri = selectedVerticesRows[gi];
      applyGradiant(ri, gi, true);
      const [newCost,, ncomponentCosts] = calculateCost(state, indices, orgvFeatures, vFeatures, eavesIndex, selectedRoofs, orgrFeatures, rFeatures, constraintLines, componentCosts);
      if (newCost < allCost) {
        componentCosts = ncomponentCosts;
        allCost = newCost;
      } else {
        applyGradiant(ri, gi);
      }
    }

    bestCost = allCost;
  };

  maxIter = 1000;

  const stepPH = 0.005;
  const stepP = 0.05;

  const iters = [];

  if (!optimizePositionOnly) {
    iter = 0;
    const meanHeightOld = orgrFeatures.map(([, h]) => h).reduce((a, b) => a + b) / orgrFeatures.length;
    let heightChanged = false;
    while (iter < maxIter && (heightChanged || iter <= 50 || (iters[iters.length - 50] - iters[iters.length - 1]) > 10)) {
      optimizePitchAndHeight(stepPH);
      iter += 1;
      iters.push(bestCost);
      // if (iter % 50 === 0) {
      //   heightChanged = false;
      //   const t = 0.5 * (iter / maxIter);
      //   for (let ri1 = 0; ri1 < rFeatures.length; ri1 += 1) {
      //     const [, h1] = rFeatures[ri1];
      //     let meanh = h1;
      //     const similarRoofs = [ri1];
      //     rFeatures.forEach(([, h2], ri2) => {
      //       if (ri1 !== ri2 && Math.abs(h1 - h2) < t) {
      //         similarRoofs.push(ri2);
      //         meanh += h2;
      //       }
      //     });
      //     meanh /= similarRoofs.length;
      //     for (let rj1 = 0; rj1 < similarRoofs.length; rj1 += 1) {
      //       const rj = similarRoofs[rj1];
      //       if (rFeatures[rj][1] !== meanh) {
      //         heightChanged = true;
      //         rFeatures[rj][1] = meanh;
      //       }
      //     }
      //   }
      // }
    }
    const meanHeightNew = rFeatures.map(([, h]) => h).reduce((a, b) => a + b) / rFeatures.length;
    rFeatures.forEach((ph) => { ph[1] += meanHeightOld - meanHeightNew; });
  } else {
    while (iter < maxIter && (iter <= 50 || (iters[iters.length - 50] - iters[iters.length - 1]) > 10)) {
      optimizePosition(stepP);
      iter += 1;
      iters.push(bestCost);
    }
  }

  const footPerUnit = parseFloat(resolution) / 30.48;
  const properties = rFeatures.map(([pitch, height, azimuth]) => [pitch, (height / scale) * footPerUnit, azimuth]);
  return [vFeatures, properties, bestCost];
};

export const pointInConvexPolygon = (poly, p, t = 1e-5, except = -1) => {
  const z = new THREE.Vector3(0, 0, 1);
  for (let i = 0; i < poly.length; i += 1) {
    if (i !== except) {
      const v1 = poly[i];// .clone().setComponent(2,0);
      let j = (i + 1) % poly.length;
      if (j === except) j = (j + 1) % poly.length;
      const v2 = poly[j];// .clone().setComponent(2,0);
      //const pt = p.clone().setComponent(2,0);
      if (v2.clone().sub(v1).cross(z).dot(p.clone().sub(v1)) < t) return false;
    }
  }
  return true;
};

export const pointInConcavePolygon = (poly, p, t = 1e-5) => {
  const { x, y } = p;
  if (Number.isNaN(x) || Number.isNaN(y)) return false;
  const triangleIndices = THREE.ShapeUtils.triangulateShape([...poly], []);
  const trianglePolygons = triangleIndices
    .map((indices) => indices.map((ti) => poly[ti]))
    .map((ti) => (THREE.ShapeUtils.isClockWise(ti) ? ti : ti.reverse()));
  return trianglePolygons.some((triangle) => pointInConvexPolygon(triangle, p, t));
};

export const convexHull = (poly) => {
  const result = poly.slice(0);
  const deletedVertices = [];
  for (let i = result.length - 1; i >= 0; i -= 1) {
    if (pointInConvexPolygon(result, result[i], 1e-5, i)) {
      deletedVertices.push(i);
    }
  }
  if (deletedVertices.length > 0) {
    deletedVertices.forEach((i) => { result.splice(i, 1); });
    return convexHull(result);
  }
  return result;
};

export const getParentsPolygon = (orgVertices, polyIndex, complete = true) => {
  const parentSegs = [];
  const poly = orgVertices[polyIndex];

  for (let i = 0; i < orgVertices.length; i += 1) {
    if (i !== polyIndex) {
      // const convexPoly = convexHull(orgVertices[i]);
      if (poly.filter((p) => pointInConcavePolygon(orgVertices[i], p)).length > (complete ? poly.length / 2 : 1)) {
        parentSegs.push(i);
      }
    }
  }

  if (parentSegs.length) {
    const parentOfParents = parentSegs.map((parentIndex) => [parentIndex, getParentsPolygon(orgVertices, parentIndex, true)]);
    parentOfParents.sort((p1, p2) => p1[1].length - p2[1].length);

    const polyIndices = [parentOfParents[0][0]];
    for (let i = 1; i < parentOfParents.length; i += 1) {
      const last = polyIndices[polyIndices.length - 1];
      if (parentOfParents[i][1].includes(last)) {
        polyIndices.push(parentOfParents[i][0]);
      }
    }
    return polyIndices;
  }

  return [];
};

export const oldCalculateRoofHeight = ({
  orgVertices,
  eaves,
  pitches,
  eaveHeights,
  verticesType,
}, polyIndex, parentSegs = null) => {
  if (!parentSegs) {
    const isObstacle = verticesType[polyIndex] === 'obstacle';
    parentSegs = getParentsPolygon(orgVertices, polyIndex, !isObstacle);
  }
  // parentSegs
  //   .map((parentIndex) => [parentIndex, getParentsPolygon(orgVertices, parentIndex, true)])
  //   .sort((p1, p2) => p1[1].length - p2[1].length);

  const polyIndices = [...parentSegs, polyIndex];

  let [h, roll] = [0, 0];
  if (eaves[polyIndex] === 'ridge') {
    const poly3d = calculatePolygonZdim(orgVertices[polyIndex],
      eaves[polyIndex], pitches[polyIndex], eaveHeights[polyIndex], roll);
    // h += -Math.min(...poly3d.map((p3d) => p3d.z));
  }

  for (let j = 1; j < polyIndices.length; j += 1) {
    const childIndex = polyIndices[j];
    const parentIndex = polyIndices[j - 1];
    const poly3d = calculatePolygonZdim(orgVertices[childIndex],
      eaves[parentIndex], pitches[parentIndex], eaveHeights[parentIndex], roll);

    const [o1, o2, childEaveType] = eaves[childIndex];
    if (childEaveType === 'valley') {
      const eave3d = calculatePolygonZdim(eaves[childIndex].slice(0, 2),
        eaves[parentIndex], pitches[parentIndex], eaveHeights[parentIndex], roll);
      const [p1, p2] = eave3d;
      roll = Math.atan2(p1.z - p2.z, o2.clone().sub(o1).length());
      if (p1.z > p2.z) h += Math.max(...poly3d.map((p3d) => p3d.z));
      else h += Math.min(...poly3d.map((p3d) => p3d.z));
    } else if (childEaveType === 'ridge') {
      h += Math.min(...poly3d.map((p3d) => p3d.z));
    } else {
      h += Math.max(...poly3d.map((p3d) => p3d.z));
    }
  }

  return [h, roll];
};

export const sunPosition = (when, lat, long) => {
  const radians = (deg) => (deg * Math.PI) / 180.0;
  // Latitude [rad]
  const latRad = radians(lat);

  const start = new Date(when.getFullYear(), 0, 0);
  const diff = (when - start) + ((start.getTimezoneOffset() - when.getTimezoneOffset()) * 60 * 1000);
  const oneDay = 1000 * 60 * 60 * 24;
  const day = Math.floor(diff / oneDay);

  // const month = when.getUTCMonth() + 1; // months from 1-12
  const year = when.getUTCFullYear();
  const hour = when.getUTCHours() + when.getUTCMinutes() / 60.0 + when.getUTCSeconds() / 3600.0;

  // Get Julian date - 2400000
  // const day = time.gmtime().tm_yday;
  // const hour = time.gmtime().tm_hour + time.gmtime().tm_min / 60.0 + time.gmtime().tm_sec / 3600.0;
  const delta = year - 1949;
  const leap = delta / 4;
  const jd = 32916.5 + delta * 365 + leap + day + hour / 24;

  // The input to the Atronomer's almanach is the difference between
  // the Julian date and JD 2451545.0 (noon, 1 January 2000)
  const t = jd - 51545;

  // Ecliptic coordinates

  // Mean longitude
  const mnlongDeg = (280.460 + 0.9856474 * t) % 360;

  // Mean anomaly
  const mnanomRad = radians((357.528 + 0.9856003 * t) % 360);

  // Ecliptic longitude and obliquity of ecliptic
  const eclong = radians((mnlongDeg + 1.915 * Math.sin(mnanomRad)
    + 0.020 * Math.sin(2 * mnanomRad)) % 360);
  const oblqecRad = radians(23.439 - 0.0000004 * t);

  // Celestial coordinates
  // Right ascension and declination
  const num = Math.cos(oblqecRad) * Math.sin(eclong);
  const den = Math.cos(eclong);
  let raRad = Math.atan(num / den);
  if (den < 0) raRad += Math.PI;
  else if (num < 0) raRad += 2 * Math.PI;
  const decRad = Math.asin(Math.sin(oblqecRad) * Math.sin(eclong));

  // Local coordinates
  // Greenwich mean sidereal time
  const gmst = (6.697375 + 0.0657098242 * t + hour) % 24;
  // Local mean sidereal time
  const lmst = (gmst + long / 15) % 24;
  const lmstRad = radians(15 * lmst);

  // Hour angle (rad)
  const haRad = (lmstRad - raRad) % (2 * Math.PI);

  // Elevation
  const elRad = Math.asin(Math.sin(decRad) * Math.sin(latRad)
    + Math.cos(decRad) * Math.cos(latRad) * Math.cos(haRad));

  // Azimuth
  let azRad = Math.asin((-Math.cos(decRad) * Math.sin(haRad)) / Math.cos(elRad));

  if (Math.sin(decRad) - Math.sin(elRad) * Math.sin(latRad) < 0) azRad = Math.PI - azRad;
  else if (Math.sin(azRad) < 0) azRad += 2 * Math.PI;

  return [elRad, azRad];
};

const isIntersects = (l1p1, l1p2, l2p1, l2p2) => {
  const [a, b, c, d, p, q, r, s] = [l1p1.x, l1p1.y, l1p2.x, l1p2.y, l2p1.x, l2p1.y, l2p2.x, l2p2.y];
  const det = (c - a) * (s - q) - (r - p) * (d - b);
  if (det === 0) return false;
  const lambda = ((s - q) * (r - a) + (p - r) * (s - b)) / det;
  const gamma = ((b - d) * (r - a) + (c - a) * (s - b)) / det;
  return (lambda > 0 && lambda < 1) && (gamma > 0 && gamma < 1);
};

export const borderPolygon = (vertices, inWidth, outWidth, closed=true) => {
  const l = vertices.length;
  const z = new THREE.Vector3(0, 0, 1);
  return vertices.map((v1, vi) => {
    if (!closed && vi == vertices.length - 1) return null;
    const closePrevious = !closed && vi == 0; 
    const closeNext = !closed && vi == vertices.length - 2;
    const v2 = vertices[(vi + 1) % l];
    const vn = vertices[(vi + 2) % l];
    const vp = vertices[(vi - 1 + l) % l];
    const eaveDir = v1.clone().sub(v2).normalize();
    const nextEaveDir = vn.clone().sub(v2).normalize();
    const previousEaveDir = vp.clone().sub(v1).normalize();
    let nextAngle = Math.acos(eaveDir.dot(nextEaveDir));
    if (Math.abs(nextAngle) < 0.10) nextAngle = Math.sign(nextAngle) * 0.10;
    let previousAngle = Math.acos(eaveDir.clone().negate().dot(previousEaveDir));
    if (Math.abs(previousAngle) < 0.10) previousAngle = Math.sign(previousAngle) * 0.10;
    const reverseNext = eaveDir.clone().cross(nextEaveDir).z < 0;
    const reversePrevious = eaveDir.clone().cross(previousEaveDir).z < 0;
    const orthoEaveDir = eaveDir.clone().cross(z).negate().normalize();
    const bisectorPreviousDir = eaveDir.clone().negate().add(previousEaveDir.clone()).normalize();
    const bisectorNextDir = eaveDir.clone().add(nextEaveDir.clone()).normalize();
    const previousR = Math.sin(previousAngle / 2);
    const nextR = Math.sin(nextAngle / 2);
    let v21, v22, v23, v13, v12, v11;
    if (closeNext) {
      v21 = v2.clone().add(orthoEaveDir.clone().multiplyScalar(inWidth));
      v22 = v2.clone().add(orthoEaveDir.clone().multiplyScalar(-outWidth));
      v23 = v2.clone().add(orthoEaveDir.clone().multiplyScalar(-outWidth));
    } else if (!reverseNext) {
      v21 = v2.clone().add(bisectorNextDir.clone().multiplyScalar(inWidth / nextR));
      v22 = v2.clone().add(bisectorNextDir.clone().multiplyScalar(-outWidth));
      v23 = v2.clone().add(orthoEaveDir.clone().multiplyScalar(-outWidth));
    } else {
      v21 = v2.clone().add(orthoEaveDir.clone().multiplyScalar(inWidth));
      v22 = v2.clone().add(bisectorNextDir.clone().multiplyScalar(-inWidth));
      v23 = v2.clone().add(bisectorNextDir.clone().multiplyScalar(outWidth / nextR));
    }
    if (closePrevious) {
      v13 = v1.clone().add(orthoEaveDir.clone().multiplyScalar(-outWidth));
      v12 = v1.clone().add(orthoEaveDir.clone().multiplyScalar(-outWidth));
      v11 = v1.clone().add(orthoEaveDir.clone().multiplyScalar(inWidth));
    } else if (!reversePrevious) {
      v13 = v1.clone().add(orthoEaveDir.clone().multiplyScalar(-outWidth));
      v12 = v1.clone().add(bisectorPreviousDir.clone().multiplyScalar(-outWidth));
      v11 = v1.clone().add(bisectorPreviousDir.clone().multiplyScalar(inWidth / previousR));
    } else {
      v13 = v1.clone().add(bisectorPreviousDir.clone().multiplyScalar(outWidth / previousR));
      v12 = v1.clone().add(bisectorPreviousDir.clone().multiplyScalar(-inWidth));
      v11 = v1.clone().add(orthoEaveDir.clone().multiplyScalar(inWidth));
    }
    return [v11, v12, v13, v23, v22, v21];
  }).filter((s) => s !== null);
};

export const shrinkPolygon = (vertices, setbacks) => {
  const l = vertices.length;
  const z = new THREE.Vector3(0, 0, 1);
  const getSetbackWidth = (vi) => (setbacks && setbacks[vi]) ?? 1;

  return vertices.map((v1, vi) => {
    const sbWidth = getSetbackWidth(vi);
    const sbWidthtNext = getSetbackWidth((vi + 1) % l);
    const sbWidthtPrevious = getSetbackWidth((vi - 1 + l) % l);
    const v2 = vertices[(vi + 1) % l];
    const vn = vertices[(vi + 2) % l];
    const vp = vertices[(vi - 1 + l) % l];
    const eaveDir = v1.clone().sub(v2).normalize();
    const nextEaveDir = vn.clone().sub(v2).normalize();
    const previousEaveDir = vp.clone().sub(v1).normalize();
    let nextAngle = Math.acos(eaveDir.dot(nextEaveDir));
    if (Math.abs(nextAngle) < 0.10) nextAngle = Math.sign(nextAngle) * 0.10;
    let previousAngle = Math.acos(eaveDir.clone().negate().dot(previousEaveDir));
    if (Math.abs(previousAngle) < 0.10) previousAngle = Math.sign(previousAngle) * 0.10;
    const reverseNext = eaveDir.clone().cross(nextEaveDir).z < 0;
    const reversePrevious = eaveDir.clone().cross(previousEaveDir).z < 0;

    const orthoEaveDir = eaveDir.clone().cross(z).negate().normalize();

    let v3 = Math.abs(nextAngle) < 3.0
      ? v2.clone()
        .add(nextEaveDir.clone().multiplyScalar(sbWidth / Math.sin(nextAngle)).multiplyScalar(reverseNext ? -1 : 1))
        .add(eaveDir.clone().multiplyScalar(sbWidthtNext / Math.sin(nextAngle)).multiplyScalar(reverseNext ? -1 : 1))
      : v2.clone().add(orthoEaveDir.clone().multiplyScalar(sbWidth));

    let v4 = Math.abs(previousAngle) < 3.0
      ? v1.clone()
        .add(previousEaveDir.clone().multiplyScalar(sbWidth / Math.sin(previousAngle)).multiplyScalar(reversePrevious ? -1 : 1))
        .add(eaveDir.clone().negate().multiplyScalar(sbWidthtPrevious / Math.sin(previousAngle)).multiplyScalar(reversePrevious ? -1 : 1))
      : v1.clone().add(orthoEaveDir.clone().multiplyScalar(sbWidth));

    if (isIntersects(v2, v3, v1, v4)) {
      [v3, v4] = [v4, v3];
    }

    const splitLine = (t1, t2, t4) => {
      const v12 = t2.clone().sub(t1);
      const v14 = t4.clone().sub(t1);
      if (v12.angleTo(v14) > Math.PI / 2) {
        const d = v14.length();
        if (d > sbWidth) {
          const n = v12.clone().cross(v14);
          const t41 = t1.clone().add(v12.negate().cross(n).normalize().multiplyScalar(sbWidth));
          const t42 = t1.clone().add(v14.clone().normalize().multiplyScalar(sbWidth));
          return [true, t41, t42];
        }
      }
      return [false, t4, t4];
    };

    const [add1, v32, v31] = splitLine(v2, v1, v3);
    const [add2, v41, v42] = splitLine(v1, v2, v4);

    if (!add1 && !add2) return [v1, v2, v3, v4];
    if (!add2) return [v1, v2, v31, v32, v4];
    if (!add1) return [v1, v2, v3, v41, v42];
    return [v1, v2, v31, v32, v41, v42];
  });
};

export const segmentProperties = (sgement) => {
  const [center, normal] = getSegmentCenterAndNormal(sgement);
  const rcenter = new THREE.Vector3(center.x, center.z, center.y);
  const rnormal = new THREE.Vector3(normal.x, normal.z, normal.y);
  const monthDots = new Array(12).fill(0).map(() => []);
  return {
    center, normal, rcenter, rnormal, dots: monthDots,
  };
};

export const addMissedVertices = (segments, state, center, scale, indices = null) => {
  if (!indices) indices = getVerticesGroups(segments, state);
  const [cx, cy] = center;
  const roofVertices = state.orgVertices.filter((p, pi) => state.verticesType[pi] === 'roof');
  const detect = roofVertices.some((poly, pi) => {
    for (let vi = 0; vi < poly.length; vi += 1) {
      const vi2 = (vi + 1) % poly.length;
      const v1 = poly[vi];
      const v2 = poly[vi2];
      for (let g = 0; g < indices.length; g += 1) {
        const group = indices[g];
        const [[pj, vj]] = group;
        const skip = group.some(([pk, vk]) => (pi === pk && (vi === vk || vi2 === vk)));
        if (!skip) {
          const v3 = roofVertices[pj][vj];
          const d13 = v1.distanceTo(v3);
          const d23 = v2.distanceTo(v3);
          const d12 = v1.distanceTo(v2);
          if (!(pi === pj && vi === vj) && Math.abs(d13 + d23 - d12) < 0.02 && d13 > 0.1 && d23 > 0.1) {
            const { x, y } = v3;
            const len = segments[pi].geometry.length;
            const newVertex = [x / scale + cx, y / scale + cy];
            if (!state.changeClockwise[pi]) segments[pi].geometry.splice(vi2, 0, newVertex);
            else segments[pi].geometry.splice(len - vi, 0, newVertex);
            return true;
          }
        }
      }
    }
    return false;
  });
  return detect;
};

export const detectEdgeTypes = (segments, state, center, scale, updateStateFromSegments) => {
  let indices;
  let missDetect = false;
  do {
    segments.forEach((s) => { s.lines = s.geometry.map(() => ''); });
    indices = getVerticesGroups(segments, state);
    missDetect = addMissedVertices(segments, state, center, scale, indices);
    if (missDetect) updateStateFromSegments(segments, state, null, center[0], center[1]);
  } while (missDetect);

  const vertexType = [];
  indices.forEach((groups) => {
    if (groups.length === 1) vertexType.push('S');
    else {
      let angle = 0;
      groups.forEach(([pi, vi]) => {
        const count = state.orgVertices[pi].length;
        const via = (vi + 1) % count;
        const vib = (vi + count - 1) % count;
        const v1 = state.orgVertices[pi][via].clone().sub(state.orgVertices[pi][vi]);
        const v2 = state.orgVertices[pi][vib].clone().sub(state.orgVertices[pi][vi]);
        let a = v1.angleTo(v2);
        if (v2.clone().cross(v1).z < 0) {
          a = 2 * Math.PI - a;
        }
        angle += a;
      });
      angle = ((angle * 180) / Math.PI);
      if (angle < 180 - 25) vertexType.push('E1');
      else if (angle < 180 + 25) vertexType.push('E2');
      else if (angle < 360 - 25) vertexType.push('E3');
      else vertexType.push('C');
    }
  });

  // let e1c = vertexType.filter((t) => t === 'E1').length;
  // let e2c = vertexType.filter((t) => t === 'E2').length;
  // let e3c = vertexType.filter((t) => t === 'E3').length;
  // let cc = vertexType.filter((t) => t === 'C').length;
  // let sc = vertexType.filter((t) => t === 'S').length;

  const getLable = (pi, vi) => {
    const vgroupIndex = indices.map((group) => group.some(([pi2, vi2]) => pi2 === pi && vi2 === vi)).indexOf(true);
    return [vertexType[vgroupIndex], vgroupIndex];
  };

  const getEdgeCount = (pi, vi1, vi2) => {
    const [, gindex1] = getLable(pi, vi1);
    const [, gindex2] = getLable(pi, vi2);
    const set1 = indices[gindex1].map(([pj]) => pj);
    const set2 = indices[gindex2].map(([pj]) => pj);
    const commonSegments = set1.filter((value) => set2.includes(value));
    const otherSegments = commonSegments.filter(((value) => value !== pi));
    if (otherSegments.length) {
      const [pj] = otherSegments;
      const [vj1, vj2] = [gindex1, gindex2].map((gindex) => indices[gindex].filter(([pk]) => pk === pj).map(([, vk]) => vk)[0]);
      return [commonSegments.length, pj, vj1, vj2];
    }
    return [commonSegments.length, null, null, null];
  };

  const isConnect = (l1, l2, lb1, lb2) => (lb1.includes(l1) && lb2.includes(l2)) || (lb1.includes(l2) && lb2.includes(l1));

  const roofVertices = state.orgVertices.filter((p, pi) => state.verticesType[pi] === 'roof');
  roofVertices.forEach((poly, pi) => {
    poly.forEach((v, vi) => {
      const vi2 = (vi + 1) % poly.length;
      const [l1, l1i] = getLable(pi, vi);
      const [l2, l2i] = getLable(pi, vi2);
      const ec = (l1 === 'C' || l1 === 'E2' || l1 === 'ER') && (l2 === 'E2' || l2 === 'ER');
      const ce = (l2 === 'C' || l2 === 'E2' || l2 === 'ER') && (l1 === 'E2' || l1 === 'ER');
      const [edgeCount] = getEdgeCount(pi, vi, vi2);
      if (edgeCount === 2 && ((ec && indices[l2i].length === 2) || (ce && indices[l1i].length === 2))) {
        let d1;
        let d2;
        if (ec) {
          const vi3 = (vi2 + 1) % poly.length;
          d1 = roofVertices[pi][vi].clone().sub(roofVertices[pi][vi2]);
          d2 = roofVertices[pi][vi3].clone().sub(roofVertices[pi][vi2]);
        } else {
          const vi3 = (vi + poly.length - 1) % poly.length;
          d1 = roofVertices[pi][vi2].clone().sub(roofVertices[pi][vi]);
          d2 = roofVertices[pi][vi3].clone().sub(roofVertices[pi][vi]);
        }
        if (Math.abs(d1.angleTo(d2) - Math.PI / 2) < Math.PI / 10) {
          const svi = state.changeClockwise[pi] ? poly.length - vi - 1 : vi;
          segments[pi].lines[svi] = 'Ridge';
          if (ec) { vertexType[l2i] = 'ER'; } else { vertexType[l1i] = 'ER'; }
        }
      }
    });
  });

  for (let t = 0; t <= 1; t += 1) {
    roofVertices.forEach((poly, pi) => {
      poly.forEach((v, vi) => {
        const vi2 = (vi + 1) % poly.length;
        const [l1] = getLable(pi, vi);
        const [l2] = getLable(pi, vi2);
        const svi = state.changeClockwise[pi] ? poly.length - vi - 1 : vi;
        const [edgeCount] = getEdgeCount(pi, vi, vi2);
        if (t === 0 && segments[pi].lines[svi] === '') {
          if (edgeCount === 1 && isConnect(l1, l2, ['E1', 'E2', 'E3', 'S'], ['ER'])) segments[pi].lines[svi] = 'Rake';
          else if (edgeCount === 1 && isConnect(l1, l2, ['C'], ['S'])) segments[pi].lines[svi] = 'Rake';
          else if (edgeCount === 1 && isConnect(l1, l2, ['E1', 'E2', 'E3', 'S'], ['E1', 'E2', 'E3', 'S'])) segments[pi].lines[svi] = 'Eave';
          else if (edgeCount === 2 && isConnect(l1, l2, ['E3'], ['C'])) segments[pi].lines[svi] = 'Valley';
          else if (edgeCount === 2 && isConnect(l1, l2, ['E1'], ['C'])) segments[pi].lines[svi] = 'Hip';
          else if (edgeCount === 2 && isConnect(l1, l2, ['C'], ['C'])) segments[pi].lines[svi] = 'Ridge';
        } else if (edgeCount === 2 && isConnect(l1, l2, ['E2'], ['C']) && segments[pi].lines[svi] !== 'Ridge') segments[pi].lines[svi] = 'Hip';
      });
    });
  }

  const newState = {
    orgVertices: [], vertices: [], vertices3D: [], verticesType: [], eaves: [], pitches: [], azimuths: [], eaveHeights: [], UV: [], changeClockwise: [], ranges: [], parents: [],
  };

  updateStateFromSegments(segments, newState, state, center[0], center[1]);
  // const isEmpty = segments.every((s) => Number(s.pitch) === 0 && Number(s.height) === 0);
  // const cost = unbalanceCost(segments, newState) / indices.length;
  // if (isEmpty || cost > 50) {
  newState.pitches.forEach((p, pi) => { newState.pitches[pi] = 30; newState.eaveHeights[pi] = 10; });
  // }
  projectTo3DFromOrgVertices(newState, false);

  roofVertices.forEach((poly, pi) => {
    poly.forEach((v, vi) => {
      const poly3d = newState.vertices3D[pi];
      const vi2 = (vi + 1) % poly.length;
      const svi = newState.changeClockwise[pi] ? poly.length - vi - 1 : vi;
      const v1 = newState.orgVertices[pi][vi];
      const v2 = newState.orgVertices[pi][vi2];
      const v3di1 = poly3d[vi];
      const v3di2 = poly3d[vi2];
      const [edgeCount, pj, vj1, vj2] = getEdgeCount(pi, vi, vi2);
      if (Math.atan2(Math.abs(v3di1.z - v3di2.z), v1.clone().sub(v2).length()) < Math.PI / 24) {
        const polyz = poly3d.map((v) => v.z);
        const minz = Math.min(...polyz);
        const maxz = Math.max(...polyz);
        const middlez = minz + (maxz - minz) / 2;
        if (v3di1.z < middlez) segments[pi].lines[svi] = 'Eave';
        else segments[pi].lines[svi] = 'Ridge';
      } else if (edgeCount === 2) {
        const poly3d2 = newState.vertices3D[pj];
        if (poly3d2) {
          const v3dj1 = poly3d2[vj1];
          const v3dj2 = poly3d2[vj2];
          const { normal: normal1, center: center1 } = segmentProperties(poly3d);
          const { normal: normal2, center: center2 } = segmentProperties(poly3d2);
          const ev1 = v3di2.clone().sub(v3di1);
          const vcen1 = v3di1.clone().add(ev1.clone().multiplyScalar(0.5));
          const ev2 = v3dj2.clone().sub(v3dj1);
          const vcen2 = v3dj1.clone().add(ev2.clone().multiplyScalar(0.5));
          const vl = ev1.clone().cross(normal1.clone());
          const vr = ev2.clone().cross(normal2.clone().multiplyScalar(-1));
          vl.normalize();
          vr.normalize();
          const vol = vcen1.clone().add(vl.multiplyScalar(15));
          const vor = vcen2.clone().add(vr.multiplyScalar(15));
          const [vcl] = calculatePolygonZdim([vcen1], newState.eaves[pi], newState.pitches[pi], newState.eaveHeights[pi]);
          const [vcr] = calculatePolygonZdim([vcen2], newState.eaves[pj], newState.pitches[pj], newState.eaveHeights[pj]);
          const [vzl] = calculatePolygonZdim([vol], newState.eaves[pi], newState.pitches[pi], newState.eaveHeights[pi]);
          const [vzr] = calculatePolygonZdim([vor], newState.eaves[pj], newState.pitches[pj], newState.eaveHeights[pj]);
          const [vzrl] = calculatePolygonZdim([vor], newState.eaves[pi], newState.pitches[pi], newState.eaveHeights[pi]);
          const [vzrr] = calculatePolygonZdim([vol], newState.eaves[pj], newState.pitches[pj], newState.eaveHeights[pj]);
          const zdiff = vzl.clone().sub(vcl).add(vzr.clone().sub(vcr)).z;
          const zcdiff = Math.abs(vcl.z - vcr.z);
          if (zdiff > 2) segments[pi].lines[svi] = 'Valley';
          else if (zdiff < -2) segments[pi].lines[svi] = 'Hip';
          else segments[pi].lines[svi] = 'Rake';
          // if (vzl.z - vcl.z > 0 && vzr.z - vcr.z > 1) segments[pi].lines[svi] = 'Valley';
          // else if (vcl.z - vzl.z > 1 && vcr.z - vzr.z > 1) segments[pi].lines[svi] = 'Hip';
          // else segments[pi].lines[svi] = 'Rake';
        }
      } else if (edgeCount === 1) {
        segments[pi].lines[svi] = 'Rake';
      }
    });
  });

  // roofVertices.forEach((poly, pi) => {
  //   poly.forEach((v, vi) => {
  //     const vi2 = (vi + 1) % poly.length;
  //     const svi = newState.changeClockwise[pi] ? poly.length - vi - 1 : vi;
  //     const [edgeCount, [pj]] = getEdgeCount(pi, vi, vi2);
  //     if (edgeCount === 2) {
  //       const label1 = segments[pi].lines[svi];
  //       const poly2 = roofVertices[pj];
  //       if (poly2) {
  //         for (let vj = 0; vj < poly2.length; vj += 1) {
  //           const vj2 = (vj + 1) % poly2.length;
  //           const ec = poly[vi].equals(poly2[vj]) && poly[vi2].equals(poly2[vj2]);
  //           const ce = poly[vi].equals(poly2[vj2]) && poly[vi2].equals(poly2[vj]);
  //           if (ec || ce) {
  //             const svj = newState.changeClockwise[pj] ? poly2.length - vj - 1 : vj;
  //             const svj2 = newState.changeClockwise[pj] ? poly2.length - vj2 - 1 : vj2;
  //             const label2 = segments[pj].lines[ce ? svj : svj2];
  //             if (label1 !== label2) {
  //               if (label1 === 'Rake' || label2 === 'Rake') {
  //                 segments[pi].lines[svi] = 'Rake';
  //                 segments[pj].lines[svj] = 'Rake';
  //               } else {
  //                 segments[pj].lines[svj] = label1;
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   });
  // });
};

export const downloadCanvas = (canvas, fileName) => {
  const downloadLink = document.createElement('a');
  downloadLink.setAttribute('download', `${fileName}.png`);
  const dataURL = canvas.toDataURL('image/png');
  const url = dataURL.replace(/^data:image\/png/, 'data:application/octet-stream');
  downloadLink.setAttribute('href', url);
  downloadLink.click();
};

export const protectMethod = (method) => {
  const protectedMethod = (...arg) => {
    try {
      method(...arg);
    } catch (exp) {
      const div = document.createElement('div');
      div.innerHTML = String(exp);
      div.style.backgroundColor = 'black';
      div.style.color = 'white';
      document.body.appendChild(div);
      console.log(exp);
    }
  };
  return protectedMethod;
};

export const ransac = (points) => {
  if (points.length <= 2) {
    return { a: 0, b: 0, c: 0 };
  }

  const THRESHOLD = 0.1;
  const maxIter = 500;

  const best = {};
  let count = 0;
  let bestCnt = 0;
  for (let iter = 0; iter < maxIter; iter += 1) {
    const i0 = Math.floor(Math.random() * (points.length - 1));
    let i1;
    do {
      i1 = Math.floor(Math.random() * (points.length - 1));
    } while (i1 === i0);
    const p0 = points[i0];
    const p1 = points[i1];

    const slope = (p1[1] - p0[1]) / (p1[0] - p0[0]);
    const a = -1.0;
    const b = 1.0 / slope;
    const c = p0[0] - p0[1] / slope;

    let newC = c;
    let cnt = 0;
    const step = (Math.random() > 0.5 ? 1 : -1) * 0.02;
    do {
      count = 0;
      for (let i = 0; i < points.length; i += 1) {
        const point = points[i];
        const [x, y] = point;
        const distance = (a * x + b * y + newC) / Math.sqrt(a * a + b * b);
        if (Math.abs(distance) < THRESHOLD) count += 1;
      }
      cnt = count;
      if (bestCnt < cnt) {
        bestCnt = cnt;
        best.a = a;
        best.b = b;
        best.c = newC;
      }
      newC += step;
    } while (bestCnt < cnt);
  }

  return best;
};

export const segmentRegression = (eave, pointCloud) => {
  // const regression = new jsregression.LinearRegression({ alpha: 0, iterations: 100, lambda: 0 });
  const z = new THREE.Vector3(0, 0, 1);
  const [a, b, eaveType] = eave;
  const planeNormal = b.clone().sub(a).normalize();
  const planeDirection = planeNormal.clone().cross(z);
  const projectedPoints = projectToPlane(pointCloud, a, planeNormal);
  const alpha = Math.atan2(planeDirection.y, planeDirection.x);
  const mx = new THREE.Matrix4().makeTranslation(-a.x, -a.y, -a.z);
  mx.premultiply(new THREE.Matrix4().makeRotationZ(2 * Math.PI - alpha));
  projectedPoints.forEach((p) => p.applyMatrix4(mx));
  const points = projectedPoints.map((p) => [p.x, p.z]);
  const isRidge = eaveType === 'ridge';
  const model = ransac(points);
  const m = -model.a / model.b;
  // model.a = -m * model.b;
  let height = 0;
  let pitch = 0;
  if (Math.abs(model.b) > 1e-5) {
    if (isRidge) {
      const maxx = Math.max(...points.map(([x]) => x));
      height = -model.c / model.b - (model.a * maxx) / model.b;
    } else {
      height = -model.c / model.b;
    }
    pitch = Math.min(70, Math.abs((Math.atan(m, 1) / Math.PI) * 180));
    if (Number.isNaN(height)) height = 0;
    if (Number.isNaN(pitch)) pitch = 0;
    height = Math.min(100, Math.max(0, height - 0.2));
  }
  return [pitch, height];
};

const estimate = (xyz) => {
  // const { v } = SVD(axyz);
  // return v.map((r) => r[r.length - 1]);
  // return zp.linalg.svd(axyz)[-1][-1, :];
  const [v1, v2, v3] = xyz;
  const [a1, a2, a3] = [v2[0] - v1[0], v2[1] - v1[1], v2[2] - v1[2]];
  const [b1, b2, b3] = [v3[0] - v1[0], v3[1] - v1[1], v3[2] - v1[2]];
  const n = [a2 * b3 - a3 * b2, a3 * b1 - a1 * b3, a1 * b2 - a2 * b1];
  const sn = (n[0] ** 2 + n[1] ** 2 + n[2] ** 2) ** 0.5;
  const nn = [n[0] / sn, n[1] / sn, n[2] / sn];
  const c = [(v1[0] + v1[0] + v3[0]) / 3, (v1[1] + v1[1] + v3[1]) / 3, (v1[2] + v1[2] + v3[2]) / 3];
  const d = -(nn[0] * c[0] + nn[1] * c[1] + nn[2] * c[2]);
  return [...nn, d];
};

const choice = (data, sampleSize) => {
  const samples = new Array(sampleSize).fill(0).map(() => data[Math.floor(Math.random() * data.length)]);
  return samples;
};

const dot = (a, b) => a.map((x, i) => a[i] * b[i]).reduce((m, n) => m + n);

export const runRansac = (samples, estimateFunc, threshold, sampleSize, goalInliers, maxIterations, stopAtGoal = true) => {
  let bestIc = 0;
  let bestModel = null;
  for (let i = 0; i < maxIterations; i += 1) {
    const s = choice(samples, sampleSize);
    const m = estimateFunc(s);
    const ic = samples.map((sample) => Math.abs(dot(m, sample)) < threshold).reduce((a, b) => a + b);
    // ic = (np.abs(m.dot(samples.T)) < threshold).sum()
    if (ic > bestIc) {
      let newic = ic;
      const sign = Math.random() > 0.5 ? 1 : -1;
      do {
        bestIc = newic;
        bestModel = [...m];
        if (bestIc > goalInliers && stopAtGoal) break;
        m[3] += sign * 0.01;
        newic = samples.map((sample) => Math.abs(dot(m, sample)) < threshold).reduce((a, b) => a + b);
      } while (newic > ic);
      if (bestIc > goalInliers && stopAtGoal) break;
    }
  }

  // const inPoints = samples.filter((sample) => Math.abs(dot(bestModel, sample)) < threshold);
  // const { q, v } = SVD(inPoints);
  // const idx = q.indexOf(Math.min(...q));
  // bestModel = v.map((r) => r[idx]);

  return [bestModel, bestIc];
};

export const fitPlane = (data, maxIterations = 500, sampleSize = 3, goal = 0.7, threshold = 0.2, xthreshold = 0.4) => {
  const n = data.length;
  if (n === 0) return [[0, 0, 0], [0, 0, 0], 0, 0];
  const dataMean = data.reduce(([x1, y1, z1], [x2, y2, z2]) => [x1 + x2, y1 + y2, z1 + z2]).map((p) => p / n);
  const [cx, cy, cz] = dataMean;
  // const dataZeroMean = data.map(([x, y, z]) => [x - cx, y - cy, z - cz, 1]);
  const dataZeroMean = data.map(([x, y, z]) => [x, y, z, 1]);
  const goalInliers = n * goal;
  let m = null;
  let it = 0;
  let ic = 0;
  while ((m == null || ic < xthreshold) && threshold < 1) {
    [m, ic] = runRansac(dataZeroMean, estimate, threshold, sampleSize, goalInliers, maxIterations);
    ic /= dataZeroMean.length;
    it += 1;
    threshold += 0.05;
    maxIterations += 100;
    xthreshold -= 0.05;
  }
  return [m, dataMean, it, ic];
};

export const fitData = (data, model, center) => {
  // ax + by + cz + d = 0
  const [a, b, c, d] = model;
  const dataZ = data.map(([x, y]) => {
    const z = (-d - a * x - b * y) / c;
    return z;
  });
  const meanZ = dataZ.reduce((s1, s2) => s1 + s2) / dataZ.length;
  const newData = data.map(([x, y], di) => {
    // const z = dataZ[di] - meanZ + center[2];
    const z = dataZ[di];
    return [x, y, z];
  });
  return newData;
};

export const truncData = (data, dataSize) => {
  if (data.length > dataSize) {
    const sampleIndices = new Array(dataSize).fill(0).map((_, i) => i);
    sampleIndices.sort(() => (Math.random() > 0.5 ? 1 : -1));
    const samples = sampleIndices.map((i) => data[i]);
    return samples;
  }
  return data;
};

export const getDSMPoints = (segments, trees, state, center, scale, resolution, dsmRaster, minDSM, dmsOffset, mask, borderSize = 3) => {
  const { ranges } = state;

  const outBorderSize = 1;

  const [width, height] = [mask.width, mask.height];
  const contextMASK = mask.getContext('2d');
  const maskData = contextMASK.getImageData(0, 0, width, height);

  const planesPoints = segments.map(() => []);

  const fences = trees.filter((t) => t.dsm !== 'Keep')
  .map((t) => [...t.geometry[0], ...t.geometry[1]])
  .map(([x1,y1,x2,y2])=>[x1,x2,((x1-x2)**2+(y1-y2)**2)**0.5])

  // downloadCanvas(mask, 'indices.png');

  const addPoint = (x, y, si) => {
    const [ox, oy] = dmsOffset;
    const dsmIndex = (y + oy) * width + (x + ox);
    const z = (dsmRaster[dsmIndex] - minDSM) * (100 / resolution) * scale;
    planesPoints[si].push([(x - center[0]) * scale, (y - center[1]) * scale, z]);
  }

  const [minx, miny, maxx, maxy] = ranges;
  for (let x = minx; x <= maxx; x += 1) {
    for (let y = miny; y <= maxy; y += 1) {
      const inFence = fences.some(([cx,cy,cr]) => (x-cx)**2+(y-cy)**2<=cr**2);
      if (inFence) continue;
      const index = y * (width * 4) + x * 4;
      const indexTop = (y + 1) * (width * 4) + x * 4;
      const indexBottom = (y - 1) * (width * 4) + x * 4;
      const indexLeft = y * (width * 4) + (x - 1) * 4;
      const indexRight = y * (width * 4) + (x + 1) * 4;
      const si = maskData.data[index] - 1;
      const siTop = maskData.data[indexTop] - 1;
      const siBottom = maskData.data[indexBottom] - 1;
      const siLeft = maskData.data[indexLeft] - 1;
      const siRigth = maskData.data[indexRight] - 1;
      if (si >= 0 && si < segments.length && ((si === siTop ? 1 : 0) + (si === siBottom ? 1 : 0) + (si === siLeft ? 1 : 0) + (si === siRigth ? 1 : 0) >= 2)) {
        addPoint(x, y, si);
        if (si !== siTop) { const b = siTop >= 0 ? borderSize : outBorderSize; for (let k = 1; k <= b; k += 1) addPoint(x, y - k, si); }
        if (si !== siBottom) { const b = siBottom >= 0 ? borderSize : outBorderSize; for (let k = 1; k <= b; k += 1) addPoint(x, y + k, si); }
        if (si !== siLeft) { const b = siLeft >= 0 ? borderSize : outBorderSize; for (let k = 1; k <= b; k += 1) addPoint(x - k, y, si); }
        if (si !== siRigth) { const b = siRigth >= 0 ? borderSize : outBorderSize; for (let k = 1; k <= b; k += 1) addPoint(x + k, y, si); }
      }
    }
  }

  return planesPoints;
};

export const getPitchAzimuthFromModel = (model, projectedPoints) => {
  try {
    const [a, b, c] = model;
    const z = new THREE.Vector3(0, 0, 1);
    const n = new THREE.Vector3(a, b, c);
    if (n.z < 0) n.negate();
    const az = new THREE.Vector3(n.x, -n.y, 0);
    az.multiplyScalar(1 / az.length());
    let azimuth = 90 - ((Math.atan2(az.y, az.x) * 180) / Math.PI);
    if (azimuth < 0) azimuth += 360;
    const pitch = Math.abs(Math.acos(n.dot(z)) * 180) / Math.PI;
    const planeZ = projectedPoints.map((p) => p[2]);
    const height = Math.max(0, Math.min(40, Math.min(...planeZ)));
    return [pitch, azimuth, height];
  } catch {
    return [0, 0, 0];
  }
};

export const fitPlaneOnSegment = (segments, trees, state, center, scale, resolution, dsmRaster, minDSM, dmsOffset, mask) => {
  const footPerUnit = parseFloat(resolution) / 30.48;
  const { orgVertices, parents } = state;
  const planesPoints = getDSMPoints(segments, trees, state, center, scale, resolution, dsmRaster, minDSM, dmsOffset, mask);
  const models = [];
  const z = new THREE.Vector3(0, 0, 1);
  planesPoints.forEach((points, pi) => {
    try {
      const [model, mean] = fitPlane(points, 100);
      const roofVertices = orgVertices[pi].map((p) => [p.x, p.y, p.z]);
      const projectedPoints = fitData(roofVertices, model, mean);
      const [pitch, azimuth, height] = getPitchAzimuthFromModel(model, projectedPoints);
      segments[pi].pitch = Math.max(0, Math.min(69.99, pitch || 0));
      segments[pi].azimuth = azimuth || 0;
      segments[pi].height = (height / scale) * footPerUnit + 0.01;
      models.push([model, mean]);
    } catch {
      segments[pi].pitch = 0.0;
      segments[pi].azimuth = 0.0;
      segments[pi].height = 0.0;
    }
  });
  segments.forEach((s, si) => { if (parents[si].length) s.height = 0; });
  // const heights = segments.map((s) => s.height);
  // segments.forEach((s, si) => { heights[si] -= parents[si].map((pi) => segments[pi].height).reduce((a, b) => a + b, 0); });
  // segments.forEach((s, si) => { s.height = Math.max(0, heights[si]); });
};

export const distance = (a, b) => Math.hypot(a[0] - b[0], a[1] - b[1]);

export const rad2deg = (rad) => (rad * 180) / Math.PI;

export const getAzimuthFromLineLabel = (state, segments, index) => {
  const segment = segments[index];
  const { lines, geometry } = segment;

  const bestEdges = {
    Eave: { index: -1, len: 0 },
    Ridge: { index: -1, len: 0 },
    Valley: { index: -1, len: 0 },
  };

  const validEdgeType = Object.keys(bestEdges);
  geometry.forEach((v1, vi) => {
    const edgeType = lines[vi];
    if (validEdgeType.includes(edgeType)) {
      const vj = (vi + 1) % geometry.length;
      const v2 = geometry[vj];
      const edgeLength = distance(v1, v2);
      if (bestEdges[edgeType].len < edgeLength) {
        bestEdges[edgeType].index = vi;
        bestEdges[edgeType].len = edgeLength;
      }
    }
  });

  const edges = validEdgeType.map((k) => bestEdges[k]);
  const edge = edges.find((e) => e.index >= 0);

  if (!edge) return 0;

  const eaveIndex = edges.indexOf(edge);
  const eave = validEdgeType[eaveIndex] === 'Eave';

  const p1 = segment.geometry[edge.index];
  const p2 = segment.geometry[(edge.index + 1) % segment.geometry.length];

  let dx = p2[0] - p1[0];
  let dy = -(p2[1] - p1[1]);

  if (!state.changeClockwise[index]) {
    dx = -dx;
    dy = -dy;
  }

  const theta = eave ? (Math.atan2(-dy, dx) * 180) / Math.PI : (Math.atan2(dy, -dx) * 180) / Math.PI;
  let azimuth = (theta + 360) % 360;
  azimuth = Math.round((azimuth + Number.EPSILON) * 100) / 100;

  return azimuth;
};

export const setAzimuthFromLineLabel = (state, segments, setToSegments = false) => {
  segments.forEach((s, si) => {
    const azimuth = getAzimuthFromLineLabel(state, segments, si);
    state.azimuths[si] = azimuth;
    if (setToSegments) segments[si].azimuth = azimuth;
  });
};

export const isPointEqual = ([x1, y1], [x2, y2]) => (x1 === x2) && (y1 === y2);

export const clusterPoints = (segments) => {
  const segs = [...segments].map((segment, sidx) => {
    const points = [...segments].filter((el) => el.id !== segment.id).map(({ geometry }) => geometry).flat();
    segment.geometry.forEach((sp, spidx) => {
      points.forEach((p, pidx) => {
        if (!isPointEqual(sp, p) && distance(sp, p) < 0.25) {
          segment.geometry[spidx] = p;
        }
      });
    });
    return segment;
  });
  return segs;
};

export const setSegmentAttributes = (vertices, roofAttributes, newSegments) => {
  newSegments.forEach((seg, segIndex) => {
    newSegments[segIndex].pitch = Math.max(0, parseFloat(roofAttributes[segIndex][0]).toFixed(2));
    newSegments[segIndex].height = Math.max(0, parseFloat(roofAttributes[segIndex][1]).toFixed(2));
    let azimuth = parseFloat(roofAttributes[segIndex][2]).toFixed(2);
    if (azimuth < 0) azimuth = parseFloat(azimuth) + 360.00;
    if (azimuth >= 360) azimuth = parseFloat(azimuth) - 360.00;
    newSegments[segIndex].azimuth = parseFloat(azimuth);
    vertices[segIndex].forEach((v, vi) => {
      newSegments[segIndex].geometry[vi] = v;
    });
  });
  const mergedSegments = clusterPoints(newSegments);
  const filteredMergedSegments = mergedSegments.filter((el) => (el.pitch < 69 && el.height > 2.5));
  return filteredMergedSegments;
};
