import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  InputGroup,
  FormControl,
  Button,
  Spinner,
  Modal,
  CardColumns,
  Card,
} from 'react-bootstrap';
import { ChevronCompactRight } from 'react-bootstrap-icons';
import Delete from 'components/Delete';
import { segmentPropType } from '../../props';
import EditDropdown from './EditDropdown';
import PitchDropdown from './PitchDropdown';

import '../../../LeadDetails/CustomerDetails/TilePreview.css';

import { updateSegmentArea } from '../../utils';

import { DesignContext } from '../../Canvas3D/contexts/designContext';
import { LeadContext } from 'Leads/Details/hooks/context';

const pitches = [
  '0',
  '5',
  '9',
  '14',
  '18',
  '23',
  '27',
  '30',
  '34',
  '37',
  '40',
  '43',
  '45',
  '47',
  '49',
  '51',
  '53',
];

function RoofSegmentsTab({
  designType,
  hasDSM,
  segments,
  resolution,
  segmentAzimuthHandler,
  segmentEaveHeightHandler,
  segmentPitchAndAreaHandler,
  deleteShapeHandler,
  addPointToPolygonHandler,
  removePointFromPolygonHandler,
  measureHandler,
  runOptimizationHandler,
  runEdgeDetectionHandler,
  runAIHandler,
}) {
  const {
    optimizer1IsRunning,
    optimizer2IsRunning,
    edgeDetectionIsRunning,
    measureIsRunning,
    AIIsRunning,
  } = useContext(DesignContext);

  const { lead, leadImagesAPI, thumbnailsAPI, newEndpoint } =
    useContext(LeadContext);

  const selectThumbnailEndpoint = newEndpoint('crm/lead/thumbnails/select/');

  const [serverSide, setServerSide] = useState(false);
  const [degrees, setDegrees] = useState(true);
  const [depthForEdgeDetection, setDepthForEdgeDetection] = useState(true);

  const [selection, setSelection] = useState(false);
  const [loading, setLoading] = useState(true);
  const [leadImages, setLeadImages] = useState([]);
  const [selectedLeadImage, setSelectedLeadImage] = useState();
  const [leadImageIndices, setLeadImageIndices] = useState([]);

  useEffect(() => {
    setLoading(true);
    leadImagesAPI.list(`?lead=${lead.uid}`).then((data) => {
      const leadThumbnails = [];
      data = data.filter((v) => v.image_type === 'R');
      data.map((item) => {
        thumbnailsAPI
          .send('POST', {
            lead: lead.uid,
            lead_image: item.id,
          })
          .then((thumbnail) => {
            leadThumbnails.push({ ...item, img_str: thumbnail });
          });
      });
      const indices = [];
      for (let i = 0; i < leadThumbnails.length - 1; i++) {
        if (i === leadThumbnails.length - 1) {
          indices.push(i);
        } else if (i < leadThumbnails.length / 2) {
          indices.push(i + i);
        } else {
          indices.push(leadThumbnails.length - 1 - (i + 1));
        }
      }
      setLeadImages(leadThumbnails);
      setLeadImageIndices(indices);
      setLoading(false);
    });
  }, []);

  const handleEdit = (selectKey, segmentIdx) => {
    if (selectKey === 'add-point') {
      addPointToPolygonHandler(Number(segmentIdx));
    } else if (selectKey === 'remove-point') {
      removePointFromPolygonHandler(Number(segmentIdx));
    }
  };

  const handleThumbnailSelection = (e) => {
    const lead_image_id = selectedLeadImage;
    setLoading(true);
    selectThumbnailEndpoint
      .send('POST', {
        lead: lead.uid,
        lead_image: lead_image_id,
      })
      .then((res) => {
        setTimeout(() => {
          runAIHandler(lead_image_id);
        }, 2000);
        setLoading(false);
        setSelection(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateSegmentArea(segments, resolution);

  return (
    <>
      {selection && (
        <Modal
          show={selection}
          size="lg"
          onHide={() => setSelection(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Select a capture date</Modal.Title>
          </Modal.Header>
          {loading ? (
            <Modal.Body className="mx-3">
              <Spinner animation="border" size="lg" />
            </Modal.Body>
          ) : (
            <>
              <Modal.Body className="mx-3">
                <CardColumns>
                  {leadImages
                    .sort((a, b) => a.id - b.id)
                    .map((item) => {
                      return (
                        <Card
                          className={`bg-dark text-white card-hover vintage ${
                            item.id === selectedLeadImage
                              ? 'card-hover-selected'
                              : ''
                          }`}
                          onClick={() => setSelectedLeadImage(item.id)}
                        >
                          <Card.Img
                            className="img-thumbnail"
                            src={item.img_str}
                            alt="Card image"
                          />
                          <Card.ImgOverlay id={item.id}>
                            <small className="capture-date bg-dark">
                              {item.capture_date}
                            </small>
                          </Card.ImgOverlay>
                        </Card>
                      );
                    })}
                </CardColumns>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="ml-2"
                  onClick={() => handleThumbnailSelection()}
                >
                  Run AI
                </Button>
              </Modal.Footer>
            </>
          )}
        </Modal>
      )}
      {/* <Row className="mt-4">
        <Col>
          <Form.Check
            id="chk-measure-optimize-server-side"
            type="switch"
            checked={serverSide}
            onChange={(e) => setServerSide(e.target.checked)}
            label="Run Server-Side"
          />
        </Col>
      </Row> */}
      <Row className="mt-4">
        <Col sm="12">
          <Form.Check
            id="pitch"
            type="switch"
            checked={degrees}
            onChange={(e) => setDegrees(e.target.checked)}
            label={`Pitch in ${degrees ? 'Degrees' : 'Rise/Run'}`}
          />
        </Col>
      </Row>
      {/* <Row className="mt-4">
        <Col sm="12">
          <Form.Check
            id="depthForEdge"
            type="switch"
            checked={depthForEdgeDetection}
            onChange={(e) => setDepthForEdgeDetection(e.target.checked)}
            label="Use depth information in edge detection"
          />
        </Col>
      </Row> */}
      <Row className="mt-4">
        <Col>
          {leadImages.length !== 0 && (<Button
            variant="primary"
            size="sm"
            onClick={() => setSelection(true)}
            className="m-1"
          >
            {AIIsRunning && (
                <Spinner animation="border" role="status" size="sm" />
            )}
            {AIIsRunning ? ' Running AI' : 'Select Different Image'}
          </Button>
          )}
          {leadImages.length === 0 && (
            <Button
              variant="primary"
              size="sm"
              className="m-1"
              onClick={() => runAIHandler()}
              disabled={designType !== 'A'}
            >
              {AIIsRunning && (
                <Spinner animation="border" role="status" size="sm" />
              )}
              {' Run AI'}
            </Button>
          )}
        </Col>
      </Row>
      <Row className="mt-1 mb-4">
        <Col>
          <Button
            variant="outline-primary"
            size="sm"
            className="m-1"
            onClick={() => measureHandler(serverSide)}
            disabled={!hasDSM}
          >
            {measureIsRunning && (
              <Spinner animation="border" role="status" size="sm" />
            )}
            {' Measure'}     
          </Button>
          <ChevronCompactRight />
          <Button
            variant="outline-primary"
            size="sm"
            className="m-1"
            onClick={() => runOptimizationHandler(false, serverSide)}
          >
            {optimizer1IsRunning && (
              <Spinner animation="border" role="status" size="sm" />
            )}
            {' Optimize'}
          </Button>
          <ChevronCompactRight />
          <Button
            variant="outline-primary"
            size="sm"
            className="m-1"
            onClick={() =>
              runEdgeDetectionHandler(depthForEdgeDetection, serverSide)
            }
          >
            {edgeDetectionIsRunning && (
              <Spinner animation="border" role="status" size="sm" />
            )}
            {' Edge Detection'}
          </Button>
          {/* <Button size="sm" className="m-1" onClick={() => runOptimizationHandler(true)}>
            {'4-Optimize Vertex Position '}
            {optimizer2IsRunning && <Spinner animation="border" role="status" size="sm" />}
          </Button> */}
        </Col>
      </Row>
      <Row className="pl-2 my-2 font-weight-bold" noGutters>
        <Col xs={1}>#</Col>
        <Col xs={3} className="p-0 m-0">
          Pitch
        </Col>
        <Col xs={2} className="p-0 m-0">
          Azimuth
        </Col>
        {/* <Col>Area</Col> */}
        <Col xs={3}>Eave Height</Col>
        <Col xs={2}>Area</Col>
        <Col xs={1} />
      </Row>
      <Row className="m-1" noGutters>
        <Col className="p-1" xs={1} />
        <Col className="p-1 font-size-sm" xs={3}>
          Set All:
          {/* <Form.Control
            size="sm"
            as="select"
            onChange={(e) => segmentPitchAndAreaHandler(-1, e.target.value)}
          >
            <option disabled selected value> </option>
            {pitches.map((p, idx) => (
              <option key={p} value={idx}>
                {degrees ? p : `${idx}/12`}
              </option>
            ))}
          </Form.Control> */}
          <PitchDropdown
            degrees={degrees}
            pitches={pitches}
            segIdx={-1}
            segmentPitchAndAreaHandler={segmentPitchAndAreaHandler}
          />
        </Col>
        <Col className="p-1" xs={2} />
        <Col className="p-1 font-size-sm" xs={3}>
          Set All:
          <InputGroup size="sm">
            <Form.Control
              onChange={(e) => segmentEaveHeightHandler(-1, e.target.value)}
            />
            <InputGroup.Append>
              <InputGroup.Text>ft</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Col>
        <Col className="p-1" xs={2} />
        <Col className="p-1" xs={1} />
      </Row>
      {segments.map(({ id, pitch, azimuth, height, area }, index) => (
        <Row key={id} className="m-1" style={{ height: 36 }}>
          <Col className="p-1" xs={1}>
            {index + 1}
          </Col>
          <Col className="p-1" xs={3}>
            {/* <Form.Control
              size="sm"
              as="select"
              value={pitch}
              onChange={(e) => segmentPitchAndAreaHandler(index, e.target.value)}
            >
              {pitches.map((p, idx) => (
                <option key={p} value={idx}>
                  {degrees ? p : `${idx}/12`}
                </option>
              ))}
            </Form.Control> */}
            <PitchDropdown
              degrees={degrees}
              pitches={pitches}
              segIdx={index}
              segId={id}
              pitch={pitch}
              segmentPitchAndAreaHandler={segmentPitchAndAreaHandler}
            />
          </Col>
          <Col className="p-1" xs={2}>
            <Form.Control
              size="sm"
              type="number"
              placeholder="Azimuth"
              value={Math.round(azimuth)}
              onChange={(e) => segmentAzimuthHandler(index, e.target.value)}
            />
          </Col>
          <Col className="p-1" xs={3}>
            <InputGroup size="sm">
              <Form.Control
                type="number"
                value={Number(height)}
                onChange={(e) =>
                  segmentEaveHeightHandler(index, e.target.value)
                }
              />
              <InputGroup.Append>
                <InputGroup.Text>ft</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Col>
          <Col className="p-1" xs={2}>
            <Form.Control
              size="sm"
              placeholder="Area"
              value={Math.round(parseFloat(area))}
            />
          </Col>
          <Col className="p-1" xs={1}>
            <Delete id={id} onClick={() => deleteShapeHandler(id)} />
            {/* <table>
              <tr>
                <td>
                  <EditDropdown id={index} editHandler={handleEdit} />
                </td>
                <td>
                  <Delete id={id} onClick={() => deleteShapeHandler(id)} />
                </td>
              </tr>
            </table> */}
          </Col>
        </Row>
      ))}
    </>
  );
}

RoofSegmentsTab.propTypes = {
  designType: PropTypes.oneOf(['M', 'A']).isRequired,
  hasDSM: PropTypes.bool.isRequired,
  segments: segmentPropType.isRequired,
  segmentAzimuthHandler: PropTypes.func.isRequired,
  segmentPitchAndAreaHandler: PropTypes.func.isRequired,
  segmentEaveHeightHandler: PropTypes.func.isRequired,
  deleteShapeHandler: PropTypes.func.isRequired,
  addPointToPolygonHandler: PropTypes.func.isRequired,
  removePointFromPolygonHandler: PropTypes.func.isRequired,
  measureHandler: PropTypes.func.isRequired,
  runOptimizationHandler: PropTypes.func.isRequired,
  runEdgeDetectionHandler: PropTypes.func.isRequired,
  runAIHandler: PropTypes.func.isRequired,
};

export default RoofSegmentsTab;
