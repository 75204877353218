import React, {
  useState, useMemo, useRef, useEffect, useContext, useLayoutEffect,
} from 'react';
import * as THREE from 'three';

import { DesignContext } from '../contexts/designContext.js';

let groundMouseState = 0;

const Ground = ({
  position,
  width,
  height,
  showSunLight,
  showDSM,
  visible,
}) => {
  const {
    rgbTexture,
    dsmTexture,
    orgDSMTexture,
    minDSM,
    maxDSM,
    materials,
    resolution,
    scale,
    displacementBias,
    dsmOffset,
    showDSMHD,
    showDSMHouse,
    clearAllSelection,
  } = useContext(DesignContext);

  // const geometry = useRef();
  // const geometrySun = useRef();

  // const shape = useMemo(() => {
  //   const vertices = [];
  //   vertices.push(new THREE.Vector3(0, 0));
  //   vertices.push(new THREE.Vector3(width, 0));
  //   vertices.push(new THREE.Vector3(width, height));
  //   vertices.push(new THREE.Vector3(0, height));
  //   return new THREE.Shape(vertices);
  // }, [width, height]);

  // const groundDisp = useMemo(() => (
  //   <meshStandardMaterial
  //     attach="material"
  //     color="#000000"
  //     wireframe={false}
  //     side={THREE.DoubleSide}
  //     displacementMap={dsmTexture}
  //     displacementScale={100}
  //     emissive="#ffffff"
  //     emissiveMap={rgbTexture}
  //     metalness={1}
  //     roughness={1}
  //   />
  // ), [rgbTexture, dsmTexture]);

  // useEffect(() => {
  //   if (ground.current)
  //     ground.current.material.needsUpdate = true;
  // }, [materials, materials.ground, materials.groundSun]);

  const [x, y, z] = position;
  const planePosition = [x, z, y];
  const dispScale = (100 / (resolution / scale)) * (maxDSM - minDSM);
  const dispBias = -(displacementBias / 255) * dispScale;
  // const [dispScale, dispBias] = [((10000 / (resolution / scale)) / (maxDSM - minDSM)), -5];
  const dsmResolution = showDSMHD ? 1024 : 100;

  const meshDSM = useRef();
  const meshDSMSun = useRef();

  const changeSelection = (e) => {
    if (!e.ctrlKey && e.intersections.every((obj) => ['groundPlane', 'treeTrunk', 'treeBranch'].includes(obj.object.name))) {
      clearAllSelection(true, true);
    }
  };

  const groundDSMMesh = useMemo(() => (
    <mesh
      name="groundPlane"
      ref={meshDSM}
      castShadow
      receiveShadow
      visible={visible}
      rotation={[Math.PI, 0, 0]}
      position={[-x + width / 2, -y + height / 2, -0.2]}
    >
      <planeGeometry attach="geometry" args={[width, height, dsmResolution, dsmResolution]} />
      <meshStandardMaterial
        attach="material"
        color="#000000"
        wireframe={false}
        side={THREE.FrontSide}
        displacementMap={showDSMHouse ? orgDSMTexture : dsmTexture}
        displacementScale={dispScale}
        displacementBias={dispBias}
        emissive="#ffffff"
        emissiveMap={rgbTexture}
        castShadow
        receiveShadow
      />
      <meshDepthMaterial
        attach="customDepthMaterial"
        depthPacking={THREE.RGBADepthPacking}
        displacementMap={showDSMHouse ? orgDSMTexture : dsmTexture}
        displacementScale={dispScale}
        displacementBias={dispBias}
      />
    </mesh>
  ),
  [visible, x, width, y, height, dsmResolution, showDSMHouse, orgDSMTexture, dsmTexture, dispScale, dispBias, rgbTexture]);

  // const groundDSMHouseMesh = useMemo(() => (
  //   <mesh
  //     ref={meshDSM}
  //     name="groundPlane2"
  //     castShadow
  //     receiveShadow
  //     visible={visible}
  //     rotation={[Math.PI, 0, 0]}
  //     position={[-x + width / 2, -y + height / 2, -0.2]}
  //   >
  //     <planeGeometry attach="geometry" args={[width, height, dsmResolution, dsmResolution]} />
  //     <meshStandardMaterial
  //       attach="material"
  //       color="#000000"
  //       wireframe={false}
  //       side={THREE.DoubleSide}
  //       displacementMap={orgDSMTexture}
  //       displacementScale={dispScale}
  //       displacementBias={dispBias}
  //       emissive="#ffffff"
  //       emissiveMap={rgbTexture}
  //       castShadow
  //       receiveShadow
  //     />
  //     <meshDepthMaterial
  //       attach="customDepthMaterial"
  //       depthPacking={THREE.RGBADepthPacking}
  //       displacementMap={orgDSMTexture}
  //       displacementScale={dispScale}
  //       displacementBias={dispBias}
  //     />
  //   </mesh>
  // ),
  // [visible, x, width, y, height, orgDSMTexture, rgbTexture, dispBias, dispScale, dsmResolution]);

  const groundDSMSunMesh = useMemo(() => (
    <mesh
      ref={meshDSMSun}
      name="groundPlane"
      castShadow
      receiveShadow
      visible={visible}
      rotation={[Math.PI, 0, 0]}
      position={[-x + width / 2, -y + height / 2, -0.2]}
    >
      <planeGeometry attach="geometry" args={[width, height, dsmResolution, dsmResolution]} />
      <meshStandardMaterial
        attach="material"
        color="#ffffff"
        wireframe={false}
        side={THREE.FrontSide}
        map={rgbTexture}
        displacementMap={showDSMHouse ? orgDSMTexture : dsmTexture}
        displacementScale={dispScale}
        displacementBias={dispBias}
        castShadow
        receiveShadow
      />
      <meshDepthMaterial
        attach="customDepthMaterial"
        depthPacking={THREE.RGBADepthPacking}
        displacementMap={showDSMHouse ? orgDSMTexture : dsmTexture}
        displacementScale={dispScale}
        displacementBias={dispBias}
      />
    </mesh>
  ),
  [visible, x, width, y, height, dsmResolution, rgbTexture, showDSMHouse, orgDSMTexture, dsmTexture, dispScale, dispBias]);

  // const groundDSMSunHouseMesh = useMemo(() => (
  //   <mesh
  //     ref={meshDSMSun}
  //     name="groundPlane2"
  //     castShadow
  //     receiveShadow
  //     visible={visible}
  //     rotation={[Math.PI, 0, 0]}
  //     position={[-x + width / 2, -y + height / 2, -0.2]}
  //   >
  //     <planeGeometry attach="geometry" args={[width, height, dsmResolution, dsmResolution]} />
  //     <meshStandardMaterial
  //       attach="material"
  //       color="#ffffff"
  //       wireframe={false}
  //       side={THREE.DoubleSide}
  //       map={rgbTexture}
  //       displacementMap={orgDSMTexture}
  //       displacementScale={dispScale}
  //       displacementBias={dispBias}
  //       castShadow
  //       receiveShadow
  //     />
  //     <meshDepthMaterial
  //       attach="customDepthMaterial"
  //       depthPacking={THREE.RGBADepthPacking}
  //       displacementMap={orgDSMTexture}
  //       displacementScale={dispScale}
  //       displacementBias={dispBias}
  //     />
  //   </mesh>
  // ),
  // [visible, x, width, y, height, rgbTexture, orgDSMTexture, dispScale, dispBias, dsmResolution]);

  const groundMesh = useMemo(() => (
    <mesh
      name="groundPlane"
      castShadow
      receiveShadow
      visible={visible}
      rotation={[Math.PI, 0, 0]}
      position={[-x + width / 2, -y + height / 2, -0.2]}
      onPointerUp={(e) => { if (groundMouseState) changeSelection(e); }}
      onPointerDown={() => { groundMouseState = true; }}
      onPointerMove={() => { groundMouseState = false; }}
    >
      <planeGeometry attach="geometry" args={[width, height, 1, 1]} />
      <meshBasicMaterial
        attach="material"
        color="#ffffff"
        wireframe={false}
        side={THREE.FrontSide}
        map={rgbTexture}
      />
    </mesh>
  ),
  [x, width, y, height, rgbTexture, visible]);

  const groundSunMesh = useMemo(() => (
    <mesh
      name="groundPlane"
      castShadow
      receiveShadow
      visible={visible}
      rotation={[Math.PI, 0, 0]}
      position={[-x + width / 2, -y + height / 2, -0.2]}
    >
      <planeGeometry attach="geometry" args={[width, height, 1, 1]} />
      <meshStandardMaterial
        attach="material"
        color="#ffffff"
        wireframe={false}
        side={THREE.FrontSide}
        map={rgbTexture}
        castShadow
        receiveShadow
      />
    </mesh>
  ),
  [x, width, y, height, rgbTexture, visible]);

  // useEffect(() => {
  //   const uv = [0, 0, 1, 0, 1, 1, 0, 1];
  //   if (geometry.current) geometry.current.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(uv), 2));
  //   if (geometrySun.current) geometrySun.current.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(uv), 2));
  // }, [rgbTexture, dsmTexture, showSunLight, showDSM]);

  // useEffect(() => {
  //   groundMesh.material.needsUpdate = true;
  //   groundDSMMesh.material.needsUpdate = true;
  // }, [groundMesh, groundDSMMesh, showSunLight, showDSM]);

  if (showSunLight) return showDSM ? groundDSMSunMesh : groundSunMesh;
  return showDSM ? groundDSMMesh : groundMesh;

  // if (showSunLight && showDSM) return showDSMHouse ? groundDSMSunHouseMesh : groundDSMSunMesh;
  // if (!showSunLight && showDSM) return showDSMHouse ? groundDSMHouseMesh : groundDSMMesh;
  // return showDSM ? groundDSMMesh : groundMesh;
};

export default Ground;
