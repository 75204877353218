import React from 'react';

import IFrameLogo from 'components/IFrameLogo';

import PropTypes from 'prop-types';

export const Home = ({ visible, companyName }) => {
  if (companyName && visible) {
    if (companyName === 'Astrawatt' || companyName === 'demand-iq') {
      // Astrawatt/demand-iq exemption
      return null;
    }
    return (
      <IFrameLogo textColor="dark" />
    );
  }
  return null;
};

Home.propTypes = {
  visible: PropTypes.bool.isRequired,
  companyName: PropTypes.string.isRequired,
};

export default Home;
