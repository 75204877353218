import { useState, useEffect, useContext, useLayoutEffect } from 'react';
import * as THREE from 'three';
import canvasHandler from '../../DrawCanvas/handlers/canvasHandler';
import { DesignContext } from '../contexts/designContext';

export const useDrawing = (vertices, verticesType, segments, obstacles, trees, treeData, changeClockwise, getInchePerSceneUnit, scale, cx, cy, c) => {
  const {
    accordion,
    handleNewShape,
    handleSaveEdits,
    handleAddPointToPolygon,
    handleRemovePointFromPolygon,
    handleLineLabel,
    handleDeleteShape,
    annotations,
    setAnnotations,
  } = useContext(DesignContext);

  const pointToSegments = (v, zbased = false) => [v.x / scale + (cx / c), (zbased ? v.z : v.y) / scale + (cy / c)];

  const verticesToSegments = (newOrgVertices, zbased = false) => {
    const newSegmentsPosition = newOrgVertices.map((p, pi) => {
      const newVertices = p.map((v) => [v.x / scale + (cx / c), (zbased ? v.z : v.y) / scale + (cy / c)]);
      const vecs = newVertices.map((pnt) => new THREE.Vector3(pnt[0], pnt[1], 0));
      const area = THREE.ShapeUtils.area(vecs); 
      if (area > 0) {
        const first = newVertices[0];
        const last = newVertices[newVertices.length - 1];
        if (first[0] === last[0] && first[1] === last[1]) {
          newVertices.pop();
        }
        // newVertices.push([...first]);
        newVertices.reverse();
        // newVertices.pop();
        // [first] = newVertices;
        // last = newVertices[newVertices.length - 1];
        // if (first[0] === last[0] && first[1] === last[1]) {
        //   newVertices.pop();
        // }
      }
      return newVertices;
    });
    return newSegmentsPosition;
  };

  const splitGeometry = (geometry) => {
    const geo = geometry.map((g) => [Math.round(g[0]), Math.round(g[1])]);
    const geos = [];
    const processed = [];
    let lastInsertedIndex = -1;
    for (let gi = 0; gi < geo.length; gi += 1) {
      const g = geo[gi];
      const pi = processed.findIndex((x) => x[0] === g[0] && x[1] === g[1]);
      if (gi < geo.length - 1 && pi !== -1 && pi > lastInsertedIndex) {
        lastInsertedIndex = gi;
        const newGeo = geo.splice(pi, gi - pi);
        if (newGeo.length > 2) {
          newGeo.push(newGeo[0]);
          geos.push(newGeo);
        }
        geos.push(...splitGeometry(geo));
        break;
      }
      processed.push(g);
    }
    if (geos.length > 0) return geos;
    if (geo.length > 2) return [geo];
    return [];
  };

  const addShape = (shape, shapeType, objectType=null) => {
    const rawType = ["Driveway", "Wiring", "Meter"].includes(objectType);
    if (rawType) {
      const geom = shape.map((v) => ({ "x": v.x, "y": v.z, "z": v.y }));
      if (objectType === "Meter") geom.pop();
      const newShape = { id: canvasHandler.uuid(), shape: shapeType, geometry: geom, objectType: objectType };
      handleNewShape(newShape, false);
    } else {
      const geometry = verticesToSegments([shape], true);
      const geometries = shapeType === 'Polygon' || shapeType === 'Line' ? splitGeometry(geometry[0]) : [geometry[0]];
      geometries.forEach((geo) => {
        const newShape = { id: canvasHandler.uuid(), shape: shapeType, geometry: geo };
        if (shapeType === 'Point') {
          newShape.geometry = [geometry[0][0]];
          newShape.radius = getInchePerSceneUnit(shape[0].clone().sub(shape[1]).length()).toFixed(2);
        }
        handleNewShape(newShape, false);
      });
    }
  };

  const editShapes = (shapes, objectType=null) => {
    let oldShapes;
    let oldPolygons;
    if (accordion === 'segments' || accordion === 'obstacles') {
      const type = accordion === 'segments' ? 'roof' : 'obstacle';
      oldShapes = vertices.filter((s, si) => verticesType[si] === type);
      oldPolygons = accordion === 'segments' ? segments : obstacles;
    } else if (accordion === 'trees') {
      oldShapes = treeData.map((t) => [new THREE.Vector3(...t.position), new THREE.Vector3(...t.end)]);
      oldPolygons = trees;
    } else if (accordion === 'annotations') {
      const newAnnotations = [...annotations];
      newAnnotations.filter((a) => a.objectType == objectType).forEach((a, k) => {
        a.geometry = shapes[k].map((v) => ({ x: v.x, y: v.z, z: v.y }));
      });
      setAnnotations(newAnnotations);
      return;
    } else return;

    for (let nsi = 0; nsi < shapes.length; nsi += 1) {
      const os = oldShapes[nsi];
      const ns = shapes[nsi].map((p) => p.clone());
      if (ns.some((nsv, nsvi) => os[nsvi].x.toFixed(1) !== nsv.x.toFixed(1) && os[nsvi].y.toFixed(1) !== nsv.z.toFixed(1))) {
        const editedGeometry = verticesToSegments([ns], true)[0];
        switch (oldPolygons[nsi].shape) {
          case 'Polygon':
          case 'Line':
          case 'Circle':
            if (oldPolygons[nsi].geometry.some(([x, y], gi) => editedGeometry[gi][0] !== x && editedGeometry[gi][1] !== y)) {
              const editedShape = { ...oldPolygons[nsi], geometry: editedGeometry };
              handleSaveEdits(editedShape, false);
            }
            break;
          case 'Point': {
            const newR = Math.max(10, getInchePerSceneUnit(ns[0].clone().sub(ns[1]).length()).toFixed(2));
            const oldR = oldPolygons[nsi].radius;
            const [newX, newY] = editedGeometry[0];
            const [oldX, oldY] = oldPolygons[nsi].geometry[0];
            if (newX !== oldX || newY !== oldY || newR !== oldR) {
              const editedShape = { ...oldPolygons[nsi], geometry: [[newX, newY]], radius: newR };
              handleSaveEdits(editedShape, false);
            }
            break;
          }
          default:
            break;
        }
      }
    }
  };

  const deleteShape = (index, objectType=null) => {
    switch (accordion) {
      case 'segments':
        handleDeleteShape(segments[index].id);
        break;
      case 'obstacles':
        handleDeleteShape(obstacles[index].id);
        break;
      case 'trees':
        handleDeleteShape(trees[index].id);
        break;
      case 'annotations':
          const newAnnotations = [...annotations];
          const filteredAnnotation = newAnnotations.filter((a) => a.objectType == objectType);
          const idx = newAnnotations.findIndex((a) => a == filteredAnnotation[index]);
          if (idx > -1) {
            newAnnotations.splice(idx, 1);
            setAnnotations(newAnnotations);
          }
        break;
      default:
        break;
    }
  };

  const deletePointFromShape = (shapeIndex, vertexIndex, objectType=null) => {
    switch (accordion) {
      case 'segments': {
        const count = segments[shapeIndex].geometry.length;
        const vindex = changeClockwise[shapeIndex] ? (count - vertexIndex) % count : vertexIndex;
        handleRemovePointFromPolygon(shapeIndex, vindex);
        break;
      }
      case 'obstacles': {
        const obstacleIndex = segments.length + shapeIndex;
        const count = obstacles[shapeIndex].geometry.length;
        const vindex = changeClockwise[obstacleIndex] ? (count - vertexIndex) % count : vertexIndex;
        handleRemovePointFromPolygon(shapeIndex, vindex);
        break;
      }
      case 'annotations': {
        const newAnnotations = [...annotations];
        const filteredAnnotation = newAnnotations.filter((a) => a.objectType == objectType);
        const idx = newAnnotations.findIndex((a) => a == filteredAnnotation[shapeIndex]);
        if (idx > -1) {
          newAnnotations[idx].geometry.splice(vertexIndex, 1);
          if ((newAnnotations[idx].geometry.length < 2 && newAnnotations[idx].shape == "Line") || 
            (newAnnotations[idx].geometry.length < 3 && newAnnotations[idx].shape == "Polygon")) newAnnotations.splice(idx, 1);
          setAnnotations(newAnnotations);
        }
        break;
      }
      default:
        break;
    }
  };

  const addPointToShape = (shapeIndex, lineIndex, point, objectType=null) => {
    switch (accordion) {
      case 'segments': {
        const count = segments[shapeIndex].geometry.length;
        const vindex = changeClockwise[shapeIndex] ? (count - lineIndex) % count : lineIndex - 1;
        const newPoint = pointToSegments(point, true);
        handleAddPointToPolygon(shapeIndex, vindex, newPoint, true);
        break;
      }
      case 'obstacles': {
        const obstacleIndex = segments.length + shapeIndex;
        const count = obstacles[shapeIndex].geometry.length;
        const vindex = changeClockwise[obstacleIndex] ? (count - lineIndex) % count : lineIndex - 1;
        const newPoint = pointToSegments(point, true);
        handleAddPointToPolygon(shapeIndex, vindex, newPoint, true);
        break;
      }
      case 'annotations': {
        const newAnnotations = [...annotations];
        const filteredAnnotation = newAnnotations.filter((a) => a.objectType == objectType);
        const idx = newAnnotations.findIndex((a) => a == filteredAnnotation[shapeIndex]);
        if (idx > -1) {
          const newPoint = {"x": point.x, "y": point.z, "z": point.y};
          newAnnotations[idx].geometry = [
            ...newAnnotations[idx].geometry.slice(0, lineIndex),
            newPoint,
            ...newAnnotations[idx].geometry.slice(lineIndex),
          ];
          setAnnotations(newAnnotations);
        }
        break;
      }
      default:
        break;
    }
  };

  const setLineLabel = (shapeIndex, lineIndex, label, toggle = true) => {
    if (accordion === 'segments') {
      const count = segments[shapeIndex].geometry.length;
      const vindex = changeClockwise[shapeIndex] ? (count - lineIndex) % count : (count + lineIndex - 1) % count;
      const { lines } = segments[shapeIndex];
      if (lines[vindex] === label && toggle) lines[vindex] = '';
      else lines[vindex] = label;
      handleLineLabel(shapeIndex, vindex, lines);
    }
  };

  return {
    pointToSegments,
    verticesToSegments,
    addShape,
    editShapes,
    deleteShape,
    deletePointFromShape,
    addPointToShape,
    setLineLabel,
  };
};

export default useDrawing;
