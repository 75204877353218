import React, {
  useState, useMemo, useRef, useEffect, useLayoutEffect,
} from 'react';
import * as THREE from 'three';

const Vertex = ({
  position,
  addSelectedPoint = null,
  removeSelectedPoint = null,
  selectable = false,
  isSelected = false,
  index = -1,
  size = 0.5,
  color= "red",
}) => {
  const [selected, setSelected] = useState(false);

  const mat = useMemo(() => {
    return (
      <meshBasicMaterial
        attach="material"
        color={color}
        wireframe={false}
        side={THREE.DoubleSide}
      />
    );
  }, [color]);

  const matSelected = useMemo(() => {
    return (
      <meshBasicMaterial
        attach="material"
        color="green"
        wireframe={false}
        side={THREE.DoubleSide}
      />
    );
  }, []);

  // const mat = useMemo(() => {
  //   if (selected && selectable) {
  //     return (
  //       <meshBasicMaterial
  //         attach="material"
  //         color="green"
  //         wireframe={false}
  //         side={THREE.DoubleSide}
  //       />
  //     );
  //   }

  //   return (
  //     <meshBasicMaterial
  //       attach="material"
  //       color="red"
  //       wireframe={false}
  //       side={THREE.DoubleSide}
  //     />
  //   );
  // }, [selected, selectable]);

  const currentMat = (selected && selectable) ? matSelected : mat;

  useEffect(() => {
    if (selectable && isSelected !== selected) setSelected(isSelected);
  }, [isSelected, index]);

  const changeSelection = (e, selection) => {
    e.stopPropagation();
    if (selectable) {
      setSelected(selection);
      const [pIndex, vIndex] = index;
      if (selection) addSelectedPoint(pIndex, vIndex);
      else removeSelectedPoint(pIndex, vIndex);
    }
  };

  return (
    <mesh
      visible
      position={position}
      onClick={(e) => changeSelection(e, !selected)}
    >
      <sphereGeometry attach="geometry" args={[size, 10, 10]} />
      {currentMat}
    </mesh>
  );
};

export default Vertex;
