import {
  useState, useEffect, useContext, useCallback,
} from 'react';

import * as THREE from 'three';

import { DesignContext } from '../contexts/designContext';

import {
  pitchMap,
  isClockWise,
  dist,
  counterClockWise,
  calculateRoll,
  expandPolygon,
  expandPolygonWithRoll,
  calculatePolygonZdim,
  pointInConvexPolygon,
  pointInConcavePolygon,
  calculateRoofHeight,
  calculateRoofHeight2,
  optimizeVertexPosition,
  getEaveIndices,
  getEave,
  shrinkPolygon,
  getParentsPolygon,
  segmentProperties,
  projectToLine,
  protectMethod,
} from '../algorithms/geometry';

import {
  initializePanel,
  getPanelData,
  runPanelPlacement,
} from '../algorithms/panelOperation';

let panelDataOldData = null;
export const usePanelOperation = ({
  segments,
  panels,
  vertices3D,
  orgVertices,
  verticesType,
  eaves,
  pitches,
  eaveHeights,
  changeClockwise,
  inchePerUnit,
  scale,
  center,
  getSetbacks,
  readonly,
}) => {
  const {
    resolution,
    panelType,
    buffer,
    rowSpacing,
    panelTilt,
    setback,
    selectedObjects,
    setPanelPlacement,
  } = useContext(DesignContext);

  const [cx, cy] = center;
  const c = 1;

  const [panelData, setPanelData] = useState([]);
  const [moveStatus, setMoveStatus] = useState(0);

  const initPanel = (i) => initializePanel(segments[i], pitches[i], panelTilt, panelType, resolution);
  const initPanels = () => segments.map((s, si) => initPanel(si));

  const clearPanels = () => {
    const newPanels = initPanels();
    setPanelPlacement(newPanels);
  };

  const panelPlacement = useCallback((all = false, fillMode = 3, layoutMode = 1) => {
    const newPanels = runPanelPlacement({
      orgVertices, vertices3D, eaves, pitches, center,
    }, {
      segments, panels, panelType, scale, resolution, buffer, rowSpacing, panelTilt,
    }, getSetbacks, all, selectedObjects.roofSegments, fillMode, layoutMode);
    setPanelPlacement(newPanels);
  }, [buffer, rowSpacing, panelTilt, cx, cy, resolution, scale, segments, eaves, readonly, panelType]);

  const deletePanels = useCallback(() => {
    if (readonly) return;
    const newPanels = [...panels];
    const spnls = selectedObjects.panels;

    Object.keys(spnls).forEach((pi) => {
      const npi = parseInt(pi, 10);
      spnls[npi].sort((fEl, sEl) => parseInt(sEl, 10) - parseInt(fEl, 10));
      spnls[npi].forEach((pli) => {
        const hlen = newPanels[npi].horizontal.points.length;
        if (pli < hlen) newPanels[npi].horizontal.points.splice(pli, 1);
        else newPanels[npi].vertical.points.splice(pli - hlen, 1);
      });
    });

    newPanels.forEach((pnls) => {
      pnls.horizontal.selected = new Array(pnls.horizontal.points.length).fill(false);
      pnls.vertical.selected = new Array(pnls.vertical.points.length).fill(false);
    });

    setPanelPlacement(newPanels);
    selectedObjects.panels = [];
  }, [panels, readonly, selectedObjects.panels, setPanelPlacement]);

  const rotatePanels = useCallback(() => {
    if (readonly) return;
    const newPanels = [...panels];
    const spnls = selectedObjects.panels;
    const newSpnls = {};

    Object.keys(spnls).forEach((pi) => {
      const npi = parseInt(pi, 10);
      if (spnls[npi].length) {
        spnls[npi].sort((fEl, sEl) => parseInt(sEl, 10) - parseInt(fEl, 10));
        newSpnls[npi] = [];
        const hlen = newPanels[npi].horizontal.points.length;
        const vlen = newPanels[npi].vertical.points.length;
        const nsH = spnls[npi].filter((s) => s < hlen).length;
        const nsV = spnls[npi].length - nsH;
        const newH = hlen - nsH + nsV;
        const newV = vlen - nsV + nsH;
        Array(nsH).fill(0).forEach((_, ni) => newSpnls[npi].push(newH + newV - ni - 1));
        Array(nsV).fill(0).forEach((n, ni) => newSpnls[npi].push(newH - ni - 1));
        spnls[npi].forEach((pli) => {
          if (pli < hlen) {
            const pnl = newPanels[npi].horizontal.points.splice(pli, 1);
            newPanels[npi].vertical.points.push(pnl[0]);
          } else {
            const pnl = newPanels[npi].vertical.points.splice(pli - hlen, 1);
            newPanels[npi].horizontal.points.push(pnl[0]);
          }
        });
      }
    });

    setPanelPlacement(newPanels);
    selectedObjects.panels = newSpnls;
  }, [panels, readonly, selectedObjects.panels, setPanelPlacement]);

  const movePanels = useCallback((oldPosition, newPosition, status) => {
    if (readonly) return;
    setMoveStatus(status);
    if (status === 1) {
      panelDataOldData = JSON.parse(JSON.stringify(panels));
    } else if (status === 3) {
      const [ofx, ofy] = [(newPosition.x - oldPosition.x) / scale, (newPosition.z - oldPosition.z) / scale];
      const newPanels = [...panels];
      const z = new THREE.Vector3(0, 0, 1);
      Object.keys(selectedObjects.panels).forEach((pi) => {
        const hasEavex = eaves[pi] && eaves[pi][1];
        const eaveDirection = hasEavex ? eaves[pi][1].clone().sub(eaves[pi][0]).normalize() : new THREE.Vector3(1, 0, 0);
        eaveDirection.cross(z).normalize();
        selectedObjects.panels[pi].forEach((pli) => {
          const npi = parseInt(pi, 10);
          const hlen = newPanels[pi].horizontal.points.length;
          if (pli < hlen && newPanels[npi].horizontal.points && panelDataOldData[npi].horizontal.points) {
            newPanels[npi].horizontal.points[pli][0] = panelDataOldData[npi].horizontal.points[pli][0] + ofx - eaveDirection.x * 0.5;
            newPanels[npi].horizontal.points[pli][1] = panelDataOldData[npi].horizontal.points[pli][1] + ofy - eaveDirection.y * 0.5;
          } else if (newPanels[npi].vertical.points && panelDataOldData[npi].vertical.points) {
            newPanels[npi].vertical.points[pli - hlen][0] = panelDataOldData[npi].vertical.points[pli - hlen][0] + ofx - eaveDirection.x * 0.5;
            newPanels[npi].vertical.points[pli - hlen][1] = panelDataOldData[npi].vertical.points[pli - hlen][1] + ofy - eaveDirection.y * 0.5;
          }
        });
      });
      setPanelPlacement(newPanels);
    }
  }, [eaves, panels, readonly, scale, selectedObjects.panels, setPanelPlacement]);

  const insertPanels = useCallback((pi, position, type = 'v') => {
    if (readonly) return;
    let newPanels = [...panels];
    if (newPanels.length === 0) newPanels = initPanels();
    const pos = [position.x / scale + cx / c, position.z / scale + cy / c];
    if (type === 'v') newPanels[pi].vertical.points.push(pos);
    else newPanels[pi].horizontal.points.push(pos);
    setPanelPlacement(newPanels);
  }, [cx, cy, panels, readonly, scale, setPanelPlacement]);

  const alignHorizontal = useCallback(() => {
    if (readonly) return;
    const rackBuffer = Math.max(0.001, ((buffer / 10) / Number(resolution)));
    const newPanels = [...panels];
    Object.keys(selectedObjects.panels).forEach((pi) => {
      const row = [];
      const AB = eaves[pi][1].clone().sub(eaves[pi][0]).normalize();
      selectedObjects.panels[pi].forEach((vi) => {
        const p = panelData[pi].points[vi];
        const AP = new THREE.Vector3(p[0], p[1], p[2]).sub(eaves[pi][0]);
        // A + dot(AP,AB) / dot(AB,AB) * AB
        const d = AP.dot(AB) / AB.dot(AB);
        row.push([vi, d]);
      });
      if (row.length) {
        row.sort((e1, e2) => e1[1] - e2[1]);
        const hlen = newPanels[pi].horizontal.points.length;
        const [x, y] = row[0][0] >= hlen ? newPanels[pi].vertical.points[row[0][0] - hlen] : newPanels[pi].horizontal.points[row[0][0]];
        const xw = new THREE.Vector3(0, 0, 0);
        row.forEach(([vi], i) => {
          if (vi >= hlen) {
            newPanels[pi].vertical.points[vi - hlen] = [x + xw.x, y + xw.y];
          } else {
            newPanels[pi].horizontal.points[vi] = [x + xw.x, y + xw.y];
          }
          const w = (i < row.length - 1) && row[i + 1][0] >= hlen ? newPanels[pi].vertical.width : newPanels[pi].horizontal.width;
          xw.add(AB.clone().multiplyScalar(w + rackBuffer));
        });
      }
    });
    setPanelPlacement(newPanels);
  }, [eaves, panelData, panels, readonly, selectedObjects.panels, setPanelPlacement]);

  const alignVertical = useCallback(() => {
    if (readonly) return;
    const rackBuffer = Math.max(0.001, ((buffer / 10) / Number(resolution)));
    const newPanels = [...panels];
    const z = new THREE.Vector3(0, 0, 1);
    Object.keys(selectedObjects.panels).forEach((pi) => {
      const row = [];
      const AB = eaves[pi][1].clone().sub(eaves[pi][0]).cross(z).normalize();
      selectedObjects.panels[pi].forEach((vi) => {
        const p = panelData[pi].points[vi];
        const AP = new THREE.Vector3(p[0], p[1], p[2]).sub(eaves[pi][0]);
        // A + dot(AP,AB) / dot(AB,AB) * AB
        const d = AP.dot(AB) / AB.dot(AB);
        row.push([vi, d]);
      });
      if (row.length) {
        row.sort((e1, e2) => e1[1] - e2[1]);
        const hlen = newPanels[pi].horizontal.points.length;
        const [x, y] = row[0][0] >= hlen ? newPanels[pi].vertical.points[row[0][0] - hlen] : newPanels[pi].horizontal.points[row[0][0]];
        const xw = new THREE.Vector3(0, 0, 0);
        row.forEach(([vi], i) => {
          if (vi >= hlen) {
            newPanels[pi].vertical.points[vi - hlen] = [x + xw.x, y + xw.y];
          } else {
            newPanels[pi].horizontal.points[vi] = [x + xw.x, y + xw.y];
          }
          const w = row[i][0] >= hlen ? newPanels[pi].vertical.height : newPanels[pi].horizontal.height;
          xw.add(AB.clone().multiplyScalar(w + rackBuffer));
        });
      }
    });
    setPanelPlacement(newPanels);
  }, [eaves, panelData, panels, readonly, selectedObjects.panels, setPanelPlacement]);

  useEffect(() => {
    if (!eaves.length) return;
    const newPanels = getPanelData(panels, segments, pitches, orgVertices, eaves, eaveHeights, cx, cy, scale);
    setPanelData(newPanels);
  }, [segments, panels, pitches, eaves, eaveHeights, cx, cy, scale]);

  return (
    {
      moveStatus,
      panelData,
      panelPlacement: protectMethod(panelPlacement),
      deletePanels: protectMethod(deletePanels),
      rotatePanels: protectMethod(rotatePanels),
      movePanels: protectMethod(movePanels),
      insertPanels: protectMethod(insertPanels),
      alignHorizontal: protectMethod(alignHorizontal),
      alignVertical: protectMethod(alignVertical),
      clearPanels,
    }
  );
};

export default usePanelOperation;
