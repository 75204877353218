import { arrayOf, shape, number, string, bool } from 'prop-types';

const geometryType = arrayOf(arrayOf(number));

const segmentPropType = arrayOf(
  shape({
    id: string.isRequired,
    azimuth: number,
    pitch: number,
    height: number,
    lines: arrayOf(string),
    setbacks: arrayOf(number),
    geometry: geometryType,
    shape: string,
    ac_monthly: arrayOf(number),
    ac_annual: number,
    shade_factor: number,
  }),
);

const obstaclePropType = arrayOf(
  shape({
    id: string.isRequired,
    geometry: geometryType,
    shape: string,
    height: number,
    radius: number,
  }),
);

const treePropType = arrayOf(
  shape({
    id: string,
    height: number,
    model: string,
    geometry: geometryType,
    shape: string,
  }),
);

const panelsPropType = arrayOf(
  shape({
    id: string,
    azimuth: number,
    horizontal: shape({
      height: number,
      width: number,
      points: geometryType,
      selected: arrayOf(bool),
    }),
    vertical: shape({
      height: number,
      width: number,
      points: geometryType,
      selected: arrayOf(bool),
    }),
  }),
);

export { segmentPropType, obstaclePropType, treePropType, panelsPropType, geometryType };
