import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { AuthContext } from 'api/context';
import { FloatSelect, FloatInput } from 'components/Form/Inputs';

function DiscountModal({
  show,
  pid,
  amount: discountAmount,
  onHide,
  applyDiscount,
  isPreparedToCalc
}) {

  const { newEndpoint, user, displaySnack } = useContext(AuthContext);
  const discountTypeOptions = [{ name: 'Per Watt', value: 'W' }, { name: 'Flat', value: 'F' }, { name: 'Percent', value: 'P' }]
  
  const discountEndpoint = newEndpoint('discount/')
  const saveDiscountEndpoint = newEndpoint('discount/save/')

  const [discount, setDiscount] = useState(discountAmount);
  const [discountType, setDiscountType] = useState(user?.discount_type);
  const [discountValue, setDiscountValue] = useState();
  const [discountCap, setDiscountCap] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [calculated, setCalculated] = useState(false);

  const calculate = () => {
    if (discountType === 'W') {
      if (Number(discountValue) < 0) return displaySnack({ variant: 'danger', message: 'Value must be larger than 0' });
    }
    if (discountType === 'P') {
      if (Number(discountValue) > 99 || Number(discountValue) < 0) return displaySnack({ variant: 'danger', message: 'Value must be between 0 and 100' });
    }
    if (discountType === 'F') {
      if (Number(discountValue) < 0) return displaySnack({ variant: 'danger', message: 'Value must be larger than 0' });
    }
    discountEndpoint.send('POST', {
      email: user?.email,
      proposal_id: pid,
      discount_value: discountType === "P" ? Number(discountValue) / 100 : Number(discountValue),
      discount_type: discountType
    }).then((res) => {
      setDiscount(res?.discount);
      setCalculated(true);
      // saveProposalDiscount(res?.discount);
    }).catch((err) => {
      console.log(err);
    })
  };

  const approve = () => {
    if (discountAmount > 0) {
      applyDiscount(0);
    }
    applyDiscount(discount);
    setCalculated(false);
    setTimeout(() => onHide(), 250);
  };

  const saveProposalDiscount = (discount_amount) => {
    saveDiscountEndpoint.send(`POST`, {
      proposal_id: pid,
      discount: discountAmount,
    }).then((res) => {
      console.log(res);
    }).catch((err) => {
      console.log(err);
    })
  }

  useEffect(() => {
    setLoaded(false);
    setError(null);
    discountEndpoint.list(`?proposal_id=${pid}&email=${user?.email}`).then((res) => {
      setDiscountCap(res?.discount_cap);
      setLoaded(true);
    }).catch((err) => {
      console.log(`Proposal needs to be saved once at least: ${err}`);
      setLoaded(true);
      setError('Proposal needs to be saved once at least');
    });
  }, [show, discountCap]);

  return (
    <Modal
      size="sm"
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Discount</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loaded && !error && discountCap > 0 ? (
          <>
          <FloatSelect
            required
            label="Discount Type"
            nullValue="Choose Discount Type"
            options={discountTypeOptions}
            labelField='name'
            valueField='value'
            value={discountType? discountType : user?.discount_type}
            onChange={(e) => {
              setCalculated(false);
              setDiscountValue(0);
              applyDiscount(0);
              setDiscountType(e.target.value);
            }}
          />
          <br/>
          <FloatInput
            label={`Discount (${discountTypeOptions.filter((el) => el.value === discountType)[0]?.name})`}
            prepend={discountType !== "P" ? "$" : "%"}
            type="number"
            max={ discountType == "%" ? 100 : null }
            value={discountValue ? discountValue : null}
            onFocus={(e) => e.target.select()}
            onChange={(e) => {
              let value = +(e.target.value.replace(/[^0-9]/g, ''));
              if (discountType == "P" && value > 100) {
                value = discountValue;
              }
              if (value === 0) {
                value = '0';
              }

              setCalculated(false);
              setDiscountValue(value);
            }}
          />
          <InputGroup>
            <InputGroup.Prepend className='mt-2'><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
            <Form.Control
              type="number"
              className="mx-auto mt-2"
              onChange={(e) => {
                setDiscount(e.target.value)
              }}
              value={discount}
              disabled
            />
          </InputGroup>
          

          <p className="mt-2 small font-italic">
            {`Maximum discount permitted up to $${discountCap?.toFixed(2)}`}
          </p>
          </>
        ) : !isPreparedToCalc ? (
          <p>Please save your proposal by clicking on the floppy disk icon before applying a discount.</p>
        ) : !loaded ? (
          <p>Loading...</p>
        ) : (
          <p>Please contact your Company Admin to enable the discount feature for you.</p>
        ) }
      </Modal.Body>
        {loaded && !error && discountCap > 0 ? (
          <Modal.Footer>
            <Button size="sm" variant="primary" onClick={calculate}>
              Calculate
            </Button>
            <Button size="sm" variant="primary" onClick={approve} disabled={calculated ? false : true}>
              Approve
            </Button>
          </Modal.Footer>
        ) : null }
    </Modal>
  );
}

DiscountModal.propTypes = {
  show: PropTypes.bool.isRequired,
  amount: PropTypes.number.isRequired,
  discountCap: PropTypes.number.isRequired,
  onHide: PropTypes.func.isRequired,
  applyDiscount: PropTypes.func.isRequired,
  isPreparedToCalc: PropTypes.bool.isRequired
};

export default DiscountModal;
