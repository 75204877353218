import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Form, Modal } from 'react-bootstrap';

function SelectModal({ show, escalation, onHide, onSave }) {
  const [value, setValue] = useState(escalation);
  const handleSave = () => {
    onSave(value);
    onHide();
  };

  return (
    <Modal
      size="md"
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      onHide={onHide}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Utility Escalation Rate</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mt-2">
          <Col sm={8}>Escalation Percentage</Col>
          <Col sm={3}>
            <Form.Control
              as="select"
              onChange={(e) => setValue(e.target.value)}
              value={value}
            >
              <option value={1}>1%</option>
              <option value={2}>2%</option>
              <option value={3}>3%</option>
              <option value={4}>4%</option>
              <option value={5}>5%</option>
              <option value={6}>6%</option>
            </Form.Control>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button size="sm" variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

SelectModal.propTypes = {
  show: PropTypes.bool.isRequired,
  escalation: PropTypes.number.isRequired,
  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default SelectModal;
