import React from 'react';
import { Table } from 'react-bootstrap';
import { formatCurrency } from 'Main/utils';

/**
 * Calculate monthly net metering
 * @param {Object} params Parameters for calculation
 * @param {Array} params.monthlyProduction Array of monthly production values
 * @param {Array} params.monthlyConsumption Array of monthly consumption values
 * @param {number} params.systemSize System size in kW
 * @param {Object} params.utility Utility configuration
 * @param {Object} params.panel Panel configuration with degradation values
 * @returns {Object} Calculation results
 */
export const calculateMonthlyNetMeteringV2 = ({
    monthlyProduction,
    monthlyConsumption,
    systemSize,
    utility,
    panel,
    escalation
}) => {

    console.log('calculateMonthlyNetMeteringV2', {
        monthlyProduction,
        monthlyConsumption,
        systemSize,
        utility,
        panel
    });

    // Get rates configuration
    const rates = {
        // Pre-solar rates
        preSolarFixed: Number(utility.fixed_charge || 0),
        preSolarRate: Number(utility.rate_amount || 0),
        // Post-solar rates from net_metering_specs
        postSolarFixed: Number(utility.net_metering_specs?.ufsc || 22),
        exportRate: Number(utility.net_metering_specs?.export_rate || 0),
        offsetSavings: Boolean(utility.net_metering_specs?.offset_savings),
        // Add fixed cost type and related fields
        fixedCostType: utility.net_metering_specs?.fixed_cost_type || 1,
        costCap: Number(utility.net_metering_specs?.cost_cap || 0),
        costPerKw: Number(utility.net_metering_specs?.cost_per_kw || 0)
    };

    // Get panel degradation values
    const firstYearDegradation = panel?.first_year_degradation ? Number(panel.first_year_degradation) / 100 : 0.02;
    const annualDegradation = panel?.degradation ? Number(panel.degradation) / 100 : 0.0025;

    // Get escalation rate (default to 3%)
    const escalationRate = typeof escalation === 'number' ? escalation : 0.03;

    console.log('Monthly Net Metering Calculation - Using escalation rate:', {
        rawEscalation: escalation,
        escalationRate,
        isNumber: typeof escalation === 'number'
    });

    // Get current month and year
    const currentDate = new Date();
    const startMonthIndex = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];

    // Order months starting from current month through next year's month before current
    const orderedMonths = [...monthNames.slice(startMonthIndex), ...monthNames.slice(0, startMonthIndex)].map((name, i) => {
        const monthIndex = (startMonthIndex + i) % 12;
        const yearOffset = monthIndex < startMonthIndex ? 1 : 0; // If we've wrapped around to next year
        return {
            name,
            originalIndex: monthIndex,
            calendarYear: currentYear + yearOffset,
            isPreSolar: false // No pre-solar months when starting from current
        };
    });

    // Initialize results array for each year
    const yearlyResults = [];

    // Calculate for 30 years
    for (let year = 0; year < 30; year++) {
        // Apply rate escalations
        const yearRates = {
            preSolarFixed: rates.preSolarFixed * Math.pow(1 + escalationRate, year),
            preSolarRate: rates.preSolarRate * Math.pow(1 + escalationRate, year),
            postSolarFixed: rates.fixedCostType === 'constant' || rates.fixedCostType === 1
                ? rates.postSolarFixed * Math.pow(1 + escalationRate, year)
                : rates.postSolarFixed,
            exportRate: rates.exportRate * Math.pow(1 + escalationRate, year),
            offsetSavings: rates.offsetSavings,
            fixedCostType: rates.fixedCostType,
            costCap: rates.costCap,  // Cost cap doesn't escalate
            costPerKw: rates.costPerKw  // Cost per kW doesn't escalate
        };

        // Calculate post-solar fixed cost for this year
        const calculatePostSolarCost = () => {
            if (yearRates.fixedCostType === 'constant' || yearRates.fixedCostType === 1) {
                return yearRates.costCap || yearRates.postSolarFixed;
            }
            // For system size based cost, use reference system size from specs
            const referenceSystemSize = utility.net_metering_specs?.system_size || systemSize;
            // Cap at reference system size if actual size is larger
            const sizeToUse = Math.min(systemSize, referenceSystemSize);

            const calculatedCost = sizeToUse * yearRates.costPerKw;
            console.log('System Size Based Cost Calculation:', {
                actualSystemSize: systemSize,
                referenceSystemSize,
                sizeUsedForCalc: sizeToUse,
                costPerKw: yearRates.costPerKw,
                calculatedCost,
                costCap: yearRates.costCap,
                finalCost: yearRates.costCap ? Math.min(calculatedCost, yearRates.costCap) : calculatedCost
            });
            return yearRates.costCap ? Math.min(calculatedCost, yearRates.costCap) : calculatedCost;
        };

        const postSolarFixedCost = calculatePostSolarCost();

        // Apply degradation to production
        const degradationFactor = year === 0
            ? 1  // First year: no degradation
            : Math.pow(1 - annualDegradation, year - 1) * (1 - firstYearDegradation);

        const monthlyResults = [];

        // Process each month independently (no credit banking between months)
        orderedMonths.forEach((monthData, monthIndex) => {
            // Ensure all values are numbers
            const consumption = Number(monthlyConsumption[monthData.originalIndex] || 0);
            // Apply degradation to production, but zero for pre-solar months in first year
            const production = monthData.isPreSolar && year === 0
                ? 0
                : Number(monthlyProduction[monthData.originalIndex] || 0) * degradationFactor;
            const remaining = production - consumption;

            // Initialize payment values
            let amountToPay = Number(postSolarFixedCost); // Use calculated post-solar fixed cost instead of yearRates.postSolarFixed
            let exportCredit = 0;

            if (remaining < 0) {
                // Shortage: Pay for net consumption at retail rate
                const shortage = Math.abs(remaining);
                amountToPay += shortage * yearRates.preSolarRate;
            } else if (remaining > 0) {
                // Excess: Get credit at export rate if offset_savings is true
                if (yearRates.offsetSavings) {
                    // Calculate full export credit amount
                    exportCredit = remaining * yearRates.exportRate;
                }
            }

            // Calculate final amount:
            // Store the full export credit before any adjustments
            const fullExportCredit = exportCredit;

            // Calculate post-solar amount before credits
            const totalBeforeCredit = amountToPay;

            // Calculate final amount by comparing with post-solar fixed cost
            const finalAmount = totalBeforeCredit > fullExportCredit ?
                totalBeforeCredit - fullExportCredit :
                0;

            // Calculate how much of the export credit was actually used
            const actualExportCredit = Math.min(fullExportCredit, totalBeforeCredit);

            // Calculate pre-solar bill for comparison
            const preSolarBill = yearRates.preSolarFixed + (consumption * yearRates.preSolarRate);

            // Calculate savings
            const savings = Math.max(0, preSolarBill - finalAmount);

            monthlyResults.push({
                month: monthData.name,
                calendarYear: monthData.calendarYear + year,  // Add year offset to base calendar year
                consumption,
                production,
                remaining,
                postSolarFixed: yearRates.postSolarFixed,
                preSolarFixed: yearRates.preSolarFixed,
                preSolarRate: yearRates.preSolarRate,
                exportRate: yearRates.exportRate,
                amountToPay: finalAmount,
                exportCredit: actualExportCredit,
                fullExportCredit,
                preSolarBill,
                savings,
                paymentDetails: {
                    fixedCharge: yearRates.postSolarFixed,
                    energyCharge: Math.max(0, totalBeforeCredit - yearRates.postSolarFixed),
                    exportCredit: actualExportCredit,
                    fullExportCredit,
                    final: finalAmount,
                    totalBeforeCredit
                },
                isPreSolar: monthData.isPreSolar && year === 0  // Add this flag
            });
        });

        // Calculate annual totals (excluding pre-solar months for production/savings)
        const annualTotals = {
            totalProduction: monthlyResults.reduce((sum, m) =>
                sum + (m.isPreSolar ? 0 : m.production), 0),
            totalConsumption: monthlyResults.reduce((sum, m) => sum + m.consumption, 0),
            totalRemaining: monthlyResults.reduce((sum, m) =>
                sum + (m.isPreSolar ? 0 : m.remaining), 0),
            totalAmountToPay: monthlyResults.reduce((sum, m) => sum + m.amountToPay, 0),
            totalExportCredit: monthlyResults.reduce((sum, m) => sum + m.exportCredit, 0),
            preSolarCost: monthlyResults.reduce((sum, m) => sum + m.preSolarBill, 0),
            savings: monthlyResults.reduce((sum, m) => sum + m.savings, 0),
            isPartialYear: year === 0,
            activeMonths: monthlyResults.filter(m => !m.isPreSolar).length
        };

        yearlyResults.push({
            year: year + 1,
            monthlyResults,
            annualTotals,
            rates: yearRates
        });
    }

    // Calculate cumulative savings across years
    let cumulativeSavings = 0;
    yearlyResults.forEach(year => {
        cumulativeSavings += year.annualTotals.savings;
        year.annualTotals.cumulativeSavings = cumulativeSavings;
    });

    return {
        yearlyResults,
        metadata: {
            currentMonth: monthNames[startMonthIndex],
            cycleStartMonth: monthNames[startMonthIndex],
            escalationRate: escalationRate * 100,
            systemSize,
            utility
        }
    };
};

/**
 * Monthly Net Metering Table Component
 */
export const MonthlyNetMeteringTable = ({ calculationResults }) => {
    if (!calculationResults?.yearlyResults) {
        return <div>No calculation data available</div>;
    }

    const { yearlyResults, metadata } = calculationResults;
    const [selectedYear, setSelectedYear] = React.useState(1);

    // Get current year's data
    const currentYear = yearlyResults.find(y => y.year === selectedYear);
    if (!currentYear) return null;

    const { monthlyResults, annualTotals, rates } = currentYear;

    // Calculate 30-year totals
    const thirtyYearTotals = yearlyResults.reduce((acc, year) => ({
        totalProduction: acc.totalProduction + year.annualTotals.totalProduction,
        totalConsumption: acc.totalConsumption + year.annualTotals.totalConsumption,
        totalRemaining: acc.totalRemaining + year.annualTotals.totalRemaining,
        totalAmountToPay: acc.totalAmountToPay + year.annualTotals.totalAmountToPay,
        totalExportCredit: acc.totalExportCredit + year.annualTotals.totalExportCredit,
        totalSavings: acc.totalSavings + year.annualTotals.savings
    }), {
        totalProduction: 0,
        totalConsumption: 0,
        totalRemaining: 0,
        totalAmountToPay: 0,
        totalExportCredit: 0,
        totalSavings: 0
    });

    return (
        <div className="table-responsive">
            <div className="mb-3">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <small className="text-muted">
                            {currentYear.annualTotals.isPartialYear ?
                                `Solar starts from ${metadata.currentMonth} (${currentYear.annualTotals.activeMonths} active months)` :
                                'Full year'}
                        </small>
                        <div className="mt-1">
                            <select
                                className="form-control form-control-sm"
                                value={selectedYear}
                                onChange={(e) => setSelectedYear(Number(e.target.value))}
                            >
                                {yearlyResults.map(year => {
                                    const firstMonth = year.monthlyResults[0];
                                    const lastMonth = year.monthlyResults[11];
                                    return (
                                        <option key={year.year} value={year.year}>
                                            Year {year.year} ({firstMonth.calendarYear}-{lastMonth.calendarYear})
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="text-right">
                        <small className="d-block">
                            <strong>Pre-Solar Fixed Charge:</strong> ${rates.preSolarFixed.toFixed(2)}/month
                        </small>
                        <small className="d-block">
                            <strong>Pre-Solar Rate:</strong> ${rates.preSolarRate.toFixed(4)}/kWh
                        </small>
                        <small className="d-block">
                            <strong>Post-Solar Fixed Charge:</strong> {rates.fixedCostType === 'constant' || rates.fixedCostType === 1 ?
                                `$${rates.postSolarFixed.toFixed(2)}/month` :
                                `$${rates.costPerKw.toFixed(2)}/kW (Cap: $${rates.costCap.toFixed(2)})`
                            }
                        </small>
                        {rates.exportRate > 0 && (
                            <small className="d-block">
                                <strong>Export Rate:</strong> ${rates.exportRate.toFixed(4)}/kWh
                            </small>
                        )}
                        <small className="d-block text-muted mt-1">
                            Escalation: {metadata.escalationRate}% per year
                        </small>
                    </div>
                </div>
            </div>

            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Month</th>
                        <th>Consumption (kWh)</th>
                        <th>Production (kWh)</th>
                        <th>Net Energy (kWh)</th>
                        <th>Full Export Credit ($)</th>
                        <th>Applied Credit ($)</th>
                        <th>Pre-Solar Cost ($)</th>
                        <th>Post-Solar Cost ($)</th>
                        <th>Monthly Savings ($)</th>
                    </tr>
                </thead>
                <tbody>
                    {monthlyResults.map((month, index) => {
                        // Calculate tooltips
                        const netEnergyTooltip = `
                            Production: ${month.production.toFixed(2)} kWh
                            Consumption: ${month.consumption.toFixed(2)} kWh
                            Net Energy: ${month.remaining.toFixed(2)} kWh
                            ${month.remaining > 0 ? '(Excess Production)' : '(Energy Shortage)'}
                        `.trim();

                        const exportCreditTooltip = month.remaining > 0 ? `
                            Excess Energy: ${month.remaining.toFixed(2)} kWh
                            Export Rate: $${month.exportRate.toFixed(4)}/kWh
                            Full Export Credit: $${month.fullExportCredit.toFixed(2)}
                            ${month.fullExportCredit !== month.exportCredit ?
                                `\nActual Credit Applied: $${month.exportCredit.toFixed(2)}` +
                                `\n(Limited by post-solar cost of $${month.paymentDetails.totalBeforeCredit.toFixed(2)})` : ''}
                        `.trim() : 'No export credit for this month';

                        const preSolarTooltip = `
                            Fixed Charge: $${month.preSolarFixed.toFixed(2)}
                            Energy Charge: $${(month.consumption * month.preSolarRate).toFixed(2)}
                            (${month.consumption.toFixed(2)} kWh × $${month.preSolarRate.toFixed(4)}/kWh)
                            Total Pre-Solar: $${month.preSolarBill.toFixed(2)}
                        `.trim();

                        const postSolarTooltip = `
                            Total Before Credits:
                            - Fixed Charge: $${month.postSolarFixed.toFixed(2)}
                            ${month.paymentDetails.energyCharge > 0 ?
                                `- Energy Charge: $${month.paymentDetails.energyCharge.toFixed(2)}` +
                                `\n  (${Math.abs(month.remaining).toFixed(2)} kWh shortage × $${month.preSolarRate.toFixed(4)}/kWh)` : ''}
                            = Subtotal: $${month.paymentDetails.totalBeforeCredit.toFixed(2)}
                            ${month.fullExportCredit > 0 ?
                                `\nFull Export Credit: -$${month.fullExportCredit.toFixed(2)}` +
                                (month.fullExportCredit !== month.exportCredit ?
                                    `\nActual Credit Applied: -$${month.exportCredit.toFixed(2)}` +
                                    `\n(Limited by post-solar cost)` : '') : ''}
                            \nFinal Amount: $${month.amountToPay.toFixed(2)}
                            ${month.amountToPay === 0 ? '\n(Export credit covered entire bill)' : ''}
                        `.trim();

                        const savingsTooltip = `
                            Pre-Solar Cost: $${month.preSolarBill.toFixed(2)}
                            Post-Solar Cost: -$${month.amountToPay.toFixed(2)}
                            ${month.exportCredit > 0 ? `Export Credit: +$${month.exportCredit.toFixed(2)}` : ''}
                            Total Savings: $${month.savings.toFixed(2)}
                        `.trim();

                        return (
                            <tr key={month.month}
                                style={month.isPreSolar ? {
                                    backgroundColor: '#f5f5f5',
                                    color: '#999'
                                } : {}}>
                                <td>
                                    {month.month} {month.calendarYear}
                                    {month.isPreSolar && <span className="ml-1 text-muted">†</span>}
                                </td>
                                <td>{month.isPreSolar ? '-' : month.consumption.toFixed(2)}</td>
                                <td>{month.isPreSolar ? '-' : month.production.toFixed(2)}</td>
                                <td style={{ color: month.isPreSolar ? '#999' : (month.remaining >= 0 ? 'green' : 'red') }}>
                                    {month.isPreSolar ? '-' : month.remaining.toFixed(2)}
                                </td>
                                <td>{month.isPreSolar ? '-' :
                                    (month.fullExportCredit > 0 ? formatCurrency(month.fullExportCredit) : '-')}
                                </td>
                                <td>{month.isPreSolar ? '-' :
                                    (month.exportCredit > 0 ? formatCurrency(month.exportCredit) : '-')}
                                </td>
                                <td>{formatCurrency(month.preSolarBill)}</td>
                                <td>{month.isPreSolar ? '-' : formatCurrency(month.amountToPay)}</td>
                                <td style={{ color: month.isPreSolar ? '#999' : (month.savings >= 0 ? 'green' : 'red') }}>
                                    {month.isPreSolar ? '-' : formatCurrency(month.savings)}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <th>Annual Total</th>
                        <td>{annualTotals.totalConsumption.toFixed(2)}</td>
                        <td>{annualTotals.totalProduction.toFixed(2)}</td>
                        <td>{annualTotals.totalRemaining.toFixed(2)}</td>
                        <td>{formatCurrency(monthlyResults.reduce((sum, m) => sum + m.fullExportCredit, 0))}</td>
                        <td>{formatCurrency(annualTotals.totalExportCredit)}</td>
                        <td>{formatCurrency(annualTotals.preSolarCost)}</td>
                        <td>{formatCurrency(annualTotals.totalAmountToPay)}</td>
                        <td style={{ color: annualTotals.savings >= 0 ? 'green' : 'red' }}>
                            {formatCurrency(annualTotals.savings)}
                        </td>
                    </tr>
                </tfoot>
            </Table>

            <div className="mt-3">
                <h6>30-Year Summary</h6>
                <div className="row">
                    <div className="col-md-4">
                        <small className="d-block">
                            <strong>Total Production:</strong> {thirtyYearTotals.totalProduction.toFixed(2)} kWh
                        </small>
                        <small className="d-block">
                            <strong>Total Consumption:</strong> {thirtyYearTotals.totalConsumption.toFixed(2)} kWh
                        </small>
                        <small className="d-block">
                            <strong>Total Net Energy:</strong> {thirtyYearTotals.totalRemaining.toFixed(2)} kWh
                        </small>
                    </div>
                    <div className="col-md-4">
                        <small className="d-block">
                            <strong>Total Export Credits:</strong> {formatCurrency(thirtyYearTotals.totalExportCredit)}
                        </small>
                        <small className="d-block">
                            <strong>Total Amount to Pay:</strong> {formatCurrency(thirtyYearTotals.totalAmountToPay)}
                        </small>
                        <small className="d-block">
                            <strong>Net Cost:</strong> {formatCurrency(thirtyYearTotals.totalAmountToPay - thirtyYearTotals.totalExportCredit)}
                        </small>
                    </div>
                    <div className="col-md-4">
                        <small className="d-block">
                            <strong>Pre-Solar 30-Year Cost:</strong> {formatCurrency(yearlyResults.reduce((sum, year) => sum + year.annualTotals.preSolarCost, 0))}
                        </small>
                        <small className="d-block">
                            <strong>Post-Solar 30-Year Cost:</strong> {formatCurrency(thirtyYearTotals.totalAmountToPay - thirtyYearTotals.totalExportCredit)}
                        </small>
                        <small className="d-block text-success">
                            <strong>Total 30-Year Savings:</strong> {formatCurrency(thirtyYearTotals.totalSavings)}
                        </small>
                    </div>
                </div>
            </div>

            <div className="mt-2">
                {currentYear.annualTotals.isPartialYear && (
                    <small className="text-muted d-block">
                        † Pre-solar months (before {metadata.currentMonth})
                    </small>
                )}
            </div>
        </div>
    );
}; 