import axios from 'axios';

import { optimalSelection } from './panelOperation.js';
import { filterDictByKeys, daysInMonth as days, daysOfWeek } from './utils.js';

export const serviceTypes = [
  'Delivery with Standard Offer',
  'Bundled',
];

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

// handles financials for a loan payment
export const calculateLoan = (loan, yearlySavings, totalCost, rebateAmount) => {
  const { downPayment, interestRate, years } = loan;
  const rate = interestRate / 100 / 12;
  const m = years * 12;

  // calculate new loan amount
  const total = totalCost + rebateAmount;
  const amount = Math.max(total - downPayment, 0);
  const rebates = loan.rebated ? rebateAmount : 0;

  // calculate monthly and lifetime (25 year) savings
  loan.total = total;
  loan.amount = amount;
  loan.monthlyPayments = rate
    ? (amount * rate * (1 + rate) ** m) / ((1 + rate) ** m - 1)
    : amount / m;

  const payments = loan.monthlyPayments * 12;

  loan.lifetimeSavings = yearlySavings.map((value, year) => (
    year < years
      ? value - payments * (year + 1) : value - payments * years
  )).map((value) => value - downPayment + rebates);

  const data = { loan, totalCost };
  return data;
};

// helper to account for increasing cost of electrcity
export const calculateEscalation = (yearlySavings, r) => {
  let prev = 0;
  return yearlySavings.map((saved, t) => {
    const savings = prev + saved * (1 + r) ** t;
    prev = savings;
    return savings;
  });
};

export const calculateSolar = (panel, annualUsage, design, panelShadings) => {
  const panelkW = panel.power / 1000;

  const selectedPanels = optimalSelection(design, panelkW, annualUsage, panelShadings);

  return selectedPanels;
};

export const calculateSolarWithNoShading = (panel, annualUsage, design, proposalWithShadings) => {
  const panelkW = panel.power / 1000;

  let usage = annualUsage;
  // let total_panels = 0;
  // let solarProduction = 0;
  const selectedPanels = design
    .sort((a, b) => b.annualTSRF - a.annualTSRF)
    .map((roof) => {
      const total = roof.panels.length;
      const production = proposalWithShadings === true ? roof.acAnnual * roof.annualAccess : roof.acAnnual;
      // console.log('Production:', production);

      if (usage < 0) return Array(total).fill(0);
      const roofProduction = panelkW * production * total;
      usage -= roofProduction;

      if (usage > 0) {
        // total_panels += total;
        // solarProduction += roofProduction;
        return Array(total).fill(1);
      }
      // if we exceeded the offset, find how many panels were needed to get the offset
      const nPanels = Math.floor((usage + roofProduction) / (panelkW * production));
      // total_panels += nPanels;
      // solarProduction += nPanels * panelkW * production;
      usage = -1;

      // only fill panels that have the same orientation
      if (roof.vertical && roof.horizontal > roof.vertical) {
        return [
          ...Array(roof.vertical).fill(0),
          ...Array(nPanels).fill(1),
          ...Array(total - roof.vertical - nPanels > 0 ? total - roof.vertical - nPanels : 0).fill(0),
        ];
      }
      return [
        ...Array(nPanels).fill(1),
        ...Array(total - nPanels).fill(0),
      ];
    });

  return selectedPanels;
};

export const calculateSavings = (panel, design, selectedPanels, escalation, annualUsage, proposalWithShadings, utility, panelShadings, discount, utilityRate) => {
  // console.log('\n....................................Calculating Savings....................................\n');
  const { averageBill, effectiveRate, fixedCharge } = calculateBill(utility, false);
  const panelkW = panel.power / 1000;
  // yearly degradation after first year
  const degradation = panel.degradation / 100;
  const firstYearDegradation = panel?.first_year_degradation / 100 || 0.02;

  console.log('degradation: ', degradation, firstYearDegradation);

  console.log('selectedPanels: ', selectedPanels);

  // preparing to calculate total solar production from selected panels
  let panelsOn; //= selectedPanels.map((panels) => panels.reduce((a, b) => a + b, 0));

  if (selectedPanels && selectedPanels.length > 0) {
    panelsOn = selectedPanels.map((panels) => panels.reduce((a, b) => a + b, 0));
  }

  // const solarProduction = design
  //   .map(({ acAnnual, annualAccess }, idx) => panelsOn[idx] * panelkW * annualAccess * acAnnual)
  //   .reduce((a, b) => a + b, 0);

  // const monthlyProduction = Array(12).fill()
  //   .map((_, idx) => (
  //     design.map(({ acMonthly, solarAccess }, jdx) => (
  //       panelsOn[jdx] * panelkW * solarAccess[idx] * acMonthly[idx]
  //     )).reduce((a, b) => a + b, 0)
  //   ));

  let solarProduction = 0;
  let monthlyProduction = 0;
  if (proposalWithShadings) {
    const lstPanelShadings = design?.map((d, di) => panelShadings.filter(([ri]) => ri === di));
    // console.log('----->', lstPanelShadings);
    solarProduction = design
      .map(({ acAnnual }, idx) => selectedPanels[idx].map((s, si) => {
        if (lstPanelShadings[idx][si]) return s * panelkW * lstPanelShadings[idx][si][6] * acAnnual;
        return 0;
      }).reduce((a, b) => a + b, 0))
      .reduce((a, b) => a + b, 0);

    monthlyProduction = Array(12).fill().map((_, idx) => (
      design.map(({ acMonthly }, jdx) => selectedPanels[jdx].map((s, si) => {
        if (lstPanelShadings[jdx].length > 0) {
          if (lstPanelShadings[jdx][si] && lstPanelShadings[jdx][si][6]) return s * panelkW * lstPanelShadings[jdx][si][6] * acMonthly[idx];
          return 0;
        }
        return 0;
      }).reduce((a, b) => a + b, 0))
        .reduce((a, b) => a + b, 0)));
    // console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^', monthlyProduction);
    // console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^', solarProduction);
  } else {
    solarProduction = design
      .map(({ acAnnual, annualAccess }, idx) => panelsOn[idx] * panelkW * annualAccess * acAnnual)
      .reduce((a, b) => a + b, 0);

    monthlyProduction = Array(12).fill()
      .map((_, idx) => (
        design.map(({ acMonthly, solarAccess }, jdx) => (
          panelsOn[jdx] * panelkW * solarAccess[idx] * acMonthly[idx]
        )).reduce((a, b) => a + b, 0)
      ));
  }

  // let solarProduction = 0;
  // if (panelShadings.length) {

  /* const lstPanelShadings = design.map((d, di) => panelShadings.filter(([ri]) => ri === di));
    const solarProduction = design
      .map(({ acAnnual }, idx) => selectedPanels[idx].map((s, si) => {
        if (lstPanelShadings[idx][si]) return s * panelkW * lstPanelShadings[idx][si][6] * acAnnual;
        return 0;
      }).reduce((a, b) => a + b, 0))
      .reduce((a, b) => a + b, 0); */

  // } else {
  //   solarProduction = design
  //     .map(({ acAnnual, annualAccess }, idx) => panelsOn[idx] * panelkW * annualAccess * acAnnual)
  //     .reduce((a, b) => a + b, 0);
  // }

  const offset = Math.floor((solarProduction / annualUsage) * 100);
  const totalPanels = panelsOn.reduce((a, b) => a + b, 0);
  const systemSize = totalPanels * panelkW;

  const degradedProductions = Array(30)
    .fill(solarProduction)
    .map((value, index) => {
      if (index === 0) {
        // first year
        return value * (1 - firstYearDegradation)
      }
      return value * (1 - degradation) ** index;
    });

  // calculate yearly savings throughout panel lifetime
  // accounting for panel degradation...
  let yearlySavings = degradedProductions
    .map((value) => {
      if (effectiveRate !== undefined && averageBill !== undefined) {
        // if offset_saving -> (extra > 100% * export rate) - (12 * fixed solar charge)
        if (utilityRate?.offset_savings && offset > 100) {
          let over_100_production = ((offset - 100) / 100) * value;
          return Math.min((value - over_100_production) * effectiveRate + over_100_production * utility?.export_rate, (averageBill - Number(utilityRate?.ufsc)) * 12);
        }
        return Math.min(value * effectiveRate, (averageBill - Number(fixedCharge)) * 12);
      }
      if (effectiveRate !== undefined) {
        return value * effectiveRate;
      }
      // in case openei/utility information is not complete (like in iframe leads) uses a fixed_rate of 0.3
      return value * 0.3;
    });
  // ...and utility escalation
  yearlySavings = calculateEscalation(yearlySavings, escalation);

  // update discount cap since it depends on systemSize
  const discountCap = +discount * systemSize * 1000;

  const numTotalPanels = design.map(({ panels }, idx) => panels.length).reduce((a, b) => a + b, 0);

  const data = {
    offset,
    totalPanels,
    systemSize,
    solarProduction,
    monthlyProduction,
    yearlySavings,
    discountCap,
    numTotalPanels,
  };

  return data;
};

export const calculateCosts = async (
  ppt, leadAddress, currentUser, hardware, proposalSavedPackage, iframe, totalPanels, systemSize, yearlySavings,
  permitCost, discountAmount, loan, loanSwitch,
  panel, inverter, mounting, monitoring, adders, roofType,
) => {
  // console.log('\n....................................Calculating Costs....................................\n');
  const statePackage = hardware.packages.filter((item, index) => item.state === leadAddress.state)[0];
  const defaultPackage = hardware.packages.filter((item, index) => item.state === null)[0];
  const selectedPackage = statePackage !== undefined ? statePackage : defaultPackage;
  let packages = selectedPackage;

  if (proposalSavedPackage) {
    console.log(`Using saved package for proposal to calculate costs: ${JSON.stringify(proposalSavedPackage)}`);
    packages = proposalSavedPackage;
  }

  // console.log(panel, inverter, mounting, monitoring, adders, roofType);

  const inverterReq = inverter.type === 'C' ? systemSize : totalPanels;
  const totalInverters = Math.ceil(systemSize * 1000 / inverter.capacity);

  // add up flat costs and costs per watt for equipment
  let flatCosts = Number(inverter.cost) * totalInverters;
  let costPerWatt = Number(panel.cost);

  if (mounting.flat_cost) flatCosts += Number(mounting.cost);
  else costPerWatt += Number(mounting.cost);

  flatCosts += Number(monitoring.cost || 0);

  // get adders and update total cost that can be rebated and those that cannot
  let [adderCost, adderNRCost, hiddenAdderCost, visibleAdderCost] = [0, 0, 0, 0];
  Object.entries(adders).forEach(([id, {
    cost, flat_cost: flat, count, dq, hidden,
  }]) => {
    const total = (flat ? cost : cost * systemSize * 1000) * count;
    adders[id].totalCost = parseFloat(total);
    if (dq) adderNRCost += total;
    else adderCost += total;
    if (hidden) hiddenAdderCost += total;
    else visibleAdderCost += total;
  });
  console.log(adders, adderCost, adderNRCost, visibleAdderCost, hiddenAdderCost);

  // add cost per watt associated with roof type, installation package, and comissions
  costPerWatt += roofType ? Number(roofType.cost) : 0;
  if (packages && packages?.tiered_costs && packages.tiered_costs.length > 0) {
    let tier = null;
    packages.tiered_costs.filter((el) => el.name !== 'Tier-default').sort((a, b) => Number(b.threshold) - Number(a.threshold)).map((tc, idx) => {
      if (Number(systemSize) < Number(tc?.threshold) && tc.name !== 'Tier-default') {
        tier = tc
      }
    })
    if (tier === null) tier = packages.tiered_costs.filter((el) => el.name === 'Tier-default')[0]
    console.log(`tier:${tier}`);
    costPerWatt += Number(tier?.cost);
  } else {
    costPerWatt += packages ? Number(packages.cost) : 0;
  }

  costPerWatt += !iframe && currentUser ? Number(currentUser.commission) : 0;

  // for total cost system size needs to be in watts
  console.log(systemSize, costPerWatt, flatCosts);
  const systemCost = (costPerWatt * systemSize * 1000) + flatCosts;
  const preRebateCost = systemCost + permitCost + adderCost;

  // calculate credits and rebates based on preRebateCost
  const credits = hardware.credits
    .filter(({ state }) => state === null || state.toLowerCase() === ppt.state.toLowerCase())
    .map((item) => {
      let amount = Number(item.amount);
      if (item.amount_type === 'W') amount *= systemSize * 1000;
      else if (item.amount_type === 'P') amount *= (preRebateCost - discountAmount) / 100; // divide 100 to convert % to decimal
      if (item.cap) {
        let capAmount = +item.cap;
        if (item.cap_type === 'W') capAmount *= systemSize * 1000;
        else if (item.cap_type === 'P') capAmount *= (preRebateCost - discountAmount) / 100; // divide 100 to convert % to decimal
        amount = Math.min(amount, capAmount);
      }
      return { ...item, appliedAmount: amount };
    });

  const rebateAmount = credits.map(({ appliedAmount }) => appliedAmount).reduce((a, b) => a + b, 0);

  // calculate total cost
  const totalCost = loanSwitch ? ((preRebateCost - rebateAmount + adderNRCost) * (1.00 + (parseFloat(loan.fees) / 100.00))) - discountAmount : (preRebateCost - rebateAmount + adderNRCost) - discountAmount;
  console.log(preRebateCost, rebateAmount, adderNRCost);

  // lifetime savings for 25 year savings chart, also used to calculate payback period
  const lifetimeSavings = yearlySavings.map((value) => value - totalCost);

  let paybackYear = lifetimeSavings
    .indexOf(Math.max(...lifetimeSavings.filter((n) => n < 0))) + 1;

  // TODO fix me, using yearly savings of first year bc yearlySavings is cumulative
  // using first year yearlySavings works but is not accurate
  let paybackMonth = 0;
  if (paybackYear < 25) {
    const remainingCost = Math.abs(paybackYear ? lifetimeSavings[paybackYear - 1] : totalCost);
    const monthlySavings = yearlySavings[0] / 12;
    paybackMonth = Math.floor(remainingCost / monthlySavings);
    if (paybackMonth >= 12) {
      paybackMonth = 0;
      paybackYear += 1;
    }
  }

  const data = {
    // totalInverters,
    lifetimeSavings,
    paybackYear,
    paybackMonth,
    systemCost,
    preRebateCost,
    adderCost,
    adderNRCost,
    hiddenAdderCost,
    visibleAdderCost,
    discountSystemCost: Number(preRebateCost) + Number(adderNRCost),
    totalCost,
    rebateAmount,
    credits,
    adders,
    totalInverters,
  };

  return data;
};

export const createProposalData = (uid, initData, panels, _package, additionalContext = {}, desCompanyPanelId, desCompanyInverterId, designFrame, losses) => {
  const {
    segments, obstacles, trees,
  } = initData;

  const {
    panel, inverter, monitoring, mounting,
  } = _package;

  additionalContext = filterDictByKeys(additionalContext, ['panelType', 'inverter', 'defaultBtnView', 'companyPanelID', 'companyInverterID', 'setback']);
  if (additionalContext && 'panelType' in additionalContext && !designFrame) additionalContext.panel = additionalContext.panelType;

  let segmentsForSort = [...segments];
  segmentsForSort = segmentsForSort.filter((seg) => !isNaN(seg.azimuth));
  const sortedPanels = segmentsForSort.sort((a, b) => b.tsrf[12] - a.tsrf[12]).map((seg) => panels.find((pnl) => seg.id === pnl.id));
  const designData = {
    segments: segmentsForSort,
    obstacles,
    trees,
    panels: sortedPanels,
    panel,
    inverter,
    monitoring,
    mounting,
    losses,
    ...additionalContext,
  };

  const designs = sortedPanels.map(({
    id, azimuth, horizontal, vertical,
  }) => {
    const segment = segments.find((seg) => seg.id === id);
    const {
      acMonthly, poaMonthly, solarAccess, tsrf, tof,
    } = segment;
    const panelOrientation = Number(segment.panelOrientation).toFixed(13);

    let validAzimuth = Number(azimuth);
    if (validAzimuth < 0) validAzimuth += 360;
    if (validAzimuth >= 360) validAzimuth -= 360;

    return {
      azimuth: Number(validAzimuth).toFixed(2),
      panel_orientation: panelOrientation,
      horizontal: horizontal.points,
      horizontal_size: [Math.round(horizontal.height), Math.round(horizontal.width)].join(),
      vertical: vertical.points,
      vertical_size: [Math.round(vertical.height), Math.round(vertical.width)].join(),
      ac_monthly: acMonthly.map((item) => Number(item).toFixed(2)),
      solar_access: solarAccess.map((item) => Number(item).toFixed(2)),
      tsrf: tsrf.map((item) => Number(item).toFixed(2)),
      tof: tof.map((item) => Number(item).toFixed(2)),
    };
  });

  const { companyPanelID, companyInverterID } = additionalContext ?? { companyPanelID: null, companyInverterID: null };
  if (companyPanelID) {
    console.log('proposal data in proposal iframe');
  }
  if (desCompanyPanelId) {
    console.log('proposal data in designer iframe');
  }

  Object.filter = (obj, predicate) =>
    Object.fromEntries(Object.entries(obj).filter(predicate));

  function isEmpty(obj) {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }

    return true;
  }

  const paramCompanyPanelID = companyPanelID ? companyPanelID : desCompanyPanelId;
  const paramCompanyInverterID = companyInverterID ? companyInverterID : desCompanyInverterId;

  if (!isEmpty(additionalContext)) {
    additionalContext['companyPanelID'] = paramCompanyPanelID;
    additionalContext['companyInverterID'] = paramCompanyInverterID;
    additionalContext['panel'] = paramCompanyPanelID ? null : panel;
    additionalContext['inverter'] = paramCompanyInverterID ? null : inverter;
  }

  const data = {
    lead: uid,
    panel: paramCompanyPanelID ? null : panel.id,
    company_panel: paramCompanyPanelID,
    inverter: paramCompanyInverterID ? null : inverter.id,
    company_inverter: paramCompanyInverterID,
    monitoring: monitoring ? monitoring.id : null,
    mounting: mounting ? mounting.id : null,
    designs,
    design_data: designData,
    input_log: !isEmpty(additionalContext) ? Object.filter(additionalContext, ([name, data]) => (name != 'panelType' && name != 'defaultBtnView')) : {},
  };

  return data;
};

export const getProposalState = (lead, data, hardware, loan = null) => {
  // get escalation and bill
  const escalation = +data.escalation / 100;

  // calculate annual usage
  const annualUsage = lead.utility.usage.reduce((a, b) => a + b, 0);

  // set selected hardware from proposal
  let {
    design_data: {
      panel, inverter, monitoring, mounting, adders: proposal_adders
    },
    company_panel: companyPanel,
    company_inverter: companyInverter,
  } = data;

  if (!panel) panel = data.panel;
  if (!inverter) inverter = data.inverter;
  if (!monitoring) monitoring = data.monitoring;
  if (!mounting) mounting = data.mounting;

  if (companyPanel) {
    panel = companyPanel;
  }

  if (companyInverter) {
    inverter = companyInverter;
  }

  // create object containing adder Ids as key
  const adders = {};
  hardware.adders.forEach(({
    id, cost, rebate_dq: dq, ...adder
  }) => {
    // if adder is in proposal data, use count
    let count = data?.adders.find((a) => a.name === adder.name)?.count;
    if (proposal_adders) count = proposal_adders.find((a) => a.name === adder.name)?.count;
    adders[id] = {
      ...adder, count: count ?? 0, cost: +cost, totalCost: 0, dq,
    };
  });

  // get panel design and sort w.r.t. solar production
  const design = data.designs.sort((a, b) => b.tsrf[12] - a.tsrf[12]).map(({ id, ...roof }, idx) => {
    let [height, width] = roof.vertical_size.split(',').map(Number);
    const vSize = { height, width };
    [height, width] = roof.horizontal_size.split(',').map(Number);
    const hSize = { height, width };

    const acMonthly = roof.ac_monthly.map((a) => Number(a));
    const acAnnual = acMonthly.reduce((a, b) => a + b, 0);

    const azimuth = +roof.azimuth;
    const pitch = +data.design_data.segments[idx].pitch;
    const panelOrientation = +roof.panel_orientation;
    const solarAccess = roof.solar_access.filter((el, idx) => idx < 12);
    const TSRF = roof.tsrf;
    const TOF = roof.tof;
    const annualAccess = solarAccess.reduce((a, b) => a + b, 0) / solarAccess.length
    const annualTSRF = roof.tsrf[12];
    const annualTOF = roof.tof[12];

    // panels list
    const vertical = roof.vertical?.length ?? 0;
    const horizontal = roof.horizontal?.length ?? 0;
    const panels = [
      ...(roof?.vertical ?? []).map(([x, y]) => ({ x, y, ...vSize })),
      ...(roof?.horizontal ?? []).map(([x, y]) => ({ x, y, ...hSize })),
    ];
    return {
      id, acMonthly, acAnnual, horizontal, vertical, panels, azimuth, pitch, panelOrientation, solarAccess, annualAccess, annualTOF, annualTSRF, TSRF, TOF,
    };
  });

  // set selectedPanels state from proposal
  const selectedPanels = data.designs.map((roof) => {
    if (roof.horizontal_selected || roof.vertical_selected) {
      return [
        ...roof.vertical_selected.split(',').map(Number),
        ...roof.horizontal_selected.split(',').map(Number),
      ];
    }
    const total = (roof.vertical?.length ?? 0) + (roof.horizontal?.length ?? 0);
    return Array(total).fill(0);
  });

  const overlaySrc = data.production;
  const proposalShadings = data.shading_images;
  // flag indicating if proposal is going to account for per panel shading
  const proposalWithShadings = design[0].annualAccess !== 1;
  // alert(`${design[0].annualAccess}, ${proposalWithShadings}`);

  // get permitCost based on property address
  const { city, state, roof_type: roof } = lead.property;
  const roofType = hardware?.roof_types
    .find((item) => item.id === roof);
  const permit = hardware?.permits
    .find((item) => item?.city.toLowerCase() === city.toLowerCase() || item?.state === state);
  const permitCost = permit ? +permit?.cost : 0;

  loan = data.loan ? { ...loan, loaner: data.loan } : loan;
  // const loanSwitch = !!loan;
  return {
    designData: data.design_data,
    discountAmount: data.design_data?.summary?.discount_amount ? data.design_data?.summary?.discount_amount : 0,
    panel,
    inverter,
    companyPanel,
    companyInverter,
    monitoring,
    mounting,
    adders,
    roofType,
    escalation,
    // monthlyBill,
    annualUsage,
    design,
    selectedPanels,
    overlaySrc,
    proposalShadings,
    proposalWithShadings,
    permitCost,
    loan,
    annotations: data?.annotations
    // loanSwitch,
  };
};

// calculate effective utility rate based on selected rate and usage, and average utility bill
export const calculateBill = (utility, daily) => {
  const [weekdays, weekends] = daysOfWeek();
  let monthlyUsage = utility.usage.map(Number);
  if (daily) monthlyUsage = monthlyUsage.map((kwh, mo) => kwh * days[mo]);

  const fixedCharge = +utility.fixedCharge;
  const totalUsage = Math.round(monthlyUsage.reduce((a, b) => a + b, 0));
  let totalUsageCharge = 0;
  if (!totalUsage || fixedCharge === undefined) return utility;

  // is using a custom rate, just to the calculation based on average monthly usage
  if (utility.rate === 'custom') {
    totalUsageCharge = totalUsage * utility.effectiveRate;
    const averageBill = Math.round((totalUsageCharge / 12 + fixedCharge) * 100) / 100;
    return { ...utility, totalUsage, averageBill };
  }
  if (!utility.rateStructure) return utility;

  /* Calculation assumptions
  * Non-TOU, tiered utility rates (those with a 'max' field) use the same rate over a given month
  * Rate structures with > 2 periods are assumed to be TOU rates)
  * TOU rates with tiers are assumed to always have max calculated daily (non-TOU are max/month)
  * Hourly usage/day is constant for a given month (unless it's TOU where we use PeakUsage)
  * "Peak" hours are considered to be from 8am - 9pm (index 8-21)
  * TODO: make peak hours configureable
  */

  if (utility.isTOU) {
    days.forEach((nDays, mo) => {
      // TODO: need to characterize hourly usage for a given month for TOU rates
      let peakUsage = utility.peak_usage[mo];
      if (daily) peakUsage *= nDays;

      const hourlyPeakUsage = peakUsage / (24 * nDays);
      const hourlyOffPeakUsage = (monthlyUsage[mo] - peakUsage) / (24 * nDays);

      // first calculate weekday charges
      const weekdayRates = utility.weekdaySchedule[mo]; // weekday rates for current month
      let dailyUsage = 0;
      weekdayRates.forEach((period, hour) => {
        const hourlyUsage = (hour >= 8 || hour <= 21) ? hourlyPeakUsage : hourlyOffPeakUsage;
        // tiers are sorted from highest to lowest max usage
        const tiers = utility.rateStructure[period];
        try {
          const { rate, adj } = tiers.find(({ max }) => dailyUsage > (max || 0));
          const hourlyRate = (rate + (adj || 0));

          totalUsageCharge += hourlyRate + hourlyUsage * weekdays[mo];
          dailyUsage += hourlyUsage;
        } catch (err) {
          console.error(`TOU: tiers with no dailyUsage (proposal) ${err}`);
          let avgRate = 0;
          tiers.forEach((t, i) => {
            avgRate += t.rate;
          })
          avgRate = avgRate / tiers.length;
          totalUsageCharge += avgRate + hourlyUsage * weekdays[mo];
          dailyUsage += hourlyUsage;
        }

        // const { rate, adj } = tiers.find(({ max }) => dailyUsage > (max || 0));
        // const hourlyRate = (rate + (adj || 0));

        // totalUsageCharge += hourlyRate + hourlyUsage * weekdays[mo];
        // dailyUsage += hourlyUsage;
        // TODO: take into account minimum charge (units can be $/day. $/month, $/year)
      });

      const weekendRates = utility.weekendSchedule;
      weekendRates.forEach((period, hour) => {
        const hourlyUsage = (hour >= 8 || hour <= 21) ? hourlyPeakUsage : hourlyOffPeakUsage;
        // tiers are sorted from highest to lowest max usage
        const tiers = utility.rateStructure[period];
        try {
          const { rate, adj } = tiers.find(({ max }) => dailyUsage > (max || 0));
          const hourlyRate = (rate + (adj || 0));

          totalUsageCharge += hourlyRate + hourlyUsage * weekends[mo];
          dailyUsage += hourlyUsage;
        } catch (err) {
          console.error(`TOU: No weekendRates (proposal) ${err}`);
        }

        // const { rate, adj } = tiers.find(({ max }) => dailyUsage > (max || 0));
        // const hourlyRate = (rate + (adj || 0));

        // totalUsageCharge += hourlyRate + hourlyUsage * weekends[mo];
        // dailyUsage += hourlyUsage;
        // TODO: take into account minimum charge (units can be $/day. $/month, $/year)
      });
    });
    // TODO: take into account minimum charge
  } else { // non-TOU rate
    monthlyUsage.forEach((monthUsage, mo) => {
      const period = utility.weekdaySchedule[mo][0];
      const tiers = utility.rateStructure[period];

      // tiers are sorted from highest to lowest max usage
      tiers.forEach(({ rate, max, adj }) => {
        const tierUsage = monthUsage - (max || 0);
        if (tierUsage > 0) {
          totalUsageCharge += tierUsage * (rate + (adj || 0));
          monthUsage -= tierUsage;
        }
        // TODO: take into account minimum charge (units can be $/day. $/month, $/year)
      });
    });
  }

  // fixed charge portion of the bill
  let fixed = fixedCharge;
  if (utility.fixedChargeUnits === '$/day') fixed *= days.reduce((a, b) => a + b, 0);
  else if (utility.fixedChargeUnits === '$/month') fixed *= 12;

  const averageBill = Math.round(((fixed + totalUsageCharge) / 12) * 100) / 100;
  const effectiveRate = Math.round((totalUsageCharge / totalUsage) * 1e6) / 1e6;
  return {
    ...utility, averageBill, effectiveRate, totalUsage,
  };
};

// calculate usage from averageBill (only available with custom rate)
export const calculateUsage = (utility, daily, peak) => {
  if (utility.rate !== 'custom') return utility;

  const effectiveRate = +utility.effectiveRate;
  const fixedCharge = +utility.fixedCharge;
  const averageBill = +utility.averageBill;
  const monthlyUsage = (averageBill - fixedCharge) / effectiveRate;
  const totalUsage = Math.round(monthlyUsage * 12);
  const usage = Array(12)
    .fill(monthlyUsage)
    .map((kwh, i) => +(daily ? (kwh / days[i]).toFixed(2) : kwh.toFixed(0)));
  const peak_usage = usage.map((kwh) => +(kwh * peak).toFixed(daily ? 2 : 0));
  return {
    ...utility, totalUsage, usage, peak_usage, averageBill, effectiveRate, fixedCharge,
  };
};

export const getUtilityRate = async (property, utility, rate) => {
  if (!rate || rate === 'custom') return [utility, null];

  const openEIPublicURL = process.env.REACT_APP_OPENEI_PUBLIC_URL;
  const url = `${openEIPublicURL}/utility_rates?version=latest`;
  const query = `&format=json&getpage=${rate}&detail=full`;

  if (utility.rate !== 'custom') {
    if (typeof property.utility_rate === 'undefined' || property.utility_rate === null) {
      return await axios.get(url + query).then((res) => {
        if (res.status === 200) {
          const utilityRate = res.data.items[0];
          const rateStructure = utilityRate.energyratestructure;
          rateStructure.forEach((period) => period.sort((a, b) => (b.max || 0) - (a.max || 0)));
          // const isTiered = rateStructure.some((rates) => rates.length > 1);
          const isTOU = rateStructure.length > 2;

          // updateUtilityRate(utilityRate);
          // setUtilityProviderRate(utilityRate);

          let fixedCharge = utilityRate.fixedchargefirstmeter || 0;
          if (fixedCharge === 0 && utilityRate?.minchargeunits == "$/day") {
            fixedCharge = utilityRate.mincharge * 365 / 12;
            fixedCharge = fixedCharge.toFixed(2);
          }
          return [{
            ...utility,
            rate: utilityRate.label,
            rateName: utilityRate.name,
            provider: utilityRate.utility,
            fixedCharge: fixedCharge,
            fixedChargeUnits: utilityRate.fixedchargeunits,
            weekendSchedule: utilityRate.energyweekendschedule,
            weekdaySchedule: utilityRate.energyweekdayschedule,
            rateStructure,
            // isTiered,
            isTOU,
          }, utilityRate];
        }
      }).catch(() => {
        throw new Error({ variant: 'danger', message: 'Failed to retrieve utility rate information from OpenEI' });
      });
    } else {
      const rateStructure = property.utility_rate.energyratestructure;
      rateStructure.forEach((period) => period.sort((a, b) => (b.max || 0) - (a.max || 0)));
      const isTOU = rateStructure.length > 2;
      return [{
        ...utility,
        rate: property.utility_rate.label,
        rateName: property.utility_rate.name,
        provider: property.utility_rate.utility,
        fixedCharge: property.utility_rate.fixedchargefirstmeter || 0,
        fixedChargeUnits: property.utility_rate.fixedchargeunits,
        weekendSchedule: property.utility_rate.energyweekendschedule,
        weekdaySchedule: property.utility_rate.energyweekdayschedule,
        rateStructure,
        // isTiered,
        isTOU,
      }, null];
    }
  }
  return [utility, null];
};

export const getRates = async (property, utility) => {
  const timeNow = Math.round(new Date().getTime() / 1000);
  const [lat, lon] = property.coordinates;
  const openEIPublicURL = process.env.REACT_APP_OPENEI_PUBLIC_URL;
  const url = `${openEIPublicURL}/utility_rates?version=latest&format=json&sector=Residential`;
  const query = `&effective_on_date=${timeNow}&lat=${lat}&lon=${lon}`;
  // if (country === 'Canada') query += '&country=CAN';

  if (utility.rate !== 'custom') {
    if (typeof property.utility_providers === 'undefined' || property.utility_providers === null) {
      const [providers, selectedRate] = await axios.get(url + query)
        .then((res) => {
          if (res.status === 200) {
            const provs = res.data.items
              .filter(({ servicetype }) => serviceTypes.includes(servicetype))
              .map(({
                label: rate, utility: provider, name, is_default,
              }) => ({
                rate, provider, name, is_default,
              }));
            // if (provs.length === 0) changeProvider('Custom');
            // else setProviders(provs);
            const defaultRate = utility.rate ? utility : provs.find((item) => item.is_default);
            // updateUtilityProviders(provs);
            // setUtilityProviders(provs);
            return [provs, defaultRate?.rate];
          }
          return null;
        })
        .catch(() => {
          throw new Error({ variant: 'danger', message: 'Failed to retrieve utility provider options from OpenEI' });
        });
      const [updatedUtility, rate] = await getUtilityRate(property, utility, selectedRate);
      return [providers, updatedUtility, rate];
    }

    const defaultRate = utility.rate ? utility : property.utility_providers.find((item) => item.is_default);
    const selectedRate = defaultRate?.rate;
    const [updatedUtility, rate] = await getUtilityRate(property, utility, selectedRate);
    return [null, updatedUtility, rate];
  }
  // in case utility.rate is 'custom'
  return [null, utility, null]
};

export const setAverageUsage = (utility, averageUsage, daily, peak) => {
  const usage = Array(12).fill(+averageUsage);
  const averagePeak = (+averageUsage * peak).toFixed(daily ? 2 : 0);
  const peak_usage = Array(12).fill(+averagePeak);
  const totalUsage = Math.round(averageUsage * 12);
  return {
    ...utility, usage, peak_usage, totalUsage,
  };
};

export const setUsage = (utility, daily, peak, value, index) => {
  const { usage, peak_usage } = utility;
  usage.splice(index, 1, value);
  const peakValue = (+value * peak).toFixed(daily ? 2 : 0);
  peak_usage.splice(index, 1, +peakValue);
  return { ...utility, usage, peak_usage };
};

export const setPeakUsage = (utility, value, index) => {
  const { peak_usage } = utility;
  peak_usage.splice(index, 1, value);
  return { ...utility, peak_usage };
};

export const toggleDaily = (utility, peak, daily) => {
  const usage = utility.usage.map((kwh, i) => (
    !daily ? Math.round((kwh / days[i]) * 100) / 100 : Math.round(kwh * days[i])
  ));
  const peak_usage = usage.map((kwh) => (
    !daily ? Math.round(kwh * peak * 100) / 100 : Math.round(kwh * peak)
  ));

  return [{ ...utility, usage, peak_usage }, !daily];
};

export const normalisePeak = (utility, peak, daily) => {
  let norm = +peak;
  if (peak < 0) norm = 0;
  if (peak > 1) norm = 1;
  const { usage } = utility;
  const peak_usage = usage.map((kwh) => (
    daily ? Math.round(kwh * peak * 100) / 100 : Math.round(kwh * peak)
  ));
  return [norm, { ...utility, peak_usage }];
};
