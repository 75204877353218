import React, {
  useState, useContext, useEffect, useRef, useMemo, useCallback,
} from 'react';
import { Vector3, Frustum } from 'three';
import * as THREE from 'three';
import { Canvas, useFrame, useThree } from '@react-three/fiber';

import { DesignContext } from '../contexts/designContext.js';
import { SelectionHelper } from './SelectionHelper.js';

const frustum = new Frustum();

const tmpPoint = new Vector3();

const vecNear = new Vector3();
const vecTopLeft = new Vector3();
const vecTopRight = new Vector3();
const vecDownRight = new Vector3();
const vecDownLeft = new Vector3();

const vecFarTopLeft = new Vector3();
const vecFarTopRight = new Vector3();
const vecFarDownRight = new Vector3();
const vecFarDownLeft = new Vector3();

const vectemp1 = new Vector3();
const vectemp2 = new Vector3();
const vectemp3 = new Vector3();

class SelectionBox {
  constructor(camera, scene, vertices, panels, deep = Number.MAX_VALUE) {
    this.camera = camera;
    this.scene = scene;
    this.startPoint = new Vector3();
    this.endPoint = new Vector3();
    this.collection = {
      roofSegments: [], roofVertices: [], roofLines: [], obstacles: [], fireSetbacks: [], panels: [], trees: [],
    };
    this.deep = deep;
    this.vertices = vertices;
    this.panels = panels;
    this.segmentSelection = false;
    this.pointSelection = false;
    this.lineSelection = false;
    this.panelSelection = false;
    this.setbackSelection = false;
    this.readonly = false;
    this.panelVisibility = false;
  }

  select(startPoint, endPoint) {
    this.startPoint = startPoint || this.startPoint;
    this.endPoint = endPoint || this.endPoint;
    this.collection = {
      roofSegments: [], roofVertices: [], roofLines: [], obstacles: [], fireSetbacks: [], panels: [], trees: [],
    };

    this.updateFrustum(this.startPoint, this.endPoint);
    this.searchChildInFrustum(frustum, this.scene);

    return this.collection;
  }

  updateFrustum(startPoint, endPoint) {
    startPoint = startPoint || this.startPoint;
    endPoint = endPoint || this.endPoint;

    // Avoid invalid frustum

    if (startPoint.x === endPoint.x) {
      endPoint.x += Number.EPSILON;
    }

    if (startPoint.y === endPoint.y) {
      endPoint.y += Number.EPSILON;
    }

    this.camera.updateProjectionMatrix();
    this.camera.updateMatrixWorld();

    if (this.camera.isPerspectiveCamera) {
      tmpPoint.copy(startPoint);
      tmpPoint.x = Math.min(startPoint.x, endPoint.x);
      tmpPoint.y = Math.max(startPoint.y, endPoint.y);
      endPoint.x = Math.max(startPoint.x, endPoint.x);
      endPoint.y = Math.min(startPoint.y, endPoint.y);

      vecNear.setFromMatrixPosition(this.camera.matrixWorld);
      vecTopLeft.copy(tmpPoint);
      vecTopRight.set(endPoint.x, tmpPoint.y, 0);
      vecDownRight.copy(endPoint);
      vecDownLeft.set(tmpPoint.x, endPoint.y, 0);

      vecTopLeft.unproject(this.camera);
      vecTopRight.unproject(this.camera);
      vecDownRight.unproject(this.camera);
      vecDownLeft.unproject(this.camera);

      vectemp1.copy(vecTopLeft).sub(vecNear);
      vectemp2.copy(vecTopRight).sub(vecNear);
      vectemp3.copy(vecDownRight).sub(vecNear);
      vectemp1.normalize();
      vectemp2.normalize();
      vectemp3.normalize();

      vectemp1.multiplyScalar(this.deep);
      vectemp2.multiplyScalar(this.deep);
      vectemp3.multiplyScalar(this.deep);
      vectemp1.add(vecNear);
      vectemp2.add(vecNear);
      vectemp3.add(vecNear);

      const { planes } = frustum;

      planes[0].setFromCoplanarPoints(vecNear, vecTopLeft, vecTopRight);
      planes[1].setFromCoplanarPoints(vecNear, vecTopRight, vecDownRight);
      planes[2].setFromCoplanarPoints(vecDownRight, vecDownLeft, vecNear);
      planes[3].setFromCoplanarPoints(vecDownLeft, vecTopLeft, vecNear);
      planes[4].setFromCoplanarPoints(vecTopRight, vecDownRight, vecDownLeft);
      planes[5].setFromCoplanarPoints(vectemp3, vectemp2, vectemp1);
      planes[5].normal.multiplyScalar(-1);
    } else if (this.camera.isOrthographicCamera) {
      const left = Math.min(startPoint.x, endPoint.x);
      const top = Math.max(startPoint.y, endPoint.y);
      const right = Math.max(startPoint.x, endPoint.x);
      const down = Math.min(startPoint.y, endPoint.y);

      vecTopLeft.set(left, top, -1);
      vecTopRight.set(right, top, -1);
      vecDownRight.set(right, down, -1);
      vecDownLeft.set(left, down, -1);

      vecFarTopLeft.set(left, top, 1);
      vecFarTopRight.set(right, top, 1);
      vecFarDownRight.set(right, down, 1);
      vecFarDownLeft.set(left, down, 1);

      vecTopLeft.unproject(this.camera);
      vecTopRight.unproject(this.camera);
      vecDownRight.unproject(this.camera);
      vecDownLeft.unproject(this.camera);

      vecFarTopLeft.unproject(this.camera);
      vecFarTopRight.unproject(this.camera);
      vecFarDownRight.unproject(this.camera);
      vecFarDownLeft.unproject(this.camera);

      const { planes } = frustum;

      planes[0].setFromCoplanarPoints(vecTopLeft, vecFarTopLeft, vecFarTopRight);
      planes[1].setFromCoplanarPoints(vecTopRight, vecFarTopRight, vecFarDownRight);
      planes[2].setFromCoplanarPoints(vecFarDownRight, vecFarDownLeft, vecDownLeft);
      planes[3].setFromCoplanarPoints(vecFarDownLeft, vecFarTopLeft, vecTopLeft);
      planes[4].setFromCoplanarPoints(vecTopRight, vecDownRight, vecDownLeft);
      planes[5].setFromCoplanarPoints(vecFarDownRight, vecFarTopRight, vecFarTopLeft);
      planes[5].normal.multiplyScalar(-1);
    }
  }

  searchChildInFrustum(frustumVolume, object) {
    if (this.segmentSelection || this.pointSelection || this.lineSelection || this.setbackSelection) {
      this.vertices.forEach((p, pi) => {
        let selectedCount = 0;
        let firstVertexSelection = false;
        let oldVertexSelection = false;
        p.forEach((v, vi) => {
          const { x, y, z } = v;
          const c = new THREE.Vector3(x, z, y).applyMatrix4(object.matrixWorld);
          if (frustumVolume.containsPoint(c)) {
            selectedCount += 1;
            if (this.pointSelection) this.collection.roofVertices.push(`${pi}-${vi}`);
            if (oldVertexSelection) {
              if (this.lineSelection) this.collection.roofLines.push(`${pi}-${vi - 1}`);
              if (this.setbackSelection) this.collection.fireSetbacks.push(`${pi}-${vi - 1}`);
            }
            if (vi === p.length - 1 && firstVertexSelection) {
              if (this.lineSelection) this.collection.roofLines.push(`${pi}-${vi}`);
              if (this.setbackSelection) this.collection.fireSetbacks.push(`${pi}-${vi}`);
            }
            oldVertexSelection = true;
            if (vi === 0) firstVertexSelection = true;
          } else {
            oldVertexSelection = false;
          }
        });
        if (this.segmentSelection && selectedCount === p.length) this.collection.roofSegments.push(pi);
      });
    }

    if (this.panelSelection || (this.readonly && this.panelVisibility)) {
      this.panels.forEach((p, pi) => {
        p.points3D.forEach((box, pli) => {
          const center = box.map(({ x, y, z }) => new THREE.Vector3(x, z, y).applyMatrix4(object.matrixWorld)).reduce((x, y) => x.clone().add(y.clone())).multiplyScalar(1 / box.length);
          const centerInside = frustumVolume.containsPoint(center);
          const insideCount = box.filter(({ x, y, z }) => {
            const c = new THREE.Vector3(x, z, y).applyMatrix4(object.matrixWorld);
            return frustumVolume.containsPoint(c);
          }).length;
          if (centerInside && insideCount >= box.length / 2) {
            this.collection.panels.push(`${pi}-${pli}`);
          }
        });
      });
    }
  }
}

let currentRoof = null;
const firstPosition = new THREE.Vector3(0, 0, 0);
let currentPosition = new THREE.Vector3(0, 0, 0);
let [startMarker, currentMarker, lineGeometry, line] = [null, null, null, null];
let insertTimeout = 0;
const selectedItems = [];
const raycaster = new THREE.Raycaster();

const ObjectSelection = ({
  controls, canvas, vertices, panels, moveSelection, moveFunction, insertObject, insertFunction, enabled,
}) => {
  const {
    selectedObjects,
    addToSelectedObjects,
    segmentSelection,
    pointSelection,
    lineSelection,
    panelSelection,
    setbackSelection,
    moveStatus,
    readonly,
    panelVisibility,
  } = useContext(DesignContext);

  const {
    gl, camera, scene, size: canvasSize,
  } = useThree();

  const selectionBox = useMemo(() => new SelectionBox(camera, scene, vertices, panels), [camera, scene, vertices, panels]);
  const helper = useMemo(() => new SelectionHelper(selectionBox, gl, 'selectBox'), [gl]);

  useEffect(() => {
    selectionBox.segmentSelection = segmentSelection;
    selectionBox.pointSelection = pointSelection;
    selectionBox.lineSelection = lineSelection;
    selectionBox.panelSelection = panelSelection;
    selectionBox.setbackSelection = setbackSelection;
    selectionBox.readonly = readonly;
    selectionBox.panelVisibility = panelVisibility;
  }, [segmentSelection, pointSelection, lineSelection, panelSelection, setbackSelection, selectionBox, readonly, panelVisibility]);

  const searchSelectedItem = (parent, childname, first = true) => {
    if (!parent.children) return;
    let objects = [];
    switch (childname) {
      case 'panel':
        objects = selectedObjects.panels;
        break;
      default: break;
    }
    if (first) selectedItems.splice(0, selectedItems.length);
    parent.children.forEach((ch) => {
      if (ch.name === childname) {
        if (parent.index in objects && objects[parent.index].includes(ch.index)) selectedItems.push(ch);
      }
      searchSelectedItem(ch, childname, false);
    });
  };

  const initMoveObjects = () => {
    selectedItems.forEach((ch) => {
      ch.userData = ch.matrix.clone();
    });
  };

  const moveObjects = (parent, childname, startPosition, endPosition) => {
    const offset = endPosition.clone().sub(startPosition);
    const lmx = new THREE.Matrix4().makeTranslation(offset.x, offset.z, -offset.y);
    const rmx = new THREE.Matrix4().makeTranslation(0, 0, -0.21);
    selectedItems.forEach((ch) => {
      const oldMatrix = ch.userData.clone();
      const newMatrix = (oldMatrix.multiply(rmx)).premultiply(lmx);
      ch.matrix.identity();
      ch.applyMatrix4(newMatrix);
    });
  };

  const canvasMouseDown = useCallback((e) => {
    const orbit = (!e.altKey && !moveSelection && !insertObject) || e.button !== 0 || !enabled;
    if (orbit) {
      controls.current.setOrbitEnabled(orbit);
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    if ((!moveSelection || e.altKey) && !insertObject) { // start for selection
      controls.current.setOrbitEnabled(orbit);
      selectionBox.startPoint.set(
        (e.offsetX / canvasSize.width) * 2 - 1,
        -(e.offsetY / canvasSize.height) * 2 + 1,
        0.5,
      );
    } else { // move or insert
      canvas.current.style.cursor = 'move';
      firstPosition.set((e.offsetX / canvasSize.width) * 2 - 1, -(e.offsetY / canvasSize.height) * 2 + 1, 0);
      camera.updateProjectionMatrix();
      camera.updateMatrixWorld();
      raycaster.setFromCamera(firstPosition, camera);
      const intersects = raycaster.intersectObjects(scene.children, true);
      if (startMarker !== null) scene.remove(startMarker);
      if (currentMarker !== null) scene.remove(currentMarker);
      if (line !== null) scene.remove(line);
      let found = false;
      for (let i = 0; i < intersects.length; i += 1) {
        if (moveSelection && moveFunction) {
          if (intersects[i].object.name === 'panelBox' && intersects[i].object.material.name === 'mat_selected_panel') {
            found = true;
            firstPosition.set(intersects[i].point.x, intersects[i].point.y, intersects[i].point.z);
            currentRoof = intersects[i].object.parent.parent;
            searchSelectedItem(currentRoof.parent, 'panel');
            initMoveObjects();
            moveFunction(firstPosition, firstPosition, 1);

            const startMarkerGeometry = new THREE.BufferGeometry();
            startMarkerGeometry.setAttribute('position', new THREE.Float32BufferAttribute([0, 0, 0], 3));
            const startMarkerMaterial = new THREE.PointsMaterial({ color: 'blue', size: 5, sizeAttenuation: false });
            startMarker = new THREE.Points(startMarkerGeometry, startMarkerMaterial);

            const currentMarkerGeometry = new THREE.BufferGeometry();
            currentMarkerGeometry.setAttribute('position', new THREE.Float32BufferAttribute([0, 0, 0], 3));
            const currentMarkerMaterial = new THREE.PointsMaterial({ color: 'red', size: 5, sizeAttenuation: false });
            currentMarker = new THREE.Points(currentMarkerGeometry, currentMarkerMaterial);

            // const startMarkerGeometry = new THREE.SphereGeometry(0.2, 10, 5);
            // const startMarkerMaterial = new THREE.MeshBasicMaterial({ color: 'red' });
            // startMarker = new THREE.Mesh(startMarkerGeometry, startMarkerMaterial);
            // const currentMarkerGeometry = new THREE.SphereGeometry(0.2, 10, 5);
            // const currentMarkerMaterial = new THREE.MeshBasicMaterial({ color: 'green' });
            // currentMarker = new THREE.Mesh(currentMarkerGeometry, currentMarkerMaterial);
            startMarker.position.set(firstPosition.x, firstPosition.y, firstPosition.z);
            lineGeometry = new THREE.BufferGeometry().setFromPoints([firstPosition, firstPosition]);
            line = new THREE.Line(lineGeometry, new THREE.LineBasicMaterial({ color: 'red' }));
            scene.add(currentMarker);
            scene.add(startMarker);
            scene.add(line);
            break;
          }
        } else if (insertFunction) {
          if (intersects[i].object.parent?.name === 'roof' && intersects[i].object.name === 'roofPlane') {
            found = true;
            firstPosition.set(intersects[i].point.x, intersects[i].point.y, intersects[i].point.z);
            canvas.current.style.cursor = 'default';
            insertFunction(intersects[i].object.index, firstPosition);
            break;
          }
        }
      }

      controls.current.setOrbitEnabled(!found && !e.altKey);
      if (!found) {
        currentRoof = null;
      }
    }
  }, [controls, moveSelection, insertObject, selectionBox.startPoint, canvasSize.width, canvasSize.height, canvas, camera, scene, moveFunction, insertFunction, enabled]);

  const canvasMouseUp = useCallback((e) => {
    controls.current.setOrbitEnabled(true);
    if ((!e.altKey && !moveSelection && !insertObject) || e.button !== 0 || !enabled) return;
    e.preventDefault();
    e.stopPropagation();

    canvas.current.style.cursor = 'default';
    if ((!moveSelection || e.altKey) && !insertObject) {
      selectionBox.endPoint.set(
        (e.offsetX / canvasSize.width) * 2 - 1,
        -(e.offsetY / canvasSize.height) * 2 + 1,
        0.5,
      );
      const allSelected = selectionBox.select();
      ['roofSegments', 'roofVertices', 'roofLines', 'obstacles', 'fireSetbacks', 'panels', 'trees'].forEach((objectType) => {
        if (allSelected[objectType]) {
          addToSelectedObjects(allSelected[objectType], objectType, !e.shiftKey);
        }
      });
    } else {
      if (moveSelection && moveFunction && currentRoof != null && moveStatus === 2) {
        moveFunction(firstPosition, currentPosition, 3);
        setTimeout(() => moveFunction(firstPosition, currentPosition, 0), 500);
      }
      if (startMarker !== null) scene.remove(startMarker);
      if (currentMarker !== null) scene.remove(currentMarker);
      if (line !== null) scene.remove(line);
      firstPosition.set(0, 0, 0);
      currentRoof = null;
      startMarker = null;
      currentMarker = null;
    }
  }, [controls, moveSelection, insertObject, selectionBox, canvasSize.width, canvasSize.height, addToSelectedObjects, canvas, moveFunction, scene, enabled]);

  const canvasMouseMove = useCallback((e) => {
    if ((!e.altKey && !moveSelection && !insertObject) || e.button !== 0 || !enabled) return;
    e.preventDefault();
    e.stopPropagation();

    if (!moveSelection && !insertObject) {
      if (helper.isDown) {
        selectionBox.endPoint.set(
          (e.offsetX / canvasSize.width) * 2 - 1,
          -(e.offsetY / canvasSize.height) * 2 + 1,
          0.5,
        );
      }
    } else if ((moveSelection && currentRoof) || insertObject) {
      currentPosition = new THREE.Vector3((e.offsetX / canvasSize.width) * 2 - 1, -(e.offsetY / canvasSize.height) * 2 + 1, 0.5);
      raycaster.setFromCamera(currentPosition, camera);
      if (insertObject) {
        const intersects = raycaster.intersectObjects(scene.children, true);
        for (let i = 0; i < intersects.length; i += 1) {
          if (intersects[i].object.parent?.name === 'roof' && intersects[i].object.name === 'roofPlane') {
            currentPosition.set(intersects[i].point.x, intersects[i].point.y, intersects[i].point.z);
            if (!currentMarker) {
              const currentMarkerGeometry = new THREE.BufferGeometry();
              currentMarkerGeometry.setAttribute('position', new THREE.Float32BufferAttribute([0, 0, 0], 3));
              const currentMarkerMaterial = new THREE.PointsMaterial({ color: 'gold', size: 5, sizeAttenuation: false });
              currentMarker = new THREE.Points(currentMarkerGeometry, currentMarkerMaterial);
              // const currentMarkerGeometry = new THREE.SphereGeometry(0.3, 10, 5);
              // const currentMarkerMaterial = new THREE.MeshBasicMaterial({ color: 'gold' });
              // currentMarker = new THREE.Mesh(currentMarkerGeometry, currentMarkerMaterial);
              scene.add(currentMarker);
              clearTimeout(insertTimeout);
              insertTimeout = setTimeout(() => { scene.remove(currentMarker); currentMarker = null; }, 2000);
            }
            currentMarker.position.set(currentPosition.x, currentPosition.y, currentPosition.z);
            break;
          }
        }
      } else {
        const intersects = raycaster.intersectObjects([currentRoof], true);
        for (let i = 0; i < intersects.length; i += 1) {
          if (intersects[i].object.parent?.name === 'roof' && intersects[i].object.name === 'roofPlane') {
            currentPosition.set(intersects[i].point.x, intersects[i].point.y, intersects[i].point.z);
            if (moveStatus === 2 || currentPosition.clone().sub(firstPosition).length() > 0.5) {
              moveFunction(firstPosition, currentPosition, 2);
              moveObjects(currentRoof.parent, 'panel', firstPosition, currentPosition);
              currentMarker.position.set(currentPosition.x, currentPosition.y, currentPosition.z);
              lineGeometry.setFromPoints([firstPosition, currentPosition]);
              line.geometry.attributes.position.needsUpdate = true;
            }
          }
        }
      }
    }
  }, [moveSelection, insertObject, helper.isDown, selectionBox.endPoint, canvasSize.width, canvasSize.height, camera, moveFunction, enabled]);

  useEffect(() => {
    const canvasRef = canvas.current;
    if (canvasRef) {
      canvas.current.addEventListener('mousedown', canvasMouseDown, false);
      canvas.current.addEventListener('mouseup', canvasMouseUp, false);
      canvas.current.addEventListener('mousemove', canvasMouseMove, false);
    }
    return () => {
      if (canvasRef) {
        canvasRef.removeEventListener('mousedown', canvasMouseDown, false);
        canvasRef.removeEventListener('mouseup', canvasMouseUp, false);
        canvasRef.removeEventListener('mousemove', canvasMouseMove, false);
      }
    };
  }, [canvas, canvasMouseDown, canvasMouseMove, canvasMouseUp]);

  return null;
};

export default ObjectSelection;
