import * as THREE from 'three';
import { fromArrayBuffer } from 'geotiff';

import {
  dist,
  counterClockWise,
  calculateEave,
  getParentsPolygon,
  projectTo3DFromOrgVertices,
  pointInConcavePolygon,
  fitPlaneOnSegment,
  processSegmentsByLevelPriority,
  downloadCanvas,
} from './geometry.js';

import { getBorders } from './3DReconstruction.js';

export const fillPolygon = (context, points, color, penSize = 0, fill = true) => {
  let [minx, miny, maxx, maxy] = [1e5, 1e5, 0, 0];

  if (points.length > 0) {
    if (fill) {
      context.fillStyle = color;
      let point = points[0];
      context.beginPath();
      context.moveTo(point[0], point[1]);
      for (let i = 1; i < points.length; i += 1) {
        point = points[i];
        context.lineTo(point[0], point[1]);
        minx = Math.min(minx, point[0]);
        miny = Math.min(miny, point[1]);
        maxx = Math.max(maxx, point[0]);
        maxy = Math.max(maxy, point[1]);
      }
      context.closePath();
      context.fill();
    }

    if (penSize) {
      context.strokeStyle = color;
      context.lineCap = 'round';
      context.lineWidth = penSize;
      for (let i = 1; i <= points.length; i += 1) {
        const point1 = points[i - 1];
        const point2 = points[i % points.length];
        context.beginPath();
        context.moveTo(point1[0], point1[1]);
        context.lineTo(point2[0], point2[1]);
        context.stroke();
      }
    }
  }

  return [minx, miny, maxx, maxy];
};

export const segmentIndexMask = (canvasPool, state, segs, height, width) => {
  const newCanvas = canvasPool.getCanvas(canvasPool.PoolKeys.SegmentIndexMask);// document.createElement('canvas');
  const context = canvasPool.getContext(canvasPool.PoolKeys.SegmentIndexMask);// newCanvas.getContext('2d');
  // newCanvas.width = width;
  // newCanvas.height = height;
  // context.drawImage(texture.image, 0, 0);
  // const ranges = [];
  context.clearRect(0, 0, newCanvas.width, newCanvas.height);
  processSegmentsByLevelPriority(segs, state.parents, (seg, segIndex) => {
    const clr = segIndex + 1;
    fillPolygon(context, seg.geometry, `rgb(${clr}, ${clr}, ${clr})`);
  });

  // const { orgVertices } = state;
  // const shadedIndices = [];
  // while (shadedIndices.length < segs.length) {
  //   segs.forEach((seg, segIndex) => {
  //     const segParents = getParentsPolygon(orgVertices, segIndex, true);
  //     if (segParents.every((p) => shadedIndices.includes(p))) {
  //       shadedIndices.push(segIndex);
  //       const clr = segIndex + 1;
  //       fillPolygon(context, seg.geometry, `rgb(${clr}, ${clr}, ${clr})`);
  //       // fillPolygon(context, seg.geometry, 'rgb(0,0,0)', 1, false);
  //       // ranges.push(segmentRanges);
  //     }
  //   });
  // }

  // downloadCanvas(newCanvas);
  return newCanvas;
};

export const getDisplacementBias = (context, points, width, height) => {
  if (!points.length) return 0;

  let [minx, miny, maxx, maxy] = [1e5, 1e5, 0, 0];

  for (let i = 0; i < points.length; i += 1) {
    for (let j = 0; j < points[i].length; j += 1) {
      const point = points[i][j];
      minx = Math.min(minx, point[0]);
      miny = Math.min(miny, point[1]);
      maxx = Math.max(maxx, point[0]);
      maxy = Math.max(maxy, point[1]);
    }
  }

  const border = 75;
  const [xl, yb, xr, yt] = [Math.max(minx - border, 0), Math.max(miny - border, 0), Math.min(maxx + border, width), Math.min(maxy + border, height)];
  const [w, h] = [Math.floor(xr - xl), Math.floor(yt - yb)];
  if (w <= 0 || h <= 0) return 0;

  const data = context.getImageData(xl, yb, w, h);
  const means = [0, 60, 120, 180, 240];
  const meansCount = [0, 0, 0, 0, 0];

  for (let cl = 0; cl < 3; cl += 1) {
    const newMeans = [0, 0, 0, 0, 0];
    const clsCount = [0, 0, 0, 0, 0];

    for (let j = 0; j < w; j += 1) {
      for (let i = 0; i < h; i += 1) {
        const index = i * (width * 4) + j * 4;
        const intensity = data.data[index];
        if (intensity) {
          const d = means.map((m) => Math.abs(intensity - m));
          const sortedIndex = Array.from(Array(d.length).keys()).sort((a, b) => (d[a] > d[b] ? 1 : (d[a] === d[b] ? 0 : -1)));
          newMeans[sortedIndex[0]] += intensity;
          clsCount[sortedIndex[0]] += 1;
        }
      }
    }

    for (let i = 0; i < 5; i += 1) {
      if (clsCount[i] !== 0) means[i] = newMeans[i] / clsCount[i];
      meansCount[i] = clsCount[i];
    }
  }

  const t = (w * h) / 15;
  let [m, mc] = [0, 0];
  for (let i = 0; i < 5; i += 1) {
    if (meansCount[i] + mc < t) {
      m = (means[i] * meansCount[i] + m * mc) / (meansCount[i] + mc);
      mc += meansCount[i];
    } else {
      const result = (means[i] * meansCount[i] + m * mc) / (meansCount[i] + mc);
      return result;
    }
  }

  return 0;
};

export const setDisplacementMap = (segments, trees, displacementCanvas, context) => {
  const points = [];
  segments.forEach((seg) => points.push(seg.geometry));
  // const context = displacementCanvas.getContext('2d');
  segments.forEach((seg) => { fillPolygon(context, seg.geometry, 'rgb(0, 0, 0)', 30); });
  const bias = getDisplacementBias(context, points, displacementCanvas.width, displacementCanvas.height);
  const biasColor = `rgb(${bias}, ${bias}, ${bias})`;
  segments.forEach((seg) => { fillPolygon(context, seg.geometry, biasColor, 30); });

  trees.forEach((tree) => {
    if (tree.dsm && tree.dsm === 'Remove') {
      const radius = ((tree.geometry[0][0] - tree.geometry[1][0]) ** 2 + (tree.geometry[0][1] - tree.geometry[1][1]) ** 2) ** 0.5;
      const center = tree.geometry[0];
      context.beginPath();
      context.arc(center[0], center[1], radius, 0, 2 * Math.PI, false);
      context.fillStyle = biasColor;
      context.fill();
      context.lineWidth = 30;
      context.strokeStyle = biasColor;
      context.stroke();
    }
  });

  // downloadCanvas(displacementCanvas, 'editedDisplacementMap');
  return bias;
};

export const getOffset = (raster, rasterHeight, rasterWidth, borders, resolution, scale, center) => {
  if (rasterWidth === 0 || rasterHeight === 0) return [0, 0];

  const range = Math.round((10 * 100) / (resolution / scale));
  const best = { score: 0, ox: 0, oy: 0 };
  for (let ox = -range; ox <= range; ox += 1) {
    for (let oy = -range; oy <= range; oy += 1) {
      const scores = borders.map(([,,, pntsPos, pntsNeg]) => {
        const heights = [pntsPos, pntsNeg].map((pnts) => pnts.map((vp) => {
          let [i, j] = [Math.round((vp.x / scale) + center[0]), Math.round((vp.y / scale) + center[1])];
          i = Math.min(rasterWidth, Math.max(0, i + ox));
          j = Math.min(rasterHeight, Math.max(0, j + oy));
          const h = raster[i + j * rasterWidth];
          return h;
        }));
        if (!heights[0].length || !heights[1].length) return 0;
        const [heightPos, heightNeg] = heights.map((hs) => hs.reduce((a, b) => a + b));
        return heightPos - heightNeg;
      });
      const score = scores.length ? scores.reduce((a, b) => a + b) : 0;
      if (score > best.score) {
        best.score = score;
        best.ox = ox;
        best.oy = oy;
      }
    }
  }
  const offset = [best.ox, best.oy];
  return offset;
};

export const getPointClouds = (canvasPool, state, center, ranges, resolution, scale, raster, rasterHeight, rasterWidth, minDSM, maxDSM, displacementBias, dsmOffset) => {
  if (rasterWidth === 0 || rasterHeight === 0) return {};

  const segmentIndexCanvas = document.createElement('canvas');
  segmentIndexCanvas.width = rasterWidth;
  segmentIndexCanvas.height = rasterHeight;
  let context = segmentIndexCanvas.getContext('2d');
  context.imageSmoothingEnabled = false;
  const dsmBias = minDSM + (displacementBias / 255) * (maxDSM - minDSM);

  // const meanCount = 250;
  const result = {};
  const segmentRanges = {};
  state.segments.forEach((s, si) => {
    result[si] = [];
    const clr = si + 1;
    const g = s.geometry.map(([px, py]) => [Math.round(px), Math.round(py)]);
    const gx = g.map(([px]) => px);
    const gy = g.map(([, py]) => py);
    const gminx = Math.min(...gx);
    const gminy = Math.min(...gy);
    const gmaxx = Math.max(...gx);
    const gmaxy = Math.max(...gy);
    segmentRanges[si] = [gminx, gminy, gmaxx, gmaxy];
    const gcount = s.geometry.length;
    fillPolygon(context, g, `rgb(${clr}, ${clr}, ${clr})`);
    s.lines.forEach((l, li) => {
      if (l === 'Eave' || l === 'Rake') {
        fillPolygon(context, [s.geometry[li], s.geometry[(li + 1) % gcount]], 'rgb(0,0,0)', 12, false);
      }
    });
  });

  // downloadCanvas(segmentIndexCanvas, 'segmentIndices');
  const segData = context.getImageData(0, 0, rasterWidth, rasterHeight);

  const segCount = state.segments.length;
  const [minx, miny, maxx, maxy] = ranges;
  for (let i = minx; i <= maxx; i += 3) {
    for (let j = miny; j <= maxy; j += 3) {
      const index = j * (rasterWidth * 4) + i * 4;
      const sj = segData.data[index];
      if (sj > 0 && sj <= segCount) {
        const [gminx, gminy, gmaxx, gmaxy] = segmentRanges[sj - 1];
        if (i >= gminx && i <= gmaxx && j >= gminy && j <= gmaxy) {
          const io = Math.round(Math.min(rasterWidth, Math.max(0, i + dsmOffset[0])));
          const jo = Math.round(Math.min(rasterWidth, Math.max(0, j + dsmOffset[1])));
          const v = raster[io + jo * rasterWidth];
          const x = (i - center[0]) * scale;
          const y = (j - center[1]) * scale;
          const z = (v - dsmBias) * (100 / (resolution / scale));
          result[sj - 1].push(new THREE.Vector3(x, y, z));
        }
      }
    }
  }

  document.removeChild(segmentIndexCanvas);
  segmentIndexCanvas.height = 1;
  segmentIndexCanvas.width = 1;
  context = null;

  return result;
};

const extractDSMData = (canvasPool, rasters, rasterWidth, rasterLength, dsmOffset, segments, trees) => {
  const canvasTiff = canvasPool.getCanvas(canvasPool.PoolKeys.GetTiff);
  const contextTiff = canvasPool.getContext(canvasPool.PoolKeys.GetTiff);
  const imgData = contextTiff.createImageData(rasterWidth, rasterLength);

  let [minIntensity, maxIntensity] = [1E5, -1E5];

  for (let j = 0; j < rasterLength; j += 1) {
    for (let i = 0; i < rasterWidth; i += 1) {
      const intensity = rasters[0][i + j * rasterWidth];
      if (intensity > 0) {
        minIntensity = Math.min(minIntensity, intensity);
        maxIntensity = Math.max(maxIntensity, intensity);
      }
    }
  }

  for (let j = 0; j < rasterLength; j += 1) {
    for (let i = 0; i < rasterWidth; i += 1) {
      const d = rasters[0][i + j * rasterWidth];
      const intensity = d > 0 ? Math.round(((d - minIntensity) / (maxIntensity - minIntensity)) * 255) : minIntensity;
      imgData.data[(i + j * rasterWidth) * 4] = intensity;
      imgData.data[(i + j * rasterWidth) * 4 + 1] = intensity;
      imgData.data[(i + j * rasterWidth) * 4 + 2] = intensity;
      imgData.data[(i + j * rasterWidth) * 4 + 3] = 255;
    }
  }

  contextTiff.putImageData(imgData, 0, 0);

  const canvasOrgTiff = canvasPool.getCanvas(canvasPool.PoolKeys.GetTiffOrg);
  const contextOrgTiff = canvasPool.getContext(canvasPool.PoolKeys.GetTiffOrg);
  contextOrgTiff.drawImage(canvasTiff, 0, 0);
  const orgDSM = new THREE.CanvasTexture(canvasOrgTiff);

  const canvasWithHouse = canvasPool.getCanvas(canvasPool.PoolKeys.GetTiffWithHouse);
  const contextWithHouse = canvasPool.getContext(canvasPool.PoolKeys.GetTiffWithHouse);
  contextWithHouse.drawImage(canvasTiff, -dsmOffset[0], -dsmOffset[1]);
  const dsmWithHouse = new THREE.CanvasTexture(canvasWithHouse);

  const canvasWithoutHouse = canvasPool.getCanvas(canvasPool.PoolKeys.GetTiffWithoutHouse);
  const contextWithoutHouse = canvasPool.getContext(canvasPool.PoolKeys.GetTiffWithoutHouse);
  contextWithoutHouse.drawImage(canvasTiff, -dsmOffset[0], -dsmOffset[1]);
  const bias = setDisplacementMap(segments, trees, canvasWithoutHouse, contextWithoutHouse);
  const dsmWithoutHouse = new THREE.CanvasTexture(canvasWithoutHouse);

  const rasterData = [rasters[0], rasterLength, rasterWidth];
  const dsmTextureData = [orgDSM, dsmWithHouse, dsmWithoutHouse, minIntensity, maxIntensity, bias];
  const dsmParamsData = { bias, dsmOffset };

  const dsmData = { rasterData, dsmTextureData, dsmParamsData };

  return dsmData;
};

export const getTiff = (canvasPool, segments, trees, dsmSrc, dsmOffset, loadedHandler, errorHandler) => {
  fetch(dsmSrc)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.arrayBuffer();
    })
    .then(arrayBuffer => fromArrayBuffer(arrayBuffer))
    .then(tiff => tiff.getImage())
    .then(image => {
      return image.readRasters().then(rasters => {
        const { ImageLength, ImageWidth } = image.fileDirectory;
        const dsmData = extractDSMData(canvasPool, rasters, ImageWidth, ImageLength, dsmOffset, segments, trees);
        loadedHandler(dsmData);
      });
    })
    .catch(err => {
      console.log(err);
      errorHandler();
    });
};

export const updateTextureData = (canvasPool, dsmData, segments, trees) => {
  const { dsmTextureData, dsmParamsData } = dsmData;
  const { dsmOffset } = dsmParamsData;
  const [orgDSM, , , minIntensity, maxIntensity] = dsmTextureData;

  const { width, height } = orgDSM.image;

  const canvasWithHouse = canvasPool.getCanvas(canvasPool.PoolKeys.GetTiffWithHouse);
  const contextWithHouse = canvasPool.getContext(canvasPool.PoolKeys.GetTiffWithHouse);
  contextWithHouse.drawImage(orgDSM.image, -dsmOffset[0], -dsmOffset[1]);
  const dsmWithHouse = new THREE.CanvasTexture(canvasWithHouse);

  const canvasWithoutHouse = canvasPool.getCanvas(canvasPool.PoolKeys.GetTiffWithoutHouse);
  const contextWithoutHouse = canvasPool.getContext(canvasPool.PoolKeys.GetTiffWithoutHouse);
  contextWithoutHouse.drawImage(orgDSM.image, -dsmOffset[0], -dsmOffset[1]);
  const bias = setDisplacementMap(segments, trees, canvasWithoutHouse, contextWithoutHouse);
  const dsmWithoutHouse = new THREE.CanvasTexture(canvasWithoutHouse);

  const newTextureData = [orgDSM, dsmWithHouse, dsmWithoutHouse, minIntensity, maxIntensity, bias];
  return newTextureData;
};

export const setDSM = (state, raster, rasterHeight, rasterWidth, minDSM, maxDSM, displacementBias, dsmOffset) => {
  state.dsm = {
    raster, rasterHeight, rasterWidth, minDSM, maxDSM, displacementBias, dsmOffset,
  };
};

export const measureByDSM = (canvasPool, state, segments, trees, dsmData) => {
  const {
    orgVertices, verticesType, resolution, scale, center,
  } = state;
  const { rasterData, dsmTextureData } = dsmData;
  const [raster, rasterHeight, rasterWidth] = rasterData;
  const [,,, minDSM, maxDSM, displacementBias] = dsmTextureData;
  const roofVertices = orgVertices.filter((p, pi) => verticesType[pi] === 'roof');
  const borders = getBorders(state, roofVertices, true);
  const offset = getOffset(raster, rasterHeight, rasterWidth, borders, resolution, scale, center);
  const indicesMask = segmentIndexMask(canvasPool, state, segments, rasterHeight, rasterWidth);
  const baseDSM = minDSM + (displacementBias / 255) * (maxDSM - minDSM);
  fitPlaneOnSegment(segments, trees, state, center, scale, resolution, raster, baseDSM, offset, indicesMask);
  return offset;
};

export const getDSMData = (canvasPool, state, raster, segments, trees) => {
  const {
    orgVertices, verticesType, resolution, scale, center,
  } = state;
  const roofVertices = orgVertices.filter((p, pi) => verticesType[pi] === 'roof');
  const borders = getBorders(state, roofVertices, true);
  const dsmOffset = getOffset(raster[0], raster.height, raster.width, borders, resolution, scale, center);
  const dsmData = extractDSMData(canvasPool, raster, raster.width, raster.height, dsmOffset, segments, trees);
  const { dsmTextureData } = dsmData;
  const [orgDSMTexture,, dsmTexture, minDSM, maxDSM, displacementBias] = dsmTextureData;
  return {
    dsmTexture,
    orgDSMTexture,
    minDSM,
    maxDSM,
    displacementBias,
    dsmOffset,
  };
};
