import React from 'react';
import {
  InputGroup, FormControl, Dropdown, DropdownButton,
} from 'react-bootstrap';
import PropTypes, { number, string } from 'prop-types';

class PitchDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
    };
  }

  onDropdownSelect = (eventKey) => {
    const {
      degrees, pitches, segmentPitchAndAreaHandler, segIdx,
    } = this.props;
    const value = degrees ? pitches[eventKey] : Math.round(Math.atan(eventKey / 12) * (180 / Math.PI));
    segmentPitchAndAreaHandler(segIdx, value);
    // this.setState({
    //   value,
    // });
  }

  onFormControlChange = (e) => {
    const { segmentPitchAndAreaHandler, segIdx } = this.props;
    const val = e.target.value;
    segmentPitchAndAreaHandler(segIdx, val);
    // this.setState({
    //   value: val,
    // });
  }

  render() {
    const {
      degrees, pitches, pitch, segIdx, noMargin,
    } = this.props;
    return (
      <InputGroup size="sm" className={noMargin ? '' : 'mb-3'}>
        {segIdx !== -1 ? (
          <FormControl size="sm" value={pitch} onChange={this.onFormControlChange} aria-label="Text input with dropdown button" />
        ) : (
          <FormControl size="sm" onChange={this.onFormControlChange} aria-label="Text input with dropdown button" />
        )}
        <InputGroup.Append>
          <DropdownButton
            className="pitch-dropdown"
            size="sm"
            variant="outline-secondary"
            title=""
            id="input-group-dropdown-2"
            align="end"
            onSelect={this.onDropdownSelect}
          >
            {pitches.map((p, idx) => (
              <Dropdown.Item key={p} eventKey={idx}>{degrees ? p : `${idx}/12`}</Dropdown.Item>
            ))}
          </DropdownButton>
        </InputGroup.Append>
      </InputGroup>
    );
  }
}

PitchDropdown.propTypes = {
  degrees: PropTypes.bool.isRequired,
  pitches: PropTypes.array.isRequired,
  segIdx: PropTypes.number.isRequired,
  noMargin: PropTypes.bool.isRequired,
  pitch: PropTypes.oneOf([number, string]).isRequired,
  segmentPitchAndAreaHandler: PropTypes.func.isRequired,
};

export default PitchDropdown;
