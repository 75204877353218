import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { PersonCircle } from 'react-bootstrap-icons';

import ValidatedForm, { FloatInput, FloatSelect } from 'components/Form/Inputs';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ImageCropper from 'components/Form/Image';
import { Admin, Manager, Sales } from './Permissions';
import { AuthContext } from 'api/context';
// import styles from './UserSetup.module.css';

const UserSetup = ({ id, endpoint, afterSaved }) => {
  const empty = {
    first_name: '',
    last_name: '',
    email: '',
    role: 'Solar Advisor',
    is_active: true
  };
  const [user, setUser] = useState(empty);
  const [errors, setErrors] = useState({});
  const [dirtyFields, setDirtyFields] = useState({});
  const [crop, setCrop] = useState({ show: false });
  const [discountType, setDiscountType] = useState('W');

  const { displaySnack } = useContext(AuthContext);

  const discountTypeOptions = [{ name: 'Per Watt', value: 'W' }, { name: 'Flat', value: 'F' }, { name: 'Percent', value: 'P' }]
  
  const setError = (key, message) => {
    setErrors(errors => ({ ...errors, [key]: message }));
  };

  const removeError = (key) => {
    setErrors(errors => {
      delete errors[key];
      return errors;
    });
  };

  const setDirtyField = (key) => {
    setDirtyFields(state => ({ ...state, [key]: true }));
  };

  const isDirty = (key) => dirtyFields[key] == true; 

  const setRole = (e) => {
    setUser({ ...user, role: e.target.id });
  };

  const handleClose = () => setCrop({ show: false });

  const handleCrop = async (cropped) => {
    setCrop({ show: false, image: cropped });

    // prepare form data for submission
    const form = new FormData();
    const blob = await fetch(cropped).then((r) => r.blob());
    form.append('profile', blob, 'profile.jpg');
    endpoint.update(user.id, form).then((data) => setUser(data));
  };

  const handleProfile = (e) => {
    if (!e.target.files[0]) {
      setCrop({ show: false, image: undefined });
      return;
    }
    // set image from file
    setCrop({ show: true, image: URL.createObjectURL(e.target.files[0]) });
  };

  const saveCreate = () => {
    const { profile, ...userData } = user;

    if (userData?.discount_type === 'W') {
      if (Number(userData?.discount) < 0) return displaySnack({ variant: 'danger', message: 'User discount Value must be larger than 0' });
    }
    if (userData?.discount_type === 'P') {
      if (Number(userData?.discount) > 99 || Number(userData.discount) < 0) return displaySnack({ variant: 'danger', message: 'User discount Value must be between 0 and 100' });
    }
    if (userData?.discount_type === 'F') {
      if (Number(userData?.discount) < 1) return displaySnack({ variant: 'danger', message: 'User discount Value must be larger than 1' });
    }

    // if (userData.discount_type === 'W' || userData.discount_type === 'P') {
    //   if (Number(userData.discount) > 0.99 || Number(userData.discount) < 0) return displaySnack({ variant: 'danger', message: 'User discount Value must be between 0 and 1' });
    // }
    // if (userData.discount_type === 'F') {
    //   if (Number(userData.discount) < 1) return displaySnack({ variant: 'danger', message: 'User discount value must be larger than 1' });
    // }

    if (id) {
      console.log('Updating staff member');
      return endpoint.update(id, userData).then((data) => {
        setUser(data);
        afterSaved('updated');
      });
    }
    return endpoint.create(userData).then(
      () => {
        console.log('success');
        afterSaved('created');
      },
    );
  };

  useEffect(() => {
    if (id && user.id === undefined) {
      endpoint.retrieve(id).then((data) => {
        setUser(data);
        removeError('error');
      }).catch((e) => {
        setError('error', "Could't find the user via the provided ID.");
      });
    }

    setDiscountType(user?.discount_type);
  }, [id, user, endpoint]);

  useEffect(() => {
    if (!user.email) {
      setError('email', 'Email is required');
    } else {
      removeError('email');
    }
    if (!user.first_name) {
      setError('first_name', 'First name is required');
    } else {
      removeError('first_name');
    }
    if (!user.last_name) {
      setError('last_name', 'Last name is required');
    } else {
      removeError('last_name');
    }
  }, [user]);

  return (
    <ValidatedForm onSave={saveCreate}>
      <Row className="w-100">
        <Col sm={3} className="text-center">
          <ImageCropper
            aspect={1}
            show={crop.show}
            image={crop.image ? crop.image : user.profile}
            onClose={handleClose}
            onCrop={handleCrop}
          />
          <div
            role="button"
            tabIndex={-1}
            className="mx-auto rounded-circle position-relative overflow-hidden"
            style={{ width: 110, height: 110 }}
            onClick={() => document.getElementById('user-profile').click()}
            onKeyDown={() => {}} // make warnings stop
          >
            <img src={user.profile || '/profile.png'} height="100%" alt="logo" />
            <Button className="position-absolute fixed-top w-100 p-0 opacity-75">
              <PersonCircle size={90} color="white" />
            </Button>
          </div>
          <Form.File
            id="user-profile"
            className="d-none"
            onChange={handleProfile}
          />
        </Col>
        <Col sm={9} className="mt-2">
          <Row xs={1} sm={2}>
            <Col>
              <FloatInput
                label="* First Name"
                value={user.first_name}
                isInvalid={errors['first_name'] && isDirty('first_name')}
                invalidText={errors['first_name']}
                onChange={(e) => {
                  setUser({ ...user, first_name: e.target.value });
                  setDirtyField('first_name');
                }}
                required
                // disabled={user.id}
              />
            </Col>
            <Col>
              <FloatInput
                label="* Last Name"
                value={user.last_name}
                isInvalid={errors['last_name'] && isDirty('last_name')}
                invalidText={errors['last_name']}
                onChange={(e) => {
                  setUser({ ...user, last_name: e.target.value });
                  setDirtyField('last_name');
                }}
                required
                // disabled={user.id}
              />
            </Col>
          </Row>

          <Row xs={1} sm={2} className="my-2">
            <Col>
              <FloatInput
                required
                label="* Email"
                value={user.email}
                isInvalid={errors['email'] && isDirty('email')}
                invalidText={errors['email']}
                onChange={(e) => {
                  setUser({ ...user, email: e.target.value });
                  setDirtyField('email');
                }}
                disabled={user.id}
              />
            </Col>
            <Col>
              <FloatInput
                label="Phone"
                value={user.phone}
                onChange={(e) => setUser({ ...user, phone: e.target.value })}
                // disabled={user.id}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <hr />

      <h6 className="font-weight-bold">User Type</h6>
      <Row>
        <Col>
          <Form.Check
            id="Admin"
            name="userRole"
            type="radio"
            label="Company Admin"
            checked={user.role === 'Admin'}
            onChange={setRole}
            style={{ display: 'inline' }}
          />
          <Admin />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Check
            id="Solar Advisor"
            name="userRole"
            type="radio"
            label="Solar Advisor"
            checked={user.role === 'Solar Advisor'}
            onChange={setRole}
            style={{ display: 'inline' }}
          />
          <Sales />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Check
            id="Project Manager"
            name="userRole"
            type="radio"
            label="Project Manager"
            checked={user.role === 'Project Manager'}
            onChange={setRole}
            style={{ display: 'inline' }}
            disabled
          />
          <Manager />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <FloatInput
            type="number"
            label="Comission (per Watt)"
            prepend="$"
            value={user.commission}
            onChange={(e) => setUser({ ...user, commission: e.target.value })}
          />
        </Col>
        <Col>
          <FloatInput
            type="number"
            label={`Max. Discount (${discountTypeOptions.filter(el => el.value === discountType)[0]?.name})`}
            prepend={discountType !== "P" ? "$" : "%"}
            value={user?.discount}
            onChange={(e) => {
              // if (discountType === 'W') {
              //   if (Number(e.target.value) < 0) return displaySnack({ variant: 'danger', message: 'User discount Value must be larger than 0' });
              // }
              // if (discountType === 'P') {
              //   if (Number(e.target.value) > 99 || Number(e.target.value) < 0) return displaySnack({ variant: 'danger', message: 'User discount Value must be between 0 and 100' });
              // }
              // if (discountType === 'F') {
              //   if (Number(e.target.value) < 1) return displaySnack({ variant: 'danger', message: 'User discount Value must be larger than 1' });
              // }

              // if (discountType === 'W' || discountType === 'P') {
              //   if (Number(e.target.value) > 0.99 || Number(e.target.value) < 0) return displaySnack({ variant: 'danger', message: 'Value must be between 0 and 1' });
              // }
              // if (discountType === 'F') {
              //   if (Number(e.target.value) < 1) return displaySnack({ variant: 'danger', message: 'Value must be larger than 1' });
              // }
              setUser({ ...user, discount: e.target.value });
            }}
          />
        </Col>
        <Col>
          <FloatSelect
            label="Discount Type"
            nullValue="Choose Discount Type"
            options={discountTypeOptions}
            labelField='name'
            valueField='value'
            value={user?.discount_type}
            onChange={(e) => setUser({ ...user, discount_type: e.target.value })}
          />
        </Col>
      </Row>

      <hr />

      <h6 className="font-weight-bold">User Status</h6>

      <Row>
        <Col>
          <FormControlLabel
            control={<Switch checked={!user.is_active} onChange={(e) => setUser({ ...user, is_active: !e.target.checked })} name="is_active" />}
            label="Deactivated"
          />
        </Col>
      </Row>

      <Row className="mt-3 d-flex justify-content-end">
        <Col sm={2}>
          <Button type="submit" className="w-100">
            {user.id ? 'Save' : 'Create'}
          </Button>
        </Col>
      </Row>
    </ValidatedForm>
  );
};

// TODO: propTypes

export default UserSetup;
