import React, { useEffect, useState, useContext } from 'react';
import {
  Row,
  Col,
  Button,
  ButtonGroup,
  Tab,
  Tabs,
  Spinner,
  Modal,
} from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import Save, { SaveOutline } from 'components/Save';
import Edit, { EditOutline } from 'components/Edit';

import { AuthContext } from 'api/context';
import ValidatedForm, { FloatInput, FloatSelect } from 'components/Form/Inputs';
import { stateOptions, countryOptions } from 'options';
import { convertStateToAbbr } from 'options';
import LoadProfileChart from './LoadProfileChart';
import LoadProfileChartModal from './LoadProfileChartModal';

const UtilityLoad = () => {
  const { newEndpoint, displaySnack } = useContext(AuthContext);
  const loadProfilesEndpoint = newEndpoint('hardware/load_profiles/');
  const loadProfileTypesEndpoint = newEndpoint('hardware/load_profiles/types/');

  const [newLoadProfile, setNewLoadProfile] = useState();
  const [loadProfiles, setLoadProfiles] = useState([]);
  const [loadProfileTypes, setLoadProfileTypes] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [profileToDelete, setProfileToDelete] = useState(null);
  const [editingProfile, setEditingProfile] = useState();

  console.log('newLoadProfile:', newLoadProfile);
  console.log('loadProfiles:', loadProfiles);
  console.log('loadProfileTypes:', loadProfileTypes);

  // Load initial data
  useEffect(() => {
    loadProfileTypesEndpoint.list().then((data) => {
      if (data.length !== 0) {
        setLoadProfileTypes(data);
      }
    });
    loadProfilesEndpoint.list().then((data) => {
      if (data.length !== 0) {
        setLoadProfiles(data);
      }
    });
  }, []);

  // Modify initial profile setup
  const initLoadProfile = () => {
    const newProfile = {
      name: '',
      country: null,
      state: null,
      usages: [400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400],
    };
    setNewLoadProfile(newProfile);
  };

  // Modify delete handler
  const handleDeleteClick = (profile) => {
    setProfileToDelete(profile);
    setDeleteModal(true);
  };

  const handleDelete = () => {
    // First find the profile type ID from the profile to be deleted
    const profileTypeName = profileToDelete.name.startsWith('default-')
      ? profileToDelete.name.replace('default-', '')
      : profileToDelete.name;

    // Find the profile type with matching name
    const profileType = loadProfileTypes.find(type => type.name === profileTypeName);

    // Delete load profile first, then profile type
    loadProfilesEndpoint
      .remove(profileToDelete.id)
      .then(() => {
        // After load profile is deleted, delete the profile type if it exists
        if (profileType) {
          return loadProfileTypesEndpoint.remove(profileType.id);
        }
        return Promise.resolve();
      })
      .then(() => {
        // Update both states
        setLoadProfiles(loadProfiles.filter(p => p.id !== profileToDelete.id));
        setLoadProfileTypes(loadProfileTypes.filter(t => t.id !== profileType?.id));

        displaySnack({
          variant: 'success',
          message: `Deleted ${profileToDelete.name?.replace('default-', '')}`
        });
        setDeleteModal(false);
        setProfileToDelete(null);
      })
      .catch(() => {
        displaySnack({
          variant: 'warning',
          message: `Failed to delete ${profileToDelete.name?.replace('default-', '')}`,
        });
        setDeleteModal(false);
        setProfileToDelete(null);
      });
  };

  // Modify the post handler for load profiles
  const postLoadProfileHandler = (profileToUpdate) => {
    const { id, ...totalLoadProfile } = profileToUpdate;

    // Ensure usages are numbers and have default values if undefined
    totalLoadProfile.usages = (totalLoadProfile.usages || Array(12).fill(400)).map(Number);

    // Handle state value for "All States/Provinces" selection
    if (totalLoadProfile.state === `All ${totalLoadProfile.country === 'Canada' ? 'Provinces' : 'States'}`) {
      totalLoadProfile.state = null;
    }

    // For existing profiles
    if (id) {
      const existingProfile = loadProfiles.find(p => p.id === id);
      const nameHasChanged = existingProfile.name.replace('default-', '') !== totalLoadProfile.name;

      // Prepare the profile name for North America case
      if (!totalLoadProfile.country) {
        totalLoadProfile.name = `default-${totalLoadProfile.name}`;
      }

      // If name hasn't changed, delete old and create new
      const createNewProfile = () => {
        // Get the existing profile type
        const existingType = loadProfileTypes.find(type =>
          type.name === totalLoadProfile.name.replace('default-', '')
        );

        if (existingType) {
          totalLoadProfile.type = existingType.id;
        }

        return loadProfilesEndpoint
          .create(totalLoadProfile)
          .then(() => loadProfilesEndpoint.list())
          .then((data) => {
            setLoadProfiles(data);
            displaySnack({
              variant: 'success',
              message: `Updated ${profileToUpdate.name}`,
            });
          });
      };

      // If name has changed, create new profile type first
      if (nameHasChanged) {
        return loadProfileTypesEndpoint
          .create({ name: totalLoadProfile.name.replace('default-', '') })
          .then((savedType) => {
            totalLoadProfile.type = savedType.id;
            return loadProfilesEndpoint.remove(id);
          })
          .then(() => createNewProfile())
          .catch((error) => {
            console.error('Profile type creation error:', error);
            displaySnack({
              variant: 'danger',
              message: `Failed to create new profile type`,
            });
            return Promise.reject(error);
          });
      }

      // If name hasn't changed, just delete and recreate with same type
      return loadProfilesEndpoint
        .remove(id)
        .then(() => createNewProfile());
    }

    // For new profiles
    const profileTypeName = totalLoadProfile.name;
    if (!totalLoadProfile.country) {
      totalLoadProfile.name = `default-${totalLoadProfile.name}`;
    }

    return loadProfileTypesEndpoint
      .create({ name: profileTypeName })
      .then((savedType) => {
        totalLoadProfile.type = savedType.id;
        return loadProfilesEndpoint.create(totalLoadProfile);
      })
      .then(() => loadProfilesEndpoint.list())
      .then((data) => {
        setLoadProfiles(data);
        displaySnack({
          variant: 'success',
          message: `Added ${profileToUpdate.name}`,
        });
      })
      .catch((error) => {
        console.error('Save error:', error);
        displaySnack({
          variant: 'danger',
          message: 'Failed to add new load profile',
        });
        return Promise.reject(error);
      });
  };

  // Add edit handler
  const handleEditClick = (profile) => {
    const isDefault = profile.name.startsWith('default-');
    setEditingProfile({
      ...profile,
      name: isDefault ? profile.name.replace('default-', '') : profile.name,
      country: profile.country || null,
      state: profile.state || null,
      usages: profile.usages || [400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400],
      isDefault
    });
  };

  return (
    <>
      {/* Table of existing profiles */}
      {loadProfiles.length > 0 && (
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Location Scope</th>
              <th>State/Province</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {loadProfiles.map((profile) => (
              <tr key={profile.id}>
                <td>
                  {profile.name.startsWith('default-')
                    ? profile.name.replace('default-', '')
                    : profile.name}
                </td>
                <td>{profile.country || 'North America'}</td>
                <td>
                  {
                    profile.country ? (
                      profile.state ? profile.state :
                        profile.country === 'Canada' ? 'All Provinces' :
                          'All States'
                    ) : 'All States/Provinces'
                  }
                </td>
                <td>
                  <ButtonGroup size="sm">
                    <Button
                      variant="outline-primary p-2"
                      onClick={() => handleEditClick(profile)}
                    >
                      <Edit />
                    </Button>
                    <Button
                      variant="outline-primary p-2"
                      onClick={() => handleDeleteClick(profile)}
                    >
                      <Trash />
                    </Button>
                  </ButtonGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Edit Form with Graph/Usage */}
      {editingProfile && (
        <ValidatedForm
          onSave={() => {
            return postLoadProfileHandler(editingProfile).then(() => {
              setEditingProfile(undefined);
            });
          }}
        >
          <Row style={{ width: '90%' }}>
            <Col sm={4} xs={12}>
              <FloatInput
                className="mt-4"
                required
                label="Name"
                value={editingProfile.name}
                onChange={(e) =>
                  setEditingProfile({
                    ...editingProfile,
                    name: e.target.value,
                  })
                }
              />
            </Col>
            <Col sm={4} xs={12}>
              <FloatSelect
                className="mt-4"
                label="Location Scope"
                nullValue="North America"
                options={[
                  { name: 'North America', value: null },
                  { name: 'United States', value: 'United States' },
                  { name: 'Canada', value: 'Canada' }
                ]}
                labelField="name"
                valueField="value"
                value={editingProfile.country || ''}
                onChange={(e) => {
                  setEditingProfile({
                    ...editingProfile,
                    country: e.target.value === 'North America' ? null : e.target.value,
                    state: null,
                  });
                }}
              />
            </Col>
            {editingProfile.country && editingProfile.country !== 'North America' && (
              <Col sm={4} xs={12}>
                <FloatSelect
                  className="mt-4"
                  required={false}
                  label={`${editingProfile.country === 'Canada' ? 'Province' : 'State'}`}
                  nullValue={`All ${editingProfile.country === 'Canada' ? 'Provinces' : 'States'}`}
                  options={stateOptions[editingProfile.country]?.map(s => ({ name: s })) || []}
                  labelField="name"
                  value={editingProfile.state || ''}
                  onChange={(e) =>
                    setEditingProfile({
                      ...editingProfile,
                      state: e.target.value || null,
                    })
                  }
                />
              </Col>
            )}
          </Row>

          {/* Chart */}
          <Row className="mt-4">
            <Col>
              <LoadProfileChart
                loadProfile={{
                  ...editingProfile,
                  usages: editingProfile.usages || [400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400]
                }}
                handleUpdate={(updatedProfile) => {
                  console.log('Updated Profile in edit form:', updatedProfile);
                  setEditingProfile({
                    ...editingProfile,
                    usages: updatedProfile.usages.map(Number)
                  });
                }}
                newLoadProfile={false}
              />
            </Col>
          </Row>

          {/* Save/Cancel buttons */}
          <Row className="mt-3 mb-3">
            <Col className="d-flex justify-content-end">
              <ButtonGroup size="sm">
                <Button
                  variant="outline-primary p-2"
                  type="submit"
                >
                  Save
                </Button>
                <Button
                  variant="outline-primary p-2"
                  onClick={() => setEditingProfile(undefined)}
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </ValidatedForm>
      )}

      {/* New Profile Button/Form */}
      {newLoadProfile ? (
        <ValidatedForm
          onSave={() => {
            return postLoadProfileHandler(newLoadProfile).then(() => {
              setNewLoadProfile(undefined);
            });
          }}
        >
          <Row className='mt-3' style={{ width: '90%' }}>
            <Col sm={4} xs={12}>
              <FloatInput
                className="mt-4"
                required
                label="Name"
                value={newLoadProfile.name}
                onChange={(e) =>
                  setNewLoadProfile({
                    ...newLoadProfile,
                    name: e.target.value,
                  })
                }
              />
            </Col>
            <Col sm={4} xs={12}>
              <FloatSelect
                className="mt-4"
                label="Location Scope"
                nullValue="North America"
                options={[
                  { name: 'North America', value: null },
                  { name: 'United States', value: 'United States' },
                  { name: 'Canada', value: 'Canada' }
                ]}
                labelField="name"
                valueField="value"
                value={newLoadProfile.country || ''}
                onChange={(e) => {
                  setNewLoadProfile({
                    ...newLoadProfile,
                    country: e.target.value === 'North America' ? null : e.target.value,
                    state: null,
                  });
                }}
              />
            </Col>
            {newLoadProfile.country && newLoadProfile.country !== 'North America' && (
              <Col sm={4} xs={12}>
                <FloatSelect
                  className="mt-4"
                  required={false}
                  label={`${newLoadProfile.country === 'Canada' ? 'Province' : 'State'}`}
                  nullValue={`All ${newLoadProfile.country === 'Canada' ? 'Provinces' : 'States'}`}
                  options={stateOptions[newLoadProfile.country]?.map(s => ({ name: s })) || []}
                  labelField="name"
                  value={newLoadProfile.state || ''}
                  onChange={(e) =>
                    setNewLoadProfile({
                      ...newLoadProfile,
                      state: e.target.value || null,
                    })
                  }
                />
              </Col>
            )}
          </Row>

          {/* Show chart for all cases now */}
          <Row className="mt-4">
            <Col>
              <LoadProfileChart
                loadProfile={{
                  ...newLoadProfile,
                  usages: newLoadProfile.usages || [400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400]
                }}
                handleUpdate={(updatedProfile) => {
                  console.log('Updated Profile in new form:', updatedProfile);
                  setNewLoadProfile({
                    ...newLoadProfile,
                    usages: updatedProfile.usages.map(Number)
                  });
                }}
                newLoadProfile={true}
              />
            </Col>
          </Row>

          {/* Save/Cancel buttons */}
          <Row className="mt-3">
            <Col className="d-flex justify-content-end">
              <ButtonGroup size="sm">
                <Button variant="outline-primary p-2" type="submit">
                  Save
                </Button>
                <Button
                  variant="outline-primary p-2"
                  onClick={() => setNewLoadProfile(undefined)}
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </ValidatedForm>
      ) : (
        <Button
          onClick={initLoadProfile}
          className="mt-3 mb-3"
        >
          + New Load Profile
        </Button>
      )}

      {/* Delete Modal */}
      <Modal show={deleteModal} onHide={() => setDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete {profileToDelete?.name?.replace('default-', '')}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UtilityLoad;
