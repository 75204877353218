export const delay = (fn, ms) => new Promise((resolve) => setTimeout(() => resolve(fn()), ms));

export const createCanvas2D = async (width = 1024, height = 1024) => {
  const maxSeconds = 3;
  const canvas = document.createElement('canvas');
  canvas.height = height; canvas.width = width;
  const e = new Date().getTime() + (maxSeconds * 1000);
  while (!canvas.getContext('2d') && new Date().getTime() <= e) {
    await delay(100);
  }
  return canvas;
};

const initWidth = 1024;

const initHeight = 1024;

export class CanvasPool {
  PoolKeys = {
    SegmentIndexMask: 'SegmentIndexMask',
    GetTiff: 'GetTiff',
    GetTiffOrg: 'GetTiffOrg',
    GetTiffWithHouse: 'GetTiffWithHouse',
    GetTiffWithoutHouse: 'GetTiffWithoutHouse',
    GetMaskCanvas: 'GetMaskCanvas',
    GetAnualPanelsShadings: 'GetAnualPanelsShadings',
  };

  canvasPool = {};

  contextPool = {};

  imageDataPool = {};

  nodejs = false;

  constructor(createPool = true) {
    if (createPool) this.CreatePool();
  }

  CreateCanvas(width, height) {
    let canvas;
    if (!this.nodejs) {
      canvas = document.createElement('canvas');
      canvas.height = height; canvas.width = width;
    } else {
      canvas = global.createCanvas(width, height);
    }
    return canvas;
  }

  Create(key) {
    key = key.toLowerCase();

    if (this.canvasPool[key]) {
      this.Destroy(key);
    }
    const canvas = this.CreateCanvas(initWidth, initHeight);
    this.canvasPool[key] = canvas;

    if (!this.contextPool[key]) {
      const context = canvas.getContext('2d');
      this.contextPool[key] = context;
    }
  }

  Destroy(key) {
    key = key.toLowerCase();
    const canvas = this.canvasPool[key];
    canvas.width = 1;
    canvas.height = 1;
    this.canvasPool[key] = null;
    this.contextPool[key] = null;

    delete this.canvasPool[key];
    delete this.contextPool[key];
  }

  CreatePool() {
    Object.keys(this.PoolKeys).forEach((key) => {
      this.Create(key);
    });
  }

  getCanvas(key) {
    key = key.toLowerCase();
    if (!(key in this.canvasPool)) this.Create(key);
    return this.canvasPool[key];
  }

  getContext(key) {
    key = key.toLowerCase();
    const context = this.contextPool[key];
    if (!context) {
      this.Destroy(key);
      this.Create(key);
      return this.contextPool[key];
    }
    return context;
  }

  saveImageData(key, imageData = null) {
    key = key.toLowerCase();
    if (imageData === null) {
      const canvas = this.getCanvas(key);
      const context = this.getContext(key);
      imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    }
    this.imageDataPool[key] = imageData;// new ImageData(imageData.data, imageData.width, imageData.height);
  }

  getImageData(key) {
    key = key.toLowerCase();
    return this.imageDataPool[key];
  }
}

let ticTime = 0;
export function tic() {
  ticTime = Math.floor(Date.now());
}

export function toc(job) {
  const tocTime = Math.floor(Date.now()) - ticTime;
  // console.log(`${job} - time : ${tocTime}`);
}

export const downloadObjectAsJson = (json, exportName) => {
  const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(json)}`;
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('download', `${exportName}.json`);
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};

export const randId = () => 'xxxxxxxx'.replace(/[xy]/g, (c) => {
  const r = (Math.random() * 16) | 0;
  const v = c === 'x' ? r : (r & 0x3 | 0x8);
  return v.toString(16);
});

export const getTreeData = (trees, scale, center, footPerUnit) => {
  const [cx, cy] = center;
  const c = 1.0;
  const newTrees = trees.map((tree) => {
    const r2 = (tree.geometry[0][0] - tree.geometry[1][0]) ** 2
    + (tree.geometry[0][1] - tree.geometry[1][1]) ** 2;
    const pos = [(tree.geometry[0][0] - cx / c) * scale, (tree.geometry[0][1] - cy / c) * scale, 0];
    const end = [(tree.geometry[1][0] - cx / c) * scale, (tree.geometry[1][1] - cy / c) * scale, 0];
    return {
      position: pos,
      height: (tree.height / footPerUnit) * scale,
      radius: Math.sqrt(r2) * scale,
      treeType: tree.model,
      index: tree.id,
      end,
    };
  });
  return newTrees;
};

export const filterDictByKeys = (object, keys) => {
  if (object !== null) {
    const filtered = Object.keys(object).filter((key) => keys.includes(key)).reduce((obj, key) => {
      obj[key] = object[key];
      return obj;
    }, {});
    return filtered;
  }
};

const year = new Date().getFullYear();

export const daysInMonth = Array(12).fill().map((_, i) => 32 - new Date(year, i, 32).getDate());

export const daysOfWeek = () => {
  const weekdays = Array(12).fill(0);
  const weekends = Array(12).fill(0);
  daysInMonth.forEach((nDays, mo) => {
    for (let d = 0; d < nDays; d += 1) {
      const day = new Date(year, mo, d).getDay();
      if (day % 6 === 0) weekends[mo] += 1;
      else weekdays[mo] += 1;
    }
  });
  return [weekdays, weekends];
};

export default {
  CanvasPool, delay, tic, toc, getTreeData, filterDictByKeys, daysInMonth, daysOfWeek,
};
