import React, { useEffect, useState, useContext } from 'react';
import {
  Row,
  Col,
  Form,
  Button,
  ButtonGroup,
  InputGroup,
} from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';

import { AuthContext } from 'api/context';
import Save from 'components/Save';
import ValidationForm from 'components/Form/ValidationForm';

const SystemLosses = ({ inverter, losses, snowLoss }) => {
	const { company: id, user, newEndpoint, displaySnack } = useContext(AuthContext);
  const endpoint = newEndpoint('hardware/losses/');

  const [companyLosses, setCompanyLosses] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (!losses) {
      endpoint.retrieve(id)
      .then((data) => setCompanyLosses({...data, snow: snowLoss}))
      .catch((e) => console.log(e));
      // console.log(`system losses: ${JSON.stringify(companyLosses)}`);
    } else {
      setCompanyLosses({...losses, snow: snowLoss});
      // console.log(`system losses: ${JSON.stringify(companyLosses)}`);
    }
  }, []);

  useEffect(() => {
		let t = 0;
		if (companyLosses) {
			for (const property in companyLosses) {
				if (property !== 'company') {
					// console.log(`${property}: ${losses[property]}`);
          t += Number(companyLosses[property]);
				}
			}
		}
		setTotal(t);
	}, [companyLosses]);

  const handleUpdateField = (value, field) => {
    const updated = losses;
    updated[field] = value;
  };

  return (
    <>
			<h5>PVWatts Losses</h5>
			<Row className='my-2'>
				<Col>
					<strong>Inverter Efficiency: {inverter?.efficiency}</strong>
				</Col>
			</Row>
      <Row className="my-1">
        <Col>
          <InputGroup size="sm">
            <InputGroup.Prepend>
              <InputGroup.Text>Soiling</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="number"
              value={companyLosses.soiling}
              onChange={(e) => handleUpdateField(e.target.value, 'soiling')}
              step="0.10"
              min="0.00"
              required
            />
            <InputGroup.Append>
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Row>
      <Row className="my-1">
        <Col>
          <div className="p-1 mt-2 bg-dark text-white">
            * Shading analysis results are factored in calculations based on
            shading simulation results.
          </div>
          <InputGroup size="sm">
            <InputGroup.Prepend>
              <InputGroup.Text>Shading</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="number"
              value={companyLosses.shading}
              onChange={(e) => handleUpdateField(e.target.value, 'shading')}
              step="0.10"
              min="0.00"
              disabled
            />
            <InputGroup.Append>
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Row>
      <Row className="my-1">
        <Col>
          <div className="p-1 mt-2 bg-dark text-white">
            * Reduction in the system's annual output due to snow covering is
            adjusted per state automatically.
          </div>
          <InputGroup size="sm">
            <InputGroup.Prepend>
              <InputGroup.Text>Snow</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="number"
              value={companyLosses.snow}
              onChange={(e) => handleUpdateField(e.target.value, 'snow')}
              step="0.10"
              min="0.00"
              disabled
            />
            <InputGroup.Append>
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Row>
      <Row className="my-1">
        <Col>
          <InputGroup size="sm">
            <InputGroup.Prepend>
              <InputGroup.Text>Mismatch</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="number"
              value={companyLosses.mismatch}
              onChange={(e) => handleUpdateField(e.target.value, 'mismatch')}
              step="0.10"
              min="0.00"
              required
            />
            <InputGroup.Append>
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Row>
      <Row className="my-1">
        <Col>
          <InputGroup size="sm">
            <InputGroup.Prepend>
              <InputGroup.Text>Wiring</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="number"
              // check zero should be eliminated in future when when the losses change to aggregate
              value={companyLosses.wiring === '0.00' ? '2.00' : companyLosses.wiring}
              onChange={(e) => handleUpdateField(e.target.value, 'wiring')}
              step="0.10"
              min="0.00"
              required
            />
            <InputGroup.Append>
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Row>
      <Row className="my-1">
        <Col>
          <InputGroup size="sm">
            <InputGroup.Prepend>
              <InputGroup.Text>Connections</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="number"
              value={companyLosses.connections}
              onChange={(e) => handleUpdateField(e.target.value, 'connections')}
              step="0.10"
              min="0.00"
              required
            />
            <InputGroup.Append>
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Row>
      <Row className="my-1">
        <Col>
          <InputGroup size="sm">
            <InputGroup.Prepend>
              <InputGroup.Text>Light Induced Degradation</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="number"
              value={companyLosses.lid}
              onChange={(e) => handleUpdateField(e.target.value, 'lid')}
              step="0.10"
              min="0.00"
              required
            />
            <InputGroup.Append>
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Row>
      <Row className="my-1">
        <Col>
          <InputGroup size="sm">
            <InputGroup.Prepend>
              <InputGroup.Text>Nameplate Rating</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="number"
              value={companyLosses.nameplate_rating}
              onChange={(e) =>
                handleUpdateField(e.target.value, 'nameplate_rating')
              }
              step="0.10"
              min="0.00"
              required
            />
            <InputGroup.Append>
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Row>
      <Row className="my-1">
        <Col>
          <InputGroup size="sm">
            <InputGroup.Prepend>
              <InputGroup.Text>Age</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="number"
              value={companyLosses.age}
              onChange={(e) => handleUpdateField(e.target.value, 'age')}
              step="0.10"
              min="0.00"
              required
            />
            <InputGroup.Append>
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Row>
      <Row className="my-1">
        <Col>
          <InputGroup size="sm">
            <InputGroup.Prepend>
              <InputGroup.Text>Availability</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="number"
              value={companyLosses.availability}
              onChange={(e) =>
                handleUpdateField(e.target.value, 'availability')
              }
              step="0.10"
              min="0.00"
              required
            />
            <InputGroup.Append>
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Row>
			<strong>Total Losses: {total}</strong>
    </>
  );
};

export default SystemLosses;
