import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Table, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';

const ensureNumber = (value, defaultValue = 0) => {
    if (typeof value === 'string') {
        const parsed = parseFloat(value);
        return isNaN(parsed) ? defaultValue : parsed;
    }
    const num = Number(value);
    return isNaN(num) ? defaultValue : num;
};

const formatNumber = (value, decimals = 2) => {
    if (typeof value !== 'number' || isNaN(value)) {
        return '0.00';
    }
    return value.toFixed(decimals);
};

const formatCurrency = (value, decimals = 2) => {
    return `$${formatNumber(value, decimals)}`;
};

const ValueWithTooltip = ({ value, tooltip, format = 'number', decimals = 2 }) => (
    <OverlayTrigger
        placement="top"
        overlay={<Tooltip>{tooltip}</Tooltip>}
    >
        <span className="text-decoration-underline cursor-help">
            {format === 'currency' ? formatCurrency(value, decimals) : formatNumber(value, decimals)}
        </span>
    </OverlayTrigger>
);

const calculatePostSolarFixedCost = (rates) => {
    if (!rates) return 0;

    /*  console.log('Calculating post-solar fixed cost with rates:', {
         fixed_cost_type: rates.fixed_cost_type,
         net_billing_fixed_cost: rates.net_billing_fixed_cost,
         cost_cap: rates.cost_cap,
         fixed: rates.fixed,
         systemSize: rates.systemSize,
         cost_per_kw: rates.cost_per_kw
     }); */

    // If using constant cost type, use the net billing fixed cost or cost cap
    if (rates.fixed_cost_type === 'constant') {
        const cost = ensureNumber(rates.cost_cap || rates.net_billing_fixed_cost || rates.fixed);
        console.log('Using constant fixed cost:', cost);
        return cost;
    }

    // Calculate based on system size and cost per kW
    const costPerKw = ensureNumber(rates.cost_per_kw);
    const systemSize = ensureNumber(rates.systemSize);
    if (systemSize && costPerKw) {
        const calculatedCost = systemSize * costPerKw;
        const costCap = ensureNumber(rates.cost_cap || rates.net_billing_fixed_cost);

        /*  console.log('Calculated cost from system size:', {
             systemSize,
             costPerKw,
             calculatedCost,
             costCap,
             finalCost: costCap ? Math.min(calculatedCost, costCap) : calculatedCost
         }); */

        // If there's a cost cap, use the lower value
        return costCap ? Math.min(calculatedCost, costCap) : calculatedCost;
    }

    // Fallback to cost cap or original fixed cost
    const fallbackCost = ensureNumber(rates.cost_cap || rates.net_billing_fixed_cost || rates.fixed);
    console.log('Using fallback cost:', fallbackCost);
    return fallbackCost;
};

const MonthlyTable = ({ data, showYear = false }) => {
    // Add debugging for the first month's rates
    if (data.length > 0) {
        console.log('First month rates:', data[0].rates);
    }

    return (
        <div className="table-responsive">
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th rowSpan="2">Month</th>
                        <th colSpan="3">Solar Production</th>
                        <th colSpan="4">Consumption & Threshold</th>
                        <th colSpan="5">Billing</th>
                    </tr>
                    <tr>
                        <th>Production (kWh)</th>
                        <th>Self-Consumed (kWh)</th>
                        <th>Exported (kWh)</th>
                        <th>Total (kWh)</th>
                        <th>Threshold (%)</th>
                        <th>Max Offset (kWh)</th>
                        <th>Rate ($/kWh)</th>
                        <th>Pre-Solar Fixed ($)</th>
                        <th>Post-Solar Fixed ($)</th>
                        <th>Pre-Solar Bill ($)</th>
                        <th>Post-Solar Bill ($)</th>
                        <th>Net Savings ($)</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((month) => {
                        const threshold = month.rates?.threshold || 40;
                        const thresholdDecimal = threshold / 100;
                        const maxOffsetConsumption = month.consumption * thresholdDecimal;
                        const selfConsumed = Math.min(month.production, maxOffsetConsumption);
                        const exported = Math.max(0, month.production - selfConsumed);
                        const remainingConsumption = month.consumption - selfConsumed;

                        const preSolarFixedCost = month.rates.fixed;
                        const postSolarFixedCost = calculatePostSolarFixedCost(month.rates);

                        const preSolarBill = (month.consumption * month.rates.base) + preSolarFixedCost;
                        const postSolarEnergyCost = remainingConsumption * month.rates.base;
                        const exportCredit = exported * (month.rates.export || month.rates.base);
                        const postSolarBill = postSolarEnergyCost + postSolarFixedCost - exportCredit;

                        const fixedCostCalcExplanation = month.rates.fixed_cost_type === 'constant'
                            ? `Using constant fixed cost of ${formatCurrency(month.rates.cost_cap)}/month`
                            : month.rates.systemSize && month.rates.cost_per_kw
                                ? `Calculated as: min(System Size × Cost per kW, Cost Cap) = min(${formatNumber(month.rates.systemSize)} × $${formatNumber(month.rates.cost_per_kw)}, $${formatNumber(month.rates.cost_cap || 'N/A')})`
                                : `Using cost cap of ${formatCurrency(month.rates.cost_cap)}/month`;

                        return (
                            <tr key={`${month.year || 1}-${month.month}`}>
                                <td>{showYear ? `Year ${month.year} - ` : ''}{month.month}</td>
                                <td>
                                    <ValueWithTooltip
                                        value={month.production}
                                        tooltip="Total solar production for this month"
                                    />
                                </td>
                                <td>
                                    <ValueWithTooltip
                                        value={selfConsumed}
                                        tooltip={`Min(Production, Max Offset) = Min(${formatNumber(month.production)}, ${formatNumber(maxOffsetConsumption)})`}
                                    />
                                </td>
                                <td>
                                    <ValueWithTooltip
                                        value={exported}
                                        tooltip={`Production - Self-Consumed = ${formatNumber(month.production)} - ${formatNumber(selfConsumed)}`}
                                    />
                                </td>
                                <td>
                                    <ValueWithTooltip
                                        value={month.consumption}
                                        tooltip="Total energy consumption for this month"
                                    />
                                </td>
                                <td>
                                    <ValueWithTooltip
                                        value={threshold}
                                        tooltip="Maximum percentage of consumption that can be offset by solar"
                                    />
                                </td>
                                <td>
                                    <ValueWithTooltip
                                        value={maxOffsetConsumption}
                                        tooltip={`Consumption × Threshold = ${formatNumber(month.consumption)} × ${threshold}%`}
                                    />
                                </td>
                                <td>
                                    <ValueWithTooltip
                                        value={month.rates.base}
                                        tooltip="Base utility rate per kWh"
                                        decimals={4}
                                    />
                                </td>
                                <td>
                                    <ValueWithTooltip
                                        value={preSolarFixedCost}
                                        tooltip="Pre-solar monthly fixed charge"
                                        format="currency"
                                    />
                                </td>
                                <td>
                                    <ValueWithTooltip
                                        value={postSolarFixedCost}
                                        tooltip={fixedCostCalcExplanation}
                                        format="currency"
                                    />
                                </td>
                                <td>
                                    <ValueWithTooltip
                                        value={preSolarBill}
                                        tooltip={`(Consumption × Rate) + Fixed = (${formatNumber(month.consumption)} × $${formatNumber(month.rates.base, 4)}) + $${formatNumber(preSolarFixedCost)}`}
                                        format="currency"
                                    />
                                </td>
                                <td>
                                    <ValueWithTooltip
                                        value={postSolarBill}
                                        tooltip={`(Remaining × Rate) + Post-Solar Fixed - Export Credit = (${formatNumber(remainingConsumption)} × $${formatNumber(month.rates.base, 4)}) + $${formatNumber(postSolarFixedCost)} - $${formatNumber(exportCredit)}`}
                                        format="currency"
                                    />
                                </td>
                                <td>
                                    <ValueWithTooltip
                                        value={preSolarBill - postSolarBill}
                                        tooltip={`Pre-Solar Bill - Post-Solar Bill = $${formatNumber(preSolarBill)} - $${formatNumber(postSolarBill)}`}
                                        format="currency"
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <th>Monthly Average</th>
                        <td>{formatNumber(data.reduce((sum, m) => sum + m.production, 0) / data.length)}</td>
                        <td>{formatNumber(data.reduce((sum, m) => sum + Math.min(m.production, m.consumption * ((m.rates?.threshold || 40) / 100)), 0) / data.length)}</td>
                        <td>{formatNumber(data.reduce((sum, m) => {
                            const maxOffset = m.consumption * ((m.rates?.threshold || 40) / 100);
                            const selfConsumed = Math.min(m.production, maxOffset);
                            return sum + Math.max(0, m.production - selfConsumed);
                        }, 0) / data.length)}</td>
                        <td>{formatNumber(data.reduce((sum, m) => sum + m.consumption, 0) / data.length)}</td>
                        <td>-</td>
                        <td>{formatNumber(data.reduce((sum, m) => sum + (m.consumption * ((m.rates?.threshold || 40) / 100)), 0) / data.length)}</td>
                        <td>-</td>
                        <td>{formatCurrency(data.reduce((sum, m) => sum + m.rates.fixed, 0) / data.length)}</td>
                        <td>{formatCurrency(data.reduce((sum, m) => sum + calculatePostSolarFixedCost(m.rates), 0) / data.length)}</td>
                        <td>{formatCurrency(data.reduce((sum, m) => sum + ((m.consumption * m.rates.base) + m.rates.fixed), 0) / data.length)}</td>
                        <td>{formatCurrency(data.reduce((sum, m) => {
                            const threshold = (m.rates?.threshold || 40) / 100;
                            const maxOffset = m.consumption * threshold;
                            const selfConsumed = Math.min(m.production, maxOffset);
                            const remaining = m.consumption - selfConsumed;
                            const exported = Math.max(0, m.production - selfConsumed);
                            const postSolarFixed = calculatePostSolarFixedCost(m.rates);
                            return sum + ((remaining * m.rates.base) + postSolarFixed - (exported * (m.rates.export || m.rates.base)));
                        }, 0) / data.length)}</td>
                        <td>{formatCurrency(data.reduce((sum, m) => {
                            const threshold = (m.rates?.threshold || 40) / 100;
                            const maxOffset = m.consumption * threshold;
                            const selfConsumed = Math.min(m.production, maxOffset);
                            const remaining = m.consumption - selfConsumed;
                            const exported = Math.max(0, m.production - selfConsumed);
                            const preSolarBill = (m.consumption * m.rates.base) + m.rates.fixed;
                            const postSolarBill = (remaining * m.rates.base) + calculatePostSolarFixedCost(m.rates) - (exported * (m.rates.export || m.rates.base));
                            return sum + (preSolarBill - postSolarBill);
                        }, 0) / data.length)}</td>
                    </tr>
                </tfoot>
            </Table>
        </div>
    );
};

const YearlyTable = ({ data }) => {
    // Calculate cumulative savings for each year
    const dataWithCumulative = data.map((year, index) => ({
        ...year,
        cumulativeSavings: data
            .slice(0, index + 1)
            .reduce((sum, y) => sum + Math.abs(y.savings), 0)
    }));

    return (
        <div className="table-responsive">
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Year</th>
                        <th>Production (kWh)</th>
                        <th>Consumption (kWh)</th>
                        <th>Net Metered (kWh)</th>
                        <th>Annual Savings ($)</th>
                        <th>Cumulative Savings ($)</th>
                    </tr>
                </thead>
                <tbody>
                    {dataWithCumulative.map((year) => (
                        <tr key={year.year}>
                            <td>{year.year}</td>
                            <td>{formatNumber(year.totalProduction)}</td>
                            <td>{formatNumber(year.totalConsumption)}</td>
                            <td>{formatNumber(year.netMetered)}</td>
                            <td>{formatCurrency(Math.abs(year.savings))}</td>
                            <td>
                                <ValueWithTooltip
                                    value={year.cumulativeSavings}
                                    tooltip={`Total savings through year ${year.year}`}
                                    format="currency"
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

const SummaryCard = ({ title, value, unit }) => (
    <div className="card mb-3">
        <div className="card-body">
            <h6 className="card-subtitle mb-2 text-muted">{title}</h6>
            <h4 className="card-title">
                {value} {unit}
            </h4>
        </div>
    </div>
);

const MonthlyNetMeteringTable = ({ data, showYear = false }) => {
    const sortMonthsByTrueUp = (months, trueUpMonth = 1) => {
        // Ensure true-up month is a number and 1-based
        const normalizedTrueUpMonth = parseInt(trueUpMonth, 10) || 1; // Default to January (1) only if not specified

        console.log('NET_METERING: Starting true-up sort with:', {
            totalMonths: months.length,
            rawTrueUpMonth: trueUpMonth,
            normalizedTrueUpMonth,
            firstMonth: months[0],
            firstMonthRates: months[0]?.rates
        });

        // Group months by true-up period instead of calendar year
        const getTrueUpPeriod = (month, year) => {
            // If month is before true-up month, it belongs to previous year's period
            const period = month < normalizedTrueUpMonth ? year - 1 : year;
            console.log(`NET_METERING: Month ${month} Year ${year} belongs to true-up period:`, period);
            return period;
        };

        // Group by true-up periods
        const monthsByPeriod = months.reduce((acc, month) => {
            const trueUpPeriod = getTrueUpPeriod(month.month, month.year);
            if (!acc[trueUpPeriod]) acc[trueUpPeriod] = [];
            acc[trueUpPeriod].push({
                ...month,
                isAnnualTrueUp: parseInt(month.month, 10) === normalizedTrueUpMonth,
                rollingCredit: 0
            });
            return acc;
        }, {});

        console.log('NET_METERING: Grouped months by true-up period:', monthsByPeriod);

        // Process each true-up period
        const result = Object.entries(monthsByPeriod).flatMap(([period, periodMonths]) => {
            console.log(`\nNET_METERING: Processing true-up period ${period}`);

            // Sort months within period
            const sortedMonths = [...periodMonths].sort((a, b) => {
                // If months span across January, adjust for proper sorting
                const aMonth = a.month < normalizedTrueUpMonth ? a.month + 12 : a.month;
                const bMonth = b.month < normalizedTrueUpMonth ? b.month + 12 : b.month;
                return aMonth - bMonth;
            });

            console.log('NET_METERING: Sorted months in period:',
                sortedMonths.map(m => ({ month: m.month, production: m.production, consumption: m.consumption })));

            // Calculate rolling credits within period
            let rollingCredit = 0;
            return sortedMonths.map(month => {
                const netEnergy = (month.production || 0) - (month.consumption || 0);
                const currentRollingCredit = rollingCredit;

                console.log(`\nNET_METERING: Month ${month.month} calculations:`, {
                    production: month.production || 0,
                    consumption: month.consumption || 0,
                    netEnergy,
                    currentRollingCredit,
                    isAnnualTrueUp: month.isAnnualTrueUp,
                    rates: {
                        base: month.rates?.base,
                        export: month.rates?.export,
                        fixed: month.rates?.fixed,
                        postSolarFixedCharge: month.rates?.postSolarFixedCharge
                    }
                });

                // If this is true-up month, reset credits after storing them
                if (month.isAnnualTrueUp) {
                    const finalCredit = rollingCredit + netEnergy;
                    const fixedCharge = month.rates?.postSolarFixedCharge || 22;
                    const baseRate = month.rates?.base || 0;

                    console.log('NET_METERING: True-up month calculations:', {
                        finalCredit,
                        fixedCharge,
                        baseRate,
                        willResetTo: 0,
                        billIfShortage: finalCredit < 0 ?
                            fixedCharge + Math.abs(finalCredit) * baseRate :
                            fixedCharge
                    });

                    rollingCredit = 0; // Reset for next period
                    return {
                        ...month,
                        rollingCredit: finalCredit,
                        costs: {
                            fixed: fixedCharge,
                            energy: finalCredit < 0 ? Math.abs(finalCredit) * baseRate : 0,
                            total: finalCredit < 0 ?
                                fixedCharge + Math.abs(finalCredit) * baseRate :
                                fixedCharge
                        }
                    };
                }

                // Normal month within period
                rollingCredit += netEnergy;
                console.log('NET_METERING: Updated rolling credit:', rollingCredit);

                // Calculate post-solar bill
                const fixedCharge = month.rates?.postSolarFixedCharge || 22;
                const baseRate = month.rates?.base || 0;
                const currentCredit = rollingCredit;

                // For non-true-up months:
                // - If we have positive credits or zero, only pay fixed charge
                // - If we have negative credits, pay for the shortage plus fixed charge
                const energyCost = currentCredit < 0 ? Math.abs(currentCredit) * baseRate : 0;
                const totalCost = fixedCharge + energyCost;

                return {
                    ...month,
                    rollingCredit,
                    costs: {
                        fixed: fixedCharge,
                        energy: energyCost,
                        total: totalCost
                    }
                };
            });
        });

        console.log('\nNET_METERING: Final processed months:', result.map(m => ({
            month: m.month,
            year: m.year,
            production: m.production,
            consumption: m.consumption,
            netEnergy: m.production - m.consumption,
            rollingCredit: m.rollingCredit,
            isAnnualTrueUp: m.isAnnualTrueUp,
            costs: m.costs
        })));

        return result;
    };

    // Get true-up month from the rates data
    const trueUpMonth = data[0]?.rates?.true_up_month || data[0]?.rates?.trueUpMonth || 6; // Default to June (6)
    console.log('NET_METERING: Raw true-up month from rates:', {
        fromRates: data[0]?.rates?.true_up_month,
        fromRatesCamelCase: data[0]?.rates?.trueUpMonth,
        final: trueUpMonth,
        firstMonthData: data[0]
    });
    const sortedData = sortMonthsByTrueUp(data, trueUpMonth);

    return (
        <div className="table-responsive">
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th rowSpan="2">Month</th>
                        <th colSpan="2">Energy</th>
                        <th colSpan="2">Credits</th>
                        <th colSpan="3">Billing</th>
                    </tr>
                    <tr>
                        <th>Production (kWh)</th>
                        <th>Consumption (kWh)</th>
                        <th>Net Energy (kWh)</th>
                        <th>Rolling Credit (kWh)</th>
                        <th>Pre-Solar Bill ($)</th>
                        <th>Post-Solar Bill ($)</th>
                        <th>Savings ($)</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedData.map((month) => {
                        const rates = month.rates || {};
                        const costs = month.costs || {};

                        // Calculate bills using rates for the specific year
                        const baseRate = rates.base || 0;
                        const fixedCharge = rates.fixed || 0;
                        const preSolarBill = (month.consumption || 0) * baseRate + fixedCharge;
                        const postSolarBill = costs.total || rates.postSolarFixedCharge || 22; // Default to $22 for net metering
                        const savings = preSolarBill - postSolarBill;
                        const netEnergy = (month.production || 0) - (month.consumption || 0);

                        return (
                            <tr key={`${month.year || 1}-${month.month}`}>
                                <td>{showYear ? `Year ${month.year} - ` : ''}{month.month}</td>
                                <td>
                                    <ValueWithTooltip
                                        value={month.production || 0}
                                        tooltip="Solar production for this month"
                                    />
                                </td>
                                <td>
                                    <ValueWithTooltip
                                        value={month.consumption || 0}
                                        tooltip="Energy consumption for this month"
                                    />
                                </td>
                                <td>
                                    <ValueWithTooltip
                                        value={netEnergy}
                                        tooltip={`Production - Consumption = ${formatNumber(month.production || 0)} - ${formatNumber(month.consumption || 0)}`}
                                    />
                                </td>
                                <td>
                                    <ValueWithTooltip
                                        value={month.rollingCredit || 0}
                                        tooltip={month.isAnnualTrueUp ?
                                            "True-up month: Credits reset to 0" :
                                            "Accumulated credit balance (kWh). Resets on true-up month."}
                                    />
                                </td>
                                <td>
                                    <ValueWithTooltip
                                        value={preSolarBill}
                                        tooltip={`(Consumption × Rate) + Fixed = (${formatNumber(month.consumption || 0)} × $${formatNumber(baseRate, 4)}) + $${formatNumber(fixedCharge)}`}
                                        format="currency"
                                    />
                                </td>
                                <td>
                                    <ValueWithTooltip
                                        value={postSolarBill}
                                        tooltip={month.isAnnualTrueUp && (month.rollingCredit || 0) < 0 ?
                                            `Fixed Cost + Shortage Cost = $${formatNumber(costs.fixed || 0)} + $${formatNumber(costs.energy || 0)}` :
                                            `Fixed Cost Only = $${formatNumber(costs.fixed || 0)}`}
                                        format="currency"
                                    />
                                </td>
                                <td>
                                    <ValueWithTooltip
                                        value={savings}
                                        tooltip={`Pre-Solar Bill - Post-Solar Bill = $${formatNumber(preSolarBill)} - $${formatNumber(postSolarBill)}`}
                                        format="currency"
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <th>Annual Total</th>
                        <td>{formatNumber(sortedData.reduce((sum, m) => sum + (m.production || 0), 0))}</td>
                        <td>{formatNumber(sortedData.reduce((sum, m) => sum + (m.consumption || 0), 0))}</td>
                        <td>{formatNumber(sortedData.reduce((sum, m) => sum + ((m.production || 0) - (m.consumption || 0)), 0))}</td>
                        <td>-</td>
                        <td>{formatCurrency(sortedData.reduce((sum, m) => sum + ((m.consumption * (m.rates?.base || 0)) + (m.rates?.fixed || 0)), 0))}</td>
                        <td>{formatCurrency(sortedData.reduce((sum, m) => sum + ((m.costs?.total || 0) || (m.rates?.postSolarFixedCharge || 22)), 0))}</td>
                        <td>{formatCurrency(sortedData.reduce((sum, m) => {
                            const rates = m.rates || {};
                            const preSolarBill = (m.consumption || 0) * (rates.base || 0) + (rates.fixed || 0);
                            const postSolarBill = (m.costs?.total || 0) || (rates.postSolarFixedCharge || 22);
                            return sum + (preSolarBill - postSolarBill);
                        }, 0))}</td>
                    </tr>
                </tfoot>
            </Table>
        </div>
    );
};

const VerificationTable = ({ calculationResults, className = '' }) => {
    const [activeTab, setActiveTab] = useState('monthly');
    const [selectedYear, setSelectedYear] = useState(1);

    if (!calculationResults || !calculationResults.annual) {
        return <div>No calculation data available</div>;
    }

    const { metadata, annual, lifetime } = calculationResults;
    const rates = metadata?.utilityRates || {};
    const isNetMetering = metadata?.meteringType !== 'net_billing';

    const renderAllMonthlyData = () => {
        const allMonthsData = annual.flatMap(year =>
            year.months.map(month => ({
                ...month,
                year: year.year
            }))
        );

        return (
            <>
                <div className="alert alert-info">
                    {isNetMetering ? (
                        <>
                            <strong>Net Metering - Annual True-up Cycle:</strong>
                            <ul className="mb-0 mt-2">
                                <li>Credits accumulate month-to-month throughout the year</li>
                                <li>Only fixed costs ($22/month) are paid until true-up</li>
                                <li>At true-up (April):
                                    <ul>
                                        <li>If total production &ge; total consumption: Only pay fixed costs, excess credits are lost</li>
                                        <li>If total production &lt; total consumption: Pay for shortage at utility rate plus fixed costs</li>
                                    </ul>
                                </li>
                                <li>True-up period starts the month after April</li>
                                <li>Energy bank resets to zero after true-up</li>
                            </ul>
                        </>
                    ) : (
                        'Showing all months across 30 years. Use the yearly tab for a more focused view.'
                    )}
                </div>
                {isNetMetering ? (
                    <MonthlyNetMeteringTable data={allMonthsData} showYear={true} />
                ) : (
                    <MonthlyTable data={allMonthsData} showYear={true} />
                )}
            </>
        );
    };

    return (
        <div className={className}>
            <h3>{isNetMetering ? 'Net Metering' : 'Net Billing'} Calculations Verification</h3>

            <div className="row mb-4">
                <div className="col-md-3">
                    <SummaryCard
                        title="System Size"
                        value={formatNumber(metadata?.systemSize)}
                        unit="kW"
                    />
                </div>
                <div className="col-md-3">
                    <SummaryCard
                        title="Base Rate"
                        value={formatCurrency(rates?.base, 4)}
                        unit="/kWh"
                    />
                </div>
                <div className="col-md-3">
                    <SummaryCard
                        title="Export Rate"
                        value={formatCurrency(rates?.export, 4)}
                        unit="/kWh"
                    />
                </div>
                <div className="col-md-3">
                    <SummaryCard
                        title="Fixed Charge"
                        value={formatCurrency(rates?.fixedCharge)}
                        unit="/month"
                    />
                </div>
                {!isNetMetering && (
                    <>
                        <div className="col-md-3">
                            <SummaryCard
                                title="Post-Solar Fixed Cap"
                                value={formatCurrency(rates?.cost_cap)}
                                unit="/month"
                            />
                        </div>
                        <div className="col-md-3">
                            <SummaryCard
                                title="Fixed Cost Type"
                                value={rates?.fixed_cost_type || 'Not Set'}
                                unit=""
                            />
                        </div>
                        {rates?.cost_per_kw && (
                            <div className="col-md-3">
                                <SummaryCard
                                    title="Cost per kW"
                                    value={formatCurrency(rates?.cost_per_kw)}
                                    unit="/kW"
                                />
                            </div>
                        )}
                    </>
                )}
                {isNetMetering && metadata?.meteringPeriod === 'annual' && (
                    <div className="col-md-3">
                        <SummaryCard
                            title="True-up Month"
                            value={metadata?.trueUpMonth ? new Date(2000, metadata.trueUpMonth - 1).toLocaleString('default', { month: 'long' }) : 'April'}
                        />
                    </div>
                )}
            </div>

            {isNetMetering && (
                <div className="alert alert-info mb-4">
                    <strong>Net Metering Type:</strong> {metadata?.meteringPeriod === 'annual' ? 'Annual with True-up' : 'Monthly'}
                    {metadata?.meteringPeriod === 'annual' && (
                        <div>Credits accumulate until true-up month, then reset. {rates?.offsetSavings ? 'Excess generation beyond 100% offset is credited at export rate.' : 'Excess credits are forfeited at true-up.'}</div>
                    )}
                </div>
            )}

            <Tabs
                activeKey={activeTab}
                onSelect={(k) => setActiveTab(k)}
                className="mb-3"
            >
                <Tab eventKey="monthly" title="Monthly Details">
                    <div className="mb-3">
                        <select
                            className="form-select"
                            value={selectedYear}
                            onChange={(e) => setSelectedYear(Number(e.target.value))}
                        >
                            {annual.map(year => (
                                <option key={year.year} value={year.year}>
                                    Year {year.year}
                                </option>
                            ))}
                        </select>
                    </div>
                    {isNetMetering ? (
                        <MonthlyNetMeteringTable
                            data={annual.find(y => y.year === selectedYear).months}
                        />
                    ) : (
                        <MonthlyTable
                            data={annual.find(y => y.year === selectedYear).months}
                        />
                    )}
                </Tab>

                <Tab eventKey="summary" title="Lifetime Summary">
                    <div className="row mb-4">
                        <div className="col-md-6">
                            <h5>30-Year Totals</h5>
                            <Table bordered size="sm">
                                <tbody>
                                    <tr>
                                        <td>Total Production</td>
                                        <td>
                                            <ValueWithTooltip
                                                value={lifetime?.totalProduction}
                                                tooltip="Sum of all solar production over 30 years"
                                            /> kWh
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Total Consumption</td>
                                        <td>
                                            <ValueWithTooltip
                                                value={lifetime?.totalConsumption}
                                                tooltip="Sum of all energy consumption over 30 years"
                                            /> kWh
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Total Net Metered</td>
                                        <td>
                                            <ValueWithTooltip
                                                value={lifetime?.totalNetMetered}
                                                tooltip="Total Production - Total Consumption"
                                            /> kWh
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Total Savings</td>
                                        <td>
                                            <ValueWithTooltip
                                                value={Math.abs(lifetime?.totalSavings)}
                                                tooltip="Sum of all monthly savings over 30 years"
                                                format="currency"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <YearlyTable data={annual} />
                </Tab>

                <Tab eventKey="allMonthly" title="All Monthly Data">
                    {renderAllMonthlyData()}
                </Tab>
            </Tabs>
        </div>
    );
};

VerificationTable.propTypes = {
    calculationResults: PropTypes.shape({
        annual: PropTypes.arrayOf(PropTypes.shape({
            year: PropTypes.number.isRequired,
            totalProduction: PropTypes.number.isRequired,
            totalConsumption: PropTypes.number.isRequired,
            netMetered: PropTypes.number.isRequired,
            savings: PropTypes.number.isRequired,
            months: PropTypes.arrayOf(PropTypes.shape({
                month: PropTypes.number.isRequired,
                production: PropTypes.number.isRequired,
                consumption: PropTypes.number.isRequired,
                netMetered: PropTypes.number.isRequired,
                rates: PropTypes.shape({
                    base: PropTypes.number.isRequired,
                    fixed: PropTypes.number.isRequired
                }).isRequired,
                savings: PropTypes.number.isRequired
            })).isRequired
        })).isRequired,
        lifetime: PropTypes.shape({
            totalProduction: PropTypes.number.isRequired,
            totalConsumption: PropTypes.number.isRequired,
            totalNetMetered: PropTypes.number.isRequired,
            totalSavings: PropTypes.number.isRequired
        }).isRequired,
        metadata: PropTypes.shape({
            systemSize: PropTypes.number.isRequired,
            utilityRates: PropTypes.shape({
                base: PropTypes.number.isRequired,
                escalation: PropTypes.number.isRequired,
                fixedCharge: PropTypes.number.isRequired
            }).isRequired,
            calculationTimestamp: PropTypes.string.isRequired
        }).isRequired
    }).isRequired,
    className: PropTypes.string
};

export default VerificationTable; 