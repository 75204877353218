import React, {
  useState, useContext, useEffect, useRef, useMemo, forwardRef, useImperativeHandle,
} from 'react';
import { FaMountain, FaAngleDown, FaAngleUp, FaSolarPanel } from 'react-icons/fa';
import { BiSolidEditAlt } from 'react-icons/bi';
import { LiaDrawPolygonSolid } from 'react-icons/lia';
import { FiArrowDownRight, FiArrowUpRight } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { MdOutlineDraw, MdOutlinePlace } from 'react-icons/md';
import { VscCircleSmall, VscCircleFilled } from 'react-icons/vsc';
import { IoMagnetOutline } from 'react-icons/io5';
import { AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai';
import { HiAnnotation, HiOutlineAnnotation } from "react-icons/hi";
import {
  TbBrandCitymapper, TbArrowRotaryFirstRight, TbArrowRotaryRight, TbArrowRotaryStraight, TbDeselect, TbRulerMeasure,
} from 'react-icons/tb';
import { WiDegrees } from 'react-icons/wi';
import { LuUndo2, LuRedo2 } from "react-icons/lu";
import { IoCarOutline } from "react-icons/io5";
import { LiaNetworkWiredSolid } from "react-icons/lia";
import { PiRulerLight } from "react-icons/pi";

import { TbSunElectricity } from "react-icons/tb";
import { LiaYCombinator } from "react-icons/lia";
import { MdOutlineMedicalServices } from "react-icons/md";
import { LuThermometerSun } from "react-icons/lu";
import { LuUtilityPole } from "react-icons/lu";
import { AiOutlineQuestionCircle, AiOutlineDisconnect } from "react-icons/ai";
import { LuGalleryHorizontal } from "react-icons/lu";

import {
  ButtonToolbar, ButtonGroup, Button, DropdownButton, Dropdown, ProgressBar, Overlay,
} from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import * as Icon from 'react-bootstrap-icons';

import { useHotkeys } from 'react-hotkeys-hook';

// import resoultionDark from 'assets/icons/resolution-dark.png';
// import resoultionLight from 'assets/icons/resolution-light.png';

import Loading from 'components/Loading';
import PitchDropdown from '../Menu/RoofSegments/PitchDropdown';

import { DesignContext } from './contexts/designContext';

import {
  pitchMap,
} from './algorithms/geometry';

const MONTHS = [
  'Year',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const Toolbar = forwardRef((props, ref) => {
  const {
    switchAnnotation,
    clearAllSelection,
    setSelectionMode,
    moveRoofVertex,
    runOptimizer,
    setLineLabel,
    setSetback,
    deleteSelectedObjects,
    setPitch,
    setHeight,
    panelPlacement,
    toggleView,
    deletePanels,
    rotatePanels,
    alignHorizontal,
    alignVertical,
    downloadJSON,
    downloadDXF,
    segmentSelection,
    pointSelection,
    lineSelection,
    panelSelection,
    setbackSelection,
    showSunLight,
    showGround,
    showTrees,
    showSetbacks,
    showPanels,
    showRoofs,
    showShading,
    showDSM,
    showDSMHD,
    showDSMHouse,
    showMeasurements,
    moveSelection,
    insertObject,
    startShading,
    month,
    shadingType,
    setMonth,
    setShadingType,
    visible,
    accordion,
    isLoaded,
    loadingTitle,
    readonly,
    viewerMode,
    changeClockwise,
    optimizer1IsRunning,
    optimizer2IsRunning,
    edgeDetectionIsRunning,
    measureIsRunning,
    panelPlacementIsRunning,
    serverSideShadingIsRunning,
    AIIsRunning,
    viewMode,
    setViewMode,
    iframe,
    designFrame,
    togglePanelSelection,
    toggleVisibility,
    defaultBtnView,
    mode,
    moveMode,
    setMode,
    setMoveMode,
    drawingCapabilities,
    setDrawingCapabilities,
    zoomCamera,
    mapsLink,
    undo,
    redo,
    viewState,
    setViewState,
  } = useContext(DesignContext);

  const openMapLink = () => {
    const link = mapsLink();
    window.open(link, '_blank');
  };

  const toggleDrawingCapabilities = (capability) => {
    let newCapabilities = [...drawingCapabilities];
    if (drawingCapabilities.includes(capability)) {
      newCapabilities = drawingCapabilities.filter((d) => d !== capability);
    } else {
      newCapabilities.push(capability);
    }
    setDrawingCapabilities(newCapabilities);
  };

  const toolReadonly = (readonly || iframe) && !designFrame;

  const isSegments = accordion === 'segments';
  const isObstacles = accordion === 'obstacles';
  const isSetback = accordion === 'setback';
  const isShading = accordion === 'shading';
  const isDesign = accordion === 'design';
  const isTrees = accordion === 'trees';
  const isRevise = accordion === 'revise';
  const isAnnotation = accordion === 'annotations';
  const isInteractiveAnnotation = isAnnotation && ["driveway", "wiring", "meter"].includes(viewState);

  const self = useRef();
  const popupPitch = useRef();
  const popupHeight = useRef();
  const popupSetback = useRef();

  const [showPopupPitch, setShowPopupPitch] = useState(false);
  const [showPopupHeight, setShowPopupHeight] = useState(false);
  const [showPopupSetback, setShowPopupSetback] = useState(false);

  const [toolbarWidth, setToolbarWidth] = useState(0);
  const [progress, setProgress] = useState(0);

  const validSize = toolbarWidth > 750;

  const spinnerTextMap = {
    'Shading.Failed': 'Shading Analysis Failed',
    'Shading.Loaded': 'Shading Analysis Ready',
    'Shading.None': '',
    'DSM.Loaded': 'DSM Data Loaded',
    'DSM.Failed': 'Loading DSM Data Failed',
    'RGB.Loaded': 'RGB Image Loaded',
    'RGB.Failed': 'Loading RGB Image Failed',
    'Irradiance.Loaded': 'Irradiance Data Loaded',
    'Irradiance.Failed': 'Loading Irradiance Data Failed',
    Optimize: 'Optimizing',
    Measure: 'Measuring',
    'Edge Labeling': 'Edge Labeling',
    PanelPlacement: 'Panel Placement',
  };

  useImperativeHandle(ref, () => ({
    progress: (percent) => {
      setProgress(percent);
    },
    isCanceledShading: () => progress === -1,
    isRunningShading: () => progress > 0 && progress < 100,
  }));

  useHotkeys('s', (e) => { if (!visible || toolReadonly) return; e.preventDefault(); clearAllSelection(); }, [clearAllSelection, visible]);
  useHotkeys('v', (e) => { if (!visible || toolReadonly) return; e.preventDefault(); setSelectionMode('point'); }, [setSelectionMode, visible]);
  useHotkeys('e', (e) => { if (!visible || toolReadonly) return; e.preventDefault(); setSelectionMode('line'); }, [setSelectionMode, visible]);
  useHotkeys('r', (e) => { if (!visible || toolReadonly) return; e.preventDefault(); setSelectionMode('segment'); }, [setSelectionMode, visible]);
  useHotkeys('f', (e) => { if (!visible || toolReadonly) return; e.preventDefault(); setSelectionMode('setbacks'); }, [setSelectionMode, visible]);
  useHotkeys('p', (e) => { if (!visible || toolReadonly) return; e.preventDefault(); setSelectionMode('panels'); }, [setSelectionMode, visible]);
  // useHotkeys('m', (e) => { e.preventDefault(); }); //?
  useHotkeys('o', (e) => { if (!visible || toolReadonly) return; e.preventDefault(); if (pointSelection) runOptimizer(); }, [pointSelection, runOptimizer, visible]);
  // useHotkeys('e', (e) => { e.preventDefault(); if (lineSelection) opendropdown(setLineLabel); }); //?
  // useHotkeys('f', (e) => { e.preventDefault(); if (setbackSelection) opendropdown(setSetbacks); }); //?
  // useHotkeys('p', (e) => { e.preventDefault(); if (segmentSelection) opendropdown(setPitch); });
  // useHotkeys('h', (e) => { e.preventDefault(); if (segmentSelection) opendropdown(setHeight); });
  useHotkeys('up', (e) => { if (!visible || toolReadonly) return; e.preventDefault(); if (segmentSelection) setHeight('+'); }, [segmentSelection, setHeight, visible]);
  useHotkeys('down', (e) => { if (!visible || toolReadonly) return; e.preventDefault(); if (segmentSelection) setHeight('-'); }, [segmentSelection, setHeight, visible]);
  useHotkeys('l', (e) => { if (!visible || toolReadonly) return; e.preventDefault(); if (segmentSelection) panelPlacement(); }, [segmentSelection, panelPlacement, visible]);
  useHotkeys('num_add', (e) => { if (!visible || toolReadonly) return; e.preventDefault(); if (panelSelection) toggleView('insert'); }, [panelSelection, toggleView, visible]);
  useHotkeys('delete', (e) => {
    if (!visible || toolReadonly) return;
    e.preventDefault();
    if (panelSelection) deletePanels();
    if (segmentSelection) deleteSelectedObjects();
  }, [panelSelection, segmentSelection, deletePanels, deleteSelectedObjects, visible]);
  useHotkeys('x', (e) => { if (!visible || toolReadonly) return; e.preventDefault(); if (panelSelection) rotatePanels(); }, [panelSelection, rotatePanels, visible]);
  useHotkeys('a', (e) => { if (!visible || toolReadonly) return; e.preventDefault(); alignHorizontal(); }, [alignHorizontal, visible]);
  useHotkeys('q', (e) => { if (!visible || toolReadonly) return; e.preventDefault(); alignVertical(); }, [alignVertical, visible]);
  useHotkeys('m', (e) => { if (!visible || toolReadonly) return; e.preventDefault(); if (panelSelection) toggleView('move'); }, [panelSelection, toggleView, visible]);
  useHotkeys('j', (e) => { if (!visible || toolReadonly) return; e.preventDefault(); downloadJSON(); }, [downloadJSON]);
  useHotkeys('k', (e) => { if (!visible || toolReadonly) return; e.preventDefault(); if (showShading) startShading(shadingType); }, [showShading, startShading, shadingType, visible]);

  useHotkeys('f1', (e) => { if (!visible) return; e.preventDefault(); toggleView('sun'); }, [toggleView, visible]);
  useHotkeys('f2', (e) => { if (!visible) return; e.preventDefault(); toggleView('texture'); }, [toggleView, visible]);
  useHotkeys('f3', (e) => { if (!visible) return; e.preventDefault(); toggleView('trees'); }, [toggleView, visible]);
  useHotkeys('f4', (e) => { if (!visible) return; e.preventDefault(); toggleView('setbacks'); }, [toggleView, visible]);
  useHotkeys('f5', (e) => { if (!visible) return; e.preventDefault(); toggleView('panels'); }, [toggleView, visible]);
  useHotkeys('f6', (e) => { if (!visible) return; e.preventDefault(); toggleView('roofs'); }, [toggleView, visible]);
  useHotkeys('f7', (e) => { if (!visible) return; e.preventDefault(); toggleView('shading'); }, [toggleView, visible]);
  useHotkeys('f8', (e) => { if (!visible) return; e.preventDefault(); toggleView('dsm'); }, [toggleView, visible]);
  useHotkeys('f9', (e) => { if (!visible) return; e.preventDefault(); toggleView('measurements'); }, [toggleView, visible]);

  useHotkeys('.', (e) => { if (!visible) return; e.preventDefault(); setViewMode(viewMode === '3D' ? '2D' : '3D'); }, [viewMode, visible]);
  useHotkeys('alt+f', (e) => { if (!visible) return; e.preventDefault(); toggleVisibility(); }, [viewMode, visible]);
  useHotkeys('alt+p', (e) => { if (!visible) return; e.preventDefault(); togglePanelSelection(); }, [viewMode, visible]);
  useHotkeys('alt+x', (e) => { if (!visible) return; e.preventDefault(); downloadDXF(); }, [downloadDXF]);

  useHotkeys('ctrl+z', (e) => { if (!visible || toolReadonly) return; e.preventDefault(); undo(); }, [visible, toolReadonly, undo]);
  useHotkeys('ctrl+y', (e) => { if (!visible || toolReadonly) return; e.preventDefault(); redo(); }, [visible, toolReadonly, redo]);

  useHotkeys('esc', (e) => {
    if (!visible || toolReadonly) return;
    e.preventDefault();
    if (panelSelection && insertObject) toggleView('insert');
    else if (panelSelection && moveSelection) toggleView('move');
    else clearAllSelection(true);
  }, [panelSelection, insertObject, moveSelection, toggleView, visible]);

  const togglePopup = (type) => {
    if (type === 'pitch') setShowPopupPitch((old) => !old);
    else if (type === 'height') setShowPopupHeight((old) => !old);
    else if (type === 'setback') setShowPopupSetback((old) => !old);
  };

  let spinnerTitle = loadingTitle;
  if (optimizer1IsRunning || optimizer2IsRunning) spinnerTitle = 'Optimize';
  if (edgeDetectionIsRunning) spinnerTitle = 'Edge Labeling';
  if (measureIsRunning) spinnerTitle = 'Measure';
  if (panelPlacementIsRunning) spinnerTitle = 'Panel Placement';
  if (serverSideShadingIsRunning) spinnerTitle = 'Shading Simulation';
  if (AIIsRunning) spinnerTitle = 'Running AI';

  useEffect(() => {
    function handleResize() {
      setToolbarWidth(self.current?.offsetWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [self.current]);

  return (
    <div ref={self}>
      <div className="fixed-top">
        {(!isLoaded || optimizer1IsRunning || optimizer2IsRunning || edgeDetectionIsRunning || measureIsRunning || panelPlacementIsRunning|| serverSideShadingIsRunning || AIIsRunning) && <Loading title={spinnerTextMap[spinnerTitle]} />}
      </div>
      {(showShading || showSunLight) && !(progress > 0 && progress < 100) ? (
        <ButtonToolbar size="sm" className="position-absolute" style={{ left: 0, right: 0, justifyContent: 'center', zIndex: 3 }}>
          <ButtonGroup size="sm" className="ml-auto mt-1">
            {showShading ? (
              <>
                <DropdownButton
                  size="sm"
                  className="border border-dark px-0"
                  variant="light"
                  title={shadingType === 'Solar Access' ? 'SA' : shadingType}
                >
                  <Dropdown.Item onClick={() => { setShadingType('TSRF'); }}>TSRF</Dropdown.Item>
                  <Dropdown.Item onClick={() => { setShadingType('TOF'); }}>TOF</Dropdown.Item>
                  <Dropdown.Item onClick={() => { setShadingType('Solar Access'); }}>Solar Access</Dropdown.Item>
                </DropdownButton>
                {/* <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Calculate shading
                      <br />
                      Shortcut: K
                    </Tooltip>
                      )}
                >
                  <Button
                    size="sm"
                    className="border border-dark"
                    variant="light"
                    onClick={() => {
                      if (progress > 0 && progress < 100) {
                        setProgress(-1);
                      } else {
                        setProgress(0);
                        startShading(shadingType);
                      }
                    }}
                  >
                    {progress > 0 && progress < 100 ? (<Icon.StopFill />) : (<Icon.PlayFill />)}
                  </Button>
                </OverlayTrigger> */}
              </>
            ) : null}
          </ButtonGroup>
          <ButtonGroup size="sm" className="mr-auto mt-1" style={{ marginLeft: 4 }}>
            <DropdownButton
              size="sm"
              className="border border-dark px-0"
              variant="light"
              title={MONTHS[month]}
            >
              <Dropdown.Item onClick={() => setMonth(1)}>Jan</Dropdown.Item>
              <Dropdown.Item onClick={() => setMonth(2)}>Feb</Dropdown.Item>
              <Dropdown.Item onClick={() => setMonth(3)}>Mar</Dropdown.Item>
              <Dropdown.Item onClick={() => setMonth(4)}>Apr</Dropdown.Item>
              <Dropdown.Item onClick={() => setMonth(5)}>May</Dropdown.Item>
              <Dropdown.Item onClick={() => setMonth(6)}>June</Dropdown.Item>
              <Dropdown.Item onClick={() => setMonth(7)}>July</Dropdown.Item>
              <Dropdown.Item onClick={() => setMonth(8)}>Aug</Dropdown.Item>
              <Dropdown.Item onClick={() => setMonth(9)}>Sept</Dropdown.Item>
              <Dropdown.Item onClick={() => setMonth(10)}>Oct</Dropdown.Item>
              <Dropdown.Item onClick={() => setMonth(11)}>Nov</Dropdown.Item>
              <Dropdown.Item onClick={() => setMonth(12)}>Dec</Dropdown.Item>
              <Dropdown.Item onClick={() => setMonth(0)}>Year</Dropdown.Item>
            </DropdownButton>
          </ButtonGroup>
        </ButtonToolbar>
      ) : null}
      {showShading && progress > 0 && progress < 100 ? (
        <ButtonToolbar size="sm" className="position-absolute" style={{ left: 0, right: 0, top: 11, justifyContent: 'center', zIndex: 3, visibility: (visible ? 'visible' : 'collapse') }}>
          <ProgressBar title={MONTHS[month]} animated now={progress} style={{ width: '540px', height: '15px' }} />
        </ButtonToolbar>
      ) : null}
      {!toolReadonly && (
        <ButtonGroup vertical size="sm" className="position-absolute top-0 start-0" style={{ zIndex: 3, visibility: (visible ? 'visible' : 'collapse') }}>
          {isAnnotation && (
            <>
              <ButtonGroup vertical size="sm" className="ml-2 mt-1">
                <>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 500 }}
                    overlay={(
                      <Tooltip>
                        Annotation Mode
                        <br />
                        Shortcut: W
                      </Tooltip>
                    )}
                  >
                    <Button size="sm" className="border border-dark" variant={viewState === 'annotations' ? 'dark' : 'light'} onClick={() => setViewState('annotations')}><HiOutlineAnnotation /></Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 500 }}
                    overlay={(
                      <Tooltip>
                        DriveWay Mode
                        <br />
                        Shortcut: W
                      </Tooltip>
                    )}
                  >
                    <Button size="sm" className="border border-dark" variant={viewState === 'driveway' ? 'dark' : 'light'} onClick={() => setViewState('driveway')}><IoCarOutline /></Button>
                  </OverlayTrigger>
                  {/* <OverlayTrigger
                    placement="right"
                    delay={{ show: 500 }}
                    overlay={(
                      <Tooltip>
                        Wiring Mode
                        <br />
                        Shortcut: W
                      </Tooltip>
                    )}
                  >
                    <Button size="sm" className="border border-dark" variant={viewState === 'wiring' ? 'dark' : 'light'} onClick={() => setViewState('wiring')}><LiaNetworkWiredSolid /></Button>
                  </OverlayTrigger> */}
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 500 }}
                    overlay={(
                      <Tooltip>
                        Meters
                        <br />
                        Shortcut: W
                      </Tooltip>
                    )}
                  >
                    <Button size="sm" className="border border-dark" variant={viewState === 'meter' ? 'dark' : 'light'} onClick={() => setViewState('meter')}><PiRulerLight /></Button>
                  </OverlayTrigger>
                  {/* <OverlayTrigger
                    placement="right"
                    delay={{ show: 500 }}
                    overlay={(
                      <Tooltip>
                        Download JSON
                        <br />
                        Shortcut: J
                      </Tooltip>
                        )}
                  >
                    <Button size="sm" className="border border-dark" variant="light" onClick={downloadJSON}><Icon.FileCode /></Button>
                  </OverlayTrigger> */}
                </>
              </ButtonGroup>
              {(viewState === 'annotations') && (
                <ButtonGroup vertical size="sm" className="ml-2 mt-1">
                  <>
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 500 }}
                      overlay={(
                        <Tooltip>
                          Custom Annotation
                          <br />
                          Shortcut: W
                        </Tooltip>
                      )}
                    >
                      <Button size="sm" className="border border-dark" variant={mode === 'custom' ? 'dark' : 'light'} onClick={() => setMode('custom')}><AiOutlineQuestionCircle /></Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 500 }}
                      overlay={(
                        <Tooltip>
                          Inverter
                          <br />
                          Shortcut: W
                        </Tooltip>
                      )}
                    >
                      <Button size="sm" className="border border-dark" variant={mode === 'inverter' ? 'dark' : 'light'} onClick={() => setMode('inverter')}><TbSunElectricity /></Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 500 }}
                      overlay={(
                        <Tooltip>
                          Combiner panel
                          <br />
                          Shortcut: W
                        </Tooltip>
                      )}
                    >
                      <Button size="sm" className="border border-dark" variant={mode === 'combiner_panel' ? 'dark' : 'light'} onClick={() => setMode('combiner_panel')}><LiaYCombinator /></Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 500 }}
                      overlay={(
                        <Tooltip>
                          Main Service Panel
                          <br />
                          Shortcut: W
                        </Tooltip>
                      )}
                    >
                      <Button size="sm" className="border border-dark" variant={mode === 'service_panel' ? 'dark' : 'light'} onClick={() => setMode('service_panel')}><MdOutlineMedicalServices /></Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 500 }}
                      overlay={(
                        <Tooltip>
                          Production Meter
                          <br />
                          Shortcut: W
                        </Tooltip>
                      )}
                    >
                      <Button size="sm" className="border border-dark" variant={mode === 'production_meter' ? 'dark' : 'light'} onClick={() => setMode('production_meter')}><LuThermometerSun /></Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 500 }}
                      overlay={(
                        <Tooltip>
                          Utility Meter
                          <br />
                          Shortcut: W
                        </Tooltip>
                      )}
                    >
                      <Button size="sm" className="border border-dark" variant={mode === 'utility_meter' ? 'dark' : 'light'} onClick={() => setMode('utility_meter')}><LuUtilityPole /></Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 500 }}
                      overlay={(
                        <Tooltip>
                          Disconnect
                          <br />
                          Shortcut: W
                        </Tooltip>
                      )}
                    >
                      <Button size="sm" className="border border-dark" variant={mode === 'disconnect' ? 'dark' : 'light'} onClick={() => setMode('disconnect')}><AiOutlineDisconnect /></Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 500 }}
                      overlay={(
                        <Tooltip>
                          Sub Panel
                          <br />
                          Shortcut: W
                        </Tooltip>
                      )}
                    >
                      <Button size="sm" className="border border-dark" variant={mode === 'sub_panel' ? 'dark' : 'light'} onClick={() => setMode('sub_panel')}><LuGalleryHorizontal /></Button>
                    </OverlayTrigger>
                  </>
                </ButtonGroup>
              )}
            </>
          )}
          {(isSegments || isObstacles || isTrees || isInteractiveAnnotation) && (
            <ButtonGroup vertical size="sm" className="ml-2 mt-1">
              <>
                {!isAnnotation && (
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 500 }}
                    overlay={(
                      <Tooltip>
                        Selecting Mode
                        <br />
                        Shortcut: S
                      </Tooltip>
                    )}
                  >
                    <Button size="sm" className="border border-dark" variant={mode === 'select' ? 'dark' : 'light'} onClick={() => setMode('select')}><Icon.Box /></Button>
                  </OverlayTrigger>
                )}
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Drawing Mode
                      <br />
                      Shortcut: D
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant={mode === 'draw' ? 'dark' : 'light'} onClick={() => setMode('draw')}><MdOutlineDraw /></Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Editing Mode
                      <br />
                      Shortcut: E
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant={mode === 'edit' ? 'dark' : 'light'} onClick={() => setMode('edit')}><BiSolidEditAlt /></Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Removing Mode
                      <br />
                      Shortcut: R
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant={mode === 'delete' ? 'dark' : 'light'} onClick={() => setMode('delete')}><RiDeleteBinLine /></Button>
                </OverlayTrigger>
              </>
            </ButtonGroup>
          )}
          {(isSegments || isObstacles || isTrees || isInteractiveAnnotation) && (mode === 'edit') && (
            <ButtonGroup vertical size="sm" className="ml-2 mt-1">
              <>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Single Movement
                      <br />
                      Shortcut: Alt+S
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant={moveMode === 'single' ? 'dark' : 'light'} onClick={() => setMoveMode('single')}><VscCircleSmall /></Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Group Movement
                      <br />
                      Shortcut: Alt+G
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant={moveMode === 'group' ? 'dark' : 'light'} onClick={() => setMoveMode('group')}><VscCircleFilled /></Button>
                </OverlayTrigger>
              </>
            </ButtonGroup>
          )}
          {(isSegments || isObstacles || isTrees || isInteractiveAnnotation) && (mode === 'draw' || mode === 'edit') && (
            <ButtonGroup vertical size="sm" className="ml-2 mt-1">
              <>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Guide lines
                      <br />
                      Shortcut: G
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant={drawingCapabilities.includes('snaps') ? 'dark' : 'light'} onClick={() => toggleDrawingCapabilities('snaps')}><TbBrandCitymapper /></Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Magnetization
                      <br />
                      Shortcut: M
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant={drawingCapabilities.includes('magnetize') ? 'dark' : 'light'} onClick={() => toggleDrawingCapabilities('magnetize')}><IoMagnetOutline /></Button>
                </OverlayTrigger>
              </>
            </ButtonGroup>
          )}
          {(isSegments || isObstacles || isTrees || isInteractiveAnnotation) && drawingCapabilities.includes('snaps') && (mode === 'draw' || mode === 'edit') && (
            <ButtonGroup vertical size="sm" className="ml-2 mt-1">
              <>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Guide lines 45°
                      <br />
                      Shortcut: Alt+8
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant={drawingCapabilities.includes('snap45') ? 'dark' : 'light'} onClick={() => toggleDrawingCapabilities('snap45')}><TbArrowRotaryFirstRight color="yellow" /></Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Guide lines 90°
                      <br />
                      Shortcut: Alt+9
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant={drawingCapabilities.includes('snap90') ? 'dark' : 'light'} onClick={() => toggleDrawingCapabilities('snap90')}><TbArrowRotaryRight color="red" /></Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Guide lines 180°
                      <br />
                      Shortcut: Alt+0
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant={drawingCapabilities.includes('snap180') ? 'dark' : 'light'} onClick={() => toggleDrawingCapabilities('snap180')}><TbArrowRotaryStraight /></Button>
                </OverlayTrigger>
              </>
            </ButtonGroup>
          )}
          {isSegments && (mode === 'select') && (
            <ButtonGroup vertical size="sm" className="ml-2 mt-1">
              <>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Select/deselect all stuff
                      <br />
                      Shortcut: S
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant="light" onClick={() => clearAllSelection()}><TbDeselect /></Button>
                </OverlayTrigger>
              </>
            </ButtonGroup>
          )}
          {(isSetback || isDesign || isRevise) && (
            <ButtonGroup vertical size="sm" className="ml-2 mt-1">
              {isSetback && (
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Fire setback selection mode
                      <br />
                      Shortcut: F
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant={setbackSelection ? 'dark' : 'light'} onClick={() => setSelectionMode('setbacks')}><Icon.Exclude /></Button>
                </OverlayTrigger>
              )}
              {(isDesign || isRevise) && (
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Panel selection mode
                      <br />
                      Shortcut: P
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant={panelSelection ? 'dark' : 'light'} onClick={() => setSelectionMode('panels')}><Icon.Grid3x3Gap /></Button>
                </OverlayTrigger>
              )}
            </ButtonGroup>
          )}
          <ButtonGroup vertical size="sm" className="mx-2 mt-1">
            {segmentSelection && isSegments && (mode === 'select') ? (
              <>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Adjust the pitch of the roof segment
                      <br />
                      Shortcut: P
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant={showPopupPitch ? 'dark' : 'light'} ref={popupPitch} onClick={() => togglePopup('pitch')}><Icon.Arrow90degUp /></Button>
                </OverlayTrigger>
                <Overlay target={popupPitch.current} show={showPopupPitch} placement="right">
                  <div className="border border-dark bg-light" style={{ width: '100px' }}>
                    <PitchDropdown
                      degrees
                      noMargin
                      pitches={pitchMap}
                      segIdx={-1}
                      segmentPitchAndAreaHandler={(segIdx, val) => setPitch(val, 0)}
                    />
                  </div>
                </Overlay>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Adjust the height of the roof segment
                      <br />
                      Shortcut: H
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant={showPopupHeight ? 'dark' : 'light'} ref={popupHeight} onClick={() => togglePopup('height')}><Icon.ArrowBarUp /></Button>
                </OverlayTrigger>
                <Overlay target={popupHeight.current} show={showPopupHeight} placement="right">
                  <div className="border border-dark bg-light" style={{ width: '100px' }}>
                    <PitchDropdown
                      degrees
                      noMargin
                      pitches={[...Array(15).keys()].map((height) => height * 5)}
                      segIdx={-1}
                      segmentPitchAndAreaHandler={(segIdx, val) => setHeight(val)}
                    />
                  </div>
                </Overlay>
                {/* <DropdownButton size="sm" className="border border-dark" variant="light" as={ButtonGroup} title={<Icon.ArrowBarUp />}>
                  {[...Array(15).keys()].map((height) => (<Dropdown.Item onClick={() => setHeight(height * 5)} eventKey={height * 5}>{`${height * 5} ft`}</Dropdown.Item>))}
                </DropdownButton> */}
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Increase the height of the roof segment
                      <br />
                      Shortcut: Up
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant="light" onClick={() => setHeight('+')}><Icon.LayerForward /></Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Decrease the height of the roof segment
                      <br />
                      Shortcut: Down
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant="light" onClick={() => setHeight('-')}><Icon.LayerBackward /></Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Increase the pitch of the roof segment
                      <br />
                      Shortcut: Ctrl + Up
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant="light" onClick={() => setPitch('+')}><FiArrowUpRight /></Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Decrease the pitch of the roof segment
                      <br />
                      Shortcut: Ctrl + Down
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant="light" onClick={() => setPitch('-')}><FiArrowDownRight /></Button>
                </OverlayTrigger>
                {showPanels && (
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 500 }}
                    overlay={(
                      <Tooltip>
                        Running the panel placement
                        <br />
                        Shortcut: L
                      </Tooltip>
                    )}
                  >
                    <Button size="sm" className="border border-dark" variant="light" onClick={() => panelPlacement()}><Icon.Screwdriver /></Button>
                  </OverlayTrigger>
                )}
              </>
            ) : null}
            {pointSelection && isSegments ? (
              <>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Move vertex
                      <br />
                      Shortcut: M
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant="light" onClick={() => moveRoofVertex(-3, 0)}><Icon.ArrowLeft /></Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Move vertex
                      <br />
                      Shortcut: M
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant="light" onClick={() => moveRoofVertex(+3, 0)}><Icon.ArrowRight /></Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Move vertex
                      <br />
                      Shortcut: M
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant="light" onClick={() => moveRoofVertex(0, +3)}><Icon.ArrowUp /></Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Move vertex
                      <br />
                      Shortcut: M
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant="light" onClick={() => moveRoofVertex(0, -3)}><Icon.ArrowDown /></Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Optimization to adjust the position of the vertices
                      <br />
                      Shortcut: O
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant="light" onClick={() => runOptimizer()}><Icon.Calculator /></Button>
                </OverlayTrigger>
              </>
            ) : null}
            {lineSelection && isSegments ? (
              <OverlayTrigger
                placement="right"
                delay={{ show: 500 }}
                overlay={(
                  <Tooltip>
                    Set edge type
                    <br />
                    Shortcut: E
                  </Tooltip>
                )}
              >
                <DropdownButton size="sm" className="border border-dark" variant="light" as={ButtonGroup} title={<Icon.Tag />}>
                  {[['Eave', 'Green'], ['Ridge', 'Red'], ['Rake', 'DarkSlateBlue'], ['Hip', 'Aqua'], ['Valley', 'DarkOrchid']]
                    .map(([type, color]) => (
                      <Dropdown.Item onClick={() => setLineLabel(type)} eventKey={type} style={{ backgroundColor: color, color: 'white' }}>
                        {type}
                      </Dropdown.Item>
                    ))}
                </DropdownButton>
              </OverlayTrigger>
            ) : null}
            {setbackSelection && isSetback ? (
              <OverlayTrigger
                placement="right"
                delay={{ show: 500 }}
                overlay={(
                  <Tooltip>
                    Set fire setback length
                    <br />
                    Shortcut: F
                  </Tooltip>
                )}
              >
                <>
                  <Button size="sm" className="border border-dark" variant={showPopupSetback ? 'dark' : 'light'} ref={popupSetback} onClick={() => togglePopup('setback')}><Icon.ArrowBarUp /></Button>
                  <Overlay target={popupSetback.current} show={showPopupSetback} placement="right">
                    <div className="border border-dark bg-light" style={{ width: '100px' }}>
                      <PitchDropdown
                        degrees
                        noMargin
                        pitches={[...Array(15).keys()].map((length) => `${length * 5} inche`)}
                        segIdx={-1}
                        segmentPitchAndAreaHandler={(segIdx, val) => setSetback(Number(String(val).replace(' inche', '')), changeClockwise)}
                      />
                    </div>
                  </Overlay>
                </>
                {/* <DropdownButton size="sm" className="border border-dark" variant="light" as={ButtonGroup} title={<Icon.ArrowBarUp />}>
                  {[...Array(15).keys()].map((length) => (<Dropdown.Item onClick={() => setSetback(length * 5, changeClockwise)}>{`${length * 5} inche`}</Dropdown.Item>))}
                </DropdownButton> */}
              </OverlayTrigger>
            ) : null}
            {(panelSelection && isRevise) ? (
              <>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Inserting a new panel
                      <br />
                      Shortcut: +
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant={insertObject ? 'dark' : 'light'} onClick={() => toggleView('insert')}><Icon.FilePlus /></Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Deleting the selected panel
                      <br />
                      Shortcut: Delete
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant="light" onClick={() => deletePanels()}><Icon.FileX /></Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Rotate the selected panel
                      <br />
                      Shortcut: X
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant="light" onClick={() => rotatePanels()}><Icon.ArrowRepeat /></Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Align selected panel horizontaly
                      <br />
                      Shortcut: A
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant="light" onClick={() => alignHorizontal()}><Icon.AlignCenter /></Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Align selected panel verticaly
                      <br />
                      Shortcut: Q
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant="light" onClick={() => alignVertical()}><Icon.AlignMiddle /></Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Moving the selected panel
                      <br />
                      Shortcut: M
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant={moveSelection ? 'dark' : 'light'} onClick={() => toggleView('move')}><Icon.ArrowsMove /></Button>
                </OverlayTrigger>
              </>
            ) : null}
          </ButtonGroup>
          {(isSegments || isObstacles || isTrees || isSetback) && (
            <ButtonGroup vertical size="sm" className="ml-2 mt-1">
              <>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Undo
                      <br />
                      Shortcut: Ctrl+Z
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant='light' onClick={() => undo()}><LuUndo2 /></Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 500 }}
                  overlay={(
                    <Tooltip>
                      Redo
                      <br />
                      Shortcut: Ctrl+Y
                    </Tooltip>
                  )}
                >
                  <Button size="sm" className="border border-dark" variant='light' onClick={() => redo()}><LuRedo2 /></Button>
                </OverlayTrigger>
              </>
            </ButtonGroup>
          )}
        </ButtonGroup>
      )}
      {iframe && defaultBtnView ? (
        <ButtonGroup vertical size="sm" className="position-absolute top-0 start-0" style={{ zIndex: 3, top: 60, visibility: (visible ? 'visible' : 'collapse') }}>
          <ButtonGroup vertical size="sm" className="ml-2 mt-1">
            <OverlayTrigger
              placement="right"
              delay={{ show: 500 }}
              overlay={(
                <Tooltip>
                  Show all/selected panels
                  <br />
                  Shortcut: Alt+F
                </Tooltip>
              )}
            >
              <Button size="sm" className="border border-dark" variant="light" onClick={() => toggleVisibility()}><Icon.EyeFill /></Button>
            </OverlayTrigger>
            {!viewerMode && <OverlayTrigger
              placement="right"
              delay={{ show: 500 }}
              overlay={(
                <Tooltip>
                  Select optimal panels
                  <br />
                  Shortcut: Alt+P
                </Tooltip>
              )}
            >
              <Button size="sm" className="border border-dark" variant="light" onClick={() => togglePanelSelection()}><FaSolarPanel /></Button>
            </OverlayTrigger>}
            {defaultBtnView?.Annotation && <OverlayTrigger
              placement="right"
              delay={{ show: 500 }}
              overlay={(
                <Tooltip>
                  Annotation Mode
                  <br />
                  Shortcut: W
                </Tooltip>
              )}
            >
              <Button size="sm" className="border border-dark" variant={viewState == "annotations" ? "dark" : "light"} onClick={() => switchAnnotation()}><HiOutlineAnnotation /></Button>
            </OverlayTrigger>}
          </ButtonGroup>
        </ButtonGroup>
      ) : (toolReadonly && toggleVisibility && togglePanelSelection &&
        (
          <ButtonGroup vertical size="sm" className="position-absolute top-0 start-0" style={{ zIndex: 3, top: 60, visibility: (visible ? 'visible' : 'collapse') }}>
            <ButtonGroup vertical size="sm" className="ml-2 mt-1">
              <OverlayTrigger
                placement="right"
                delay={{ show: 500 }}
                overlay={(
                  <Tooltip>
                    Show all/selected panels
                    <br />
                    Shortcut: Alt+F
                  </Tooltip>
                )}
              >
                <Button size="sm" className="border border-dark" variant="light" onClick={() => toggleVisibility()}><Icon.EyeFill /></Button>
              </OverlayTrigger>
              {!viewerMode && <OverlayTrigger
                placement="right"
                delay={{ show: 500 }}
                overlay={(
                  <Tooltip>
                    Select optimal panels
                    <br />
                    Shortcut: Alt+P
                  </Tooltip>
                )}
              >
                <Button size="sm" className="border border-dark" variant="light" onClick={() => togglePanelSelection()}><FaSolarPanel /></Button>
              </OverlayTrigger>}
              <OverlayTrigger
                placement="right"
                delay={{ show: 500 }}
                overlay={(
                  <Tooltip>
                    Annotation Mode
                    <br />
                    Shortcut: W
                  </Tooltip>
                )}
              >
                <Button size="sm" className="border border-dark" variant={viewState == "annotations" ? "dark" : "light"} onClick={() => switchAnnotation()}><HiOutlineAnnotation /></Button>
              </OverlayTrigger>
            </ButtonGroup>
          </ButtonGroup>
        )
      )}

      <ButtonGroup vertical size="sm" className="position-absolute right-0" style={{ zIndex: 3, top: 0, right: 0 }}>
        <ButtonGroup vertical size="sm" className="mr-2 mt-1" style={{ display: ((toolReadonly || iframe) && !designFrame ? 'block' : 'block'), width: 37 }}>
          <OverlayTrigger
            placement="left"
            delay={{ show: 500 }}
            overlay={(
              <Tooltip>
                Switch view mode
                <br />
                Shortcut: .
              </Tooltip>
            )}
          >
            <Button disable={progress > 0 && progress < 100 ? true : null} size="sm" className="border border-dark" variant="light" onClick={() => setViewMode(viewMode === '3D' ? '2D' : '3D')}>{viewMode === '3D' ? '2D' : '3D'}</Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="left"
            delay={{ show: 500 }}
            overlay={(
              <Tooltip>
                Google Map
              </Tooltip>
            )}
          >
            <Button disable={progress > 0 && progress < 100 ? true : null} size="sm" className="border border-dark" variant="light" onClick={() => openMapLink()}><MdOutlinePlace /></Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="left"
            delay={{ show: 500 }}
            overlay={(
              <Tooltip>
                Zoom in
              </Tooltip>
            )}
          >
            <Button disable={progress > 0 && progress < 100 ? true : null} size="sm" className="border border-dark" variant="light" onClick={() => zoomCamera(1.1)}><AiOutlineZoomIn /></Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="left"
            delay={{ show: 500 }}
            overlay={(
              <Tooltip>
                Zoom out
              </Tooltip>
            )}
          >
            <Button disable={progress > 0 && progress < 100 ? true : null} size="sm" className="border border-dark" variant="light" onClick={() => zoomCamera(0.9)}><AiOutlineZoomOut /></Button>
          </OverlayTrigger>
        </ButtonGroup>

        {iframe && defaultBtnView ? (
          <ButtonGroup vertical size="sm" className="mr-2 mt-1" style={{ visibility: (visible ? 'visible' : 'collapse') }}>
            {(defaultBtnView?.ShadingSimulator) && (
              <OverlayTrigger
                placement="left"
                delay={{ show: 500 }}
                overlay={(
                  <Tooltip>
                    Toggle Shading Simulator
                    <br />
                    Shortcut: F1
                  </Tooltip>
                )}
              >
                <Button disable={progress > 0 && progress < 100 ? true : null} size="sm" className="border border-dark" variant={showSunLight ? 'dark' : 'light'} onClick={() => toggleView('sun')}><Icon.BrightnessHigh /></Button>
              </OverlayTrigger>
            )}
            {(defaultBtnView?.Ground) && (
              <OverlayTrigger
                placement="left"
                delay={{ show: 500 }}
                overlay={(
                  <Tooltip>
                    Toggle Ground
                    <br />
                    Shortcut: F2
                  </Tooltip>
                )}
              >
                <Button size="sm" className="border border-dark" variant={showGround ? 'dark' : 'light'} onClick={() => toggleView('texture')}><Icon.Geo /></Button>
              </OverlayTrigger>
            )}
            {(defaultBtnView?.Trees) && (
              <OverlayTrigger
                placement="left"
                delay={{ show: 500 }}
                overlay={(
                  <Tooltip>
                    Toggle Trees
                    <br />
                    Shortcut: F3
                  </Tooltip>
                )}
              >
                <Button size="sm" className="border border-dark" variant={showTrees ? 'dark' : 'light'} onClick={() => toggleView('trees')}><Icon.Tree /></Button>
              </OverlayTrigger>
            )}
            {(defaultBtnView?.FireSetbacks) && (
              <OverlayTrigger
                placement="left"
                delay={{ show: 500 }}
                overlay={(
                  <Tooltip>
                    Toggle Fire Setbacks
                    <br />
                    Shortcut: F4
                  </Tooltip>
                )}
              >
                <Button size="sm" className="border border-dark" variant={showSetbacks ? 'dark' : 'light'} onClick={() => toggleView('setbacks')}><Icon.Fire /></Button>
              </OverlayTrigger>
            )}
            {(defaultBtnView?.Panels) && (
              <OverlayTrigger
                placement="left"
                delay={{ show: 500 }}
                overlay={(
                  <Tooltip>
                    Toggle Panels
                    <br />
                    Shortcut: F5
                  </Tooltip>
                )}
              >
                <Button size="sm" className="border border-dark" variant={showPanels ? 'dark' : 'light'} onClick={() => toggleView('panels')}><Icon.Grid3x3Gap /></Button>
              </OverlayTrigger>
            )}
            {(defaultBtnView?.House) && (
              <OverlayTrigger
                placement="left"
                delay={{ show: 500 }}
                overlay={(
                  <Tooltip>
                    Toggle House
                    <br />
                    Shortcut: F6
                  </Tooltip>
                )}
              >
                <Button size="sm" className="border border-dark" variant={showRoofs ? 'dark' : 'light'} onClick={() => toggleView('roofs')}><Icon.House /></Button>
              </OverlayTrigger>
            )}
            {(defaultBtnView?.ShadingGradients) && (
              <OverlayTrigger
                placement="left"
                delay={{ show: 500 }}
                overlay={(
                  <Tooltip>
                    Toggle Shading Gradients
                    <br />
                    Shortcut: F7
                  </Tooltip>
                )}
              >
                <Button size="sm" className="border border-dark" variant={showShading ? 'dark' : 'light'} onClick={() => toggleView('shading')}><Icon.CloudSun /></Button>
              </OverlayTrigger>
            )}
            {(defaultBtnView?.DSM) && (
              <OverlayTrigger
                placement="left"
                delay={{ show: 500 }}
                overlay={(
                  <Tooltip>
                    Toggle DSM
                    <br />
                    Shortcut: F8
                  </Tooltip>
                )}
              >
                <Button size="sm" className="border border-dark" variant={showDSM ? 'dark' : 'light'} onClick={() => toggleView('dsm')}><Icon.Layers /></Button>
              </OverlayTrigger>
            )}
            {(defaultBtnView?.HouseDSM && showDSM) && (
              <OverlayTrigger
                placement="left"
                delay={{ show: 500 }}
                overlay={(
                  <Tooltip>
                    Toggle House DSM
                    <br />
                    Shortcut: Ctrl + R
                  </Tooltip>
                )}
              >
                <Button size="sm" className="border border-dark" variant={showDSMHouse ? 'dark' : 'light'} onClick={() => toggleView('dsmhouse')}><Icon.HouseX /></Button>
              </OverlayTrigger>
            )}
          </ButtonGroup>
        ) : (
          <ButtonGroup vertical size="sm" className="mr-2 mt-1" style={{ visibility: (visible ? 'visible' : 'collapse') }}>
            {(isShading || toolReadonly) && (
              <OverlayTrigger
                placement="left"
                delay={{ show: 500 }}
                overlay={(
                  <Tooltip>
                    Toggle Shading Simulator
                    <br />
                    Shortcut: F1
                  </Tooltip>
                )}
              >
                <Button disable={progress > 0 && progress < 100 ? true : null} size="sm" className="border border-dark" variant={showSunLight ? 'dark' : 'light'} onClick={() => toggleView('sun')}><Icon.BrightnessHigh /></Button>
              </OverlayTrigger>
            )}
            {toolReadonly && (
              <OverlayTrigger
                placement="left"
                delay={{ show: 500 }}
                overlay={(
                  <Tooltip>
                    Toggle Ground
                    <br />
                    Shortcut: F2
                  </Tooltip>
                )}
              >
                <Button size="sm" className="border border-dark" variant={showGround ? 'dark' : 'light'} onClick={() => toggleView('texture')}><Icon.Geo /></Button>
              </OverlayTrigger>
            )}
            {(
              <OverlayTrigger
                placement="left"
                delay={{ show: 500 }}
                overlay={(
                  <Tooltip>
                    Toggle Trees
                    <br />
                    Shortcut: F3
                  </Tooltip>
                )}
              >
                <Button size="sm" className="border border-dark" variant={showTrees ? 'dark' : 'light'} onClick={() => toggleView('trees')}><Icon.Tree /></Button>
              </OverlayTrigger>
            )}
            {(isSetback || toolReadonly) && (
              <OverlayTrigger
                placement="left"
                delay={{ show: 500 }}
                overlay={(
                  <Tooltip>
                    Toggle Fire Setbacks
                    <br />
                    Shortcut: F4
                  </Tooltip>
                )}
              >
                <Button size="sm" className="border border-dark" variant={showSetbacks ? 'dark' : 'light'} onClick={() => toggleView('setbacks')}><Icon.Fire /></Button>
              </OverlayTrigger>
            )}
            {(isDesign || isRevise || toolReadonly) && (
              <OverlayTrigger
                placement="left"
                delay={{ show: 500 }}
                overlay={(
                  <Tooltip>
                    Toggle Panels
                    <br />
                    Shortcut: F5
                  </Tooltip>
                )}
              >
                <Button size="sm" className="border border-dark" variant={showPanels ? 'dark' : 'light'} onClick={() => toggleView('panels')}><Icon.Grid3x3Gap /></Button>
              </OverlayTrigger>
            )}
            {(isSegments || toolReadonly) && (
              <OverlayTrigger
                placement="left"
                delay={{ show: 500 }}
                overlay={(
                  <Tooltip>
                    Toggle House
                    <br />
                    Shortcut: F6
                  </Tooltip>
                )}
              >
                <Button size="sm" className="border border-dark" variant={showRoofs ? 'dark' : 'light'} onClick={() => toggleView('roofs')}><Icon.House /></Button>
              </OverlayTrigger>
            )}
            {(isShading || toolReadonly) && (
              <OverlayTrigger
                placement="left"
                delay={{ show: 500 }}
                overlay={(
                  <Tooltip>
                    Toggle Shading Gradients
                    <br />
                    Shortcut: F7
                  </Tooltip>
                )}
              >
                <Button size="sm" className="border border-dark" variant={showShading ? 'dark' : 'light'} onClick={() => toggleView('shading')}><Icon.CloudSun /></Button>
              </OverlayTrigger>
            )}
            {(
              <OverlayTrigger
                placement="left"
                delay={{ show: 500 }}
                overlay={(
                  <Tooltip>
                    Toggle DSM
                    <br />
                    Shortcut: F8
                  </Tooltip>
                )}
              >
                <Button size="sm" className="border border-dark" variant={showDSM ? 'dark' : 'light'} onClick={() => toggleView('dsm')}><Icon.Layers /></Button>
              </OverlayTrigger>
            )}
            {showDSM ? (<OverlayTrigger
              placement="left"
              delay={{ show: 500 }}
              overlay={(
                <Tooltip>
                  Toggle House DSM
                  <br />
                  Shortcut: Ctrl + R
                </Tooltip>
              )}
            >
              <Button size="sm" className="border border-dark" variant={showDSMHouse ? 'dark' : 'light'} onClick={() => toggleView('dsmhouse')}><Icon.HouseX /></Button>
            </OverlayTrigger>
            ) : null}
          </ButtonGroup>
        )}

        {(
          <ButtonGroup vertical size="sm" className="mr-2 mt-1" style={{ visibility: (visible ? 'visible' : 'collapse') }}>
            {(isSegments && (mode !== 'select') && (!(iframe && defaultBtnView) || defaultBtnView?.Measurements)) && (
              <OverlayTrigger
                placement="left"
                delay={{ show: 500 }}
                overlay={(
                  <Tooltip>
                    Toggle Measurements
                    <br />
                    Shortcut: F9
                  </Tooltip>
                )}
              >
                <Button size="sm" className="border border-dark" variant={showMeasurements ? 'dark' : 'light'} onClick={() => toggleView('measurements')}><TbRulerMeasure /></Button>
              </OverlayTrigger>
            )}
          </ButtonGroup>
        )}

      </ButtonGroup>
    </div>
  );
});

export default Toolbar;
