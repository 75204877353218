import React, {
  useState,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Form, ButtonGroup, InputGroup } from 'react-bootstrap';
import { ArrowClockwise, Files, Dash } from 'react-bootstrap-icons';
import Slider from '@material-ui/core/Slider';
import Delete from 'components/Delete';

import { DesignContext } from '../Canvas3D/contexts/designContext.js';

const color_mapping = { "DriveWay": "green", "Wiring": "blue", "Meters": "red" };
 
const Annotation = ({ }) => {
  const copyAnnotations = (data) => {
    return data && data.map((annotation) => ({...annotation}));
  }

  const { annotations, setAnnotations } = useContext(DesignContext);
  const [uiAnnotations, setUiAnnotations] = useState(copyAnnotations(annotations));/* || [
    {
      type: 'Custom',
      position: [0,0,0],
      text: 'Annotation text',
    }, 
    {
      type: 'Inverter',
      position: [0,0,0],
      text: 'Inverter 1',
    },
    {
      type: 'Combiner Panel',
      position: [0,0,0],
      text: 'Combiner Panel 1',
    },
    {
      type: 'Service Panel',
      position: [0,0,0],
      text: 'Service Panel 1',
    },
    {
      type: 'Production Meter',
      position: [0,0,0],
      text: 'Production Meter 1',
    },
    {
      type: 'Utility Meter',
      position: [0,0,0],
      text: 'Utility Meter 1',
    },
    {
      type: 'Disconnect',
      position: [0,0,0],
      text: 'Disconnect 1',
    },
    {
      type: 'Sub Panel',
      position: [0,0,0],
      text: 'Sub Panel 1',
    },
    {
      type: 'DriveWay',
      position: [0,0,0],
      text: 'DriveWay 1',
    },
    {
      type: 'Wiring',
      position: [0,0,0],
      text: 'Wiring 1',
    },
    {
      type: 'Meters',
      position: [0,0,0],
      text: 'Meters 1',
    }
  ]);*/
  const [ changeOccured, setChangeOccured ] = useState(false);

  const changeUiAnnotation = (index, text) => {
    if (uiAnnotations[index].text !== text) {
      const newAnnotations = [...uiAnnotations];
      newAnnotations[index].text = text;
      setUiAnnotations(newAnnotations);
      setChangeOccured(true);
    }
  }

  const changeAnnotation = (index, text) => {
    if (changeOccured) {
      setChangeOccured(false);
      const newAnnotations = [...uiAnnotations];
      newAnnotations[index].text = text;
      setAnnotations(newAnnotations);
    }
  }

  const removeAnnotation = (index) => {
    const newAnnotations = [...uiAnnotations];
    newAnnotations.splice(index, 1);
    setUiAnnotations(newAnnotations);
    setAnnotations(newAnnotations);
  }

  useEffect(() => {
    if (annotations && annotations.length > 0)
      setUiAnnotations(copyAnnotations(annotations));
  }, [annotations]);

  return (
    <>
      <Row>
        <Col className='text-center xs-6'>
          Name
        </Col>
        <Col className='text-center xs-6'>
          Type
        </Col>
      </Row>
      {(uiAnnotations || []).map((annotation, index) => (
        <Row>
          <Col>
            <InputGroup
              size="sm"
            >
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <div style={{ width: '25px' }}>
                    {index + 1}
                  </div>
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control value={annotation.text}
                onChange={(e) => changeUiAnnotation(index, e.target.value)}
                onBlur={(e) => changeAnnotation(index, e.target.value)}
              />
              <InputGroup.Append>
                <InputGroup.Text>
                  <div style={{ width: '100px', textAlign: 'center', color: annotation.type in color_mapping ? color_mapping[annotation.type] : 'inherit' }}>
                    {annotation.type}
                  </div>
                </InputGroup.Text>
              </InputGroup.Append>
              <InputGroup.Append
                style={{ color: annotation.type === 'DriveWay' ? '#d4edda' : 'inherit' }}
              >
                <InputGroup.Text>
                  <Delete id={index} onClick={() => removeAnnotation(index)} />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Row>
      ))}
    </>
  );
};

Annotation.propTypes = {
};

export default Annotation;
