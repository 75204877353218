import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { tableIcons, tableTheme } from 'Main/tables';

const ActiveEmployees = ({ data, onRowClick }) => {
  const columns = [
    { field: 'first_name', title: 'First Name' },
    { field: 'last_name', title: 'Last Name' },
    { field: 'email', title: 'Email' },
    { field: 'role', title: 'Role' },
    { field: 'designs', title: 'AI Design Usage' },
    { field: 'is_active', title: 'Status', render: (d) => <Chip variant="outlined" color="primary"  style={{backgroundColor: d.is_active ? '#34eb58' : '#ebb134' }} size="small" label={ d.is_active ? 'Active' : 'Deactivated' } /> }
  ];

  console.log('data:', data);
  // TODO: show total employees / number of allowed employees
  return (
    <MuiThemeProvider theme={tableTheme}>
      <MaterialTable
        responsive
        data={data}
        columns={columns}
        icons={tableIcons}
        
        title=""
        options={{
          headerStyle: { fontWeight: 'bold' },
          search: true,
          actionsColumnIndex: -1,
        }}
        
        onRowClick={onRowClick}
      />
    </MuiThemeProvider>
  );
};

ActiveEmployees.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  onRowClick: PropTypes.func.isRequired,
};

export default ActiveEmployees;
