import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { FloatInput, FloatSelect } from 'components/Form/Inputs';

const NetMeteringAndBilling = ({
    data,
    onChange,
    showOffsetCheckbox = true
}) => {
    console.log('NetMeteringAndBilling received data:', data);

    const handleChange = (field, value) => {
        console.log('NetMeteringAndBilling handleChange:', { field, value, currentData: data });

        // Handle offset_savings at the top level (only when no billing type)
        if (field === 'offset_savings' && !data?.billing_type) {
            console.log('Handling top-level offset_savings change:', value);
            onChange('offset_savings', value);
            return;
        }

        // Handle billing type changes
        if (field === 'billing_type') {
            onChange(field, value);
            return;
        }

        // Handle specs updates based on billing type
        const specType = data?.billing_type === 'net_billing' ? 'net_billing_specs' : 'net_metering_specs';
        const currentSpecs = data[specType] || {};

        switch (field) {
            case 'metering_period':
            case 'trueup_month':
            case 'fixed_cost_type':
            case 'ufsc':
            case 'system_size':
            case 'cost_per_kw':
            case 'cost_cap':
            case 'offset_savings':
            case 'export_rate':
            case 'export_percentage':
                onChange(specType, {
                    ...currentSpecs,
                    [field]: value
                });
                break;
            default:
                break;
        }
    };

    const billingTypeOptions = [
        { name: 'Choose...', value: '' },
        { name: 'Net Metering', value: 'net_metering' },
        { name: 'Net Billing', value: 'net_billing' }
    ];

    const meteringPeriodOptions = [
        { name: 'Annual', value: 'annual' },
        { name: 'Monthly', value: 'monthly' }
    ];

    const monthOptions = [
        { name: 'January', value: 'January' },
        { name: 'February', value: 'February' },
        { name: 'March', value: 'March' },
        { name: 'April', value: 'April' },
        { name: 'May', value: 'May' },
        { name: 'June', value: 'June' },
        { name: 'July', value: 'July' },
        { name: 'August', value: 'August' },
        { name: 'September', value: 'September' },
        { name: 'October', value: 'October' },
        { name: 'November', value: 'November' },
        { name: 'December', value: 'December' }
    ];

    const fixedCostTypeOptions = [
        { name: 'Constant', value: 'constant' },
        { name: 'System Size', value: 'system_size' }
    ];

    const specs = data?.billing_type === 'net_billing' ? data.net_billing_specs : data.net_metering_specs;
    const isNetMetering = data?.billing_type === 'net_metering';
    const isAnnualMetering = specs?.metering_period === 'annual';
    const isSystemSizeType = specs?.fixed_cost_type === 'system_size';
    const isConstantType = specs?.fixed_cost_type === 'constant';
    const SHOW_ONLY_OFFSET_CHECKBOX = true;

    if (SHOW_ONLY_OFFSET_CHECKBOX && showOffsetCheckbox && !data?.billing_type) {

        return (
            <Row>
                <Col xs={12} className='mb-3'>
                    <Form.Check
                        id="utility-offset-savings"
                        checked={data?.offset_savings || false}
                        onChange={(e) => {
                            console.log('Top-level offset savings changed:', e.target.checked);
                            handleChange('offset_savings', e.target.checked);
                        }}
                        label="Savings to calculate past 100% offset"
                    />
                </Col>
            </Row>
        )



    }

    return (
        <>
            {/* Top level offset savings - only show when no billing type selected */}
            {showOffsetCheckbox && !data?.billing_type && (
                <Row>
                    <Col xs={12} className='mb-3'>
                        <Form.Check
                            id="utility-offset-savings"
                            checked={data?.offset_savings || false}
                            onChange={(e) => {
                                console.log('Top-level offset savings changed:', e.target.checked);
                                handleChange('offset_savings', e.target.checked);
                            }}
                            label="Savings to calculate past 100% offset"
                        />
                    </Col>
                </Row>
            )}

            {/* Always show Billing Type */}
            <Row>
                <Col sm={3} xs={12}>
                    <FloatSelect
                        label="Billing Type"
                        nullValue="Choose..."
                        options={billingTypeOptions}
                        labelField="name"
                        valueField="value"
                        value={data?.billing_type || ''}
                        onChange={(e) => handleChange('billing_type', e.target.value)}
                    />
                </Col>
            </Row>

            {/* Net Metering Fields */}
            {isNetMetering && (
                <>
                    <Row className="mt-3">
                        {/* Metering Period */}
                        <Col sm={3} xs={12}>
                            <FloatSelect
                                label="Metering Period"
                                nullValue="Choose Period"
                                options={meteringPeriodOptions}
                                labelField="name"
                                valueField="value"
                                value={specs?.metering_period || ''}
                                onChange={(e) => handleChange('metering_period', e.target.value)}
                            />
                        </Col>

                        {/* True-up Month - only show if annual metering */}
                        {isAnnualMetering && (
                            <Col sm={3} xs={12}>
                                <FloatSelect
                                    label="True-up Month"
                                    nullValue="Choose Month"
                                    options={monthOptions}
                                    labelField="name"
                                    valueField="value"
                                    value={specs?.trueup_month || ''}
                                    onChange={(e) => handleChange('trueup_month', e.target.value)}
                                />
                            </Col>
                        )}

                        {/* Fixed Cost Type */}
                        <Col sm={3} xs={12}>
                            <FloatSelect
                                label="Fixed Cost Type"
                                nullValue="Choose Type"
                                options={fixedCostTypeOptions}
                                labelField="name"
                                valueField="value"
                                value={specs?.fixed_cost_type || ''}
                                onChange={(e) => handleChange('fixed_cost_type', e.target.value)}
                            />
                        </Col>
                        <Col sm={3} xs={12}>
                            <FloatInput
                                label="Export Rate"
                                prepend="$"
                                value={specs?.export_rate || ''}
                                onChange={(e) => handleChange('export_rate', e.target.value)}
                            />
                        </Col>
                    </Row>

                    {/* Conditional Fields based on Fixed Cost Type */}
                    {(isConstantType || isSystemSizeType) && (
                        <Row className="mt-3">
                            {/* Show Utility Fixed Charge for Constant type */}
                            {isConstantType && (
                                <Col sm={3} xs={12}>
                                    <FloatInput
                                        label="Utility Fixed Charge"
                                        prepend="$"
                                        value={specs?.ufsc || ''}
                                        onChange={(e) => handleChange('ufsc', e.target.value)}
                                    />
                                </Col>
                            )}

                            {/* Show System Size fields for system_size type */}
                            {isSystemSizeType && (
                                <>
                                    {/* <Col sm={3} xs={12}>
                                        <FloatInput
                                            label="System Size"
                                            append="kW"
                                            value={specs?.system_size || ''}
                                            onChange={(e) => handleChange('system_size', e.target.value)}
                                        />
                                    </Col> */}
                                    <Col sm={3} xs={12}>
                                        <FloatInput
                                            label="Cost per kW"
                                            prepend="$"
                                            value={specs?.cost_per_kw || ''}
                                            onChange={(e) => handleChange('cost_per_kw', e.target.value)}
                                        />
                                    </Col>
                                    <Col sm={3} xs={12}>
                                        <FloatInput
                                            label="Cost Cap"
                                            prepend="$"
                                            value={specs?.cost_cap || ''}
                                            onChange={(e) => handleChange('cost_cap', e.target.value)}
                                        />
                                    </Col>
                                </>
                            )}
                        </Row>
                    )}

                    {/* Net Metering Offset Savings */}
                    <Row className="mt-3">
                        <Col xs={12}>
                            <Form.Check
                                id="net-metering-offset-savings"
                                checked={specs?.offset_savings || false}
                                onChange={(e) => handleChange('offset_savings', e.target.checked)}
                                label="Calculate savings exceeding a 100% energy offset"
                            />
                        </Col>
                    </Row>
                </>
            )}

            {/* Net Billing Fields */}
            {data?.billing_type === 'net_billing' && (
                <>
                    <Row className="mt-3">
                        {/* Fixed Cost Type */}
                        <Col sm={3} xs={12}>
                            <FloatSelect
                                label="Fixed Cost Type"
                                nullValue="Choose Type"
                                options={fixedCostTypeOptions}
                                labelField="name"
                                valueField="value"
                                value={specs?.fixed_cost_type || ''}
                                onChange={(e) => handleChange('fixed_cost_type', e.target.value)}
                            />
                        </Col>
                        <Col sm={3} xs={12}>
                            <FloatInput
                                label="Export Rate"
                                prepend="$"
                                value={specs?.export_rate || ''}
                                onChange={(e) => handleChange('export_rate', e.target.value)}
                            />
                        </Col>
                        <Col sm={3} xs={12}>
                            <FloatInput
                                label="Export Percentage"
                                append="%"
                                value={specs?.export_percentage || ''}
                                onChange={(e) => handleChange('export_percentage', e.target.value)}
                            />
                        </Col>
                        <Col sm={3} xs={12}>
                            <FloatInput
                                label="Consumption Percentage"
                                append="%"
                                value={(100 - (specs?.export_percentage)) || ''}
                                disabled={true}

                            />
                        </Col>
                    </Row>

                    {/* Conditional Fields based on Fixed Cost Type */}
                    {(isConstantType || isSystemSizeType) && (
                        <Row className="mt-3">
                            {/* Show Utility Fixed Charge for Constant type */}
                            {isConstantType && (
                                <Col sm={3} xs={12}>
                                    <FloatInput
                                        label="Utility Fixed Charge"
                                        prepend="$"
                                        value={specs?.ufsc || ''}
                                        onChange={(e) => handleChange('ufsc', e.target.value)}
                                    />
                                </Col>
                            )}

                            {/* Show System Size fields for system_size type */}
                            {isSystemSizeType && (
                                <>
                                    {/* <Col sm={3} xs={12}>
                                        <FloatInput
                                            label="System Size"
                                            append="kW"
                                            value={specs?.system_size || ''}
                                            onChange={(e) => handleChange('system_size', e.target.value)}
                                        />
                                    </Col> */}
                                    <Col sm={3} xs={12}>
                                        <FloatInput
                                            label="Cost per kW"
                                            prepend="$"
                                            value={specs?.cost_per_kw || ''}
                                            onChange={(e) => handleChange('cost_per_kw', e.target.value)}
                                        />
                                    </Col>
                                    <Col sm={3} xs={12}>
                                        <FloatInput
                                            label="Cost Cap"
                                            prepend="$"
                                            value={specs?.cost_cap || ''}
                                            onChange={(e) => handleChange('cost_cap', e.target.value)}
                                        />
                                    </Col>
                                </>
                            )}
                        </Row>
                    )}
                </>
            )}
        </>
    );
};

export default NetMeteringAndBilling; 