import { filterDuplicates } from '../DesignTool/utils';

export default (API, uid) => {
  // helper to load data from API
  const loadData = (data) => {
    // console.log(data, 'Load');
    const shade = data.shaded;
    const colorRange = data.color_range.split(',').map(Number);
    const panels = data.panels || [];
    const annotations = data.annotations || [];

    // get segments data
    const segments = [];
    data.segments
      .map(({
        lines,
        height,
        azimuth,
        panel_orientation: panelOrientation,
        ac_monthly: acMonthly,
        poa_monthly: poaMonthly,
        solar_access: solarAccess,
        ...segment
      }) => {
        const lineArray = lines.length > 0 ? lines : Array(segment.geometry.length).fill('');
        const acAnnual = acMonthly.reduce((a, b) => a + b, 0);
        const duplicates = filterDuplicates(segment.geometry);
        if (duplicates[0].length > 2) {
          segments.push({
            ...segment,
            shape: 'Polygon',
            height: +height,
            azimuth: +azimuth,
            lines: lineArray.map((lineLabel) => (lineLabel === 'None' ? '' : lineLabel)),
            panelOrientation,
            acMonthly,
            poaMonthly,
            acAnnual,
            solarAccess,
          });
        }
      });

    // get obstacle data
    const obstacles = data.obstacles.map(({ radius, height, shape, ...obstacle }) => ({
      ...obstacle,
      height: +height,
      radius: shape === 'Polygon' ? null : +radius,
      shape,
    }));

    // get tree data
    const trees = data.trees.map(({ radius, height, ...tree }) => ({
      ...tree,
      height: +height,
    }));

    // add empty panel containers
    /* const panels = segments.map(({id, azimuth}) => {
          return {
            id,
            azimuth,
            horizontal: {height: 0, width: 0, points: []},
            vertical: {height: 0, width: 0, points: []},
            ac_monthly: [],
            solar_access: 1,
          };
        }) */

    // return all data
    return {
      segments, obstacles, trees, shade, colorRange, panels, annotations,
    };
  };

  // recover data from previously saved canvas
  const getCanvas = () => (
    API.retrieve(uid).then((data) => loadData(data))
  );

  // save all data
  const saveCanvas = (canvasData) => {
    const {
      segments, obstacles, trees, colorRange, ...canvas
    } = canvasData;
    if (segments) {
      canvas.segments = segments.map(({ acMonthly, poaMonthly, solarAccess, panelOrientation, tsrf, tof, ...segment }) => ({
        ...segment,
        panel_orientation: panelOrientation,
        ac_monthly: acMonthly,
        poa_monthly: poaMonthly,
        solar_access: solarAccess.map((val) => val?.toFixed(4)),
        tsrf: tsrf.map((val) => val?.toFixed(4)),
        tof: tof.map((val) => val?.toFixed(4)),
      }));
    }
    if (obstacles) {
      canvas.obstacles = obstacles;
    }
    if (trees) {
      canvas.trees = trees;
    }
    if (colorRange) canvas.color_range = colorRange.join(',');
    return API.update(uid, canvas).then((data) => loadData(data));
  };

  return {
    saveCanvas,
    getCanvas,
  };
};
