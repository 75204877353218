import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import LoginForm from './LoginForm';
import ResetForm from './ResetForm';

import './Auth.css';

const Auth = ({ user, login }) => {
  const match = useRouteMatch();
  
  return (
    <Container className="login-container" fluid>
      <Row className="h-100">
        <Col md={6} className="offset-md-3">
          <Switch>
            <Route path={`${match.path}/password-reset/:token/:uid`} component={ResetForm} />
            <Route path={`${match.path}/login`} render={() => !user ? <LoginForm login={login} /> : <Redirect to="/" />} />
            <Route path={`${match.path}/`} render={() => <Redirect to={ !user ? `${match.path}/login` : '/' } />} />
          </Switch>
        </Col>
      </Row>
    </Container>
  );
};

LoginForm.propTypes = {
  login: PropTypes.func.isRequired,
};

export default Auth;
