import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Modal,
} from 'react-bootstrap';

import SystemLosses from '../SystemLosses';
import SolarPowerChartCSS from '../SolarPowerChartCSS';

function ArrayMetrics({
  num,
  selected,
  azimuth,
  pitch,
  arrayTOF,
  arrayTSRF,
  arraySA,
}) {
  return (
    <tr style={{ fontSize: '0.85rem' }}>
      <td>{num}</td>
      <td>{selected}</td>
      <td>{azimuth}</td>
      <td>{pitch}</td>
      <td>{arrayTOF}</td>
      <td>{arrayTSRF}</td>
      <td>{arraySA}</td>
    </tr>
  );
}

function MonthlyMetrics({ num, monthlyData, segment }) {
  return (
    <>
      <tr style={{ fontSize: '0.85rem' }}>
        <td>{num}</td>
        <td>{monthlyData[1][segment]['arraySA']}</td>
        <td>{monthlyData[2][segment]['arraySA']}</td>
        <td>{monthlyData[3][segment]['arraySA']}</td>
        <td>{monthlyData[4][segment]['arraySA']}</td>
        <td>{monthlyData[5][segment]['arraySA']}</td>
        <td>{monthlyData[6][segment]['arraySA']}</td>
        <td>{monthlyData[7][segment]['arraySA']}</td>
        <td>{monthlyData[8][segment]['arraySA']}</td>
        <td>{monthlyData[9][segment]['arraySA']}</td>
        <td>{monthlyData[10][segment]['arraySA']}</td>
        <td>{monthlyData[11][segment]['arraySA']}</td>
        <td>{monthlyData[12][segment]['arraySA']}</td>
      </tr>
    </>
  );
}

function LeadInformation({ shadingReportData }) {
  return (
    <table
      className="mb-3"
      // style={{ marginLeft: '2.2rem', marginRight: '2.2rem' }}
    >
      <tbody style={{ fontSize: '0.8rem' }}>
        <tr>
          <th style={{ paddingRight: '20mm' }}>Homeowner</th>
          {/* <th style={{ paddingRight: '55mm' }}>Designer</th> */}
          <th style={{ paddingRight: '20mm' }}>Client</th>
          <th style={{ paddingRight: '70mm' }}>Address</th>
          <th style={{ paddingRight: '45mm' }}>Coordiantes</th>
          <th style={{ paddingRight: '45mm' }}>Date</th>
        </tr>
        <tr>
          <td>{shadingReportData['homeowner']}</td>
          {/* <td>{shadingReportData['designer']}</td> */}
          <td>{shadingReportData['client']}</td>
          <td>{shadingReportData['address']}</td>
          <td>{`${shadingReportData['coordinates'][0]}, ${shadingReportData['coordinates'][1]}`}</td>
          <td>{shadingReportData['date']}</td>
        </tr>
        {/* <tr>
          <th style={{ paddingRight: '55mm' }}>Address</th>
          <th style={{ paddingRight: '55mm' }}>Coordiantes</th>
          <th style={{ paddingRight: '55mm' }}>Date</th>
        </tr>
        <tr>
          <td>
            {shadingReportData['address'][0]}, {shadingReportData['address'][1]}
          </td>
          <td>{`${shadingReportData['coordinates'][0]}, ${shadingReportData['coordinates'][1]}`}</td>
          <td>{shadingReportData['date']}</td>
        </tr> */}
      </tbody>
    </table>
  );
}

function ShadingReportModal({
  show,
  onHide,
  shadingReportData,
  exportPDFShadingReport,
  monthlyProduction,
  losses,
  snowLoss,
  inverter,
  exporting,
  iframe,
}) {
  const [metrics, setMetrics] = useState([]);
  const [monthlyMetrics, setMonthlyMetrics] = useState([]);
  let logoUrl = '/aerialytic-logo.png';
  useEffect(() => {
    if (shadingReportData) {
      const panelMetrics = shadingReportData['panelMetrics'];
      const m = [];
      const mm = [];
      let arrayCount = 0;
      for (let i = 0; i < panelMetrics.length; i++) {
        if (panelMetrics[i]['selected'] > 0) {
          arrayCount += 1;
          m.push(
            <ArrayMetrics
              key={`321${i}`}
              num={arrayCount}
              selected={panelMetrics[i]['selected']}
              azimuth={panelMetrics[i]['azimuth']}
              pitch={panelMetrics[i]['pitch']}
              arrayTOF={panelMetrics[i]['arrayTOF']}
              arrayTSRF={panelMetrics[i]['arrayTSRF']}
              arraySA={panelMetrics[i]['arraySA']}
            />
          );
          mm.push(
            <MonthlyMetrics
              key={`322${i}`}
              num={arrayCount}
              monthlyData={shadingReportData['monthlyPanelMetrics']}
              segment={i}
            />
          );
        }
      }
      setMetrics(m);
      setMonthlyMetrics(mm);
    }
  }, [shadingReportData]);

  return (
    shadingReportData !== null && (
      <Modal
        className={exporting && iframe ? 'd-none' : ''}
        show={show}
        onHide={onHide}
        dialogClassName="modal-90w"
        backdrop={false}
        // centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Shading Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container
            id="shading-report"
            className={exporting || iframe ? 'a4-land-size-fixed ' : ''}
          >
            {/* <IFrameLogo textColor="dark" shading /> */}
            <Row>
              <div className="float-left">
                <img src={`${logoUrl}`} width="100" />
              </div>
              <br />
              <hr className='p-0 m-0'></hr>
            </Row>

            <Row>
              <LeadInformation shadingReportData={shadingReportData} />
            </Row>

            <Row className="" noGutters>
              <Col xs={5}>
                <img
                  className="img-thumbnail rounded-0"
                  src={shadingReportData['canvas2DJPG']}
                  width={448}
                  height={'auto'}
                />
                <div className="pr-3">
                  <strong style={{ fontSize: '0.7rem' }}>
                    Insolation (KWh/m
                    <sup>2</sup>
                    /year)
                  </strong>
                  <img
                    src="/cmap_inferno.png"
                    width="100%"
                    height={20}
                    alt="legend"
                  />
                  <div
                    style={{ fontSize: '0.7rem' }}
                    className="w-100 px-1 text-primary d-flex justify-content-between border border-primary border-bottom-0"
                  >
                    <p className="m-0">{0}</p>
                    <p className="m-0">{2450}</p>
                  </div>
                </div>
              </Col>
              <Col xs={7}>
                <table className="table table-dark ml-1 mb-0">
                  <thead>
                    <tr style={{ fontSize: '0.7rem' }}>
                      <th>Array</th>
                      <th>Panels</th>
                      <th>Azimuth</th>
                      <th>Pitch</th>
                      <th>Annual TOF</th>
                      <th>Annual TSRF</th>
                      <th>Annual SA</th>
                    </tr>
                  </thead>
                  <tbody>{metrics}</tbody>
                </table>
              </Col>
            </Row>
            {exporting && (
              <>
                <div id="page2el"></div>
                <Row>
                  <div className="float-left">
                    <img src={`${logoUrl}`} width="100" />
                  </div>
                  <br />
                  <hr className='p-0 m-0'></hr>
                </Row>

                <Row>
                  <LeadInformation shadingReportData={shadingReportData} />
                </Row>
              </>
            )}

            <Row className="mt-1" noGutters>
              <Col xs={12}>
                <h6>Monthly Solar Access Values</h6>
                <table className="table table-dark table-monthly mt-2 mb-0">
                  <thead>
                    <tr style={{ fontSize: '0.7rem' }}>
                      <th>Array</th>
                      <th>Jan.</th>
                      <th>Feb.</th>
                      <th>Mar.</th>
                      <th>Apr.</th>
                      <th>May</th>
                      <th>June</th>
                      <th>July</th>
                      <th>Aug.</th>
                      <th>Sept.</th>
                      <th>Oct.</th>
                      <th>Nov.</th>
                      <th>Dec.</th>
                    </tr>
                  </thead>
                  <tbody>{monthlyMetrics}</tbody>
                </table>
              </Col>
            </Row>
            <Row className="mt-3" noGutters>
              <Col xs={6}>
                <div className="float-left">
                  <img
                    className="img-thumbnail rounded-0"
                    src={shadingReportData['canvas3DJPG1']}
                    width={384}
                    height={'auto'}
                  />
                </div>
              </Col>
              <Col xs={6}>
                <div className="float-right">
                  <img
                    className="img-thumbnail rounded-0"
                    src={shadingReportData['canvas3DJPG2']}
                    width={384}
                    height={'auto'}
                  />
                </div>
              </Col>
            </Row>
            {exporting && (
              <>
                <div id="page2el"></div>
                <Row>
                  <div className="float-left">
                    <img src={`${logoUrl}`} width="100" />
                  </div>
                  <br />
                  <hr className='p-0 m-0'></hr>
                </Row>

                <Row>
                  <LeadInformation shadingReportData={shadingReportData} />
                </Row>
              </>
            )}
            <Row className="mt-1" noGutters>
              <Col xs={8}>
                <h6>Monthly Solar Production</h6>
                <SolarPowerChartCSS
                  annualProduction={monthlyProduction}
                />
              </Col>
              <Col xs={4} className='pl-2'>
                <h6 className='mt-4'>Total:</h6>
                <span>{monthlyProduction.reduce((a, b) => a + b, 0).toFixed(2)} kWh</span>
              </Col>
            </Row>
            {exporting && (
              <>
                <div id="page2el"></div>
                <Row>
                  <div className="float-left">
                    <img src={`${logoUrl}`} width="100" />
                  </div>
                  <br />
                  <hr className='p-0 m-0'></hr>
                </Row>

                <Row>
                  <LeadInformation shadingReportData={shadingReportData} />
                </Row>
              </>
            )}
            <Row className="mt-1" noGutters>
              <Col xs={12}>
                <div className="">
                  <SystemLosses inverter={inverter} losses={losses} snowLoss={snowLoss} />
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="primary" onClick={exportPDFShadingReport}>
            export as PDF
          </Button>
        </Modal.Footer>
      </Modal>
    )
  );
}

// UtilityModal.defaultProps = {
// utility: { provider: 'Not Available', fixed_fee: 0, rate: 0 },
// };

ShadingReportModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  shadingReportData: PropTypes.object.isRequired,
  exportPDFShadingReport: PropTypes.func.isRequired,
  monthlyProduction: PropTypes.object.isRequired,
  losses: PropTypes.object,
  snowLoss: PropTypes.string,
  inverter: PropTypes.object,
  exporting: PropTypes.bool.isRequired,
};

export default ShadingReportModal;
