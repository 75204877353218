import {
  useState, useEffect,
} from 'react';

import * as THREE from 'three';
import { getTreeData } from '../algorithms/utils';

export const useTreeOperation = ({
  trees,
  scale,
  center,
  footPerUnit,
}) => {
  const [treeData, setTreeData] = useState([]);

  const [cx, cy] = center;
  const c = 1;

  useEffect(() => {
    // const newTrees = trees.map((tree) => {
    //   const r2 = (tree.geometry[0][0] - tree.geometry[1][0]) ** 2
    //   + (tree.geometry[0][1] - tree.geometry[1][1]) ** 2;
    //   const pos = [(tree.geometry[0][0] - cx / c) * scale, (tree.geometry[0][1] - cy / c) * scale, 0];

    //   return {
    //     position: pos,
    //     height: (tree.height / footPerUnit) * scale,
    //     radius: Math.sqrt(r2) * scale,
    //     treeType: tree.model,
    //     index: tree.id,
    //   };
    // });
    const newTrees = getTreeData(trees, scale, [cx, cy, 1], footPerUnit);
    setTreeData(newTrees);
  }, [c, cx, cy, footPerUnit, scale, setTreeData, trees]);

  return (
    {
      treeData,
    }
  );
};

export default useTreeOperation;
