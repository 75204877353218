import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Form, Modal } from 'react-bootstrap';

function SelectModal({
  show,
  title,
  selectValue,
  selectOptions,
  nullOption,
  disabled,
  onHide,
  onSave,
}) {
  const [value, setValue] = useState(selectValue);
  const handleSave = () => {
    onSave(value);
    onHide();
  };
  useEffect(() => setValue(selectValue), [selectValue]);

  return (
    <Modal
      size="md"
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      onHide={onHide}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mt-2">
          <Col sm={8} className="h6">
            <Form.Control
              as="select"
              onChange={(e) => setValue(e.target.value)}
              value={value}
              disabled={disabled}
            >
              {nullOption
                ? <option value="">{nullOption}</option>
                : <option disabled>{`Select ${title}`}</option>}
              {selectOptions.map(({ id, label }) => (
                <option key={id} value={id}>{label}</option>
              ))}
            </Form.Control>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button size="sm" variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

SelectModal.defaultProps = {
  nullOption: null,
  disabled: false,
};

SelectModal.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  selectValue: PropTypes.string.isRequired,
  selectOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  nullOption: PropTypes.string,
  disabled: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default SelectModal;
