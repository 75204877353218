import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Alert, ProgressBar } from 'react-bootstrap';
import AuthManager, { client } from './auth';
import REST from './rest';

export const AuthContext = createContext();

const AuthProvider = ({ children, iframe = false, apiKey = null }) => {
  const [auth] = useState(() => AuthManager());
  const [status, setStatus] = useState({ loaded: false, error: null });
  const [user, setUser] = useState();
  const [AiDesigns, setAiDesigns] = useState();
  const [snackbar, setSnackbar] = useState();

  // modify console logging to only print in dev mode
  if (process.env.NODE_ENV === 'production') {
    // console.log = function () {};
  }

  // utility to display snackbars across screens
  const displaySnack = (state, time = 5000) => {
    setSnackbar(state);
    setTimeout(() => setSnackbar(), time);
  };

  useEffect(() => {
    if (apiKey) auth.setApiKey(apiKey);
  }, [apiKey]);

  const getHeaders = () => {
    const token = auth.getToken();
    if (!iframe && token) {
      return { Authorization: `Token ${token}` };
    }
    const ApiKey = apiKey || auth.getApiKey();
    if (iframe && ApiKey) {
      return { Authorization: `Api-Key ${ApiKey}` };
    }
    return { Authorization: '' };
    // throw new Error('You were logged out in another tab.');
  };

  // Method for creating API endpoints
  const newEndpoint = (path, blob=false) => {
    // shortcut for abstracting API requests, guarantees token is up to date when called
    const request = (method, url, data) => {
      try {
        const headers = getHeaders();
        let params = { method, url, data, headers };
        if (blob) params = { ...params, responseType: 'blob' };
        return client(params)
          .then((res) => {
            if (res.status >= 200 && res.status < 300) return res.data;
            return res;
          });
      } catch (error) {
        console.log('No token.');
        setUser(null);
        // logout(); // TODO: display modal or re-direct to login screen
        return Promise.reject(error);
      }
    };
    return REST(path, request);
  };

  const getTaskStatus = (taskId) => client.get(`task/${taskId}/`).then(({ data }) => data);

  // retrieve data for logged in user
  const getUserData = () => {
    setStatus({ loaded: false, error: null });
    const userAPI = newEndpoint('user');
    userAPI.retrieve('self').then((data) => {
      setUser(data);
      setStatus({ loaded: true, error: null });
    }).catch(err => {
      console.log('Fetching user data error: ', err);
      setStatus({ loaded: true, error: "We encountered an error while retrieving user information!" });
    });
  };

  useEffect(() => {
    if (user) {
      const CheckLimitAPI = newEndpoint('designer/limit/check');
      CheckLimitAPI.send('POST', {company: user?.company?.id})
        .then((res) => setAiDesigns(res))
        .catch((err) => console.log(err));
    }
  }, [user]);

  // Submit credentials to log in
  // TODO: change signature to do (token) ONLY
  const login = (token) => {
    auth.setToken(token);
    setTimeout(() => getUserData(), 0);
  };

  // logout current user
  const logout = () => {
    console.log('Logging out!');
    auth.ereaseToken();
    setUser(null);
  };

  const getBaseUrl = () => client.defaults.baseURL;

  useEffect(() => {
    console.log(process.env.NODE_ENV);

    const token = auth.getToken();
    const ApiKey = apiKey || auth.getApiKey();

    if (!iframe && token) {
      console.log('Authenticating with refresh token.');
    } else if (iframe && ApiKey) {
      console.log('Authenticating with api key.');
    }

    getUserData();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        status,
        company: user?.company?.id,
        AiDesigns,
        login,
        logout,
        newEndpoint,
        displaySnack,
        getTaskStatus,
        getHeaders,
        getBaseUrl,
        auth,
        iframe
      }}
    >
      <>
        {snackbar && (
          <Alert
            className="snackbar"
            variant={snackbar.variant}
            onClose={() => setSnackbar()}
            dismissible
          >
            {snackbar.message?.split('\n').map((msg) => (
              <div>{msg}</div>
            ))}
          </Alert>
        )}
        {children}
      </>
    </AuthContext.Provider>
  );
};

AuthProvider.defaultProps = {
  iframe: false,
  apiKey: null,
};

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  iframe: PropTypes.bool,
  apiKey: PropTypes.string,
};

export default AuthProvider;
