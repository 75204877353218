import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Form, InputGroup,
} from 'react-bootstrap';
import Delete from 'components/Delete';
import { treePropType } from '../props';
import { distance } from '../utils';
import { DesignContext } from '../Canvas3D/contexts/designContext';

function Trees({ trees, treeUpdateHandler, deleteShapeHandler }) {
  const handleHeightChange = (height, index) => {
    const tree = { ...trees[index], height };
    treeUpdateHandler(tree, index);
  };

  const handleModelChange = (model, index) => {
    const tree = { ...trees[index], model };
    treeUpdateHandler(tree, index);
  };

  const handleDSMChange = (dsm, index) => {
    const tree = { ...trees[index], dsm };
    if (dsm === 'Remove') tree.height = 0; else tree.height = 20;
    treeUpdateHandler(tree, index);
  };

  const { resolution } = useContext(DesignContext);

  return (
    <div>
      <Row className="mt-2 font-weight-bold">
        <Col xs={1}>#</Col>
        <Col xs={3} className="pr-1">Height</Col>
        <Col xs={2} className="pl-1">Radius</Col>
        <Col xs={3}>Shape</Col>
        <Col xs={2}>DSM</Col>
        <Col xs={1} />
      </Row>
      {trees.map(({
        id, height, model, shape, dsm, geometry,
      }, index) => (
        <Row key={id} className="mt-2" style={{ height: 34 }}>
          <Col xs={1}>{index + 1}</Col>
          <Col xs={3} className="pr-1">
            <InputGroup size="sm">
              <Form.Control
                id={index}
                value={height}
                onChange={(e) => handleHeightChange(+e.target.value, index)}
                disabled={shape === 'Polygon'}
              />
              <InputGroup.Append>
                <InputGroup.Text>ft</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Col>
          <Col xs={2} className="pl-1">
            <InputGroup size="sm">
              <Form.Control
                id={index}
                value={(parseFloat(distance(geometry[0], geometry[1]) * resolution) / 30.48).toFixed(2)}
                disabled
              />
              <InputGroup.Append>
                <InputGroup.Text>ft</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Col>
          <Col xs={3}>
            <Form.Control
              size="sm"
              as="select"
              id={index}
              value={model}
              onChange={(e) => handleModelChange(e.target.value, index)}
              disabled={shape === 'Polygon'}
            >
              <option value="Cone">Cone</option>
              <option value="Sphere">Sphere</option>
            </Form.Control>
          </Col>
          <Col xs={2}>
            <Form.Control
              size="sm"
              as="select"
              id={index}
              value={dsm}
              onChange={(e) => handleDSMChange(e.target.value, index)}
            >
              <option value="Keep">Keep</option>
              <option value="Remove">Remove</option>
            </Form.Control>
          </Col>
          <Col xs={1} className="text-left">
            <Delete id={index} onClick={() => deleteShapeHandler(id)} />
          </Col>
        </Row>
      ))}
    </div>
  );
}

Trees.propTypes = {
  trees: treePropType.isRequired,
  treeUpdateHandler: PropTypes.func.isRequired,
  deleteShapeHandler: PropTypes.func.isRequired,
};

export default Trees;
