import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Form, Modal } from 'react-bootstrap';

function AddersModal({
  show,
  current,
  onHide,
  onSave,
}) {
  const [adders, setAdders] = useState({});

  const updateCount = (id, value) => {
    const updated = { ...adders };
    updated[id].count = value;
    setAdders(updated);
  };

  const saveAdders = () => {
    const updated = { ...adders };
    Object.keys(updated).forEach((id) => {
      const count = Number(updated[id].count);
      updated[id].count = count >= 0 ? count : 0;
    });
    onSave(updated);
  };

  const close = () => {
    setAdders(JSON.parse(JSON.stringify(current)));
    onHide();
  };

  useEffect(() => setAdders(JSON.parse(JSON.stringify(current))), [current]);

  return (
    <Modal
      size="md"
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Adders</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {Object.entries(adders).map(([id, { name, count }]) => (
          <Row key={id} className="m-2">
            <Col>
              <Form.Label>{name}</Form.Label>
            </Col>
            <Col className="d-flex">
              <Form.Control
                className="w-50"
                type="number"
                onChange={(e) => updateCount(id, e.target.value)}
                value={count}
              />
              <Button className="mx-1" onClick={() => updateCount(id, +count + 1)}>+</Button>
              <Button className="mx-1" onClick={() => updateCount(id, +count - 1)}>-</Button>
            </Col>
          </Row>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={close}>
          Close
        </Button>
        <Button size="sm" variant="primary" onClick={saveAdders}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

AddersModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  current: PropTypes.shape({
    name: PropTypes.string,
    cost: PropTypes.number,
    count: PropTypes.number,
    totalCost: PropTypes.number,
    dq: PropTypes.bool,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
};

export default AddersModal;
