import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { CaretDownFill } from 'react-bootstrap-icons';
import Accordion from 'react-bootstrap/Accordion';

import { setTitle } from 'Main/utils';
import Hardware from './Hardware';
import Adders from './Adders';
import Roofs from './Roofs';
import Permits from './Permits';
import Setbacks from './Setbacks';
import Warranty from './Warranty';
import CreditRebate from './CreditRebate';
import InstallationPackage from './InstallationPackage';
import UtilityRate from './UtilityRate';
import UtilityLoad from './UtilityLoad';
import Loans from './Loans';
import SystemLosses from './SystemLosses';
// import classes from './Installation.module.css';

const InstallationSettings = () => {
  const [key, setKey] = useState(null);
  const title = 'Installation Settings';
  useEffect(() => setTitle(title), []);

  return (
    <div>
      <Accordion className="my-5" onSelect={(active) => setKey(active)}>
        <Card className="mb-3 border shadow text-dark bg-white rounded">
          <Accordion.Toggle
            className="bg-white border-0"
            as={Card.Header}
            eventKey="0"
          >
            <Row className="h5 font-weight-bold text-primary mb-0">
              <Col>Hardware</Col>
              <Col className="text-right h6">
                <CaretDownFill />
              </Col>
            </Row>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Hardware />
          </Accordion.Collapse>
        </Card>

        <Card className="mb-3 border shadow text-dark bg-white rounded">
          <Accordion.Toggle
            className="bg-white border-0"
            as={Card.Header}
            eventKey="1"
          >
            <Row className="h5 font-weight-bold text-primary mb-0">
              <Col>Adders</Col>
              <Col className="text-right h6">
                <CaretDownFill />
              </Col>
            </Row>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <Adders />
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card className="mb-3 border shadow text-dark bg-white rounded">
          <Accordion.Toggle
            className="bg-white border-0"
            as={Card.Header}
            eventKey="2"
          >
            <Row className="h5 font-weight-bold text-primary mb-0">
              <Col>Roof Types</Col>
              <Col className="text-right h6">
                <CaretDownFill />
              </Col>
            </Row>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              <Roofs />
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card className="mb-3 border shadow text-dark bg-white rounded">
          <Accordion.Toggle
            className="bg-white border-0"
            as={Card.Header}
            eventKey="3"
          >
            <Row className="h5 font-weight-bold text-primary mb-0">
              <Col>Permits</Col>
              <Col className="text-right h6">
                <CaretDownFill />
              </Col>
            </Row>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <Card.Body className="pt-0">
              <Permits />
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card className="mb-3 border shadow text-dark bg-white rounded">
          <Accordion.Toggle
            className="bg-white border-0"
            as={Card.Header}
            eventKey="4"
          >
            <Row className="h5 font-weight-bold text-primary mb-0">
              <Col>Warranties</Col>
              <Col className="text-right h6">
                <CaretDownFill />
              </Col>
            </Row>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="4">
            <Card.Body>
              <Warranty />
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card className="mb-3 border shadow text-dark bg-white rounded">
          <Accordion.Toggle
            className="bg-white border-0"
            as={Card.Header}
            eventKey="5"
          >
            <Row className="h5 font-weight-bold text-primary mb-0">
              <Col>Credits & Rebates</Col>
              <Col className="text-right h6">
                <CaretDownFill />
              </Col>
            </Row>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="5">
            <Card.Body>
              <CreditRebate />
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card className="mb-3 border shadow text-dark bg-white rounded">
          <Accordion.Toggle
            className="bg-white border-0"
            as={Card.Header}
            eventKey="6"
          >
            <Row className="h5 font-weight-bold text-primary mb-0">
              <Col>Setbacks</Col>
              <Col className="text-right h6">
                <CaretDownFill />
              </Col>
            </Row>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="6">
            <Card.Body>
              <Setbacks />
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card className="mb-3 border shadow text-dark bg-white rounded">
          <Accordion.Toggle
            className="bg-white border-0"
            as={Card.Header}
            eventKey="7"
          >
            <Row className="h5 font-weight-bold text-primary mb-0">
              <Col>Installation Package</Col>
              <Col className="text-right h6">
                <CaretDownFill />
              </Col>
            </Row>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="7">
            <Card.Body>
              {key === '7' && <InstallationPackage />}
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        {<Card className="mb-3 border shadow text-dark bg-white rounded">
          <Accordion.Toggle
            className="bg-white border-0"
            as={Card.Header}
            eventKey="8"
          >
            <Row className="h5 font-weight-bold text-primary mb-0">
              <Col>Loans</Col>
              <Col className="text-right h6">
                <CaretDownFill />
              </Col>
            </Row>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="8">
            <Card.Body>
              <Loans />
            </Card.Body>
          </Accordion.Collapse>
        </Card>}

        <Card className="mb-3 border shadow text-dark bg-white rounded">
          <Accordion.Toggle
            className="bg-white border-0"
            as={Card.Header}
            eventKey="9"
          >
            <Row className="h5 font-weight-bold text-primary mb-0">
              <Col>System Losses</Col>
              <Col className="text-right h6">
                <CaretDownFill />
              </Col>
            </Row>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="9">
            <Card.Body>
              <SystemLosses />
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card className="mb-3 border shadow text-dark bg-white rounded">
          <Accordion.Toggle
            className="bg-white border-0"
            as={Card.Header}
            eventKey="10"
          >
            <Row className="h5 font-weight-bold text-primary mb-0">
              <Col>Utility Rates</Col>
              <Col className="text-right h6">
                <CaretDownFill />
              </Col>
            </Row>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="10">
            <Card.Body>
              {key === '10' && <UtilityRate />}
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card className="mb-3 border shadow text-dark bg-white rounded">
          <Accordion.Toggle
            className="bg-white border-0"
            as={Card.Header}
            eventKey="11"
          >
            <Row className="h5 font-weight-bold text-primary mb-0">
              <Col>Utility Load Profiles</Col>
              <Col className="text-right h6">
                <CaretDownFill />
              </Col>
            </Row>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="11">
            <Card.Body>
              {key === '11' && <UtilityLoad />}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
};

export default InstallationSettings;
