import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Button,
  Spinner,
  Form,
  Modal,
  InputGroup,
} from 'react-bootstrap';
import { Check2, X } from 'react-bootstrap-icons';
import { AuthContext } from 'api/context';
import { LeadContext } from 'Leads/Details/hooks/context';
import { convertStateToAbbr, getSnowLoss } from 'options';
import { number } from 'mathjs';

const Shading = ({
  shaded,
  colorRange,
  shadingHandler,
  showOverlay,
  toggleOverlay,
  shadingMonth,
  shadingStatus,
  serverSideShadingIsRunning,
  setPVwattsLosses,
}) => {
  // const [init, setInit] = useState(true);
  // const [loading, setLoading] = useState();
  // const [success, setSuccess] = useState(false);
  const {
    company: id,
    user,
    newEndpoint,
    displaySnack,
  } = useContext(AuthContext);
  const { lead } = useContext(LeadContext);

  const [serverSide, setServerSide] = useState(true);
  const [cmin, cmax] = colorRange;

  const [showLossesModal, setShowLossesModal] = useState(false);

  const handleClose = () => setShowLossesModal(false);
  const handleShow = () => setShowLossesModal(true);

  const endpoint = newEndpoint('hardware/losses/');

  const [losses, setLosses] = useState([]);
  const [snow, setSnow] = useState(0.0);

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
    'Year',
  ];
  
  useEffect(() => {
    if (lead?.property?.state) {
      let stateAbbr = convertStateToAbbr(lead?.property?.state, 'abbr');
      if (stateAbbr) {
        let snowLoss = getSnowLoss(stateAbbr);
        setSnow(parseFloat(snowLoss));
      }
    }
    
    endpoint.retrieve(id)
      .then((data) => setLosses(data))
      .catch((e) => console.log(e));
  }, []);

  // useEffect(() => {
  //   if (shadingStatus === 1) {
  //     setLoading(false);
  //     setSuccess(true);
  //     setInit(false);
  //   } else if (shadingStatus === 0) {
  //     setLoading(false);
  //     setSuccess(true);
  //   } else if (shadingStatus === -1) {
  //     setLoading(false);
  //     setSuccess(false);
  //   }
  // }, [shadingStatus, shadingMonth]);

  const handleSetLosses = () => {
    console.log(`system losses updated for lead: ${JSON.stringify(losses)}`);
    setPVwattsLosses(losses);
    handleClose();
  };

  const handleUpdateField = (value, field) => {
    const updated = losses;
    updated[field] = value;
    setLosses({...updated});
  };

  const isLoading = shadingStatus === 1;
  const isCompleted = shadingStatus === 0;
  const isError = shadingStatus === -1;

  const runShading = () => {
    if (isLoading) return;
    // setLoading(true);
    // send shading request
    shadingHandler(serverSide);
    // .then((status) => {
    //   setSuccess(status);
    //   setLoading(false);
    // });
  };

  return (
    <>
      <Row xs={1} md={2}>
        <Col>
          <Form.Check
            id="chk-server-side"
            type="switch"
            checked={serverSide}
            onChange={(e) => setServerSide(e.target.checked)}
            label="Server-Side Shading"
          />
        </Col>
      </Row>
      <Row xs={1} md={2} className="my-2">
        <Col>
          <Button variant="outline-dark" size="sm" onClick={setShowLossesModal}>
            System Losses
          </Button>

          <Modal show={showLossesModal} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>System Losses</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row className="my-1">
                <Col>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Soiling</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="number"
                      value={parseFloat(losses.soiling)}
                      onChange={(e) =>
                        handleUpdateField(e.target.value, 'soiling')
                      }
                      step="0.10"
                      min="0.00"
                      required
                      disabled={user.role !== 'admin'}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Row>
              <Row className="my-1">
                <Col>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Shading</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="number"
                      value={parseFloat(losses.shading)}
                      onChange={(e) =>
                        handleUpdateField(e.target.value, 'shading')
                      }
                      step="0.10"
                      min="0.00"
                      disabled
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Row>
              <Row className="my-1">
                <Col>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Snow</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="number"
                      value={snow}
                      onChange={(e) =>
                        handleUpdateField(e.target.value, 'snow')
                      }
                      step="0.10"
                      min="0.00"
                      disabled
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Row>
              <Row className="my-1">
                <Col>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Mismatch</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="number"
                      value={parseFloat(losses.mismatch)}
                      onChange={(e) =>
                        handleUpdateField(e.target.value, 'mismatch')
                      }
                      step="0.10"
                      min="0.00"
                      required
                      disabled={user.role !== 'admin'}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Row>
              <Row className="my-1">
                <Col>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Wiring</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="number"
                      value={losses.wiring}
                      onChange={(e) =>
                        handleUpdateField(e.target.value, 'wiring')
                      }
                      step="0.10"
                      min="0.00"
                      required
                      disabled={user.role !== 'admin'}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Row>
              <Row className="my-1">
                <Col>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Connections</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="number"
                      value={parseFloat(losses.connections)}
                      onChange={(e) =>
                        handleUpdateField(e.target.value, 'connections')
                      }
                      step="0.10"
                      min="0.00"
                      required
                      disabled={user.role !== 'admin'}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Row>
              <Row className="my-1">
                <Col>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        Light Induced Degradation
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="number"
                      value={parseFloat(losses.lid)}
                      onChange={(e) => handleUpdateField(e.target.value, 'lid')}
                      step="0.10"
                      min="0.00"
                      required
                      disabled={user.role !== 'admin'}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Row>
              <Row className="my-1">
                <Col>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Nameplate Rating</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="number"
                      value={parseFloat(losses.nameplate_rating)}
                      onChange={(e) =>
                        handleUpdateField(e.target.value, 'nameplate_rating')
                      }
                      step="0.10"
                      min="0.00"
                      required
                      disabled={user.role !== 'admin'}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Row>
              <Row className="my-1">
                <Col>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Age</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="number"
                      value={parseFloat(losses.age)}
                      onChange={(e) => handleUpdateField(e.target.value, 'age')}
                      step="0.10"
                      min="0.00"
                      required
                      disabled={user.role !== 'admin'}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Row>
              <Row className="my-1">
                <Col>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Availability</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="number"
                      value={parseFloat(losses.availability)}
                      onChange={(e) =>
                        handleUpdateField(e.target.value, 'availability')
                      }
                      step="0.10"
                      min="0.00"
                      required
                      disabled={user.role !== 'admin'}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSetLosses}>
                Set System Losses
              </Button>
            </Modal.Footer>
          </Modal>
        </Col>
      </Row>
      <Row xs={1} md={2}>
        <Col>
          <Button id="shading-btn" className="w-100" onClick={runShading}>
            {/* {(isCompleted || isError) && 'Run Shading '} */}
            Run Shading 
            {(isLoading || serverSideShadingIsRunning) && (
              <Spinner animation="border" role="status" size="sm" />
            )}
            {isLoading &&
              `(${
                shadingStatus in monthNames ? monthNames[shadingMonth] : 'Jan'
              })`}
            {(isCompleted && !serverSideShadingIsRunning) && <Check2 size="24px" />}
            {isError && <X color="red" size="24px" />}
          </Button>
        </Col>
        {/* <Col>
          <Button
            className="w-100"
            onClick={toggleOverlay}
            disabled={!shaded}
          // disabled={!accordion || !konvaKeys.includes(accordion)}
          >
            {`${showOverlay ? 'Hide' : 'Show'} Production`}
            {/* <CloudSun size={24} />
          </Button>
        </Col> */}
        {/* <Col className="my-2">
          Shading Available
          {shaded ? <Check2 color="green" size={24} /> : <X color="red" size={24} />}
        </Col> */}
      </Row>
      {shadingStatus !== -1 && (
        <Row className="mt-3">
          <Col>
            <strong>
              Insolation (KWh/m
              <sup>2</sup>
              /year)
            </strong>
            <img
              src="/cmap_inferno.png"
              width="100%"
              height={30}
              alt="legend"
            />
            <div className="w-100 px-1 text-primary d-flex justify-content-between border border-primary border-bottom-0">
              <p className="m-0">{cmin}</p>
              <p className="m-0">{cmax}</p>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

Shading.propTypes = {
  shaded: PropTypes.bool.isRequired,
  colorRange: PropTypes.arrayOf(PropTypes.number).isRequired,
  shadingHandler: PropTypes.func.isRequired,
  showOverlay: PropTypes.bool.isRequired,
  toggleOverlay: PropTypes.func.isRequired,
};

export default Shading;
