import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Row, Col, Card, Button, Spinner } from 'react-bootstrap';
import { PencilSquare, Download, CloudSun } from 'react-bootstrap-icons';
import JsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
import { toPng } from 'html-to-image';
import Pluralize from 'pluralize';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import ValidatedForm from 'components/Form/Inputs';
import Loading from 'components/Loading';
import SaveIcon from 'components/Save';
import { formatCurrency } from 'Main/utils';

import html2canvas from 'html2canvas';

import {
  calculateLoan,
  calculateEscalation,
  calculateSolar,
  calculateSolarWithNoShading,
  calculateSavings,
  calculateCosts,
  getProposalState,
  getRates,
} from 'Leads/Details/DesignTool/Canvas3D/algorithms/proposal';

import { DesignContext } from '../../DesignTool/Canvas3D/contexts/designContext';
import Canvas3D from 'Leads/Details/DesignTool/Canvas3D';

import { ios } from 'Leads/Details/DesignTool/utils';

import TogglePanels from './TogglePanels';
import Controls from './Controls';
import PaybackChart from './PaybackChart';
import SolarPowerChart from './SolarPowerChart';
// import ShadeReport from '../../ShadeReport';
import LoanReport from './LoanReport';
import {
  DiscountModal,
  AddersModal,
  SelectModal,
  EscalationModal,
  ShadingReportModal,
} from './Modals';
import getImages from './ChartsImage';
import { filterDictByKeys } from '../../DesignTool/Canvas3D/algorithms/utils';
import IFrameLogo from 'components/IFrameLogo';
import { getSnowLoss, convertStateToAbbr } from 'options';
import axios from 'axios';
import PortalRedirect from '../PortalRedirect';
import OtherDetails from 'Leads/Details/LeadDetails/OtherDetails';
import { calculateNetMetering, calculateBilling } from './NetMetering';
import { calculateAnnualNetMeteringV2, AnnualNetMeteringTable } from './NetMetering/AnnualNetMetering';
import { MonthlyNetMeteringTable } from './NetMetering/MonthlyNetMetering';
import { calculateMonthlyNetMeteringV2 } from './NetMetering/MonthlyNetMetering';
import { calculateNetBillingV2, NetBillingTable } from './NetMetering/NetBilling';

const SCALE = 0.688;
class Report extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // control PanelDesign component
      modal: null,
      scale: SCALE,
      visibility: true,
      showOverlay: false,

      // data
      designData: {},

      // hardware
      panel: {},
      inverter: {},
      companyPanel: null,
      companyInverter: null,
      monitoring: {},
      mounting: {},
      adders: {},
      permitCost: 0,
      selectedPanels: null,

      // financials
      // monthlyBill: 0,
      escalation: 0.03,
      yearlySavings: [],
      offset: 0,
      totalPanels: 0,
      // totalInverters: 0,
      systemSize: 0,
      // totalSavings: 0,
      annualUsage: 0,
      solarProduction: 0,
      monthlyProduction: [],
      paybackYear: 0,
      paybackMonth: 0,
      lifetimeSavings: [],
      systemCost: 0,
      adderCost: 0,
      adderNRCost: 0,
      hiddenAdderCost: 0,
      visibleAdderCost: 0,
      preRebateCost: 0,
      discountSystemCost: 0,
      totalCost: 0,
      discountAmount: 0,
      discountCap: 0,
      rebateAmount: 0,
      credits: [],

      // loan calculator
      loanSwitch: false,
      loan: {
        loaner: 'DEFAULT',
        total: 0,
        amount: 0,
        downPayment: 0,
        rebated: true,
        monthlyPayments: 0,
        interestRate: 5,
        years: 25,
        fees: 0,
        lifetimeSavings: [],
      },
      loanObj: null,
      selectedObjects: {
        roofSegments: [],
        roofVertices: {},
        roofLines: {},
        obstacles: [],
        fireSetbacks: {},
        panels: {},
        trees: [],
      },
      viewMode: '3D',
      shadingTexture: null,
      shadingMonth: -1,
      panelShadings: [],
      monthlyPanelShadings: [],
      shadingReportData: null,
      exporting: false,
      proposalWithShadings: true,
      viewerMode: false,
      portalLoading: false,
      showNetMeteringVerification: false,
      netMeteringCalculations: null,
      annotations: [],
    };
  }

  componentDidMount() {
    const {
      pid,
      API,
      lead,
      hardware,
      callback,
      currentUser: advisor,
    } = this.props;

    console.log('Props from report index.js: ', this.props);

    const { loan } = this.state;

    this.updateCallback = callback;

    API.retrieve(pid).then((data) => {
      const state = getProposalState(lead, data, hardware, loan);
      console.log('State from report index.js: ', state);

      this.setState(state);

      // run solar calculations for first time if there is no summary saved
      if (data.summary) {
        this.calcSavings();
      } else if (state.proposalWithShadings === true) {
        setTimeout(() => this.solarCalculations(), 200);
        setTimeout(() => this.solarCalculations(), 200);
      } else {
        setTimeout(() => this.solarCalculationsWithNoShading(), 100);
        setTimeout(() => this.solarCalculationsWithNoShading(), 100);
      }

      this.syncSelectedPanelFrom2D();

      // Calculate net metering after solar calculations are done
      //setTimeout(() => this.calculateNetMetering(), 300);
    });

    const logoImg = new Image();
    logoImg.onload = () =>
      this.setState({ logoWidth: logoImg.width, logoHeight: logoImg.height });
    logoImg.src = advisor.company?.logo;

    const { resolution } = lead.property;
    this.resolution = Number(resolution) / 100.0;
  }

  componentDidUpdate(prevProps) {
    const prev = prevProps.lead.utility;
    console.log('prevProps.lead.utility: ', prevProps.lead.utility);


    const {
      lead: {
        utility: { averageBill, effectiveRate, fixedCharge },
      },
    } = this.props;
    if (
      (prev.averageBill || 0) !== (averageBill || 0) ||
      prev.effectiveRate !== effectiveRate ||
      prev.fixedCharge !== fixedCharge
    )
      this.calcSavings();
  }

  copyProposal = (id = null) => {
    const { pid, API } = this.props;
    if (id !== null) {
      API.send('GET', null, `${id}/duplicate/`).then((data) => {
        if (this.updateCallback) {
          this.updateCallback('CopyProposal', data.id);
        }
      });
      // proposalAPI.update(id, { id, name, copy: true }, true)
    } else {
      API.send('GET', null, `${pid}/duplicate/`).then((data) => {
        if (this.updateCallback) {
          this.updateCallback('CopyProposal', data.id);
        }
      });
      // proposalAPI.update(id, { id, name, copy: true }, true)
    }
  };

  // utility function to close modals
  hideModal = () => this.setState({ modal: null });

  // handlers for equipment selections
  solarPanelChangeHandler = (value) => {
    const { hardware, lead } = this.props;
    const { panel: currentPanel, proposalWithShadings } = this.state;
    const panel = hardware.panels.find(({ id }) => id === value);

    // change inverter if 'has_micro' attribute has changed
    let { inverter } = this.state;
    const statePackage = hardware.packages.filter(
      (item, index) => item.state === lead.address.state
    )[0];
    const defaultPackage = hardware.packages.filter(
      (item, index) => item.state === null
    )[0];
    const selectedPackage =
      statePackage !== undefined ? statePackage : defaultPackage;
    const defaultInverter = Object(
      selectedPackage.inverter || hardware.inverters[0]
    );
    if (panel.has_micro !== currentPanel.has_micro) {
      inverter = panel.has_micro ? Object() : defaultInverter;
    }
    this.setState({ panel, inverter }, () => {
      if (proposalWithShadings === true) {
        this.solarCalculations();
      } else {
        this.solarCalculationsWithNoShading();
      }
    });
  };

  invertersChangeHandler = (value) => {
    if (value === '') return;
    const { hardware } = this.props;
    const inverter = hardware.inverters.find(({ id }) => id === value);
    this.setState({ inverter }, () => this.calcCosts());
  };

  mountingChangeHandler = (value) => {
    const { hardware } = this.props;
    const mounting = Object(hardware.mounting.find(({ id }) => id === value));
    this.setState({ mounting }, () => this.calcCosts());
  };

  monitoringChangeHandler = (value) => {
    const { hardware } = this.props;
    const monitoring = Object(
      hardware.monitoring.find(({ id }) => id === value)
    );
    this.setState({ monitoring }, () => this.calcCosts());
  };

  addersUpdateHandler = (adders) => {
    this.setState({ modal: null, adders }, () => this.calcCosts());
  };

  deleteAdder = (id) => {
    this.setState(
      ({ adders }) => {
        const addr = adders[id];
        addr.count = 0;
        return { adders: { [id]: addr, ...adders } };
      },
      () => this.calcCosts()
    );
  };

  // handlers for utility-related values
  utilityEscalationHandler = (value) => {
    console.log('Escalation value received:', value);
    const escalation = value / 100;
    console.log('Escalation converted to decimal:', escalation);
    this.setState({ escalation }, () => {
      console.log('State updated with escalation:', this.state.escalation);
      this.calcSavings();
    });
  };

  // re-calculate annual usage and panel selection when bill is changed
  // monthlyBillHandler = (monthlyBill) => {
  //   const { lead: { utility: { fixed_fee: fixed, rate } } } = this.props;
  //   const annualUsage = ((monthlyBill - fixed) / rate) * 12;

  //   this.setState(
  //     { modal: null, monthlyBill, annualUsage },
  //     () => this.solarCalculations(),
  //   );
  // };

  loanChangeHandler = (loan) => this.setState({ loan }, () => this.calcCosts());

  setLoanObj = (loanObj) => this.setState({ loanObj });

  // handles financials for a loan payment
  loanCalculator = () => {
    const { loan, yearlySavings, totalCost, rebateAmount } = this.state;
    const data = calculateLoan(loan, yearlySavings, totalCost, rebateAmount);
    // if (this.updateCallback) this.updateCallback('Loan', data);
    this.setState({ ...data });
  };

  solarCalculations = async () => {
    // console.log('solar calc. -with- shading');
    if (this.processSolarCalculations) return;
    this.processSolarCalculations = true;

    const {
      panel,
      annualUsage,
      design,
      panelShadings: statePanelShadings,
      monthlyPanelShadings: stateMonthlyPanelShadings,
    } = this.state;

    let panelShadings = statePanelShadings;
    let monthlyPanelShadings = stateMonthlyPanelShadings;
    if (!panelShadings || panelShadings.length === 0) {
      panelShadings = await this.canvas3DElement.getAnualPanelsShadings();
      monthlyPanelShadings = panelShadings.pop();
    }

    const selectedPanels = calculateSolar(
      panel,
      annualUsage,
      design,
      panelShadings
    );

    this.setState(
      {
        selectedPanels,
        panelShadings,
        monthlyPanelShadings,
      },
      () => {
        this.syncSelectedPanelFrom2D();
        this.calcSavings();
        this.processSolarCalculations = false;
      }
    );

    console.log('NET_METERING_DEBUG: Solar Calculations:', JSON.stringify({
      state: {
        panel: this.state.panel,
        annualUsage: this.state.annualUsage,
        /*  design: this.state.design, */
        /* panelShadings: this.state.panelShadings, */
        /*  monthlyPanelShadings: this.state.monthlyPanelShadings */
      }
    }, null, 2));
  };

  solarCalculationsWithOffset = async (offset) => {
    // console.log('solar calc. -with- shading');
    if (this.processSolarCalculations) return;
    this.processSolarCalculations = true;

    const {
      panel,
      annualUsage,
      design,
      panelShadings: statePanelShadings,
      monthlyPanelShadings: stateMonthlyPanelShadings,
    } = this.state;

    let panelShadings = statePanelShadings;
    let monthlyPanelShadings = stateMonthlyPanelShadings;
    if (!panelShadings || panelShadings.length === 0) {
      panelShadings = await this.canvas3DElement.getAnualPanelsShadings();
      monthlyPanelShadings = panelShadings.pop();
    }

    const selectedPanels = calculateSolar(
      panel,
      annualUsage * (offset / 100),
      design,
      panelShadings
    );

    this.setState(
      {
        selectedPanels,
        panelShadings,
        monthlyPanelShadings,
      },
      () => {
        this.syncSelectedPanelFrom2D();
        this.calcSavings();
        this.processSolarCalculations = false;
      }
    );
  };

  solarCalculationsWithNoShading = () => {
    // console.log('solar calc. -without- shading');
    // calculating usage per month
    const { panel, annualUsage, design, proposalWithShadings } = this.state;

    const selectedPanels = calculateSolarWithNoShading(
      panel,
      annualUsage,
      design,
      proposalWithShadings
    );

    this.setState(
      {
        selectedPanels,
      },
      () => {
        this.syncSelectedPanelFrom2D();
        this.calcSavings();
      }
    );
  };

  shadingValues = () => {
    const { iframe } = this.props;

    const { designData, design } = this.state;
    if (this.updateCallback) {
      const shadingPerRoof = design.map(
        (
          { id, annualAccess, annualTSRF, annualTOF, solarAccess, TSRF, TOF },
          idx
        ) => {
          const monthlySA = solarAccess;
          const monthlyTSRF = TSRF;
          const monthlyTOF = TOF;
          return {
            idx,
            annualSA: annualAccess,
            annualTSRF,
            annualTOF,
            monthlySA,
            monthlyTSRF,
            monthlyTOF,
          };
        }
      );
      this.updateCallback('ShadingValues', shadingPerRoof);
    }
  };

  pitchAndAzimuith = () => {
    const { design } = this.state;

    if (this.updateCallback) {
      const pitchAndAzimuthPerRoof = design.map(
        ({ id, azimuth, pitch }, idx) => {
          return { idx, azimuth, pitch };
        }
      );
      this.updateCallback('RoofOrientation', pitchAndAzimuthPerRoof);
    }
  };

  panelOrientation = () => {
    const { design, designData, selectedPanels } = this.state;

    const { segments } = designData;

    let pos = design.map(({ id, horizontal, vertical, panels }, idx) => {
      let selectedCount = 0;
      let hc = 0;
      let vc = 0;
      selectedPanels[idx].map((s, si) => {
        if (Number(s) === 1) {
          selectedCount += 1;
          if (panels[si].height < panels[si].width) {
            hc += 1;
          } else {
            vc += 1;
          }
        }
      });

      if (selectedCount > 0) {
        if ((hc > 0) & (vc > 0)) {
          return { idx, orientation: 'combination' };
        } else if ((hc > 0) & (vc === 0)) {
          return { idx, orientation: 'horizontal' };
        } else if ((hc === 0) & (vc > 0)) {
          return { idx, orientation: 'vertical' };
        }
      } else return { idx, orientation: 'N/A' };
    });

    if (this.updateCallback) {
      this.updateCallback('PanelOrientationPerArray', pos);
    }
    return pos;
  };

  productionPerPanel = async () => {
    const {
      proposalWithShadings,
      panel,
      design,
      selectedPanels,
      panelShadings: statePanelShadings,
      monthlyPanelShadings: stateMonthlyPanelShadings,
    } = this.state;

    if (this.updateCallback) {
      let panelShadings = statePanelShadings;
      let monthlyPanelShadings = stateMonthlyPanelShadings;
      // if (proposalWithShadings) {
      // if proposal is with shading analysis we can pass back per panel production
      if (!panelShadings || !panelShadings.length) {
        panelShadings = await this.canvas3DElement.getAnualPanelsShadings();
        monthlyPanelShadings = panelShadings.pop();
        this.setState({ panelShadings, monthlyPanelShadings });
      }
      const lstPanelShadings = design.map((d, di) =>
        panelShadings.filter(([ri]) => ri === di)
      );
      const panelkW = panel.power / 1000;
      const productionPerPanel = design.map(
        ({ id, annualAccess, annualTSRF, annualTOF, acAnnual }, idx) => {
          const roofPanels = selectedPanels[idx].map((s, si) => {
            if (lstPanelShadings[idx][si])
              return s * panelkW * lstPanelShadings[idx][si][6] * acAnnual;
            return 0;
          });
          return { idx, roofPanels };
        }
      );
      this.updateCallback('ProductionPerPanel', productionPerPanel);
      // }
    }
  };

  shadingValuesPerArray = async () => {
    if (this.updateCallback) {
      try {
        let panelMetrics = await this.calcPanelMetrics();
        this.updateCallback('ShadingValuesPerArray', {
          yearly: panelMetrics['panelMetrics'],
          monthly: panelMetrics['monthlyPanelMetrics'],
        });
      } catch (err) {
        console.log(`error in calcPanelMetrics: ${err}`);
      }
    }
  };

  hourlyProduction = async () => {
    if (this.updateCallback) {
      try {
        const { pid, lead, API } = this.props;
        const { panel, selectedPanels, panelShadings, monthlyPanelShadings, design } = this.state;
        API.send('GET', null, `${pid}/hourly_production/`).then(
          async (data) => {
            let hourly = JSON.parse(data)?.ac;
            let panelMetrics = await this.calcPanelMetrics();
            let yearly = panelMetrics['panelMetrics'];
            let monthly = panelMetrics['monthlyPanelMetrics'];

            let daysInMonths = [31, 29, 31, 29, 31, 30, 31, 31, 30, 31, 30, 30];

            let hourlyProductionPerArray = [];

            Object.values(hourly).forEach((rawHourliesForSegment, segIdx) => {
              let d = { idx: segIdx, ac_hourly: {} };

              Object.values(rawHourliesForSegment).forEach((acHoursInMonth, i) => {
                const rawMonthlySum = acHoursInMonth.reduce((a, b) => a + b, 0);

                let monthlyShadingFactor = monthly[i + 1][segIdx]?.arraySA;

                const finalMonthlyTarget = rawMonthlySum * monthlyShadingFactor;

                let ratio = 0;
                if (rawMonthlySum > 0) {
                  ratio = monthly[i + 1][segIdx]?.production / rawMonthlySum;
                }

                let adjustedHourly = acHoursInMonth.map((rawHour) => {
                  const scaledVal = rawHour * ratio;
                  return scaledVal;
                });

                d['ac_hourly'][i] = adjustedHourly;
              });

              hourlyProductionPerArray.push(d);
            });

            let v = 0;
            hourlyProductionPerArray.map((el, i) => {
              Object.values(el['ac_hourly']).map((elh, j) => {
                v += elh.reduce((a, b) => a + b, 0)
              });
            });
            console.log(`sum of hourly production data: ${v}`);

            this.updateCallback('HourlyProduction', hourlyProductionPerArray);

            console.log('NET_METERING_DEBUG: Hourly Production:', JSON.stringify({
              hourlyProductionPerArray,
              monthly: monthlyPanelMetrics,
              yearly: panelMetrics
            }, null, 2));
          }
        );
      } catch (err) {
        console.log(`error in ProductionPerHour: ${err}`);
      }
    }
  };

  calcSavings = async () => {
    const { iframe } = this.props;
    const {
      panel,
      design,
      selectedPanels,
      escalation,
      annualUsage,
      proposalWithShadings,
      panelShadings: statePanelShadings,
      monthlyPanelShadings: stateMonthlyPanelShadings,
      totalCost,
    } = this.state;

    console.log('Savings Calculation Started:', {
      escalation,
      monthlyProduction: this.state.monthlyProduction,
      systemSize: this.state.systemSize,
      utility: this.props.lead.utility
    });

    console.log('State from report index.js calcSavings: ', this.state);
    console.log('Props from report index.js calcSavings: ', this.props);
    const discount = !iframe ? this.props.currentUser.discount : 0;
    const {
      lead: { utility, property },
    } = this.props;

    // Log utility settings for debugging
    console.log('Utility Configuration:', {
      billing_type: utility.billing_type,
      net_metering_specs: utility.net_metering_specs,
      net_billing_specs: utility.net_billing_specs,
      base_rate: utility.rate_amount,
      export_rate: utility.export_rate
    });

    let panelShadings = statePanelShadings;
    let monthlyPanelShadings = stateMonthlyPanelShadings;
    try {
      if (!panelShadings || !panelShadings.length) {
        panelShadings = await this.canvas3DElement.getAnualPanelsShadings();
        monthlyPanelShadings = panelShadings.pop();
        this.setState({ panelShadings, monthlyPanelShadings });
      }
    } catch (err) {
      console.log(err);
    }

    const [providers, updatedUtility, rate] = await getRates(property, utility);
    const data = calculateSavings(
      panel,
      design,
      selectedPanels,
      escalation,
      annualUsage,
      proposalWithShadings,
      updatedUtility,
      panelShadings,
      discount,
      utility
    );

    const {
      offset,
      totalPanels,
      systemSize,
      solarProduction,
      monthlyProduction,
      yearlySavings,
      discountCap,
      numTotalPanels,
    } = data;

    // Get monthly consumption from utility
    const monthlyConsumption = Array.isArray(utility.usage)
      ? utility.usage.map(usage => Number(usage))
      : new Array(12).fill(Number(utility.totalUsage) / 12);

    // Calculate savings based on billing type
    let savingsResults;
    let yearlySavingsToUse;
    let lifetimeSavingsToUse;

    if (!utility.billing_type) {
      // Use original calculation flow when billing_type is null
      this.setState({ ...data }, () => {
        this.calcCosts();
      });
      return;
    }

    if (utility.billing_type === 'net_billing') {
      // Use Net Billing calculations
      savingsResults = calculateNetBillingV2({
        monthlyProduction,
        monthlyConsumption,
        systemSize,
        utility,
        panel,
        escalation
      });

      // Get yearly savings and make them cumulative
      yearlySavingsToUse = savingsResults.yearlyResults
        .slice(0, 25)
        .reduce((acc, year, index) => {
          const currentYearSavings = year.annualTotals.savings;
          acc[index] = index === 0 ? currentYearSavings : acc[index - 1] + currentYearSavings;
          return acc;
        }, []);

      lifetimeSavingsToUse = yearlySavingsToUse.map(value => value - totalCost);

    } else if (utility.net_metering_specs?.metering_period === 'monthly') {
      // Use Monthly Net Metering calculations
      savingsResults = calculateMonthlyNetMeteringV2({
        monthlyProduction,
        monthlyConsumption,
        systemSize,
        utility,
        panel,
        escalation
      });

      // Get yearly savings and make them cumulative
      yearlySavingsToUse = savingsResults.yearlyResults
        .slice(0, 25)
        .reduce((acc, year, index) => {
          const currentYearSavings = year.annualTotals.savings;
          acc[index] = index === 0 ? currentYearSavings : acc[index - 1] + currentYearSavings;
          return acc;
        }, []);

      lifetimeSavingsToUse = yearlySavingsToUse.map(value => value - totalCost);

    } else {
      // Use Annual Net Metering calculations (default)
      savingsResults = calculateAnnualNetMeteringV2({
        monthlyProduction,
        monthlyConsumption,
        systemSize,
        utility,
        panel,
        escalation
      });

      // Get yearly savings and make them cumulative
      yearlySavingsToUse = savingsResults.yearlyResults
        .slice(0, 25)
        .reduce((acc, year, index) => {
          const currentYearSavings = year.annualTotals.savings;
          acc[index] = index === 0 ? currentYearSavings : acc[index - 1] + currentYearSavings;
          return acc;
        }, []);

      lifetimeSavingsToUse = yearlySavingsToUse.map(value => value - totalCost);
    }

    // Update state with calculated values
    this.setState({
      ...data,
      yearlySavings: yearlySavingsToUse,
      lifetimeSavings: lifetimeSavingsToUse,
      netMeteringCalculations: savingsResults
    }, () => {
      this.calcCosts();
    });

    console.log('Savings Calculation Results:', {
      monthlyProduction,
      solarProduction,
      systemSize,
      utility: {
        billing_type: utility.billing_type,
        specs: utility.billing_type === 'net_billing' ? utility.net_billing_specs : utility.net_metering_specs
      },
      yearlySavings: yearlySavingsToUse,
      calculationType: utility.billing_type === 'net_billing' ? 'Net Billing' :
        utility.net_metering_specs?.metering_period === 'monthly' ? 'Monthly Net Metering' :
          'Annual Net Metering'
    });
  };

  calcCosts = async () => {
    const {
      lead: { property: ppt, address: leadAddress, utility },
      currentUser,
      hardware,
      iframe,
    } = this.props;
    const {
      totalPanels,
      systemSize,
      yearlySavings,
      permitCost,
      discountAmount,
      loan,
      loanSwitch,
    } = this.state;
    // installation settings cost per watt
    const {
      designData,
      panel,
      inverter,
      mounting,
      monitoring,
      adders,
      roofType,
    } = this.state;

    console.log('yearlySavings: ', yearlySavings);

    const data = await calculateCosts(
      ppt,
      leadAddress,
      currentUser,
      hardware,
      designData.selectedPackage,
      iframe,
      totalPanels,
      systemSize,
      yearlySavings,
      permitCost,
      discountAmount,
      loan,
      loanSwitch,
      panel,
      inverter,
      mounting,
      monitoring,
      adders,
      roofType
    );

    const { lifetimeSavings } = data;

    if (this.updateCallback)
      this.updateCallback('ProposalCosts', {
        estimatedYearlySavings: yearlySavings[0],
        estimated25YearSavings: yearlySavings[24],
        systemSize,
        yearlySavings,
        totalPanels,
        ...data,
      });
    this.setState({ ...data }, () => this.loanCalculator());
  };

  // calculate discount
  discountApplied = (amount) => {
    const discountAmount = amount;
    this.setState({ discountAmount }, () => this.calcCosts());
  };

  // set scaling
  setScale = (scale = SCALE) => this.setState({ scale });

  syncSelectedPanelFrom2D = () => {
    const {
      selectedPanels,
      selectedObjects,
      designData: { panels: designPanels },
    } = this.state;
    const panels = {};

    const getPanelIndex = (ri, pi) => {
      const hrCount = designPanels[ri].horizontal.points.length;
      const vcCount = designPanels[ri].vertical.points.length;
      if (pi >= vcCount) return pi - vcCount;
      return pi + hrCount;
    };

    selectedPanels.forEach((pnls, ri) => {
      const indices = pnls
        .map((v, i) => (v ? getPanelIndex(ri, i) : -1))
        .filter((v) => v !== -1);
      panels[ri] = indices;
    });

    this.setState({ selectedObjects: { ...selectedObjects, panels } });
  };

  syncSelectedPanelFrom3D = () => {
    const {
      selectedPanels: selected,
      selectedObjects,
      designData: { panels: designPanels },
    } = this.state;
    const selectedPanels = selected.map((panels) =>
      Array(panels.length).fill(0)
    );

    const getPanelIndex = (ri, pi) => {
      const hrCount = designPanels[ri].horizontal.points.length;
      const vcCount = designPanels[ri].vertical.points.length;
      if (pi >= hrCount) return pi - hrCount;
      return pi + vcCount;
    };

    Object.keys(selectedObjects.panels).forEach((ri) => {
      const pnls = selectedObjects.panels[ri];
      const indices = pnls.map((v, i) => getPanelIndex(ri, v));
      indices.forEach((pi) => {
        selectedPanels[ri][pi] = 1;
      });
    });

    this.setState({ selectedPanels }, () => this.calcSavings());
  };

  addToSelectedObjects = (index, objectType, removeOld = false) => {
    if (objectType !== 'panels') return;

    const { selectedObjects } = this.state;
    const objects = selectedObjects.panels;

    let newObjects;
    if (!Array.isArray(objects))
      newObjects =
        !removeOld && objects ? JSON.parse(JSON.stringify(objects)) : {};
    else newObjects = !removeOld && objects ? [...objects] : [];

    const items = Array.isArray(index) ? index : [index];

    items.forEach((s) => {
      const [pi, vi] = s.split('-');
      const nvi = parseInt(vi, 10);
      if (!(pi in newObjects)) newObjects[pi] = [];
      if (!newObjects[pi].includes(nvi)) newObjects[pi].push(nvi);
    });

    Object.keys(newObjects)
      .reverse()
      .forEach((i) => {
        if (newObjects[i] === undefined) delete newObjects[i];
      });

    this.setState({
      selectedObjects: { ...selectedObjects, panels: newObjects },
    });
    this.syncSelectedPanelFrom3D();
    // this.updateSelectedPanels();
  };

  removeFromSelectedObjects = (index, objectType) => {
    if (objectType !== 'panels') return;

    const { selectedObjects } = this.state;
    const objects = selectedObjects.panels;

    let newObjects;
    if (!Array.isArray(objects))
      newObjects = objects ? JSON.parse(JSON.stringify(objects)) : {};
    else newObjects = objects ? [...objects] : [];

    const items = Array.isArray(index) ? index : [index];

    items.forEach((s) => {
      const [pi, vi] = s.split('-');
      const nvi = parseInt(vi, 10);
      if (!(pi in newObjects)) return;
      if (newObjects[pi].includes(nvi))
        newObjects[pi].splice(newObjects[pi].indexOf(nvi), 1);
    });

    Object.keys(newObjects)
      .reverse()
      .forEach((i) => {
        if (newObjects[i] === undefined) delete newObjects[i];
      });

    this.setState({
      selectedObjects: { ...selectedObjects, panels: newObjects },
    });
    this.syncSelectedPanelFrom3D();
  };

  // handle toggling specific panels
  panelToggleHandler = (idx, jdx) => {
    const selectedPanels = [...this.state.selectedPanels];
    const panel = selectedPanels[idx][jdx];
    selectedPanels[idx][jdx] = panel ? 0 : 1;
    this.setState({ selectedPanels }, () => {
      this.syncSelectedPanelFrom2D();
      this.calcSavings();
    });
  };

  // deselect all panels
  togglePanelSelection = () => {
    const {
      selectedPanels: selected,
      totalPanels,
      proposalWithShadings,
    } = this.state;
    if (totalPanels !== 0) {
      const selectedPanels = selected.map((panels) =>
        Array(panels.length).fill(0)
      );
      this.setState({ selectedPanels }, () => {
        this.syncSelectedPanelFrom2D();
        this.calcSavings();
      });
    } else {
      if (proposalWithShadings === true) {
        this.solarCalculations();
      } else {
        this.solarCalculationsWithNoShading();
      }
    }
  };

  toggleAllPanels = () => {
    const {
      selectedPanels: selected,
      totalPanels,
      proposalWithShadings,
    } = this.state;
    if (totalPanels !== 0) {
      const selectedPanels = selected.map((panels) =>
        Array(panels.length).fill(0)
      );
      this.setState({ selectedPanels }, () => {
        this.syncSelectedPanelFrom2D();
        this.calcSavings();
        if (this.updateCallback) {
          this.updateCallback('proposal.ToggleAllPanels', '');
        }
      });
    } else {
      const selectedPanels = selected.map((panels) =>
        Array(panels.length).fill(1)
      );
      this.setState({ selectedPanels }, () => {
        this.syncSelectedPanelFrom2D();
        this.calcSavings();
        if (this.updateCallback) {
          this.updateCallback('proposal.ToggleAllPanels', '');
        }
      });
    }
  };

  toggleViewerMode = () => {
    const { viewerMode } = this.state;
    this.setState(
      {
        viewerMode: !viewerMode,
      },
      () => {
        if (this.updateCallback) {
          this.updateCallback('proposal.ToggleViewerMode', '');
        }
      }
    );
  };

  handleDownload = () => {
    this.setState({ visibility: false, showOverlay: false }, () => {
      setTimeout(() => {
        this.downloadPdf();
      }, 500);
    });
  };

  calcPanelMetrics = async () => {
    const data = {};
    const {
      panelShadings: statePanelShadings,
      monthlyPanelShadings: stateMonthlyPanelShadings,
      design,
      designData,
      selectedPanels,
      panel,
    } = this.state;

    const { segments } = designData;

    let panelShadings = statePanelShadings;
    let monthlyPanelShadings = stateMonthlyPanelShadings;
    if (!panelShadings || panelShadings.length === 0) {
      panelShadings = await this.canvas3DElement.getAnualPanelsShadings();
      monthlyPanelShadings = panelShadings.pop();
    }

    const lstPanelShadings = design.map((d, di) =>
      panelShadings.filter(([ri]) => ri === di)
    );

    const avgPanelMetrics = design.map(({ id, acMonthly }, idx) => {
      let segID = id.split('-')[id.split('-').length - 1];
      let segment = segments[idx];
      let sumSA = 0;
      let sumTOF = 0;
      let sumTSRF = 0;
      let count = 0;
      let selectedCount = 0;
      const roofPanels = selectedPanels[idx].map((s, si) => {
        count += 1;
        if (Number(s) === 1) {
          selectedCount += 1;
        }
        if (lstPanelShadings[idx][si]) {
          sumSA += s * lstPanelShadings[idx][si][6];
          sumTOF += s * lstPanelShadings[idx][si][7];
          sumTSRF += s * lstPanelShadings[idx][si][8];
        }
      });
      count = count - 1;
      if (selectedCount > 0) {
        let arraySA = sumSA / selectedCount;
        let arrayTOF = sumTOF / selectedCount;
        let arrayTSRF = sumTSRF / selectedCount;
        let arrayProduction =
          arraySA *
          selectedCount *
          (panel?.power / 1000) *
          acMonthly.reduce((a, b) => a + b, 0);
        arraySA = arraySA.toFixed(2);
        arrayTOF = arrayTOF.toFixed(2);
        arrayTSRF = arrayTSRF.toFixed(2);
        return {
          idx,
          selected: selectedCount,
          production: arrayProduction,
          pitch: segment ? Number(segment?.pitch).toFixed(2) : 'NaN',
          azimuth: segment ? Number(segment?.azimuth).toFixed(2) : 'NaN',
          arraySA,
          arrayTOF,
          arrayTSRF,
        };
      } else {
        let arraySA = 0;
        let arrayTOF = 0;
        let arrayTSRF = 0;
        return {
          idx,
          selected: selectedCount,
          pitch: segment ? Number(segment?.pitch).toFixed(2) : 'NaN',
          azimuth: segment ? Number(segment?.azimuth).toFixed(2) : 'NaN',
          arraySA,
          arrayTOF,
          arrayTSRF,
        };
      }
    });

    const avgMonthlyPanelMetrics = {};
    for (let i = 1; i <= 12; i += 1) {
      const lstMonthlyPanelShadings = design.map((d, di) =>
        monthlyPanelShadings[i].filter(([ri]) => ri === di)
      );
      const avgMPanelMetrics = design.map(({ id, acMonthly }, idx) => {
        let segID = id.split('-')[id.split('-').length - 1];
        let segment = segments[idx];
        let sumSA = 0;
        let sumTOF = 0;
        let sumTSRF = 0;
        let count = 0;
        let selectedCount = 0;
        const roofPanels = selectedPanels[idx].map((s, si) => {
          count += 1;
          if (Number(s) === 1) {
            selectedCount += 1;
          }
          if (lstMonthlyPanelShadings[idx][si]) {
            sumSA += s * lstMonthlyPanelShadings[idx][si][6];
            sumTOF += s * lstMonthlyPanelShadings[idx][si][7];
            sumTSRF += s * lstMonthlyPanelShadings[idx][si][8];
          }
        });
        count = count - 1;
        if (selectedCount > 0) {
          let arraySA = sumSA / selectedCount;
          let arrayTOF = sumTOF / selectedCount;
          let arrayTSRF = sumTSRF / selectedCount;
          let arrayProduction =
            arraySA * selectedCount * (panel?.power / 1000) * acMonthly[i - 1];
          arraySA = arraySA.toFixed(2);
          arrayTOF = arrayTOF.toFixed(2);
          arrayTSRF = arrayTSRF.toFixed(2);
          return {
            idx,
            selected: selectedCount,
            production: arrayProduction,
            pitch: segment ? Number(segment?.pitch).toFixed(2) : 'NaN',
            azimuth: segment ? Number(segment?.azimuth).toFixed(2) : 'NaN',
            arraySA,
            arrayTOF,
            arrayTSRF,
          };
        } else {
          let arraySA = 0;
          let arrayTOF = 0;
          let arrayTSRF = 0;
          let arrayProduction = 0;
          return {
            idx,
            selected: selectedCount,
            production: arrayProduction,
            pitch: segment ? Number(segment?.pitch).toFixed(2) : 'NaN',
            azimuth: segment ? Number(segment?.azimuth).toFixed(2) : 'NaN',
            arraySA,
            arrayTOF,
            arrayTSRF,
          };
        }
      });
      avgMonthlyPanelMetrics[i] = avgMPanelMetrics;
    }

    data['panelMetrics'] = avgPanelMetrics;
    data['monthlyPanelMetrics'] = avgMonthlyPanelMetrics;
    return data;
  };

  handleShadingReportDownload = async (params = null, showModal = true) => {
    const shadingReportData = {};

    const {
      panelShadings: statePanelShadings,
      monthlyPanelShadings: stateMonthlyPanelShadings,
      design,
      designData,
      selectedPanels,
      panel,
    } = this.state;

    const { segments } = designData;

    let panelShadings = statePanelShadings;
    let monthlyPanelShadings = stateMonthlyPanelShadings;
    // if (!panelShadings || panelShadings.length === 0) {
    //   panelShadings = await this.canvas3DElement.getAnualPanelsShadings();
    //   monthlyPanelShadings = panelShadings.pop();
    // }

    panelShadings = await this.canvas3DElement.getAnualPanelsShadings();
    monthlyPanelShadings = panelShadings.pop();

    const lstPanelShadings = design.map((d, di) =>
      panelShadings.filter(([ri]) => ri === di)
    );

    const avgPanelMetrics = design.map(({ id, acMonthly }, idx) => {
      let segID = id.split('-')[id.split('-').length - 1];
      let segment = segments[idx];
      let sumSA = 0;
      let sumTOF = 0;
      let sumTSRF = 0;
      let count = 0;
      let selectedCount = 0;
      const roofPanels = selectedPanels[idx].map((s, si) => {
        count += 1;
        if (Number(s) === 1) {
          selectedCount += 1;
        }
        if (lstPanelShadings[idx][si]) {
          sumSA += s * lstPanelShadings[idx][si][6];
          sumTOF += s * lstPanelShadings[idx][si][7];
          sumTSRF += s * lstPanelShadings[idx][si][8];
        }
      });
      count = count - 1;
      if (selectedCount > 0) {
        let arraySA = sumSA / selectedCount;
        let arrayTOF = sumTOF / selectedCount;
        let arrayTSRF = sumTSRF / selectedCount;
        let arrayProduction =
          arraySA *
          selectedCount *
          (panel?.power / 1000) *
          acMonthly.reduce((a, b) => a + b, 0);
        arraySA = arraySA.toFixed(2);
        arrayTOF = arrayTOF.toFixed(2);
        arrayTSRF = arrayTSRF.toFixed(2);
        return {
          id,
          panelCount: count,
          selected: selectedCount,
          production: arrayProduction,
          pitch: segment ? Number(segment?.pitch).toFixed(2) : 'NaN',
          azimuth: segment ? Number(segment?.azimuth).toFixed(2) : 'NaN',
          arraySA,
          arrayTOF,
          arrayTSRF,
        };
      } else {
        let arraySA = 0;
        let arrayTOF = 0;
        let arrayTSRF = 0;
        let arrayProduction = 0;
        return {
          id,
          panelCount: count,
          selected: selectedCount,
          production: arrayProduction,
          pitch: segment ? Number(segment?.pitch).toFixed(2) : 'NaN',
          azimuth: segment ? Number(segment?.azimuth).toFixed(2) : 'NaN',
          arraySA,
          arrayTOF,
          arrayTSRF,
        };
      }
    });

    const avgMonthlyPanelMetrics = {};
    for (let i = 1; i <= 12; i += 1) {
      const lstMonthlyPanelShadings = design.map((d, di) =>
        monthlyPanelShadings[i].filter(([ri]) => ri === di)
      );
      const avgMPanelMetrics = design.map(({ id, acMonthly }, idx) => {
        let segID = id.split('-')[id.split('-').length - 1];
        let segment = segments[idx];
        let sumSA = 0;
        let sumTOF = 0;
        let sumTSRF = 0;
        let count = 0;
        let selectedCount = 0;
        const roofPanels = selectedPanels[idx].map((s, si) => {
          count += 1;
          if (Number(s) === 1) {
            selectedCount += 1;
          }
          if (lstMonthlyPanelShadings[idx][si]) {
            sumSA += s * lstMonthlyPanelShadings[idx][si][6];
            sumTOF += s * lstMonthlyPanelShadings[idx][si][7];
            sumTSRF += s * lstMonthlyPanelShadings[idx][si][8];
          }
        });
        count = count - 1;
        if (selectedCount > 0) {
          let arraySA = sumSA / selectedCount;
          let arrayTOF = sumTOF / selectedCount;
          let arrayTSRF = sumTSRF / selectedCount;
          let arrayProduction =
            arraySA * selectedCount * (panel?.power / 1000) * acMonthly[i - 1];
          arraySA = arraySA.toFixed(2);
          arrayTOF = arrayTOF.toFixed(2);
          arrayTSRF = arrayTSRF.toFixed(2);
          return {
            id,
            panelCount: count,
            selected: selectedCount,
            production: arrayProduction,
            pitch: segment ? Number(segment?.pitch).toFixed(2) : 'NaN',
            azimuth: segment ? Number(segment?.azimuth).toFixed(2) : 'NaN',
            arraySA,
            arrayTOF,
            arrayTSRF,
          };
        } else {
          let arraySA = 0;
          let arrayTOF = 0;
          let arrayTSRF = 0;
          let arrayProduction = 0;
          return {
            id,
            panelCount: count,
            selected: selectedCount,
            production: arrayProduction,
            pitch: segment ? Number(segment?.pitch).toFixed(2) : 'NaN',
            azimuth: segment ? Number(segment?.azimuth).toFixed(2) : 'NaN',
            arraySA,
            arrayTOF,
            arrayTSRF,
          };
        }
      });
      avgMonthlyPanelMetrics[i] = avgMPanelMetrics;
    }

    shadingReportData['panelMetrics'] = avgPanelMetrics;
    shadingReportData['monthlyPanelMetrics'] = avgMonthlyPanelMetrics;

    this.setState({ visibility: false });
    this.canvas3DElement.toggleView('shading', false, true);
    await new Promise((r) => setTimeout(r, 500));
    const {
      url: canvas2DJPG,
      width: canvas3DJPGWidth,
      height: canvas3DJPGHeight,
    } = await this.canvas3DElement.exportToJPG();
    await new Promise((r) => setTimeout(r, 500));
    this.canvas3DElement.toggleView('shading', true, false);
    await new Promise((r) => setTimeout(r, 500));
    const {
      url: canvas3DJPG1,
      width: canvas3DJPGWidth1,
      height: canvas3DJPGHeight1,
    } = await this.canvas3DElement.exportToJPG(2, [70, 0, 60]);
    const {
      url: canvas3DJPG2,
      width: canvas3DJPGWidth2,
      height: canvas3DJPGHeight2,
    } = await this.canvas3DElement.exportToJPG(2, [-70, 0, 60]);

    shadingReportData['canvas2DJPG'] = canvas2DJPG;
    shadingReportData['canvas3DJPG1'] = canvas3DJPG1;
    shadingReportData['canvas3DJPG2'] = canvas3DJPG2;

    if (showModal === false) {
      const blobClient = axios.create();
      blobClient
        .get(canvas2DJPG, {
          responseType: 'blob',
        })
        .then((res) => {
          this.blobToDataURL(res.data, (dataUrl) => {
            shadingReportData['canvas2DJPG'] = dataUrl;
          });
        })
        .catch((err) => {
          return null;
        });
      blobClient
        .get(canvas3DJPG1, {
          responseType: 'blob',
        })
        .then((res) => {
          this.blobToDataURL(res.data, (dataUrl) => {
            shadingReportData['canvas3DJPG1'] = dataUrl;
          });
        })
        .catch((err) => {
          return null;
        });
      blobClient
        .get(canvas3DJPG2, {
          responseType: 'blob',
        })
        .then((res) => {
          this.blobToDataURL(res.data, (dataUrl) => {
            shadingReportData['canvas3DJPG2'] = dataUrl;
          });
        })
        .catch((err) => {
          return null;
        });
    }

    const {
      iframe,
      lead: { homeowners, address, property },
      currentUser: advisor,
      fullAddress,
      hardware,
    } = this.props;

    // if (!iframe) {
    //   const contact = homeowners[0];
    //   const customerName = `${contact?.first_name} ${contact?.last_name}`;
    //   const addressTop = address?.street;
    //   const addressBottom = `${address?.city}, ${address?.state}`;

    //   // solar advisor
    //   const company = advisor.company.name;
    //   const advisorName = `${advisor.first_name} ${advisor.last_name}`;

    //   shadingReportData['homeowner'] = customerName;
    //   shadingReportData['coordinates'] = property?.coordinates;
    //   shadingReportData['address'] = [addressTop, addressBottom];
    //   shadingReportData['client'] = company;
    //   shadingReportData['designer'] = advisorName;

    //   let options = { year: 'numeric', month: 'long', day: 'numeric' };
    //   let today  = new Date();

    //   shadingReportData['date'] = today.toLocaleDateString("en-US", options)
    // } else {
    //   // pass
    // }
    const contact = homeowners[0];
    const customerName = `${contact?.first_name} ${contact?.last_name}`;
    const addressTop = address?.street;
    const addressBottom = `${address?.city}, ${address?.state}`;

    // solar advisor
    const company = advisor.company.name;
    const advisorName = `${advisor.first_name} ${advisor.last_name}`;

    shadingReportData['homeowner'] = customerName;
    shadingReportData['coordinates'] = property?.coordinates;
    shadingReportData['address'] = `${addressTop}, ${addressBottom}`;
    shadingReportData['client'] = company;
    // shadingReportData['designer'] = advisorName;

    if (iframe && params && params?.homeowner) {
      shadingReportData['homeowner'] = params?.homeowner;
      shadingReportData['coordinates'] = [
        params?.coordinates.split(',')[0].trim(),
        params?.coordinates.split(',')[1].trim(),
      ];
      shadingReportData['address'] = params?.address;
      shadingReportData['client'] = params?.client;
    }

    let options = { year: 'numeric', month: 'long', day: 'numeric' };
    let today = new Date();

    shadingReportData['date'] = today.toLocaleDateString('en-US', options);

    let modal = 'shading-report';
    if (showModal === false) modal = null;

    this.setState({
      shadingReportData,
      modal,
      visibility: true,
    });
  };

  dataURLtoBlob = (dataurl) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    // eslint-disable-next-line no-plusplus
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  blobToDataURL = (blob, callback) => {
    const a = new FileReader();
    a.onload = (e) => {
      callback(e.target.result);
    };
    a.readAsDataURL(blob);
  };

  captureThumbnail = async () => {
    try {
      // Step 1: Get the canvas image in base64 format
      const { base64 } = await this.canvas3DElement.exportThumbnail();

      if (!base64) {
        throw new Error(
          'Failed to retrieve canvas image from exportThumbnail.'
        );
      }

      // Step 2: Access the parent container of the canvas
      const parentContainer = document.getElementsByClassName('container3d')[0];

      if (!parentContainer) {
        throw new Error('Parent container not found for the canvas element.');
      }

      // Step 3: Capture the annotations (HTML overlay) using html2canvas
      const annotationsCanvas = await html2canvas(parentContainer, {
        useCORS: true,
        ignoreElements: (element) => {
          console.log(`element.tagName: ${element.tagName}`);
          return element.tagName === 'CANVAS'; // Skip the WebGL canvas
        },
        logging: false,
        scale: 1,
        backgroundColor: null,
      });
      const annotationsDataUrl = annotationsCanvas.toDataURL('image/png');

      // Step 4: Merge the canvas (from base64) and annotations
      const combinedCanvas = document.createElement('canvas');
      const context = combinedCanvas.getContext('2d');

      // Set dimensions for the combined canvas
      combinedCanvas.width = annotationsCanvas.width;
      combinedCanvas.height = annotationsCanvas.height;

      const canvasImage = new Image();
      const annotationsImage = new Image();

      canvasImage.src = base64; // Use the base64 from exportThumbnail
      annotationsImage.src = annotationsDataUrl;

      // Wait for both images to load and then draw them sequentially
      await Promise.all([
        new Promise((resolve) => (canvasImage.onload = resolve)),
        new Promise((resolve) => (annotationsImage.onload = resolve)),
      ]);

      // Draw the canvas image first
      context.drawImage(
        canvasImage,
        0,
        0,
        combinedCanvas.width,
        combinedCanvas.height
      );

      // Draw the annotations on top
      context.drawImage(
        annotationsImage,
        0,
        0,
        combinedCanvas.width,
        combinedCanvas.height
      );

      // Step 5: Export the combined image as a data URL
      const finalDataUrl = combinedCanvas.toDataURL('image/png');

      // Remove the "data:image/png;base64," prefix
      const base64Data = finalDataUrl.replace(/^data:image\/png;base64,/, '');

      // Step 6: Send the base64 data to the update callback
      if (this.updateCallback) {
        this.updateCallback('proposal.CaptureThumbnail', base64Data);
      }

      return base64Data;
    } catch (err) {
      console.error('Error capturing thumbnail:', err);
      if (this.updateCallback) {
        this.updateCallback('Error', {
          code: 86,
          message: 'Error in proposal.CaptureImage',
        });
      }
    }
  };

  captureImage = async () => {
    const imageData = await this.canvas3DElement.exportToJPG();
    const blobClient = axios.create();
    blobClient
      .get(imageData.url, {
        responseType: 'blob',
      })
      .then((res) => {
        console.log(res.data);
        this.blobToDataURL(res.data, (dataUrl) => {
          if (this.updateCallback) {
            this.updateCallback('proposal.CaptureImage', dataUrl);
          }
        });
      })
      .catch((err) => {
        console.log(err);
        if (this.updateCallback) {
          this.updateCallback('Error', {
            code: 86,
            message: 'Error in proposal.CaptureImage',
          });
        }
      });
    return imageData;
  };

  downloadShadingReport = async (params = null) => {
    let logoUrl = '/logo.png';
    const { iframe, callback } = this.props;
    const { shadingReportData } = this.state;
    this.setState({
      exporting: true,
    });
    await new Promise((r) => setTimeout(r, 500));

    if (shadingReportData === null || iframe) {
      await this.handleShadingReportDownload(params);
    }

    if (iframe) {
      await new Promise((r) => setTimeout(r, 3500));
    }

    const htmlElement = document.querySelector('#shading-report');
    const opt = {
      margin: [24, 24, 24, 24],
      filename: 'shading-report.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, allowTaint: false, letterRendering: true },
      pagebreak: { mode: 'avoid-all', before: '#page2el' },
      jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' },
    };
    // New Promise-based usage:
    if (iframe && this.updateCallback) {
      if (params?.format) {
        if (params?.format === 'pdf') {
          await html2pdf()
            .set(opt)
            .from(htmlElement)
            .save()
            .then(function (pdf) {
              callback('proposal.ShadingReportPDF', '');
            });
        } else if (params?.format === 'base64') {
          await html2pdf()
            .set(opt)
            .from(htmlElement)
            .outputPdf()
            .then(function (pdf) {
              // This logs the right base64
              // console.log(btoa(pdf));
              callback('proposal.ShadingReportBase64', btoa(pdf));
            });
        }
      } else {
        await html2pdf()
          .set(opt)
          .from(htmlElement)
          .save()
          .then(function (pdf) {
            callback('proposal.ShadingReportPDF', '');
          });
      }
    } else {
      await html2pdf().set(opt).from(htmlElement).save();
    }

    await new Promise((r) => setTimeout(r, 2500));
    this.setState({
      exporting: false,
      modal: '',
    });
  };

  downloadPdf = async () => {
    const primaryColor = '#333F50';
    const black = '#000000';
    const white = '#FFFFFF';
    const lightGrey = '#F4F5F8';

    const dateLong = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date().toLocaleString('en-US', dateLong);

    const pdf = new JsPDF('p');
    const width = pdf.internal.pageSize.getWidth();
    const height = pdf.internal.pageSize.getHeight();

    // TODO figure out specifics of adding logo to PDF
    // let logoUrl = '/logo.png';
    const pageOneContentWidth = width / 2.2 + 15;
    // let adjustHeight = 20;
    let aspectRatio = this.state.logoWidth / this.state.logoHeight;
    let imgWidth = width - pageOneContentWidth - 60;
    let imgHeight = imgWidth / aspectRatio;

    // customer data
    const {
      lead: { homeowners, address, property },
      currentUser: advisor,
      fullAddress,
      hardware,
    } = this.props;
    const contact = homeowners[0];
    const customerName = `${contact.first_name} ${contact.last_name}`;
    const addressTop = address.street;
    const addressBottom = `${address.city}, ${address.state}`;
    console.log('advisor: ', advisor);

    // solar advisor
    const company = advisor.company.name;
    let logoUrl = advisor.company?.logo;

    console.log('logoUrl: ', logoUrl);


    const advisorName = `${advisor.first_name} ${advisor.last_name}`;

    // Title Page
    pdf.addImage('/pdfHomePage.png', 10, 10, width / 2.2, height - 20);
    pdf.setFont('Helvetica', 'bold');
    pdf.setFontSize(50);
    pdf.setTextColor(primaryColor);
    pdf.text('Custom', pageOneContentWidth, 23);
    pdf.text('Solar', pageOneContentWidth, 43);
    pdf.text('Design', pageOneContentWidth, 63);

    pdf.setFont('Helvetica', 'normal');
    pdf.setFontSize(12);
    pdf.setTextColor(black);
    pdf.text(date, pageOneContentWidth, 75);

    pdf.setFont('Helvetica', 'bold');
    pdf.setFontSize(14);
    pdf.setTextColor(black);
    pdf.text('PREPARED FOR', pageOneContentWidth, 110);

    pdf.setFont('Helvetica', 'normal');
    pdf.setFontSize(12);
    pdf.setTextColor(black);
    pdf.text(customerName, pageOneContentWidth, 115);
    pdf.text(addressTop, pageOneContentWidth, 120);
    pdf.text(addressBottom, pageOneContentWidth, 125);

    pdf.setFont('Helvetica', 'bold');
    pdf.setFontSize(14);
    pdf.setTextColor(black);
    pdf.text('PREPARED BY', pageOneContentWidth, 150);

    pdf.setFont('Helvetica', 'normal');
    pdf.setFontSize(12);
    pdf.setTextColor(black);
    pdf.text(advisorName, pageOneContentWidth, 155);
    pdf.text(company, pageOneContentWidth, 160);
    pdf.text(advisor.email, pageOneContentWidth, 165);
    pdf.text(advisor.phone || '', pageOneContentWidth, 170);

    if (logoUrl) {
      pdf.addImage(
        logoUrl,
        'PNG',
        pageOneContentWidth,
      /* height-20 */ 185,
        imgWidth,
        imgHeight
      );


    }



    // const pageOneHeight = 60

    /* pdf.setFont("Helvetica","bold")
      pdf.setFontSize(80)
      pdf.setTextColor("#22919E")
      pdf.text("AERIALYTIC", 45,300) */

    pdf.addPage();
    pdf.setFillColor(lightGrey);
    pdf.rect(0, 0, width, height, 'F');

    // hardware
    const { panel, inverter, mounting, monitoring, loanSwitch } = this.state;
    let panelName = panel.manufacturer
      ? `${panel.manufacturer.name} ${panel.model}`
      : '';
    panelName = pdf.splitTextToSize(panelName, 30);
    // let solarPanelModelpdf = this.state.panelsSelected.model;

    // financial info
    // TODO: clean this up? what values can we pre-format
    const solarProduction = `${this.state.solarProduction.toFixed(2)} kWh`;
    const annualUsage = `${this.state.annualUsage.toFixed(2)} kWh`;
    const offset = `${this.state.offset} %`;
    const systemSize = `${this.state.systemSize.toFixed(2)} kW`;
    const totalCost = formatCurrency(this.state.totalCost);
    const yearlySavings = formatCurrency(this.state.yearlySavings[0]);
    const payback = (
      this.state.paybackYear +
      this.state.paybackMonth / 12
    ).toFixed(1);

    const lifetimeSavings = loanSwitch
      ? formatCurrency(this.state.loan.lifetimeSavings[24])
      : formatCurrency(this.state.lifetimeSavings[24]);

    // loan related finances
    // const { amount, interestRate, years} = this.state.loan;
    const monthlyPayments = formatCurrency(this.state.loan.monthlyPayments);

    // TODO: write this to PDF somewhere?
    // const loanSummary = pdf.splitTextToSize(
    //   `For a ${formatCurrency(amount)} loan at ${interestRate}% interest with a ${years} year amortization, your monthly payments would be ${monthlyPayments}`,
    //   100,
    // );

    const houseImage = document.querySelector('.canvas3d').children[0];
    const solarGraphChart =
      document.querySelector('#solarGraphChart').children[1];
    const paybackChart = document.querySelector('#paybackChart').children[1];
    const footNote =
      'The savings, production and estimated payback period on this Custom Solar Design is an estimate only.';

    // pdf.addImage('/logo.png', 'PNG', width-imgWidth-5, 10, imgWidth, imgHeight)
    pdf.setFillColor(primaryColor);
    pdf.roundedRect(5, 10, width - 10, 25, 1, 1, 'F');

    pdf.setFont('Helvetica', 'normal');
    pdf.setFontSize(12);
    pdf.setTextColor(white);
    pdf.text('Here is your custom solar design for', 10, 20);

    pdf.setFont('Helvetica', 'bold');
    pdf.setFontSize(18);
    pdf.setTextColor(white);
    pdf.text(fullAddress, 10, 28);

    const {
      url: canvas3DJPG,
      width: canvas3DJPGWidth,
      height: canvas3DJPGHeight,
    } = await this.canvas3DElement.exportToJPG();
    pdf.addImage(canvas3DJPG, 'JPEG', 25, 38, width - 50, 138);

    const savingsHeight = height / 2.5 + 60;
    pdf.setFillColor(primaryColor);
    pdf.roundedRect(5, savingsHeight, width - 10, 25, 1, 1, 'F');

    pdf.setFont('Helvetica', 'bold');
    pdf.setFontSize(25);
    pdf.setTextColor(white);
    pdf.text(yearlySavings, 10, savingsHeight + 12);
    pdf.text(lifetimeSavings, width / 2 - 20, savingsHeight + 12);
    pdf.text(
      loanSwitch ? monthlyPayments : payback,
      width - 50,
      savingsHeight + 12
    );

    // Add "months" as payback period if on Cash tab
    pdf.setFontSize(12);
    if (!loanSwitch) pdf.text('months', width - 36, savingsHeight + 12);

    pdf.setFont('Helvetica', 'normal');
    pdf.setTextColor(white);
    pdf.text('First Year Savings', 10, savingsHeight + 18);
    pdf.text('25 Year Savings', width / 2 - 15, savingsHeight + 18);
    pdf.text(
      loanSwitch ? 'Monthly Payment' : 'Payback Period',
      width - 50,
      savingsHeight + 18
    );

    // overview & equipment sections
    const overviewHeight = savingsHeight + 28;
    pdf.setFillColor(white);
    pdf.setDrawColor('#ECECEC');
    pdf.roundedRect(5, overviewHeight, width / 2 - 5, 82, 1, 1, 'DF');
    pdf.roundedRect(
      width / 2 + 5,
      overviewHeight,
      width / 2 - 10,
      82,
      1,
      1,
      'DF'
    );

    pdf.setFont('Helvetica', 'bold');
    pdf.setTextColor(black);
    pdf.setFontSize(12);
    pdf.text('Overview', 10, overviewHeight + 6);

    pdf.setFont('Helvetica', 'normal');
    pdf.text('Offset', 10, height / 2.5 + 90 + 13);
    pdf.text('System Size', 10, height / 2.5 + 105 + 13);
    pdf.text('Total Cost', 10, height / 2.5 + 120 + 13);
    pdf.text('Electricity Usage', 10, height / 2.5 + 135 + 13);
    pdf.text('Solar Production', 10, height / 2.5 + 150 + 13);

    pdf.setFont('Helvetica', 'bold');
    pdf.text(offset, width / 2 - 40, height / 2.5 + 90 + 13);
    pdf.text(systemSize, width / 2 - 40, height / 2.5 + 105 + 13);
    pdf.text(totalCost, width / 2 - 40, height / 2.5 + 120 + 13);
    pdf.text(annualUsage, width / 2 - 40, height / 2.5 + 135 + 13);
    pdf.text(solarProduction, width / 2 - 40, height / 2.5 + 150 + 13);

    pdf.setFont('Helvetica', 'bold');
    pdf.setTextColor(black);
    pdf.setFontSize(12);
    pdf.text('Equipment', width / 2 + 10, overviewHeight + 6);

    pdf.setFont('Helvetica', 'normal');
    pdf.text('Solar Panel', width / 2 + 10, height / 2.5 + 90 + 13);
    pdf.text('Inverter', width / 2 + 10, height / 2.5 + 110 + 13);
    pdf.text('Mounting', width / 2 + 10, height / 2.5 + 130 + 13);
    pdf.text('Monitoring', width / 2 + 10, height / 2.5 + 150 + 13);

    pdf.setFont('Helvetica', 'bold');
    pdf.setFontSize(12);
    pdf.text(panelName, width - 50, height / 2.5 + 90 + 13);
    // pdf.text(solarPanelModelpdf, width - 50, height / 2.5 + 95 + 13);
    pdf.text(inverter.model || '', width - 50, height / 2.5 + 110 + 13);
    pdf.text(mounting.model || '', width - 50, height / 2.5 + 130 + 13);
    pdf.text(monitoring.model || '', width - 50, height / 2.5 + 150 + 13);

    pdf.setFont('Helvetica', 'italic');
    pdf.setTextColor(black);
    pdf.setFontSize(8);
    pdf.text('Page 2', width - 15, height - 3);
    pdf.text(footNote, 5, height - 3);

    // adding third page
    pdf.addPage();
    pdf.setFillColor(lightGrey);
    pdf.rect(0, 0, width, height, 'F');

    pdf.setFont('Helvetica', 'italic');
    pdf.setTextColor(black);
    pdf.setFontSize(8);
    pdf.text('Page 3', width - 15, height - 3);
    pdf.text(footNote, 5, height - 3);

    // chart sections white squares
    pdf.setFillColor(white);
    pdf.setDrawColor('#ECECEC');
    pdf.roundedRect(5, 10, width / 2 - 5, 80, 1, 1, 'DF');
    pdf.roundedRect(width / 2 + 5, 10, width / 2 - 10, 80, 1, 1, 'DF');

    // pdf.addImage(logoUrl, 'PNG', width-imgWidth-5, 10, imgWidth, imgHeight)

    // electricity usage vs production
    await toPng(solarGraphChart)
      .then((dataUrl) => {
        pdf.setFont('Helvetica', 'bold');
        pdf.setFontSize(12);
        pdf.text('Average Electricity Usage', 7, 18);
        pdf.addImage(dataUrl, 'PNG', 6, 24, width / 2 - 7, height / 4.5);
      })
      .catch((e) => console.log('solarGraphChart', e));

    // 25 year savings chart
    await toPng(paybackChart)
      .then((dataUrl) => {
        pdf.setFont('Helvetica', 'bold');
        pdf.setFontSize(12);
        pdf.text('25 Years Savings', width / 2 + 7, 18);
        pdf.addImage(dataUrl, width / 2 + 5, 24, width / 2 - 14, height / 4.5);
      })
      .catch((e) => console.log('paybackChart', e));

    const breakdownStart = height / 4.5 + 30;

    const adders = [];
    Object.entries(this.state.adders).forEach(
      ([_, { count, name, totalCost: total }]) => {
        if (count) {
          const adderName = count === 1 ? name : `${name} (x${count})`;
          adders.push({ name: adderName, total });
        }
      }
    );

    const { credits, systemCost, permitCost } = this.state;
    let breakdownHeight = 32;
    if (permitCost) breakdownHeight += 13;
    if (adders.length > 0) breakdownHeight += 8 + adders.length * 5;
    if (credits.length > 0) breakdownHeight += 8 + credits.length * 5;

    pdf.setFillColor(white);
    pdf.setDrawColor('#ECECEC');
    pdf.roundedRect(5, breakdownStart, width - 10, breakdownHeight, 1, 1, 'DF');

    // write content
    pdf.text('Financial Breakdown', 10, breakdownStart + 8);
    pdf.setFont('Helvetica', 'normal');
    pdf.setDrawColor('#808080');

    // system costs
    let currentHeight = breakdownStart + 18;
    pdf.text('System Cost', 40, currentHeight);
    pdf.text('+', 150, currentHeight);
    pdf.text(formatCurrency(systemCost), 155, currentHeight);
    currentHeight += 5;

    // permit costs
    const permitCity = `${property.city}, ${property.state}`;

    if (permitCost) {
      pdf.text('Permits', 40, currentHeight + 2);
      pdf.line(30, currentHeight + 3, width - 30, currentHeight + 3);
      pdf.text(permitCity, 40, currentHeight + 8);
      pdf.text('+', 150, currentHeight + 8);
      pdf.text(formatCurrency(permitCost), 155, currentHeight + 8);
      currentHeight += 13;
    }

    if (adders.length > 0) {
      pdf.text('Adders', 40, currentHeight + 2);
      pdf.line(30, currentHeight + 3, width - 30, currentHeight + 3);
      currentHeight += 8;
    }
    adders.forEach(({ name, total }) => {
      pdf.text(name, 40, currentHeight);
      pdf.text('+', 150, currentHeight);
      pdf.text(formatCurrency(total), 155, currentHeight);
      currentHeight += 5;
    });

    if (credits.length > 0) {
      pdf.text('Credits', 40, currentHeight + 2);
      pdf.line(30, currentHeight + 3, width - 30, currentHeight + 3);
      currentHeight += 8;
    }
    credits.forEach(({ name, amount }) => {
      pdf.text(name, 40, currentHeight);
      pdf.text('-', 150, currentHeight);
      pdf.text(formatCurrency(amount), 155, currentHeight);
      currentHeight += 5;
    });

    pdf.setDrawColor('#404040');
    pdf.setFont('Helvetica', 'bold');
    pdf.line(30, currentHeight - 2, width - 30, currentHeight - 2);
    pdf.text('Total', 40, currentHeight + 3);
    pdf.text(totalCost, 155, currentHeight + 3);

    // add warranty section
    currentHeight = breakdownStart + breakdownHeight + 6;
    const { warranty } = hardware;
    if (warranty) {
      // split into lines to fit page width
      const splitWarranty = pdf.splitTextToSize(warranty, width - 10);

      pdf.setFillColor(white);
      pdf.setDrawColor('#ECECEC');
      pdf.roundedRect(
        5,
        currentHeight,
        width - 10,
        4 * splitWarranty.length + 16,
        1,
        1,
        'DF'
      );

      pdf.setFont('Helvetica', 'bold');
      pdf.setTextColor(black);
      pdf.setFontSize(12);
      pdf.text('Warranty', 10, currentHeight + 8);

      // warranty details
      pdf.setFont('Helvetica', 'normal');
      pdf.setFontSize(10);
      pdf.text(splitWarranty, 10, currentHeight + 14);
    }

    // const reportName = `${company}_${customerName}_${systemSize}kW_${date}.pdf`;
    // pdf.output('save', reportName);
    window.open(pdf.output('bloburl'));
  };

  updateSelectedPanels = async () => {
    const { pid, API } = this.props;

    const { design, selectedPanels } = this.state;

    // update selected panels
    const designs = design.map(({ id, vertical }, index) => {
      let vert = selectedPanels[index];
      const horizontal = vertical > 0 ? vert.splice(vertical).join(',') : '';
      vert = vert.join(',');
      return { id, vertical_selected: vert, horizontal_selected: horizontal };
    });

    const data = { designs };
    API.update(pid, data, true); // partial update
  };

  // update report in backend
  updateReport = async () => {
    const { pid, API, iframe, hardware, lead } = this.props;

    const {
      offset,
      systemSize,
      yearlySavings,
      lifetimeSavings,
      totalCost,
      escalation,
      panel,
      currentPanel,
      inverter,
      monitoring,
      mounting,
      adders,
      design,
      selectedPanels,
      solarProduction,
      annualUsage,
      paybackYear,
      paybackMonth,
      systemCost,
      adderCost,
      adderNRCost,
      hiddenAdderCost,
      visibleAdderCost,
      preRebateCost,
      discountSystemCost,
      discountAmount,
      loan,
      credits,
      permitCost,
      loanObj,
      designData,
      panelShadings,
      shadingReportData,
      companyPanel,
      companyInverter,
      loanSwitch,
      totalPanels,
    } = this.state;

    let selectedInverter = inverter;
    const statePackage = hardware.packages.filter(
      (item, index) => item.state === lead.address.state
    )[0];
    const defaultPackage = hardware.packages.filter(
      (item, index) => item.state === null
    )[0];
    const selectedPackage =
      statePackage !== undefined ? statePackage : defaultPackage;
    const defaultInverter = Object(
      selectedPackage.inverter || hardware.inverters[0]
    );
    if (panel?.has_micro !== currentPanel?.has_micro) {
      selectedInverter = panel.has_micro ? Object() : defaultInverter;
    }

    const panelMetrics = await this.calcPanelMetrics();

    // save only panel positions in this case
    if (!yearlySavings[0] || !lifetimeSavings[24]) {
      // update selected panels
      const designs = design.map(({ id, vertical }, index) => {
        let vert = selectedPanels[index];
        const horizontal = vertical > 0 ? vert.splice(vertical).join(',') : '';
        vert = vert.join(',');
        return { id, vertical_selected: vert, horizontal_selected: horizontal };
      });

      const size = systemSize.toFixed(3);
      const summary = [
        size,
        solarProduction,
        Math.floor(offset),
        annualUsage.toFixed(0),
        paybackYear,
        paybackMonth,
        systemCost,
        selectedPackage,
        selectedInverter,
      ].join(',');

      try {
        if (!panelShadings || panelShadings.length === 0) {
          panelShadings = await this.canvas3DElement.getAnualPanelsShadings();
        }
      } catch (err) {
        console, log(err);
      }

      let data = {
        summary,
        designs,
        escalation: (escalation * 100).toFixed(1),
        monitoring: monitoring.id,
        mounting: mounting.id,
        adders: Object.entries(adders)
          .map(([id, adder]) => ({ ...adder, id, proposal: pid }))
          .filter(({ count }) => count > 0),
        design_data: {
          ...designData,
          selectedPackage,
          panel,
          inverter,
          monitoring,
          mounting,
          adders: Object.entries(adders)
            .map(([id, adder]) => ({ ...adder, id, proposal: pid }))
            .filter(({ count }) => count > 0),
          roof_type: lead?.property?.roof_type,
          selected_panels: totalPanels,
          summary: {
            system_size: size,
            // yearly_savings: yearly,
            // lifetime_savings: savings,
            // total_cost: total,
            solar_production: solarProduction,
            offset: Math.floor(offset),
            annual_usage: annualUsage.toFixed(0),
            payback_year: paybackYear,
            payback_month: paybackMonth,
            system_cost: systemCost,
            adder_cost: adderCost,
            adder_nrcost: adderNRCost,
            hidden_adder_cost: hiddenAdderCost,
            visible_adder_cost: visibleAdderCost,
            pre_rebate_cost: preRebateCost,
            discount_system_cost: discountSystemCost,
            discount_amount: discountAmount,
          },
          credits,
          permit_cost: permitCost,
          panel_shadings: panelShadings,
          panel_metrics: panelMetrics,
          shading_report: shadingReportData,
        },
      };

      if (companyPanel !== null && companyInverter !== null)
        data = {
          ...data,
          company_panel: panel.id,
          company_inverter: inverter.id,
        };
      else data = { ...data, panel: panel.id, inverter: inverter.id };

      await API.update(pid, data, true); // partial update

      if (this.updateCallback) {
        this.updateCallback(
          'proposal.Save',
          'Selected panels are saved on proposal.'
        );
        // try {
        //   if (['AERIAL', 'ENERFL', 'ENERF2'].includes(lead?.company)) {
        //     let panelMetrics = await this.calcPanelMetrics();
        //     this.updateCallback('proposal.ShadingValuesPerArray', {
        //       yearly: panelMetrics['panelMetrics'],
        //       monthly: panelMetrics['monthlyPanelMetrics'],
        //     });
        //   }
        // } catch (err) {
        //   console.log(`error in calcPanelMetrics: ${err}`);
        // }
      }

      return;
    }

    // if (!yearlySavings[0] || !lifetimeSavings[24]) return;
    this.setState({
      portalLoading: true,
    });
    const images = await getImages([
      'rgbImage',
      'paybackChart',
      'solarGraphChart',
    ]);

    const size = systemSize.toFixed(3);
    const yearly = yearlySavings[0].toFixed(2);
    const savings = lifetimeSavings[24].toFixed(2);
    const total = totalCost.toFixed(2);
    const summary = [
      size,
      yearly,
      savings,
      total,
      solarProduction,
      Math.floor(offset),
      annualUsage.toFixed(0),
      paybackYear,
      paybackMonth,
      systemCost,
    ].join(',');

    // update selected panels
    const designs = design.map(({ id, vertical }, index) => {
      let vert = selectedPanels[index];
      const horizontal = vertical > 0 ? vert.splice(vertical).join(',') : '';
      vert = vert.join(',');
      return { id, vertical_selected: vert, horizontal_selected: horizontal };
    });

    try {
      if (!panelShadings || panelShadings.length === 0) {
        panelShadings = await this.canvas3DElement.getAnualPanelsShadings();
      }
    } catch (err) {
      console, log(err);
    }

    let data = {
      summary,
      loan: loanObj || null,
      loan_switch: loanSwitch,
      designs,
      images,
      escalation: (escalation * 100).toFixed(1),
      panel: panel.id,
      inverter: inverter.id,
      monitoring: monitoring.id,
      mounting: mounting.id,
      adders: Object.entries(adders)
        .map(([id, adder]) => ({ ...adder, id, proposal: pid }))
        .filter(({ count }) => count > 0),
      design_data: {
        ...designData,
        selectedPackage,
        panel,
        inverter,
        monitoring,
        mounting,
        adders: Object.entries(adders)
          .map(([id, adder]) => ({ ...adder, id, proposal: pid }))
          .filter(({ count }) => count > 0),
        roof_type: lead?.property?.roof_type,
        selected_panels: totalPanels,
        summary: {
          system_size: size,
          yearly_savings: yearly,
          yearly_savings_array: yearlySavings,
          lifetime_savings: savings,
          lifetime_savings_array: lifetimeSavings,
          total_cost: total,
          solar_production: solarProduction,
          offset: Math.floor(offset),
          annual_usage: annualUsage.toFixed(0),
          payback_year: paybackYear,
          payback_month: paybackMonth,
          system_cost: systemCost,
          adder_cost: adderCost,
          adder_nrcost: adderNRCost,
          hidden_adder_cost: hiddenAdderCost,
          visible_adder_cost: visibleAdderCost,
          pre_rebate_cost: preRebateCost,
          discount_system_cost: discountSystemCost,
          discount_amount: discountAmount,
        },
        credits,
        permit_cost: permitCost,
        panel_shadings: panelShadings,
        panel_metrics: panelMetrics,
        shading_report: shadingReportData,
      },
    };

    if (companyPanel !== null && companyInverter !== null)
      data = {
        ...data,
        company_panel: panel.id,
        company_inverter: inverter.id,
      };
    else data = { ...data, panel: panel.id, inverter: inverter.id };

    await API.update(pid, data, true); // partial update
    setTimeout(
      () =>
        this.setState({
          portalLoading: false,
        }),
      3000
    );

    if (this.updateCallback) {
      this.updateCallback('proposal.Save', 'Proposal is saved.');
      // try {
      //   if (['AERIAL', 'ENERFL', 'ENERF2'].includes(lead?.company)) {
      //     let panelMetrics = await this.calcPanelMetrics();
      //     this.updateCallback('proposal.ShadingValuesPerArray', {
      //       yearly: panelMetrics['panelMetrics'],
      //       monthly: panelMetrics['monthlyPanelMetrics'],
      //     });
      //   }
      // } catch (err) {
      //   console.log(`error in calcPanelMetrics: ${err}`);
      // }
    }

    /*  console.log('NET_METERING_DEBUG: Report Update Data:', JSON.stringify({
       proposal: {
         summary,
         monthlyData,
         utility: lead.utility
       }
     }, null, 2)); */

    /*  console.log('NET_METERING_DEBUG: Report Data:', JSON.stringify({
       props: {
         lead: this.props.lead
       },
       state: {
         summary: this.state.summary,
         monthlyData: this.state.monthlyData
       }
     }, null, 2)); */
  };

  switchView = (viewMode) => {
    this.setState({ viewMode });
    this.canvas3DElement.setViewMode(viewMode);
  };

  toggleOverlay = () => {
    const { showOverlay } = this.state;
    this.canvas3DElement.toggleView('shading', showOverlay, !showOverlay);
    this.setState({ showOverlay: !showOverlay });
  };

  toggleVisibility = () => {
    const { visibility } = this.state;
    this.setState({ visibility: !visibility });
  };

  setShadingTexture = (texture, month) => {
    this.setState({
      showOverlay: true,
      shadingMonth: month,
      shadingTexture: texture,
    });
  };

  switchAnnotationMode = () => {
    if (this.updateCallback) {
      this.canvas3DElement.switchAnnotation();
    }
  };

  newAnnotation = (position, type='custom', params={}) => {
    const { annotations } = this.state;
    const typeName = type.toLowerCase();
    const typeCaption = typeName == 'custom' ? "Annotation" : type;
    const typeLength = annotations.filter((a) => a?.type?.toLowerCase() === typeName).length || 0;
    const newAnnotations = [...annotations];
    newAnnotations.push({ position: { ...position }, text: `${typeCaption}_${typeLength + 1}`, type, ...params });
    this.persistAnnotation(newAnnotations);
    if (this.updateCallback) this.updateCallback('proposal.new.annotation', { position, text: `${typeCaption}_${typeLength + 1}`, type: 'custom' });
    this.getAnnotations();
  };

  persistAnnotation = (annotations) => {
    const { pid, API } = this.props;
    const data = { annotations };
    API.update(pid, data, true);
    this.setState({ annotations });
  };

  updateAnnotations = (annotations) => {
    if (this.updateCallback && annotations) {
      this.canvas3DElement.setAnnotations(annotations);
      this.getAnnotations();
      return true;
    }
    return false;
  };

  getAnnotations = () => {
    if (this.updateCallback) {
      const annotations = this.canvas3DElement.getAnnotations();
      this.updateCallback('proposal.annotations', annotations);
    }
  };

  removeAnnotation = (index) => {
    const { annotations } = this.state;
    const newAnnotations = [...annotations];
    newAnnotations.splice(index, 1);
    this.persistAnnotation(newAnnotations);
    this.getAnnotations();
    
  }

  handleDownload3DJSON = () => {
    this.canvas3DElement.downloadJSON();
  }

  // Add this method to calculate net metering
  calculateNetMetering = async (params = null) => {
    const { lead } = this.props;
    const { monthlyProduction, systemSize } = params || this.state;


    console.log('calculateNetMetering', this.props);
    console.log('calculateNetMetering', this.state);

    console.log('Calculating net metering with:', {
      monthlyProduction,
      systemSize,
      utility: lead.utility
    });

    if (!monthlyProduction || !systemSize || !lead.utility) {
      console.error('Missing required data for net metering calculations:', {
        hasMonthlyProduction: !!monthlyProduction,
        hasSystemSize: !!systemSize,
        hasUtility: !!lead.utility
      });
      return;
    }

    try {
      const netMeteringResults = calculateNetMetering({
        monthlyProduction,
        systemSize,
        utility: lead.utility
      });

      console.log('Net metering results:', netMeteringResults);

      const billingResults = calculateBilling(netMeteringResults, lead.utility);
      console.log('Billing results:', billingResults);

      this.setState({
        netMeteringCalculations: {
          ...netMeteringResults,
          billing: billingResults
        }
      });
    } catch (error) {
      console.error('Error calculating net metering:', error);
    }
  };

  // Add toggle method
  toggleNetMeteringVerification = () => {
    this.setState(prevState => {
      const showNetMeteringVerification = !prevState.showNetMeteringVerification;

      // If we're showing the verification and don't have calculations yet, calculate them
      if (showNetMeteringVerification && !prevState.netMeteringCalculations) {
        this.calculateNetMetering();
      }

      return { showNetMeteringVerification };
    });
  };

  calculateAnnualNetMetering = () => {
    const { monthlyProduction, systemSize, escalation } = this.state;
    const { lead } = this.props;

    console.log('calculateAnnualNetMetering', this.props);


    if (!monthlyProduction || !systemSize || !lead.utility) {
      return null;
    }



    // Get monthly consumption from utility
    const monthlyConsumption = Array.isArray(lead.utility.usage)
      ? lead.utility.usage.map(usage => Number(usage))
      : new Array(12).fill(Number(lead.utility.totalUsage) / 12);

    console.log('Annual Net Metering Calculation Input:', {
      monthlyProduction,
      monthlyConsumption,
      systemSize,
      utility: {
        billing_type: lead.utility.billing_type,
        rate_amount: lead.utility.rate_amount,
        fixed_charge: lead.utility.fixed_charge,
        net_metering_specs: lead.utility.net_metering_specs
      }
    });

    return calculateAnnualNetMeteringV2({
      monthlyProduction,
      monthlyConsumption,
      systemSize,
      utility: lead.utility,
      panel: this.state.panel,
      escalation
    });
  };

  render() {
    const { design, panel, inverter, monitoring, mounting, loanSwitch } =
      this.state;
    const { validUtility, pid, displaySnack, callback } = this.props;

    if (design === undefined) return <Loading />;

    // destructure rendered props/state
    const {
      lead,
      hardware,
      iframe,
      companyName,
      context: iframeContext = {},
    } = this.props;

    let snowLoss = '0.0';
    try {
      snowLoss = getSnowLoss(convertStateToAbbr(lead?.property?.state, 'abbr'));
    } catch (err) {
      console.log(`errror in getting snow loss: ${err}`);
    }

    const {
      modal,
      scale,
      visibility,
      showOverlay,
      overlaySrc,
      offset,
      annualUsage,
      escalation,
      loan,
      rebateAmount,
      totalPanels,
      selectedPanels,
      adders,
      credits,
      systemCost,
      adderCost,
      adderNRCost,
      hiddenAdderCost,
      visibleAdderCost,
      preRebateCost,
      discountSystemCost,
      permitCost,
      totalCost,
      systemSize,
      discountAmount,
      discountCap,
      monthlyProduction,
      solarProduction,
      yearlySavings,
      viewMode,
      shadingTexture,
      selectedObjects,
      shadingReportData,
      exporting,
      viewerMode,
      portalLoading,
      showNetMeteringVerification,
      netMeteringCalculations,
      annotations,
    } = this.state;

    const { warranty } = hardware;
    const { paybackYear, paybackMonth } = this.state;

    // set yearlySavings and annualSavings based on state of Cash vs. Loan
    const lifetimeSavings = loanSwitch
      ? loan.lifetimeSavings
      : this.state.lifetimeSavings;
    const totalSavings = yearlySavings[24] || 0;

    // **** props for 3D
    const { designData, proposalShadings } = this.state;
    const {
      segments,
      obstacles,
      trees,
      panels: designPanels,
      losses,
    } = designData;
    const { coordinates, resolution, rgb: imgSrc, dsm: dsmSrc } = lead.property;

    const show2D = viewMode === '2D';

    let filteredIframeContext = {};
    if (iframe) {
      filteredIframeContext = filterDictByKeys(iframeContext, [
        'buffer',
        'setback',
        'panelType',
        'defaultBtnView',
      ]);
    }

    const isIos = ios();

    // dummy method to fix "automaticAzimuthMode is not a function" error
    const automaticAzimuthMode = (mode) => {
      return;
    };

    const setDSMParams = (DSMParams) => {
      // const { updateLead, lead: { property } } = this.context;
      // updateLead({ property: { ...property, dsm_params: DSMParams } });
    };

    // TODO: Remove after migrating old data from /media to /imagery
    function parsePropertyDataURL(property, type) {
      try {
        if (!property) return null;
        const imagery = property[type];
        if (imagery && imagery.startsWith('pd_')) {
          if (type == 'rgb') {
            return `${process.env.REACT_APP_PROPERTY_DATA_RGB_PUBLIC_URL}/${imagery}/rgb.tiff`;
          } else if (type == 'dsm') {
            return `${process.env.REACT_APP_PROPERTY_DATA_DSM_PUBLIC_URL}/${imagery}/dsm.tiff`;
          }
        } else {
          return imagery.startsWith('http') ? imagery : `/media/${imagery}`;
        }
      } catch (err) {
        console.log(`Error parsing property data URL: ${err}`);
        return null;
      }
    }

    const property = lead.property;

    let rgbImage = undefined;
    let dsmImage = undefined;
    if (property) {
      rgbImage = parsePropertyDataURL(property, 'rgb');
      dsmImage = parsePropertyDataURL(property, 'dsm');
    }

    const viewer = (
      <DesignContext.Provider
        value={{
          coordinates,
          resolution,
          selectedObjects,
          panelVisibility: visibility,
          viewMode,
          iframe,
          dsmParams: lead.property.dsm_params,
          ios: isIos,
          mode: 'select',
          viewerMode,
          annotations,
          addToSelectedObjects: this.addToSelectedObjects,
          removeFromSelectedObjects: this.removeFromSelectedObjects,
          setShadingTexture: this.setShadingTexture,
          setViewMode: this.switchView,
          togglePanelSelection: this.togglePanelSelection,
          toggleVisibility: this.toggleVisibility,
          automaticAzimuthMode,
          setDSMParams,
          newAnnotation: this.newAnnotation,
          setAnnotations: this.persistAnnotation,
          ...filteredIframeContext,
        }}
      >
        <Canvas3D
          ref={(canvas3D) => {
            this.canvas3DElement = canvas3D;
          }}
          // visible={!show2D}
          visible={exporting ? false : true}
          imgSrc={rgbImage}
          dsmSrc={dsmImage}
          segments={segments}
          obstacles={obstacles}
          trees={trees}
          panels={designPanels}
          annotations={annotations || []}
          proposalShadings={proposalShadings}
          iframeCallback={callback}
          // initViewMode={iframe ? '2D' : '3D'}
          initViewMode={'2D'}
          readonly
        />
        {exporting && <Loading title="Generating Shading Report" />}
        {/* <TogglePanels
          imgSrc={lead.property.rgb}
          overlaySrc={overlaySrc}
          scale={scale}
          visibility={visibility}
          showOverlay={showOverlay}
          panels={design}
          selected={selectedPanels}
          handlePanelToggle={this.panelToggleHandler}
          shadingTexture={shadingTexture}
          visible={show2D}
        /> */}
        {companyName !== 'Astrawatt' && companyName !== 'demand-iq' && (
          <IFrameLogo textColor="light" />
        )}
      </DesignContext.Provider>
    );

    if (iframe) {
      return (
        <>
          {viewer}
          <ShadingReportModal
            show={modal === 'shading-report'}
            onHide={this.hideModal}
            shadingReportData={shadingReportData}
            exportPDFShadingReport={this.downloadShadingReport}
            monthlyProduction={monthlyProduction}
            losses={losses}
            snowLoss={snowLoss}
            inverter={inverter}
            exporting={exporting}
            iframe={iframe}
          />
        </>
      );
    }

    return (
      <Col className="p-0 m-0">
        <Row className="d-flex justify-content-between">
          <Col>
            <Button
              className="float-left"
              variant={loanSwitch ? 'outline-primary' : 'primary'}
              onClick={() =>
                this.setState({ loanSwitch: false }, () => {
                  this.setLoanObj(null);
                  this.calcCosts();
                })
              }
            >
              Cash
            </Button>
            {/* <Button
              className="ml-2 px-3"
              variant={!loanSwitch ? 'outline-primary' : 'primary'}
              onClick={() => this.setState({ loanSwitch: true }, () => {
                this.calcCosts();
              })}
            >
              Loan
            </Button> */}
          </Col>
          <Col className="text-right">
            <ValidatedForm onSave={this.updateReport}>
              <PortalRedirect
                leadId={lead.uid}
                proposalId={pid}
                loading={this.state.portalLoading}
                handleSaveProposal={this.updateReport}
                handleShadingReport={this.handleShadingReportDownload}
              />
              <Button className="mx-2" type="submit">
                <SaveIcon />
              </Button>
              <Button
                className="float-right mr-0"
                onClick={this.handleDownload}
              >
                <Download size={20} />
              </Button>
              <Button
                className="float-right mr-2"
                onClick={this.handleShadingReportDownload}
              >
                <CloudSun size={20} />
              </Button>
            </ValidatedForm>
          </Col>
        </Row>
        {/* === DESIGN & SUMMARY === */}
        <Row noGutters>
          <Col xl={8} lg={12} md={12} s={12} xs={12} className="mt-3">
            {/* <Row>
              <Col>
                <Controls
                  resetScale={() => this.setScale(SCALE)}
                  scaleUp={() => this.setScale(scale + 0.1)}
                  scaleDown={() => this.setScale(scale - 0.1)}
                  hasPanels={totalPanels !== 0}
                  togglePanelSelection={this.togglePanelSelection}
                  visibility={visibility}
                  showOverlay={showOverlay}
                  toggleVisibility={this.toggleVisibility}
                  toggleOverlay={this.toggleOverlay}
                  viewMode={viewMode}
                  switchView={this.switchView}
                />
              </Col>
            </Row> */}
            <Row className="m-0 pr-2">
              <Col
                className="m-0 p-0 border rounded border-primary overflow-auto"
                style={{ height: 507 }}
              >
                {viewer}
              </Col>
            </Row>
          </Col>
          <Col xl={4} lg={12} md={12} s={12} xs={12} className="h-100">
            <Row className="mt-3" noGutters>
              <Col>
                <Card className="shadow-sm p-3 text-white h-100 bg-primary-600">
                  <h4 className="font-weight-bold">
                    {yearlySavings[0] ? (
                      formatCurrency(yearlySavings[0])
                    ) : (
                      <Spinner animation="border" size="sm" />
                    )}
                  </h4>
                  <small>Estimated Yearly Savings</small>
                  <hr className="font-weight-bold border border-white" />
                  <h4 className="font-weight-bold">
                    {totalSavings ? (
                      formatCurrency(totalSavings)
                    ) : (
                      <Spinner animation="border" size="sm" />
                    )}
                  </h4>
                  <small>Estimated 25 Year Savings</small>
                  <hr className="font-weight-bold border border-white" />
                  <h4 className="font-weight-bold">
                    {totalCost ? (
                      formatCurrency(totalCost)
                    ) : (
                      <Spinner animation="border" size="sm" />
                    )}
                  </h4>
                  <small>Estimated Total Cost </small>
                </Card>
              </Col>
              <Col>
                <Card
                  className="shadow-sm p-3 bg-primary-300"
                  onClick={() => {
                    if (!portalLoading) {
                      this.setState({ modal: 'discount' });
                    } else {
                      displaySnack({
                        variant: 'warning',
                        message:
                          'Please wait until proposal is sucessfully saved.',
                      });
                    }
                  }}
                  style={{ height: 175 }}
                >
                  <CircularProgressbar
                    className="font-weight-bold"
                    strokeWidth={14}
                    value={offset}
                    text={`${offset}%`}
                    styles={buildStyles({
                      pathColor: 'darkgreen',
                      textColor: '#333333',
                      textSize: '24px',
                      trailColor: 'white',
                    })}
                  />
                  <div className="mt-2 text-center font-weight-bold">
                    Offset
                  </div>
                </Card>
                <Card className="shadow-sm p-4 text-center">
                  <h4 className="font-weight-bold">
                    {`${systemSize.toFixed(3)} kW`}
                  </h4>
                  <small>Total System Size</small>
                </Card>
              </Col>
            </Row>
            <Row className="" noGutters>
              <Col>
                <Card className="shadow-sm p-5">
                  <table>
                    <tbody>
                      <tr>
                        <td className="text-center">
                          <h4 className="font-weight-bold">
                            {`${solarProduction.toFixed(0)} kWh`}
                          </h4>
                          <small>Solar Production</small>
                        </td>
                        <td className="text-center">
                          <h4 className="font-weight-bold">
                            {`${annualUsage.toFixed(0)} kWh`}
                          </h4>
                          <small>Electricity Usage</small>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Card>
              </Col>
            </Row>
            <Row className="" noGutters>
              <Col>
                <Card className="shadow-sm p-3">
                  {loanSwitch ? (
                    <p className="small m-0">
                      Your monthly loan payments will be
                      <span className="h4 font-weight-bold">
                        &nbsp;
                        {formatCurrency(loan.monthlyPayments)}
                      </span>
                      .
                    </p>
                  ) : (
                    <p className="small m-0">
                      Your estimated payback period will be
                      <span className="h4 font-weight-bold">{` ${paybackYear} `}</span>
                      {Pluralize('year', paybackYear)},
                      <span className="h4 font-weight-bold">{` ${paybackMonth} `}</span>
                      {Pluralize('month', paybackMonth)}.
                    </p>
                  )}
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* === EQUIPMENT AND ADDERS  === */}
        <Row className="mt-3" noGutters>
          <Col md={7}>
            <Card className="shadow-sm h-100 mr-1" body>
              <h5 className="font-weight-bold">Equipment</h5>
              <Row className="mt-1">
                <Col
                  className="d-flex flex-column rounded shadow-sm m-2 px-2 pt-2 pointer border border-secondary"
                  onClick={() => this.setState({ modal: 'panel' })}
                >
                  {panel.manufacturer && (
                    <h5 className="mt-auto">
                      {`${panel.manufacturer.name ? panel.manufacturer.name : panel.manufacturer} - ${panel.model}`}
                    </h5>
                  )}
                  Solar Panel
                </Col>
                <Col
                  className="d-flex flex-column rounded shadow-sm m-2 px-2 pt-2 pointer border border-secondary"
                  onClick={() => this.setState({ modal: 'mounting' })}
                >
                  {mounting && <h5 className="mt-auto">{mounting.model}</h5>}
                  Mounting Hardware
                </Col>
              </Row>
              <Row>
                <Col
                  className="d-flex flex-column rounded shadow-sm m-2 px-2 pt-2 pointer border border-secondary"
                  onClick={() => this.setState({ modal: 'inverter' })}
                >
                  {panel && (
                    <h5 className="mt-auto">
                      {panel.has_micro
                        ? 'Built-In'
                        : inverter && inverter.model}
                    </h5>
                  )}
                  Inverter
                </Col>
                <Col
                  className="d-flex flex-column rounded shadow-sm m-2 px-2 pt-2 pointer border border-secondary"
                  onClick={() => this.setState({ modal: 'monitoring' })}
                >
                  {monitoring && (
                    <h5 className="mt-auto">{monitoring.model}</h5>
                  )}
                  Monitoring System
                </Col>
              </Row>
            </Card>
          </Col>
          <Col md={5} className="mt-4 mt-lg-0">
            <Card className="shadow-sm h-100" body>
              <h5 className="font-weight-bold">Adders</h5>
              <Row xs={1} md={2} className="mt-1">
                {Object.entries(adders)
                  .filter(([_, { count }]) => count)
                  .map(([id, { name, count }]) => (
                    <Col
                      key={id}
                      className="ml-1 font-weight-bold h6 pointer"
                      onClick={() => this.deleteAdder(id)}
                    >
                      {count === 1 ? name : `${name} x ${count}`}
                    </Col>
                  ))}
              </Row>

              <Row className="mt-1">
                <Col>
                  <Button onClick={() => this.setState({ modal: 'adder' })}>
                    + Adders
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row className="mt-2" noGutters>
          <Col className="h-100 m-3">
            <OtherDetails type="P" pid={pid} />
          </Col>
        </Row>

        {/* === PAYBACK AND ELECTRICITY USAGE GRAPHS === */}
        <Row className="mt-1" noGutters>
          <Col lg={6}>
            <Card className="shadow-sm h-100 mr-1" body>
              <Row>
                <Col className="d-flex justify-content-between">
                  <h5 className="font-weight-bold">25 Years Savings</h5>
                  <PencilSquare
                    className="pointer"
                    onClick={() => this.setState({ modal: 'escalation' })}
                    size={24}
                  />
                </Col>
              </Row>
              <p className="small font-italic">
                {`Estimate is based on an annual ${escalation * 100}% utility rate increase`}
              </p>
              <PaybackChart graphData={lifetimeSavings} />
            </Card>
          </Col>
          <Col lg={6} className="mt-4 mt-lg-0">
            <Card
              className="shadow-sm h-100"
              body
              id="card-average-electricity-usage"
            >
              <Row>
                <Col className="d-flex justify-content-between">
                  <h5 className="font-weight-bold">
                    Average Electricity Usage
                  </h5>
                  {/* <PencilSquare
                    className="pointer"
                    onClick={() => this.setState({ modal: 'utility' })}
                    size={24}
                  /> */}
                </Col>
              </Row>
              <SolarPowerChart
                annualProduction={monthlyProduction}
                annualUsage={lead.utility.usage}
              />
            </Card>
          </Col>
        </Row>

        {/* === LOAN CALCULATOR === */}
        {/* {loanSwitch && (
          <LoanReport
            loan={loan}
            rebates={rebateAmount}
            loanChangeHandler={this.loanChangeHandler}
            setLoanObj={this.setLoanObj}
          />
        )} */}

        {/* === FINANCES FOR YOUR SOLAR SYSTEM === */}
        <Row className="mt-1" noGutters>
          <Col md={7}>
            <Card className="shadow-sm h-100 mr-1" body>
              <h5 className="font-weight-bold">Financial Breakdown</h5>
              <Row>
                <Col sm={5} className="h-100">
                  <Card className="shadow-sm p-3 text-white bg-primary-600">
                    <h4 className="font-weight-bold">
                      {formatCurrency(yearlySavings[0])}
                    </h4>
                    <small>Estimated First Year Savings</small>
                    <hr className="font-weight-bold border border-white" />
                    <h4 className="font-weight-bold">
                      {formatCurrency(totalSavings)}
                    </h4>
                    <small>Estimated 25-Year Savings</small>
                    {!loanSwitch && (
                      <>
                        <hr className="font-weight-bold border border-white" />
                        <h4 className="font-weight-bold">
                          {Pluralize('year', paybackYear, true)}
                          ,
                          <br />
                          {Pluralize('month', paybackMonth, true)}.
                        </h4>
                        <small>Payback Period</small>
                      </>
                    )}
                  </Card>
                </Col>
                <Col sm={7} className="h-100">
                  <Row>
                    {/* <Col>
                      <Row className="px-4 d-flex justify-content-between">
                        System Cost
                        <strong>{formatCurrency(systemCost)}</strong>
                      </Row>

                      {permitCost ? (
                        <Row className="px-4 d-flex justify-content-between">
                          Permits
                          <strong>{`+ ${formatCurrency(permitCost)}`}</strong>
                        </Row>
                      ) : null}

                      {Object.entries(adders)
                        .filter(([_, { count }]) => count)
                        .map(([id, { name, count, totalCost: total }]) => (
                          <Row
                            key={id}
                            className="px-4 d-flex justify-content-between"
                          >
                            {count === 1 ? name : `${name} (x${count})`}
                            <strong>{`+ ${formatCurrency(total)}`}</strong>
                          </Row>
                        ))}

                      {credits.map(({ name, appliedAmount }) => (
                        <Row
                          key={name}
                          className="px-4 d-flex justify-content-between"
                        >
                          {name}
                          <strong>{`- ${formatCurrency(appliedAmount)}`}</strong>
                        </Row>
                      ))}

                      <Row className="px-4 d-flex justify-content-between">
                        Discount
                        <strong>- {formatCurrency(discountAmount)}</strong>
                      </Row>

                      <hr />

                      <Row className="px-4 d-flex justify-content-between">
                        Total Cost
                        <strong>{formatCurrency(totalCost)}</strong>
                      </Row>
                    </Col> */}
                    <Col>
                      {discountAmount > 0 ? (
                        <>
                          <Row className="px-4 d-flex justify-content-between">
                            System Cost
                            <strong>{formatCurrency(discountSystemCost - visibleAdderCost)}</strong>
                          </Row>

                          {Object.entries(adders)
                            .filter(([_, { count }]) => count)
                            .map(([id, el]) => !el?.hidden && (
                              <Row className="px-4 d-flex justify-content-between">
                                {el?.count === 1 ? `${el?.name} ${el?.dq ? '(Rebate Exemption)' : ''}` : `${el?.name} x ${el?.count} ${el?.dq ? '(Rebate Exemption)' : ''}`}
                                {el?.flat_cost ? (
                                  <strong>{`+ ${formatCurrency(Number(el?.count) * Number(el?.cost))}`}</strong>
                                ) : (
                                  <strong>{`+ ${formatCurrency(Number(el?.count) * Number(el?.cost) * Number(systemSize) * 1000)}`}</strong>
                                )}
                              </Row>
                            ))}

                          <Row className="px-4 d-flex justify-content-between">
                            Discount
                            <strong>{`- ${formatCurrency(discountAmount)}`}</strong>
                          </Row>

                          <Row className="px-4 d-flex justify-content-between">
                            Total Cost
                            <strong>{formatCurrency(discountSystemCost - discountAmount)}</strong>
                          </Row>

                          {credits.map(({ name, appliedAmount }) => (
                            <Row
                              key={name}
                              className="px-4 d-flex justify-content-between"
                            >
                              {name}
                              <strong>{`- ${formatCurrency(appliedAmount)}`}</strong>
                            </Row>
                          ))}

                          <hr />

                          <Row className="px-4 d-flex justify-content-between">
                            Net Cost
                            <strong>{formatCurrency(totalCost)}</strong>
                          </Row>
                        </>
                      ) : (
                        <>
                          <Row className="px-4 d-flex justify-content-between">
                            System Cost
                            <strong>{formatCurrency(discountSystemCost - visibleAdderCost)}</strong>
                          </Row>

                          {Object.entries(adders)
                            .filter(([_, { count }]) => count)
                            .map(([id, el]) => !el?.hidden && (
                              <Row className="px-4 d-flex justify-content-between">
                                {el?.count === 1 ? `${el?.name} ${el?.dq ? '(Rebate Exemption)' : ''}` : `${el?.name} x ${el?.count} ${el?.dq ? '(Rebate Exemption)' : ''}`}
                                {el?.flat_cost ? (
                                  <strong>{`+ ${formatCurrency(Number(el?.count) * Number(el?.cost))}`}</strong>
                                ) : (
                                  <strong>{`+ ${formatCurrency(Number(el?.count) * Number(el?.cost) * Number(systemSize) * 1000)}`}</strong>
                                )}
                              </Row>
                            ))}

                          <Row className="px-4 d-flex justify-content-between">
                            Total Cost
                            <strong>{formatCurrency(discountSystemCost)}</strong>
                          </Row>

                          {credits.map(({ name, appliedAmount }) => (
                            <Row
                              key={name}
                              className="px-4 d-flex justify-content-between"
                            >
                              {name}
                              <strong>{`- ${formatCurrency(appliedAmount)}`}</strong>
                            </Row>
                          ))}

                          <hr />

                          <Row className="px-4 d-flex justify-content-between">
                            Net Cost
                            <strong>{formatCurrency(totalCost)}</strong>
                          </Row>
                        </>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col md={5} className="mt-4 mt-lg-0">
            <Card className="shadow-sm h-100" body>
              <h5 className="font-weight-bold">Warranty</h5>
              <p className="mt-1">{warranty}</p>
            </Card>
          </Col>
        </Row>

        {/* === SHADE REPORT === */}
        {/* this.state.design && this.state.panel && (
          <ShadeReport
            wattage={this.state.panel.power}
            panels={this.state.design}
          />
        ) */}

        {/* === DISCOUNT COST MODAL === */}
        <DiscountModal
          show={modal === 'discount'}
          onHide={this.hideModal}
          pid={pid}
          amount={discountAmount}
          applyDiscount={this.discountApplied}
          isPreparedToCalc={!!designData.summary}
        />

        {/* === SOLAR PANELS MODAL === */}
        <SelectModal
          title="Solar Panel"
          show={modal === 'panel'}
          selectValue={panel.id || ''}
          selectOptions={hardware.panels
            .filter(
              ({ width, length }) =>
                width === panel.width && length === panel.length
            )
            .map(({ id, manufacturer, model }) => ({
              id,
              label: `${manufacturer.name}-${model}`,
            }))}
          onHide={this.hideModal}
          onSave={this.solarPanelChangeHandler}
        />

        {/* === MOUNTING HARDWARE MODAL === */}
        <SelectModal
          title="Mounting Hardware"
          show={modal === 'mounting'}
          selectValue={mounting.id}
          selectOptions={hardware.mounting.map(({ id, model }) => ({
            id,
            label: model,
          }))}
          onHide={this.hideModal}
          onSave={this.mountingChangeHandler}
        />

        {/* === INVERTER MODAL === */}
        <SelectModal
          title="Inverter"
          show={modal === 'inverter'}
          selectValue={inverter.id || ''}
          selectOptions={hardware.inverters.map(({ id, model }) => ({
            id,
            label: model,
          }))}
          nullOption={panel && panel.has_micro ? 'Built-In Inverter' : null}
          disabled={panel && panel.has_micro}
          onHide={this.hideModal}
          onSave={this.invertersChangeHandler}
        />

        {/* === MONITORING SYSTEM MODAL === */}
        <SelectModal
          title="Monitoring System"
          show={modal === 'monitoring'}
          selectValue={monitoring.id || ''}
          selectOptions={hardware.monitoring.map(({ id, model }) => ({
            id,
            label: model,
          }))}
          nullOption="No Monitoring System"
          onHide={this.hideModal}
          onSave={this.monitoringChangeHandler}
        />

        {/* === ADDERS MODAL === */}
        <AddersModal
          show={modal === 'adder'}
          onHide={this.hideModal}
          current={adders}
          options={hardware.adders}
          onSave={this.addersUpdateHandler}
        />

        {/* === UTILITY ESCALATION MODAL === */}
        <EscalationModal
          show={modal === 'escalation'}
          escalation={escalation * 100}
          onHide={this.hideModal}
          onSave={this.utilityEscalationHandler}
        />

        {/* === UTILITY RATE MODAL === */}
        {/* <UtilityModal
          show={modal === 'utility'}
          onHide={this.hideModal}
          utility={lead.utility}
          // monthlyBill={this.state.monthlyBill}
          // monthlyBillHandler={this.monthlyBillHandler}
        /> */}

        <ShadingReportModal
          show={modal === 'shading-report'}
          onHide={this.hideModal}
          shadingReportData={shadingReportData}
          exportPDFShadingReport={this.downloadShadingReport}
          monthlyProduction={monthlyProduction}
          losses={losses}
          snowLoss={snowLoss}
          inverter={inverter}
          exporting={exporting}
          iframe={iframe}
        />

        <p id="footnote" className="mt-5 font-italic">
          * The savings, production and payback period in this Custom Solar
          Design is an estimate only.
        </p>

        {/* Add this near the top of your render method */}
        {/* <div className="mt-4">
          <button
            className="btn btn-secondary"
            onClick={this.toggleNetMeteringVerification}
            disabled={!monthlyProduction || !systemSize}
          >
            {showNetMeteringVerification ? 'Hide' : 'Show'} Net Metering Verification
          </button>

          {showNetMeteringVerification && (
            <div className="mt-3">
              {netMeteringCalculations ? (
                <VerificationTable
                  calculationResults={netMeteringCalculations}
                  className="mb-4"
                />
              ) : (
                <div className="alert alert-info">
                  Calculating net metering data...
                </div>
              )}
            </div>
          )}
        </div> */}

        {/*  <div className="mt-4">
          <Card className="shadow-sm">
            <Card.Header>
              <h5 className="mb-0">Annual Net Metering Analysis</h5>
            </Card.Header>
            <Card.Body>
              {monthlyProduction && systemSize ? (
                <AnnualNetMeteringTable
                  calculationResults={this.calculateAnnualNetMetering()}
                />
              ) : (
                <div className="alert alert-info">
                  Loading production data...
                </div>
              )}
            </Card.Body>
          </Card>
        </div> */}


        {/* (this.props.lead.utility.billing_type === 'net_billing' || this.props.lead.utility.billing_type === 'net_metering') && <div className="mt-4">
          <Card className="shadow-sm">
            <Card.Header>
              <h5 className="mb-0">
                {this.props.lead.utility.billing_type === 'net_billing' ?
                  'Net Billing Analysis' :
                  this.props.lead.utility.net_metering_specs?.metering_period === 'monthly' ?
                    'Monthly Net Metering Analysis' :
                    'Annual Net Metering Analysis'
                }
              </h5>
            </Card.Header>
            <Card.Body>
              {monthlyProduction && systemSize ? (
                this.props.lead.utility.billing_type === 'net_billing' ? (
                  <NetBillingTable
                    calculationResults={calculateNetBillingV2({
                      monthlyProduction,
                      monthlyConsumption: Array.isArray(this.props.lead.utility.usage)
                        ? this.props.lead.utility.usage
                        : new Array(12).fill(this.props.lead.utility.totalUsage / 12),
                      systemSize,
                      utility: this.props.lead.utility,
                      panel: this.state.panel,
                      escalation: this.state.escalation
                    })}
                  />
                ) : this.props.lead.utility.net_metering_specs?.metering_period === 'monthly' ? (
                  <MonthlyNetMeteringTable
                    calculationResults={calculateMonthlyNetMeteringV2({
                      monthlyProduction,
                      monthlyConsumption: Array.isArray(this.props.lead.utility.usage)
                        ? this.props.lead.utility.usage
                        : new Array(12).fill(this.props.lead.utility.totalUsage / 12),
                      systemSize,
                      utility: this.props.lead.utility,
                      panel: this.state.panel,
                      escalation: this.state.escalation
                    })}
                  />
                ) : (
                  <AnnualNetMeteringTable
                    calculationResults={this.calculateAnnualNetMetering()}
                  />
                )
              ) : (
                <div className="alert alert-info">
                  Loading production data...
                </div>
              )}
            </Card.Body>
          </Card>
        </div>
         */}

        {/* this.state.netMeteringCalculations && (
          <div className="mt-4">
            <h4>Net Metering Analysis</h4>
            {this.props.lead.utility.net_metering_specs?.metering_period === 'monthly' ? (
              <MonthlyNetMeteringTable
                calculationResults={this.state.netMeteringCalculations}
              />
            ) : (
              <AnnualNetMeteringTable
                calculationResults={this.state.netMeteringCalculations}
              />
            )}
          </div>
        ) */}
      </Col>
    );
  }
}

Report.defaultProps = {
  callback: null,
};

Report.propTypes = {
  pid: PropTypes.string.isRequired,
  API: PropTypes.instanceOf(Object).isRequired,
  hardware: PropTypes.shape({
    adders: PropTypes.instanceOf(Array),
    credits: PropTypes.instanceOf(Array),
    inverters: PropTypes.instanceOf(Array),
    monitoring: PropTypes.instanceOf(Array),
    mounting: PropTypes.instanceOf(Array),
    packages: PropTypes.instanceOf(Array),
    panels: PropTypes.instanceOf(Array),
    permits: PropTypes.instanceOf(Array),
    roof_types: PropTypes.instanceOf(Array),
    warranty: PropTypes.string,
  }).isRequired,
  currentUser: PropTypes.shape({
    commission: PropTypes.string,
    discount: PropTypes.string,
  }).isRequired,
  fullAddress: PropTypes.string.isRequired,
  lead: PropTypes.shape({
    property: PropTypes.instanceOf(Object),
    utility: PropTypes.instanceOf(Object),
    contact: PropTypes.instanceOf(Object),
    address: PropTypes.instanceOf(Object),
  }).isRequired,
  validUtility: PropTypes.bool.isRequired,
  callback: PropTypes.func,
};

export default Report;
