import React, { createContext, useContext, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { AuthContext } from 'api/context';
import { setTitle } from 'Main/utils';
import Canvas from './canvas';
// import reducer from './reducer';

export const LeadContext = createContext();

const LeadProvider = ({ children }) => {
  const {
    user, company, newEndpoint, displaySnack, getTaskStatus, AiDesigns,
  } = useContext(AuthContext);

  // set initial state for context provider
  const history = useHistory();
  const { uid, tab } = useParams();
  const [lead, setLead] = useState();

  // lead-related APIs
  const API = newEndpoint('crm/leads/');
  const noteAPI = newEndpoint('crm/notes/');
  const taskAPI = newEndpoint('crm/tasks/');
  const documentAPI = newEndpoint('crm/documents/');
  const productionMapAPI = newEndpoint('crm/production/');
  const packageAPI = newEndpoint('hardware/packages/');
  const hardwareAPI = newEndpoint('hardware/all/');
  const proposalAPI = newEndpoint('designer/proposal/');
  const fieldsAPI = newEndpoint('crm/lead/fields/');
  const namevaluesAPI = newEndpoint('crm/lead/namevalues/');
  const pinHouseAPI = newEndpoint('crm/lead/pin_house/');
  const predictAPI = newEndpoint('crm/lead/predict/');
  const leadImagesAPI = newEndpoint('crm/lead/images/');
  const thumbnailsAPI = newEndpoint('crm/lead/thumbnails/');
  const projectAPI = newEndpoint('project_management/projects/');
  const paymentAPI = newEndpoint('project_management/payments/');
  const coverageAPI = newEndpoint('api/coverage/');
  // design tool APIs
  const PVWattsAPI = newEndpoint('designer/pvwatts/');
  const saveShadingsAPI = newEndpoint('designer/gradients/');
  const runShadingsAPI = newEndpoint('designer/gradients/shade');
  const removeShadingsAPI = newEndpoint('designer/gradients/remove/');
  const exportDesignAPI = newEndpoint('designer/export/design/');
  const measureAPI = newEndpoint('designer/measure/');
  const optimizeAPI = newEndpoint('designer/optimize/');
  const edgeDetectionAPI = newEndpoint('designer/edge_detection/');
  const panelPlacementAPI = newEndpoint('designer/panel_placement/');
  const ratesAPI = newEndpoint('hardware/rates/');

  const [stepsDone, setStepsDone] = useState(['Customer Details'])

  // wrapper to facilitate canvas-related operations
  const CanvasAPI = Canvas(newEndpoint('designer/canvas'), uid);

  const pinHouse = (data) => pinHouseAPI.create(data);

  const navigateTab = (newTab) => history.push(`/leads/${uid}/${newTab}`);

  const refreshLead = (field) => {
    console.log('refreshLead');
    if (field) API.retrieve(uid).then((data) => setLead({ ...lead, [field]: data[field] }));
    // return Promise.reject(new Error('Refresh should specify a field argument.'));
  };

  const setUtility = (utility) => setLead({ ...lead, utility });

  const validUtility = (lead?.utility?.usage !== undefined // ?totalUsage
    && lead?.utility?.effectiveRate !== undefined
    && lead?.utility?.fixedCharge !== undefined);

  const updateLead = async (updateData) => API.update(uid, updateData).then((data) => {
    // TODO: this is not pretty....
    // is there a better way to write this while preserving utility data?
    console.log('updateLead');
    const update = { ...lead, ...data, utility: lead.utility };
    setLead(update);
    return update;
  });

  const stepsDoneHandler = (newStep) => {
    //STEPS
    //Manual Lead when creating is manually
    //Customer Details, Property Information, Utility Information
    setStepsDone(prevState => [newStep, ...prevState])
  }

  useEffect(() => {
    function fetchData() {
      return Promise.all([
        API.retrieve(uid)
          .then(async (data) => {
            const { utility } = data;
            console.log('Utility in fetchData', utility);
            if (utility.rate === 'custom') {
              // Preserve existing utility values
              const existingUtility = {
                ...utility,
                provider: 'Custom',
                fixedChargeUnits: '$/month',
                fixedCharge: utility.fixed_charge,
                effectiveRate: utility.rate_amount,
                export_rate: utility.export_rate,
                ufsc: utility.ufsc,
                offset_savings: utility.offset_savings,
                billing_type: utility.billing_type,

                // Initialize net_metering_specs object if billing type is net_metering
                net_metering_specs: utility.billing_type === 'net_metering' ? {
                  ufsc: utility.net_metering_specs?.ufsc || 22,
                  cost_cap: utility.net_metering_specs?.cost_cap || 0,
                  cost_per_kw: utility.net_metering_specs?.cost_per_kw || 0,
                  export_rate: utility.net_metering_specs?.export_rate || utility.rate_amount || 0,
                  system_size: utility.net_metering_specs?.system_size || 0,
                  trueup_month: utility.net_metering_specs?.trueup_month || "April",
                  offset_savings: utility.net_metering_specs?.offset_savings || false,
                  fixed_cost_type: utility.net_metering_specs?.fixed_cost_type || "constant",
                  metering_period: utility.net_metering_specs?.metering_period || "annual"
                } : null,

                // Initialize net_billing_specs object if billing type is net_billing
                net_billing_specs: utility.billing_type === 'net_billing' ? {
                  ufsc: utility.net_billing_specs?.ufsc || 22,
                  cost_cap: utility.net_billing_specs?.cost_cap || 0,
                  cost_per_kw: utility.net_billing_specs?.cost_per_kw || 0,
                  export_rate: utility.net_billing_specs?.export_rate || 0.022, // Default to $0.022/kWh
                  system_size: utility.net_billing_specs?.system_size || 0,
                  fixed_cost_type: utility.net_billing_specs?.fixed_cost_type || "constant",
                  export_percentage: utility.net_billing_specs?.export_percentage || 40 // Default to 40%
                } : null
              };

              if (utility?.company_rate) {
                console.log('Fetching company rate:', utility.company_rate);
                try {
                  const rateData = await ratesAPI.retrieve(utility.company_rate);
                  console.log('Rate Data from company rate:', rateData);

                  // Handle specs based on billing type
                  if (rateData.billing_type === 'net_metering' && rateData.net_metering_specs) {
                    existingUtility.net_metering_specs = {

                      ...rateData.net_metering_specs
                    };
                    console.log('Updated net_metering_specs from rate data:', existingUtility.net_metering_specs);
                  } else if (rateData.billing_type === 'net_billing' && rateData.net_billing_specs) {
                    existingUtility.net_billing_specs = {

                      ...rateData.net_billing_specs
                    };
                    console.log('Updated net_billing_specs from rate data:', existingUtility.net_billing_specs);
                  }

                  // Update billing type if specified in rate data
                  if (rateData.billing_type) {
                    existingUtility.billing_type = rateData.billing_type;
                  }

                  // Add debugging for configuration
                  console.log('Utility Configuration:', {
                    billing_type: existingUtility.billing_type,
                    net_metering_specs: existingUtility.net_metering_specs,
                    net_billing_specs: existingUtility.net_billing_specs
                  });

                } catch (error) {
                  console.error('Error fetching company rate:', error);
                }
              }

              // Replace the utility object with our updated version
              Object.assign(utility, existingUtility);
            }
            // console.log(data);
            return data;
          }),
        newEndpoint('crm/sources').list(),
        newEndpoint('crm/lead_status').list(),
        newEndpoint('crm/lead_reason_for_loss').list(),
        newEndpoint('hardware/roofs').list()
          .then((data) => data.map(({ cost, ...roof }) => roof)),
      ]).then(([leadData, sources, statuses, lossReasons, roofTypes]) => ({
        ...leadData, sources, statuses, lossReasons, roofTypes,
      }));
    }

    if (lead === undefined && uid !== 'new' && uid !== undefined) {
      console.log('Fetching lead data.');
      fetchData().then((data) => {
        const homeowner = data.homeowners[0];
        const title = homeowner.first_name || homeowner.last_name
          ? `${homeowner.first_name} ${homeowner.last_name}` : data.address.street;
        setTitle(title);
        setLead(data);
      }).catch((e) => {
        console.log(e);
        // if (!iframe) history.push('/404');
      });
    }
  }, [uid, lead, API, newEndpoint, history]);

  useEffect(() => setTitle('New Lead'), []);

  return (
    <LeadContext.Provider
      value={{
        user,
        company,
        uid,
        tab,
        lead,
        AiDesigns,
        setUtility,
        validUtility,
        newEndpoint,
        displaySnack,
        navigateTab,
        leadAPI: API,
        updateLead,
        refreshLead,
        noteAPI,
        taskAPI,
        documentAPI,
        proposalAPI,
        CanvasAPI,
        hardwareAPI,
        packageAPI,
        productionMapAPI,
        saveShadingsAPI,
        removeShadingsAPI,
        runShadingsAPI,
        leadImagesAPI,
        thumbnailsAPI,
        exportDesignAPI,
        fieldsAPI,
        coverageAPI,
        namevaluesAPI,
        pinHouse,
        predictAPI,
        PVWattsAPI,
        projectAPI,
        paymentAPI,
        measureAPI,
        optimizeAPI,
        edgeDetectionAPI,
        panelPlacementAPI,
        getTaskStatus,
        stepsDone,
        stepsDoneHandler
      }}
    >
      {children}
    </LeadContext.Provider>
  );
};

export default LeadProvider;
