import React, { useEffect, useRef } from "react";

const GridHelper = ({ visible }) => {
  const gridRef = useRef();

  useEffect(() => {
    if (gridRef && gridRef.current) {
      gridRef.current.material.opacity = 0.25;
      gridRef.current.material.transparent = true;
    }
  }, []);

  return <gridHelper name="grid" ref={gridRef} args={[200, 40]} visible={visible} />;
};

export default GridHelper;
