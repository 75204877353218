import React, {
  useState, useMemo, useRef, useEffect, useLayoutEffect, useContext,
} from 'react';
import * as THREE from 'three';
// import randomColor from 'randomcolor';

import { DesignContext } from '../contexts/designContext.js';

const Wall = ({ vertices }) => {
  const {
    showSunLight,
  } = useContext(DesignContext);

  const geometry = useMemo(() => {

    const result = [];
    for (let i = 0; i < vertices.length; i += 1) {
      const v1 = vertices[i];
      const v2 = vertices[(i + 1) % vertices.length];

      result.push(
        v1.x, v1.y, -v1.z,
        v2.x, v2.y, -v2.z,
        v2.x, v2.y, 0,
        v2.x, v2.y, 0,
        v1.x, v1.y, 0,
        v1.x, v1.y, -v1.z,
      );
    }

    const positions = new Float32Array(result);
    const [gem_1, gem_2] = [new THREE.BufferGeometry(), new THREE.BufferGeometry()];
    gem_1.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    gem_2.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    // gem_1.computeFaceNormals();
    gem_1.computeVertexNormals();
    return [gem_1, gem_2];

  }, [vertices]);

  const mat = useMemo(() => (showSunLight ? (
    <meshStandardMaterial
      color="rgb(200,200,200)"
      attach="material"
      wireframe={false}
      side={THREE.BackSide}
    />
  ) : (
    <meshBasicMaterial
      color="rgb(200,200,200)"
      attach="material"
      wireframe={false}
      side={THREE.DoubleSide}
    />
  )), [showSunLight]);

  return (
    <>
      <mesh
        name="wall"
        castShadow
        receiveShadow
      >
        <primitive attach="geometry" object={geometry[0]} />
        {mat}
      </mesh>
      <line name="wallBorder">
        <primitive attach="geometry" object={geometry[1]} />
        <lineBasicMaterial attach="material" color="black" transparent opacity={0.2} linewidth={2} />
      </line>
    </>
  );
};

export default Wall;
