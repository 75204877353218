import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Form, InputGroup } from 'react-bootstrap';
// import mapsIcon from "assets/images/maps-icon.svg";

function Controls({
  mode,
  moveMode,
  setMode,
  setMoveMode,
  resetScale,
  scaleUp,
  scaleDown,
  mapsLink,
  viewMode,
  switchView,
}) {
  return null;
  return (
    <div className="w-100 mb-2 d-flex justify-content-between">
      <div>
        <ButtonGroup className="pr-2">
          {['select', 'draw', 'edit', 'delete'].map((md) => (
            <Button
              key={md}
              className="text-capitalize"
              variant={mode === md ? 'primary' : 'outline-primary'}
              onClick={() => setMode(md)}
              disabled={!mode}
            >
              {md}
            </Button>
          ))}
        </ButtonGroup>

        <ButtonGroup className={`pr-2 ${(mode === 'edit' ? '' : 'd-none')}`}>
          {['single', 'group'].map((md) => (
            <Button
              key={md}
              className="text-capitalize"
              variant={moveMode === md ? 'primary' : 'outline-primary'}
              onClick={() => setMoveMode(md)}
              disabled={!moveMode}
            >
              {md}
            </Button>
          ))}
        </ButtonGroup>

        <ButtonGroup>
          <a
            className="btn btn-outline-primary p-1"
            target="_blank"
            rel="noopener noreferrer"
            href={mapsLink()}
          >
            <img src="/maps-icon.svg" height={28} alt="google-maps" />
          </a>
        </ButtonGroup>
      </div>

      {/* Map Controls */}
      <div>
        <ButtonGroup>
          {/* {viewMode === '3D' && (
          <Button
            className="btn btn-sm bg-transparent"
            onClick={() => switchView('2D')}
          >
            <img src="/2d.svg" height={30} alt="3D" />
          </Button>
          )}
          {viewMode === '2D' && (
          <Button
            className="btn btn-sm bg-transparent"
            onClick={() => switchView('3D')}
          >
            <img src="/3d.svg" height={30} alt="2D" />
          </Button>
          )} */}
          {/* <a
            className="btn btn-outline-primary p-1"
            target="_blank"
            rel="noopener noreferrer"
            href={mapsLink()}
          >
            <img src="/maps-icon.svg" height={28} alt="google-maps" />
          </a> */}
          {/* <Button onClick={resetScale} variant="outline-primary">1x</Button>
          <Button onClick={scaleUp} variant="outline-primary">+</Button>
          <Button onClick={scaleDown} variant="outline-primary">-</Button> */}
        </ButtonGroup>
      </div>
    </div>
  );
}

Controls.defaultProps = {
  mode: null,
  moveMode: 'group',
};

Controls.propTypes = {
  mode: PropTypes.string,
  moveMode: PropTypes.string,
  setMode: PropTypes.func.isRequired,
  setMoveMode: PropTypes.func.isRequired,
  resetScale: PropTypes.func.isRequired,
  scaleUp: PropTypes.func.isRequired,
  scaleDown: PropTypes.func.isRequired,
  mapsLink: PropTypes.func.isRequired,
  viewMode: PropTypes.string,
  switchView: PropTypes.func.isRequired,
};

export default Controls;
