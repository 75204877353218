import * as THREE from 'three';

import { gray2gradient, downloadCanvas, pointInConcavePolygon } from './geometry.js';
import { tic, toc } from './utils.js';
import { fillPolygon } from './dsm.js';
import { context } from 'react-three-fiber';

function dataTextureToBase64JpegSync(dataTexture, quality = 0.92) {
  try {
    // Step 1: Retrieve pixel data, width, and height from DataTexture
    const width = dataTexture.image.width;
    const height = dataTexture.image.height;
    const data = dataTexture.image.data;

    // Step 2: Create HTML5 Canvas and 2D Context
    let canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    let ctx = canvas.getContext('2d');

    // Step 3: Create ImageData and populate it
    const imgData = ctx.createImageData(width, height);
    for (let i = 0; i < imgData.data.length; i += 1) {
      imgData.data[i] = data[i];
    }

    // Place the ImageData into the canvas
    ctx.putImageData(imgData, 0, 0);

    // Step 4: Convert canvas to Base64 JPEG
    const base64Jpeg = canvas.toDataURL('image/jpeg', quality);

    canvas.width = 1;
    canvas.height = 1;
    ctx = null;
    canvas.remove();
    canvas = null;

    return base64Jpeg;
  } catch (error) {
    console.error('An error occurred:', error);
    return null;
  }
}


export default class Materials {
  canvasPool;

  TSRFTextures = {};

  TOFTextures = {};

  SolarAccessTextures = {};

  diffuseTextures = {};

  constructor(canvasPool) {
    this.canvasPool = canvasPool;
  }

  getMaskCanvas = (segments) => {
    const newCanvas = this.canvasPool.getCanvas(this.canvasPool.PoolKeys.GetMaskCanvas);
    const context = this.canvasPool.getContext(this.canvasPool.PoolKeys.GetMaskCanvas);
    context.clearRect(0, 0, newCanvas.width, newCanvas.height);
    segments.forEach((seg) => {
      fillPolygon(context, seg.geometry, `rgb(${255}, ${255}, ${255})`);
    });
    return newCanvas;
  }

  saveTextures = (info) => {
    const data = {
      diffuse: [], tsrf: [], tof: [], solarAccess: [], ...info,
    };
    const { tsrf: diffuseTSRF, tof: diffuseTOF } = this.diffuseTextures;
    const diffuseTSRFData = dataTextureToBase64JpegSync(diffuseTSRF);
    const diffuseTOFData = dataTextureToBase64JpegSync(diffuseTOF);
    data.diffuse.push(diffuseTSRFData);
    data.diffuse.push(diffuseTOFData);
    Object.keys(this.TSRFTextures).forEach((i) => {
      const tsrfGray = this.TSRFTextures[i][0];
      const tofGray = this.TOFTextures[i][0];
      const saGray = this.SolarAccessTextures[i][0];
      const tsrfData = dataTextureToBase64JpegSync(tsrfGray);
      const tofData = dataTextureToBase64JpegSync(tofGray);
      const saData = dataTextureToBase64JpegSync(saGray);
      data.tsrf.push(tsrfData);
      data.tof.push(tofData);
      data.solarAccess.push(saData);
    });
    return data;
  }

  getPanelRectPoints = (point, width, height, azimuth) => {
    const [x, y] = point;
    const az = ((azimuth * Math.PI) / 180.0);
    const [cx, cy] = [x, y];
    const points = [[x, y], [x, y + height], [x + width, y + height], [x + width, y]].map(([vx, vy]) => {
      const newX = cx + (vx - cx) * Math.cos(az) - (vy - cy) * Math.sin(az);
      const newY = cy + (vx - cx) * Math.sin(az) + (vy - cy) * Math.cos(az);
      return new THREE.Vector3(newX, newY, 0);
    });
    const xs = points.map((p) => p.x);
    const ys = points.map((p) => p.y);
    const minx = Math.round(Math.min(...xs));
    const maxx = Math.round(Math.max(...xs));
    const miny = Math.round(Math.min(...ys));
    const maxy = Math.round(Math.max(...ys));
    const ranges = [minx, maxx, miny, maxy];
    return [points, ranges];
  }

  delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

getAnualPanelsShadings = async (segments, panels) => {
  console.log('getAnualPanelsShadings: Starting shading calculations (with coverage score)...');

  // Track total polygons processed vs. how many have at least 1 in-bounds pixel
  let totalPolygons = 0;
  let coveredPolygons = 0;

  try {
    // 1) Wait for baseline (month=0) textures to load or bail out
    let tryCount = 25;
    while (
      (!this.SolarAccessTextures[0] || !this.SolarAccessTextures[0][0] ||
        !this.TOFTextures[0]        || !this.TOFTextures[0][0]        ||
        !this.TSRFTextures[0]       || !this.TSRFTextures[0][0]) &&
      --tryCount > 0
    ) {
      console.warn('[getAnualPanelsShadings] Waiting for month=0 textures...', {
        solarAccess0: !!(this.SolarAccessTextures[0]?.[0]),
        tof0: !!(this.TOFTextures[0]?.[0]),
        tsrf0: !!(this.TSRFTextures[0]?.[0]),
        remainingWaits: tryCount
      });
      await this.delay(1000);
    }

    if (
      !this.SolarAccessTextures[0] || !this.SolarAccessTextures[0][0] ||
      !this.TOFTextures[0]        || !this.TOFTextures[0][0]         ||
      !this.TSRFTextures[0]       || !this.TSRFTextures[0][0]
    ) {
      console.error('[getAnualPanelsShadings] Missing baseline textures (month=0). Returning [].');
      return [];
    }

    const grayTexture = this.SolarAccessTextures[0][0];
    const grayTOFTexture = this.TOFTextures[0][0];
    const grayTSRFTexture = this.TSRFTextures[0][0];

    if (!grayTexture.image || !grayTOFTexture.image || !grayTSRFTexture.image) {
      console.error('[getAnualPanelsShadings] Baseline textures loaded, but .image is null. Returning [].');
      return [];
    }

    const { width, height, data } = grayTexture.image;
    const { data: tofData } = grayTOFTexture.image;
    const { data: tsrfData } = grayTSRFTexture.image;

    // Main shading data
    const values = [];

    // ========== BASELINE PASS (month=0) ==========
    for (let i = 0; i < panels.length; i += 1) {
      let panelIndex = 0;
      for (let k = 0; k < 2; k += 1) {
        const t = (k === 0) ? 'vertical' : 'horizontal';
        const panelGroup = panels[i][t];
        if (!panelGroup || !panelGroup.points) continue;

        for (let j = 0; j < panelGroup.points.length; j += 1) {
          // Count polygons
          totalPolygons++;

          const [rect, [minx, maxx, miny, maxy]] = this.getPanelRectPoints(
            panelGroup.points[j],
            panelGroup.width,
            panelGroup.height,
            segments[i].panelOrientation
          );

          let n = 0;
          let sumSA = 0;
          let sumTOF = 0;
          let sumTSRF = 0;

          for (let x = minx; x <= maxx; x++) {
            for (let y = miny; y <= maxy; y++) {
              const index = y * (width * 4) + (x * 4);
              if (index < 0 || index >= data.length) {
                continue; // out-of-bounds
              }
              const p = new THREE.Vector3(x, y, 0);
              if (pointInConcavePolygon(rect, p)) {
                n++;
                const v = data[index] / 255.0;
                const tofV = tofData[index] / 255.0;
                const tsrfV = tsrfData[index] / 255.0;
                sumSA += v;
                sumTOF += tofV;
                sumTSRF += tsrfV;
              }
            }
          }

          if (n > 0) {
            coveredPolygons++;
          }

          const [v1, v2, v3, v4] = rect;
          const vrect = [
            v1, v1.clone().add(v2).multiplyScalar(0.5),
            v2, v2.clone().add(v3).multiplyScalar(0.5),
            v3, v3.clone().add(v4).multiplyScalar(0.5),
            v4, v4.clone().add(v1).multiplyScalar(0.5)
          ];

          values.push([
            i, t, panelIndex,
            (minx + maxx) / 2,
            (miny + maxy) / 2,
            vrect,
            n > 0 ? sumSA / n : 0,
            n > 0 ? sumTOF / n : 0,
            n > 0 ? sumTSRF / n : 0
          ]);
          panelIndex += 1;
        }
      }
    }

    // ========== MONTHLY PASS (1..12) ==========
    const monthlyValues = {};

    for (let m = 1; m <= 12; m++) {
      // Check if monthly textures exist
      if (
        !this.SolarAccessTextures[m] || !this.SolarAccessTextures[m][0] ||
        !this.TOFTextures[m]        || !this.TOFTextures[m][0]         ||
        !this.TSRFTextures[m]       || !this.TSRFTextures[m][0]
      ) {
        console.warn(`[getAnualPanelsShadings] Month=${m} missing textures, skipping.`);
        monthlyValues[m] = [];
        continue;
      }

      const grayTextureMonth = this.SolarAccessTextures[m][0];
      const grayTOFTextureMonth = this.TOFTextures[m][0];
      const grayTSRFTextureMonth = this.TSRFTextures[m][0];

      if (!grayTextureMonth.image || !grayTOFTextureMonth.image || !grayTSRFTextureMonth.image) {
        console.warn(`[getAnualPanelsShadings] Month=${m} texture .image is null, skipping.`);
        monthlyValues[m] = [];
        continue;
      }

      const { width: mWidth, height: mHeight, data: mData } = grayTextureMonth.image;
      const { data: mTofData } = grayTOFTextureMonth.image;
      const { data: mTsrfData } = grayTSRFTextureMonth.image;

      const monthArray = [];

      for (let i = 0; i < panels.length; i += 1) {
        let panelIndex = 0;
        for (let k = 0; k < 2; k += 1) {
          const t = (k === 0) ? 'vertical' : 'horizontal';
          const panelGroup = panels[i][t];
          if (!panelGroup || !panelGroup.points) continue;

          for (let j = 0; j < panelGroup.points.length; j += 1) {
            const [rect, [minx, maxx, miny, maxy]] = this.getPanelRectPoints(
              panelGroup.points[j],
              panelGroup.width,
              panelGroup.height,
              segments[i].panelOrientation
            );

            let n = 0;
            let sumSA = 0;
            let sumTOF = 0;
            let sumTSRF = 0;

            for (let x = minx; x <= maxx; x++) {
              for (let y = miny; y <= maxy; y++) {
                const idx = y * (mWidth * 4) + (x * 4);
                if (idx < 0 || idx >= mData.length) {
                  continue;
                }
                const p = new THREE.Vector3(x, y, 0);
                if (pointInConcavePolygon(rect, p)) {
                  n++;
                  sumSA += (mData[idx] / 255.0);
                  sumTOF += (mTofData[idx] / 255.0);
                  sumTSRF += (mTsrfData[idx] / 255.0);
                }
              }
            }

            const [v1, v2, v3, v4] = rect;
            const vrect = [
              v1, v1.clone().add(v2).multiplyScalar(0.5),
              v2, v2.clone().add(v3).multiplyScalar(0.5),
              v3, v3.clone().add(v4).multiplyScalar(0.5),
              v4, v4.clone().add(v1).multiplyScalar(0.5)
            ];

            monthArray.push([
              i, t, panelIndex,
              (minx + maxx) / 2,
              (miny + maxy) / 2,
              vrect,
              n > 0 ? sumSA / n : 0,
              n > 0 ? sumTOF / n : 0,
              n > 0 ? sumTSRF / n : 0
            ]);
            panelIndex++;
          }
        }
      }

      monthlyValues[m] = monthArray;
    }

    // Append monthly data to the main array
    values.push(monthlyValues);

    // ========== LOG COVERAGE SCORE ==========
    const coverageScore = (totalPolygons > 0) ? (coveredPolygons / totalPolygons) : 1.0;
    const coveragePct = (coverageScore * 100).toFixed(2);

    // Always log coverage, even if 100%
    console.log(`[getAnualPanelsShadings] Done. Coverage Score: ${coveragePct}% (${coveredPolygons}/${totalPolygons} polygons had in-bounds pixels).`);

    return values;

  } catch (err) {
    console.error('[getAnualPanelsShadings] Unexpected error:', err);
    console.warn('Returning empty array to avoid crashing further logic.');
    return [];
  }
};

// If you have this function in the same file, you can leave it as-is:
gradiantData = (grayData, width, height, ranges) => {
  // ...
  // unchanged from your existing snippet
  return grayData;
};


  
  gradiantData = (grayData, width, height, ranges) => {
    if (!ranges) return grayData;

    tic();

    const [minx, miny, maxx, maxy] = ranges;

    const maskContext = this.canvasPool.getContext(this.canvasPool.PoolKeys.GetMaskCanvas);
    const maskData = maskContext.getImageData(0, 0, width, height);

    const srcData = grayData;

    try {
      const destData = new Uint8Array(width * height * 4);

      for (let x = minx; x <= maxx; x += 1) {
        for (let y = miny; y <= maxy; y += 1) {
          const index = y * (width * 4) + x * 4;
          const maskValue = maskData.data[index];
          if (maskValue !== 0) {
            const v = srcData[index];
            const gradiant = gray2gradient(v / 255);
            [destData[index], destData[index + 1], destData[index + 2]] = gradiant;
            destData[index + 3] = 255;
          }
        }
      }

      return destData;
    } catch (e) {
      return grayData;
    }
  }

  gradiantDataTexture = (grayTexture, ranges, mask, name) => {
    if (!ranges) return grayTexture;

    tic();

    const { width, height } = grayTexture.image;
    const [minx, miny, maxx, maxy] = ranges;

    const maskContext = this.canvasPool.getContext(this.canvasPool.PoolKeys.GetMaskCanvas);
    const maskData = maskContext.getImageData(0, 0, width, height);
    const srcContext = this.canvasPool.getContext(name);
    const srcData = srcContext.getImageData(0, 0, width, height);

    try {
      const destData = new Uint8Array(width * height * 4);

      for (let x = minx; x <= maxx; x += 1) {
        for (let y = miny; y <= maxy; y += 1) {
          const index = y * (width * 4) + x * 4;
          const maskValue = maskData.data[index];
          if (maskValue !== 0) {
            const v = srcData.data[index];
            const gradiant = gray2gradient(v / 255);
            [destData[index], destData[index + 1], destData[index + 2]] = gradiant;
            destData[index + 3] = 255;
          }
        }
      }

      const texture = new THREE.DataTexture(destData, width, height, THREE.RGBAFormat, THREE.UnsignedByteType);
      texture.flipY = true;
      texture.needsUpdate = true;

      return texture;
    } catch (e) {
      return grayTexture;
    }
  }

  loadBase64ToTexture = (name, month, base64) => new Promise((resolve, reject) => {
    const image = new Image();
    image.width = 1024;
    image.height = 1024;
    image.onload = () => {
      let canvas = document.createElement('canvas');
      canvas.width = 1024;
      canvas.height = 1024;
      let ctx = canvas.getContext('2d');
      ctx.drawImage(image, 0, 0);

      const pixelData = ctx.getImageData(0, 0, 1024, 1024).data;

      const texture = this.dataToTexture(pixelData, 1024, 1024);
      resolve([name, month, texture, pixelData]);

      canvas.width = 1;
      canvas.height = 1;
      ctx = null;
      canvas.remove();
      canvas = null;
    };
    image.onerror = reject;
    image.src = base64;
  });

  dataToTexture = (data, width, height) => {
    const shadingTexture = new THREE.DataTexture(data, width, height, THREE.RGBAFormat, THREE.UnsignedByteType);
    shadingTexture.flipY = true;
    // shadingTexture.flipX = true;
    shadingTexture.needsUpdate = true;
    return shadingTexture;
  }

  getCollectionByName = (name) => {
    if (name.startsWith('diffuse_')) return this.diffuseTextures;
    if (name.startsWith('tsrf_')) return this.TSRFTextures;
    if (name.startsWith('tof_')) return this.TOFTextures;
    if (name.startsWith('solarAccess_')) return this.SolarAccessTextures;
    return null;
  };

  loadTextures = async (data) => {
    const {
      diffuse, tsrf, tof, solarAccess, ranges, segments,
    } = data;

    const tasks = [];
    tasks.push(this.loadBase64ToTexture('diffuse_tsrf', 'tsrf', diffuse[0]));
    tasks.push(this.loadBase64ToTexture('diffuse_tof', 'tof', diffuse[1]));
    for (let i = 0; i < tsrf.length; i += 1) {
      tasks.push(this.loadBase64ToTexture(`tsrf_${i}`, i, tsrf[i]));
      tasks.push(this.loadBase64ToTexture(`tof_${i}`, i, tof[i]));
      tasks.push(this.loadBase64ToTexture(`solarAccess_${i}`, i, solarAccess[i]));
    }
    const result = await Promise.allSettled(tasks);
    const mask = this.getMaskCanvas(segments);
    result.forEach((item) => {
      if (item.status === 'fulfilled') {
        const [name, m, texture, pixelData] = item.value;
        const collection = this.getCollectionByName(name);
        if (collection === this.diffuseTextures) {
          collection[m] = texture;
        } else {
          collection[m] = [texture, null];
          const gradientData = this.gradiantData(pixelData, 1024, 1024, ranges);
          collection[m][1] = this.dataToTexture(gradientData, 1024, 1024); // this.gradiantDataTexture(texture, ranges, mask, name);
        }
      }
    });
  }

  clean = (m, type) => {
    let collection = null;

    if (type === 'TSRF') {
      collection = this.TSRFTextures;
    } else if (type === 'TOF') {
      collection = this.TOFTextures;
    } else if (type === 'SA') {
      collection = this.SolarAccessTextures;
    }

    if (collection && m in collection) {
      if (collection[m].dispose) collection[m].dispose();
      delete collection[m];
    }
  }
}
