import React from 'react';
import PropTypes from 'prop-types';

import style from './IFrameLogo.module.css';

const IFrameLogo = (props) => {
  const { textColor } = props;
  if (process.env.REACT_APP_BACKEND === 'true') {
    return (
      <div className={textColor === 'dark' ? style.IFrameLogo : style.IFrameLogoLight}>
        <p>
          Powered by
          <br />
          <span className={style.IFrameLogoSpan}>AERIALYTIC</span>
        </p>
      </div>
    );
  }

  return '';
};

IFrameLogo.propTypes = {
  textColor: PropTypes.string.isRequired,
};

export default IFrameLogo;
