import Tool from './tool';

const rectangle = { ...Tool };

rectangle.name = 'Rectangle';

rectangle.onMouseDown = function onMouseDown(start, options) {
  if (!this.state) {
    this.state = {
      options,
    };
  }
  this.setInitSettings({ start, options });
};

rectangle.onMouseMove = function onMouseMove(position) {
  if (!this.state || !this.state.started) return;
  this.ctx.putImageData(this.imageData, 0, 0);
  this.draw(this.state.start, position);
};

rectangle.draw = function draw(start, position, isInitSet, data) {
  if (isInitSet === false) {
    this.ctx.fillStyle = 'rgba(255,0, 0, 0.5)';
    this.ctx.strokeStyle = this.state.options.lineColor;
    this.ctx.lineWidth = this.state.options.lineWidth;
    this.setInitSettings({ start, options: this.state.options });
  }
  this.ctx.fillStyle = 'rgba(255, 0, 0, 0.5)';
  this.ctx.fillRect(
    start.x,
    start.y,
    position.x - start.x,
    position.y - start.y
  );
  this.ctx.lineWidth = this.state.options.lineWidth + 1;
  this.ctx.strokeStyle = this.state.options.shadowColor;
  this.ctx.strokeRect(
    start.x,
    start.y,
    position.x - start.x,
    position.y - start.y
  );
  this.ctx.lineWidth = this.state.options.lineWidth;
  this.ctx.strokeStyle = this.state.options.lineColor;
  this.ctx.strokeRect(
    start.x,
    start.y,
    position.x - start.x,
    position.y - start.y
  );
};

rectangle.onMouseUp = function onMouseUp(position, callback) {
  if (!this.state) return;
  const data = [
    [this.state.start.x, this.state.start.y],
    [this.state.start.x, position.y],
    [position.x, position.y],
    [position.x, this.state.start.y],
  ];
  const { start } = this.state;
  const { options } = this.state;
  this.state.started = false;
  // this callback is just to trigger a new
  // Rectangle ID
  callback();
  return {
    data,
    canvas: {
      start,
      end: position,
      options,
    },
  };
};

export default rectangle;
