import React, { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone'
import { Row, Col, Button, ButtonGroup, Tab, Tabs, Spinner, Form, Alert } from 'react-bootstrap';
import { Trash, Inbox, InfoCircleFill } from 'react-bootstrap-icons';
import Save from 'components/Save';

import { AuthContext } from 'api/context';
import ValidatedForm, { FloatInput, FloatSelect } from 'components/Form/Inputs';
import { stateOptions, countryOptions } from 'options';
import { convertStateToAbbr } from 'options';
import NetMeteringAndBilling from './components/NetMeteringAndBilling';
import IFrame from 'IFrame';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  marginBottom: '16px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const UtilityRate = () => {
  const { newEndpoint, displaySnack } = useContext(AuthContext);
  const ratesEndpoint = newEndpoint('hardware/rates/');
  const utilitiesEndpoint = newEndpoint('hardware/utilities/');

  // all design rates
  const [allStateAlertOpened, setAllStateAlertOpened] = useState(true);
  const [perStateAlertOpened, setPerStateAlertOpened] = useState(true);
  const [perUtilityByStateAlertOpened, setPerUtilityByStateAlertOpened] = useState(true);
  const [newRate, setNewRate] = useState();
  const [newStateDefault, setNewStateDefault] = useState();
  const [companyRates, setCompanyRates] = useState([]);

  const [states, setStates] = useState([]);
  const [utilityCompanies, setUtilityCompanies] = useState([]);
  const [spinner, setSpinner] = useState(false);

  const [allUtilities, setAllUtilities] = useState([]);



  const countries = countryOptions.map((el) => ({ name: el }));



  useEffect(() => {
    if (newStateDefault && newStateDefault?.country) {
      const stateList = stateOptions[newStateDefault?.country].map((el) => ({ name: el }));
      setStates(stateList);
    }
  }, [newStateDefault?.country, newStateDefault?.state]);

  useEffect(() => {
    if (newRate && newRate?.country) {
      const stateList = stateOptions[newRate.country].map((el) => ({ name: el }));
      const sortData = (data) => data.sort((a, b) => a.name > b.name ? 1 : -1);
      setStates(stateList);

      if (newRate.country === 'Canada') {
        setSpinner(true);
        utilitiesEndpoint.list(`?country=CA`).then((data) => {
          sortData(data);
          setUtilityCompanies(data);
          setSpinner(false);
        });
      } else if (newRate.country === 'United States' && newRate?.state) {
        setSpinner(true);
        utilitiesEndpoint.list(`?state=${convertStateToAbbr(newRate?.state, 'abbr')}`).then((data) => {
          sortData(data);
          setUtilityCompanies(data);
          setSpinner(false);
        });
      }
    }
  }, [newRate?.country, newRate?.state]);


  useEffect(() => {
    loadRates();
    loadUtilities();
  }, []);

  const loadRates = () => {
    ratesEndpoint.list().then((data) => {
      console.log('Data from loadRates: ', data);
      if (data.length !== 0) {
        setCompanyRates(data);
      }
    });
  };

  const loadUtilities = () => {
    utilitiesEndpoint.list().then((data) => {
      if (data.length !== 0) {
        data.sort((a, b) => a.name > b.name ? 1 : -1);
        setAllUtilities(data);
      }
    });
  };

  const handleUpdateRate = (value, field, index) => {
    console.log('Updating rate:', { field, value, index });
    const updated = Array.from(companyRates);
    const currentRate = updated[index];

    // Handle specs updates
    if (field === 'net_metering_specs' || field === 'net_billing_specs') {
      updated[index] = {
        ...currentRate,
        [field]: value
      };
    }
    // Handle billing type changes
    else if (field === 'billing_type') {
      updated[index] = {
        ...currentRate,
        billing_type: value,
        // Reset specs when changing billing type
        net_metering_specs: value === 'net_metering' ? {} : null,
        net_billing_specs: value === 'net_billing' ? {} : null
      };

      if (value) {

      }
    }
    // Handle basic fields
    else {
      updated[index] = {
        ...currentRate,
        [field]: value
      };
    }

    console.log('Updated rate object:', updated[index]);
    setCompanyRates(updated);
  };

  const postRateHandler = (rateToUpdate) => {
    const { id, ...totalRate } = rateToUpdate;

    //console.log('Formatted rate before: ', rateToUpdate);
    console.log('Formatted rate before: ', totalRate);

    // Format the data to match our backend model
    const formattedRate = {
      ...totalRate,
      utility_company: totalRate?.utility_company?.id || totalRate?.utility_company,

      // Basic rate fields
      name: totalRate.name,
      fixed_charge: totalRate.fixed_charge ? parseFloat(totalRate.fixed_charge) : null,
      rate_amount: totalRate.rate_amount ? parseFloat(totalRate.rate_amount) : null,
      export_rate: (totalRate.billing_type === 'net_metering' || totalRate.billing_type === 'net_billing' || !totalRate.offset_savings) ? null : totalRate.export_rate ? parseFloat(totalRate.export_rate) : null,
      ufsc: (totalRate.billing_type === 'net_metering' || totalRate.billing_type === 'net_billing') ? null : totalRate.ufsc ? parseFloat(totalRate.ufsc) : null,
      offset_savings: (totalRate.billing_type === 'net_metering' || totalRate.billing_type === 'net_billing') ? false : totalRate.offset_savings || false,

      // Location fields
      country: totalRate.country || null,
      state: totalRate.state || null,

      // Billing type
      billing_type: totalRate.billing_type || null,

      // Specs fields - ensure they're properly formatted
      net_metering_specs: totalRate.billing_type === 'net_metering' ? {
        ...totalRate.net_metering_specs,
        metering_period: totalRate.net_metering_specs?.metering_period,
        trueup_month: totalRate.net_metering_specs?.trueup_month || '0',
        export_rate: totalRate.net_metering_specs?.export_rate ? parseFloat(totalRate.net_metering_specs.export_rate) : null,
        fixed_cost_type: totalRate.net_metering_specs?.fixed_cost_type,
        ufsc: totalRate.net_metering_specs?.fixed_cost_type === 'constant' ? totalRate.net_metering_specs?.ufsc ? parseFloat(totalRate.net_metering_specs.ufsc) : null : 0,
        system_size: totalRate.net_metering_specs?.fixed_cost_type === 'system_size' ? totalRate.net_metering_specs?.system_size ? parseFloat(totalRate.net_metering_specs.system_size) : 0 : 0,
        cost_per_kw: totalRate.net_metering_specs?.fixed_cost_type === 'system_size' ? totalRate.net_metering_specs?.cost_per_kw ? parseFloat(totalRate.net_metering_specs.cost_per_kw) : null : 0,
        cost_cap: totalRate.net_metering_specs?.fixed_cost_type === 'system_size' ? totalRate.net_metering_specs?.cost_cap ? parseFloat(totalRate.net_metering_specs.cost_cap) : null : 0
      } : null,

      net_billing_specs: totalRate.billing_type === 'net_billing' ? {
        ...totalRate.net_billing_specs,
        export_rate: totalRate.net_billing_specs?.export_rate ? parseFloat(totalRate.net_billing_specs.export_rate) : null,
        fixed_cost_type: totalRate.net_billing_specs?.fixed_cost_type === 'constant' ? 1 : 2,
        ufsc: totalRate.net_billing_specs?.fixed_cost_type === 'constant' ? totalRate.net_billing_specs?.ufsc ? parseFloat(totalRate.net_billing_specs.ufsc) : null : 0,
        system_size: totalRate.net_billing_specs?.fixed_cost_type === 'system_size' ? totalRate.net_billing_specs?.system_size ? parseFloat(totalRate.net_billing_specs.system_size) : 0 : 0,
        cost_per_kw: totalRate.net_billing_specs?.fixed_cost_type === 'system_size' ? totalRate.net_billing_specs?.cost_per_kw ? parseFloat(totalRate.net_billing_specs.cost_per_kw) : null : 0,
        cost_cap: totalRate.net_billing_specs?.fixed_cost_type === 'system_size' ? totalRate.net_billing_specs?.cost_cap ? parseFloat(totalRate.net_billing_specs.cost_cap) : null : 0,
        export_percentage: totalRate.net_billing_specs?.export_percentage ? parseFloat(totalRate.net_billing_specs.export_percentage) : null
      } : null
    };

    console.log('Formatted rate after:', formattedRate);

    if (id) {
      return ratesEndpoint.update(id, formattedRate)
        .then(() => displaySnack({ variant: 'success', message: `Updated ${rateToUpdate.name}.` }))
        .catch(() => displaySnack({ variant: 'danger', message: `Failed update ${rateToUpdate.name}.` }));
    }
    return ratesEndpoint.create(formattedRate)
      .then(() => ratesEndpoint.list().then((data) => setCompanyRates(data)))
      .then(() => {
        displaySnack({ variant: 'success', message: `Added ${rateToUpdate.name}.` });
        setNewRate();
        setNewStateDefault();
      })
      .catch(() => {
        displaySnack({
          variant: 'danger',
          message: 'Failed to add new rate',
        });
      });
  };

  const onDrop = useCallback(files => {
    const csvFile = files[0];
    if (!csvFile) {
      return;
    }
    const formData = new FormData();
    formData.append("csv", csvFile);
    ratesEndpoint.send('POST', formData, 'bulk_insert')
      .then((data) => {
        displaySnack({ variant: 'success', message: `Inserted ${data.added_rows} records successfully` });
        if (data.invalid_rows > 0) {
          displaySnack({ variant: 'warning', message: `${data.invalid_rows} records were not inserted. The row information may not be valid.` });
        }
        loadRates();
      })
      .catch(() => displaySnack({ variant: 'danger', message: `Failed to insert dataset` }));
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: { "text/csv": [".csv"] },
    multiple: false,
    onDrop
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  const handleDelete = (index) => {
    const updated = Array.from(companyRates);
    const rm = updated.splice(index, 1)[0];
    ratesEndpoint.remove(rm.id)
      .then(() => { setCompanyRates(updated); displaySnack({ variant: 'success', message: `Deleted ${rm.id}.` }); })
      .catch(() => displaySnack({ variant: 'warning', message: `Failed to delete ${rm.name}` }));
  };

  const initRate = () => setNewRate({ name: '', country: 'United States', state: '', offset_savings: false });

  const initStateDefault = () => setNewStateDefault({ name: '', country: 'United States', state: '', offset_savings: false });

  const nostateItem = companyRates.find((s) => s.state == null && s.name === 'default') ?? { name: 'default', country: null, state: null, offset_savings: false };
  let nostateIndex = companyRates.indexOf(nostateItem);
  if (nostateIndex < 0) {
    companyRates.push(nostateItem);
    nostateIndex = companyRates.indexOf(nostateItem);
  }

  return (
    <>
      <Tabs defaultActiveKey="all-states" id="uncontrolled-tab-example" className="mb-3 p-2 nav-pills nav-fill">
        <Tab eventKey="all-states" title="All States/Provinces">
          <Alert variant="info" show={allStateAlertOpened} onClose={() => setAllStateAlertOpened(false)} dismissible>As a default our software will pull utility data in from Open EI. If Open EI does not have data for a specific co-ordinate if this section is populated our software will pull in the utility rates for all states/provinces with the data that you enter here.</Alert>
          <ValidatedForm onSave={() => postRateHandler(nostateItem)}>
            <Row>
              <Col style={{ display: "none" }} sm={3} xs={12}>
                <FloatInput
                  required
                  label="Name"
                  value="default"
                  onChange={(e) => handleUpdateRate(e.target.value, 'name', nostateIndex)}
                  disabled
                />
              </Col>
              <Col sm={3} xs={12}>
                <FloatInput
                  required
                  label="Fixed Charge"
                  prepend="$"
                  value={nostateItem.fixed_charge || ''}
                  onChange={(e) => handleUpdateRate(e.target.value, 'fixed_charge', nostateIndex)}
                />
              </Col>
              <Col sm={3} xs={12}>
                <FloatInput
                  required
                  label="Rate Amount"
                  prepend="$"
                  value={nostateItem.rate_amount || ''}
                  onChange={(e) => handleUpdateRate(e.target.value, 'rate_amount', nostateIndex)}
                />
              </Col>
              {nostateItem.offset_savings && (
                <>
                  <Col sm={3} xs={12}>
                    <FloatInput
                      required
                      label="Utility Fixed Solar Cost"
                      prepend="$"
                      value={nostateItem.ufsc || ''}
                      onChange={(e) => handleUpdateRate(e.target.value, 'ufsc', nostateIndex)}
                    />
                  </Col>
                  <Col sm={3} xs={12}>
                    <FloatInput
                      required
                      label="Export Rate"
                      prepend="$"
                      value={nostateItem.export_rate || ''}
                      onChange={(e) => handleUpdateRate(e.target.value, 'export_rate', nostateIndex)}
                    />
                  </Col>
                </>
              )}
            </Row>
            {<Row>
              <Col xs={12} className='mt-3'>
                <NetMeteringAndBilling
                  data={nostateItem}
                  onChange={(field, value) => {
                    console.log('NetMeteringAndBilling onChange:', field, value);
                    handleUpdateRate(value, field, nostateIndex);
                  }}
                />
              </Col>
            </Row>}

            <Row className="mt-3 mb-3">
              <Col>
                <Button type="submit">
                  Save
                </Button>
              </Col>
            </Row>
          </ValidatedForm>
        </Tab>
        <Tab eventKey="per-state-province" title="Per State/Province">
          <Alert variant="info" show={perStateAlertOpened} onClose={() => setPerStateAlertOpened(false)} dismissible>If this section is populated you'll be able to select the custom rate by state/province that you populate here when doing a design / proposal.</Alert>
          {!newStateDefault && companyRates.filter(r => r?.name.includes('-default')).length == 0 ? (
            <Row className="pb-2">
              <i className="px-3 text-muted">No information has been added for this section. You can click the +Rate button to add a utility by state/province.</i>
            </Row>
          ) : null}

          {companyRates.map((item, index) => (item?.state !== null) && (item?.name.includes('-default')) && (
            <Row>
              <Col>
                <ValidatedForm key={item?.id} onSave={() => postRateHandler(item)}>
                  <Row>
                    <Col sm={3} xs={12}>
                      <FloatSelect
                        label="Country"
                        nullValue="Choose Country"
                        options={countries}
                        labelField="name"
                        value={item.country}
                        onChange={(e) => handleUpdateRate(e.target.value, 'country', index)}
                        required
                        disabled
                      />
                    </Col>
                    <Col sm={3} xs={12}>
                      <FloatSelect
                        required
                        label="State"
                        nullValue="Choose State"
                        options={stateOptions[item?.country].map((el) => ({ name: el }))}
                        labelField="name"
                        value={item.state || ''}
                        onChange={(e) => { handleUpdateRate(e.target.value, 'state', index); handleUpdateRate(`${e.target.value}-default`, 'name', index); }}
                        disabled
                      />
                    </Col>
                    <Col sm={6} xs={12}>
                      <FloatInput
                        required
                        label="Name"
                        value={item.name || ''}
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col sm={3} xs={12}>
                      <FloatInput
                        required
                        label="Fixed Charge"
                        prepend="$"
                        value={item?.fixed_charge || ''}
                        onChange={(e) => handleUpdateRate(e.target.value, 'fixed_charge', index)}
                      />
                    </Col>
                    <Col sm={3} xs={12}>
                      <FloatInput
                        required
                        label="Rate Amount"
                        prepend="$"
                        value={item?.rate_amount || ''}
                        onChange={(e) => handleUpdateRate(e.target.value, 'rate_amount', index)}
                      />
                    </Col>
                    {!item?.offset_savings ? null : (

                      item.billing_type === 'net_metering' || item.billing_type === 'net_billing' ? null :
                        <>
                          <Col sm={3} xs={12}>
                            <FloatInput
                              required
                              label="Utility Fixed Solar Cost"
                              prepend="$"
                              value={item?.ufsc || ''}
                              onChange={(e) => handleUpdateRate(e.target.value, 'ufsc', index)}
                            />
                          </Col>
                          <Col sm={3} xs={12}>
                            <FloatInput
                              required
                              label="Export Rate"
                              prepend="$"
                              value={item?.export_rate || ''}
                              onChange={(e) => handleUpdateRate(e.target.value, 'export_rate', index)}
                            />
                          </Col>
                        </>
                    )}
                  </Row>
                  {<Row>
                    <Col xs={12} className='mt-3'>
                      <NetMeteringAndBilling
                        data={item}
                        onChange={(field, value) => handleUpdateRate(value, field, index)}
                      />
                    </Col>
                  </Row>}
                  <Row className="mt-3 mb-3 float-right">
                    <Col>
                      <ButtonGroup size="sm">
                        <Button variant="outline-primary p-2" type="submit">
                          <Save />
                        </Button>
                        <Button variant="outline-primary p-2" onClick={() => handleDelete(index)}>
                          <Trash />
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </ValidatedForm>
              </Col>
            </Row>
          ))}

          {newStateDefault === undefined ? (
            <Row className="mt-2">
              <Col>
                <Button onClick={initStateDefault}>
                  + Rate
                </Button>
              </Col>
            </Row>
          ) : (
            <>
              <ValidatedForm onSave={() => postRateHandler(newStateDefault)}>
                <Row>
                  <Col sm={3} xs={12}>
                    <FloatSelect
                      label="Country"
                      nullValue="Choose Country"
                      options={countries}
                      labelField="name"
                      value={newStateDefault.country || ''}
                      onChange={(e) => setNewStateDefault({ ...newStateDefault, country: e.target.value })}
                      required
                    />
                  </Col>
                  <Col sm={3} xs={12}>
                    <FloatSelect
                      required
                      label="State"
                      nullValue="Choose State"
                      options={states}
                      labelField="name"
                      value={newStateDefault.state || ''}
                      onChange={(e) => setNewStateDefault({ ...newStateDefault, state: e.target.value, name: `${e.target.value}-default` })}
                    />
                  </Col>
                  <Col sm={6} xs={12}>
                    <FloatInput
                      required
                      label="Name"
                      value={newStateDefault.name || ''}
                      onChange={(e) => setNewStateDefault({ ...newStateDefault, name: e.target.value })}
                      disabled
                    />
                  </Col>

                </Row>
                <Row className="mt-3">
                  <Col sm={3} xs={12}>
                    <FloatInput
                      required
                      label="Fixed Charge"
                      prepend="$"
                      value={newStateDefault.fixed_charge || ''}
                      onChange={(e) => setNewStateDefault({ ...newStateDefault, fixed_charge: e.target.value })}
                    />
                  </Col>
                  <Col sm={3} xs={12}>
                    <FloatInput
                      required
                      label="Rate Amount"
                      prepend="$"
                      value={newStateDefault.rate_amount || ''}
                      onChange={(e) => setNewStateDefault({ ...newStateDefault, rate_amount: e.target.value })}
                    />
                  </Col>
                  {!newStateDefault?.offset_savings ? null : (

                    item.billing_type === 'net_metering' || item.billing_type === 'net_billing' ? null :
                      <>
                        <Col sm={3} xs={12}>
                          <FloatInput
                            required
                            label="Utility Fixed Solar Cost"
                            prepend="$"
                            value={newStateDefault.ufsc || ''}
                            onChange={(e) => setNewStateDefault({ ...newStateDefault, ufsc: e.target.value })}
                          />
                        </Col>
                        <Col sm={3} xs={12}>
                          <FloatInput
                            required
                            label="Export Rate"
                            prepend="$"
                            value={newStateDefault.export_rate || ''}
                            onChange={(e) => setNewStateDefault({ ...newStateDefault, export_rate: e.target.value })}
                          />
                        </Col>
                      </>
                  )}
                </Row>
                {<Row>
                  <Col xs={12} className='mt-3'>
                    <NetMeteringAndBilling
                      data={newStateDefault}
                      onChange={(field, value) => setNewStateDefault({ ...newStateDefault, [field]: value })}
                    />
                  </Col>
                </Row>}
                <Row className="mt-3 mb-3 float-right">
                  <Col>
                    <ButtonGroup size="sm">
                      <Button variant="outline-primary p-2" type="submit">
                        Save
                      </Button>
                      <Button variant="outline-primary p-2" onClick={() => setNewStateDefault()}>
                        Delete
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </ValidatedForm>
            </>
          )}
        </Tab>
        <Tab eventKey="per-utility-by-state" title="Per Utility by State/Province">
          <Alert variant="info" show={perUtilityByStateAlertOpened} onClose={() => setPerUtilityByStateAlertOpened(false)} dismissible>
            <p>If this section is populated you'll be able to select the utility provider that you populate here when doing a design / proposal.</p>
            <p><InfoCircleFill color='#2a64db' /> To bulk upload utility companies to a specific state download the CSV file <a target="_blank" download href="https://docs.google.com/spreadsheets/d/1zWIQiVn2aW11GsN22U2ptB3kOcHRuF1Jt9pGMLwZiRA/edit?gid=0#gid=0" style={{ textDecoration: 'underline' }}>here</a> and email it to <a href='mailto:support@aerialytic.com'>support@aerialytic.com</a>. We will upload it for you in 2 - 3 business days. </p>
          </Alert>
          <p>To bulk upload utility company rates, email us at <a href='mailto:support@aerialytic.com'>support@aerialytic.com</a> for the 'Utility Company Master List Template'. We will send you the latest template that you can fill out and upload below.</p>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            {
              isDragActive ?
                <p>Drop the CSV file here ...</p> :
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  <Inbox size={48} />
                  <p>Click or drag file to this aera to upload</p>
                </div>
            }
          </div>

          {companyRates.map((item, index) => (
            (item?.state !== null && !item?.name.includes('-default') && (
              <Row>
                <Col>
                  <ValidatedForm key={item?.id} onSave={() => postRateHandler(item)}>
                    <Row>
                      <Col sm={3} xs={12}>
                        <FloatInput
                          required
                          label="Name"
                          placeholder="Example: Texas_American Electric Power"
                          helpText="Include state and utility provider in name."
                          value={item.name || ''}
                          onChange={(e) => handleUpdateRate(e.target.value, 'name', index)}
                        />
                      </Col>
                      <Col sm={3} xs={12}>
                        <FloatSelect
                          label="Country"
                          nullValue="Choose Country"
                          options={countries}
                          labelField="name"
                          value={item.country}
                          onChange={(e) => handleUpdateRate(e.target.value, 'country', index)}
                          required
                          disabled
                        />
                      </Col>
                      <Col sm={3} xs={12}>
                        <FloatSelect
                          required
                          label="State"
                          nullValue="Choose State"
                          options={stateOptions[item?.country].map((el) => ({ name: el }))}
                          labelField="name"
                          value={item.state || ''}
                          onChange={(e) => handleUpdateRate(e.target.value, 'state', index)}
                          disabled
                        />
                      </Col>
                      <Col sm={3} xs={12}>
                        <FloatSelect
                          required
                          label="Utility Company"
                          nullValue="Choose Utility Company"
                          options={item?.country === 'Canada' ? allUtilities.filter((el) => el.country === item?.country) : allUtilities.filter((el) => el.country === item?.country && el.state === item?.state)}
                          labelField="name"
                          value={item.utility_company?.id || ''}
                          onChange={(e) => handleUpdateRate(e.target.value, 'utility_company', index)}
                          disabled
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col sm={3} xs={12}>
                        <FloatInput
                          required
                          label="Fixed Charge"
                          prepend="$"
                          value={item?.fixed_charge || ''}
                          onChange={(e) => handleUpdateRate(e.target.value, 'fixed_charge', index)}
                        />
                      </Col>
                      <Col sm={3} xs={12}>
                        <FloatInput
                          required
                          label="Rate Amount"
                          prepend="$"
                          value={item?.rate_amount || ''}
                          onChange={(e) => handleUpdateRate(e.target.value, 'rate_amount', index)}
                        />
                      </Col>
                      {!item?.offset_savings ? null : (

                        item.billing_type === 'net_metering' || item.billing_type === 'net_billing' ? null :
                          <>
                            <Col sm={3} xs={12}>
                              <FloatInput
                                required
                                label="Utility Fixed Solar Cost"
                                prepend="$"
                                value={item?.ufsc || ''}
                                onChange={(e) => handleUpdateRate(e.target.value, 'ufsc', index)}
                              />
                            </Col>
                            <Col sm={3} xs={12}>
                              <FloatInput
                                required
                                label="Export Rate"
                                prepend="$"
                                value={item?.export_rate || ''}
                                onChange={(e) => handleUpdateRate(e.target.value, 'export_rate', index)}
                              />
                            </Col>
                          </>
                      )}
                    </Row>
                    {<Row>
                      <Col xs={12} className='mt-3'>
                        <NetMeteringAndBilling
                          data={item}
                          onChange={(field, value) => handleUpdateRate(value, field, index)}
                        />
                      </Col>
                    </Row>}
                    <Row className="mt-3 mb-3 float-right">
                      <Col>
                        <ButtonGroup size="sm">
                          <Button variant="outline-primary p-2" type="submit">
                            <Save />
                          </Button>
                          <Button variant="outline-primary p-2" onClick={() => handleDelete(index)}>
                            <Trash />
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </ValidatedForm>
                </Col>
              </Row>
            ))
          ))}

          {newRate === undefined ? (
            <Row className="mt-2">
              <Col>
                <Button onClick={initRate}>
                  + Rate
                </Button>
              </Col>
            </Row>
          ) : (
            <>
              <ValidatedForm onSave={() => postRateHandler(newRate)}>
                <Row>
                  <Col sm={3} xs={12}>
                    <FloatInput
                      required
                      label="Name"
                      placeholder="Example: Texas_American Electric Power"
                      helpText="Include state and utility provider in name."
                      value={newRate.name || ''}
                      onChange={(e) => setNewRate({ ...newRate, name: e.target.value })}
                    />
                  </Col>
                  <Col sm={3} xs={12}>
                    <FloatSelect
                      label="Country"
                      nullValue="Choose Country"
                      options={countries}
                      labelField="name"
                      value={newRate.country || ''}
                      onChange={(e) => setNewRate({ ...newRate, country: e.target.value })}
                      required
                    />
                  </Col>
                  <Col sm={3} xs={12}>
                    <FloatSelect
                      required
                      label="State"
                      nullValue="Choose State"
                      options={states}
                      labelField="name"
                      value={newRate.state || ''}
                      onChange={(e) => setNewRate({ ...newRate, state: e.target.value })}
                    />
                  </Col>
                  <Col sm={3} xs={12}>
                    {spinner ? (
                      <Spinner animation="border" />
                    ) : (
                      <FloatSelect
                        required
                        label="Utility Company"
                        nullValue="Choose Utility Company"
                        options={utilityCompanies}
                        labelField="name"
                        value={newRate?.utility_company || ''}
                        onChange={(e) => {
                          setNewRate({ ...newRate, utility_company: e.target.value });
                        }}
                      />)
                    }
                  </Col>
                </Row>
                <Row className="mt-3">

                  <Col sm={3} xs={12}>
                    <FloatInput
                      required
                      label="Fixed Charge"
                      prepend="$"
                      value={newRate.fixed_charge || ''}
                      onChange={(e) => setNewRate({ ...newRate, fixed_charge: e.target.value })}
                    />
                  </Col>
                  <Col sm={3} xs={12}>
                    <FloatInput
                      required
                      label="Rate Amount"
                      prepend="$"
                      value={newRate.rate_amount || ''}
                      onChange={(e) => setNewRate({ ...newRate, rate_amount: e.target.value })}
                    />
                  </Col>
                  {!newRate?.offset_savings ? null : (
                    <>
                      <Col sm={3} xs={12}>
                        <FloatInput
                          required
                          label="Utility Fixed Solar Cost"
                          prepend="$"
                          value={newRate.ufsc || ''}
                          onChange={(e) => setNewRate({ ...newRate, ufsc: e.target.value })}
                        />
                      </Col>
                      <Col sm={3} xs={12}>
                        <FloatInput
                          required
                          label="Export Rate"
                          prepend="$"
                          value={newRate.export_rate || ''}
                          onChange={(e) => setNewRate({ ...newRate, export_rate: e.target.value })}
                        />
                      </Col>
                    </>
                  )}
                </Row>
                {<Row>
                  <Col xs={12} className='mt-3'>
                    <NetMeteringAndBilling
                      data={newRate}
                      onChange={(field, value) => setNewRate({ ...newRate, [field]: value })}
                    />
                  </Col>
                </Row>}
                <Row className="mt-3 mb-3 float-right">
                  <Col>
                    <ButtonGroup size="sm">
                      <Button variant="outline-primary p-2" type="submit">
                        Save
                      </Button>
                      <Button variant="outline-primary p-2" onClick={() => setNewRate()}>
                        Delete
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </ValidatedForm>
            </>
          )}
        </Tab>
      </Tabs>
    </>
  );
};

export default UtilityRate;
