import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs } from 'react-bootstrap';

import RoofSegmentsTab from './RoofSegmentsTab';
import SegmentsLabelsTab from './SegmentsLabelsTab';
import { segmentPropType } from '../../props';

function RoofSegments({
  designType,
  hasDSM,
  segments,
  resolution,
  selectedLabel,
  currentTabHandler,
  segmentAzimuthHandler,
  segmentPitchAndAreaHandler,
  segmentEaveHeightHandler,
  deleteShapeHandler,
  selectedLabelHandler,
  clearLabelsHandler,
  addPointToPolygonHandler,
  removePointFromPolygonHandler,
  measureHandler,
  runOptimizationHandler,
  runEdgeDetectionHandler,
  runAIHandler,
}) {
  return (
    <Tabs
      fill
      defaultActiveKey="roof-segments"
      id="uncontrolled-tab-example"
      onSelect={(key) => currentTabHandler(key)}
    >
      <Tab eventKey="roof-segments" title="Roof Segments">
        <RoofSegmentsTab
          designType={designType}
          hasDSM={hasDSM}
          segments={segments}
          resolution={resolution}
          segmentAzimuthHandler={segmentAzimuthHandler}
          segmentPitchAndAreaHandler={segmentPitchAndAreaHandler}
          segmentEaveHeightHandler={segmentEaveHeightHandler}
          deleteShapeHandler={deleteShapeHandler}
          addPointToPolygonHandler={addPointToPolygonHandler}
          removePointFromPolygonHandler={removePointFromPolygonHandler}
          measureHandler={measureHandler}
          runOptimizationHandler={runOptimizationHandler}
          runEdgeDetectionHandler={runEdgeDetectionHandler}
          runAIHandler={runAIHandler}
        />
      </Tab>
      <Tab eventKey="segment-labels" title="Segment Labels">
        <SegmentsLabelsTab
          designType={designType}
          selectedLabel={selectedLabel}
          selectedLabelHandler={selectedLabelHandler}
          clearLabelsHandler={clearLabelsHandler}
        />
      </Tab>
    </Tabs>
  );
}

RoofSegments.propTypes = {
  designType: PropTypes.oneOf(['M', 'A']).isRequired,
  hasDSM: PropTypes.bool.isRequired,
  segments: segmentPropType.isRequired,
  selectedLabel: PropTypes.string.isRequired,
  currentTabHandler: PropTypes.func.isRequired,
  segmentAzimuthHandler: PropTypes.func.isRequired,
  segmentPitchAndAreaHandler: PropTypes.func.isRequired,
  segmentEaveHeightHandler: PropTypes.func.isRequired,
  deleteShapeHandler: PropTypes.func.isRequired,
  selectedLabelHandler: PropTypes.func.isRequired,
  clearLabelsHandler: PropTypes.func.isRequired,
  addPointToPolygonHandler: PropTypes.func.isRequired,
  removePointFromPolygonHandler: PropTypes.func.isRequired,
  measureHandler: PropTypes.func.isRequired,
  runOptimizationHandler: PropTypes.func.isRequired,
  runEdgeDetectionHandler: PropTypes.func.isRequired,
  runAIHandler: PropTypes.func.isRequired,
};

export default RoofSegments;
