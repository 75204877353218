import React from 'react';
import { Table } from 'react-bootstrap';
import { formatCurrency } from 'Main/utils';

const ensureNumber = (value, defaultValue = 0) => {
    if (typeof value === 'string') {
        const parsed = parseFloat(value);
        return isNaN(parsed) ? defaultValue : parsed;
    }
    const num = Number(value);
    return isNaN(num) ? defaultValue : num;
};

// Add formatNumber function
const formatNumber = (value, decimals = 2) => {
    if (typeof value !== 'number' || isNaN(value)) {
        return '0.00';
    }
    return value.toFixed(decimals);
};

// Add formatRate function for displaying rates
const formatRate = (value) => {
    if (typeof value !== 'number' || isNaN(value)) {
        return '$0.0000';
    }
    return `$${value.toFixed(4)}`;
};

// Add formatDollars function for displaying currency without formatCurrency
const formatDollars = (value) => {
    if (typeof value !== 'number' || isNaN(value)) {
        return '$0.00';
    }
    return `$${value.toFixed(2)}`;
};

/**
 * Calculate net billing with threshold-based self consumption
 * @param {Object} params Parameters for calculation
 * @param {Array} params.monthlyProduction Array of monthly production values
 * @param {Array} params.monthlyConsumption Array of monthly consumption values
 * @param {number} params.systemSize System size in kW
 * @param {Object} params.utility Utility configuration
 * @param {Object} params.panel Panel configuration with degradation values
 * @returns {Object} Calculation results
 */
export const calculateNetBillingV2 = ({
    monthlyProduction,
    monthlyConsumption,
    systemSize,
    utility,
    panel,
    escalation
}) => {
    console.log('calculateNetBillingV2 inputs:', {
        monthlyProduction,
        monthlyConsumption,
        systemSize,
        utility,
        panel,
        escalation
    });

    // Get rates configuration from net_billing_specs
    const specs = utility.net_billing_specs || {};

    console.log('calculateNetBillingV2 specs:', specs, utility);
    const rates = {
        // Pre-solar rates
        preSolarFixed: Number(utility.fixed_charge || 0),
        preSolarRate: Number(utility.rate_amount || 0),
        // Post-solar rates
        postSolarFixed: Number(specs.ufsc || 22),
        exportRate: Number(specs.export_rate || 0.022),
        threshold: Number(specs.export_percentage) || 40,
        // Cost calculation type
        fixedCostType: specs.fixed_cost_type || 1,
        costCap: Number(specs.cost_cap || 0),
        costPerKw: Number(specs.cost_per_kw || 0)
    };

    // Get panel degradation values
    const firstYearDegradation = panel?.first_year_degradation ? Number(panel.first_year_degradation) / 100 : 0.02;
    const annualDegradation = panel?.degradation ? Number(panel.degradation) / 100 : 0.0025;

    // Get escalation rate (default to 3%)
    const escalationRate = typeof escalation === 'number' ? escalation : 0.03;

    console.log('Net Billing Calculation - Using escalation rate:', {
        rawEscalation: escalation,
        escalationRate,
        isNumber: typeof escalation === 'number'
    });

    // Get current month and year
    const currentDate = new Date();
    const startMonthIndex = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];

    // Order months starting from current month through next year's month before current
    const orderedMonths = [...monthNames.slice(startMonthIndex), ...monthNames.slice(0, startMonthIndex)].map((name, i) => {
        const monthIndex = (startMonthIndex + i) % 12;
        const yearOffset = monthIndex < startMonthIndex ? 1 : 0; // If we've wrapped around to next year
        return {
            name,
            originalIndex: monthIndex,
            calendarYear: currentYear + yearOffset,
            isPreSolar: false // No pre-solar months when starting from current
        };
    });

    // Initialize results array for each year
    const yearlyResults = [];

    // Calculate for each year (0-29)
    for (let year = 0; year < 30; year++) {
        // Apply rate escalations
        const yearRates = {
            ...rates,
            preSolarRate: rates.preSolarRate * Math.pow(1 + escalationRate, year),
            postSolarFixed: rates.fixedCostType === 'constant' || rates.fixedCostType === 1
                ? rates.postSolarFixed * Math.pow(1 + escalationRate, year)
                : rates.postSolarFixed,
            exportRate: rates.exportRate * Math.pow(1 + escalationRate, year)
        };

        // Calculate post-solar fixed cost for this year
        const calculatePostSolarCost = () => {
            if (yearRates.fixedCostType === 'constant' || yearRates.fixedCostType === 1) {
                return yearRates.costCap || yearRates.postSolarFixed;
            }
            // For system size based cost, use reference system size from specs
            const referenceSystemSize = utility.net_billing_specs?.system_size || systemSize;
            // Cap at reference system size if actual size is larger
            const sizeToUse = Math.min(systemSize, referenceSystemSize);

            const calculatedCost = sizeToUse * yearRates.costPerKw;
            console.log('System Size Based Cost Calculation:', {
                actualSystemSize: systemSize,
                referenceSystemSize,
                sizeUsedForCalc: sizeToUse,
                costPerKw: yearRates.costPerKw,
                calculatedCost,
                costCap: yearRates.costCap,
                finalCost: yearRates.costCap ? Math.min(calculatedCost, yearRates.costCap) : calculatedCost
            });
            return yearRates.costCap ? Math.min(calculatedCost, yearRates.costCap) : calculatedCost;
        };

        const postSolarFixedCost = calculatePostSolarCost();

        // Calculate degradation factor
        const degradationFactor = year === 0 ?
            1 - firstYearDegradation :
            (1 - firstYearDegradation) * Math.pow(1 - annualDegradation, year);

        // Process each month
        const monthlyData = orderedMonths.map((monthData) => {
            // Ensure all values are numbers
            const consumption = Number(monthlyConsumption[monthData.originalIndex] || 0);
            // Apply degradation to production, but zero for pre-solar months in first year
            const production = monthData.isPreSolar && year === 0
                ? 0
                : Number(monthlyProduction[monthData.originalIndex] || 0) * degradationFactor;

            // Calculate threshold-based values
            const maxOffset = consumption * (yearRates.threshold / 100);
            const selfConsumed = Math.min(production, maxOffset);
            const exported = Math.max(0, production - selfConsumed);
            const remaining = consumption - selfConsumed;

            // Calculate costs
            const remainingCost = remaining * yearRates.preSolarRate;
            const exportCredit = exported * yearRates.exportRate;

            // Calculate bills
            const preSolarBill = consumption * yearRates.preSolarRate + yearRates.preSolarFixed;
            const postSolarBill = monthData.isPreSolar && year === 0
                ? preSolarBill  // For pre-solar months, use pre-solar bill
                : postSolarFixedCost + remainingCost - exportCredit;
            const savings = Math.max(0, preSolarBill - postSolarBill);

            return {
                month: monthData.name,
                calendarYear: monthData.calendarYear + year,  // Add year offset to base calendar year
                production,
                consumption,
                details: {
                    selfConsumed,
                    exported,
                    remaining,
                    maxOffset,
                    threshold: yearRates.threshold
                },
                costs: {
                    preSolar: preSolarBill,
                    postSolar: postSolarBill,
                    fixed: postSolarFixedCost,
                    remaining: remainingCost,
                    exportCredit,
                    savings
                },
                rates: yearRates,
                isPreSolar: monthData.isPreSolar && year === 0  // Keep this flag for consistency
            };
        });

        // Calculate annual totals (excluding pre-solar months for production/savings)
        const annualTotals = monthlyData.reduce((totals, month) => ({
            production: totals.production + (month.isPreSolar ? 0 : month.production),
            consumption: totals.consumption + month.consumption,
            selfConsumed: totals.selfConsumed + (month.isPreSolar ? 0 : month.details.selfConsumed),
            exported: totals.exported + (month.isPreSolar ? 0 : month.details.exported),
            remaining: totals.remaining + month.details.remaining,
            preSolarCost: totals.preSolarCost + month.costs.preSolar,
            postSolarCost: totals.postSolarCost + month.costs.postSolar,
            savings: totals.savings + month.costs.savings,
            isPartialYear: year === 0,
            activeMonths: monthlyData.filter(m => !m.isPreSolar).length
        }), {
            production: 0,
            consumption: 0,
            selfConsumed: 0,
            exported: 0,
            remaining: 0,
            preSolarCost: 0,
            postSolarCost: 0,
            savings: 0,
            isPartialYear: year === 0,
            activeMonths: monthlyData.filter(m => !m.isPreSolar).length
        });

        yearlyResults.push({
            year: year + 1,
            monthlyData,
            annualTotals,
            rates: yearRates
        });
    }

    return {
        yearlyResults,
        metadata: {
            systemSize,
            rates,
            currentMonth: monthNames[startMonthIndex],
            cycleStartMonth: monthNames[startMonthIndex],
            calculationTimestamp: new Date().toISOString()
        }
    };
};

/**
 * Net Billing Table Component
 */
export const NetBillingTable = ({ calculationResults }) => {
    if (!calculationResults?.yearlyResults?.length) {
        return <div className="alert alert-warning">No calculation results available</div>;
    }

    const [selectedYear, setSelectedYear] = React.useState(1);
    const { yearlyResults, metadata } = calculationResults;
    console.log('NetBillingTable metadata:', metadata);
    const rates = metadata.rates;

    // Get current year's data
    const currentYear = yearlyResults.find(y => y.year === selectedYear);
    if (!currentYear) return null;

    // Calculate 30-year totals
    const thirtyYearTotals = yearlyResults.reduce((acc, year) => ({
        production: acc.production + year.annualTotals.production,
        consumption: acc.consumption + year.annualTotals.consumption,
        selfConsumed: acc.selfConsumed + year.annualTotals.selfConsumed,
        exported: acc.exported + year.annualTotals.exported,
        remaining: acc.remaining + year.annualTotals.remaining,
        preSolarCost: acc.preSolarCost + year.annualTotals.preSolarCost,
        postSolarCost: acc.postSolarCost + year.annualTotals.postSolarCost,
        savings: acc.savings + year.annualTotals.savings
    }), {
        production: 0,
        consumption: 0,
        selfConsumed: 0,
        exported: 0,
        remaining: 0,
        preSolarCost: 0,
        postSolarCost: 0,
        savings: 0
    });

    return (
        <div className="table-responsive">
            <div className="mb-3">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <small className="text-muted">
                            {currentYear.annualTotals.isPartialYear ?
                                `Solar starts ${metadata.currentMonth} ${currentYear.monthlyData[0].calendarYear}` :
                                `Year ${currentYear.year} (${currentYear.monthlyData[0].calendarYear}-${currentYear.monthlyData[11].calendarYear})`}
                        </small>
                        <div className="mt-1">
                            <select
                                className="form-control form-control-sm"
                                value={selectedYear}
                                onChange={(e) => setSelectedYear(Number(e.target.value))}
                            >
                                {yearlyResults.map(year => {
                                    const firstMonth = year.monthlyData[0];
                                    const lastMonth = year.monthlyData[11];
                                    return (
                                        <option key={year.year} value={year.year}>
                                            Year {year.year} ({firstMonth.calendarYear}-{lastMonth.calendarYear})
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="text-right">
                        <small className="d-block">
                            <strong>Pre-Solar Fixed Charge:</strong> {formatDollars(rates.preSolarFixed)}/month
                        </small>
                        <small className="d-block">
                            <strong>Pre-Solar Rate:</strong> {formatRate(rates.preSolarRate)}/kWh
                        </small>
                        <small className="d-block">
                            <strong>Post-Solar Fixed Charge:</strong> {rates.fixedCostType === 'constant' || rates.fixedCostType === 1 ?
                                `${formatDollars(rates.postSolarFixed)}/month` :
                                `${formatDollars(rates.costPerKw)}/kW (Cap: ${formatDollars(rates.costCap)})`
                            }
                        </small>
                        <small className="d-block">
                            <strong>Export Rate:</strong> {formatRate(rates.exportRate)}/kWh
                        </small>
                        <small className="d-block">
                            <strong>Self-Consumption Threshold:</strong> {rates.threshold}%
                        </small>
                    </div>
                </div>
            </div>

            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th rowSpan="2">Month</th>
                        <th colSpan="3">Solar Production</th>
                        <th colSpan="3">Consumption & Threshold</th>
                        <th colSpan="4">Billing</th>
                    </tr>
                    <tr>
                        <th>Production (kWh)</th>
                        <th>Self-Consumed (kWh)</th>
                        <th>Exported (kWh)</th>
                        <th>Total (kWh)</th>
                        <th>Max Offset (kWh)</th>
                        <th>Remaining (kWh)</th>
                        <th>Pre-Solar ($)</th>
                        <th>Post-Solar ($)</th>
                        <th>Export Credit ($)</th>
                        <th>Savings ($)</th>
                    </tr>
                </thead>
                <tbody>
                    {currentYear.monthlyData.map(month => (
                        <tr key={month.month}
                            style={month.isPreSolar ? {
                                backgroundColor: '#f5f5f5',
                                color: '#999'
                            } : {}}>
                            <td>
                                {month.month} {month.calendarYear}
                                {month.isPreSolar && <span className="ml-1 text-muted">†</span>}
                            </td>
                            <td title={`Total solar production for this month`}>
                                {month.isPreSolar ? '-' : formatNumber(month.production)}
                            </td>
                            <td title={`Energy consumed directly up to ${rates.threshold}% threshold (${formatNumber(month.details.maxOffset)} kWh)`}>
                                {month.isPreSolar ? '-' : formatNumber(month.details.selfConsumed)}
                            </td>
                            <td title={`Excess energy exported to grid at ${formatRate(month.rates.exportRate)}/kWh`}>
                                {month.isPreSolar ? '-' : formatNumber(month.details.exported)}
                            </td>
                            <td title={`Total energy consumption`}>
                                {formatNumber(month.consumption)}
                            </td>
                            <td title={`Maximum energy that can be offset (${rates.threshold}% of ${formatNumber(month.consumption)} kWh)`}>
                                {month.isPreSolar ? '-' : formatNumber(month.details.maxOffset)}
                            </td>
                            <td title={`Energy purchased from utility at ${formatRate(month.rates.preSolarRate)}/kWh`}>
                                {month.isPreSolar ? '-' : formatNumber(month.details.remaining)}
                            </td>
                            <td title={`Pre-solar bill = (${formatNumber(month.consumption)} kWh × ${formatRate(month.rates.preSolarRate)}) + ${formatDollars(month.rates.preSolarFixed)}`}>
                                {formatDollars(month.costs.preSolar)}
                            </td>
                            <td title={`Post-solar bill = ${formatDollars(month.costs.fixed)} + (${formatNumber(month.details.remaining)} kWh × ${formatRate(month.rates.preSolarRate)}) - (${formatNumber(month.details.exported)} kWh × ${formatRate(month.rates.exportRate)})`}>
                                {month.isPreSolar ? '-' : formatDollars(month.costs.postSolar)}
                            </td>
                            <td title={`Export credit = ${formatNumber(month.details.exported)} kWh × ${formatRate(month.rates.exportRate)}`}>
                                {month.isPreSolar ? '-' : formatDollars(month.costs.exportCredit)}
                            </td>
                            <td title={`Total savings = ${formatDollars(month.costs.preSolar)} - ${formatDollars(month.costs.postSolar)}`}
                                style={{ color: month.isPreSolar ? '#999' : (month.costs.savings >= 0 ? 'green' : 'red') }}>
                                {month.isPreSolar ? '-' : formatDollars(month.costs.savings)}
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <th>Annual Total</th>
                        <td>{formatNumber(currentYear.annualTotals.production)}</td>
                        <td>{formatNumber(currentYear.annualTotals.selfConsumed)}</td>
                        <td>{formatNumber(currentYear.annualTotals.exported)}</td>
                        <td>{formatNumber(currentYear.annualTotals.consumption)}</td>
                        <td>-</td>
                        <td>{formatNumber(currentYear.annualTotals.remaining)}</td>
                        <td>{formatDollars(currentYear.annualTotals.preSolarCost)}</td>
                        <td>{formatDollars(currentYear.annualTotals.postSolarCost)}</td>
                        <td>{formatDollars(currentYear.annualTotals.exported * currentYear.rates.exportRate)}</td>
                        <td>{formatDollars(currentYear.annualTotals.savings)}</td>
                    </tr>
                </tfoot>
            </Table>

            <div className="mt-3">
                <h6>30-Year Summary</h6>
                <div className="row">
                    <div className="col-md-4">
                        <small className="d-block">
                            <strong>Total Production:</strong> {formatNumber(thirtyYearTotals.production)} kWh
                        </small>
                        <small className="d-block">
                            <strong>Total Self-Consumed:</strong> {formatNumber(thirtyYearTotals.selfConsumed)} kWh
                        </small>
                        <small className="d-block">
                            <strong>Total Exported:</strong> {formatNumber(thirtyYearTotals.exported)} kWh
                        </small>
                    </div>
                    <div className="col-md-4">
                        <small className="d-block">
                            <strong>Total Consumption:</strong> {formatNumber(thirtyYearTotals.consumption)} kWh
                        </small>
                        <small className="d-block">
                            <strong>Total Remaining:</strong> {formatNumber(thirtyYearTotals.remaining)} kWh
                        </small>
                        <small className="d-block">
                            <strong>Offset Percentage:</strong> {formatNumber((thirtyYearTotals.selfConsumed / thirtyYearTotals.consumption) * 100)}%
                        </small>
                    </div>
                    <div className="col-md-4">
                        <small className="d-block">
                            <strong>Pre-Solar 30-Year Cost:</strong> {formatDollars(thirtyYearTotals.preSolarCost)}
                        </small>
                        <small className="d-block">
                            <strong>Post-Solar 30-Year Cost:</strong> {formatDollars(thirtyYearTotals.postSolarCost)}
                        </small>
                        <small className="d-block text-success">
                            <strong>Total 30-Year Savings:</strong> {formatDollars(thirtyYearTotals.savings)}
                        </small>
                    </div>
                </div>
            </div>

            <div className="mt-2">
                {currentYear.annualTotals.isPartialYear && (
                    <small className="text-muted d-block">
                        † Pre-solar months (before {metadata.currentMonth})
                    </small>
                )}
            </div>
        </div>
    );
}; 