import React, {
  useState, useContext, useEffect, useRef, useMemo, useCallback,
} from 'react';
import { MeshLine, MeshLineMaterial, MeshLineRaycast } from 'three.meshline';
import * as THREE from 'three';
import {
  extend, Canvas, useFrame, useThree,
} from '@react-three/fiber';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import robotoFont from 'assets/fonts/Roboto_Bold.json';

import PropTypes from 'prop-types';
import { DesignContext } from '../contexts/designContext.js';

import {
  DrawingState,
  lineColors,
  dotColors,
  getConnectedComponents,
  getSnapLines,
  getPolygonEave,
} from '../algorithms/drawing.js';

extend({
  MeshLine, MeshLineMaterial, MeshLineRaycast, TextGeometry,
});

const raycaster = new THREE.Raycaster();
const drawingFirstPosition = new THREE.Vector3(0, 0, 0);
const edgeFont = new FontLoader().parse(robotoFont);
const yAxis = new THREE.Vector3(0, 1, 0);
document.ringScale = 1.0;

const cvDriveway = document.createElement('canvas');

function Text({
  txt, startPosition, endPosition, autoText, color, size, offset,
}) {
  const { getFootPerSceneUnit } = useContext(DesignContext);

  const ref = useRef();
  const refUnderLine = useRef();
  const planeRef = useRef();

  useEffect(() => {
    if (ref.current) {
      const s = startPosition.clone();
      const e = endPosition.clone();
      let n = e.sub(s);
      const l = n.length();
      n = n.normalize();
      const o = n.clone().cross(yAxis);
      const theta = Math.atan2(n.x, n.z) - Math.PI / 2;
      ref.current.geometry.computeBoundingSphere();
      ref.current.geometry.computeBoundingBox();
      const box = ref.current.geometry.boundingBox;
      const r = ref.current.geometry.boundingSphere?.radius ?? 0;
      const p = s.add(n.multiplyScalar(l / 2.0 - r)).sub(o.multiplyScalar(offset));
      ref.current.position.set(p.x, p.y, p.z);
      ref.current.rotation.set(-Math.PI / 2, 0, theta);

      if (refUnderLine.current) {
        refUnderLine.current.position.set(p.x, p.y, p.z);
        refUnderLine.current.rotation.set(-Math.PI / 2, 0, theta);
      }

      const matrix = new THREE.Matrix4().makeTranslation(p.x, p.y, p.z).multiply(
        new THREE.Matrix4().makeRotationX(-Math.PI / 2).multiply(
        new THREE.Matrix4().makeRotationZ(theta)),
      ).multiply(new THREE.Matrix4().makeTranslation((box.max.x - box.min.x) / 2, (box.max.y - box.min.y) / 2, 0))
       .multiply(new THREE.Matrix4().makeScale(box.max.x - box.min.x, box.max.y - box.min.y, 1));

      planeRef.current.matrix.identity();
      planeRef.current.applyMatrix4(matrix);
    }
  }, [startPosition, endPosition, offset]);

  const content = useMemo(() => {
    if (autoText) {
      const l = getFootPerSceneUnit(startPosition.clone().sub(endPosition).length());
      if (l >= 10) return `${l.toFixed(2)} ft`;
      if (l >= 5) return `${l.toFixed(1)}`;
    }
    return txt;
  }, [autoText, endPosition, getFootPerSceneUnit, startPosition, txt]);

  return (
    <>
      <mesh renderOrder={3} ref={planeRef} matrixAutoUpdate={false}>
        <planeGeometry args={[1,1]}/>
        <meshBasicMaterial attach="material" color="white" depthTest={false} transparent opacity={0.5} />
      </mesh>
      <mesh renderOrder={4} ref={ref}>
        <textGeometry args={[content, { font: edgeFont, size, height: 0.1, curveSegments: 1, bevelEnabled: false }]} />
        <meshBasicMaterial attach="material" color="black" depthTest={false} transparent opacity={1} />
      </mesh>
      {!autoText && (txt == "6") && (
        <mesh renderOrder={4} ref={refUnderLine}>
          <textGeometry args={["_", { font: edgeFont, size: size + 0.5, height: 0.1, curveSegments: 1, bevelEnabled: false }]} />
          <meshBasicMaterial attach="material" color="black" depthTest={false} transparent opacity={1} />
        </mesh>
      )}
    </>
  );
}
Text.propTypes = {
  txt: PropTypes.string,
  startPosition: PropTypes.objectOf(THREE.Vector3).isRequired,
  endPosition: PropTypes.objectOf(THREE.Vector3).isRequired,
  autoText: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.number,
  offset: PropTypes.number,
};
Text.defaultProps = {
  txt: '',
  autoText: true,
  color: 'black',
  size: 1.5,
  offset: 1.0,
};

function Line({
  start, end, color, width, autoText, dots, enabled, measurements, fontScale, hasSegments,
}) {
  const { getFootPerSceneUnit, getSceneUnitPerFoot } = useContext(DesignContext);

  const l = useMemo(() => start.clone().sub(end).length(), [start, end]);

  const segmentLines = useMemo(() => {
    if (!hasSegments) return null;
    const l = getFootPerSceneUnit(start.clone().sub(end).length());
    const step = getSceneUnitPerFoot(10);
    const segments = Array(Math.floor(l / 10)).fill(1.0).map((v, i) => i % 10 == 0 ? v * 3 : v);
    const n = end.clone().sub(start).normalize();
    const o = n.clone().cross(yAxis);
    const lines = segments.map((s, i) => {
      const p = start.clone().add(n.clone().multiplyScalar(i * step));
      return [p.clone().sub(o.clone().multiplyScalar(s)), p.clone().add(o.clone().multiplyScalar(s))];
    });
    return lines;
  }, [hasSegments, start, end]);

  return (
    <>
      <mesh renderOrder={2}>
        <meshLine attach="geometry" points={[start, end]} />
        <meshLineMaterial
          side={THREE.DoubleSide}
          attach="material"
          color={color}
          depthTest={false}
          transparent
          sizeAttenuation={0}
          lineWidth={enabled || measurements ? width : 0.003}
          dashArray={dots ? 1.0 / l : 0}
          dashRatio={dots ? 0.5 : 0}
        />
      </mesh>
      {segmentLines && segmentLines.map((s) => (
        <mesh renderOrder={2}>
          <meshLine attach="geometry" points={[s[0], s[1]]} />
          <meshLineMaterial
            side={THREE.DoubleSide}
            attach="material"
            color={color}
            depthTest={false}
            transparent
            sizeAttenuation={0}
            lineWidth={enabled || measurements ? width : 0.003}
          />
        </mesh>
      ))}
      {(enabled || measurements) && <Text autoText={autoText} startPosition={start} endPosition={end} color="black" size={1.25 * fontScale} />}
    </>
  );
}
Line.propTypes = {
  start: PropTypes.objectOf(THREE.Vector3).isRequired,
  end: PropTypes.objectOf(THREE.Vector3).isRequired,
  color: PropTypes.string,
  width: PropTypes.number,
  autoText: PropTypes.bool,
  dots: PropTypes.bool,
  enabled: PropTypes.bool,
  measurements: PropTypes.bool,
  fontScale: PropTypes.number,
  hasSegments: PropTypes.bool,
};
Line.defaultProps = {
  color: 'white',
  width: 0.004,
  autoText: true,
  dots: false,
  enabled: true,
  measurements: true,
  fontScale: 1.0,
  hasSegments: false,
};

function Ring({
  point, color, r1, r2, opacity = 1.0,
}) {
  const ref = useRef();

  useFrame((state) => {
    const { camera } = state;
    let ratio = 1.0;
    if (camera.type === 'PerspectiveCamera') ratio = Math.max(0.1, Math.min(1.0, camera.position.length() / 100)).toFixed(2);
    else ratio = Math.max(0.2, Math.min(1.0, 4.0 / camera.zoom)).toFixed(2);
    if (ref.current.scale.x !== ratio) {
      ref.current.scale.set(ratio, ratio, ratio);
      document.ringScale = ratio;
    }
  });

  return (
    <mesh
      name="ring"
      visible
      renderOrder={3}
      position={point}
      rotation={[-Math.PI / 2, 0, 0]}
      ref={ref}
    >
      <ringBufferGeometry args={[r1, r2, 32]} />
      <meshBasicMaterial attach="material" color={color} depthTest={false} side={THREE.DoubleSide} transparent opacity={opacity} />
    </mesh>
  );
}
Ring.propTypes = {
  point: PropTypes.objectOf(THREE.Vector3).isRequired,
  color: PropTypes.string.isRequired,
  r1: PropTypes.number.isRequired,
  r2: PropTypes.number.isRequired,
  opacity: PropTypes.number.isRequired,
};

function Shape({
  points, edgeCount, index, visibility, backgroundColor, backgroundStyle, text, fontScale,
}) {
  const geo = useRef();
  const mat = useRef();
  const vertices = useMemo(() => {
    const triangleIndices = THREE.ShapeUtils.triangulateShape(points.map((v) => new THREE.Vector3(v.x, v.z, 0)), []);
    const array = [];
    triangleIndices.forEach((trig) => {
      trig.forEach((i) => {
        const v = points[i];
        array.push(v.x, v.y, v.z);
      });
    });
    const buffer = new Float32Array(array);
    return buffer;
  }, [points]);

  const textBaseLine = useMemo(() => {
    if (backgroundStyle != 'hashed') {
      if (points.length === 2) return [points[0], points[1]];
      if (points.length < 3) return [points[0], points[1]];
      const [ui, vi] = getPolygonEave(points, edgeCount);
      const [u, v] = [points[ui], points[vi]];
      return [u, v];
    } else {
      const xs = points.map((v) => v.x);
      const ys = points.map((v) => v.z);
      const u = new THREE.Vector3(Math.min(...xs), 0, (Math.min(...ys) + Math.max(...ys)) / 2);
      const v = new THREE.Vector3(Math.max(...xs), 0, (Math.min(...ys) + Math.max(...ys)) / 2);
      return [u, v];
    }
    // const centroid = points.reduce((acc, curv) => acc.add(curv), zero).multiplyScalar(1.0 / points.length);
    // const uv = v.clone().sub(u).normalize();
    // const start = centroid.clone().add(uv.clone().multiplyScalar(10));
    // const end = centroid.clone().add(uv.clone().multiplyScalar(-10));
  }, [points, edgeCount, backgroundStyle]);

  const raiseClickPolygon = () => {
    document.dispatchEvent(new CustomEvent('ClickPolygon', { detail: { index }, bubbles: true }));
  };

  const color = useMemo(() => backgroundColor || (Math.random() * 0xffffff), [backgroundColor]);

  // const hashedMap = useMemo(() => {
  //   const cv = document.createElement('canvas'), sz = 64, ctx = cv.getContext('2d');
  //   cv.width = cv.height = sz; ctx.fillStyle = 'green'; ctx.fillRect(0, 0, sz, sz);
  //   for (let i = -sz; i < 2 * sz; i += 8) { ctx.beginPath(); ctx.moveTo(i, 0); ctx.lineTo(i + sz, sz); ctx.strokeStyle = 'white'; ctx.stroke(); }
  //   const txDriveway = new THREE.CanvasTexture(cv); txDriveway.wrapS = txDriveway.wrapT = THREE.RepeatWrapping; txDriveway.repeat.set(8, 8);
  //   txDriveway.needsUpdate = true;
  //   return txDriveway;
  // });

useEffect(() => {
  if (geo.current) {
    geo.current.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
    geo.current.attributes.position.needsUpdate = true;
    geo.current.computeBoundingBox();
    geo.current.computeBoundingSphere();

    if (backgroundStyle === "hashed") {
      const bbox = geo.current.boundingBox;
      const rangeX = bbox.max.x - bbox.min.x;
      const rangeY = bbox.max.z - bbox.min.z;
      const posArr = geo.current.attributes.position.array;
      const uvs = new Float32Array((posArr.length / 3) * 2);

      for (let i = 0, j = 0; i < posArr.length; i += 3, j += 2) {
        const x = posArr[i], y = posArr[i + 2];
        uvs[j] = (x - bbox.min.x) / rangeX;
        uvs[j + 1] = (y - bbox.min.y) / rangeY;
      }
      geo.current.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));

      const sz = 64;
      cvDriveway.width = cvDriveway.height = sz;
      const ctx = cvDriveway.getContext('2d');

      ctx.clearRect(0, 0, sz, sz);

      ctx.strokeStyle = 'white';
      ctx.lineWidth = 8;
      for (let i = -sz; i < 2 * sz; i += 32) {
        ctx.beginPath();
        ctx.moveTo(i-10, -10);
        ctx.lineTo(i + sz + 10, sz + 10);
        ctx.stroke();
      }

      const txDriveway = new THREE.CanvasTexture(cvDriveway);
      txDriveway.wrapS = txDriveway.wrapT = THREE.RepeatWrapping;
      txDriveway.repeat.set(rangeX / 5, rangeY / 5);
      txDriveway.needsUpdate = true;

      mat.current.map = txDriveway;
      mat.current.needsUpdate = true;
    }
  }
}, [vertices, backgroundStyle]);


  return (
    <>
      {visibility === 'all' && backgroundStyle == '' && (
        <>
          <mesh renderOrder={1} onClick={raiseClickPolygon}>
            <bufferGeometry attach="geometry" ref={geo} />
            <meshBasicMaterial attach="material" color={color} depthTest={false} side={THREE.DoubleSide} transparent opacity={0.3} />
          </mesh>
          {['all', 'indices'].includes(visibility) && index > 0 && <Text autoText={false} offset={1.0} txt={String(index)} startPosition={textBaseLine[0]} endPosition={textBaseLine[1]} color="black" size={2.0 * fontScale} />}
        </>
      )}
      {backgroundStyle == 'hashed' && (
        <>
          <mesh renderOrder={1} onClick={raiseClickPolygon}>
            <bufferGeometry attach="geometry" ref={geo} />
              <meshBasicMaterial attach="material" ref={mat} depthTest={false} side={THREE.DoubleSide} transparent />
          </mesh>
          <Text renderOrder={1} autoText={false} offset={0.0} txt={text} startPosition={textBaseLine[0]} endPosition={textBaseLine[1]} color="white" size={2.0 * fontScale} />
        </>
      )}
    </>
  );
}
Shape.propTypes = {
  index: PropTypes.number.isRequired,
  points: PropTypes.objectOf(THREE.Vector3).isRequired,
  edgeCount: PropTypes.objectOf(Number),
  visibility: PropTypes.string,
  text: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundStyle: PropTypes.string,
  fontScale: PropTypes.number,
};
Shape.defaultProps = {
  visibility: 'all',
  edgeCount: [],
  text: '',
  backgroundColor: '',
  backgroundStyle: '',
  fontScale: 1.0,
};

function Polygon({
  points, edgeCount, index, lastConnect, colors, widths, enabled, visibility, boldStart, type, measurements, fill, backgroundColor, backgroundStyle, text, dots, ringScale, lineColor, fontScale, showLines, showRings, hasSegments,
}) {
  const ringSize = type !== 'Point' ? 1.0 * ringScale : 0.7 * ringScale;

  const showOnlyFirstRing = useMemo(() => {
    if (type === 'Point') {
      const len = points[0].clone().sub(points[1]).length();
      return len < 1.5;
    }
    return false;
  }, [points, type]);

  const fillVisibility = fill ? "all" : 'indices';

  return (
    <group>
      <Shape points={points} edgeCount={edgeCount} index={index} visibility={enabled ? fillVisibility : ''} backgroundColor={backgroundColor} backgroundStyle={backgroundStyle} text={text} fontScale={fontScale} rnd={Math.random()} />
      {showLines && points.map((v, vi) => (vi > 0) && <Line enabled={enabled} start={v} end={points[vi - 1]} color={vi in colors ? colors[vi] : lineColor} width={vi in widths ? widths[vi] : 0.006} measurements={measurements} dots={dots} fontScale={fontScale} hasSegments={hasSegments} />)}
      {showLines && lastConnect && points.length > 2 && points.map((v, vi) => <Line enabled={enabled} start={v} end={points[(vi + points.length - 1) % points.length]} color={vi in colors ? colors[vi] : lineColor} width={vi in widths ? widths[vi] : 0.006} measurements={measurements} dots={dots} fontScale={fontScale}  />)}
      {showRings && points.map((v, vi) => (!showOnlyFirstRing || vi === 0) && (
        <>
          <Ring point={v} color="black" r1={1.2 * ringSize} r2={1.5 * ringSize} opacity={1.0} />
          <Ring point={v} color={boldStart && vi === 0 ? 'blue' : lineColor} r1={1.5 * ringSize} r2={2.25 * ringSize} opacity={1.0} />
          <Ring point={v} color="white" r1={0} r2={2.25 * ringSize} opacity={0.01} />
        </>
      ))}
    </group>
  );
}
Polygon.propTypes = {
  index: PropTypes.number.isRequired,
  points: PropTypes.arrayOf(THREE.Vector3).isRequired,
  edgeCount: PropTypes.arrayOf(Number),
  lastConnect: PropTypes.bool,
  colors: PropTypes.arrayOf(PropTypes.string),
  widths: PropTypes.arrayOf(PropTypes.number),
  enabled: PropTypes.bool,
  visibility: PropTypes.string,
  type: PropTypes.string,
  boldStart: PropTypes.bool,
  measurements: PropTypes.bool,
  fill: PropTypes.bool,
  text: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundStyle: PropTypes.string,
  dots: PropTypes.bool,
  ringScale: PropTypes.number,
  lineColor: PropTypes.string,
  fontScale: PropTypes.number,
  showLines: PropTypes.bool,
  showRings: PropTypes.bool,
  hasSegments: PropTypes.number,
};
Polygon.defaultProps = {
  lastConnect: false,
  edgeCount: [],
  colors: [],
  widths: [],
  enabled: true,
  visibility: 'all',
  type: 'Polygon',
  boldStart: false,
  measurements: true,
  fill: true,
  text: '',
  backgroundColor: '',
  backgroundStyle: '',
  dots: false,
  ringScale: 1.0,
  lineColor: 'white',
  fontScale: 1.0,
  showLines: true,
  showRings: true,
  hasSegments: false,
};

function Circle({
  startPoint, endPoint, color, opacity, index,
}) {
  const r = useMemo(() => {
    const radius = endPoint.clone().setComponent(1, 0).sub(startPoint.clone().setComponent(1, 0)).length();
    return radius;
  }, [startPoint, endPoint]);

  const raiseClickCircle = () => {
    if (index !== -1) {
      document.dispatchEvent(new CustomEvent('ClickPolygon', { detail: { index }, bubbles: true }));
    }
  };

  return (
    <>
      <mesh
        name="circle"
        visible
        position={startPoint}
        rotation={[-Math.PI / 2, 0, 0]}
      >
        <ringBufferGeometry args={[r, r + 0.5, 32]} />
        <meshBasicMaterial attach="material" color={color} depthTest={false} transparent opacity={opacity} />
      </mesh>
      <mesh
        name="circle_fill"
        visible
        position={startPoint}
        rotation={[-Math.PI / 2, 0, 0]}
        onClick={raiseClickCircle}
      >
        <ringBufferGeometry args={[0, r + 0.5, 32]} />
        <meshBasicMaterial attach="material" depthTest={false} transparent opacity={0} />
      </mesh>
    </>
  );
}
Circle.propTypes = {
  startPoint: PropTypes.objectOf(THREE.Vector3).isRequired,
  endPoint: PropTypes.objectOf(THREE.Vector3).isRequired,
  color: PropTypes.string.isRequired,
  opacity: PropTypes.number,
  index: PropTypes.number,
};
Circle.defaultProps = {
  opacity: 1.0,
  index: -1,
};

function Cylandar({
  startPoint, endPoint, height, color, opacity, borderColor, borderOpacity,
}) {
  const [r, startTopPoint, endTopPoint] = useMemo(() => {
    const radius = endPoint.clone().setComponent(1, 0).sub(startPoint.clone().setComponent(1, 0)).length();
    const sTopPnt = startPoint.clone().add(new THREE.Vector3(0, height, 0));
    const eTopPnt = endPoint.clone().add(new THREE.Vector3(0, height, 0));
    return [radius, sTopPnt, eTopPnt];
  }, [startPoint, endPoint, height]);

  return (
    <>
      <mesh
        name="ring"
        visible
        renderOrder={3}
        position={startPoint}
      >
        <cylinderGeometry args={[r, r, height, 32]} />
        <meshBasicMaterial attach="material" color={color} depthTest={false} side={THREE.DoubleSide} transparent opacity={opacity} />
      </mesh>
      <Circle startPoint={startPoint} endPoint={endPoint} color={borderColor} opacity={borderOpacity} />
      <Circle startPoint={startTopPoint} endPoint={endTopPoint} color={borderColor} opacity={borderOpacity} />
    </>
  );
}
Cylandar.propTypes = {
  startPoint: PropTypes.objectOf(THREE.Vector3).isRequired,
  endPoint: PropTypes.objectOf(THREE.Vector3).isRequired,
  height: PropTypes.number,
  color: PropTypes.string,
  opacity: PropTypes.number,
  borderColor: PropTypes.string,
  borderOpacity: PropTypes.number,
};
Cylandar.defaultProps = {
  height: 1.0,
  color: 'green',
  opacity: 0.4,
  borderColor: 'white',
  borderOpacity: 1.0,
};

let downPoint = new THREE.Vector3(0, 0, 0);
const setDownPoint = (pnt) => { downPoint = pnt; };

const Drawing = ({
  controls, canvas, shapes, shapeTypes, labels, enabled, visible, tool, objectType, texts, backgroundColor, backgroundStyle, dots, ringScale, lineColor, forceMeasurements, fontScale, maxPoints, hasSegments,
}) => {
  const {
    camera, scene, size: canvasSize,
  } = useThree();

  const {
    mode,
    moveMode,
    viewMode,
    viewState,
    selectedLabel,
    addShape,
    editShapes,
    deleteShape,
    addPointToShape,
    deletePointFromShape,
    setLineLabel,
    drawingCapabilities,
    showMeasurements,
    setHistoryEnabled,
    getSceneUnitPerFoot,
  } = useContext(DesignContext);

  const [drawingState, setDrawingState] = useState(DrawingState.ReadyForDraw);
  const [points, setPoints] = useState([]);
  const [currentPoint, setCurrentPoint] = useState(null);
  // const [downPoint, setDownPoint] = useState(null);
  const [snapPoint, setSnapPoint] = useState(null);
  const [snapLines, setSnapLines] = useState(null);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [editedPolygons, setEditedPolygons] = useState(null);
  const [polygonsOrders, setPolygonsOrders] = useState([]);

  const hasCapability = useCallback((capability) => drawingCapabilities.includes(capability), [drawingCapabilities]);

  const polygons = useMemo(() => shapes?.map((vs) => vs.map((v) => new THREE.Vector3(v.x, v.z, v.y))), [shapes]);
  const types = useMemo(() => shapeTypes ?? shapes?.map(() => tool), [shapeTypes, shapes, viewState, mode, tool]);
  const edgeCounts = useMemo(() => {
    const allEdges = {};
    const edges = polygons?.map((vs) => vs.map((v, vi) => {
      const vj = (vi - 1 + vs.length) % vs.length;
      const v1 = v.clone().multiplyScalar(10).round();
      const v2 = vs[vj].clone().multiplyScalar(10).round();
      const key1 = [...v1, ...v2].toString();
      const key2 = [...v2, ...v1].toString();
      if (key1 in allEdges) allEdges[key1] += 1; else allEdges[key1] = 1;
      if (key2 in allEdges) allEdges[key2] += 1; else allEdges[key2] = 1;
      return [...v1, ...v2];
    }));
    const counts = edges.map((es) => es.map((e) => allEdges[e]));
    return counts;
  }, [polygons]);

  const flatPlane = useMemo(() => {
    const geometry = new THREE.PlaneGeometry(350, 350);
    const material = new THREE.MeshBasicMaterial({
      color: 0xffff00, side: THREE.DoubleSide, transparent: true, opacity: 0.0,
    });
    const plane = new THREE.Mesh(geometry, material);
    plane.name = 'guideplane';
    plane.rotateX(Math.PI / 2);
    return plane;
  }, []);

  useEffect(() => {
    if (mode === 'edit' && enabled && visible) scene.add(flatPlane);
    return () => { scene.remove(flatPlane); };
  }, [flatPlane, mode, enabled, visible, scene]);

  const updatePlane = useCallback((z) => { flatPlane.position.set(0, z, 0); }, [flatPlane]);

  useEffect(() => {
    if (polygonsOrders.length !== shapes.length) {
      const newOrders = shapes?.map((_, shi) => shi);
      setPolygonsOrders(newOrders);
    }
  }, [polygonsOrders.length, shapes]);

  useEffect(() => {
    setEditedPolygons(null);
    setDownPoint(null);
    setSelectedPoint(null);
    // setSnapPoint(null);
    setSnapLines(null);
  }, [shapes]);

  const getCurrentPoint = useCallback((e, onlyGuidePlane = false, notNull = false) => {
    drawingFirstPosition.set((e.offsetX / canvasSize.width) * 2 - 1, -(e.offsetY / canvasSize.height) * 2 + 1, 0);
    camera.updateProjectionMatrix();
    camera.updateMatrixWorld();
    raycaster.setFromCamera(drawingFirstPosition, camera);
    // const ground = scene.children.find((o) => o.name === 'ground');
    const objs = onlyGuidePlane ? [flatPlane] : scene.children;
    const intersects = raycaster.intersectObjects(objs, true);
    for (let index = intersects.length - 1; index >= 0; index -= 1) {
      const element = intersects[index];
      if (element.object.visible && element.object.name !== 'sky' && element.object.name !== 'wallBorder' && ((notNull && element.object.name !== 'guideplane') || onlyGuidePlane || mode === 'draw' || element.object.name === 'ring') && (mode === 'edit' || element.object.name !== 'guideplane')) {
        const v = element.point.clone().setComponent(1, 0);
        return v;
      }
    }
    return null;
  }, [camera, canvasSize.height, canvasSize.width, flatPlane, mode, scene.children]);

  const pointsLen = points.length;
  const ccPolygons = useMemo(() => getConnectedComponents(polygons, pointsLen ? [points] : []), [polygons, points, pointsLen]);

  const checkForSnap = useCallback((point, onlyOnPoint = false, onlyOneLine = false, cc = true, onlyOnLine = false) => {
    const threshPoint = 1;
    const thresh = 2.0 * document.ringScale;
    if (points.length > 2) {
      const prevPoint = points.find((p) => {
        if (p.clone().sub(point).length() < thresh) return [p, null, null];
        return null;
      });
      if (prevPoint && prevPoint !== points[points.length - 1]) {
        return [prevPoint, null];
      }
    }

    const indices = polygons.reduce((acc, vs, pi) => {
      if (acc) return acc;
      const vf = vs.find((v) => (v.clone().setComponent(1, 0).sub(point).length() < (types[pi] !== 'Point' ? thresh : threshPoint)));
      if (vf) return [vf, pi];
      return null;
    }, null);

    const labelMode = mode === 'label';

    if (indices && !labelMode) {
      const [vf, pi] = indices;
      const vType = types[pi];
      return [vf, null, vType];
    }
    if (!onlyOnPoint) {
      const snap45 = !labelMode && hasCapability('snaps') && hasCapability('snap45');
      const snap90 = !labelMode && hasCapability('snaps') && hasCapability('snap90');
      const snap180 = labelMode || hasCapability('snap180');
      const [spnts, slines] = getSnapLines(cc ? ccPolygons : polygons, points, point, thresh, onlyOneLine, onlyOnLine, snap45, snap90, snap180);
      if (hasCapability('snaps') || mode === 'edit' || labelMode) return [spnts, slines, null];
      return [spnts, null, null];
    }
    return [null, null, null];
  }, [ccPolygons, hasCapability, mode, points, polygons, types]);

  const checkPolygonForMove = useCallback((poly, polyIndex, fromPoint, toPoint) => {
    if (types[polyIndex] !== 'Polygon') {
      const centerIsMoved = poly[0].clone().setComponent(1, 0).sub(fromPoint).length() < 0.2;
      if (centerIsMoved) {
        const vMove = toPoint.clone().setComponent(1, 0).sub(fromPoint);
        return [poly.map((p) => p.clone().add(vMove)), true];
      }
    }
    let isChanged = false;
    const edited = poly.map((p) => {
      const cond = p.clone().setComponent(1, 0).sub(fromPoint).length() < 0.2;
      if (cond) {
        isChanged = true;
        return toPoint;
      }
      return p;
    });
    if (!isChanged) return [poly, false];
    return [edited, true];
  }, [types]);

  const movePoint = useCallback((fromPoint, toPoint) => {
    const fPoint = fromPoint.clone().setComponent(1, 0);
    let edited;
    if (moveMode === 'group') {
      edited = polygons.map((ps, pi) => {
        const [editedPolygon] = checkPolygonForMove(ps, pi, fPoint, toPoint);
        return editedPolygon;
      });
    } else {
      let isChanged = false;
      edited = polygonsOrders.map((o) => {
        if (isChanged) return polygons[o];
        const [editedPoly, changed] = checkPolygonForMove(polygons[o], o, fPoint, toPoint);
        if (changed) isChanged = true;
        return editedPoly;
      });
    }
    setEditedPolygons(edited);
    return edited;
  }, [checkPolygonForMove, moveMode, polygons, polygonsOrders]);

  const canvasMouseDown = useCallback((e) => {
    if (!visible) return;
    const orbit = e.button !== 0 || !enabled;
    if (orbit) {
      controls.current.setOrbitEnabled(orbit);
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    const v = getCurrentPoint(e);
    if (v) {
      setDownPoint(v.clone());
      setCurrentPoint(v.clone());
    }
    switch (mode) {
      case 'draw':
        if (v) {
          if (drawingState === DrawingState.ReadyForDraw) setPoints([]);
          setHistoryEnabled(false);
          setDrawingState(DrawingState.Drawing);
        }
        break;
      case 'edit':
        if (v) {
          const [snap, , snapType] = checkForSnap(v);
          if (snap) controls.current.setOrbitEnabled(false);
          setSelectedPoint(snap);
          setSelectedType(snapType);
        }
        break;
      default:
        break;
    }
  }, [visible, enabled, getCurrentPoint, mode, controls, drawingState, checkForSnap]);

  const canvasMouseMove = useCallback((e) => {
    if (!enabled || !visible) return;
    const rightangles = hasCapability('snaps');
    const cc = !e.ctrlKey;
    const oneline = e.shiftKey || !rightangles;
    e.preventDefault();
    e.stopPropagation();
    const firstEdit = ((mode === 'edit' || mode === 'label') && !downPoint);
    const v = getCurrentPoint(e, selectedPoint, firstEdit);
    if (v) setCurrentPoint(v.clone());
    if (v && selectedType !== 'Point') {
      const [snap, snapLns] = checkForSnap(v, false, oneline, cc, firstEdit || !rightangles);
      setSnapLines(snapLns);
      if (mode !== 'label' || snapLns) setSnapPoint(snap);
      if (!selectedPoint && snap) updatePlane(snap.y);
    } else {
      setSnapPoint(null);
      setSnapLines(null);
    }
    switch (mode) {
      case 'draw':
        break;
      case 'edit':
        if (selectedPoint && v && downPoint) {
          controls.current.setOrbitEnabled(false);
          movePoint(selectedPoint, v);
        } else {
          controls.current.setOrbitEnabled(true);
        }
        break;
      default:
        break;
    }
  }, [enabled, visible, hasCapability, mode, getCurrentPoint, selectedPoint, selectedType, checkForSnap, updatePlane, controls, movePoint]);

  const canvasMouseUp = useCallback((e) => {
    if (!visible) return;
    controls.current.setOrbitEnabled(true);
    const rightClick = e.button === 2;
    if ((e.button !== 0 && !rightClick) || !enabled) return;
    const closeShape = rightClick && mode === 'draw' && tool === 'Line';
    if (mode !== 'edit' && rightClick && !closeShape) return;
  
    const alt = !hasCapability('magnetize');
    e.preventDefault();
    e.stopPropagation();
    let v;
    switch (mode) {
      case 'draw':
        v = getCurrentPoint(e);
        if (v && (downPoint || closeShape) && (viewMode === '2D' || v.round().equals(downPoint.round()))) {
          const nosnap = !snapPoint || alt;
          const closedPoly = tool !== 'Point' && tool !== 'Circle' && points.length > 1 && snapPoint && snapPoint.equals(points[0]);
          const addedPoint = nosnap && !closedPoly ? currentPoint : snapPoint;
          if (!closeShape) points.push(addedPoint.clone());
          const createPoint = tool === 'Point' && points.length === 1;
          const createCircle = tool === 'Circle' && points.length === 2;
          const createPolygon = points.length > 2 && addedPoint.equals(points[0]);
          const createLine = points.length > 1 && (points.length == maxPoints || addedPoint.equals(points[0]) || closeShape);
          if (createPoint) {
            const pointR = getSceneUnitPerFoot(4 * 0.0833333333);
            points.push(addedPoint.clone().add(new THREE.Vector3(pointR, 0, 0)));
          }
          if (createLine && points.length == 2) points.push(points[0].clone());
          if (createPoint || createCircle || createPolygon || createLine) {
            addShape(points, tool, objectType);
            setDrawingState(DrawingState.ReadyForDraw);
            setPoints([]);
            setHistoryEnabled(true);
          } else {
            setPoints([...points]);
          }
        }
        break;
      case 'edit':
        v = getCurrentPoint(e, selectedPoint);
        if (snapPoint && snapLines && snapLines.length >= 1) {
          let added = false;
          snapLines.forEach((sl) => {
            const snapType = sl[4];
            if (snapType === 0 && rightClick) {
              addPointToShape(sl[8], sl[9], snapPoint, objectType);
              added = true;
            }
          });
          if (added) break;
        }
        if (v && downPoint && !rightClick && editedPolygons) {
          if (!snapPoint || alt) {
            editShapes(editedPolygons, objectType);
          } else if (selectedPoint) {
            const edited = snapPoint.x === selectedPoint.x && snapPoint.z === selectedPoint.z ? editedPolygons : movePoint(selectedPoint, snapPoint);
            editShapes(edited, objectType);
          }
        }
        break;
      case 'delete':
        if (snapPoint) {
          let pindex = null;
          let vindex = null;
          const snp = snapPoint.clone().setComponent(1, 0);
          polygonsOrders.find((pi) => {
            const poly = polygons[pi];
            const vert = poly.find((pv) => pv.clone().setComponent(1, 0).equals(snp));
            if (vert) {
              pindex = pi;
              vindex = poly.indexOf(vert);
              return true;
            }
            return false;
          });
          if (pindex !== null && vindex !== null) deletePointFromShape(pindex, vindex, objectType);
        }
        break;
      case 'label':
        v = getCurrentPoint(e, selectedPoint);
        if (snapPoint && snapLines && snapLines.length >= 1) {
          snapLines.forEach((sl, sli) => {
            const snapType = sl[4];
            if (snapType === 0) {
              setLineLabel(sl[8], sl[9], selectedLabel, false, sli === 0);
            }
          });
        }
        break;
      default:
        break;
    }
    setEditedPolygons(null);
    setDownPoint(null);
    setSelectedPoint(null);
    setSelectedType(null);
    // setSnapPoint(null);
    setSnapLines(null);
    // setTimeout(() => { canvasMouseMove(e); }, 500);
  }, [visible, controls, enabled, mode, viewMode, objectType, hasCapability, getCurrentPoint, selectedPoint, snapPoint, snapLines, editedPolygons, currentPoint, points, tool, addShape, addPointToShape, editShapes, movePoint, polygonsOrders, deletePointFromShape, polygons, setLineLabel, selectedLabel, canvasMouseMove]);

  const clickPolygon = useCallback((e) => {
    if (!enabled) return;
    const { index } = e.detail;
    if (mode === 'delete' && !snapPoint) {
      deleteShape(index - 1, objectType);
      return;
    }
    const pos = polygonsOrders.indexOf(index - 1);
    if (pos > -1) {
      const newOrders = [...polygonsOrders];
      newOrders.splice(pos, 1);
      newOrders.unshift(index - 1);
      setPolygonsOrders(newOrders);
    }
  }, [deleteShape, enabled, mode, polygonsOrders, snapPoint, objectType]);

  useEffect(() => {
    const ref = canvas.current;
    if (ref) ref.addEventListener('mousedown', canvasMouseDown, false);
    return () => { if (ref) ref.removeEventListener('mousedown', canvasMouseDown, false); };
  }, [canvas, canvasMouseDown]);

  useEffect(() => {
    const ref = canvas.current;
    if (ref) ref.addEventListener('mousemove', canvasMouseMove, false);
    return () => { if (ref) ref.removeEventListener('mousemove', canvasMouseMove, false); };
  }, [canvas, canvasMouseMove]);

  useEffect(() => {
    const ref = canvas.current;
    if (ref) ref.addEventListener('mouseup', canvasMouseUp, false);
    return () => { if (ref) ref.removeEventListener('mouseup', canvasMouseUp, false); };
  }, [canvas, canvasMouseUp]);

  useEffect(() => {
    const canvasKeyDown = (e) => {
      if (!enabled || !visible) return;
      e.stopPropagation();
      const esc = e.key === 'Escape';
      const ctrlz = e.ctrlKey && e.key === 'z';
      switch (mode) {
        case 'draw':
          if (esc) {
            setPoints([]);
            setHistoryEnabled(true);
          } else if (ctrlz) {
            points.pop();
            setPoints([...points]);
            if (points.length === 0) setHistoryEnabled(true);
          }
          break;
        case 'edit':
          if (esc) {
            setEditedPolygons(null);
            setDownPoint(null);
            setSelectedPoint(null);
            setSnapPoint(null);
            setSnapLines(null);
          }
          break;
        default:
          break;
      }
    };
    document.addEventListener('keydown', canvasKeyDown, false);
    return () => { document.removeEventListener('keydown', canvasKeyDown, false); };
  }, [enabled, mode, visible, points]);

  useEffect(() => {
    document.addEventListener('ClickPolygon', clickPolygon, false);
    return () => { document.removeEventListener('ClickPolygon', clickPolygon, false); };
  }, [clickPolygon]);

  if (!visible) return null;
  const renderPolys = (editedPolygons || polygons);
  const visibility = mode === 'select' ? 'indices' : (enabled ? 'all' : 'boarders');

  return (
    <>
      <group name="drawing">
        {renderPolys.map((s, si) => (
          <>
            {types[si] !== 'Polygon' && types[si] !== 'Line' && (<Circle startPoint={s[0]} endPoint={s[1]} index={si + 1} />)}
            {((types[si] === 'Polygon' || types[si] === 'Line' || enabled) && (
              <Polygon
                key={si}
                index={si + 1}
                points={s}
                edgeCount={edgeCounts[si]}
                lastConnect={types[si] !== 'Line'}
                colors={enabled ? labels[si]?.map((l) => (l ? lineColors[l] : lineColor)) : []}
                widths={enabled ? labels[si]?.map((l) => (l ? 0.006 : 0.004)) : []}
                cameraPosition={camera.position}
                enabled={enabled}
                visibility={visibility}
                type={types[si]}
                measurements={forceMeasurements || showMeasurements}
                fill={types[si] !== 'Line'}
                text={texts && texts[si]}
                backgroundColor={backgroundColor}
                backgroundStyle={backgroundStyle}
                dots={dots}
                ringScale={ringScale}
                lineColor={lineColor}
                fontScale={fontScale}
                showLines={forceMeasurements || visibility == 'all' || !enabled}
                showRings={visibility == 'all' && enabled}
                hasSegments={hasSegments}
            />
            ))}
          </>
        ))}
      </group>
      <group name="drawing.edit">
        {enabled && snapLines && snapLines.map((sl) => {
          const firstEditOnLine = mode === 'edit' && !downPoint && sl[4] === 0;
          return (
            <Line
              start={sl[1]}
              end={sl[0]}
              width={!firstEditOnLine ? 0.004 : 0.006}
              color={mode !== 'label' ? dotColors[sl[4]] : lineColors[selectedLabel]}
              dots={!firstEditOnLine && mode !== 'label'}
            />
          );
        })}
      </group>
      {enabled && (
        <group name="drawing.line">
          {(points.length > 0 && currentPoint && drawingState === DrawingState.Drawing) && (
            <>
              <Line start={currentPoint} end={points[points.length - 1]} width={0.004} color="#39D86F" hasSegments={hasSegments} />
              <Ring point={currentPoint} color="green" r1={0} r2={0.75} cameraPosition={camera.position} opacity={1.0} />
            </>
          )}
          <Polygon points={points} lastConnect={false} index={0} boldStart fill={tool !== 'Line'} />
          {mode !== 'label' && snapPoint && (!selectedPoint || !(snapPoint.x === selectedPoint.x && snapPoint.z === selectedPoint.z)) && (<Ring point={snapPoint} color="red" r1={0.5} r2={1.5} opacity={1.0} />)}
          {mode === 'edit' && currentPoint && downPoint && selectedPoint && (<Ring point={currentPoint} color="green" r1={0} r2={1.5} opacity={1.0} />)}
          {tool !== 'Polygon' && tool !== 'Line' && points.length > 0 && <Cylandar startPoint={points[points.length - 1]} endPoint={currentPoint} /> }
        </group>
      )}
    </>
  );
};
Drawing.propTypes = {
  controls: PropTypes.instanceOf(HTMLInputElement).isRequired,
  canvas: PropTypes.instanceOf(Canvas).isRequired,
  shapes: PropTypes.arrayOf(THREE.Vector3),
  shapeTypes: PropTypes.arrayOf(PropTypes.string),
  labels: PropTypes.arrayOf(PropTypes.string),
  enabled: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  tool: PropTypes.oneOf(['Point', 'Circle', 'Polygon', 'Line']),
  objectType: PropTypes.string,
  texts: PropTypes.arrayOf(PropTypes.string),
  backgroundColor: PropTypes.string,
  backgroundStyle: PropTypes.string,
  dots: PropTypes.bool,
  ringScale: PropTypes.number,
  lineColor: PropTypes.string,
  forceMeasurements: PropTypes.bool,
  fontScale: PropTypes.number,
  maxPoints: PropTypes.number,
  hasSegments: PropTypes.bool,
};
Drawing.defaultProps = {
  shapes: [],
  shapeTypes: null,
  labels: [],
  tool: 'Polygon',
  objectType: null,
  texts: null,
  backgroundColor: '',
  backgroundStyle: '',
  dots: false,
  ringScale: 1.0,
  lineColor: 'white',
  forceMeasurements: false,
  fontScale: 1.0,
  maxPoints: 1e3,
  hasSegments: false,
};

export default Drawing;
