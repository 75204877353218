import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Card, Button } from 'react-bootstrap';
import { ArrowDownShort, ArrowLeftShort } from 'react-bootstrap-icons';
// import "./index.css";

import Help from 'components/Help';
import RoofSegments from './RoofSegments';
import Obstacles from './Obstacles';
import Trees from './Trees';
import Shading from './Shading';
import Setback from './Setback';
import Design from './Design';
import Revise from './Revise';
import Annotation from './Annotation';
import {
  segmentPropType, obstaclePropType, treePropType, panelsPropType,
} from '../props';

function Menu({
  activeKey,
  designType,
  shaded,
  buffer,
  rowSpacing,
  panelTilt,
  fireSetbacks,
  setbackMode,
  serverSideShadingIsRunning,
  setbackModeHandler,
  fireSetbackHandler,
  colorRange,
  currentAccordionHandler,
  currentTabHandler,
  tool,
  toolHandler,
  selectedLabel,
  selectedPanels,
  selectedRoof,
  selectedLabelHandler,
  clearLabelsHandler,
  deleteShapeHandler,
  addPointToPolygonHandler,
  removePointFromPolygonHandler,
  newPanelHandler,
  deletePanelHandler,
  rotatePanelHandler,
  setPVwattsLosses,
  segments,
  resolution,
  segmentAzimuthHandler,
  segmentPitchAndAreaHandler,
  segmentEaveHeightHandler,
  treeUpdateHandler,
  obstacles,
  obstacleHeightHandler,
  obstacleRadiusHandler,
  obstacleSetbackHandler,
  trees,
  panels,
  placementHandler,
  shadingHandler,
  measureHandler,
  runOptimizationHandler,
  runEdgeDetectionHandler,
  proposalHandler,
  panelAzimuthHandler,
  currentPanel,
  currentInverter,
  changePanelHardware,
  changeInverterHardware,
  bufferHandler,
  rowSpacingHandler,
  panelTiltHandler,
  showOverlay,
  toggleOverlay,
  shadingMonth,
  shadingStatus,
  designFrame,
  runAIHandler,
  hasDSM,
  solarPanels,
  inverters,
}) {
  const roofsHelp = 'Draw a polygon for each roof segment, and add an eave label to determine azimuth.';
  const obstaclesHelp = 'Select an obstacle shape and draw all roof obstacles.';
  const treesHelp = 'Draw a tree by drawing a circle outward from its center, and selecting a tree type.';
  const shadingHelp = 'Run annual shading analysis for annotated roof segments, and review roof insolation.';
  const setbackHelp = 'Configure fire setbacks.';
  const designHelp = 'Perform panel fit over roof segments based on selected solar panels and fire setback.';
  const reviseHelp = 'Add/remove and adjust alignment of solar panels for roof segments.';

  return (
    <div className='menu-layout'>
      <Accordion activeKey={activeKey} onSelect={currentAccordionHandler}>
        <Card className="shadow border rounded">
          <Accordion.Toggle
            className="bg-white text-bold h5 border-0 rounded"
            as={Card.Header}
            eventKey="segments"
          >
            Roof Segments
            { activeKey === 'segments' ? <ArrowLeftShort style={{ float: 'right' }} /> : <ArrowDownShort style={{ float: 'right' }} /> }
            <Help text={roofsHelp} />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="segments">
            <Card.Body className="pt-0">
              <RoofSegments
                designType={designType}
                hasDSM={hasDSM}
                segments={segments}
                resolution={resolution}
                segmentAzimuthHandler={segmentAzimuthHandler}
                segmentPitchAndAreaHandler={segmentPitchAndAreaHandler}
                segmentEaveHeightHandler={segmentEaveHeightHandler}
                currentTabHandler={currentTabHandler}
                selectedLabel={selectedLabel}
                selectedLabelHandler={selectedLabelHandler}
                clearLabelsHandler={clearLabelsHandler}
                deleteShapeHandler={deleteShapeHandler}
                addPointToPolygonHandler={addPointToPolygonHandler}
                removePointFromPolygonHandler={removePointFromPolygonHandler}
                measureHandler={measureHandler}
                runOptimizationHandler={runOptimizationHandler}
                runEdgeDetectionHandler={runEdgeDetectionHandler}
                runAIHandler={runAIHandler}
              />
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card className="shadow border rounded">
          <Accordion.Toggle
            className="bg-white text-bold h5 border-0 rounded"
            as={Card.Header}
            eventKey="obstacles"
          >
            Obstacles
            { activeKey === 'obstacles' ? <ArrowLeftShort style={{ float: 'right' }} /> : <ArrowDownShort style={{ float: 'right' }} /> }
            <Help text={obstaclesHelp} />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="obstacles">
            <Card.Body className="pt-0">
              <Obstacles
                obstacles={obstacles}
                tool={tool}
                toolHandler={toolHandler}
                obstacleRadiusHandler={obstacleRadiusHandler}
                obstacleHeightHandler={obstacleHeightHandler}
                obstacleSetbackHandler={obstacleSetbackHandler}
                deleteShapeHandler={deleteShapeHandler}
              />
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card className="shadow border rounded">
          <Accordion.Toggle
            className="bg-white text-bold h5 border-0 rounded"
            as={Card.Header}
            eventKey="trees"
          >
            Trees
            { activeKey === 'trees' ? <ArrowLeftShort style={{ float: 'right' }} /> : <ArrowDownShort style={{ float: 'right' }} /> }
            <Help text={treesHelp} />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="trees">
            <Card.Body className="pt-0">
              <Trees
                trees={trees}
                treeUpdateHandler={treeUpdateHandler}
                deleteShapeHandler={deleteShapeHandler}
              />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="shadow border rounded ">
          <Accordion.Toggle
            className="bg-white text-bold h5 border-0 rounded"
            as={Card.Header}
            eventKey="shading"
          >
            Shading Analysis
            { activeKey === 'shading' ? <ArrowLeftShort style={{ float: 'right' }} /> : <ArrowDownShort style={{ float: 'right' }} /> }
            <Help text={shadingHelp} />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="shading">
            <Card.Body className="pt-0">
              <Shading
                shaded={shaded}
                colorRange={colorRange}
                shadingHandler={shadingHandler}
                showOverlay={showOverlay}
                toggleOverlay={toggleOverlay}
                shadingMonth={shadingMonth}
                shadingStatus={shadingStatus}
                serverSideShadingIsRunning={serverSideShadingIsRunning}
                setPVwattsLosses={setPVwattsLosses}
              />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="shadow border rounded ">
          <Accordion.Toggle
            className="bg-white text-bold h5 border-0 rounded"
            as={Card.Header}
            eventKey="setback"
          >
            Fire Setbacks
            { activeKey === 'setback' ? <ArrowLeftShort style={{ float: 'right' }} /> : <ArrowDownShort style={{ float: 'right' }} /> }
            <Help text={setbackHelp} />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="setback">
            <Card.Body className="pt-0">
              <Setback
                // segments={segments}
                fireSetbacks={fireSetbacks}
                setbackMode={setbackMode}
                setbackModeHandler={setbackModeHandler}
                fireSetbackHandler={fireSetbackHandler}
              />
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card className="shadow border rounded ">
          <Accordion.Toggle
            className="bg-white text-bold h5 border-0 rounded"
            as={Card.Header}
            eventKey="design"
          >
            Design
            { activeKey === 'design' ? <ArrowLeftShort style={{ float: 'right' }} /> : <ArrowDownShort style={{ float: 'right' }} /> }
            <Help text={designHelp} />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="design">
            <Card.Body className="pt-0">
              <Design
                currentPanel={currentPanel}
                currentInverter={currentInverter}
                buffer={buffer}
                rowSpacing={rowSpacing}
                panelTilt={panelTilt}
                changePanelHardware={changePanelHardware}
                changeInverterHardware={changeInverterHardware}
                placementHandler={placementHandler}
                bufferHandler={bufferHandler}
                rowSpacingHandler={rowSpacingHandler}
                panelTiltHandler={panelTiltHandler}
                solarPanels={solarPanels}
                inverters={inverters}
              />
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card className="shadow border rounded ">
          <Accordion.Toggle
            className="bg-white text-bold h5 border-0 rounded"
            as={Card.Header}
            eventKey="revise"
          >
            Edit Panel Placement
            { activeKey === 'revise' ? <ArrowLeftShort style={{ float: 'right' }} /> : <ArrowDownShort style={{ float: 'right' }} /> }
            <Help text={reviseHelp} />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="revise">
            <Card.Body className="pt-0">
              <Revise
                selectedPanels={selectedPanels}
                selectedRoof={selectedRoof}
                newPanelHandler={newPanelHandler}
                deletePanelHandler={deletePanelHandler}
                rotatePanelHandler={rotatePanelHandler}
                proposalHandler={proposalHandler}
                panels={panels}
                segments={segments}
                panelAzimuthHandler={panelAzimuthHandler}
              />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="shadow border rounded ">
          <Accordion.Toggle
            className="bg-white text-bold h5 border-0 rounded"
            as={Card.Header}
            eventKey="annotations"
          >
            Annotations
            { activeKey === 'annotations' ? <ArrowLeftShort style={{ float: 'right' }} /> : <ArrowDownShort style={{ float: 'right' }} /> }
            <Help text={reviseHelp} />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="annotations">
            <Card.Body className="pt-0">
              <Annotation
              />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      { !designFrame && (
      <Button
        className="create-proposal mt-3"
        onClick={proposalHandler}
        disabled={panels.length === 0}
        style={{ float: 'right' }}
      >
        Create Proposal
      </Button>
      )}
    </div>
  );
}

Menu.defaultProps = {
  setbackMode: 0,
  currentPanel: '',
};

Menu.propTypes = {
  activeKey: PropTypes.string.isRequired,
  designType: PropTypes.oneOf(['M', 'A']).isRequired,
  hasDSM: PropTypes.bool.isRequired,
  currentAccordionHandler: PropTypes.func.isRequired,
  proposalHandler: PropTypes.func.isRequired,
  // Roof Segments
  segments: segmentPropType.isRequired,
  selectedLabel: PropTypes.string.isRequired,
  currentTabHandler: PropTypes.func.isRequired,
  segmentAzimuthHandler: PropTypes.func.isRequired,
  segmentPitchAndAreaHandler: PropTypes.func.isRequired,
  segmentEaveHeightHandler: PropTypes.func.isRequired,
  deleteShapeHandler: PropTypes.func.isRequired,
  selectedLabelHandler: PropTypes.func.isRequired,
  clearLabelsHandler: PropTypes.func.isRequired,
  addPointToPolygonHandler: PropTypes.func.isRequired,
  removePointFromPolygonHandler: PropTypes.func.isRequired,
  measureHandler: PropTypes.func.isRequired,
  runOptimizationHandler: PropTypes.func.isRequired,
  runAIHandler: PropTypes.func.isRequired,
  // Obstacles
  obstacles: obstaclePropType.isRequired,
  toolHandler: PropTypes.func.isRequired,
  obstacleRadiusHandler: obstaclePropType.isRequired,
  obstacleSetbackHandler: obstaclePropType.isRequired,
  obstacleHeightHandler: obstaclePropType.isRequired,
  // Trees
  trees: treePropType.isRequired,
  treeUpdateHandler: PropTypes.func.isRequired,
  // Shading
  shaded: PropTypes.bool.isRequired,
  colorRange: PropTypes.arrayOf(PropTypes.number).isRequired,
  shadingHandler: PropTypes.func.isRequired,
  showOverlay: PropTypes.bool.isRequired,
  toggleOverlay: PropTypes.func.isRequired,
  shadingMonth: PropTypes.number.isRequired,
  shadingStatus: PropTypes.number.isRequired,
  // Fire Setbacks
  fireSetbacks: PropTypes.shape({
    general: PropTypes.number,
    eave: PropTypes.number,
    ridge: PropTypes.number,
    rake: PropTypes.number,
    hip: PropTypes.number,
    valley: PropTypes.number,
  }).isRequired,
  setbackMode: PropTypes.number,
  setbackModeHandler: PropTypes.func.isRequired,
  fireSetbackHandler: PropTypes.func.isRequired,
  // Design
  currentPanel: PropTypes.string,
  buffer: PropTypes.number.isRequired,
  rowSpacing: PropTypes.number.isRequired,
  panelTilt: PropTypes.number.isRequired,
  changePanelHardware: PropTypes.func.isRequired,
  placementHandler: PropTypes.func.isRequired,
  bufferHandler: PropTypes.func.isRequired,
  rowSpacingHandler: PropTypes.func.isRequired,
  panelTiltHandler: PropTypes.func.isRequired,
  // Revise
  selectedRoof: PropTypes.number.isRequired,
  selectedPanels: PropTypes.bool.isRequired,
  panels: panelsPropType.isRequired,
  panelAzimuthHandler: PropTypes.func.isRequired,
  newPanelHandler: PropTypes.func.isRequired,
  rotatePanelHandler: PropTypes.func.isRequired,
  deletePanelHandler: PropTypes.func.isRequired,
};

export default Menu;
