import React, {
  useState, useMemo, useRef, useEffect, useLayoutEffect, useContext,
} from 'react';
import * as THREE from 'three';

import { DesignContext } from '../contexts/designContext.js';

const Tree = ({
  index = -1,
  position,
  height,
  radius,
  treeType,
  addSelectedThree = null,
  removeSelectedThree = null,
  selectable = false,
  isSelected = false,
  visible=true,
}) => {
  const {
    showSunLight,
  } = useContext(DesignContext);
  const [selected, setSelected] = useState(false);

  const [matTrunk, matBranch] = useMemo(() => {
    const mTrunk = (
      <meshStandardMaterial
        attach="material"
        color="#732413"
        wireframe={false}
        side={THREE.FrontSide}
      />
    );

    let mBranch = null;
    if (showSunLight) {
      mBranch = selected && selectable ? (
        <meshStandardMaterial
          attach="material"
          color="#46de26"
          wireframe={false}
          side={THREE.FrontSide}
        />
      ) : (
        <meshStandardMaterial
          attach="material"
          color="#267615"
          wireframe={false}
          side={THREE.FrontSide}
        />
      );
    } else {
      mBranch = selected && selectable ? (
        <meshStandardMaterial
          attach="material"
          color="#46de26"
          wireframe={false}
          side={THREE.FrontSide}
        />
      ) : (
        <meshStandardMaterial
          attach="material"
          color="#267615"
          wireframe={false}
          side={THREE.FrontSide}
        />
      );
    }

    return [mTrunk, mBranch];
  }, [selected, selectable, showSunLight]);

  useEffect(() => {
    if (selectable && isSelected !== selected) setSelected(isSelected);
  }, [isSelected, index, selectable, selected]);

  const changeSelection = (e, selection) => {
    if (selectable) {
      e.stopPropagation();
      setSelected(selection);
      const [pIndex, vIndex] = index;
      if (selection) addSelectedThree(pIndex, vIndex);
      else removeSelectedThree(pIndex, vIndex);
    }
  };

  const truncH = Math.min(10, height / 2);
  const [x, y, z] = position;
  const spherePosition = [x, truncH + (height - truncH) / 2, y];
  const spherescaleY = (height - truncH) / (radius * 2);

  return (
    <group
      name="tree"
      castShadow
      receiveShadow
      visible={height > 0}
    >
      <mesh
        name="treeTrunk"
        visible={visible}
        castShadow
        receiveShadow
        position={[x, z + (truncH / 2), y]}
        onClick={(e) => changeSelection(e, !selected)}
      >
        <cylinderBufferGeometry attach="geometry" args={[radius / 5, radius / 3, truncH, 5]} />
        {matTrunk}
      </mesh>
      {treeType === 'Cone'
        ? (
          <mesh
            name="treeBranch"
            visible={visible}
            castShadow
            receiveShadow
            position={spherePosition}
            scale={[1, spherescaleY, 1]}
            onClick={(e) => changeSelection(e, !selected)}
          >
            <cylinderBufferGeometry attach="geometry" args={[0, radius, radius * 2, 32]} />
            {matBranch}
          </mesh>
        )
        : (
          <mesh
            name="treeBranch"
            visible={visible}
            castShadow
            receiveShadow
            position={spherePosition}
            scale={[1, spherescaleY, 1]}
            onClick={(e) => changeSelection(e, !selected)}
          >
            <sphereGeometry attach="geometry" args={[radius, 32, 32]} />
            {matBranch}
          </mesh>
        )}
    </group>
  );
};

export default Tree;
