/* eslint-disable no-plusplus */
import * as THREE from 'three';
// import { BloomEffect, EffectComposer, EffectPass, RenderPass, ShaderPass, PixelationEffect, DotScreenEffect, SavePass, SMAAEffect, SSAOEffect, NormalPass } from "postprocessing";
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { downloadCanvas, gray2gradient } from './geometry.js';

import { tic, toc } from './utils.js';




export default class Shader {
  resolution = 1.0;

  scale = 5.0;

  minDSM = 0.0;

  maxDSM = 0.0;

  displacementBias = 0.0;

  dsmTexture = null;

  ranges = [];

  weather = null;

  center = null;

  sun = null;

  ambient = null;

  ios = false;

  renderer = null;

  canvasPool = null;

  debugMode = false;

  constructor(resolution, scale, minDSM, maxDSM, displacementBias, dsmTexture, ranges, weather, center) {
    this.resolution = resolution;
    this.scale = scale;
    this.minDSM = minDSM;
    this.maxDSM = maxDSM;
    this.displacementBias = displacementBias;
    this.dsmTexture = dsmTexture;
    this.ranges = ranges;
    this.weather = weather;
    this.center = center;
  }

  placeImage = (
    sourceArray,
    targetArray,
    sourceWidth,
    sourceHeight,
    targetWidth,
    targetHeight,
    startX,
    startY,
  ) => {
    // Ensure that the source image fits within the target image starting from (startX, startY)
    if (
      startX + sourceWidth > targetWidth
      || startY + sourceHeight > targetHeight
    ) {
      throw new Error('The source image will not fit at the specified start coordinates.');
    }
  
    // Loop through every pixel in the source image
    for (let y = 0; y < sourceHeight; y++) {
      for (let x = 0; x < sourceWidth; x++) {
        // Calculate the index for the source and target arrays
        const sourceIndex = (y * sourceWidth + x) * 4; // each pixel has 4 components (R, G, B, A)
        const targetIndex = ((startY + y) * targetWidth + (startX + x)) * 4;
  
        // Copy the RGBA values from the source to the target array
        targetArray[targetIndex] = sourceArray[sourceIndex]; // R
        targetArray[targetIndex + 1] = sourceArray[sourceIndex + 1]; // G
        targetArray[targetIndex + 2] = sourceArray[sourceIndex + 2] // B
        targetArray[targetIndex + 3] = 255; // A
      }
    }
  }

  setRenderer(renderer) {
    this.renderer = renderer;
  }

  setCanvasPool(canvasPool) {
    this.canvasPool = canvasPool;
  }

  newRenderer = (width, height) => {
    const renderTarget = new THREE.WebGLRenderTarget(width, height, { type: THREE.UnsignedByteType, encoding: THREE.LinearEncoding, minFilter: THREE.LinearFilter, magFilter: THREE.LinearFilter, format: THREE.RGBAFormat });
    return renderTarget;
  }

  changeMaterial = (parent, newParent, iterCount, changeScene) => {
    let isroof = false;
    if (!['treeTrunk', 'treeBranch'].includes(newParent.name)) {
      const obstacle = newParent.name === 'roofPlane' && parent.parent.name === '';
      const ground = newParent.name === 'groundPlane';

      if (obstacle) {
        newParent.material = new THREE.MeshBasicMaterial({ color: 0x000000, side: THREE.DoubleSide });
      } else if (ground) {
        const dispScale = (100 / (this.resolution / this.scale)) * (this.maxDSM - this.minDSM);
        const dispBias = -(this.displacementBias / 255) * dispScale;

        newParent.material = new THREE.MeshStandardMaterial({
          color: 0x000000,
          transparent: true,
          opacity: 1 / iterCount,
          side: THREE.DoubleSide,
          displacementMap: this.dsmTexture,
          displacementScale: dispScale,
          displacementBias: dispBias,
        });
        newParent.customDepthMaterial = new THREE.MeshDepthMaterial({
          depthPacking: THREE.RGBADepthPacking,
          displacementMap: this.dsmTexture,
          displacementScale: dispScale,
          displacementBias: dispBias,
        });
      } else {
        isroof = true;
        newParent.material = new THREE.MeshLambertMaterial({
          color: newParent.name === 'roofPlane' ? 0xffffff : 0x000000,
          transparent: true,
          opacity: 1 / iterCount,
          side: THREE.DoubleSide,
        });
      }

      if (changeScene) {
        parent.material = newParent.material.clone();
      }

      newParent.material.blending = THREE.AdditiveBlending;
    } else {
      newParent.material = new THREE.MeshBasicMaterial({ color: 0x000000 });
      newParent.material.colorWrite = false;
      newParent.material.depthWrite = false;
      newParent.material.side = THREE.FrontSide;
    }

    return isroof;
  }

  cloneScene = (shadingType, iterCount, parent, changeScene = false, isRoot = false) => {
    if (!parent) return null;

    const newParent = parent.clone();
    const isTSRF = shadingType === 'TSRF';
    const isTOF = shadingType === 'TOF';
    const isSolarAccess = shadingType === 'SolarAccess';

    if (isRoot) newParent.background = null;

    let isRoof = false;
    if (newParent.material) {
      isRoof = this.changeMaterial(parent, newParent, iterCount, changeScene);
    }

    if (!newParent.name.includes('Light')) {
      newParent.castShadow = !isTOF;
      newParent.receiveShadow = !isTOF && isRoof;
      if (changeScene) {
        parent.castShadow = !isTOF;
        parent.receiveShadow = !isTOF && isRoof;
      }
    }

    const [minx, miny, maxx, maxy, maxz] = this.ranges;
    const [rwidth, rheight] = [maxx - minx, maxy - miny];

    for (let i = newParent.children.length - 1; i >= 0; i -= 1) {
      const obj = newParent.children[i];
      if (!['panel', 'setback', 'points', 'panel_points', 'grid', 'floor', 'wallBorder', 'sky', 'sunSphere', 'ambientLight'].includes(obj.name)) {
        const newObj = this.cloneScene(shadingType, iterCount, !changeScene ? obj : parent.children[i], changeScene);
        if (newObj) {
          if (obj.name === 'sunLight') {
            const r = Math.max(1, (maxz - 100) / 50);
            this.sun = newObj;
            this.sun.color = new THREE.Color(1, 1, 1);
            this.sun.intensity = 1;
            this.sun.shadow.bias = -0.001;
            this.sun.shadow.radius = 0.0;
            this.sun.shadow.mapSize.height = 1024;
            this.sun.shadow.mapSize.width = 1024;
            this.sun.shadow.camera.far = 500;
            this.sun.shadow.camera.left = -rwidth / 6;
            this.sun.shadow.camera.right = rwidth / 6;
            this.sun.shadow.camera.top = (rheight / 9) * r;
            this.sun.shadow.camera.bottom = (-rheight / 9) * r;
            this.sun.shadow.camera.zoom = 1;
          } else if (obj.name === 'ambientLight') {
            this.ambient = newObj;
            this.ambient.color = new THREE.Color(1, 1, 1);
            this.ambient.intensity = 0;
          }

          newParent.add(newObj);
        }
      }
      newParent.remove(obj);
    }

    return newParent;
  }

  changeSceneMaterials = (scene, shadingType) => {
    this.cloneScene(shadingType, 1, scene, true, true);
  }

  moveSunManualy = (pos) => {
    this.sun.position.x = pos.x + this.sun.target.position.x;
    this.sun.position.y = pos.y + this.sun.target.position.y;
    this.sun.position.z = pos.z + this.sun.target.position.z;
  }

  moveSun = (time, minutes) => {
    const pos = this.weather.getSunPosition(time);
    this.moveSunManualy(pos);
    const newTime = new Date(time);
    newTime.setTime(newTime.getTime() + (minutes * 60 * 1000));
    return [newTime, pos];
  }

  getCanvasAndContext = (type, month) => {
    const ty = type.toLowerCase();
    const newCanvas = ty.startsWith('diffuse_') ? this.canvasPool.getCanvas(ty) : this.canvasPool.getCanvas(`${ty}_${month}`);
    const context = ty.startsWith('diffuse_') ? this.canvasPool.getContext(ty) : this.canvasPool.getContext(`${ty}_${month}`);
    return [newCanvas, context];
  }

  renderTargetToData = (renderer) => {
    const renderTarget = renderer.getRenderTarget();

    const pixelBuffer = new Uint8Array(renderTarget.width * renderTarget.height * 4);
    renderer.readRenderTargetPixels(renderTarget, 0, 0, renderTarget.width, renderTarget.height, pixelBuffer);

    const data = new Uint8Array(renderTarget.width * renderTarget.height * 4);
    for (let y = 0; y < renderTarget.height; y += 1) {
      for (let x = 0; x < renderTarget.width; x += 1) {
        let index = (y * renderTarget.width + x) * 4;
        const v = pixelBuffer[index];
        if (v < 0 || v > 255) console.log(`==== V: ${v}`);
        index = ((renderTarget.height - y - 1) * renderTarget.width + x) * 4;
        data[index] = v;
        data[++index] = v;
        data[++index] = v;
        data[++index] = 255;
      }
    }


  
    // if (this.renderer.shadowMap.enabled) {
    //   saveTextureAsImage(data, renderTarget.width, renderTarget.height)
    // }
    return data;
  }

  renderTargetSize = (renderer) => {
    const renderTarget = renderer.getRenderTarget();
    return [renderTarget.width, renderTarget.height];
  }

  renderTargetToCanvas = (renderer, _) => {
    const renderTarget = renderer.getRenderTarget();
    const canvas = document.createElement('canvas');
    canvas.width = renderTarget.width;
    canvas.height = renderTarget.height;
    let ctx = canvas.getContext('2d');
    // Read pixel data from the render target
    const pixelBuffer = new Uint8Array(renderTarget.width * renderTarget.height * 4);
    renderer.readRenderTargetPixels(renderTarget, 0, 0, renderTarget.width, renderTarget.height, pixelBuffer);

    // Create an ImageData object from the pixel buffer
    const imageData = ctx.getImageData(0, 0, renderTarget.width, renderTarget.height);
    const { data } = imageData;
    for (let y = 0; y < renderTarget.height; y += 1) {
      for (let x = 0; x < renderTarget.width; x += 1) {
        let index = (y * renderTarget.width + x) * 4;
        const v = pixelBuffer[index];
        index = ((renderTarget.height - y - 1) * renderTarget.width + x) * 4;
        data[index] = v;
        data[++index] = v;
        data[++index] = v;
        data[++index] = 255;
      }
    }
    // Draw the ImageData onto the canvas
    ctx.putImageData(imageData, 0, 0);
    return canvas;
  }

  dataToTexture = (data, width, height) => {
    const shadingTexture = new THREE.DataTexture(data, width, height, THREE.RGBAFormat, THREE.UnsignedByteType);
    shadingTexture.flipY = true;
    // shadingTexture.flipX = true;
    shadingTexture.encoding = THREE.LinearEncoding;
    shadingTexture.needsUpdate = true;
    return shadingTexture;
  }

  renderTargetToCompleteData = (renderer) => {
    const data = this.renderTargetToData(renderer);
    const fullTextureWidth = 1024;
    const fullTextureHeight = 1024;

    // eslint-disable-next-line no-undef
    const fullTextureData = new Uint8Array(fullTextureWidth * fullTextureHeight * 4);

    const [minx, miny, maxx, maxy] = this.ranges;
    const [dataWidth, dataHeight] = [maxx - minx, maxy - miny];

    this.placeImage(data, fullTextureData, dataWidth, dataHeight, fullTextureWidth, fullTextureHeight, minx, miny);

    return fullTextureData;
  }

  getRenderTimes = (month) => {
    const irradianceWeekly = this.weather.getDnWeekly(month);
    const renderCountWeekly = irradianceWeekly.map((irw) => irw.map((ir) => (ir > 0 ? 1 : 0)).reduce((a, b) => a + b));
    return [renderCountWeekly, irradianceWeekly];
  }

  findBy = (objs, fieldName, name) => {
    for (let i = 0; i < objs.length; i += 1) {
      if (objs[i][fieldName] === name) {
        return objs[i];
      }
    }
    return null;
  }

  getCamera = () => {
    const [minx, miny, maxx, maxy] = this.ranges;
    const shadingCamera = new THREE.OrthographicCamera(-((maxx - minx) / 2) * this.scale, ((maxx - minx) / 2) * this.scale, ((maxy - miny) / 2) * this.scale, -((maxy - miny) / 2) * this.scale, 0.1, 1000);
    shadingCamera.position.set((minx + (maxx - minx) / 2 - this.center[0]) * this.scale, 100, (miny + (maxy - miny) / 2 - this.center[1]) * this.scale);
    shadingCamera.lookAt(shadingCamera.position.x, 0, shadingCamera.position.z);
    return shadingCamera;
  }

  exportAttributes = (scene, json, meshName, attrName) => {
    const attrs = {};
    scene.traverse((child) => {
      if (child instanceof THREE.Mesh && child.name === meshName && attrName in child.geometry.attributes) {
        attrs[child.geometry.uuid] = child.geometry.attributes[attrName];
      }
    });
    for (let i = 0; i < json.geometries.length; i += 1) {
      const { uuid } = json.geometries[i];
      if (uuid in attrs) {
        if (!('data' in json.geometries[i])) json.geometries[i].data = {};
        if (!('attributes' in json.geometries[i].data)) json.geometries[i].data.attributes = {};
        json.geometries[i].data.attributes[attrName] = attrs[uuid];
      }
    }
  }

  exportScene = (scene, leaduid, data) => {
    const { imgSrc, dsmSrc } = data;
    const json = scene.toJSON();
    this.exportAttributes(scene, json, 'roofPlane', 'uv');
    const ground = this.findBy(json.object.children, 'name', 'ground').children[0];
    const dsmMat = this.findBy(json.materials, 'uuid', ground.material);
    const dsmTex = this.findBy(json.textures, 'uuid', dsmMat.displacementMap);
    const dsmImg = dsmTex.image;
    for (let i = 0; i < json.images.length; i += 1) {
      if (json.images[i].uuid === dsmImg) {
        json.images[i].url = dsmSrc;
      } else {
        json.images[i].url = imgSrc;
      }
    }
    const camera = this.getCamera();
    json.camera = camera.toJSON();
    json.lead = leaduid;
    json.initData = data;
    return json;
  }

  exportSunPositionAndIntensity = (renderPerHour = 4) => {
    const positions = [];
    const intensities = [];
    const irradiances = [];
    for (let month = 1; month <= 12; month += 1) {
      const monthPositions = [];
      const monthIntensities = [];
      const monthIrradiances = [];
      const [renderCountWeekly, irradianceWeekly] = this.getRenderTimes(month);
      const iterCount = renderCountWeekly.reduce((a, b) => a + b) * this.weather.renderPerHour;  
      const sumBeemIrradiance = irradianceWeekly.map((rw) => rw.reduce((a, b) => a + b)).reduce((a, b) => a + b);
      // irradiances.push(sumBeemIrradiance);
      let sumIrradiance = 0;
      for (let weekIndex = 0; weekIndex < irradianceWeekly.length; weekIndex += 1) {
        const weekIrradiance = irradianceWeekly[weekIndex];
        for (let hourIndex = 0; hourIndex < weekIrradiance.length; hourIndex += 1) {
          const irradiance = weekIrradiance[hourIndex];
          if (irradiance > 0) {
            const count = irradiance > 0 ? renderPerHour : 1;
            for (let k = 0; k < count; k += 1) {
              const minute = k * (60.0 / renderPerHour);
              const sunPos = this.weather.getSunPositionWeekly(month, hourIndex, minute, weekIndex);
              const sunIntensity = (irradiance / (sumBeemIrradiance * renderPerHour)) * iterCount;
              sumIrradiance += sunIntensity;
              monthPositions.push([sunPos.x, sunPos.y, sunPos.z]);
              monthIntensities.push(sunIntensity);
              monthIrradiances.push(irradiance);
            }
          }
        }
      }
      positions.push(monthPositions);
      intensities.push(monthIntensities);
      irradiances.push(monthIrradiances);
    }
    return [positions, intensities, irradiances];
  }

  downloadContent = (content, filename, contentType) => {
    if (!contentType) contentType = 'application/octet-stream';
    const a = document.createElement('a');
    const blob = new Blob([content], { 'type': contentType });
    a.href = window.URL.createObjectURL(blob);
    a.download = filename;
    a.click();
  }

  shade = (scene, shadingType, month) => {
    if (this.debugMode) tic();
    const [renderCountWeekly, irradianceWeekly] = this.getRenderTimes(month);
    const [minx, miny, maxx, maxy] = this.ranges;

    const iterCount = renderCountWeekly.reduce((a, b) => a + b) * this.weather.renderPerHour;

    const shadingScene = this.cloneScene(shadingType, iterCount, scene, false, true);
    const [width, height] = [maxx - minx, maxy - miny];

    const shadingCamera = new THREE.OrthographicCamera(-((maxx - minx) / 2) * this.scale, ((maxx - minx) / 2) * this.scale, ((maxy - miny) / 2) * this.scale, -((maxy - miny) / 2) * this.scale, 0.1, 1000);
    shadingCamera.position.set((minx + (maxx - minx) / 2 - this.center[0]) * this.scale, 100, (miny + (maxy - miny) / 2 - this.center[1]) * this.scale);
    shadingCamera.lookAt(shadingCamera.position.x, 0, shadingCamera.position.z);

    this.renderer.autoClear = false;

    this.renderer.shadowMap.enabled = shadingType !== 'TOF';
    this.renderer.shadowMap.type = THREE.VSMShadowMap;
    this.renderer.preserveDrawingBuffer = true;
    this.renderer.setPixelRatio(1.0);
    this.renderer.setSize(width, height, true);
    this.renderer.clear(true, true, true);

    const renderTarget = this.newRenderer(width, height);
    this.renderer.setRenderTarget(renderTarget);

    if (this.ambient) this.ambient.intensity = 0;

    const irradianceDnWeekly = this.weather.getDnWeekly(month);
    const sumBeemIrradiance = irradianceDnWeekly.map((rw) => rw.reduce((a, b) => a + b)).reduce((a, b) => a + b);

    // let sumIrradiance = 0;
    for (let weekIndex = 0; weekIndex < irradianceWeekly.length; weekIndex += 1) {
      const weekIrradiance = irradianceWeekly[weekIndex];
      for (let hourIndex = 0; hourIndex < weekIrradiance.length; hourIndex += 1) {
        const irradiance = weekIrradiance[hourIndex];
        if (irradiance > 0) {
          const count = irradiance > 0 ? this.weather.renderPerHour : 1;
          for (let k = 0; k < count; k += 1) {
            const minute = k * (60.0 / this.weather.renderPerHour);
            const sunPos = this.weather.getSunPositionWeekly(month, hourIndex, minute, weekIndex);
            this.moveSunManualy(sunPos);
            this.sun.intensity = (irradiance / (sumBeemIrradiance * 4)) * iterCount;

            // sumIrradiance += this.sun.intensity;
            this.renderer.render(shadingScene, shadingCamera);
            this.renderer.clear(false, true, true);
          }
        }
      }
    }

    // const shadingTexture = this.renderTargetToTexture(this.renderer, shadingType, month);
    // downloadCanvas(shadingTexture.image, `${shadingType}_${month}.png`);

    const shadingData = this.renderTargetToCompleteData(this.renderer);

    this.renderer.setRenderTarget(null);

    if (this.debugMode) toc(`shade - ${shadingType} - ${month}`);
    return shadingData;
  }

  shadeDiffuse = (scene, shadingType) => {
    if (this.debugMode) tic();

    const points = [];
    const radius = 250;
    const detail = 2;
    const geo = new THREE.IcosahedronGeometry(radius, detail);
    const bath = new THREE.Mesh(geo, null);
    const count = bath.geometry.attributes.position.array.length / 9;
    for (let i = 0; i < count; i += 1) {
      const vert1 = new THREE.Vector3().fromArray(bath.geometry.attributes.position.array, i * 9 + 0 * 3);
      const vert2 = new THREE.Vector3().fromArray(bath.geometry.attributes.position.array, i * 9 + 1 * 3);
      const vert3 = new THREE.Vector3().fromArray(bath.geometry.attributes.position.array, i * 9 + 2 * 3);
      if (vert1.y >= 0) points.push(vert1);
      if (vert2.y >= 0) points.push(vert2);
      if (vert3.y >= 0) points.push(vert3);
    }
    const uniquePoints = [...new Map(points.map((item) => [item.x + item.y / radius + item.z / (radius ** 2), item])).values()];

    const iterCount = uniquePoints.length;
    const shadingScene = this.cloneScene(shadingType, iterCount, scene, false, true);

    const [minx, miny, maxx, maxy] = this.ranges;
    const [width, height] = [maxx - minx, maxy - miny];

    if (this.ambient) this.ambient.intensity = 0;
    this.sun.intensity = 1.0;

    const shadingCamera = new THREE.OrthographicCamera(-((maxx - minx) / 2) * this.scale, ((maxx - minx) / 2) * this.scale, ((maxy - miny) / 2) * this.scale, -((maxy - miny) / 2) * this.scale, 0.1, 1000);
    shadingCamera.position.set((minx + (maxx - minx) / 2 - this.center[0]) * this.scale, 100, (miny + (maxy - miny) / 2 - this.center[1]) * this.scale);
    shadingCamera.lookAt(shadingCamera.position.x, 0, shadingCamera.position.z);

    this.renderer.autoClear = false;
    this.renderer.shadowMap.enabled = shadingType !== 'TOF';
    this.renderer.shadowMap.type = THREE.VSMShadowMap;

    this.renderer.preserveDrawingBuffer = true;
    this.renderer.setPixelRatio(1);
    this.renderer.setSize(width, height, true);
    this.renderer.clear(true, true, true);

    const renderTarget = this.newRenderer(width, height);
    this.renderer.setRenderTarget(renderTarget);

    for (let i = 0; i < iterCount; i += 1) {
      const pos = uniquePoints[i];
      this.moveSunManualy(pos);
      this.renderer.render(shadingScene, shadingCamera);
      this.renderer.clear(false, true, true);
    }

    const diffuseData = this.renderTargetToCompleteData(this.renderer);

    // const shadingTexture = this.renderTargetToTexture(this.renderer, shadingType, 'diffuse');
    // downloadCanvas(shadingTexture.image, `${shadingType}_${'diffuse'}.png`);

    this.renderer.setRenderTarget(null);

    if (this.debugMode) toc(`diffuse shade - ${shadingType}`);
    return diffuseData;
  }

  calculateSolarAccessData = (tsrfData, tofData, dtsrfData, dtofData, month, width, height) => {
    const contextMASK = this.canvasPool.getContext('GetMaskCanvas');

    const maskData = contextMASK.getImageData(0, 0, width, height);


    const tsrfFloatData = new Float32Array(width * height * 4);
    const tofFloatData = new Float32Array(width * height * 4);
    const saFloatData = new Float32Array(width * height * 4);

    const saData = new Uint8Array(width * height * 4);

    const irradianceDnWeekly = this.weather.getDnWeekly(month);
    const irradianceDfWeekly = this.weather.getDfWeekly(month);
    const sumBeemIrradiance = irradianceDnWeekly.map((rw) => rw.reduce((a, b) => a + b)).reduce((a, b) => a + b);
    const sumDiffuseIrradiance = irradianceDfWeekly.map((rw) => rw.reduce((a, b) => a + b)).reduce((a, b) => a + b);

    let sumSolarAcces = 0;
    let SACounetr = 0;

    const [minx, miny, maxx, maxy] = this.ranges;
    for (let x = minx; x <= maxx; x += 1) {
      for (let y = miny; y <= maxy; y += 1) {
        const index = y * (width * 4) + x * 4;
        const maskValue = maskData.data[index];
        if (maskValue === 0) {
          for (let ch = 0; ch < 4; ch += 1) {
            tsrfData[index + ch] = 0;
            tofData[index + ch] = 0;
            saData[index + ch] = 0;

            tsrfFloatData[index + ch] = 0;
            tofFloatData[index + ch] = 0;
            saFloatData[index + ch] = 0;
          }
        } else {
          const tsrfIntensity = tsrfData[index];
          const tofIntensity = tofData[index];
          const dtsrfIntensity = dtsrfData[index];
          const dtofIntensity = dtofData[index];

          const numerator = (sumBeemIrradiance * tsrfIntensity + sumDiffuseIrradiance * dtsrfIntensity) / 255;
          const denominator = (sumBeemIrradiance * tofIntensity + sumDiffuseIrradiance * dtofIntensity) / 255;

          const sa = denominator !== 0 ? Math.min(1, numerator / denominator) * 255 : 0;
          const saFloat = denominator !== 0 ? Math.min(1, numerator / denominator) : 0;

          sumSolarAcces += sa;
          SACounetr += 1;

          [saData[index], saData[index + 1], saData[index + 2]] = [sa, sa, sa];
          saData[index + 3] = 255;

          const tsrfValueFloat = numerator / this.weather.optimalInsolations[month - 1]
          // if (tsrfValueFloat < 0 || tsrfValueFloat > 1) console.log(`==== tsrfValueFloat: ${tsrfValueFloat}`);
          const tofValueFloat = denominator / this.weather.optimalInsolations[month - 1]
          // if (tofValueFloat < 0 || tofValueFloat > 1) console.log(`==== tofValueFloat: ${tofValueFloat}`);

          const tsrfValue = Math.min(255, tsrfValueFloat * 255);
          const tofValue = Math.min(255, tofValueFloat * 255);

          // console.log(`==== tsrfValue: ${tsrfValue}`);
          // console.log(`==== tofValue: ${tofValue}`);

          [tsrfData[index], tsrfData[index + 1], tsrfData[index + 2]] = [tsrfValue, tsrfValue, tsrfValue];
          [tofData[index], tofData[index + 1], tofData[index + 2]] = [tofValue, tofValue, tofValue];


          // Float pipeline

          [tsrfFloatData[index], tsrfFloatData[index + 1], tsrfFloatData[index + 2]] = [tsrfValueFloat, tsrfValueFloat, tsrfValueFloat, 1.0];
          [tofFloatData[index], tofFloatData[index + 1], tofFloatData[index + 2]] = [tofValueFloat, tofValueFloat, tofValueFloat, 1.0];
          [saFloatData[index], saFloatData[index + 1], saFloatData[index + 2], saFloatData[index + 3]] = [saFloat, saFloat, saFloat, 1.0];
        }
      }
    }

    sumSolarAcces /= SACounetr;

    return [saData, tsrfData, tofData, sumSolarAcces, tsrfFloatData, tofFloatData, saFloatData];
  }

  calculateAverageTextures = (materials, shader, shadeData, width, height, setTextures = true) => {
    tic();

    const contextMASK = this.canvasPool.getContext('GetMaskCanvas');
    const maskData = contextMASK.getImageData(0, 0, width, height);

    const dataTSRF = new Uint8Array(width * height * 4);
    const dataTOF = new Uint8Array(width * height * 4);
    const dataSA = new Uint8Array(width * height * 4);
    const dataGTSRF = new Uint8Array(width * height * 4);
    const dataGTOF = new Uint8Array(width * height * 4);
    const dataGSA = new Uint8Array(width * height * 4);


    const dataFloatTSRF = new Float32Array(width * height * 4);
    const dataFloatTOF = new Float32Array(width * height * 4);
    const dataFloatSA = new Float32Array(width * height * 4);

    const data = [];
    const sumIrradiance = [];

    for (let i = 1; i <= 12; i += 1) {
      const tsrfData = shadeData[i][0];
      const tofData = shadeData[i][1];
      const saData = shadeData[i][2];
      const tsrfFloatData = shadeData[i][3];
      const tofFloatData = shadeData[i][4];
      const saFloatData = shadeData[i][5];

      data.push([tsrfData, tofData, saData, tsrfFloatData, tofFloatData, saFloatData]);

      const irradianceDnWeekly = this.weather.getDnWeekly(i);
      const irradianceDfWeekly = this.weather.getDfWeekly(i);
      const sumBeemIrradiance = irradianceDnWeekly.map((rw) => rw.reduce((a, b) => a + b)).reduce((a, b) => a + b);
      const sumDiffuseIrradiance = irradianceDfWeekly.map((rw) => rw.reduce((a, b) => a + b)).reduce((a, b) => a + b);

      sumIrradiance.push(sumBeemIrradiance + sumDiffuseIrradiance);
    }

    const totalIrradiance = sumIrradiance.reduce((a, b) => a + b);
    const optimalInsolationInYear = this.weather.optimalInsolations.reduce((a, b) => a + b);

    const [minx, miny, maxx, maxy] = this.ranges;
    for (let x = minx; x <= maxx; x += 1) {
      for (let y = miny; y <= maxy; y += 1) {
        const index = y * (width * 4) + x * 4;
        const maskValue = maskData.data[index];
        if (maskValue === 0) {
          dataGTSRF[index + 3] = 0;
          dataGTOF[index + 3] = 0;
          dataGSA[index + 3] = 0;
        } else {
          let tsrfIntensity = 0;
          let tofIntensity = 0;
          let saIntensity = 0;

          let tsrfFloatIntensity = 0;
          let tofFloatIntensity = 0;
          let saFloatIntensity = 0;

          for (let i = 1; i <= 12; i += 1) {
            const [tsrfData, tofData, solarAccessData, tsrfFloatData, tofFloatData, solarAccessFloatData] = data[i - 1];
            tsrfIntensity += tsrfData[index] * this.weather.optimalInsolations[i - 1];
            tofIntensity += tofData[index] * this.weather.optimalInsolations[i - 1];
            saIntensity += solarAccessData[index] * sumIrradiance[i - 1];

            tsrfFloatIntensity += tsrfFloatData[index] * this.weather.optimalInsolations[i - 1];
            tofFloatIntensity += tofFloatData[index] * this.weather.optimalInsolations[i - 1];
            saFloatIntensity += solarAccessFloatData[index] * sumIrradiance[i - 1];
          }

          const avgTSRF = tsrfIntensity / optimalInsolationInYear;
          const avgFloatTSRF = tsrfFloatIntensity / optimalInsolationInYear;

          dataTSRF[index] = avgTSRF;
          dataTSRF[index + 1] = avgTSRF;
          dataTSRF[index + 2] = avgTSRF;
          dataTSRF[index + 3] = 255;

          dataFloatTSRF[index] = avgFloatTSRF;
          dataFloatTSRF[index + 1] = avgFloatTSRF;
          dataFloatTSRF[index + 2] = avgFloatTSRF;
          dataFloatTSRF[index + 3] = 1.0;

          const gTSRF = gray2gradient(avgFloatTSRF);

          [dataGTSRF[index], dataGTSRF[index + 1], dataGTSRF[index + 2]] = gTSRF;
          dataGTSRF[index + 3] = 255;

          let avgTOF = tofIntensity / optimalInsolationInYear;
          let avgFloatTOF = tofFloatIntensity / optimalInsolationInYear;

          dataTOF[index] = avgTOF;
          dataTOF[index + 1] = avgTOF;
          dataTOF[index + 2] = avgTOF;
          dataTOF[index + 3] = 255;


          dataFloatTOF[index] = avgFloatTOF;
          dataFloatTOF[index + 1] = avgFloatTOF;
          dataFloatTOF[index + 2] = avgFloatTOF;
          dataFloatTOF[index + 3] = 1.0;

          const gTOF = gray2gradient(avgFloatTOF);

          [dataGTOF[index], dataGTOF[index + 1], dataGTOF[index + 2]] = gTOF;
          dataGTOF[index + 3] = 255;

          const avgSA = saIntensity / totalIrradiance;
          const avgFloatSA = saFloatIntensity / totalIrradiance;

          dataSA[index] = avgSA;
          dataSA[index + 1] = avgSA;
          dataSA[index + 2] = avgSA;
          dataSA[index + 3] = 255;

          dataFloatSA[index] = avgFloatSA;
          dataFloatSA[index + 1] = avgFloatSA;
          dataFloatSA[index + 2] = avgFloatSA;
          dataFloatSA[index + 3] = 1.0;

          const gSA = gray2gradient(avgFloatSA);

          [dataGSA[index], dataGSA[index + 1], dataGSA[index + 2]] = gSA;
          dataGSA[index + 3] = 255;
        }
      }
    }

    shadeData[0] = [dataTSRF, dataTOF, dataSA, dataFloatTSRF, dataFloatTOF, dataFloatSA];

    if (setTextures) {
      console.log('set textures');
      const TSRFTexture = shader.dataToTexture(dataTSRF, width, height);
      const TOFTexture = shader.dataToTexture(dataTOF, width, height);
      const SATexture = shader.dataToTexture(dataSA, width, height);
      const GTSRFTexture = shader.dataToTexture(dataGTSRF, width, height);
      const GTOFTexture = shader.dataToTexture(dataGTOF, width, height);
      const GSATexture = shader.dataToTexture(dataGSA, width, height);

      materials.setTSRFTextures(0, TSRFTexture, GTSRFTexture);
      materials.setTOFTextures(0, TOFTexture, GTOFTexture);
      materials.setSolarAccessTextures(0, SATexture, GSATexture);
    }

    toc('calculateAverageTextures');
  }

  calculateShadingFactor = async (shadingData, segmentCount) => {
    tic();

    const indicesMaskCanvas = this.canvasPool.getCanvas(this.canvasPool.PoolKeys.SegmentIndexMask);
    const indicesMaskContext = this.canvasPool.getContext(this.canvasPool.PoolKeys.SegmentIndexMask);
    const imageSegmentIndexData = indicesMaskContext.getImageData(0, 0, indicesMaskCanvas.width, indicesMaskCanvas.height);
    const { width } = indicesMaskCanvas;

    const [tsrfAccumulator, tofAccumulator, saAccumulator, counts] = [[], [], [], []];

    for (let s = 0; s < segmentCount; s += 1) {
      tsrfAccumulator.push(new Array(13).fill(0));
      tofAccumulator.push(new Array(13).fill(0));
      saAccumulator.push(new Array(13).fill(0));
      counts.push(new Array(13).fill(0));
    }

    const [minx, miny, maxx, maxy] = this.ranges;
    for (let x = minx; x <= maxx; x += 1) {
      for (let y = miny; y <= maxy; y += 1) {
        const index = y * (width * 4) + x * 4;
        const segmentIndex = imageSegmentIndexData.data[index] - 1;
        if (segmentIndex >= 0 && segmentIndex < segmentCount) {
          // TODO: it was m = 0 before, but I changed to m = 1
          for (let m = 0; m <= 12; m += 1) {
            const [tsrfMonthData, tofMonthData, saMonthData, tsrfFloatMonthData, tofFloatMonthData, saFloatMonthData] = shadingData[m];
            tsrfAccumulator[segmentIndex][m] += tsrfFloatMonthData[index];
            tofAccumulator[segmentIndex][m] += tofFloatMonthData[index];
            saAccumulator[segmentIndex][m] += saFloatMonthData[index];
            counts[segmentIndex][m] += 1;
          }
        }
      }
    }

    const [tsrfResult, tofResult, saResult] = [[], [], []];
    for (let s = 0; s < segmentCount; s += 1) {
      tsrfResult.push([]);
      tofResult.push([]);
      saResult.push([]);

      for (let m = 0; m <= 12; m += 1) {
        tsrfResult[s].push(tsrfAccumulator[s][m] / (counts[s][m]));
        tofResult[s].push(tofAccumulator[s][m] / (counts[s][m]));
        saResult[s].push(saAccumulator[s][m] / (counts[s][m]));
      }

      tsrfResult[s].push(tsrfResult[s].shift());
      tofResult[s].push(tofResult[s].shift());
      saResult[s].push(saResult[s].shift());
    }

    toc('calculateShadingFactor');

    return [tsrfResult, tofResult, saResult];
  }
}


function saveTextureAsImage(fullTextureData, width, height) {
  // Create a canvas element
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');

  // Create an ImageData object using the texture data
  const imageData = new ImageData(new Uint8ClampedArray(fullTextureData), width, height);

  // Put the ImageData into the canvas
  ctx.putImageData(imageData, 0, 0);

  // Convert the canvas to a Blob
  canvas.toBlob(function(blob) {
    // Create an anchor element and set the URL as the Blob URL
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = URL.createObjectURL(blob);
    a.download = 'texture.png'; // Name the download file
    
    // Trigger the download
    a.click();

    // Clean up the URL object and remove the anchor element
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  });
}