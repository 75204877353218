import {
  useState, useEffect, useContext,
} from 'react';

import * as THREE from 'three';

import SunCalc from 'suncalc';
import { DesignContext } from '../contexts/designContext';
import { sunPosition, gray2gradient, downloadCanvas } from '../algorithms/geometry';
import {
  Weather,
  monthNames,
  weekDays,
  renderPerHour,
  MaximumInsolationInMonth,
  MaximumInsolationInYear,
} from '../algorithms/weather';

export const useWeatherData = (irradiancePath, coordinates, updateLoadingStatus, readonly) => {
  const {
    optPitchAndAzimuth,
    setOptPitchAndAzimuth,
  } = useContext(DesignContext);
  const [weather] = useState(new Weather());
  const [month, setMonth] = useState(0);
  const [weatherData, setWeatherData] = useState({});
  const [timezone, setTimezone] = useState('');
  const [loadStatus, setLoadStatus] = useState('Loading');
  const [{ optimalNormal, optimalInsolations }, setOptimalNormal] = useState({ optimalNormal: null, optimalInsolations: 0 });

  const getSunPositionByElevationAndAzimuth = (azimuthAngle, elevateAngle, distance = 150) => weather.getSunPositionByElevationAndAzimuth(azimuthAngle, elevateAngle, distance);

  const getSunPosition = (time, distance = 150) => weather.getSunPosition(time, distance);

  const getSunPositionWeekly = (m, hourIndex, minute, weekIndex, distance = 150) => weather.getSunPositionWeekly(m, hourIndex, minute, weekIndex, distance);

  const getInsolation = (m, week, hour, type) => weather.getInsolation(m, week, hour, type);

  const getInsolationWeekly = (m, type) => weather.getInsolationWeekly(m, type);

  const getOptPitchAndAzimuth = () => {
    const [pitch, azimuth, normal, insolations] = weather.getOptPitchAndAzimuth();
    setOptPitchAndAzimuth(pitch, azimuth);
    setOptimalNormal({ optimalNormal: normal, optimalInsolations: insolations });
    return [pitch, azimuth, normal, insolations];
  };

  useEffect(() => {
    const changeLoadStatus = (status) => {
      setLoadStatus(status);
      updateLoadingStatus(status);
    };

    weather.load(irradiancePath).then((data) => {
      changeLoadStatus('Irradiance.Loaded');
      setWeatherData(data);
    }).catch(() => {
      changeLoadStatus('Irradiance.Failed');
    });
    // if (imgSrc) {
    //   const scr = `${imgSrc.substr(0, imgSrc.lastIndexOf('/'))}/irradiance.json`;
    //   fetch(scr)
    //     .then((res) => res.json())
    //     .then((json) => {
    //       changeLoadStatus('Irradiance.Loaded');
    //       setWeatherData(json);
    //     })
    //     .catch(() => changeLoadStatus('Irradiance.Failed'));
    // }
  }, [weather, irradiancePath]);

  useEffect(() => {
    weather.changeByCoordinate(coordinates);
    setTimezone(weather.timezone);
  }, [weather, coordinates]);

  return {
    month,
    timezone,
    monthNames,
    weekDays,
    renderPerHour,
    weatherData,
    MaximumInsolationInMonth,
    MaximumInsolationInYear,
    optimalNormal,
    optimalInsolations,
    loadStatus,
    setMonth,
    getSunPositionByElevationAndAzimuth,
    getSunPosition,
    getSunPositionWeekly,
    getDn: (m, week, hour) => getInsolation(m, week, hour, 'dn'),
    getDf: (m, week, hour) => getInsolation(m, week, hour, 'df'),
    getDnWeekly: (m) => getInsolationWeekly(m, 'dn'),
    getDfWeekly: (m) => getInsolationWeekly(m, 'df'),
    getOptPitchAndAzimuth,
  };
};

export default useWeatherData;
