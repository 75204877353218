import React, { useEffect, useState, useContext } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { decode, encode, GlobalId } from '../../../id'
import { AuthContext } from 'api/context';

const PortalRedirect = (props) => {

  const { proposalId, leadId, loading, handleSaveProposal = null, handleShadingReport = null } = props;
  const { company, auth } = useContext(AuthContext);

  // Elements for new Platform integration
  const globalCompanyId = encode(new GlobalId('Organization', 'legacy', company));
  const globalProposalId = encode(new GlobalId('Proposal', 'legacy', proposalId));
  const globalCustomerId = encode(new GlobalId('Customer', 'legacy', leadId));
  const session = auth.getToken();

  const getCustomerPortalLink = async (e) => {
    try {
      if (handleSaveProposal) {
        e.preventDefault();
        // await handleShadingReport(null, false);
        // await handleSaveProposal();
        const anchor = e.target.closest("a");
        const href = anchor.getAttribute('href');
        window.open(href, '_blank', 'noopener');
      }
    } catch (err) {
      console.log(err);
    }
  }

  const accessibilityList = ["WOLFRI", "AERIAL", "AERIA1", "SUNCHE", "JSCONS", "SUNLY2", "TEMPOS", "SIGESO"];

  if (accessibilityList.includes(company) || company.includes("WOLFR")) {
    return (
      <DropdownButton style={{ display: 'inline' }} size={props?.size} className={`my-0 py-0 ${props?.size === 'sm' ?  '.dropdown-menu-sm' : '.dropdown-menu-default'}`} variant='secondary' id="customer-portal-dropdown" title="Customer Portal" disabled={props?.size == 'sm' || loading ? true : false}>
      <Dropdown.Item
        onClick={e => getCustomerPortalLink(e)}
        className='px-1 my-0'
        href={`${process.env.REACT_APP_PLATFORM_PUBLIC_URL}/apps/customer-portal/${globalCustomerId}?proposalId=${globalProposalId}&organizationId=${globalCompanyId}&viewAs=customer`}>
        <small>Homeowner</small>
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        onClick={e => getCustomerPortalLink(e)}
        className='px-1 my-0'
        href={`${process.env.REACT_APP_PLATFORM_PUBLIC_URL}/auth/signin/legacy?session=${session}&organization=${globalCompanyId}&redirectTo=${encodeURIComponent(`${process.env.REACT_APP_PLATFORM_PUBLIC_URL}/apps/customer-portal/${globalCustomerId}?proposalId=${globalProposalId}&organizationId=${globalCompanyId}`)}`}>
        <small>Consultant</small>
      </Dropdown.Item>
    </DropdownButton>

    );
  }

  return <></>
};

PortalRedirect.propTypes = {
  textColor: PropTypes.string.isRequired,
};


export default PortalRedirect;
