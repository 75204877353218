const Tool = {
  setInitSettings(data) {
    this.state = {
      ...this.state,
      started: true,
      start: data.start,
      canvasData: [],
      options: data.options,
    };
    this.imageData = this.ctx.getImageData(
      0,
      0,
      this.ctx.canvas.width,
      this.ctx.canvas.height,
    );
    // this.ctx.lineWidth = data.options.lineWidth;
    // this.ctx.strokeStyle = data.options.lineColor;
  },

  draw(start, position, isInitSet, data) {
    if (isInitSet === false) {
      this.setInitSettings({ start, options: data.options || {} }, true);
    }

    // draw line shadows
    this.ctx.beginPath();
    this.ctx.strokeStyle = this.state.options.shadowColor;
    this.ctx.lineWidth = this.state.options.lineWidth + 1;
    this.ctx.moveTo(start.x, start.y);
    this.ctx.lineTo(position.x, position.y);
    this.ctx.arc(
      position.x,
      position.y,
      this.state.options.lineWidth + 1,
      0,
      2 * Math.PI
    );
    this.ctx.stroke();

    // draw line
    this.ctx.beginPath();
    this.ctx.strokeStyle = this.state.options.lineColor;
    this.ctx.lineWidth = this.state.options.lineWidth;
    this.ctx.moveTo(start.x, start.y);
    this.ctx.lineTo(position.x, position.y);
    this.ctx.arc(
      position.x,
      position.y,
      this.state.options.lineWidth,
      0,
      2 * Math.PI,
    );
    this.ctx.stroke();
  },

  onMouseDown() {
    throw new Error('onMouseDown must be implemented.');
  },

  onMouseMove() {
    throw new Error('onMouseMove must be implemented.');
  },

  onMouseUp() {
    throw new Error('onMouseUp must be implemented.');
  },

  createStartPoint(start, radius = 3) {
    const circle = new Path2D();
    this.ctx.strokeStyle = '#FF0000';
    circle.arc(start.x, start.y, radius, 0, 2 * Math.PI);
    if (this.ctx) {
      // this.ctx.fillStyle = 'rgba(255, 0, 0, 0.5)';
      // this.ctx.fill(circle);
      this.ctx.stroke(circle);
    }
    return circle;
  },

  closePath() {
    if (this.ctx) {
      this.ctx.closePath();
    }
  },

  resetState() {
    this.state = null;
  },
};

export default Tool;
