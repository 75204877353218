import * as areaPolygon from 'area-polygon';
import * as convertUnits from 'convert-units';

// calculate euclidean distance
export const distance = (a, b) => Math.hypot(a[0] - b[0], a[1] - b[1]);

export const rad2deg = (rad) => (rad * 180) / Math.PI;

export const dot = (a, b) => a.map((x, i) => a[i] * b[i]).reduce((m, n) => m + n);

// vector sum arrays
export const sumVects = (a, b) => a.map((ai, i) => ai + b[i]);
export const subVects = (a, b) => a.map((ai, i) => ai - b[i]);

// check if point is in a given shape
export const isPointInShape = (point, path) => {
  const nvert = path.length;

  // For Circle just check distance from center is less than radius
  if (nvert === 2) return distance(point, path[0]) < distance(path[0], path[1]);

  // ray-casting algorithm based on
  // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html
  const [x, y] = point;
  let inside = false;
  for (let i = 0, j = nvert - 1; i < nvert; j = i++) {
    const [xi, yi] = path[i];
    const [xj, yj] = path[j];

    if (yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi) {
      inside = !inside;
    }
  }
  return inside;
};

// check if point is on line AB
export const isPointOnLine = (point, A, B) => (
  Math.abs(distance(A, point) + distance(B, point) - distance(A, B)) <= 1.25
);

export const isPointNearLine = (point, A, B, thr) => (
  Math.abs(distance(A, point) + distance(B, point) - distance(A, B)) <= thr
);

// check if point is along path
export const isPointInPath = (point, path) => {
  let lineIndex = -1;

  path.forEach((_, index) => {
    // check distance from between vertices ==
    // the sum of distances from each vertex to cursor
    if (index < path.length - 1) {
      if (isPointOnLine(point, path[index], path[index + 1])) {
        lineIndex = index;
        return;
      }
      // check line that closes the path
    } else if (isPointOnLine(point, path[index], path[0])) {
      lineIndex = index;
    }
  });
  return lineIndex;
};

// scales geometry of given shape
export const scaleShape = (shape, factor, retGeom = false) => {
  const scaled = shape.geometry.map((p) => [
    Math.round(p[0] * factor),
    Math.round(p[1] * factor),
  ]);
  if (retGeom) return scaled;
  return { ...shape, geometry: scaled };
};

export const isPointEqual = ([x1, y1], [x2, y2]) => (x1 === x2) && (y1 === y2);

export const filterDuplicates = (geometry) => {
  if (geometry.length === 1) return [geometry, []];
  const removed = [];
  const geom = geometry.filter((pt, index) => {
    const next = (index + 1) % geometry.length;
    const remove = isPointEqual(pt, geometry[next]);
    if (remove) removed.push(index);
    return !remove;
  });
  return [geom, removed];
};

// TODO: re-write this function to detect "overlapping" lines more generally
export const findCommonLine = (points1, points2) => {
  const intersection = points1.filter((p1) => points2.some((p2) => isPointEqual(p1, p2)));
  if (intersection.length === 0) return -1;

  const indexes = [];
  points2.forEach((p, i) => {
    intersection.forEach((item, j) => {
      if (p[0] === item[0] && p[1] === item[1]) {
        indexes.push(i);
      }
    });
  });
  return indexes;
};

export const inchToPx = (inches, resolution) => (inches * 2.54) / resolution;

export const inchToCm = (inch) => (inch * 2.54);

// https://stackoverflow.com/questions/9692448/how-can-you-find-the-centroid-of-a-concave-irregular-polygon-in-javascript
function polygonCentroid(pts) {
  const first = pts[0];
  const last = pts[pts.length - 1];
  const nPts = pts.length;
  if (first[0] !== last[0] || first[1] !== last[1]) pts.push(first);
  let twicearea = 0;
  let [x, y] = [0, 0];
  let p1;
  let p2;
  let f;

  for (let i = 0, j = nPts - 1; i < nPts; j = i++) {
    p1 = pts[i]; p2 = pts[j];
    f = p1[0] * p2[1] - p2[0] * p1[1];
    twicearea += f;
    x += (p1[0] + p2[0]) * f;
    y += (p1[1] + p2[1]) * f;
  }
  f = twicearea * 3;
  return [x / f, y / f];
}

// fix azimuth orientation of panels on a roof
export const rotateSegmentPanels = (segment, panels, azimuth) => {
  const angle = azimuth - panels.azimuth;
  if (Math.abs(angle) < 0.1) return panels;

  // only calculate cos/sin once
  const cosA = Math.cos((angle / 180) * Math.PI);
  const sinA = Math.sin((angle / 180) * Math.PI);

  const [cx, cy] = polygonCentroid(segment.geometry);
  // Iterate points converting from cartesion to polar coords and back
  const rotated = panels;
  rotated.azimuth = azimuth;
  rotated.vertical.points = panels.vertical.points.map(([x, y]) => [
    (x - cx) * cosA - (y - cy) * sinA + cx,
    (x - cx) * sinA + (y - cy) * cosA + cy,
  ]);
  rotated.horizontal.points = panels.horizontal.points.map(([x, y]) => [
    (x - cx) * cosA - (y - cy) * sinA + cx,
    (x - cx) * sinA + (y - cy) * cosA + cy,
  ]);

  return rotated;
};

export const appendArc = (vertices, center, radius, startVertex, endVertex, isPaddingBoundary) => {
  const twoPI = Math.PI * 2;
  let startAngle = Math.atan2(startVertex[1] - center[1], startVertex[0] - center[0]);
  let endAngle = Math.atan2(endVertex[1] - center[1], endVertex[0] - center[0]);
  if (startAngle < 0) startAngle += twoPI;
  if (endAngle < 0) endAngle += twoPI;
  const arcSegmentCount = 5; // Odd number so one arc vertex will be exactly arcRadius from center
  const angle = (startAngle > endAngle) ? (startAngle - endAngle) : (startAngle + twoPI - endAngle);
  const angle5 = ((isPaddingBoundary) ? -angle : twoPI - angle) / arcSegmentCount;

  vertices.push(startVertex);
  for (let i = 1; i < arcSegmentCount; i += 1) {
    const theta = startAngle + angle5 * i;
    const vertex = [
      center[0] + Math.cos(theta) * radius,
      center[1] + Math.sin(theta) * radius,
    ];
    vertices.push(vertex);
  }
  vertices.push(endVertex);
};

export const edgeIntersection = (edgeA, edgeB) => {
  // TODO: clean this up
  const den = (edgeB[1][1] - edgeB[0][1]) * (edgeA[1][0] - edgeA[0][0])
    - (edgeB[1][0] - edgeB[0][0]) * (edgeA[1][1] - edgeA[0][1]);
  // if (den == 0)
  //   return null;  // lines are parallel or coincident

  const ua = ((edgeB[1][0] - edgeB[0][0]) * (edgeA[0][1] - edgeB[0][1])
    - (edgeB[1][1] - edgeB[0][1]) * (edgeA[0][0] - edgeB[0][0])) / den;
  // let ub = ((edgeA[1][0] - edgeA[0][0]) * (edgeA[0][1] - edgeB[0][1])
  // - (edgeA[1][1] - edgeA[0][1]) * (edgeA[0][0] - edgeB[0][0])) / den;

  // if (ua < 0 || ub < 0 || ua > 1 || ub > 1)
  //   return null;

  return [
    edgeA[0][0] + ua * (edgeA[1][0] - edgeA[0][0]),
    edgeA[0][1] + ua * (edgeA[1][1] - edgeA[0][1]),
  ];
};

export const offsetEdge = (ept1, ept2, diff) => {
  return [
    [ept1[0] + diff[0], ept1[1] + diff[1]],
    [ept2[0] + diff[0], ept2[1] + diff[1]],
  ];
};

export const rotateArrayRight = (arr, k) => {
  for (let i = 0; i < k; i += 1) {
    const last = arr.pop();
    arr.unshift(last);
  }
  return arr;
};

export const rotateArrayLeft = (arr, k) => {
  for (let i = 0; i < k; i += 1) {
    const first = arr.shift();
    arr.push(first);
  }
  return arr;
};

export const rotateLeft = (arr) => {
  const first = arr.shift();
  arr.push(first);
  return arr;
};

export const rotateRight = (arr) => {
  const last = arr.pop();
  arr.unshift(last);
  return arr;
};

/* export const paddingAroundPolygon = (shape) => {

}; */

export const orthoProject = (a, b, c) => {
  let mr = 1000000000;

  if (b[0] - a[0] !== 0) {
    mr = (b[1] - a[1]) / (b[0] - a[0]);
  }

  let ms = -10000000000;
  if (mr !== 0) {
    ms = (-1) / mr;
  }

  const a1 = -mr;
  // const b1 = 1;
  const c1 = a[1] - mr * a[0];

  const a2 = -ms;
  // const b2 = 1;
  const c2 = c[1] - ms * c[0];

  const X = Math.round((c1 - c2) / (a1 - a2));
  const Y = Math.round((a1 * c2 - a2 * c1) / (a1 - a2));

  return [X, Y];
};

const dist2 = (v, w) => (v[0] - w[0]) ** 2 + (v[1] - w[1]) ** 2;

function distToSegmentSquared(p, v, w) {
  const l2 = dist2(v, w);
  if (l2 === 0) return dist2(p, v);
  let t = ((p[0] - v[0]) * (w[0] - v[0]) + (p[1] - v[1]) * (w[1] - v[1])) / l2;
  t = Math.max(0, Math.min(1, t));
  return dist2(p, [v[0] + t * (w[0] - v[0]), v[0] + t * (w[0] - v[0])]);
}

export const distToLine = (p, v, w) => Math.sqrt(distToSegmentSquared(p, v, w));

export const translateVector = (origin, pt, rotation) => {
  const angle = rotation * (Math.PI / 180); // convert to radians
  const [x3, y3] = [
    (Math.cos(angle) * (pt[0] - origin[0]) - Math.sin(angle) * (pt[1] - origin[1])) + origin[0],
    (Math.sin(angle) * (pt[0] - origin[0]) + Math.cos(angle) * (pt[1] - origin[1])) + origin[1],
  ];
  return [x3, y3];
};

export const getKonvaRectCorners = (rect) => {
  const { x, y } = rect.position();
  const rotation = rect.rotation();
  const width = rect.width();
  const height = rect.height();

  return [
    [x, y],
    translateVector([x, y], [x + width, y], rotation),
    translateVector([x, y], [x + width, y + height], rotation),
    translateVector([x, y], [x, y + height], rotation),
  ];
};

export const drawOrientation = (geometry, pindex) => {
  const p1 = geometry[pindex];
  const p2 = geometry[(pindex + 1) % geometry.length];

  const center = geometry
    .reduce(([cX, cY], [pX, pY]) => [cX + pX, cY + pY], [0, 0])
    .map((xi) => xi / geometry.length);

  // vector from center to p1
  const v1X = -(p1[0] - center[0]);
  const v1Y = -(p1[1] - center[1]);

  // vector from center to p2
  const v2X = -(p2[0] - center[0]);
  const v2Y = -(p2[1] - center[1]);

  if (v1X * v2Y - v1Y * v2X >= 0) {
    return 'cw';
  }
  return 'ccw';
};

export const inwardEdgeNormal = (geometry) => {
  const ori = drawOrientation(geometry, 0);

  const inwardNormals = geometry.map((pt, jdx) => {
    let dx = geometry[(jdx + 1) % geometry.length][0] - pt[0];
    let dy = geometry[(jdx + 1) % geometry.length][1] - pt[1];
    const edgeLength = Math.sqrt(dx * dx + dy * dy);
    if (ori === 'ccw') {
      dx = -dx;
      dy = -dy;
    }
    return [-dy / edgeLength, dx / edgeLength];
  });
  return inwardNormals;
};

export const mindDistanceToLine = (E, A, B) => {
  /*
    E: Target point
    A: Line point 1
    B: Line point 2
  */
  // vector AB
  const AB = [NaN, NaN];
  AB[0] = B[0] - A[0];
  AB[1] = B[1] - A[1];

  // vector BP
  const BE = [NaN, NaN];
  BE[0] = E[0] - B[0];
  BE[1] = E[1] - B[1];

  // vector AP
  const AE = [NaN, NaN];
  AE[0] = E[0] - A[0];
  AE[1] = E[1] - A[1];
  // Variables to store dot product
  // Calculating the dot product
  const AB_BE = AB[0] * BE[0] + AB[1] * BE[1];
  const AB_AE = AB[0] * AE[0] + AB[1] * AE[1];
  // Minimum distance from
  // point E to the line segment
  let reqAns = 0;
  if (AB_BE > 0) {
    // Case 1
    // Finding the magnitude
    const y = E[1] - B[1];
    const x = E[0] - B[0];
    reqAns = Math.sqrt(x * x + y * y);
  } else if (AB_AE < 0) {
    // Case 2
    const y = E[1] - A[1];
    const x = E[0] - A[0];
    reqAns = Math.sqrt(x * x + y * y);
  } else {
    // Case 3
    // Finding the perpendicular distance
    const x1 = AB[0];
    const y1 = AB[1];
    const x2 = AE[0];
    const y2 = AE[1];
    const mod = Math.sqrt(x1 * x1 + y1 * y1);
    reqAns = Math.abs(x1 * y2 - y1 * x2) / mod;
  }
  return reqAns;
};

const nearestLine = (pt, shapeGeometry) => {
  const lineDistances = Array(shapeGeometry.length);
  shapeGeometry.forEach((p, idx) => {
    lineDistances[idx] = mindDistanceToLine(pt, p, shapeGeometry[(idx + 1) % shapeGeometry.length]);
  });
  return [lineDistances.indexOf(Math.min(...lineDistances)), Math.min(...lineDistances)];
};

export const padPolygon = (shape, scale, setback, mode, resolution) => {
  // TODO: include pitch factors
  const inwardNormals = inwardEdgeNormal(shape.geometry);
  const offsetEdges = [];
  if (mode === 0) {
    // general single-value setback mode
    shape.geometry.forEach((pt, idx) => {
      let diff = null;
      if (!shape.lineSetbacks[idx]) {
        if (shape.lines[idx] !== 'Eave') {
          diff = [
            inwardNormals[idx][0] * scale * inchToPx(setback, resolution),
            inwardNormals[idx][1] * scale * inchToPx(setback, resolution),
          ];
        } else {
          diff = [0, 0];
        }
      } else {
        diff = [
          inwardNormals[idx][0] * scale * inchToPx(shape.lineSetbacks[idx], resolution),
          inwardNormals[idx][1] * scale * inchToPx(shape.lineSetbacks[idx], resolution),
        ];
      }
      // if (halfPadding === true) {
      //   diff = [diff[0] / 2, diff[1] / 2];
      // }
      const paddedEdgePts = offsetEdge(pt, shape.geometry[(idx + 1) % shape.geometry.length], diff);
      offsetEdges.push(paddedEdgePts);
    });
  } else if (mode === 1) {
    // edge label setback mode
    shape.geometry.forEach((pt, idx) => {
      let diff = null;
      if (!shape.lineSetbacks[idx]) {
        const label = shape.lines[idx];
        if (Object.keys(setback).includes(label)) {
          diff = [
            inwardNormals[idx][0] * scale * inchToPx(+setback[label], resolution),
            inwardNormals[idx][1] * scale * inchToPx(+setback[label], resolution),
          ];
        } else {
          diff = [0, 0];
        }
      } else {
        diff = [
          inwardNormals[idx][0] * scale * inchToPx(shape.lineSetbacks[idx], resolution),
          inwardNormals[idx][1] * scale * inchToPx(shape.lineSetbacks[idx], resolution),
        ];
      }
      const paddedEdgePts = offsetEdge(pt, shape.geometry[(idx + 1) % shape.geometry.length], diff);
      offsetEdges.push(paddedEdgePts);
    });
  }

  const vertices = [];
  offsetEdges.forEach((offset, idx) => {
    const thisEdge = offsetEdges[idx];
    const prevEdge = offsetEdges[(idx + offsetEdges.length - 1) % offsetEdges.length];
    const vertex = edgeIntersection(prevEdge, thisEdge);
    if (vertex) {
      vertices.push(vertex);
    } else {
      const arcCenter = shape.geometry[idx];
      appendArc(vertices, arcCenter, 1, prevEdge[1], thisEdge[0], true);
    }
  });

  // prevent setbacks from extending outside segment geometry
  vertices.forEach((vertex, idx) => {
    if (!isPointInShape(vertex, shape.geometry)) {
      const [lineIdx, minDist] = nearestLine(vertex, shape.geometry);
      // console.log(lineIdx, minDist);
      if (minDist > 0.5) {
        const segmentEdge = [
          shape.geometry[lineIdx],
          shape.geometry[(lineIdx + 1) % shape.geometry.length],
        ];
        const paddedEdge1 = [
          vertices[(idx + vertices.length - 1) % vertices.length],
          vertices[idx],
        ];
        const paddedEdge2 = [
          vertices[idx],
          vertices[(idx + 1) % vertices.length],
        ];
        const newVertex1 = edgeIntersection(segmentEdge, paddedEdge1);
        const newVertex2 = edgeIntersection(segmentEdge, paddedEdge2);
        // console.log(newVertex1, newVertex2, 'Why');
        vertices.splice(idx, 1, newVertex1, newVertex2);
      }
    }
  });
  return vertices;
};

const delay = (fn, ms) => new Promise((resolve) => setTimeout(() => resolve(fn()), ms));

const randInt = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

export const fail = () => {
  throw new Error('Failed to retrieve data from NREL.');
};

export const retry = async (fn, maxAttempts) => {
  const execute = async (attempt) => {
    try {
      return await fn();
    } catch (err) {
      if (attempt <= maxAttempts) {
        const nextAttempt = attempt + 1;
        const delayInSeconds = Math.max(Math.min((2 ** nextAttempt) + randInt(-nextAttempt, nextAttempt), 600), 1);
        console.log(`Retrying after ${delayInSeconds} seconds due to:`, err);
        return delay(() => execute(nextAttempt), delayInSeconds * 1000);
      }
      throw err;
    }
  };
  return execute(1);
};

export const ios = () => {
  if (typeof window === 'undefined' || typeof navigator === 'undefined') return false;
  const isIos = /iPhone|iPad|iPod|iOS/i.test(navigator.userAgent || navigator.vendor);
  return isIos;
};

function getPolygonArea(points) {
  let area = 0;
  let n = points.length;
  if (points[0][0] !== points[n - 1][0] || points[0][1] !== points[n - 1][1]) {
    points = [...points, points[0]];
  } else {
    n = n - 1;
  }
  for (let i = 0; i < n; i++) {
    const j = i + 1;
    area += points[i][0] * points[j][1] - points[j][0] * points[i][1];
  }
  return Math.abs(area) / 2;
}

export const getSegmentArea = (segment, resolution) => {
  const pitchRad = (Number(segment.pitch) * Math.PI) / 180;
  const areaXY_px_2 = getPolygonArea(segment.geometry);
  const areaXY_cm_2 = areaXY_px_2 * (resolution * resolution);
  const areaXY_m_2 = areaXY_cm_2 * 0.0001;
  const area3D_m_2 = areaXY_m_2 / Math.cos(pitchRad);
  const area3D_ft_2 = area3D_m_2 * 10.7639;
  if (isNaN(area3D_ft_2)) area3D_ft_2 = 0;
  return area3D_ft_2;
}

export const getSegmentArea_ = (segment) => {
  const samplingRate = 30;
  const P = Math.tan(Number(segment.pitch) * 0.0174533) * 12;
  const slopeFactor = Math.sqrt(P * P + 12 * 12) / 12;
  const segArea = areaPolygon(segment.geometry) * slopeFactor;
  const segAreaSqm = (segArea * samplingRate) / 10000;
  const area = convertUnits(segAreaSqm).from('m2').to('ft2');
  if (isNaN(area)) area = 0;
  return area;
};

export const updateSegmentArea = (segments, resolution) => {
  segments.forEach((segment) => {
    if (isNaN(segment.area)) segment.area = getSegmentArea(segment, resolution);
  });
};