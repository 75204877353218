/* eslint-disable react/no-this-in-sfc */
import React, {
  useState,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';

import { Canvas, useFrame, useThree } from '@react-three/fiber';

import * as THREE from 'three';

import canvasHandler from '../DrawCanvas/handlers/canvasHandler';

// Components
import { LeadContext } from 'Leads/Details/hooks/context';
import { DesignContext } from './contexts/designContext';
import Toolbar from './Toolbar';
import Scene from './objects/Scene.js';
import ExportWrapper from './objects/ExportWrapper';

import useMaterials from './hooks/useMaterials';
import useDSM from './hooks/useDSM';
import useShader from './hooks/useShader';
import useWeatherData from './hooks/useWeatherData';
import use3DReconstruction from './hooks/use3DReconstruction';
import usePanelOperation from './hooks/usePanelOperation';
import useTreeOperation from './hooks/useTreeOperation';
import useDrawing from './hooks/useDrawing';

import './styles.css';

import {
  optimizeVertexPosition,
  optimizePlanes,
  getParentsPolygon,
  projectToPlane,
  segmentRegression,
  fitPlaneOnSegment,
  projectTo3DFromOrgVertices,
} from './algorithms/geometry';

import { CanvasPool, downloadObjectAsJson } from './algorithms/utils';

import { detectEdgeTypes } from './algorithms/edgeDetection';
import { initState, sceneUnitToInche } from './algorithms/3DReconstruction';
import { getOffset, segmentIndexMask, measureByDSM } from './algorithms/dsm';
import { CanvasPoolContext } from './contexts/canvasPoolContext';

let loadingTimeout;

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

let DTSRFData = null;

let DTOFData = null;

let StorageData = null;

const Canvas3D = forwardRef(
  (
    {
      imgSrc,
      dsmSrc,
      segments,
      obstacles,
      trees,
      panels,
      annotations,
      visible,
      size,
      readonly,
      proposalShadings,
      iframeCallback,
      initViewMode = '2D',
    },
    ref
  ) => {
    const {
      viewerMode,
      resolution,
      selectionMode,
      setSelectionMode,
      selectedObjects,
      removeFromSelectedObjects,
      addToSelectedObjects,
      clearSelection,
      deleteSelectedObjects,
      setHeight,
      setPitch,
      setSetback,
      setSegmentPosition,
      changeEdgeTypes,
      moveRoofVertex,
      coordinates,
      setSegmentShadingFactors,
      setSegmentShadingFactorsServerSide,
      accordion,
      setShadingTexture,
      shadingStatus,
      shadingIsRunning,
      setShadingStatus,
      setShadingIsRunning,
      setServerSideShadingIsRunning,
      setSegmentParameters,
      optPOA,
      automaticAzimuthMode,
      mode,
      setMode,
      designType,
      updateRGBTextureIsRunning,
      setUpdateRGBTextureIsRunning,
      setAnnotations,
      designFrame,
      // moveMode,
      // tool,
      // setViewMode,
    } = useContext(DesignContext);
    const { lead, user, displaySnack, runShadingsAPI, exportDesignAPI } =
      useContext(LeadContext);
    const { canvasPool } = useContext(CanvasPoolContext);
    const [viewMode, setViewMode] = useState(initViewMode);
    const [viewState, setViewState] = useState();
    // const [annotations, setAnnots] = useState([]);

    const [drawingCapabilities, setDrawingCapabilities] = useState([
      'magnetize',
      'snap45',
      'snap90',
      'snap180',
    ]);

    const sceneRef = useRef();
    const sceneObjRef = useRef();
    const canvas = useRef();
    const controls = useRef();
    const toolbar = useRef();
    const container = useRef();
    const exportRef = useRef();

    const [[isLoaded, loadingTitle, loadedComponents], setIsLoaded] = useState([
      false,
      '',
      [],
    ]);

    const updateLoadingStatus = (component = null, checkRef = true) => {
      if (component) loadedComponents.push(component);
      const loadedRef = !!(
        !checkRef ||
        (container.current && sceneRef.current && controls.current)
      );
      const loaded =
        loadedRef &&
        (loadedComponents.includes('DSM.Loaded') ||
          loadedComponents.includes('DSM.Failed')) &&
        (loadedComponents.includes('Irradiance.Loaded') ||
          loadedComponents.includes('Irradiance.Failed')) &&
        (loadedComponents.includes('Shading.Loaded') ||
          loadedComponents.includes('Shading.Failed') ||
          loadedComponents.includes('Shading.None'));
      if (loaded) clearInterval(loadingTimeout);
      setIsLoaded([loaded, component, loadedComponents]);
    };

    const [views, setViews] = useState(
      !readonly
        ? ['texture', 'trees', 'roofs', 'dsm']
        : ['texture', 'trees', 'roofs', 'dsm', 'panels']
    );

    const {
      orgDSMTexture,
      dsmTexture,
      minDSM,
      maxDSM,
      displacementBias,
      dsmOffset,
      dsmRaster,
      dsmRasterHeight,
      dsmRasterWidth,
      loadStatus: dsmLoadStatus,
      fillPolygon,
      getPointClouds,
      calculateAndSetOffset,
      changeOffset,
      getDSMData,
    } = useDSM(canvasPool, dsmSrc, segments, trees, updateLoadingStatus);

    const irradiancePath = `/media/${user.company.id}/${lead.uid}`;

    const weather = useWeatherData(
      irradiancePath,
      coordinates,
      updateLoadingStatus,
      readonly
    );
    const { month, setMonth, getOptPitchAndAzimuth } = weather;

    const materials = useMaterials({
      canvasPool,
      imgSrc,
      dsmTexture,
      month,
      setShadingTexture,
      sceneRef,
      fillPolygon,
      updateLoadingStatus,
      proposalShadings: proposalShadings ?? lead.property.shading_images,
      iframeCallback,
    });

    const {
      rgbTexture,
      setRGBTexture,
      texture,
      selectedTexture,
      setTexture,
      setCurrentTexture,
      changeMaterials,
      getMaskCanvas,
      resetRGBTexture,
    } = materials;

    const {
      verticesInfo: {
        orgVertices,
        vertices,
        vertices3D,
        verticesType,
        eaves,
        pitches,
        azimuths,
        eaveHeights,
        UV,
        changeClockwise,
        ranges,
        parents,
      },
      scale,
      cx,
      cy,
      c,
      inchePerUnit,
      footPerUnit,
      getFootPerSceneUnit,
      getInchePerSceneUnit,
      getSceneUnitPerFoot,
      getSetbacks,
      updateStateFromSegments,
      updateEavesByAzimuth,
      magnetizeVertices,
      getBorders,
    } = use3DReconstruction(
      segments,
      obstacles,
      texture,
      visible,
      readonly,
      mode
    );

    // const drawEaves = () => {
    //   const material = new THREE.LineBasicMaterial({ color: 0xff0000 });
    //   const startMaterial = new THREE.MeshBasicMaterial({ color: 'blue' });
    //   const endMaterial = new THREE.MeshBasicMaterial({ color: 'green' });
    //   eaves.forEach(([p1, p2]) => {
    //     const vs = new THREE.Vector3(p1.x, p1.z + 1, p1.y);
    //     const ve = new THREE.Vector3(p2.x, p2.z + 1, p2.y);
    //     const geometry = new THREE.BufferGeometry().setFromPoints([vs, ve]);
    //     const line = new THREE.Line(geometry, material);
    //     sceneRef.current.add(line);
    //     const startGeometry = new THREE.SphereGeometry(0.5, 15, 15);
    //     const startMarker = new THREE.Mesh(startGeometry, startMaterial);
    //     startMarker.position.set(vs.x, vs.y, vs.z);
    //     sceneRef.current.add(startMarker);
    //     const endGeometry = new THREE.SphereGeometry(0.5, 15, 15);
    //     const endMarker = new THREE.Mesh(endGeometry, endMaterial);
    //     endMarker.position.set(ve.x, ve.y, ve.z);
    //     sceneRef.current.add(endMarker);
    //   });
    // };

    // useEffect(() => {
    //   if (sceneRef.current) drawEaves();
    // }, [eaves]);

    const center = [cx / c, cy / c];

    const {
      moveStatus,
      panelData,
      panelPlacement,
      deletePanels,
      rotatePanels,
      movePanels,
      insertPanels,
      alignHorizontal,
      alignVertical,
      clearPanels,
    } = usePanelOperation({
      segments,
      panels,
      vertices3D,
      orgVertices,
      verticesType,
      eaves,
      pitches,
      eaveHeights,
      changeClockwise,
      inchePerUnit,
      scale,
      center,
      getSetbacks,
      readonly,
    });

    const { treeData } = useTreeOperation({
      trees,
      scale,
      center,
      footPerUnit,
    });

    const shader = useShader({
      canvasPool,
      imgSrc,
      dsmTexture,
      minDSM,
      maxDSM,
      displacementBias,
      center,
      scale,
      coordinates,
      resolution,
      ranges,
      materials,
      weather,
      readonly,
    });

    const {
      verticesToSegments,
      addShape,
      editShapes,
      deleteShape,
      deletePointFromShape,
      addPointToShape,
      setLineLabel,
    } = useDrawing(
      vertices,
      verticesType,
      segments,
      obstacles,
      trees,
      treeData,
      changeClockwise,
      getInchePerSceneUnit,
      scale,
      cx,
      cy,
      c
    );

    const updateRGBTexture = () => {
      resetRGBTexture();
      if (updateRGBTextureIsRunning === true) {
        setUpdateRGBTextureIsRunning(false);
      }
    };

    const { currentShadingType: shadingType, setShadingType } = shader;

    const changeTextureByMonth = () => {
      let shadingTextures;

      if (shadingType === 'TSRF')
        shadingTextures = materials.getTSRFTexture(month);
      else if (shadingType === 'TOF')
        shadingTextures = materials.getTOFTexture(month);
      else if (shadingType === 'Solar Access')
        shadingTextures = materials.getSolarAccessTexture(month);

      if (shadingTextures) {
        setCurrentTexture(shadingTextures[1], month);
      }
    };

    const uuidv4 = () =>
      'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
  
    const shiftLatLong = (coordinates, v, xzy=false) => {
      const [centerLat, centerLon] = coordinates;
      const [dxCm, dyCm, dzCm] = [v.x, xzy ? v.z : v.y, xzy ? v.y : v.z];
      const degPerMeterLat = 1 / 111111;  
      const degPerMeterLon = 1 / (111111 * Math.cos(centerLat * Math.PI / 180));
      const dxMeter = dxCm / 100;
      const dyMeter = dyCm / 100;
      const newLat = centerLat - (dyMeter * degPerMeterLat);
      const newLon = centerLon + (dxMeter * degPerMeterLon);
      const dzFeet = dzCm * 0.0328;
      return [newLon, newLat, dzFeet];
    };

    const downloadJSON = () => {
      const { width, height } = texture.image;

      const geoEncode = (poly, properties, shapeType) => {
        const coords = [poly.map((v) => {
          const pos = v.clone().multiplyScalar(resolution / scale).add(new THREE.Vector3(center[0]-(width/2), center[1]-(height/2), 0));
          return shiftLatLong(coordinates, pos)
        })];
        coords[0].push(coords[0][0]);
        if (shapeType == 'Line') shapeType = "LineString";
        return {
        "type": "Feature",
        "properties": properties,
        "geometry": {
          "coordinates": coords,
          "type": shapeType,
        }};
      };
      const setbacks = vertices.filter((poly) => poly !== null).map((poly, index) => getSetbacks(poly, index));

      const segmentsFeatures = vertices3D
        .filter((v, vi) => verticesType[vi] === 'roof')
        .map((poly, index) => geoEncode(poly, {
          id: uuidv4(),
          index: index,
          type: 'face',
          azimuth: segments[index].azimuth,
          pitch: segments[index].pitch,
          height: segments[index].eaveHeight
        }, 'Polygon'));

      const obstacleFeatures = vertices3D
        .filter((v, vi) => verticesType[vi] !== 'roof')
        .map((poly, index) => geoEncode(poly, {
          id: uuidv4(),
          type: 'protrusions',
          index: index,
          radius: obstacles[index].radius,
          height: obstacles[index].height,
          shape: obstacles[index].shape,
        }, obstacles[index].shape));

      const panelFeatures = panelData?.map((segmentPanels, index) => segmentPanels.points3D.map((pnl) => geoEncode(pnl, {
          id: uuidv4(),
          type: 'panel',
          index: index,
          azimuth: segmentPanels.azimuth,
          tilt: segmentPanels.tilt,
      }, 'Polygon'))).flat();

      const data = {
        id: lead.property.lead,
        site: {
          address: {
            addressOne: `${lead.property.street}`,
            addressTwo: null,
            city: lead.property.city,
            province: lead.property.state,
            postalCode: lead.property.zipcode,
          },
          coordinateSystemOrigin: {
            latitude: coordinates[0],
            longitude: coordinates[1],
          },
          // resolution value in feet
          resolution: resolution / 30.48,
        },
        geoJSON: {
            "type": "FeatureCollection",
            "features": [...segmentsFeatures, ...obstacleFeatures, ...panelFeatures],
        },
        annotations: annotations?.map((annot) => ({...annot, "position": shiftLatLong(coordinates, annot.position, true)})),
        setbacks: setbacks,
      };

      if (iframeCallback) {
        if (['RIGHTA', 'AERIAL'].includes(user?.company.id)) iframeCallback('3DJson', data);
      } else {
        const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(data))}`;
        const downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute('href', dataStr);
        downloadAnchorNode.setAttribute('download', 'data.json');
        document.body.appendChild(downloadAnchorNode);
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
      }
    };

    const downloadDXF = () => {
      if (readonly) return;

      const { width, height } = texture.image;
      // const json = shader.exportScene(sceneRef.current, lead.uid, {
      //   height,
      //   width,
      //   imgSrc,
      //   dsmSrc,
      //   coordinates,
      //   resolution,
      //   scale,
      //   minDSM,
      //   maxDSM,
      //   displacementBias,
      //   ranges,
      //   center,
      //   segments,
      //   obstacles,
      //   trees,
      //   treeData,
      //   panels,
      //   panelData,
      //   state: { orgVertices, vertices3D },
      // });
      // downloadObjectAsJson(JSON.stringify(json), 'scene');

      const state = {
        height,
        width,
        coordinates,
        resolution,
        scale,
        treeData,
        panelData,
      };

      const exportType = 'dxf'; // dwg
      const request = {
        uid: lead.uid,
        format: 'autocad',
        export_type: exportType,
        state: state,
      };

      exportDesignAPI
        .create(request)
        .then((response) => {
          displaySnack({
            variant: 'success',
            message: `Export to ${exportType} was executed successfully.`,
          });
          const dataStr = `data:image/x-${exportType};charset=utf-8,${encodeURIComponent(response)}`;
          const downloadAnchorNode = document.createElement('a');
          downloadAnchorNode.setAttribute('href', dataStr);
          downloadAnchorNode.setAttribute(
            'download',
            `model-${lead.uid}.${exportType}`
          );
          document.body.appendChild(downloadAnchorNode);
          downloadAnchorNode.click();
          downloadAnchorNode.remove();
        })
        .catch(() => {
          displaySnack({
            variant: 'danger',
            message: `Error in export to ${exportType}.`,
          });
        });
      // const d = new Drawing();
      // d.addLayer('roofs', Drawing.ACI.YELLOW, 'DOTTED');
      // d.setActiveLayer('roofs');
      // vertices.forEach((p, pi) => {
      //   for (let i = 0; i < p.length; i += 1) {
      //     const v1 = p[i];
      //     const v2 = p[(i + 1) % p.length];
      //     const { x: x1, y: y1 } = v1;
      //     const { x: x2, y: y2 } = v2;
      //     d.drawLine(x1, y1, x2, y2);
      //   }
      // });

      // d.setUnits('Decimeters');
      // d.drawText(10, 0, 10, 0, 'Hello World'); // draw text in the default layer named "0"
      // d.addLayer('l_green', Drawing.ACI.GREEN, 'CONTINUOUS');
      // d.setActiveLayer('l_green');
      // d.drawText(20, -70, 10, 0, 'go green!');
      // d.addLayer('l_yellow', Drawing.ACI.YELLOW, 'DOTTED')
      //   .setActiveLayer('l_yellow')
      //   .drawCircle(50, -30, 25);
      // const dataStr = `data:image/x-dxf;charset=utf-8,${encodeURIComponent(d.toDxfString())}`;
      // const downloadAnchorNode = document.createElement('a');
      // downloadAnchorNode.setAttribute('href', dataStr);
      // downloadAnchorNode.setAttribute('download', `model-${lead.uid}.dxf`);
      // document.body.appendChild(downloadAnchorNode);
      // downloadAnchorNode.click();
      // downloadAnchorNode.remove();
    };

    const getSimilarVertex = (pindex, vindex) => {
      const selected = [];
      const { x, y } = vertices3D[pindex][vindex];
      vertices3D.forEach((poly, pi) => {
        if (verticesType[pi] === 'roof') {
          poly.forEach((v, vi) => {
            if (v.x === x && v.y === y) selected.push(`${pi}-${vi}`);
          });
        }
      });
      return selected;
    };

    const addSelectedPoint = (pindex, vindex) => {
      const selected = getSimilarVertex(pindex, vindex);
      addToSelectedObjects(selected, 'roofVertices');
    };

    const removeSelectedPoint = (pindex, vindex) => {
      const selected = getSimilarVertex(pindex, vindex);
      removeFromSelectedObjects(selected, 'roofVertices');
    };

    const toggleView = (view, forceRemove = false, forceAdd = false) => {
      const viewNames = [
        'sun',
        'texture',
        'trees',
        'setbacks',
        'panels',
        'roofs',
        'shading',
        'dsm',
        'dsmhd',
        'dsmhouse',
        'move',
        'insert',
        'measurements',
      ];
      const removeView = (vw) => {
        const idx = views.indexOf(vw);
        if (idx >= 0) views.splice(idx, 1);
      };

      if ((views.includes(view) || forceRemove) && !forceAdd) {
        removeView(view);
        switch (view) {
          case 'shading':
            setCurrentTexture(rgbTexture, month);
            // materials.changeMaterials(sceneRef.current);
            break;
          case 'dsm':
            break;
          default:
            break;
        }
      } else {
        if (!views.includes(view)) views.push(view);
        switch (view) {
          case 'move':
            removeView('insert');
            break;
          case 'insert':
            removeView('move');
            break;
          case 'shading':
            changeTextureByMonth();
            break;
          case 'dsm':
            break;
          default:
            break;
        }
      }

      setViews([...views]);
    };

    const runOptimizer = (selectAll = false, optimizePosition = true) => {
      if (readonly) return;

      const oldState = {
        orgVertices,
        vertices,
        vertices3D,
        verticesType,
        eaves,
        pitches,
        azimuths,
        eaveHeights,
        UV,
        changeClockwise,
        ranges,
        parents,
      };

      // BEGIN for export to express.js api
      // const state = {
      //   scale, center, ranges, treeData, resolution, orgVertices, vertices, vertices3D, verticesType, eaves, pitches, azimuths, eaveHeights, UV, changeClockwise, parents,
      // };
      // const optimalPOA = weather.getOptPitchAndAzimuth();
      // const dsmParams = { minDSM, maxDSM, dsmOffset, displacementBias, dsmSrc };
      // const [positions, intensities, irradiances] = shader.exportSunPositionAndIntensity();
      // const data = { positions, intensities, irradiances, dsmParams, optimalPOA, treeData, state };
      // shader.downloadContent(JSON.stringify(data), "irradiance.json", "application/json");
      // END for export to express.js api

      optimizePlanes(segments, oldState, resolution, scale); // const allVertices =
      // const color = THREE.MathUtils.randInt(0, 0xffffff);
      // allVertices.forEach(([x, y, z]) => {
      //   const point = new THREE.Vector3(x, y, z);
      //   const startMarkerGeometry = new THREE.SphereGeometry(0.5, 15, 15);
      //   const startMarkerMaterial = new THREE.MeshBasicMaterial({ color });
      //   const marker = new THREE.Mesh(startMarkerGeometry, startMarkerMaterial);
      //   marker.position.set(point.x, point.z, point.y);
      //   sceneRef.current.add(marker);
      // });
      setSegmentParameters(segments);

      // const selectedRoof = selectAll ? segments.map((s, si) => si) : selectedObjects.roofSegments;
      // const selectedVertices = selectAll ? segments.map((s) => s.geometry.map((v, vi) => vi)) : selectedObjects.roofVertices;
      // // const eavesIndex = getEaveIndices(oldState);

      // // const indices = optimizeVertexPosition(segments, oldState, selectedRoof, selectedVertices, optimizePosition);
      // // indices.forEach((stack) => {
      // //   stack.forEach((points) => {
      // //     const color = THREE.MathUtils.randInt(0, 0xffffff);
      // //     points.forEach(([pi, vi]) => {
      // //       const point = vertices3D[pi][vi];
      // //       const startMarkerGeometry = new THREE.SphereGeometry(0.5, 15, 15);
      // //       const startMarkerMaterial = new THREE.MeshBasicMaterial({ color });
      // //       const marker = new THREE.Mesh(startMarkerGeometry, startMarkerMaterial);
      // //       marker.position.set(point.x, point.z, point.y);
      // //       sceneRef.current.add(marker);
      // //     });
      // //   });
      // // });
      // // return;

      // const [newOrgVertices, roofAttributes] = optimizeVertexPosition(segments, oldState, resolution, scale, selectedRoof, selectedVertices, optimizePosition);
      // const newSegmentsPosition = verticesToSegments(newOrgVertices);
      // setSegmentPosition(newSegmentsPosition, roofAttributes);
      if (iframeCallback) iframeCallback('Optimize', '');
    };

    const edgeDetection = (depthForEdgeDetection) => {
      if (readonly) return;
      const oldState = {
        orgVertices,
        vertices,
        vertices3D,
        verticesType,
        eaves,
        pitches,
        azimuths,
        eaveHeights,
        UV,
        changeClockwise,
        ranges,
        parents,
      };
      const { width, height } = texture?.image ?? { width: 0, height: 0 };
      initState(oldState, segments, scale, resolution, height, width);

      const roofVertices = orgVertices.filter(
        (p, pi) => verticesType[pi] === 'roof'
      );
      const borders = getBorders(oldState, roofVertices, true);

      changeEdgeTypes(segments);
      magnetizeVertices(segments);

      if (dsmLoadStatus === 'DSM.Loaded' && depthForEdgeDetection) {
        const offset = calculateAndSetOffset(
          borders,
          resolution,
          scale,
          center
        );
        const indicesMask = segmentIndexMask(
          canvasPool,
          oldState,
          segments,
          height,
          width
        );
        const baseDSM = minDSM + (displacementBias / 255) * (maxDSM - minDSM);
        detectEdgeTypes(
          segments,
          oldState,
          center,
          scale,
          resolution,
          updateStateFromSegments,
          getBorders,
          true,
          dsmRaster,
          baseDSM,
          offset,
          indicesMask
        );
      } else {
        detectEdgeTypes(
          segments,
          oldState,
          center,
          scale,
          resolution,
          updateStateFromSegments,
          getBorders,
          false
        );
      }

      changeEdgeTypes(segments);
      if (iframeCallback) iframeCallback('EdgeLabeling', '');
    };

    const calculateRoofShading = async (data) => {
      const state = {
        orgVertices,
        vertices,
        vertices3D,
        verticesType,
        eaves,
        pitches,
        azimuths,
        eaveHeights,
        UV,
        changeClockwise,
        ranges,
        parents,
      };

      const { width, height } = texture.image;
      segmentIndexMask(canvasPool, state, segments, height, width);
      shader.calculateAverageTextures(materials, shader, data, 1024, 1024);

      const shadingFactors = await shader.calculateShadingFactor(
        data,
        segments.length,
        width
      );

      setSegmentShadingFactors(shadingFactors);
      toolbar.current.progress(100);
      setMonth(0);
      materials.saveTextures({
        ranges,
        segments: segments.map((s) => ({ geometry: s.geometry })),
      });

      if (iframeCallback) iframeCallback('Shading', '');
    };

    const loadShading = (shadingName) => {
      const src = `${imgSrc.substr(0, imgSrc.indexOf('/leads/'))}${shadingName.replace('/storage', '')}`;
      materials.loadShadingJson(src, (json) => {
        setMonth(0);
        setTimeout(() => {
          changeTextureByMonth();
        }, 500);
        setSegmentShadingFactorsServerSide(json['segments']);
        toolbar.current.progress(100);
      });
    };

    const startServerSideShading = () => {
      if (readonly) return;

      setServerSideShadingIsRunning(true);

      materials.resetTSRFTextures();
      materials.resetTOFTextures();
      materials.resetSolarAccessTextures();
      changeTextureByMonth();

      const { width, height } = texture.image;
      const json = shader.exportScene(sceneRef.current, lead.uid, {
        height,
        width,
        imgSrc,
        dsmSrc,
        coordinates,
        resolution,
        scale,
        minDSM,
        maxDSM,
        displacementBias,
        ranges,
        center,
        segments,
        obstacles,
        trees,
        panels,
        state: { orgVertices },
      });
      // downloadObjectAsJson(JSON.stringify(json), 'scene');

      const request = {
        uid: lead.uid,
        // scene: JSON.stringify(json),
      };

      runShadingsAPI
        .create(request)
        .then((response) => {
          loadShading(response);
          if (!iframeCallback) {
            displaySnack({
              variant: 'success',
              message: 'Server-side shading was executed successfully.',
            });
          }
          if (iframeCallback) console.log('Server-side shading was executed successfully.');
          if (setShadingStatus) setShadingStatus(0);
          if (iframeCallback) iframeCallback('Shading', '');
          setServerSideShadingIsRunning(false);
        })
        .catch(() => {
          if (!iframeCallback) displaySnack({ variant: 'danger', message: 'Error in running Server-side shading.' });
          if (iframeCallback) console.log('Error in running server-side shading.');
          if (setShadingStatus) {
            let status = shadingStatus;
            if (status >= 0) status = -1;
            setShadingStatus(status - 1);
          }
          setServerSideShadingIsRunning(false);
        });
    };

    const startShading = async (type, m = 0, mask = null) => {
      if (readonly) return;
      if (weather.loadStatus !== 'Irradiance.Loaded') {
        displaySnack({
          variant: 'danger',
          message: 'Irradiance data was not loaded.',
        });
        return;
      }

      setShadingIsRunning(true);

      if (m === 0) {
        StorageData = {};

        materials.resetTSRFTextures();
        materials.resetTOFTextures();
        materials.resetSolarAccessTextures();
        changeTextureByMonth();

        shader.resetRenderer();
        DTSRFData = shader.shadeDiffuse(sceneRef.current, 'TSRF');
        const diffuseTSRFTexture = shader.dataToTexture(DTSRFData, 1024, 1024);

        DTOFData = shader.shadeDiffuse(sceneRef.current, 'TOF');
        const diffuseTOFTexture = shader.dataToTexture(DTOFData, 1024, 1024);

        materials.setDiffuseTextures(diffuseTSRFTexture, diffuseTOFTexture);
        mask = getMaskCanvas(segments);
      }

      if (
        shader &&
        m <= 12 &&
        (m === 0 || !toolbar.current?.isCanceledShading())
      ) {
        if (m > 0) {
          const TSRFShadeData = shader.shade(sceneRef.current, 'TSRF', m);
          const TOFShadeData = shader.shade(sceneRef.current, 'TOF', m);

          const [
            SAData,
            TSRFData,
            TOFData,
            sumSolarAcces,
            tsrfFloatData,
            tofFloatData,
            saFloatData,
          ] = shader.calculateSolarAccessData(
            TSRFShadeData,
            TOFShadeData,
            DTSRFData,
            DTOFData,
            m,
            1024,
            1024
          );

          if (sumSolarAcces < 5 && iframeCallback) {
            iframeCallback(
              'ShadingCalcError',
              'Shading can not be calculated on this device'
            );
            return;
          }

          StorageData[m] = [
            TSRFData,
            TOFData,
            SAData,
            tsrfFloatData,
            tofFloatData,
            saFloatData,
          ];

          const TSRFTexture = shader.dataToTexture(TSRFData, 1024, 1024);
          const TSRFGradientData = materials.gradiantData(
            TSRFData,
            1024,
            1024,
            shader.ranges
          );
          const TSRFGradientTexture = shader.dataToTexture(
            TSRFGradientData,
            1024,
            1024
          );

          const TOFTexture = shader.dataToTexture(TOFData, 1024, 1024);
          const TOFGradientData = materials.gradiantData(
            TOFData,
            1024,
            1024,
            shader.ranges
          );
          const TOFGradientTexture = shader.dataToTexture(
            TOFGradientData,
            1024,
            1024
          );

          const SATexture = shader.dataToTexture(SAData, 1024, 1024);
          const SAGradientData = materials.gradiantData(
            SAData,
            1024,
            1024,
            shader.ranges
          );
          const SAGradientTexture = shader.dataToTexture(
            SAGradientData,
            1024,
            1024
          );

          materials.setTSRFTextures(m, TSRFTexture, TSRFGradientTexture);
          materials.setTOFTextures(m, TOFTexture, TOFGradientTexture);
          materials.setSolarAccessTextures(m, SATexture, SAGradientTexture);

          if (shadingType === 'TSRF') setCurrentTexture(TSRFGradientTexture, m);
          else if (shadingType === 'TOF')
            setCurrentTexture(TOFGradientTexture, m);
          else if (shadingType === 'Solar Access')
            setCurrentTexture(SAGradientTexture, m);

          if (!toolbar.current.isCanceledShading())
            toolbar.current.progress((m / 13) * 100);
        } else {
          toolbar.current.progress(1);
        }

        if (m === 0) {
          materials.resetTSRFTextures();
          materials.resetTOFTextures();
          materials.resetSolarAccessTextures();
          changeTextureByMonth();
        }

        if (m < 12) {
          setMonth(m + 1);
          await sleep(200);
          await startShading(type, m + 1, mask);
        } else if (m === 12) {
          await calculateRoofShading(StorageData, mask);
        }
      }

      setShadingIsRunning(false);
    };

    const measure = async (callback) => {
      // alert('start measure');
      const oldState = {
        orgVertices,
        vertices,
        vertices3D,
        verticesType,
        eaves,
        pitches,
        azimuths,
        eaveHeights,
        UV,
        changeClockwise,
        ranges,
        parents,
      };
      // let tryCount = 50;
      // while (!texture.image && --tryCount > 0) {
      //   await new Promise((r) => setTimeout(r, 200));
      // }
      const { width = 1024, height = 1024 } = texture?.image ?? {};
      // alert(`${scale}, ${resolution}, ${height}, ${width}`);
      initState(oldState, segments, scale, resolution, height, width);
      // alert('measure initState');
      if (dsmRaster) {
        const dsmData = getDSMData();
        const offset = measureByDSM(
          canvasPool,
          oldState,
          segments,
          trees,
          dsmData
        );

        // points.forEach((pnts, ind) => {
        //   if (ind !== 4) return;
        //   const color = THREE.MathUtils.randInt(0, 0xffffff);
        //   pnts.forEach((point) => {
        //     const startMarkerGeometry = new THREE.SphereGeometry(0.5, 15, 15);
        //     const startMarkerMaterial = new THREE.MeshBasicMaterial({ color });
        //     const marker = new THREE.Mesh(startMarkerGeometry, startMarkerMaterial);
        //     marker.position.set(point[0], point[2], point[1]);
        //     sceneRef.current.add(marker);
        //   });
        // });
        // setSegmentParameters(segments);
        // return;

        changeOffset(offset);
      }
      setSegmentParameters(segments);
      if (callback) callback();
      if (iframeCallback) iframeCallback('Measure', '');
    };

    useLayoutEffect(() => {
      if (!isLoaded) {
        // const loadedRef = !!(container.current && sceneRef.current && controls.current);
        // if (loadedRef) {
        clearInterval(loadingTimeout);
        loadingTimeout = setInterval(
          () => updateLoadingStatus(null, false),
          1000
        );
        // }
      }
    });

    useEffect(() => {
      if (
        views.includes('shading') &&
        !toolbar.current.isRunningShading() &&
        materials
      ) {
        setTimeout(() => changeTextureByMonth(), 100);
      }
    }, [month, shadingType, isLoaded]);

    useEffect(() => {
      if (!readonly && !dsmRaster && designType === 'M')
        automaticAzimuthMode(true);
      else automaticAzimuthMode(false);
    }, [automaticAzimuthMode, designType, dsmRaster, isLoaded, readonly]);

    useEffect(() => {
      if (isLoaded && iframeCallback) {
        iframeCallback('Loaded', '');
      }
    }, [isLoaded]);

    useEffect(() => {
      if (accordion) {
        if (views.includes('panels')) toggleView('panels');
        if (views.includes('shading')) toggleView('shading');
        if (views.includes('setbacks')) toggleView('setbacks');
      }
      switch (accordion) {
        case 'segments':
          setSelectionMode('segment');
          break;
        case 'obstacles':
          break;
        case 'setback':
          setSelectionMode('setbacks');
          if (!views.includes('setbacks')) toggleView('setbacks');
          break;
        case 'shading':
          if (!views.includes('shading')) toggleView('shading');
          break;
        case 'design':
          if (!views.includes('panels')) toggleView('panels');
          if (!views.includes('setbacks')) toggleView('setbacks');
          break;
        case 'trees':
          break;
        case 'revise':
          setSelectionMode('panels');
          if (!views.includes('panels')) toggleView('panels');
          if (!views.includes('setbacks')) toggleView('setbacks');
          break;
        case 'annotations':
          setViewState('annotations');
          if (!iframeCallback) setMode('');
          break;
        default:
          break;
      }
    }, [accordion]);

    const segmentSelection = selectionMode === 'segment';
    const pointSelection = selectionMode === 'point';
    const lineSelection = selectionMode === 'line';
    const panelSelection = selectionMode === 'panels';
    const setbackSelection = selectionMode === 'setbacks';

    const clearAllSelection = (forceClear = false, allObjects = false) => {
      if (!clearSelection) return;
      if (forceClear) {
        clearSelection('roofSegments', true);
        return;
      }
      if (allObjects || segmentSelection) clearSelection('roofSegments');
      if (allObjects || pointSelection) clearSelection('roofVertices');
      if (allObjects || lineSelection) clearSelection('roofLines');
      if (allObjects || setbackSelection) clearSelection('fireSetbacks');
      if (allObjects || panelSelection) clearSelection('panels');
    };

    const showSunLight =
      views.includes('sun') && !toolbar.current?.isRunningShading();
    const showGround = views.includes('texture');
    const showTrees = views.includes('trees');
    const showSetbacks = views.includes('setbacks');
    const showPanels = views.includes('panels');
    const showRoofs = views.includes('roofs');
    const showShading = views.includes('shading');
    const showDSM = views.includes('dsm');
    const showDSMHouse = views.includes('dsmhouse');
    const showDSMHD = views.includes('dsmhd');
    const showMeasurements = views.includes('measurements');
    const showAnnotation = views.includes('annotations');
    const moveSelection = panelSelection && views.includes('move');
    const insertObject = panelSelection && views.includes('insert');

    useEffect(() => {
      setTimeout(() => {
        if (texture) texture.needsUpdate = true;
        if (dsmTexture) dsmTexture.needsUpdate = true;
        materials.changeMaterials(sceneRef.current);
      }, 100);
    }, [showSunLight, showDSM, showShading, texture, dsmTexture, materials]);

    useEffect(() => {
      if (!canvas.current) return;
      switch (mode) {
        case 'select':
          canvas.current.style.cursor = 'default';
          break;
        case 'draw':
          canvas.current.style.cursor = 'crosshair';
          break;
        case 'edit':
        case 'delete':
        case 'label':
          canvas.current.style.cursor = 'pointer';
          break;
        default:
          break;
      }
    }, [mode, canvas]);

    useEffect(() => {
      if (accordion === 'obstacles' || accordion === 'trees')
        setDrawingCapabilities([]);
      else if (accordion === 'segments')
        setDrawingCapabilities(['magnetize', 'snap45', 'snap90', 'snap180']);
    }, [accordion]);

    useEffect(() => {
      if (viewMode === '3D' && ["draw", "edit", "delete", "label"].includes(mode)) setViewMode('2D');
    }, [mode, setViewMode]);

    useEffect(() => {
      if (viewMode === '3D' && ["draw", "edit", "delete", "label"].includes(mode)) setMode('select');
    }, [viewMode, setMode]);

    useEffect(() => {
      if (readonly && !iframeCallback) toggleView('shading');
    }, []);

    const labels = useMemo(
      () =>
        segments
          .map((s) => s.lines)
          .map((ls, si) => {
            const lc = ls.length;
            const el = !changeClockwise[si]
              ? ls.map((l, li) => ls[(li - 1 + lc) % lc])
              : ls.map((l, li) => ls[(li + 1) % lc]).reverse();
            return el;
          }),
      [segments, changeClockwise]
    );

    const { width: gwidth, height: gheight } = texture?.image ?? {
      width: 1,
      height: 1,
    };

    // useLayoutEffect(() => {
    //   if (isLoaded) {
    //     container.current.style.display = visible ? 'block' : 'none';
    //   }
    // });

    const getAnualPanelsShadings = async () =>
      await materials.getAnualPanelsShadings(segments, panels, gwidth, gheight);
    const exportToJPG = (zoom = null, lookPos = null) =>
      exportRef.current.exportToJPG(zoom, lookPos);
    const exportThumbnail = () => exportRef.current.exportThumbnail();

    const zoomCamera = (zoomFactor) => {
      if (sceneObjRef.current) {
        const camera = sceneObjRef.current.getCamera();
        if (viewMode === '3D') {
          if (zoomFactor !== 0) {
            camera.position.x *= 1 / zoomFactor;
            camera.position.y *= 1 / zoomFactor;
            camera.position.z *= 1 / zoomFactor;
          } else {
            camera.position.x = 0;
            camera.position.y = 100;
            camera.position.z = 200;
          }
          camera.updateProjectionMatrix();
        } else {
          if (zoomFactor !== 0) camera.zoom *= zoomFactor;
          else camera.zoom = 3;
          camera.updateProjectionMatrix();
        }
      }
    };

    const switchAnnotation = (newViewState = null) => {
      if (newViewState != null) setViewState(newViewState);
      else {
        if (viewState == 'annotations') setViewState('');
        else setViewState('annotations');
      }
    };

    // const setAnnotations = (annots) => {
    //   setAnnots(annots);
    // };

    const getAnnotations = () => {
      return annotations;
    };

    useImperativeHandle(ref, () => ({
      isLoaded,
      viewState,
      annotations,
      panelPlacement,
      deletePanels,
      rotatePanels,
      toggleView,
      startShading,
      updateRGBTexture,
      startServerSideShading,
      runOptimizer,
      edgeDetection,
      measure,
      downloadJSON,
      downloadDXF,
      clearPanels,
      getOptPitchAndAzimuth,
      getAnualPanelsShadings,
      changeOffset,
      exportToJPG,
      exportThumbnail,
      zoomCamera,
      setViewMode,
      switchAnnotation,
      setAnnotations,
      getAnnotations,
    }));

    return (
      <>
        <DesignContext.Consumer>
          {(value) => {
            const newValue = {
              ...value,
              segments,
              obstacles,
              trees,
              imgSrc,
              rgbTexture,
              texture,
              dsmTexture,
              orgDSMTexture,
              selectedTexture,
              minDSM,
              maxDSM,
              dsmOffset,
              displacementBias,
              segmentSelection,
              pointSelection,
              lineSelection,
              panelSelection,
              setbackSelection,
              showSunLight,
              showGround,
              showTrees,
              showSetbacks,
              showPanels,
              showRoofs,
              showShading,
              showDSM,
              showDSMHD,
              showDSMHouse,
              showMeasurements,
              showAnnotation,
              moveSelection,
              insertObject,
              center: [cx / c, cy / c],
              scale,
              month,
              shadingType,
              weather,
              materials,
              visible,
              moveStatus,
              isLoaded,
              loadingTitle,
              readonly,
              viewMode,
              resolution,
              selectionMode,
              setSelectionMode,
              selectedObjects,
              removeFromSelectedObjects,
              addToSelectedObjects,
              clearSelection,
              deleteSelectedObjects,
              setHeight,
              setPitch,
              setSetback,
              setSegmentPosition,
              changeEdgeTypes,
              moveRoofVertex,
              coordinates,
              setSegmentShadingFactors,
              accordion,
              setShadingTexture,
              shadingStatus,
              setShadingStatus,
              setSegmentParameters,
              optPOA,
              automaticAzimuthMode,
              orgVertices,
              vertices,
              vertices3D,
              verticesType,
              eaves,
              pitches,
              azimuths,
              eaveHeights,
              UV,
              changeClockwise,
              ranges,
              parents,
              cx,
              cy,
              c,
              inchePerUnit,
              footPerUnit,
              updateEavesByAzimuth,
              panelData,
              gwidth,
              gheight,
              canvas,
              sceneRef,
              treeData,
              controls,
              labels,
              drawingCapabilities,
              viewerMode,
              viewState,
              annotations,
              iframeCallback,
              designFrame,
              getFootPerSceneUnit,
              getInchePerSceneUnit,
              getSceneUnitPerFoot,
              clearAllSelection,
              runOptimizer,
              panelPlacement,
              toggleView,
              deletePanels,
              rotatePanels,
              alignHorizontal,
              alignVertical,
              downloadJSON,
              downloadDXF,
              startShading,
              setMonth,
              setShadingType,
              setViewMode,
              loadShading,
              getSetbacks,
              updateStateFromSegments,
              magnetizeVertices,
              getBorders,
              addSelectedPoint,
              removeSelectedPoint,
              insertPanels,
              movePanels,
              addShape,
              editShapes,
              deleteShape,
              deletePointFromShape,
              addPointToShape,
              setLineLabel,
              setDrawingCapabilities,
              setMode,
              zoomCamera,
              switchAnnotation,
              setAnnotations,
              setViewState,
            };

            return (
              <DesignContext.Provider value={newValue}>
                <Toolbar ref={toolbar} />
                <div
                  ref={container}
                  className={`mx-auto ${visible ? 'position-relative container3d' : 'position-absolute container3d-invisible'} canvas3d`}
                >
                  <Canvas
                    orthographic={viewMode === '2D'}
                    shadows
                    shadowMap={{ type: THREE.VSMShadowMap }}
                    ref={canvas}
                    pixelRatio={window.devicePixelRatio}
                    camera={
                      viewMode === '2D'
                        ? { position: [0, 100, 0], up: [0, 1, 0], zoom: 3 }
                        : { position: [0, 100, 200], fov: 60, far: 1024 }
                    }
                    onCreated={({ gl, scene }) => {
                      gl.setClearColor('#f0f0f0');
                      gl.shadowMap.enabled = true;
                      sceneRef.current = scene;
                    }}
                  >
                    <Scene data={newValue} ref={sceneObjRef} />
                    <ExportWrapper ref={exportRef} sceneObjRef={sceneObjRef} />
                  </Canvas>
                </div>
              </DesignContext.Provider>
            );
          }}
        </DesignContext.Consumer>
      </>
    );
  }
);

Canvas3D.propTypes = {
  imgSrc: PropTypes.string,
  segments: PropTypes.array,
  obstacles: PropTypes.array,
  trees: PropTypes.array,
  panels: PropTypes.array,
};

export default Canvas3D;
