import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import Delete from 'components/Delete';
import { obstaclePropType } from '../props';

function Obstacles({ obstacles, tool, toolHandler, deleteShapeHandler, obstacleHeightHandler, obstacleRadiusHandler, obstacleSetbackHandler }) {
  return (
    <div>
      <Row className='px-3'>
        {[['Polygon','Polygon'], ['Point','Point'], ['Keepout','Line']].map(([shapename, shape]) => (
          <Col key={shape} className='px-1'>
            <Button size="sm" className="w-100" onClick={() => toolHandler(shape)} style={{ color: tool === shape ? 'white' : 'gray' }}>
              {shapename}
            </Button>
          </Col>
        ))}
      </Row>
      <Row className="my-2 font-weight-bold">
        <Col xs={3}>#&nbsp;&nbsp;&nbsp;&nbsp;Shape</Col>
        <Col xs={3}>Radius</Col>
        <Col xs={3}>Height</Col>
        <Col xs={3}>Setback</Col>
      </Row>
      {obstacles.map(({ id, shape, radius = 5, height = 12, setback = 0 }, index) => (
        <div class="d-flex">
          <Row key={id} style={{ height: 34 }}>
            <Col xs={3}>{index + 1}&nbsp;&nbsp;&nbsp;&nbsp;{shape}</Col>
            <Col xs={3}>
              <InputGroup size="sm">
                <Form.Control
                  size="sm"
                  type="number"
                  placeholder={shape === 'Polygon' ? 'N/A' : 'Radius'}
                  value={shape === 'Polygon' ? 'N/A' : radius}
                  disabled={shape === 'Polygon'}
                  onChange={(e) => { obstacleRadiusHandler(index, e.target.value); }}
                />
                <InputGroup.Append>
                  <InputGroup.Text>in</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Col>
            <Col xs={3}>
              <InputGroup size="sm">
                <Form.Control
                  size="sm"
                  type="number"
                  placeholder="Height"
                  value={height}
                  onChange={(e) => { obstacleHeightHandler(index, e.target.value); }}
                />
                <InputGroup.Append>
                  <InputGroup.Text>in</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Col>
            <Col xs={3}>
              <InputGroup size="sm">
                <Form.Control
                  size="sm"
                  type="number"
                  placeholder="Setback"
                  value={setback}
                  onChange={(e) => { obstacleSetbackHandler(index, e.target.value); }}
                />
                <InputGroup.Append>
                  <InputGroup.Text>in</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Col>
          </Row>
          <div class="py-1 px-2 flex">
            <Delete id={id} onClick={() => deleteShapeHandler(id)} />
          </div>
        </div>
      ))}
    </div>
  );
}

Obstacles.propTypes = {
  obstacles: obstaclePropType.isRequired,
  toolHandler: PropTypes.func.isRequired,
  obstacleRadiusHandler: PropTypes.func.isRequired,
  obstacleHeightHandler: PropTypes.func.isRequired,
  deleteShapeHandler: PropTypes.func.isRequired,
};

export default Obstacles;
