import React from 'react';
import PropTypes from 'prop-types';

export const Error = ({ visible, errors }) => {
  if (visible) return (<h1 style={{ Color: 'red' }}>{errors}</h1>);
  return null;
};

Error.defaultProps = {
  errors: null,
};

Error.propTypes = {
  visible: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
};

export default Error;
