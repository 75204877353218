import Tool from './tool';

export const POLYGON = 'Polygon';

const polygon = { ...Tool };

polygon.name = 'Polygon';

polygon.onMouseDown = function onMouseDown(start, options, callback) {
  if (!this.state) {
    this.state = {
      ...this.state,
      initialCircle: this.createStartPoint(start, 3),
      startPoint: start,
    };
  }
  if (!this.state.pathData) {
    this.state.pathData = [];
  }
  if (
    this.ctx.isPointInPath(this.state.initialCircle, start.x, start.y)
    && this.state.canvasData
    && this.state.canvasData.length > 0
    && this.state.pathData
    && this.state.pathData.length >= 3
  ) {
    // the idea is to reconstructed the path using this.state.pathData here
    // so we can fill it out.
    this.resetState();
    // This callback is just to
    // sinalize we finish to draw and
    // we should create another polygon ID
    // to track the data
    callback();
    return;
  }
  this.setInitSettings({ start, options });
};

polygon.onTouchStart = function onTouchStart(start, options, callback) {
  if (!this.state) {
    this.state = {
      ...this.state,
      initialCircle: this.createStartPoint(start, 3),
      startPoint: start,
    };
  }
  if (!this.state.pathData) {
    this.state.pathData = [];
  }
  if (
    this.ctx.isPointInPath(this.state.initialCircle, start.x, start.y)
    && this.state.canvasData
    && this.state.canvasData.length > 0
    && this.state.pathData
    && this.state.pathData.length >= 3
  ) {
    // the idea is to reconstructed the path using this.state.pathData here
    // so we can fill it out.
    this.resetState();
    // This callback is just to
    // sinalize we finish to draw and
    // we should create another polygon ID
    // to track the data
    callback();
    return;
  }
  this.setInitSettings({ start, options });
};

polygon.onMouseMove = function onMouseMove(position) {
  if (!this.state || !this.state.started) return;
  this.ctx.putImageData(this.imageData, 0, 0);
  this.draw(this.state.start, position);

  // check canvas data to ensure that algorithm started
  // to be drawn
  // if (this.ctx.isPointInPath(this.state.initialCircle, position.x, position.y)
  //     && this.state.canvasData.length > 0 && this.state.pathData.length >= 3) {
  //         // the idea is to reconstructed the path using this.state.pathData here
  //         // so we can fill it out.
  //         this.resetState();
  //         // This callback is just to
  //         // finalize we finish to draw and
  //         // we should create another polygon ID
  //         // to track the data
  //         callback();
  // }
};

polygon.onTouchMove = function onTouchMove(position) {
  if (!this.state || !this.state.started) return;
  this.ctx.putImageData(this.imageData, 0, 0);
  this.draw(this.state.start, position);
};

polygon.onMouseUp = function onMouseUp(position) {
  if (!this.state) return null;
  const data = [this.state.start.x, this.state.start.y];
  this.state.pathData.push(data);
  this.state.canvasData.push(data);
  const { start } = this.state;
  const { options } = this.state;
  return {
    data,
    canvas: {
      start,
      end: position,
      options,
    },
  };
};

polygon.onTouchEnd = function onTouchEnd(position) {
  if (!this.state) return null;
  const data = [this.state.start.x, this.state.start.y];
  this.state.pathData.push(data);
  this.state.canvasData.push(data);
  const { start } = this.state;
  const { options } = this.state;
  return {
    data,
    canvas: {
      start,
      end: position,
      options,
    },
  };
};

export default polygon;
