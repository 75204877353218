import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from '@reactchartjs/react-chart.js';

function SolarPowerChart({ annualProduction, annualUsage }) {
  const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Production',
        data: annualProduction,
        backgroundColor: 'black',
        borderWidth: 1,
        order: 2,
      },
      {
        label: 'Usage',
        data: annualUsage,
        backgroundColor: '#7F7F7F80',
        type: 'line',
        order: 1,
      },
    ],
  };

  const options = {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: 'Months',
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: 'Output [kWh/kW]',
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        title: (item) => item[0].label,
        label: (item) => `${item.yLabel.toFixed(2)} kWh/kW`,
      },
    },
  };
  return (
    <div id="solarGraphChart">
      <Bar redraw={false} data={data} options={options} height={169} />
    </div>
  );
}

SolarPowerChart.propTypes = {
  annualProduction: PropTypes.arrayOf(PropTypes.number).isRequired,
  annualUsage: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default SolarPowerChart;
