import Tool from './tool';

export const POINT = 'Point';

const point = { ...Tool };

point.name = 'Point';

point.onMouseDown = function onMouseDown(start, options) {
  if (!this.state) {
    this.state = {
      ...this.state,
      initialCircle: this.createStartPoint(start, 3),
      startPoint: start,
    };
  }
  if (!this.state.pathData) {
    this.state.pathData = [];
  }
  this.ctx.fillStyle = options.color;
  this.setInitSettings({ start, options });
};

point.onTouchStart = function onTouchStart(start, options) {
  if (!this.state) {
    this.state = {
      ...this.state,
      initialCircle: this.createStartPoint(start, 3),
      startPoint: start,
    };
  }
  if (!this.state.pathData) {
    this.state.pathData = [];
  }
  this.ctx.fillStyle = options.color;
  this.setInitSettings({ start, options });
};

point.onMouseMove = () => {}; // (position, callback) => {};

point.onTouchMove = () => {}; // (position, callback) => {};

point.onMouseUp = function onMouseUp(position, callback) {
  if (!this.state) return null;
  callback();
  const data = [this.state.start.x, this.state.start.y];
  this.state.pathData.push(data);
  this.state.canvasData.push(data);
  const { start } = this.state;
  const { options } = this.state;
  return {
    data,
    canvas: {
      start,
      end: position,
      options,
    },
  };
};

point.onTouchEnd = function onTouchEnd(position, callback) {
  if (!this.state) return null;
  callback();
  const data = [this.state.start.x, this.state.start.y];
  this.state.pathData.push(data);
  this.state.canvasData.push(data);
  const { start } = this.state;
  const { options } = this.state;
  return {
    data,
    canvas: {
      start,
      end: position,
      options,
    },
  };
};

export default point;
