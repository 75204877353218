import { useThree } from '@react-three/fiber';
import * as THREE from 'three';
import {
  useState, useContext, useRef, useEffect, useMemo, forwardRef, useImperativeHandle,
} from 'react';

export const ExportWrapper = forwardRef((props, ref, sceneObjRef) => {
  const { gl, scene, camera } = useThree();

  const getDepthMaterial = (near, far, depthTexture) => {
    return new THREE.ShaderMaterial({
      vertexShader: `
        varying vec2 vUv;

        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        #include <packing>

        varying vec2 vUv;
        uniform sampler2D tDepth;
        uniform float cameraNear;
        uniform float cameraFar;

        float readDepth( sampler2D depthSampler, vec2 coord ) {
          float fragCoordZ = texture2D( depthSampler, coord ).x;
          return fragCoordZ;
          // float viewZ = perspectiveDepthToViewZ( fragCoordZ, cameraNear, cameraFar );
          // return viewZToOrthographicDepth( viewZ, cameraNear, cameraFar );
        }

        void main() {
          float depth = readDepth( tDepth, vUv );
          gl_FragColor.rgb = 1.0 - vec3( depth );
          gl_FragColor.a = 1.0;
        }
      `,
      uniforms: {
        cameraNear: { value: near },
        cameraFar: { value: far },
        tDepth: { value: depthTexture }
      }
    });
  };

  const setupScene = (scene, depthMaterial) => {
    const validNames = ["trees", "tree", "treeTrunk", "treeBranch", "roof", "roofPlane"];
    scene.background = new THREE.Color(0x000000);
    if (!depthMaterial) depthMaterial = new THREE.MeshBasicMaterial({ color: 0x000000 });
    scene.traverse(
      (element) => {
        if (validNames.includes(element.name)) {
          if (element.isMesh) {
            let targetMaterial = depthMaterial;
            targetMaterial.side = THREE.DoubleSide;
            element.material = targetMaterial;
            element.material.needsUpdate = true
          }
          element.visible = true;
        } else if (element.name != "") {
          element.visible = false;
        }
      }
    );
  };

  const setupRenderTarget = (renderer) => {
    const format = THREE.DepthFormat;
    const type = THREE.UnsignedIntType;
    const target = new THREE.WebGLRenderTarget(1024, 1024);
    target.texture.minFilter = THREE.NearestFilter;
    target.texture.magFilter = THREE.NearestFilter;
    target.stencilBuffer = false;
    target.depthTexture = new THREE.DepthTexture();
    target.depthTexture.format = format;
    target.depthTexture.type = type;
    const dpr = renderer.getPixelRatio();
    target.setSize(1024 * dpr, 1024 * dpr);
    return target;
  };

  const setupPost = (postMaterial) => {
    const postCamera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1);
    const postPlane = new THREE.PlaneGeometry(2, 2);
    const postQuad = new THREE.Mesh(postPlane, postMaterial);
    const postScene = new THREE.Scene();
    postScene.add(postQuad);
    return [postScene, postCamera];
  }

  const setupCamera = (scale, center, maxH, camera, forDepth=false) => {
    let [cx, cy] = center;
    cx = (1024 / 2) - cx;
    cy = (1024 / 2) - cy;
    camera.position.set(cx * scale, maxH, cy * scale);
    camera.up.set(0, 1, 0);
    camera.lookAt(cx * scale, 0, cy * scale);
    camera.zoom = 1.0 / scale;
    camera.left = 1024 / -2;
    camera.right = 1024 / 2;
    const sy = forDepth ? -1 : 1;
    camera.top = 1024 / 2 * sy;
    camera.bottom = 1024 / -2 * sy;
    camera.near = 0;
    camera.far = maxH * (forDepth ? 1 : 2);
    camera.updateProjectionMatrix();
  };

  const renderToJPG = async (zoom=null, lookPos=null) => {
    gl.domElement.getContext('webgl', { preserveDrawingBuffer: true });
    gl.render(scene, camera);

    // make a copy of scene & camera to restore after export
    const oldCam = camera.clone();
    const oldScene = scene.clone();

    // get the initial size of render
    let initSize = new THREE.Vector2();
    gl.getSize(initSize);

    let canvas = gl.domElement;

    // set the export size
    gl.setSize(512, 512);

    // configure camera for the image export
    camera.position.set(0, 100, 0);
    camera.up.set(0, 1, 0);
    
    if (lookPos) {
      camera.lookAt(lookPos[0], lookPos[1], lookPos[2]);
    } else {
      camera.lookAt(0, 0, 0);
    }

    if (zoom) {
      camera.zoom = zoom;
    } else {
      camera.zoom = 3;
    }
    
    camera.left = 512 / -2;
    camera.right = 512 / 2;
    camera.top = 512 / 2;
    camera.bottom = 512 / -2;
    camera.updateProjectionMatrix();

    gl.render(scene, camera);

    const blob = await new Promise((resolve) => canvas.toBlob(resolve, 'image/jpg', 1.0));
    const url = URL.createObjectURL(blob);
    // const a = document.createElement('a');
    // a.href = url;
    // a.download = 'canvas.jpg';
    // a.click();
    // console.log('function is actually being used');

    // revert size/scene/camera of render to initial
    gl.setSize(initSize.x, initSize.y);
    gl.render(oldScene, oldCam);
    camera.updateProjectionMatrix();
    gl.domElement.getContext('webgl', { preserveDrawingBuffer: false });
    return { url, width: canvas.clientWidth, height: canvas.clientHeight };
  };

  const renderThumbnail = async () => {
    gl.domElement.getContext('webgl', { preserveDrawingBuffer: true });
    let canvas = gl.domElement;

    const currentCamera = props.sceneObjRef.current.getCamera()

    gl.render(scene, currentCamera);

    const base64 = canvas.toDataURL('image/png');
  
    gl.domElement.getContext('webgl', { preserveDrawingBuffer: false });
    return { base64, width: canvas.clientWidth, height: canvas.clientHeight };
  };

  useImperativeHandle(ref, () => ({
    exportToJPG(zoom=null, lookPos=null) {
      return renderToJPG(zoom, lookPos);
    },
    exportThumbnail() {
      return renderThumbnail();
    }
  }), []);

  return null;
});

export default ExportWrapper;
