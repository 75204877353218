import React from 'react';
import ReactLoading from 'react-loading';
import PropTypes from 'prop-types';
import style from './loading.module.css';

const Loading = ({ title }) => (
  <>
    <div className="d-flex justify-content-center py-5">
      <div className={style.label}>
        {title}
      </div>
      <ReactLoading color="black" type="spin" height="20%" width="20%" />
    </div>
  </>
);

export const AerialyticSpinner = () => (
  <div className={style.spinner}>
    <img src="/logo_square.png" width="150px" alt="loading" />
  </div>
);

Loading.defaultProps = {
  title: '',
};

Loading.propTypes = {
  title: PropTypes.string,
};

export default Loading;
