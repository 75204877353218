import React, {
  useState, useEffect, useLayoutEffect, useMemo, forwardRef, useImperativeHandle, useContext,
} from 'react';
import * as THREE from 'three';

import { LeadContext } from 'Leads/Details/hooks/context';
import { gray2gradient, downloadCanvas, pointInConcavePolygon } from '../algorithms/geometry';
import Materials from '../algorithms/materials';
import { tic, toc } from '../algorithms/utils';

const cvSelected = document.createElement('canvas');

const useMaterials = ({
  canvasPool, imgSrc, dsmTexture, month, setShadingTexture, sceneRef, fillPolygon, updateLoadingStatus, proposalShadings, iframeCallback,
}) => {
  const { lead, user, saveShadingsAPI } = useContext(LeadContext);

  const handleSaveShadings = async (gradients) => {
    const { uid } = lead;
    const blob = new Blob([JSON.stringify(gradients)]);
    const formData = new FormData();
    formData.append('shadings', blob, 'shadings.json');
    formData.append('uid', uid);
    saveShadingsAPI.create(formData).then(() => {
      console.log('Shading file uploading completed!');
      if (iframeCallback) {
        iframeCallback('Shading', 'Shading file upload completed');
      }
    });
    console.log('Shading file uploading started!');
  };

  const [{ rgbLoadStatus, shadingLoadStatus }, setLoadStatus] = useState({ rgbLoadStatus: 'RGB.Loading', shadingLoadStatus: 'Shading.Loading' });

  const [rgbTexture, setRGBTexture] = useState();
  const [texture, setTexture] = useState();
  const [selectedTexture, setSelectedTexture] = useState();
  const [materials] = useState(new Materials(canvasPool));

  useEffect(() => {
    materials.canvasPool = canvasPool;
    materials.fillPolygon = fillPolygon;
  }, [materials, canvasPool, fillPolygon]);

  const changeLoadStatus = (status) => {
    if (status.startsWith('RGB.')) setLoadStatus({ rgbLoadStatus: status, shadingLoadStatus });
    else if (status.startsWith('Shading.')) setLoadStatus({ rgbLoadStatus, shadingLoadStatus: status });
    updateLoadingStatus(status);
  };

  const changeMaterials = (scene) => {
    if (scene) {
      scene.traverse((node) => { if (node.material) { node.material.needsUpdate = true; } });
    }
  };

  const getSelectedTexture = (currentTexture) => {
    if (!currentTexture || !currentTexture.image || typeof(currentTexture.image) == 'object') return null;
    const sz = currentTexture.image.width;
    cvSelected.width = currentTexture.image.width;
    cvSelected.height = currentTexture.image.height;
    const ctx = cvSelected.getContext('2d');

    ctx.clearRect(0, 0, sz, sz);
    ctx.drawImage(currentTexture.image, 0, 0, cvSelected.width, cvSelected.height);

    const spacing = 6;
    const radius = 0.5;
    for (let y = 0; y < cvSelected.height; y += spacing) {
      for (let x = 0; x < cvSelected.width; x += spacing) {
        ctx.fillStyle = 'white';
        ctx.beginPath();
        ctx.arc(x, y, radius, 0, Math.PI * 2);
        ctx.fill();
      }
    }

    const txSelectedRoof = new THREE.CanvasTexture(cvSelected);
    txSelectedRoof.wrapS = txSelectedRoof.wrapT = THREE.RepeatWrapping;
    txSelectedRoof.repeat.set(1, 1);
    txSelectedRoof.needsUpdate = true;

    return txSelectedRoof;
  };

  const setCurrentTexture = (currentTexture, m = -1) => {
    setTexture(currentTexture);
    setSelectedTexture(getSelectedTexture(currentTexture));
    if (m !== -1) setShadingTexture(currentTexture, m);
    changeMaterials(sceneRef.current);
  };

  useEffect(() => {
    if (dsmTexture) dsmTexture.needsUpdate = true;
    changeMaterials(sceneRef.current);
  }, [dsmTexture, sceneRef]);

  const saveTextures = async (info, StorageData, DTSRFData, DTOFData) => {
    const data = materials.saveTextures(info, StorageData, DTSRFData, DTOFData);
    await handleSaveShadings(data);
  };

  const loadShadingJson = (src, callback = null) => {
    if (src) {
      fetch(`${src}?${Math.random() * 100000}`)
        .then((res) => res.json())
        .then(async (json) => {
          try {
            const { diffuse } = json;
            if (!diffuse) throw Error();
            await materials.loadTextures(json);
            if (callback) callback(json);
            changeLoadStatus('Shading.Loaded');
          } catch {
            changeLoadStatus('Shading.Failed');
          }
        })
        .catch(() => {
          changeLoadStatus('Shading.Failed');
        });
    } else {
      changeLoadStatus('Shading.None');
    }
  };

  useLayoutEffect(() => {
    if (!imgSrc) return;

    try {
      new THREE.TextureLoader().load(imgSrc, (tx) => {
        setRGBTexture(tx);
        setCurrentTexture(tx);
        changeLoadStatus('RGB.Loaded');
      });
    } catch { console.log('error in load texture'); changeLoadStatus('RGB.Failed'); }

    const scr = proposalShadings; // lead.property.shading_images;
    try {
      setTimeout(() => loadShadingJson(scr), 3000);
    } catch (err) {
      console.log(err);
    }
  }, [imgSrc]);

  const black = useMemo(() => (
    <meshStandardMaterial
      attach="material"
      color="rgb(0,0,0)"
      side={THREE.DoubleSide}
      blending={THREE.AdditiveBlending}
      castShadow
      receiveShadow
    />
  ), []);

  const groundSun = useMemo(() => (
    <meshStandardMaterial
      attach="material"
      color="#ffffff"
      wireframe={false}
      side={THREE.DoubleSide}
      map={texture}
      castShadow
      receiveShadow
    />
  ), [texture]);

  const ground = useMemo(() => (
    <meshBasicMaterial
      attach="material"
      color="#ffffff"
      wireframe={false}
      side={THREE.DoubleSide}
      map={texture}
    />
  ), [texture]);

  const groundDispSun = useMemo(() => (
    <meshStandardMaterial
      attach="material"
      color="#ffffff"
      wireframe={false}
      side={THREE.DoubleSide}
      map={texture}
      displacementMap={dsmTexture}
      displacementScale={100}
      castShadow
      receiveShadow
    />
  ), [texture, dsmTexture]);

  const groundDisp = useMemo(() => (
    <meshStandardMaterial
      attach="material"
      color="#000000"
      wireframe={false}
      side={THREE.DoubleSide}
      displacementMap={dsmTexture}
      displacementScale={100}
      emissive="#ffffff"
      emissiveMap={texture}
      metalness={1}
      roughness={1}
    />
  ), [texture, dsmTexture]);

  return ({
    rgbTexture,
    setRGBTexture,
    texture,
    selectedTexture,
    setTexture,
    setCurrentTexture,
    changeMaterials,
    getAnualPanelsShadings: materials.getAnualPanelsShadings,

    resetRGBTexture: () => {
      let randInt = Math.random();
      try {
        new THREE.TextureLoader().load(`${imgSrc}?${randInt}`, (tx) => {
          setRGBTexture(tx);
          setCurrentTexture(tx);
          changeLoadStatus('RGB.Loaded');
        });
      } catch { console.log('error in load texture'); changeLoadStatus('RGB.Failed'); }
    },

    resetTSRFTextures: () => { for (let i = 0; i <= 12; i += 1) materials.clean(i, 'TSRF'); },
    setTSRFTextures: (m, graySA, gradientSA) => {
      materials.clean(m, 'TSRF');
      materials.TSRFTextures[m] = [graySA, gradientSA];
    },
    getTSRFTexture: (m) => (m in materials.TSRFTextures ? materials.TSRFTextures[m] : null),

    resetTOFTextures: () => { for (let i = 0; i <= 12; i += 1) materials.clean(i, 'TOF'); },
    setTOFTextures: (m, grayTOF, gradientTOF) => {
      materials.clean(m, 'TOF');
      materials.TOFTextures[m] = [grayTOF, gradientTOF];
    },
    getTOFTexture: (m) => (m in materials.TOFTextures ? materials.TOFTextures[m] : null),

    resetSolarAccessTextures: () => {
      for (let i = 0; i <= 12; i += 1) materials.clean(i, 'SA');
    },
    setSolarAccessTextures: (m, graySolarAccess, gradientSolarAccess) => {
      materials.clean(m, 'SA');
      materials.SolarAccessTextures[m] = [graySolarAccess, gradientSolarAccess];
    },
    getSolarAccessTexture: (m) => (m in materials.SolarAccessTextures ? materials.SolarAccessTextures[m] : null),

    getDiffuseTextures: () => [materials.diffuseTextures.tsrf, materials.diffuseTextures.tof],
    setDiffuseTextures: (diffuseTSRF, diffuseTOF) => {
      materials.diffuseTextures.tsrf = diffuseTSRF;
      materials.diffuseTextures.tof = diffuseTOF;
    },

    black,
    groundSun,
    ground,
    groundDispSun,
    groundDisp,

    loadShadingJson,
    saveTextures,
    getMaskCanvas: materials.getMaskCanvas,
    gradiantData: materials.gradiantData
  });
};

export default useMaterials;
