import React, { Component, createContext, useState, useEffect } from 'react';
import { Row, Col, Card, Alert, Button } from 'react-bootstrap';
import { ArrowsFullscreen, InfoCircle } from 'react-bootstrap-icons';
import update from 'immutability-helper';
import Fullscreen from 'fullscreen-react';
import * as areaPolygon from 'area-polygon';
import * as convertUnits from 'convert-units';
import disableScroll from 'disable-scroll';

import { LeadContext } from 'Leads/Details/hooks/context';
import { DesignContext } from './Canvas3D/contexts/designContext';

import {
  distance,
  scaleShape,
  rotateSegmentPanels,
  findCommonLine,
  drawOrientation,
  isPointEqual,
  isPointInShape,
  filterDuplicates,
  isPointNearLine,
  retry,
  ios,
  getSegmentArea
} from './utils';
import { setSegmentAttributes } from './Canvas3D/algorithms/geometry';
import { filterDictByKeys } from './Canvas3D/algorithms/utils';
import Controls from './Controls';
import ControlsBottom from './ControlsBottom';
import Menu from './Menu';
import DrawCanvas from './DrawCanvas';
// import PlacePanels from './PlacePanels';
import ShadeReport from '../ShadeReport';
import Canvas3D from './Canvas3D';
import IFrameLogo from 'components/IFrameLogo';
import { createProposalData } from './Canvas3D/algorithms/proposal';
import { convertStateToAbbr, getSnowLoss } from 'options';

const CANVAS_SIZE = 1024;
const SCALE = 0.688;
const samplingRate = 30;
const konvaKeys = ['shading', 'setback', 'design', 'revise'];
const canvasKeys = ['segments', 'obstacles', 'trees'];

class DesignTool extends Component {
  constructor(props) {
    super(props);

    const { iframe, designFrame } = this.props;

    this.state = {
      tool: 'Polygon',
      designType: 'M',
      panelNotSet: false,

      segments: [],
      obstacles: [],
      trees: [],
      panels: [],
      shade: false,
      buffered: [], // setback-buffered segments

      package: {},
      buffer: 12.7,
      rowSpacing: 0,
      panelTilt: 0,
      selectedRoof: -1,
      selectedLabel: 'Eave',
      selectedPanels: false,

      setbackMode: 0,
      fireSetbacks: {
        general: 18,
        eave: 0,
        ridge: 18,
        rake: 18,
        hip: 18,
        valley: 18,
      },

      mode: 'select',
      moveMode: 'group',
      polyEdit: -1,
      focusedPolygonToEdit: -1,
      currentAccordion: 'segments',
      currentTab: null,
      scale: SCALE,

      showOverlay: false,
      colorRange: [0, 2450],

      isFullScreen: false,
      viewMode: '2D', // !iframe || designFrame ? '2D' : '3D',
      selectionMode: 'segment',
      coordinates: [],
      selectedObjects: {
        roofSegments: [],
        roofVertices: {},
        roofLines: {},
        obstacles: [],
        fireSetbacks: {},
        panels: {},
        trees: [],
      },
      optPitchAndAzimuth: { pitch: 24.0, azimuth: 184.0 },
      optPOA: null,
      shadingTexture: null,
      shadingMonth: 0,
      shadingStatus: 0,
      shadingIsRunning: false,
      serverSideShadingIsRunning: false,
      optimizer1IsRunning: false,
      optimizer2IsRunning: false,
      edgeDetectionIsRunning: false,
      measureIsRunning: false,
      panelPlacementIsRunning: false,
      AIIsRunning: false,
      automaticAzimuth: false,
      pvwattsHandled: false,
      solarPanels: [],
      inverters: [],
      losses: null,
      updateRGBTextureIsRunning: false,
    };

    this.histories = [];
    this.historyState = {
      current: -1,
      state: "",
    }
  }

  componentDidMount() {
    const { company, uid, packageAPI, CanvasAPI, lead, newEndpoint } = this.context;
    const { iframe, designFrame, context: iframeContext = {}, callback: iframeCallback } = this.props;

    const resolution = Number(lead.property.resolution);
    const { production: overlay, design_type: designType } = lead.property;
    const setbackAPI = newEndpoint('hardware/setbacks/');
    const solarPanelsAPI = newEndpoint('hardware/panels/');
    const invertersAPI = newEndpoint('hardware/inverters/');
    const lossesEndpoint = newEndpoint('hardware/losses/');

    const imagery = lead?.property?.rgb

    if (iframe && !imagery) {
      iframeCallback('Error', { code: '46', message: 'Lead has no imagery' });
    }

    // load canvas objects
    Promise.all([
      CanvasAPI.getCanvas(uid),
      packageAPI.list().then((data) => (data.length !== 0 ? data : Object())),
      setbackAPI.retrieve(company),
      (iframe) ? Promise.resolve([]) : solarPanelsAPI.list(),
      (iframe) ? Promise.resolve([]) : invertersAPI.list(),
      lossesEndpoint.retrieve(company),
    ]).then(([canvasData, packageData, fireSetbacks, solarPanels, inverters, losses]) => {
      const statePackage = packageData.filter((item, index) => item.state === lead.address.state)[0];
      const defaultPackage = packageData.filter((item, index) => item.state === null)[0];
      // console.log('selected package: ', statePackage !== undefined ? statePackage : defaultPackage);

      const package_ = statePackage || defaultPackage;
      const flagPackage = { ...package_ };
      const { panel: panelHardware } = package_;
      if (iframe && iframeContext && iframeContext?.panelDefault !== 'default' && (iframeContext?.companyPanelID === null || iframeContext?.companyPanelID === undefined)) {
        if (iframe && !designFrame && iframeContext?.panelType) {
          iframeContext.panelType = { ...panelHardware, ...iframeContext.panelType };
          // console.log(`(************ iframe panel: ${iframeContext.panelType}`)
          package_.panel = iframeContext.panelType;
          if (package_.panel?.model === flagPackage.panel?.model && Number(package_.panel?.power) === Number(flagPackage.panel?.power)) {
            iframeCallback('Error', { code: '445', message: 'iframe panel config is not set properly! (same)' });
            this.setState({
              panelNotSet: true
            });
          }
        } else {
          if (!designFrame) {
            iframeCallback('Error', { code: '445', message: 'iframe panel config is not set properly!' });
            this.setState({
              panelNotSet: true
            });
          }
        }
      }

      if (iframeContext?.panelDefault === 'default') {
        iframeContext.panelType = { ...panelHardware }
        package_.panel = iframeContext.panelType;
      }

      if (iframe && iframeContext && (iframeContext?.companyPanelID !== null && iframeContext?.companyPanelID !== undefined)) {
        const { companyPanelID } = iframeContext;
        // console.log(`************ companyPanelID = ${companyPanelID} in didMount`);
        const companyPanelsAPI = newEndpoint('panels/company/');
        companyPanelsAPI.retrieve(companyPanelID).then((panelData) => {
          package_.panel = panelData;
        });
      }
      if (iframe && iframeContext && (iframeContext?.companyInverterID !== null && iframeContext?.companyInverterID !== undefined)) {
        const { companyInverterID } = iframeContext;
        // console.log(`************ companyInverterID = ${companyInverterID} in didMount`);
        const companyInverterAPI = newEndpoint('inverters/company/');
        companyInverterAPI.retrieve(companyInverterID).then((inverterData) => {
          package_.inverter = inverterData;
        });
      }
      const filteredIframeContext = filterDictByKeys(iframeContext, ['buffer', 'rowSpacing', 'panelTilt' , 'setback', 'panelType', 'defaultBtnView']);

      let losses_ = losses
      if (iframe && iframeContext) {
        if (iframeContext?.losses) {
          losses_ = {...losses, ...iframeContext?.losses};
        }
      }

      try {
        let stateAbbr = convertStateToAbbr(lead?.property?.state, 'abbr');
        let snow = getSnowLoss(stateAbbr);

        losses_['snow'] = snow;
      } catch (err) {
        console.log(`error in calculating snow loss: ${err}`);
      }

      console.log(`--> system losses: ${JSON.stringify(losses_)}`);

      solarPanels = solarPanels.filter((el, i) => el?.is_hidden == false);
      inverters = inverters.filter((el, i) => el?.is_hidden == false);

      this.setStatex({
        ...canvasData, package: package_, fireSetbacks, solarPanels, inverters, resolution, overlay, designType, losses: losses_, ...filteredIframeContext,
      }, () => this.updateSetbacks());

      if (iframe && iframeContext?.setback) {
        const s = iframeContext.setback;
        if (Array.isArray(s)) {
          const bfireSetbacks = {
            ...fireSetbacks, eave: s[0], ridge: s[1], rake: s[2], hip: s[3], valley: s[4],
          };
          this.updateSetbacks(bfireSetbacks, 1);
        } else {
          const bfireSetbacks = { ...fireSetbacks, general: s };
          this.updateSetbacks(bfireSetbacks, 0);
        }
      }
    }).catch((e) => {
      console.log(e);
    });

    document.addEventListener('keydown', this.onKeyDown, false);

    this.setState3x({
      coordinates: lead.property.coordinates,
    });
  }

  // TODO: Implement saving for each annotation type instead of always calling saveAll
  componentDidUpdate(prevProps, prevState) {
    if (!(this.canvas3DElement && this.canvas3DElement.isLoaded)) return;

    const { currentAccordion, fireSetbacks } = this.state;
    ['segments', 'obstacles', 'trees', 'panels', 'annotations'].forEach((accordion) => {
      if ((prevState.currentAccordion === accordion && currentAccordion !== accordion) || (prevState[accordion] !== this.state[accordion])) {
        // console.log(accordion, 'closed.');
        const data = [...this.state[accordion]];
        this.context.CanvasAPI.saveCanvas({ [accordion]: data });
      }
    });

    const { iframe, context: iframeContext = {} } = this.props;
    const { currentAccordion: prevAccordion } = prevState;
    const { context: prevContext = {} } = prevProps;

    if (iframe && iframeContext?.setback && JSON.stringify(prevContext?.setback) !== JSON.stringify(iframeContext?.setback)) {
      const s = iframeContext.setback;
      if (Array.isArray(s)) {
        const bfireSetbacks = {
          ...fireSetbacks, eave: s[0], ridge: s[1], rake: s[2], hip: s[3], valley: s[4],
        };
        this.updateSetbacks(bfireSetbacks, 1);
      } else {
        const bfireSetbacks = { ...fireSetbacks, general: s };
        this.updateSetbacks(bfireSetbacks, 0);
      }
    } else if (!konvaKeys.includes(prevAccordion) && konvaKeys.includes(currentAccordion)) {
      console.log('update setbacks.');
      this.updateSetbacks();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown, false);
  }

  onKeyDown = (e) => {
    // if (e.key === '0') this.handleResetScale();
    if (e.key === '+') this.handleScaleUp();
    if (e.key === '-') this.handleScaleDown();
  }

  handleSaveAll = () => {
    const { CanvasAPI } = this.context;
    const {
      segments, obstacles, trees, colorRange,
    } = this.state;
    CanvasAPI.saveCanvas({
      segments, obstacles, trees, colorRange,
    }).then((canvasData) => this.setState2x({ ...canvasData }));
  };

  // This function is for sending data to backend and receive auto-placed panels in return.
  exportCanvas = (includeChildren = false) => {
    const { lead: { property }, uid, user } = this.context;
    const {
      obstacles, segments, trees, resolution,
    } = this.state;
    console.log('exporting...');

    const [lat, lng] = property.coordinates;
    const request = {
      lat,
      lng,
      uid,
      company: user?.company?.id,
      resolution,
      ai_design: false, // property.design_type === 'A',
      obstacles,
    };

    request.obstacles = obstacles;

    request.roofs = segments.map(({ pitch, ...roof }, index) => {
      if (includeChildren) {
        roof.children = segments.filter(({ geometry: shape }, jdx) => {
          if (index === jdx) return false;
          return shape.every((pt) => isPointInShape(pt, roof.geometry));
        }).map((child) => child.geometry);
      }
      return { ...roof, pitch };
    });

    request.trees = trees.map(({ geometry: points, ...tree }) => {
      let radius = 0;
      let geometry = points;
      if (tree.shape === 'Circle') {
        const [p1, p2] = points;
        radius = Math.ceil(distance(p1, p2));
        geometry = [p1];
      }
      return { ...tree, geometry, radius };
    });
    return request;
  }

  handlePlacement = async (params = null, serverSide = false, fillMode = 3, layoutMode = 1) => {
    const { iframe, context: iframeContext, callback: iframeCallback } = this.props;
    this.setState3x({ panelPlacementIsRunning: true });
    if (!serverSide) {
      if (iframe && (iframeContext?.companyPanelID !== null && iframeContext?.companyPanelID !== undefined)) {
        const { companyPanelID } = iframeContext;
        // console.log(`************ companyPanelID = ${companyPanelID} in handlePlacement`);
        const { newEndpoint } = this.context;
        const companyPanelsAPI = newEndpoint('panels/company/');
        companyPanelsAPI.retrieve(companyPanelID).then((panelData) => {
          this.changePanelHardware(panelData);
        });
      }
      if (iframe && params?.companyPanelID !== undefined) {
        if (params?.companyPanelID !== null) {
          const { newEndpoint } = this.context;
          const companyPanelsAPI = newEndpoint('panels/company/');
          companyPanelsAPI.retrieve(params?.companyPanelID).then((panelData) => {
            this.changePanelHardware(panelData);
          });
        }
      }
      try {
        let panelPlacementFillMode = fillMode;
        let panelPlacementLayoutMode = layoutMode;
        if (params?.fill_mode) {
          panelPlacementFillMode = params?.fill_mode;
        } else if (iframeContext?.panelOrientation) {
          panelPlacementFillMode = iframeContext?.panelOrientation;
        }
        if (params?.layout_mode) {
          panelPlacementLayoutMode = params?.layout_mode;
        } else if (iframeContext?.panelLayout) {
          panelPlacementLayoutMode = iframeContext?.panelLayout;
        }
        setTimeout(() => this.canvas3DElement.panelPlacement(true, Number(panelPlacementFillMode), Number(panelPlacementLayoutMode)), 1500);
      } catch (err) {
        console.log(`Error in 3D panel placement: ${err}`);
      }
    } else {
      const { segments, buffer, rowSpacing, panelTilt, package: { panel } } = this.state;
      const fireSetbacks = segments.map((seg) => seg.setbacks);
      const { panelPlacementAPI, uid } = this.context;
      panelPlacementAPI.create({
        uid, panel, fireSetbacks, buffer, rowSpacing, panelTilt, fillMode
      }).then((data) => {
        const panels = data;
        this.setState3x({ panels });
      }).finally(() => this.setState3x({ panelPlacementIsRunning: false }));
    }
    // const { package: { panel: { length, width } }, buffer } = this.state;

    // const request = this.exportCanvas(true);
    // request.panelSize = [length, width].map((n) => (+n + buffer) / 10);
    // // request.setback = +convertUnits(+setbackSize).from('in').to('cm').toFixed(5);

    // this.context.panelFillAPI.create(request).then((data) => {
    //   // load panel data
    //   const panels = data.map((panel) => {
    //     const { vertical, horizontal } = panel;
    //     vertical.selected = Array(vertical.points.length).fill(false);
    //     horizontal.selected = Array(horizontal.points.length).fill(false);
    //     return { ...panel, horizontal, vertical };
    //   });
    //   this.setStatex({ panels });
    // }).catch((err) => console.error(err));
  }

  // TODO: do we need to enforce that request ids match segment ids?
  // this point would be generally applicable to other methods/functions
  handleMeasure = (serverSide = false) => {
    const { mode } = this.state;
    if (mode !== 'select') {
      this.handleMode('select');
      setTimeout(() => { this.handleMeasure(serverSide); }, 200);
      return;
    }

    this.setState3x({ measureIsRunning: true });
    if (!serverSide) {
      setTimeout(this.canvas3DElement.measure, 0);
    } else {
      const { measureAPI, uid } = this.context;
      // const { segments } = this.state;
      measureAPI.create({ uid }).then((data) => {
        const [newSegments, offset] = data;
        this.setSegmentParameters(newSegments);
        // const copySegments = [...segments];
        // newSegments.forEach((seg, i) => {
        //   copySegments[i].pitch = seg.pitch.toFixed(2);
        //   copySegments[i].azimuth = seg.azimuth.toFixed(2);
        //   copySegments[i].height = seg.height.toFixed(2);
        // });
        // this.setStatex({ segments: copySegments });
        this.canvas3DElement.changeOffset(offset);
      }).finally(() => this.setState3x({ measureIsRunning: false }));
    }
  }

  handleDownload3DJSON = () => {
    this.canvas3DElement.downloadJSON();
  }

  setPVwattsLosses = (losses) => {
    this.setState2x({ losses });
  }

  handlePVWatts = async () => {
    const { newEndpoint, company: id } = this.context;
    const { iframe, context: iframeContext, callback: iframeCallback } = this.props;
    const {
      PVWattsAPI, displaySnack, updateLead, lead: { property },
    } = this.context;
    const { package: { inverter }, losses } = this.state;

    let tryCount = 3;
    let [optPitch, optAzimuth] = [24.0, 184.0];
    try {
      // calculate optimal pitch and azimuth for poa request
      [optPitch, optAzimuth] = this.canvas3DElement.getOptPitchAndAzimuth();
      await new Promise((r) => setTimeout(r, 4000));
      if (tryCount > 0 && (optPitch === undefined || optAzimuth === undefined)) {
        [optPitch, optAzimuth] = this.canvas3DElement.getOptPitchAndAzimuth();
        await new Promise((r) => setTimeout(r, 2000));
        tryCount -= 1;
      }
    } catch (err) {
      if (iframe && tryCount === 0 /*&& err.response.data.code*/) {
        // console.log(err);
        iframeCallback('Error', { code: '22', message: 'Error in calculating optimal pitch and azimuth' });
      }
    }

    // alert(`${property.optimal_roof.pitch.toFixed(1)}, ${property.optimal_roof.azimuth.toFixed(1)}`);

    const request = this.exportCanvas(true);
    if (property.optimal_poa.reduce((a, b) => a + b, 0) === 0) {
      request.optPitchAndAzimuth = [property?.optimal_roof?.pitch ? property?.optimal_roof?.pitch.toFixed(1) : optPitch.toFixed(1), property?.optimal_roof?.azimuth ? property?.optimal_roof?.azimuth.toFixed(1) : optAzimuth.toFixed(1)];
    }
    request.inverter_efficiency = inverter?.efficiency;
    if (iframe) {
      const filteredIframeContext = filterDictByKeys(iframeContext, ['panelType', 'inverter', 'monitoring', 'mounting']);
      if (iframeContext?.companyInverterID) {
        request.inverter_efficiency = inverter?.efficiency;
      } else if (filteredIframeContext?.inverter?.efficiency) {
        request.inverter_efficiency = filteredIframeContext?.inverter.efficiency;
      } else {
        console.log('default inverter efficiency');
        request.inverter_efficiency = 97.00;
      }
    }

    request.losses = losses;

    PVWattsAPI.create(request)
      .then((data) => {
        const result = JSON.parse(data);
        const { segments } = this.state;
        const updatedSegments = [...segments];
        updatedSegments.forEach((seg, i) => {
          const ac = result['ac'][i]?.split(',').map(parseFloat) ?? 0;
          const poa = result['poa'][i]?.split(',').map(parseFloat) ?? 0;
          updatedSegments[i].acMonthly = ac;
          updatedSegments[i].poaMonthly = poa;
          updatedSegments[i].acAnnual = ac.reduce((a, b) => a + b, 0);
          updatedSegments[i].pvwatts_update = false;
        });
        if (property.optimal_poa.reduce((a, b) => a + b, 0) === 0) {
          const optPOA = result['poa']['opt']?.split(',').map(parseFloat) ?? 0;
          updateLead({ property: { ...property, optimal_poa: optPOA } });
        }
        this.setState2x({ segments: updatedSegments, pvwattsHandled: true });
        if (iframe) {
          this.setState({
            pvwattsHandled: true
          });
          console.log('Data updated from PVWatts');
        } else {
          displaySnack({ variant: 'success', message: 'Data updated from PVWatts' });
        }
      })
      .catch((err) => {
        if (iframe && err?.response?.data?.code) {
          iframeCallback('Error', { code: err.response.data.code, message: err.response.data.msg });
        }
        if (iframe) {
          console.log(`Failed to update data from PVWatts: {err}`);
        } else {
          displaySnack({ variant: 'danger', message: 'Failed to update data from PVWatts' });
        }
      });
  }

  // handleSaveShadings = (gradients) => {
  //   const { uid, user, saveShadingsAPI } = this.context;
  //   const request = {
  //     uid,
  //     company: user.company.id,
  //     gradients,
  //   };
  //   saveShadingsAPI.create(request).then((data) => {
  //     const result = JSON.parse(data);
  //     console.log(result.url);
  //   });
  // }

  runOptimizationHandler = (optimizePosition, serverSide = false) => {
    // const newSegments = clusterPoints([...this.state.segments]);
    const { mode } = this.state;
    if (mode !== 'select') {
      this.handleMode('select');
      setTimeout(() => { this.runOptimizationHandler(optimizePosition, serverSide); }, 200);
      return;
    }

    this.setState3x({ optimizer1IsRunning: !optimizePosition, optimizer2IsRunning: optimizePosition });
    if (!serverSide) {
      setTimeout(() => this.canvas3DElement.runOptimizer(true, optimizePosition, serverSide), 100);
    } else {
      const { optimizeAPI, uid } = this.context;
      optimizeAPI.create({ uid }).then((newSegments) => {
        this.setSegmentParameters(newSegments);
      }).finally(() => this.setState3x({ optimizer1IsRunning: false, optimizer2IsRunning: false }));
    }
  };

  runEdgeDetectionHandler = (depthForEdgeDetection, serverSide = false) => {
    this.setState3x({ edgeDetectionIsRunning: true });
    if (!serverSide) {
      setTimeout(() => this.canvas3DElement.edgeDetection(depthForEdgeDetection, serverSide), 0);
    } else {
      const { edgeDetectionAPI, uid } = this.context;
      edgeDetectionAPI.create({ uid, byDSM: true }).then((newSegments) => {
        this.changeEdgeTypes(newSegments);
      }).finally(() => this.setState3x({ edgeDetectionIsRunning: false }));
    }
  };

  trackTask = (taskId) => {
    if (taskId === undefined) return;
    const {
      uid, displaySnack, getTaskStatus, CanvasAPI,
    } = this.context;

    getTaskStatus(taskId).then(({ state }) => {
      console.log(taskId, state);
      if (state === 'STARTED') return;
      clearInterval(this.ai_interval);
      this.setState3x({ AIIsRunning: false });
      if (state === 'SUCCESS') {
        displaySnack({ variant: 'info', message: 'Your AI design is ready.' });
        CanvasAPI.getCanvas(uid).then((canvasData) => {
          this.setState2x({ ...canvasData });
        });
      } else {
        displaySnack({ variant: 'danger', message: 'An error was encountered.' });
      }
    });
  };

  setUpdateRGBTextureIsRunning = (value) => {
    // value must be boolean
    this.setState({updateRGBTextureIsRunning: value});
  }

  runAIHandler = (lead_image = null) => {
    const { lead, predictAPI, displaySnack } = this.context;

    const request = {
      uid: lead.uid,
      design_type: 'A',
      no_refinement: 'F',
      lead_image,
    };

    this.setState3x({ AIIsRunning: true });

    this.setState({
      updateRGBTextureIsRunning: true
    }, () => {
      this.canvas3DElement.updateRGBTexture();
      this.forceUpdate();
    });

    predictAPI.create(request)
      .then((data) => {
        console.log(data.task);
        if (data.task) {
          displaySnack({ variant: 'info', message: 'Your AI Design is processing.' });
          this.ai_interval = setInterval(() => this.trackTask(data.task), 1500);
          console.log(this.ai_interval);
        }
      }).catch(() => {
        displaySnack({ variant: 'danger', message: 'AI prediction not started.' });
      });
  };

  handleShading = async (serverSide = false) => {
    const { shadingStatus } = this.state;
    const { lead: { property, uid } } = this.context;
    const { iframe, designFrame, companyName, callback: iframeCallback } = this.props;
    // this.handlePVWatts();
    // const response = await retry(this.handlePVWatts, 3);

    if (iframe && !designFrame) {
      console.log('shading in proposal iframe');
    } else {
      if (serverSide) {
        this.setServerSideShadingIsRunning(true);
      }
      try {
        const response = await retry(this.handlePVWatts, 3);
      } catch(err) {
        console.log(`error in handling pvwatts: ${err}`)
      }
      // wait till PVWatts info is saved properly
      await new Promise((r) => setTimeout(r, 1500));
    }
    const isIos = ios();


    // wait for page/tab be visible
    const pageVisibility = !document.hidden;
    while (document.hidden) {
      console.log('wait for page to be visible');
      await new Promise((r) => setTimeout(r, 500));
    }

    if (!pageVisibility) await new Promise((r) => setTimeout(r, 1500));

    // if (iframe && isIos && companyName == 'Aerialytic') {
    //   while (this.state.segments[0].acMonthly.reduce((a, b) => a + b, 0) === 0.0) {
    //     console.log('wait for pvwatts handler');
    //     await new Promise((r) => setTimeout(r, 500));
    //   }
    //   this.canvas3DElement.startServerSideShading();
    // } else
    let tryCount = 15;
    if (iframe || designFrame) {
      while ((this.state.segments ?? []).length === 0 || this.state.segments[0].acMonthly.reduce((a, b) => a + b, 0) === 0.0) {
        console.log('wait for pvwatts handler');
        await new Promise((r) => setTimeout(r, 4000));
        tryCount -= 1;
        if (tryCount === 0) {
          return iframeCallback('Error', { code: '51', message: 'Error in PVWatts request' });
        }
      }
      await new Promise((r) => setTimeout(r, 2000));
      if (serverSide) {
        await this.canvas3DElement.startServerSideShading();
        if (shadingStatus < 0) {
          console.log("fallback: client-side shading");
          return await this.canvas3DElement.startShading();
        }
      } else {
        return await this.canvas3DElement.startShading();
      }
    } else {
      if (serverSide) {
        const { newEndpoint, user } = this.context;
        if (user.email.includes('@aerialytic')) {
          const shadingInputAPI = newEndpoint('api/shading_input/');
          shadingInputAPI.create({ uid }).then((res) => {
            const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(res)}`;
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute('href', dataStr);
            downloadAnchorNode.setAttribute('download', 'data.json');
            document.body.appendChild(downloadAnchorNode);
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
          });
          await this.canvas3DElement.startServerSideShading();
        } else {
          await this.canvas3DElement.startServerSideShading();
        }
      } else {// if (!serverSide) {
        setTimeout(() => this.canvas3DElement.startShading(), 250);
      }
    }

    while (this.state.segments[0].acMonthly.reduce((a, b) => a + b, 0) === 0.0) {
      console.log('wait for pvwatts handler');
      await new Promise((r) => setTimeout(r, 500));
    }

    // const { uid, shadingAPI, productionMapAPI, CanvasAPI } = this.context;
    // const request = this.exportCanvas();
    // return shadingAPI.create(request)
    //   .then((data) => {
    //     const { roofs, image: production, colorRange } = data;

    //     // update shade factor for each roof in response
    //     const segments = Array.from(this.state.segments);
    //     roofs.forEach(({ solarAccess }, index) => {
    //       // const access = solarAccess.reduce((a, b) => a + b, 0)/solarAccess.length;
    //       segments[index].solarAccess = solarAccess;
    //     });
    //     // save shading data to backend
    //     this.setStatex(
    //       { segments, colorRange, shade: true },
    //       () => CanvasAPI.saveCanvas({ segments })
    //     );
    //     productionMapAPI.update(uid, { production })
    //       .then(({ url }) => this.setStatex({ overlay: url }));
    //     return true;
    //   }).catch(() => false);
  }

  shadingValues = () => {
    // method that is used in iframe to pass back shading values
    const { iframe, callback: iframeCallback } = this.props;

    const { segments, panels } = this.state;
    const annualData = segments.map(({ id, solarAccess, tsrf, tof }) => {
      const panel = panels.find((pnl) => pnl.id === id);

      const annualTSRF = tsrf[12];
      const annualTOF = tof[12];
      const annualSolarAccess = solarAccess[12];

      return {
        id, annualSolarAccess, annualTSRF, annualTOF,
      };
    });

    if (iframeCallback) iframeCallback('ShadingValues', { data: annualData });
  }

  handleProposal = async (params = null, loadAfter = false) => {
    /**
     * @param params: iframe params
     */

    // receive design data for creating a new proposal
    const {
      uid, refreshLead, proposalAPI, navigateTab,
    } = this.context;

    // set hardware from state.package if it exists
    const { segments, package: _package, panels, losses, annotations } = this.state;

    const pvwattsRequest = segments[0]?.acMonthly.reduce((a, b) => a + b, 0) === 0.0 ? false : true;
    if (!pvwattsRequest) {
      const response = await retry(this.handlePVWatts, 3);
    }

    while (this.state.segments[0].acMonthly.reduce((a, b) => a + b, 0) === 0.0) {
      console.log('wait for pvwatts handler');
      await new Promise((r) => setTimeout(r, 500));
    }

    const { iframe, designFrame, callback: iframeCallback, context: iframeContext = {} } = this.props;

    const desCompanyPanelId = params?.companyPanelID;
    const desCompanyInverterId = params?.companyInverterID;

    if (iframeContext?.panelDefault === 'default') {
      const { panel: panelHardware } = _package;
      iframeContext.panelType = { ...panelHardware }
    }

    // console.log(`handleProposal: ${desCompanyPanelId}, ${desCompanyInverterId}`);
    const data = createProposalData(uid, this.state, panels, _package, iframeContext, desCompanyPanelId, desCompanyInverterId, designFrame, losses);

    // console.log(`handleProposal - iframe context: ${JSON.stringify(iframeContext)}\nhandleProposal - package: ${JSON.stringify(_package)}\nhandleProposal - data: ${JSON.stringify(data)}\n`)

    proposalAPI.create({ ...data, annotations }).then(({ id }) => {
      refreshLead('proposals');
      if (!iframe) navigateTab(`proposals?id=${id}`);
      else if (iframeCallback) iframeCallback('NewProposal', id);
      if (loadAfter) {
        window.location.replace(`/iframe/${uid}?id=${id}`);
      }
    });
  }

  handleMode = (mode) => {
    const { currentAccordion, currentTab } = this.state;
    if (currentAccordion === 'segments' && currentTab === 'segment-labels') {
      this.setState3x({ mode, selectedLabel: '' });
    } else if (currentAccordion === 'annotations') {
      this.setState3x({ mode });
    } else {
      this.setState3x({ mode });
    }
  }

  handleMoveMode = (moveMode) => this.setState3x({ moveMode });

  handleCurrentAccordion = (currentAccordion) => {
    let mode;
    let tool = 'Polygon';
    const { currentTab, losses } = this.state;
    const { iframe, designFrame, callback: iframeCallback } = this.props;

    // update mode, and tool based on opened accordion
    if (canvasKeys.includes(currentAccordion)) mode = 'draw';
    else mode = 'select';
    if (currentAccordion === 'segments' && currentTab === 'segment-labels') mode = 'label';
    if (currentAccordion === 'trees') tool = 'Circle';
    // if (currentAccordion === 'setback') this.updateSetbacks();

    if (iframe && !designFrame) {
      if (this.state.segments?.length === 0) {
        return false;
      }
    }

    this.setState3x({ currentAccordion, mode, tool, losses });
    return true;
  }

  handleNewSegment = ({ geometry: geom, ...segment }) => {
    const [geometry] = filterDuplicates(geom);
    const newSegment = {
      ...segment,
      azimuth: 0,
      pitch: 0,
      area: 0,
      height: 0,
      geometry,
      lines: Array(geometry.length).fill(''),
      setbacks: Array(geometry.length).fill(0),
      acAnnual: 0,
      acMonthly: Array(12).fill(0),
      poaMonthly: Array(12).fill(0),
      solarAccess: Array(13).fill(1),
      tsrf: Array(13).fill(1),
      tof: Array(13).fill(1),
    };
    this.setStatex(({ segments }) => ({ segments: [...segments, newSegment] }), null, ['segments']);
  }

  handleNewObstacle = (obstacle) => {
    const isLine = obstacle.shape === 'Line';
    const newObstacle = {
      height: isLine ? 0 : 12,
      radius: isLine ? 0 : 5,
      setback: isLine ? 25 : 0,
      ...obstacle,
    };
    const newObstacles = [...this.state.obstacles, newObstacle];
    this.setState3x({ obstacles: newObstacles });
  }

  handleNewTree = (tree) => {
    const { resolution } = this.state;
    const newTree = {
      ...tree,
      model: 'Sphere',
      dsm: 'Keep',
      height: (parseFloat(distance(tree.geometry[0], tree.geometry[1]) * resolution) / 30.48).toFixed(2) * 2,
    };
    const newTrees = [...this.state.trees, newTree];
    this.setState3x(({ trees: newTrees }));
  }

  handleNewShape = (shape, scaled = true) => {
    // scale data back
    const { scale, currentAccordion } = this.state;
    const newShape = scaled ? scaleShape(shape, 1 / scale) : shape;

    // pass data to relevant handler
    switch (currentAccordion) {
      case 'segments':
        this.handleNewSegment(newShape);
        break;
      case 'obstacles':
        this.handleNewObstacle(newShape);
        break;
      case 'trees':
        this.handleNewTree(newShape);
        break;
      case 'annotations':
        if (newShape.objectType != '') this.newAnnotation({}, newShape.objectType, newShape);
      default:
        break;
    }
  }

  // manage annotation edits
  // TODO: setState by reference
  handleSaveEdits = (updated, scaled = true) => {
    // scale data back
    const { scale, currentAccordion: accordion } = this.state;
    const geom = scaled ? scaleShape(updated, 1 / scale, true) : updated.geometry;
    const [geometry, removed] = filterDuplicates(geom);

    // get current data based on accordion, and update the changes
    const { [accordion]: oldData } = this.state;
    const data = [...oldData]
    const index = data.map(({ id }) => id).indexOf(updated.id);

    // remove invalid geometries
    switch (accordion) {
      case 'segments':
        if (geometry.length < 3) return this.handleDeleteSegment(updated.id);
        data[index] = { ...data[index], geometry };

        // update line labels if any duplicate points were removed
        if (accordion === 'segments') { // && removed.length > 0) {
          let { lines } = data[index];
          lines = lines.filter((_, idx) => !removed.includes(idx));

          // if lines & geometry mismatch in length, create a new array with the correct length
          if (lines.length !== geometry.length) lines = Array(geometry.length).fill(null);
          data[index].lines = lines;
        }

        this.setStatex({ [accordion]: [...data] }, () => {
          if (accordion === 'segments') this.getAzimuthFromLineLabel(index);
        });

        break;
      case 'obstacles':
        if (updated.shape === 'Polygon' || updated.shape === 'Line') {
          if (geometry.length < 3) return this.handleDeleteObstacle(updated.id);
          data[index] = { ...data[index], geometry };
        }
        if (updated.shape === 'Point') {
          if (geometry.length !== 1) return this.handleDeleteObstacle(updated.id);
          data[index] = { ...data[index], geometry, radius: updated.radius };
        }
        this.setStatex({ [accordion]: [...data] });
        break;
      case 'trees':
        if (geometry.length !== 2) return this.handleDeleteTree(updated.id);
        data[index] = { ...data[index], geometry };
        this.setStatex({ [accordion]: [...data] });
        break;
      case 'annotations':
        if (geometry.length !== 2) annotations.splice(index, 1);
        else data[index] = { ...data[index], geometry };
        this.setAnnotations(annotations);
        break;
      default:
        break;
    }
  }

  handleAddPointToPolygon = (index, lineIndex, point, scaled = false) => {
    // scale back the data from draw canvas
    const { scale, currentAccordion: accordion } = this.state;
    const scaledPoint = !scaled ? [Math.round(point[0] / scale), Math.round(point[1] / scale)] : [Math.round(point[0]), Math.round(point[1])];
    if (['segments', 'obstacles'].includes(accordion)) {
      const { [accordion]: data } = this.state;
      // insert new point in polygon
      data[index].geometry.splice(lineIndex + 1, 0, scaledPoint);
      if (data[index].lines) data[index].lines.splice(lineIndex + 1, 0, null);
      this.setState2x({ [accordion]: [...data], polyEdit: -1 });
    }
  }

  handleRemovePointFromPolygon = (index, pointIdxInGeometry) => {
    const { currentAccordion: accordion } = this.state;
    if (['segments', 'obstacles'].includes(accordion)) {
      const { [accordion]: data } = this.state;
      data[index].geometry.splice(pointIdxInGeometry, 1);
      if (data[index].lines) data[index].lines.splice(pointIdxInGeometry, 1);
      if (data[index].geometry.length < 3) {
        data.splice(index, 1);
      }
      this.setState2x({ [accordion]: [...data], polyEdit: -1 });
    }
  }

  // TODO: clean up this method to reduce style guide conflicts
  // TODO: add option for labelling a line that has both points overlapped by another line
  handleLineLabel = (index, lineIndex, lines) => {
    const { fireSetbacks } = this.state;
    const changedSegs = [];
    this.setStatex(({ segments }) => {
      const segment = segments[index];
      segment.lines = lines;
      segment.setbacks = lines.map((label) => fireSetbacks[label?.toLowerCase()] || 0);

      const op1 = segment.geometry[lineIndex];
      const op2 = segment.geometry[(lineIndex + 1) % segment.geometry.length];
      const overlappedOri = drawOrientation(segment.geometry, lineIndex);

      // label co-incident & overlapping lines
      if (lines[lineIndex] !== 'Eave') {
        segments.forEach((seg, j) => {
          if (j !== index) {
            const common = findCommonLine(segment.geometry, seg.geometry);
            if (common !== -1 && common.length === 2) {
              // console.log('entered');
              const li = Math.min(...common);
              const currentOri = drawOrientation(seg.geometry, li);
              if (li === 0 && Math.max(...common) === (seg.geometry.length - 1)) {
                // console.log('ab', j);
                const p1 = seg.geometry[Math.max(...common)];
                const p2 = seg.geometry[(Math.max(...common) + 1) % seg.geometry.length];
                // console.log(op1, p1, op2, p2);
                if (overlappedOri === currentOri) {
                  if (isPointEqual(op1, p2) && isPointEqual(op2, p1)) {
                    // console.log('a1');
                    if (!seg.lines) seg.lines = Array(seg.geometry.length).fill('');
                    seg.lines[Math.max(...common)] = lines[lineIndex];
                    if (!changedSegs.includes(j)) changedSegs.push(j);
                  }
                } else if (isPointEqual(op1, p1) && isPointEqual(op2, p2)) {
                  if (!seg.lines) seg.lines = Array(seg.geometry.length).fill('');
                  seg.lines[Math.max(...common)] = lines[lineIndex];
                  if (!changedSegs.includes(j)) changedSegs.push(j);
                }
              } else {
                // console.log('ac', j);
                const p1 = seg.geometry[li];
                const p2 = seg.geometry[(li + 1) % seg.geometry.length];
                // console.log(op1, p1, op2, p2);
                if (overlappedOri === currentOri) {
                  if (isPointEqual(op1, p2) && isPointEqual(op2, p1)) {
                    if (!seg.lines) seg.lines = Array(seg.geometry.length).fill('');
                    seg.lines[li] = lines[lineIndex];
                    if (!changedSegs.includes(j)) changedSegs.push(j);
                  }
                } else if (isPointEqual(op1, p1) && isPointEqual(op2, p2)) {
                  // console.log('b2');
                  if (!seg.lines) seg.lines = Array(seg.geometry.length).fill('');
                  seg.lines[li] = lines[lineIndex];
                  if (!changedSegs.includes(j)) changedSegs.push(j);
                }
              }
            } else if (common !== -1 && common.length === 1) {
              const li = common[0];
              // const currentOri = drawOrientation(seg.geometry, li);
              const p1 = seg.geometry[li];
              const condition = (p) => isPointNearLine(p, op1, op2, 0.5) && !isPointEqual(p, p1);
              const p2Idx = seg.geometry.findIndex(condition);
              if (p2Idx !== -1) {
                if (li === 0 && p2Idx === (seg.geometry.length - 1)) {
                  if (!seg.lines) seg.lines = Array(seg.geometry.length).fill('');
                  seg.lines[p2Idx] = lines[lineIndex];
                  if (!changedSegs.includes(j)) changedSegs.push(j);
                } else if (p2Idx === 0 && li === (seg.geometry.length - 1)) {
                  if (!seg.lines) seg.lines = Array(seg.geometry.length).fill('');
                  seg.lines[li] = lines[lineIndex];
                  if (!changedSegs.includes(j)) changedSegs.push(j);
                } else {
                  if (!seg.lines) seg.lines = Array(seg.geometry.length).fill('');
                  seg.lines[Math.min(li, p2Idx)] = lines[lineIndex];
                  if (!changedSegs.includes(j)) changedSegs.push(j);
                }
              }
            }
          }
        });
      }
      return { segments: [...segments] };
    }, () => {
      if (this.state.selectedLabel === 'Eave' || this.state.selectedLabel === 'Ridge') {
        this.getAzimuthFromLineLabel(index);
        changedSegs.forEach((changedIdx) => {
          this.getAzimuthFromLineLabel(changedIdx);
        });
      }
      const { currentAccordion } = this.state;
      const data = [...this.state[currentAccordion]];
      this.context.CanvasAPI.saveCanvas({ [currentAccordion]: data });
    }, ['segments']);
  }

  handleSegmentAzimuth = (idx, azimuth) => {
    const segments = [...this.state.segments];
    const { resolution } = this.state;
    const segment = segments[idx];
    azimuth = Number(azimuth);
    const area = getSegmentArea(segment, resolution);
    segments[idx] = { ...segment, azimuth, area, pvwatts_update: true };
    this.setState3x({ segments });
  }

  handleSegmentPitchAndArea = (idx, pitch) => {
    // if idx param. is -1, the pitch value will be set for all segments.
    const segments = [...this.state.segments];
    const { resolution } = this.state;
    if (idx === -1) {
      for (let i = 0; i < segments.length; i += 1) {
        const segment = segments[i];

        pitch = Number(pitch);
        const area = getSegmentArea(segment, resolution);

        // update segment area & pitch
        segments[i] = { ...segment, pitch, area, pvwatts_update: true };
      }
      this.setState3x({ segments });
    } else {
      const segment = segments[idx];
      // console.log("test", Object.values(corners))
      pitch = Number(pitch);
      const area = getSegmentArea(segment, resolution);
      // const segArea = areaPolygon(segment.geometry) * slopeFactors[pitch];
      // const segArea = areaPolygon(segment.geometry) * slopeFactor;
      // const segAreaSqm = (segArea * samplingRate) / 10000;
      // const area = convertUnits(segAreaSqm).from('m2').to('ft2');

      // update segments area & pitch
      segments[idx] = { ...segment, pitch, area, pvwatts_update: true };
      // Set pitch for initial panels array
      // const panels = [...this.state.panels];
      // panels[idx] = {...panels[idx], pitch};
      this.setState3x({ segments });
    }
  }

  handleSegmentEaveHeight = (idx, height) => {
    // if idx param. is -1, the eave height value will be set for all segments.
    if (idx === -1) {
      const newSegments = [...this.state.segments];
      newSegments.forEach((seg, i) => {
        newSegments[i].height = height;
      });
      this.setState3x({ segments: newSegments });
    } else {
      const newSegments = [...this.state.segments];
      newSegments[idx].height = height;
      this.setState3x({ segments: newSegments });
    }
  }

  handleTreeUpdate = (tree, idx) => {
    const newTrees = [...this.state.trees];
    newTrees[idx] = tree;
    this.setState3x({ trees: newTrees });
  }

  // TODO: re-write this + subsequent into a single method
  handleAddPointToPolygonFromMenu = (key) => {
    this.setState2x({
      mode: 'edit',
      polyEdit: 1,
      focusedPolygonToEdit: key,
    });
  }

  handleRemovePointFromPolygonFromMenu = (key) => {
    this.setState2x({
      mode: 'edit',
      polyEdit: 2,
      focusedPolygonToEdit: key,
    });
  }

  handleDeleteShape = (key) => {
    const { currentAccordion } = this.state;
    switch (currentAccordion) {
      case 'segments':
        // this.drawCanvasElement.removeShape(key);
        this.handleDeleteSegment(key);
        break;
      case 'obstacles':
        // this.drawCanvasElement.removeShape(key);
        this.handleDeleteObstacle(key);
        break;
      case 'trees':
        // this.drawCanvasElement.removeShape(key);
        this.handleDeleteTree(key);
        break;
      case 'annotations':
        this.handleDeleteAnnotation(key);
        break;
      default:
        break;
    }
  }

  handleDeleteSegment = (key) => {
    const { CanvasAPI } = this.context;
    const { segments, panels } = this.state;

    const newSegments = [...segments].filter((segment) => segment.id !== key);
    const newPanels = [...panels].filter((panel) => panel.id !== key);

    CanvasAPI.saveCanvas({ ['panels']: newPanels });
    this.setState3x({ segments: newSegments, panels: newPanels });
    // this.setStatex(({ segments, panels }) => ({
    //   segments: segments.filter((segment) => segment.id !== key),
    //   panels: panels.filter((segment) => segment.segmentId !== key),
    // }));
  }

  handleDeleteObstacle = (key) => {
    this.setStatex(({ obstacles }) => ({
      obstacles: obstacles.filter((obstacle) => obstacle.id !== key),
    }), null, ['obstacles']);
  }

  handleDeleteTree = (key) => {
    this.setStatex(({ trees }) => ({
      trees: trees.filter((tree) => tree.id !== key),
    }), null, ['trees']);
  }

  handleDeleteAnnotation = (key) => {
    const index = this.state.annotations.map(({ id }) => id).indexOf(key);
    if (index === -1) return;
    annotations.splice(index, 1);
    this.setAnnotations(annotations);
  } 

  handleNewPanel = () => {
    const { viewMode } = this.state;
    if (viewMode === '3D') { this.canvas3DElement.toggleView('insert'); return; }
    this.placePanelsElement.addNewPanel();
  }

  handleDeletePanel = () => {
    const { panels, selectedPanels, viewMode } = this.state;
    if (viewMode === '3D') { this.canvas3DElement.deletePanels(); return; }
    if (!selectedPanels) return;

    const deleted = panels.map((panel) => {
      const horizontal = panel.horizontal.points.filter((pt, jdx) => (
        !panel.horizontal.selected[jdx]
      ));
      const vertical = panel.vertical.points.filter((pt, jdx) => (
        !panel.vertical.selected[jdx]
      ));
      return {
        ...panel,
        horizontal: {
          ...panel.horizontal,
          points: horizontal,
          selected: Array(horizontal.length).fill(false),
        },
        vertical: {
          ...panel.vertical,
          points: vertical,
          selected: Array(vertical.length).fill(false),
        },
      };
    });
    this.setState2x({ panels: deleted, selectedPanels: false });
    this.syncSelectedPanelFrom2D();
  }

  handleRotatePanel = () => {
    const { panels, selectedPanels, viewMode } = this.state;
    if (viewMode === '3D') { this.canvas3DElement.rotatePanels(); return; }
    if (!selectedPanels) return;

    const rotated = panels.map((panel) => {
      const horizontal = [];
      const vertical = [];
      panel.horizontal.points.forEach((pt, jdx) => {
        if (panel.horizontal.selected[jdx]) vertical.push(pt);
        else horizontal.push(pt);
      });
      panel.vertical.points.forEach((pt, jdx) => {
        if (panel.vertical.selected[jdx]) horizontal.push(pt);
        else vertical.push(pt);
      });
      return {
        ...panel,
        horizontal: {
          ...panel.horizontal,
          points: horizontal,
          selected: Array(horizontal.length).fill(false),
        },
        vertical: {
          ...panel.vertical,
          points: vertical,
          selected: Array(vertical.length).fill(false),
        },
      };
    });
    this.setState2x({ panels: rotated, selectedPanels: false });
    this.syncSelectedPanelFrom2D();
  }

  handlePanelAzimuth = (index, azimuth) => {
    if (index === null) return;
    this.setStatex(({ segments, panels }) => {
      const segment = segments[index];
      const rotated = rotateSegmentPanels(segment, panels[index], azimuth);
      panels.splice(index, 1, rotated);
      return { panels: [...panels] };
    }, null, ['panels']);
  }

  updateSetbacks = (pFireSetbacks = null, pSetbackMode = null, forceUpdate = false) => {
    const {
      segments, setbackMode, fireSetbacks,
    } = this.state;

    if (!pSetbackMode) pSetbackMode = setbackMode;
    if (!pFireSetbacks) pFireSetbacks = fireSetbacks;

    console.log('SETBACK MODE = ', setbackMode);

    let hasChanged = false;

    const updated = segments.map((seg) => {
      if (seg.setbacks && seg.setbacks.length > 0 && !forceUpdate) return seg;
      hasChanged = true;
      const setbacks = seg.lines.map((label) => {
        if (pSetbackMode === 0) return (label === 'Eave' ? 0 : pFireSetbacks.general);
        return pFireSetbacks[label?.toLowerCase()] || 0;
      });
      return { ...seg, setbacks };
    });

    this.setState2x(({ segments: updated, setbackMode: pSetbackMode, fireSetbacks: pFireSetbacks }));
    if (hasChanged || forceUpdate) this.context.CanvasAPI.saveCanvas({ segments: updated });
    return hasChanged;
  }

  handleSetbackMode = (setbackMode) => this.setStatex(
    { setbackMode },
    () => this.updateSetbacks(null, null, true),
  );

  handleFireSetbacks = (setback, label) => {
    this.setStatex(({ fireSetbacks }) => ({
      fireSetbacks: { ...fireSetbacks, [label]: setback },
    }), () => { this.updateSetbacks(null, null, true); }, ['fireSetbacks']);
  }

  handleLineSetbackEdit = (editLines, setback) => {
    console.log(editLines, setback);
    const spec = Object();
    editLines.forEach(({ seg, line }) => {
      spec[seg] = { setbacks: { [line]: { $set: setback } } };
    });
    console.log(spec);
    this.setStatex(({ segments }) => update({ segments }, { segments: spec }), null, ['segments']);
  }

  handlePanels = (panels) => this.setState3x({ panels })

  handleTool = (tool) => this.setState3x({ tool });

  handleSelectedLabel = (selectedLabel) => this.setState3x({ selectedLabel, mode: 'label' });

  clearLabelsHandler = () => {
    const { segments } = this.state;
    const newSegments = [...segments];
    newSegments.forEach((s) => { s.lines = s.geometry.map(() => ''); });
    this.setState3x({ segments: newSegments });
  };

  clearMeasurment = () => {
    const { segments } = this.state;
    const newSegments = [...segments];
    newSegments.forEach((s) => { s.pitch = 0; s.azimuth = 0; s.height = 0; });
    this.setSegmentParameters(newSegments);
  };

  clearPanels = () => {
    this.canvas3DElement.clearPanels();
  };

  handleBuffer = (buffer) => this.setState3x({ buffer });

  handleRowSpacing = (rowSpacing) => this.setState3x({ rowSpacing });

  handlePanelTilt = (panelTilt) => this.setState3x({ panelTilt });

  handleSegmentTabs = (currentTab) => {
    const mode = (currentTab === 'segment-labels') ? 'label' : 'draw';
    this.setState2x({ mode, currentTab });
  }

  handleSelectedPanel = (id, isSelected = null) => {
    const [idx, hv, jdx] = id.split('_');
    const { panels } = this.state;

    const { selected } = panels[idx][hv];
    panels[idx][hv].selected[jdx] = isSelected === null ? !selected[jdx] : isSelected;

    const selectedPanels = panels.some((panel) => (
      panel.vertical.selected.some((el) => el)
      || panel.horizontal.selected.some((el) => el)
    ));

    this.setState2x({ panels, selectedPanels });

    this.syncSelectedPanelFrom2D();
  }

  handleSelectedRoof = (index) => {
    const selectedRoof = index === undefined ? -1 : index;
    this.setState3x({ selectedRoof });
    this.syncSelectedRoofFrom2D();
  }

  // TODO: do we still need this?
  // setScroller = (left, top) => this.setStatex({ scroller: [left, top] });

  handleResetScale = () => {
    this.setState({ scale: SCALE });
    this.canvas3DElement.zoomCamera(0);
  }

  handleScaleUp = () => {
    this.setState(({ scale }) => ({ scale: Math.min(scale * 1.3, 10) }));
    this.canvas3DElement.zoomCamera(1.1);
  }

  handleScaleDown = () => {
    this.setState(({ scale }) => ({ scale: Math.max(scale / 1.3, SCALE) }));
    this.canvas3DElement.zoomCamera(0.9);
  }

  automaticAzimuthMode = (mode) => {
    this.setState3x(({ automaticAzimuth: mode }));
  }

  getAzimuthFromLineLabel = (index) => {
    const { segments, automaticAzimuth } = this.state;
    if (!automaticAzimuth) return;

    const newSegments = [...segments];
    const segment = newSegments[index];
    const { lines, geometry } = segment;

    const bestEdges = {
      Eave: { index: -1, len: 0 },
      Ridge: { index: -1, len: 0 },
      Valley: { index: -1, len: 0 },
    };

    const validEdgeType = Object.keys(bestEdges);
    geometry.forEach((v1, vi) => {
      const edgeType = lines[vi];
      if (validEdgeType.includes(edgeType)) {
        const vj = (vi + 1) % geometry.length;
        const v2 = geometry[vj];
        const edgeLength = distance(v1, v2);
        if (bestEdges[edgeType].len < edgeLength) {
          bestEdges[edgeType].index = vi;
          bestEdges[edgeType].len = edgeLength;
        }
      }
    });

    const edges = validEdgeType.map((k) => bestEdges[k]);
    const edge = edges.find((edge) => edge.index >= 0);
    if (!edge) return;
    const eaveIndex = edges.indexOf(edge);
    const eave = validEdgeType[eaveIndex] === 'Eave';

    // const eave = lines.indexOf('Eave');
    // const ridge = lines.indexOf('Ridge');
    // if (eave === -1 && ridge === -1) return;

    // const ll = eave === -1 ? ridge : eave;

    const p1 = segment.geometry[edge.index];
    const p2 = segment.geometry[(edge.index + 1) % segment.geometry.length];

    // Computing azimuth
    let dx = p2[0] - p1[0];
    let dy = -(p2[1] - p1[1]); // inverted to account for origin on top-left

    if (drawOrientation(segment.geometry, edge.index) === 'ccw') {
      // console.log(index, 'CCW');
      dx = -dx;
      dy = -dy;
    }
    const theta = eave ? (Math.atan2(-dy, dx) * 180) / Math.PI : (Math.atan2(dy, -dx) * 180) / Math.PI;
    let azimuth = (theta + 360) % 360;
    azimuth = Math.round((azimuth + Number.EPSILON) * 100) / 100;
    segment.azimuth = azimuth;

    newSegments[index] = segment;

    this.setState3x({ segments: newSegments });
  }

  mapsLink = () => {
    const { lead } = this.context;
    const [lat, lng] = lead.property.coordinates;
    return `https://www.google.com/maps/@${lat},${lng},61m/data=!3m1!1e3`;
  }

  toggleOverlay = () => this.setState({ showOverlay: !this.state.showOverlay });

  cleanCanvas = () => {
    const { removeShadingsAPI, lead } = this.context;
    // this.drawCanvasElement.cleanCanvas();
    this.setState3x({
      segments: [],
      obstacles: [],
      trees: [],
      panels: [],
    });
    const request = {
      uid: lead.uid,
    };
    removeShadingsAPI.create(request).then((data) => {
      console.log('shading gradients removed');
    });
  }

  handleMouseWheel = (event) => {
    const { scale, viewMode, currentAccordion } = this.state;
    if (viewMode === '2D') {
      const wheel = event.deltaY / 120;
      // if (wheel < 0) this.handleScaleUp();
      // else this.handleScaleDown();
      this.mouseDownPosition = [event.clientX, event.clientY];
      const { top: ctop, left: cleft } = this.container.getBoundingClientRect();
      const { top, left } = event.target.getBoundingClientRect();
      const pos = [this.mouseDownPosition[0] - left, this.mouseDownPosition[1] - top];
      const newScale = wheel < 0 ? Math.min(scale * 1.3, 10) : Math.max(scale / 1.3, SCALE);
      const scaleRatio = newScale / scale;
      const newPos = [pos[0] * scaleRatio, pos[1] * scaleRatio];
      const size = CANVAS_SIZE * newScale;
      if (konvaKeys.includes(currentAccordion)) {
        // this.placePanelsElement.updateScale(newScale);
        // pass
      } else {
        // this.drawCanvasElement.updateScale(newScale, size);
      }
      this.container.scrollLeft = newPos[0] - (this.mouseDownPosition[0] - cleft);
      this.container.scrollTop = newPos[1] - (this.mouseDownPosition[1] - ctop);
    }
  }

  handleMouseDown = (event) => {
    if (this.mouseDownState) return;

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    if (event.button === 2 || isIOS) {
      event.preventDefault();
      event.stopPropagation();
      this.mouseDownState = true;
      this.mouseDownPosition = [event.clientX, event.clientY];
      this.scrollPosition = [this.container.scrollLeft, this.container.scrollTop];
    }
  }

  handleMouseMove = (event) => {
    const { iframe, designFrame } = this.props;
    if (!designFrame) {
      if (this.canvas3DElement.isLoaded && this.container && !this.container.hidden)
        disableScroll.on({ disableScroll: true, disableWheel: true });
    }
    if (this.mouseDownState) {
      const pos = [event.clientX, event.clientY];
      this.container.scrollLeft = this.scrollPosition[0] - pos[0] + this.mouseDownPosition[0];
      this.container.scrollTop = this.scrollPosition[1] - pos[1] + this.mouseDownPosition[1];
    }
  }

  handleMouseUp = () => {
    this.mouseDownState = false;
  }

  handleMouseLeave = () => {
    disableScroll.off();
  }

  handleMouseEnter = () => {
    const { iframe, designFrame } = this.props;
    if (!designFrame) {
      if (this.canvas3DElement.isLoaded && this.container && !this.container.hidden) {
        disableScroll.on({ disableScroll: true, disableWheel: true });
      }
    }
  }

  // replace with inline arrow function
  goFullScreen = (enabled) => {
    this.setState({ isFullScreen: enabled });
  }

  changePanelHardware = (panel) => {
    // console.log(this.state.package);
    // console.log(panel);
    const { iframe, designFrame } = this.props;

    const { package: installationPackage } = this.state;
    panel.length = Math.max(500, Number(panel.length));
    panel.width = Math.max(500, Number(panel.width));

    // if (designFrame) {
    //   console.log(`designer custom panel type:
    //     \nlength: ${panel.length}
    //     \nwidth: ${panel.width}
    //     \ndegradation: ${panel.degradation}
    //     \nefficiency: ${panel.efficiency}
    //     \npower: ${panel.power}`
    //   );
    // }

    this.setStatex({ package: { ...installationPackage, panel }, panels: [] }, () => {
      // this.handlePlacement();
    });

    // if (designFrame) {
    //   console.log(`designer installation package: ${JSON.stringify(installationPackage)}`);
    // }
  }

  changeInverterHardware = (inverter) => {
    const { package: installationPackage } = this.state;
    this.setStatex({ package: { ...installationPackage, inverter } });
  }

  switchView = (viewMode) => {
    this.setState3x({ viewMode });
    this.canvas3DElement.setViewMode(viewMode);
    if (viewMode === '2D') this.setState({ scale: SCALE });
  }

  setSelectionMode = (type) => {
    this.setState({ selectionMode: type });
  }

  syncSelectedRoofFrom3D = () => {
    const { selectedObjects } = this.state;
    const selectedSegments = selectedObjects.roofSegments;
    const selectedRoof = selectedSegments.length ? selectedSegments[selectedSegments.length - 1] : -1;
    this.setState3x({ selectedRoof });
  }

  syncSelectedRoofFrom2D = () => {
    const { selectedRoof, selectedObjects } = this.state;
    const selectedSegments = selectedRoof !== -1 ? [selectedRoof] : [];
    this.setState({ selectedObjects: { ...selectedObjects, roofSegments: selectedSegments } });
  }

  syncSelectedPanelFrom3D = () => {
    const { panels, selectedObjects } = this.state;
    const selectedPanels = selectedObjects.panels;

    let isSelectedPanels = false;

    panels.forEach((panel, segmentIndex) => {
      panel.horizontal.selected.forEach((_, pi) => { panel.horizontal.selected[pi] = false; });
      panel.vertical.selected.forEach((_, pi) => { panel.vertical.selected[pi] = false; });

      if (segmentIndex in selectedPanels) {
        selectedPanels[segmentIndex].forEach((panelIndex) => {
          isSelectedPanels = true;
          const panelCount = panel.horizontal.points.length;
          if (panelIndex < panelCount) {
            panel.horizontal.selected[panelIndex] = true;
          } else {
            panel.vertical.selected[panelIndex - panelCount] = true;
          }
        });
      }
    });

    this.setState2x({ panels, selectedPanels: isSelectedPanels });
  }

  syncSelectedPanelFrom2D = () => {
    const { panels, selectedObjects } = this.state;
    const selectedPanels = {};

    panels.forEach((panel, segmentIndex) => {
      const selectedList = [];
      panel.horizontal.selected.forEach((selected, pi) => { if (selected) selectedList.push(pi); });
      const horizontalCount = panel.horizontal.points.length;
      panel.vertical.selected.forEach((selected, pi) => { if (selected) selectedList.push(pi + horizontalCount); });
      if (selectedList) selectedPanels[segmentIndex] = selectedList;
    });

    this.setState({ selectedObjects: { ...selectedObjects, panels: selectedPanels } });
  }

  addToSelectedObjects = (index, objectType, removeOld = false) => {
    const { selectedObjects } = this.state;
    const objects = selectedObjects[objectType];

    let newObjects;
    if (!Array.isArray(objects)) newObjects = !removeOld && objects ? JSON.parse(JSON.stringify(objects)) : {};
    else newObjects = !removeOld && objects ? [...objects] : [];

    const items = Array.isArray(index) ? index : [index];

    items.forEach((s) => {
      if (typeof (s) !== 'number') {
        const [pi, vi] = s.split('-');
        const nvi = parseInt(vi, 10);
        if (!(pi in newObjects)) newObjects[pi] = [];
        if (!newObjects[pi].includes(nvi)) newObjects[pi].push(nvi);
      } else if (!newObjects.includes(s)) newObjects.push(s);
    });

    Object.keys(newObjects).reverse().forEach((i) => { if (newObjects[i] === undefined) delete newObjects[i]; });

    this.setState({ selectedObjects: { ...selectedObjects, [objectType]: newObjects } });

    switch (objectType) {
      case 'panels':
        this.syncSelectedPanelFrom3D();
        break;
      case 'roofSegments':
        this.syncSelectedRoofFrom3D();
        break;
      default:
        break;
    }
  }

  removeFromSelectedObjects = (index, objectType) => {
    const { selectedObjects } = this.state;
    const objects = selectedObjects[objectType];

    let newObjects;
    if (!Array.isArray(objects)) newObjects = objects ? JSON.parse(JSON.stringify(objects)) : {};
    else newObjects = objects ? [...objects] : [];

    const items = Array.isArray(index) ? index : [index];

    items.forEach((s) => {
      if (typeof (s) !== 'number') {
        const [pi, vi] = s.split('-');
        const nvi = parseInt(vi, 10);
        if (!(pi in newObjects)) return;
        if (newObjects[pi].includes(nvi)) newObjects[pi].splice(newObjects[pi].indexOf(nvi), 1);
      } else if (newObjects.includes(s)) newObjects.splice(newObjects.indexOf(s), 1);
    });

    Object.keys(newObjects).reverse().forEach((i) => { if (newObjects[i] === undefined) delete newObjects[i]; })

    this.setState({ selectedObjects: { ...selectedObjects, [objectType]: newObjects } });

    switch (objectType) {
      case 'panels':
        this.syncSelectedPanelFrom3D();
        break;
      case 'roofSegments':
        this.syncSelectedRoofFrom3D();
        break;
      default:
        break;
    }
  }

  clearSelection = (objectType, forceClear = false) => {
    let notExist = false;
    const { selectedObjects, segments, panels } = this.state;
    const objects = selectedObjects[objectType];
    if (!Array.isArray(objects)) {
      notExist = Object.keys(objects).length === 0;
      notExist = notExist || Object.keys(objects).every((s) => objects[s].length === 0)
    } else {
      notExist = selectedObjects[objectType].length === 0;
    }

    if (!notExist || forceClear) {
      this.setState({
        selectedObjects: {
          roofSegments: selectedObjects.roofSegments.length ? [] : selectedObjects.roofSegments,
          roofVertices: Object.keys(selectedObjects.roofVertices).length ? {} : selectedObjects.roofVertices,
          roofLines: Object.keys(selectedObjects.roofLines).length ? {} : selectedObjects.roofLines,
          obstacles: selectedObjects.obstacles.length ? [] : selectedObjects.obstacles,
          fireSetbacks: Object.keys(selectedObjects.fireSetbacks).length ? {} : selectedObjects.fireSetbacks,
          panels: Object.keys(selectedObjects.panels).length ? {} : selectedObjects.panels,
          trees: selectedObjects.trees.length ? [] : selectedObjects.trees,
        },
      });
    } else {
      let selectedObjs = {};
      switch (objectType) {
        case 'roofSegments':
          selectedObjs = [];
          segments.forEach((segment, segmentIndex) => {
            selectedObjs.push(segmentIndex);
          });
          break;
        case 'roofVertices':
        case 'fireSetbacks':
          segments.forEach((segment, segmentIndex) => {
            selectedObjs[segmentIndex] = [];
            segment.geometry.forEach((vertex, vertexIndex) => {
              selectedObjs[segmentIndex].push(vertexIndex);
            });
          });
          break;
        case 'panels':
          panels.forEach((p, pi) => {
            selectedObjs[pi] = [];
            const allPanels = [...p.vertical.points, ...p.horizontal.points];
            allPanels.forEach((pl, pli) => {
              selectedObjs[pi].push(pli);
            });
          });
          break;
        default:
          break;
      }
      this.setState({ selectedObjects: { ...selectedObjects, [objectType]: selectedObjs } });
    }

    switch (objectType) {
      case 'panels':
        this.syncSelectedPanelFrom3D();
        break;
      case 'roofSegments':
        this.syncSelectedRoofFrom3D();
        break;
      default:
        break;
    }
  }

  deleteSelectedObjects = (objectType) => {
    const { segments, selectedObjects } = this.state;
    switch (objectType) {
      case 'roofSegments': {
        const newSegments = [...segments];
        selectedObjects.roofSegments.sort().reverse().forEach((index) => {
          newSegments.splice(index, 1);
        });
        this.setState3x({ segments: newSegments });
        break;
      }
      default:
        break;
    }
  }

  setHeight = (value) => {
    const { segments, selectedObjects } = this.state;
    const newSegments = [...segments];
    selectedObjects.roofSegments.forEach((index) => {
      if (value === '+') newSegments[index].height = (Number(newSegments[index].height) + 1).toFixed(2);
      else if (value === '-') newSegments[index].height = Math.max(0, (Number(newSegments[index].height) - 1)).toFixed(2);
      else newSegments[index].height = Math.max(0, Number(value)).toFixed(2);
    });
    this.setState3x({ segments: newSegments });
  };

  setPitch = (value, i) => {
    const { segments, selectedObjects } = this.state;
    const newSegments = [...segments];
    selectedObjects.roofSegments.forEach((index) => {
      if (value === '+') newSegments[index].pitch = (Number(newSegments[index].pitch) + 1).toFixed(2);
      else if (value === '-') newSegments[index].pitch = Math.max(0, (Number(newSegments[index].pitch) - 1)).toFixed(2);
      else newSegments[index].pitch = Math.max(0, Number(value)).toFixed(2);
    });
    this.setState3x({ segments: newSegments });
  }

  setSetback = (length, changeClockwise) => {
    const { segments, obstacles, selectedObjects, setback } = this.state;
    const newSegments = [...segments];
    const newObstacles = [...obstacles];
    let changeSegments = false;
    let changeObstacles = false;
    const segLen = newSegments.length;
    Object.keys(selectedObjects.fireSetbacks).forEach((pi) => {
      if (pi < segLen) {
        const len = newSegments[pi].geometry.length;
        if (!newSegments[pi].setbacks) newSegments[pi].setbacks = new Array(len).fill(setback);
        selectedObjects.fireSetbacks[pi].forEach((vi) => {
          if (changeClockwise[pi]) newSegments[pi].setbacks[len - vi - 1] = parseFloat(length);
          else newSegments[pi].setbacks[vi] = parseFloat(length);
        });
        newSegments[pi].setbacks = [...newSegments[pi].setbacks];
        changeSegments = true;
      } else {
        newObstacles[pi - segLen].setback = parseFloat(length);
        changeObstacles = true;
      }
    });
    if (changeSegments) { this.setState3x({ segments: newSegments }); this.context.CanvasAPI.saveCanvas({ segments: newSegments }); }
    if (changeObstacles) { this.setState3x({ obstacles: newObstacles }); this.context.CanvasAPI.saveCanvas({ obstacles: newObstacles }); }
  }

  setShadingStatus = (status) => {
    this.setState2x({ shadingStatus: status });
  }

  setShadingIsRunning = (running) => {
    this.setState({ shadingIsRunning: running });
  }

  setServerSideShadingIsRunning = (running) => {
    this.setState({ serverSideShadingIsRunning: running });
  }

  setShadingTexture = (texture, month) => {
    this.setState2x({
      showOverlay: true, shadingMonth: month, shadingTexture: texture,
    });
  }

  moveRoofVertex = (x, y) => {
    const { segments, selectedObjects } = this.state;
    const newSegments = [...segments];
    Object.keys(selectedObjects.roofVertices).forEach((index) => {
      selectedObjects.fireSetbacks[index].forEach((vi) => {
        const vertex = newSegments[index].geometry[vi];
        vertex[0] += x;
        vertex[1] += y;
      });
    });
    this.setState3x({ segments: newSegments });
  }

  setSegmentPosition = (vertices, roofAttributes) => {
    const { segments } = this.state;
    const newSegments = [...segments];
    const filteredMergedSegments = setSegmentAttributes(vertices, roofAttributes, newSegments);
    filteredMergedSegments.forEach((seg, segIndex) => {
      const P = Math.tan(newSegments[segIndex].pitch * 0.0174533) * 12;
      const slopeFactor = Math.sqrt(P * P + 12 * 12) / 12;
      const segArea = areaPolygon(newSegments[segIndex].geometry) * slopeFactor;
      const segAreaSqm = (segArea * samplingRate) / 10000;
      const area = convertUnits(segAreaSqm).from('m2').to('ft2');
      newSegments[segIndex].area = area;
    });
    this.setState3x({ segments: filteredMergedSegments });
    setTimeout(() => {
      this.setState3x({ optimizer1IsRunning: false, optimizer2IsRunning: false });
    }, 500);
  }

  setSegmentParameters = (changedSegments) => {
    const { segments } = this.state;
    const newSegments = [...segments];
    newSegments.forEach((seg, segIndex) => {
      if (changedSegments[segIndex]) {
        newSegments[segIndex].pvwatts_update = true;
        newSegments[segIndex].pitch = Math.min(69.99, Number(changedSegments[segIndex].pitch).toFixed(2));
        newSegments[segIndex].azimuth = Number(changedSegments[segIndex].azimuth).toFixed(2);
        newSegments[segIndex].height = Number(changedSegments[segIndex].height).toFixed(2);
        const P = Math.tan(newSegments[segIndex].pitch * 0.0174533) * 12;
        const slopeFactor = Math.sqrt(P * P + 12 * 12) / 12;
        const segArea = areaPolygon(newSegments[segIndex].geometry) * slopeFactor;
        const segAreaSqm = (segArea * samplingRate) / 10000;
        const area = convertUnits(segAreaSqm).from('m2').to('ft2');
        newSegments[segIndex].area = area;
      }
    });
    // const filteredNewSegments = newSegments.filter((el) => (el.pitch < 69 && el.height > 2.5));
    this.setState3x({ segments: newSegments });
    setTimeout(() => {
      this.setState3x({ measureIsRunning: false, optimizer1IsRunning: false, optimizer2IsRunning: false });
    }, 500);
  }

  setPanelsOrientation = (changedSegments) => {
    const { segments } = this.state;
    segments.forEach((seg, segIndex) => {
      const orientation = Number(changedSegments[segIndex].panelOrientation || segments[segIndex].azimuth);
      if (seg?.panelOrientation !== orientation) seg.panelOrientation = orientation;
    });
  };

  changeEdgeTypes = (changedSegments) => {
    const { segments } = this.state;
    const newSegments = [...segments];
    newSegments.forEach((s, si) => { s.lines = [...changedSegments[si].lines]; });
    this.setStatex({ segments: newSegments }, () => {
      segments.forEach((seg, idx) => {
        this.getAzimuthFromLineLabel(idx);
      });
    });
    setTimeout(() => {
      this.setState3x({ edgeDetectionIsRunning: false });
    }, 500);
  };

  setPanelPlacement = (panels) => {
    this.setState3x({ panels });
    setTimeout(() => {
      this.setState3x({ panelPlacementIsRunning: false });
    }, 500);
    const { iframe, callback: iframeCallback } = this.props;
    if (iframeCallback) iframeCallback('PanelPlacement', '');
  }

  handleObstacleRadius = (oi, v) => {
    const { obstacles } = this.state;
    const newObstacle = [...obstacles];
    newObstacle[oi].radius = parseFloat(v);
    this.setState3x({ obstacles: newObstacle });
  }

  handleObstacleHeight = (oi, v) => {
    const { obstacles } = this.state;
    const newObstacle = [...obstacles];
    newObstacle[oi].height = parseFloat(v);
    this.setState3x({ obstacles: newObstacle });
  }

  handleObstacleSetback = (oi, v) => {
    const { obstacles } = this.state;
    const newObstacle = [...obstacles];
    newObstacle[oi].setback = parseFloat(v);
    this.setState3x({ obstacles: newObstacle });
  }

  setSegmentShadingFactors = ([tsrf, tof, solarAccess]) => {
    const { segments } = this.state;
    const newSegments = [...segments];
    newSegments.forEach((seg, segIndex) => {
      seg.solarAccess = solarAccess[segIndex];
      seg.tsrf = tsrf[segIndex];
      seg.tof = tof[segIndex];
    });
    this.setState3x({ segments: newSegments });
  }

  setSegmentShadingFactorsServerSide = (segmentsShadingFactors) => {
    const { segments } = this.state;
    const newSegments = [...segments];
    newSegments.forEach((seg, segIndex) => {
      seg.solarAccess = segmentsShadingFactors[segIndex].solarAccess;
      seg.tsrf = segmentsShadingFactors[segIndex].tsrf;
      seg.tof = segmentsShadingFactors[segIndex].tof;
    });
    this.setState3x({ segments: newSegments });
  }

  setOptPitchAndAzimuth = (pitch, azimuth) => {
    const { updateLead, lead: { property } } = this.context;
    const optPitchAndAzimuth = { pitch, azimuth };
    updateLead({ property: { ...property, optimal_roof: optPitchAndAzimuth } });
  }

  setDSMParams = (DSMParams) => {
    const { updateLead, lead: { property } } = this.context;
    updateLead({ property: { ...property, dsm_params: DSMParams } });
  }

  goFullScreen = (e) => {
    this.setState({ isFullScreen: e });
  }

  capture_image = async () => {
    const image_data = await this.canvas3DElement.exportToJPG();
    return image_data;
  }

  newAnnotation = (position, type, params={}) => {
    const { annotations } = this.state;
    const typeName = type.toLowerCase();
    const typeCaption = typeName == 'custom' ? "Annotation" : type;
    const typeLength = annotations.filter((a) => a.type.toLowerCase() === typeName).length;
    const newAnnotations = [...annotations];
    newAnnotations.push({ position: { ...position }, text: `${typeCaption}_${typeLength + 1}`, type, ...params });
    this.setAnnotations(newAnnotations);
  };

  setAnnotations = (annotations) => {
    this.setState3x({ annotations });
    this.context.CanvasAPI.saveCanvas({ "annotations": annotations });
  };

  removeAnnotation = (index) => {
    const { annotations } = this.state;
    const newAnnotations = [...annotations];
    newAnnotations.splice(index, 1);
    this.setAnnotations(newAnnotations);
  }

  getUIState = () => {
    const {
      currentAccordion,
      tool,
      designType,
      buffer,
      rowSpacing,
      panelTilt,
      setbackMode,
      fireSetbacks,
      mode,
      moveMode,
      viewMode,
      selectionMode,
      coordinates,
      optPitchAndAzimuth,
      segments,
      obstacles,
      trees,
      panels,
      annotations,
      measureIsRunning,
      optimizer1IsRunning,
      optimizer2IsRunning,
      edgeDetectionIsRunning,
      AIIsRunning,
      panelPlacementIsRunning,
      shadingMonth,
      shadingStatus,
      solarPanels,
      selectedRoof,
    } = this.state;

    const data = {
      currentAccordion,
      tool,
      designType,
      buffer,
      rowSpacing,
      panelTilt,
      setbackMode,
      fireSetbacks,
      mode,
      moveMode,
      viewMode,
      selectionMode,
      coordinates,
      optPitchAndAzimuth,
      segments,
      obstacles,
      trees,
      panels,
      annotations,
      package: this.state.package,
      measureIsRunning,
      optimizer1IsRunning,
      edgeDetectionIsRunning,
      AIIsRunning,
      panelPlacementIsRunning,
      shadingMonth,
      shadingStatus,
      solarPanels,
      selectedRoof,
    };

    return data;
  }

  setStatex = (data, callback, stateNames) => {
    const isFunc = data instanceof Function;
    if (isFunc) this.saveState(data, stateNames, true);
    else this.saveState(data);

    if (!callback) this.setState(data);
    else this.setState(data, callback);

    if (isFunc && stateNames) {
      setTimeout(() => {
        const stateValues = Object.assign({}, ...stateNames.map((x) => ({ [x]: this.state[x] })));
        this.callbackDesignerIframe(stateValues);
        this.saveState(stateValues, stateNames, false, true);
      }, 500);
    } else if (typeof data === 'object') {
      this.callbackDesignerIframe(data);
    }
  }

  setState2x = (data) => {
    this.saveState(data);
    this.setState(data);
    this.callbackDesignerIframe(data);
  }

  setState3x = (data, saveState=true) => {
    if (saveState) this.saveState(data);
    this.setState(data);
    this.callbackDesignerIframe(data, false);
  }

  callbackDesignerIframe = (data, check = true) => {
    const { iframe, designFrame, callback: iframeCallback } = this.props;
    if (iframe && designFrame && iframeCallback) {
      if (!check) {
        iframeCallback('State', data);
      } else {
        const {
          tool,
          designType,
          segments,
          obstacles,
          trees,
          panels,
          annotations,
          solarPanels,
          buffer,
          rowSpacing,
          panelTilt,
          automaticAzimuth,
          setbackMode,
          fireSetbacks,
          mode,
          selectedLabel,
          moveMode,
          currentAccordion,
          viewMode,
          selectionMode,
          coordinates,
          optPitchAndAzimuth,
          pitchUnit,
          shadingMonth,
          shadingStatus,
          package: package_,
          selectedRoof,
        } = data;

        const filteredData = {
          tool,
          designType,
          segments,
          obstacles,
          trees,
          panels,
          annotations,
          // solarPanels,
          buffer,
          rowSpacing,
          panelTilt,
          automaticAzimuth,
          setbackMode,
          fireSetbacks,
          mode,
          selectedLabel,
          moveMode,
          currentAccordion,
          viewMode,
          selectionMode,
          coordinates,
          optPitchAndAzimuth,
          pitchUnit,
          shadingMonth,
          shadingStatus,
          package: package_,
          selectedRoof,
        };

        Object.keys(filteredData).forEach((key) => (filteredData[key] === undefined ? delete filteredData[key] : {}));
        if (filteredData) iframeCallback('State', filteredData);
      }
    }
  }

  setHistoryEnabled = (enabled) => {
    this.historyState.state = enabled ? 'enabled' : 'disabled';
  }

  undo = () => {
    if (this.histories.length && this.historyState.current >= 0 && this.historyState.state !== 'disabled') {
      this.historyState.current = Math.max(this.historyState.current - 1, 0);
      this.applyState(true);
    }
  }

  redo = () => {
    if (this.histories.length && this.historyState.current < this.histories.length && this.historyState.state !== 'disabled') {
      this.historyState.current = Math.min(this.historyState.current + 1, this.histories.length - 1);
      this.applyState(false);
    }
  }

  applyState = () => {
    const historyItem = this.histories[this.historyState.current];
    if (!historyItem) return;
    const data = {};
    Object.keys(historyItem).forEach((k) => { data[k] = JSON.parse(historyItem[k]); });
    this.setState3x(data, false);
  }

  saveState = (data, keys=null, onlySaveOld=false, updateOld=false) => {
    if (!(this.canvas3DElement && this.canvas3DElement.isLoaded)) return;
    const validKeys = ['segments', 'obstacles', 'trees', 'annotations', 'setbackMode', 'fireSetbacks'];
    const previousHistoryItem = {};
    const historyItem = {};
    const maxSteps = 100;
    if (!keys) keys = Object.keys(data);
    keys.forEach((key) => {
      if (validKeys.includes(key)) {
        const oldValue = JSON.stringify(this.state[key]);
        if (onlySaveOld) {
          previousHistoryItem[key] = oldValue;
          return;
        }
        const newValue = JSON.stringify(data[key]);
        if (updateOld) historyItem[key] = newValue;
        else if (oldValue !== newValue) {
          previousHistoryItem[key] = oldValue;
          historyItem[key] = newValue;
        }
      }
    });
    const currentCount = Object.keys(historyItem).length;
    const previousCount = Object.keys(previousHistoryItem).length;
    if (currentCount || previousCount) {
      const initHistory = this.histories.length == 0;
      if (!initHistory) {
        const oldKeys = Object.keys(this.histories[this.histories.length - 1]);
        const mergeState = keys.some((x) => !oldKeys.includes(x));
        if (mergeState) this.histories[this.histories.length - 1] = { ...this.histories[this.histories.length - 1], ...previousHistoryItem };
      }
      const items = [];
      if (previousCount && initHistory) items.push(previousHistoryItem);
      if (currentCount) items.push(historyItem);
      if (items.length) {
        this.histories.splice(this.historyState.current + 1, maxSteps, ...items);
        if (this.histories.length > maxSteps) this.histories.shift();
        this.historyState.current = this.histories.length - 1;
      }
    }
  }

  checkIsLoaded = () => {
    return this.canvas3DElement.isLoaded;
  };

  checkIsShadingRunning = () => {
    return this.state.shadingIsRunning;
  };

  checkIsPVWattsHandled = () => {
    return this.state.pvwattsHandled;
  };

  render() {
    const {
      iframe, designFrame, companyName, callback: iframeCallback, context: iframeContext = {}
    } = this.props;

    const { panelNotSet } = this.state;
    if (panelNotSet) return <></>;

    // if (Object.keys(this.state.package).length === 0) {
    //   return !iframe ? (
    //     <Alert variant="warning">
    //       <Alert.Heading>No Installation Package Was Found</Alert.Heading>
    //       <p>
    //         It looks like you tried to create a design without having configured an
    //         installation package in your settings.
    //         <br />
    //         It will only take a minute, you&apos;re almost there!
    //       </p>
    //     </Alert>
    //   ) : <p>No Installation Package Was Found</p>;
    // }

    const { lead: { property }, validUtility } = this.context;
    if (!property?.coordinates && !iframe) {
      // && property?.effectiveRate === undefined
      // && property?.fixedCharge === undefined)) {
      return (
        <Alert className="w-75 m-5" variant="info">
          <InfoCircle size={20} className="mr-2" />
          Property Details, and Utility Details must be filled out before creating a design.
        </Alert>
      );
    }

    const hasDSM = property.dsm != null;

    const {
      isFullScreen,
      overlay,
      showOverlay,
      scale,
      resolution,
      currentAccordion: accordion,
      tool,
      segments,
      obstacles,
      trees,
      panels,
      annotations,
      buffered,
      selectedRoof,
      selectedLabel,
      selectedPanels,
      shade,
      buffer,
      rowSpacing,
      panelTilt,
      fireSetbacks,
      mode,
      moveMode,
      setbackMode,
      polyEdit,
      focusedPolygonToEdit,
      colorRange,
      designType,
      package: { panel: panelHardware, inverter: inverterHardware },
      shadingMonth,
      shadingStatus,
      viewMode,
      coordinates,
      setback,
      selectionMode,
      selectedObjects,
      shadingTexture,
      shadingIsRunning,
      serverSideShadingIsRunning,
      optimizer1IsRunning,
      optimizer2IsRunning,
      edgeDetectionIsRunning,
      measureIsRunning,
      panelPlacementIsRunning,
      AIIsRunning,
      solarPanels,
      inverters,
      automaticAzimuth,
      updateRGBTextureIsRunning,
    } = this.state;

    // value that is passed to context indicating id client's device is running iOS
    const isIos = ios();

    const fullAddress = `${property.street}, ${property.city}, ${property.state}`;
    const canvasData = canvasKeys.includes(accordion) ? this.state[accordion] : [];
    const ISODate = new Date().toISOString();
    const overlaySrc = `${overlay}?iso=${ISODate}`;
    const size = CANVAS_SIZE * scale;

    const show2D = viewMode === '2D';
    const showPlacePanels = konvaKeys.includes(accordion);
    const currentPanel = panelHardware;

    const providerValues = {
      coordinates,
      resolution: property.resolution,
      buffer,
      rowSpacing,
      panelTilt,
      setback,
      accordion,
      panelType: currentPanel,
      selectionMode,
      selectedObjects,
      shadingIsRunning,
      serverSideShadingIsRunning,
      optimizer1IsRunning,
      optimizer2IsRunning,
      edgeDetectionIsRunning,
      measureIsRunning,
      panelPlacementIsRunning,
      AIIsRunning,
      optPitchAndAzimuth: property.optimal_roof,
      dsmParams: property.dsm_params,
      optPOA: property.optimal_poa,
      shadingMonth,
      shadingStatus,
      viewMode,
      iframe,
      designFrame,
      mode,
      tool,
      selectedLabel,
      setRoofLineLabel: this.setRoofLineLabel,
      ios: isIos,
      moveMode,
      automaticAzimuth,
      designType,
      updateRGBTextureIsRunning,
      annotations,
      setSelectionMode: this.setSelectionMode,
      addToSelectedObjects: this.addToSelectedObjects,
      removeFromSelectedObjects: this.removeFromSelectedObjects,
      clearSelection: this.clearSelection,
      setHeight: this.setHeight,
      setPitch: this.setPitch,
      setSetback: this.setSetback,
      deleteSelectedObjects: this.deleteSelectedObjects,
      setSegmentPosition: this.setSegmentPosition,
      changeEdgeTypes: this.changeEdgeTypes,
      setPanelPlacement: this.setPanelPlacement,
      moveRoofVertex: this.moveRoofVertex,
      setSegmentShadingFactors: this.setSegmentShadingFactors,
      setSegmentShadingFactorsServerSide: this.setSegmentShadingFactorsServerSide,
      setShadingTexture: this.setShadingTexture,
      setShadingStatus: this.setShadingStatus,
      setShadingIsRunning: this.setShadingIsRunning,
      setServerSideShadingIsRunning: this.setServerSideShadingIsRunning,
      setOptPitchAndAzimuth: this.setOptPitchAndAzimuth,
      setSegmentParameters: this.setSegmentParameters,
      setDSMParams: this.setDSMParams,
      automaticAzimuthMode: this.automaticAzimuthMode,
      setPanelsOrientation: this.setPanelsOrientation,
      setViewMode: this.switchView,
      setMode: this.handleMode,
      setMoveMode: this.handleMoveMode,
      handleNewShape: this.handleNewShape,
      handleSaveEdits: this.handleSaveEdits,
      handleAddPointToPolygon: this.handleAddPointToPolygon,
      handleRemovePointFromPolygon: this.handleRemovePointFromPolygon,
      handleDeleteShape: this.handleDeleteShape,
      handleLineLabel: this.handleLineLabel,
      mapsLink: this.mapsLink,
      undo: this.undo,
      redo: this.redo,
      setHistoryEnabled: this.setHistoryEnabled,
      setUpdateRGBTextureIsRunning: this.setUpdateRGBTextureIsRunning,
      setAnnotations: this.setAnnotations,
      newAnnotation: this.newAnnotation,
    };

    const viewerHeight = !iframe ? CANVAS_SIZE * SCALE + 2 : '100%';

    const handlers = isIos ? {
      onPointerDown: this.handleMouseDown,
      onPointerMove: this.handleMouseMove,
      onPointerUp: this.handleMouseUp,
      onPointerEnter: this.handleMouseEnter,
      onPointerLeave: this.handleMouseLeave,
    } : {
      onMouseDown: this.handleMouseDown,
      onMouseMove: this.handleMouseMove,
      onMouseUp: this.handleMouseUp,
      onMouseEnter: this.handleMouseEnter,
      onMouseLeave: this.handleMouseLeave,
    };


    // TODO: Remove after migrating old data from /media to /imagery
    function parsePropertyDataURL(property, type) {
      try {
        if (!property) return null
        const imagery = property[type]
        if(imagery && imagery.startsWith('pd_')) {
          if (type == 'rgb') {
            return `${process.env.REACT_APP_PROPERTY_DATA_RGB_PUBLIC_URL}/${imagery}/rgb.tiff`
          } else if (type == 'dsm') {
            return `${process.env.REACT_APP_PROPERTY_DATA_DSM_PUBLIC_URL}/${imagery}/dsm.tiff`
          }
        } else {
          return imagery.startsWith("http") ? imagery : `/media/${imagery}`
        }
      } catch (error) {
        console.log('Error parsing property imagery', error)
        return null
      }

    }

    let rgbImage = undefined
    let dsmImage = undefined
    if (property) {
      rgbImage = parsePropertyDataURL(property, 'rgb')
      dsmImage = parsePropertyDataURL(property, 'dsm')
    }

    const viewer = (
      <div
        aria-hidden="true"
        ref={(e) => { this.container = e; }}
        className="mw-100 position-relative rounded border border-primary overflow-auto"
        onWheel={this.handleMouseWheel}
        {...handlers}
        style={{
          maxHeight: viewerHeight,
          height: viewerHeight,
        }}
      >
        <Canvas3D
          ref={(canvas3D) => { this.canvas3DElement = canvas3D; }}
          visible
          imgSrc={rgbImage}
          dsmSrc={dsmImage}
          segments={segments}
          obstacles={obstacles}
          trees={trees}
          panels={panels}
          annotations={annotations || []}
          size={size}
          iframeCallback={iframeCallback}
          initViewMode={'2D'}
        />
        {(iframe && (companyName !== 'Astrawatt') && (companyName !== 'demand-iq')) && <IFrameLogo /> }
      </div>
    );

    if (iframe) {
      return (
        <DesignContext.Provider value={providerValues}>
          <Fullscreen isEnter={isFullScreen} onChange={(e) => this.setState({ isFullScreen: e })}>
            {viewer}
          </Fullscreen>
        </DesignContext.Provider>
      );
    }

    if (iframe && designFrame) {
      //
      return (
        <DesignContext.Provider value={providerValues}>
          <Row className="bg-light py-3 full-screenable-node font-vh g-0">
            <Col lg={8} md={12} s={12} xs={12} className="mb-5">
              <Controls
                mode={mode}
                moveMode={moveMode}
                setMode={this.handleMode}
                setMoveMode={this.handleMoveMode}
                resetScale={this.handleResetScale}
                scaleUp={this.handleScaleUp}
                scaleDown={this.handleScaleDown}
                mapsLink={this.mapsLink}
                viewMode={konvaKeys.includes(accordion) ? '3D' : viewMode}
                switchView={this.switchView}
              />
              {/* <Fullscreen isEnter={isFullScreen} onChange={(e) => this.setStatex({ isFullScreen: e })}> */}
              <div className="mh-100" style={{ height: 600 }}>
                {viewer}
              </div>
              {/* </Fullscreen> */}
              {/* <ControlsBottom
                saveCanvas={this.handleSaveAll}
                cleanCanvas={this.cleanCanvas}
                disabled={!accordion || konvaKeys.includes(accordion)}
              /> */}
            </Col>
            <Col lg={4} md={12} s={12} xs={12} className="mt-5 mw-100">
              <Menu
                activeKey={accordion}
                designType={designType}
                hasDSM={hasDSM}
                shaded={shade}
                buffer={buffer}
                rowSpacing={rowSpacing}
                panelTilt={panelTilt}
                fireSetbacks={fireSetbacks}
                setbackMode={setbackMode}
                serverSideShadingIsRunning={serverSideShadingIsRunning}
                setbackModeHandler={this.handleSetbackMode}
                fireSetbackHandler={this.handleFireSetbacks}
                colorRange={colorRange}
                currentAccordionHandler={this.handleCurrentAccordion}
                currentTabHandler={this.handleSegmentTabs}
                tool={tool}
                toolHandler={this.handleTool}
                selectedLabel={selectedLabel}
                selectedRoof={selectedRoof}
                selectedPanels={selectedPanels}
                selectedLabelHandler={this.handleSelectedLabel}
                clearLabelsHandler={this.clearLabelsHandler}
                deleteShapeHandler={this.handleDeleteShape}
                addPointToPolygonHandler={this.handleAddPointToPolygonFromMenu}
                removePointFromPolygonHandler={this.handleRemovePointFromPolygonFromMenu}
                obstacleHeightHandler={this.handleObstacleHeight}
                obstacleRadiusHandler={this.handleObstacleRadius}
                obstacleSetbackHandler={this.handleObstacleSetback}
                newPanelHandler={this.handleNewPanel}
                deletePanelHandler={this.handleDeletePanel}
                rotatePanelHandler={this.handleRotatePanel}
                setPVwattsLosses={this.setPVwattsLosses}
                segments={segments}
                resolution={resolution}
                segmentAzimuthHandler={this.handleSegmentAzimuth}
                segmentPitchAndAreaHandler={this.handleSegmentPitchAndArea}
                segmentEaveHeightHandler={this.handleSegmentEaveHeight}
                treeUpdateHandler={this.handleTreeUpdate}
                obstacles={obstacles}
                trees={trees}
                panels={panels}
                placementHandler={this.handlePlacement}
                shadingHandler={this.handleShading}
                measureHandler={this.handleMeasure}
                runOptimizationHandler={this.runOptimizationHandler}
                runEdgeDetectionHandler={this.runEdgeDetectionHandler}
                runAIHandler={this.runAIHandler}
                proposalHandler={this.handleProposal}
                panelAzimuthHandler={this.handlePanelAzimuth}
                currentPanel={panelHardware?.id}
                currentInverter={inverterHardware?.id}
                changePanelHardware={this.changePanelHardware}
                changeInverterHardware={this.changeInverterHardware}
                setbackHandler={this.handleSetback}
                bufferHandler={this.handleBuffer}
                rowSpacingHandler={this.handleRowSpacing}
                panelTiltHandler={this.handlePanelTilt}
                showOverlay={showOverlay}
                toggleOverlay={this.toggleOverlay}
                shadingMonth={shadingMonth}
                shadingStatus={shadingStatus}
                solarPanels={solarPanels}
                inverters={inverters}
              />
            </Col>
          </Row>
        </DesignContext.Provider>
      );
    }

    return (
      <DesignContext.Provider value={providerValues}>
        <Card className="shadow w-100 mt-3 bg-primary text-white" body>
          <Button
            className="float-right"
            onClick={() => this.setState({ isFullScreen: !isFullScreen })}
          >
            <ArrowsFullscreen />
          </Button>
          Here is your custom solar design for
          <h3 className="mt-2">{fullAddress}</h3>
        </Card>
        <Fullscreen isEnter={isFullScreen} onChange={(e) => this.setState({ isFullScreen: e })}>
          <Row className="bg-light py-3 full-screenable-node" noGutters>
            <Col xl={8} lg={12} md={12} s={12} xs={12} className="mb-3 pr-2">
              <Controls
                mode={mode}
                moveMode={moveMode}
                setMode={this.handleMode}
                setMoveMode={this.handleMoveMode}
                resetScale={this.handleResetScale}
                scaleUp={this.handleScaleUp}
                scaleDown={this.handleScaleDown}
                mapsLink={this.mapsLink}
                viewMode={konvaKeys.includes(accordion) ? '3D' : viewMode}
                switchView={this.switchView}
              />
              {viewer}
              <ControlsBottom
                saveCanvas={this.handleSaveAll}
                cleanCanvas={this.cleanCanvas}
                disabled={!accordion || konvaKeys.includes(accordion)}
              />
            </Col>
            <Col xl={4} lg={12} md={12} s={12} xs={12}>
              <Menu
                activeKey={accordion}
                designType={designType}
                hasDSM={hasDSM}
                shaded={shade}
                buffer={buffer}
                rowSpacing={rowSpacing}
                panelTilt={panelTilt}
                fireSetbacks={fireSetbacks}
                setbackMode={setbackMode}
                serverSideShadingIsRunning={serverSideShadingIsRunning}
                setbackModeHandler={this.handleSetbackMode}
                fireSetbackHandler={this.handleFireSetbacks}
                colorRange={colorRange}
                currentAccordionHandler={this.handleCurrentAccordion}
                currentTabHandler={this.handleSegmentTabs}
                tool={tool}
                toolHandler={this.handleTool}
                selectedLabel={selectedLabel}
                selectedRoof={selectedRoof}
                selectedPanels={selectedPanels}
                selectedLabelHandler={this.handleSelectedLabel}
                clearLabelsHandler={this.clearLabelsHandler}
                deleteShapeHandler={this.handleDeleteShape}
                addPointToPolygonHandler={this.handleAddPointToPolygonFromMenu}
                removePointFromPolygonHandler={this.handleRemovePointFromPolygonFromMenu}
                obstacleHeightHandler={this.handleObstacleHeight}
                obstacleRadiusHandler={this.handleObstacleRadius}
                obstacleSetbackHandler={this.handleObstacleSetback}
                newPanelHandler={this.handleNewPanel}
                deletePanelHandler={this.handleDeletePanel}
                rotatePanelHandler={this.handleRotatePanel}
                setPVwattsLosses={this.setPVwattsLosses}
                segments={segments}
                resolution={resolution}
                segmentAzimuthHandler={this.handleSegmentAzimuth}
                segmentPitchAndAreaHandler={this.handleSegmentPitchAndArea}
                segmentEaveHeightHandler={this.handleSegmentEaveHeight}
                treeUpdateHandler={this.handleTreeUpdate}
                obstacles={obstacles}
                trees={trees}
                panels={panels}
                placementHandler={this.handlePlacement}
                shadingHandler={this.handleShading}
                measureHandler={this.handleMeasure}
                runOptimizationHandler={this.runOptimizationHandler}
                runEdgeDetectionHandler={this.runEdgeDetectionHandler}
                runAIHandler={this.runAIHandler}
                proposalHandler={this.handleProposal}
                panelAzimuthHandler={this.handlePanelAzimuth}
                currentPanel={panelHardware?.id}
                currentInverter={inverterHardware?.id}
                changePanelHardware={this.changePanelHardware}
                changeInverterHardware={this.changeInverterHardware}
                setbackHandler={this.handleSetback}
                bufferHandler={this.handleBuffer}
                rowSpacingHandler={this.handleRowSpacing}
                panelTiltHandler={this.handlePanelTilt}
                showOverlay={showOverlay}
                toggleOverlay={this.toggleOverlay}
                shadingMonth={shadingMonth}
                shadingStatus={shadingStatus}
                solarPanels={solarPanels}
                inverters={inverters}
              />
            </Col>
          </Row>
        </Fullscreen>
        <ShadeReport
          wattage={panelHardware?.power || 0}
          segments={segments}
          panels={panels}
          optPitchAndAzimuth={property.optimal_roof}
        />
      </DesignContext.Provider>
    );
  }
}

DesignTool.contextType = LeadContext;
export default DesignTool;
