/* eslint-disable */
import * as THREE from 'three';

import SunCalc from 'suncalc';

import { getFuzzyLocalTimeFromPoint } from '@mapbox/timespace';
import { sunPosition, gray2gradient, downloadCanvas } from './geometry';

export const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
export const weekDays = [[1, 2, 3, 4, 5, 6, 7], [8, 9, 10, 11, 12, 13, 14, 15], [16, 17, 18, 19, 20, 21, 22, 23], [24, 25, 26, 27, 28, 29, 30, 31]];
export const renderPerHour = 4;
export const MaximumInsolationInMonth = 250000;
export const MaximumInsolationInYear = 2450000;

export class Weather {
  coordinates = [];

  weatherData = null;

  optimalNormal = [];

  optPitchAndAzimuth = null;

  optimalInsolations = null;

  weatherURL = '';

  timezone = '';

  monthNames = monthNames;

  weekDays = weekDays;

  renderPerHour = renderPerHour;

  monthNames = monthNames;

  MaximumInsolationInMonth = MaximumInsolationInMonth;

  MaximumInsolationInYear = MaximumInsolationInYear;

  constructor(coordinates, weatherData = null) {
    this.weatherData = weatherData;
    if (coordinates) this.changeByCoordinate(coordinates);
  }

  load = (weatherURL) => new Promise((resolve, reject) => {
    if (weatherURL) {
      this.weatherURL = weatherURL;
      const scr = `${weatherURL}/irradiance.json`;
      fetch(scr)
        .then((res) => res.json())
        .then((json) => { this.weatherData = json; resolve(json); })
        .catch(() => reject());
    } else reject();
  })

  changeByCoordinate = (coordinates) => {
    this.coordinates = coordinates;
    let tz;
    const timestamp = Date.now();
    try { tz = getFuzzyLocalTimeFromPoint(timestamp, [coordinates[1], coordinates[0]])._z.name; } catch { tz = Intl.DateTimeFormat().resolvedOptions().timeZone; }
    this.timezone = tz;
  }

  loadByCoordinate = async (coordinates, weatherURL) => {
    this.changeByCoordinate(coordinates);
    await this.load(weatherURL);
  }

  changeTimezone = (date, ianatz) => {
    const invdate = new Date(date.toLocaleString('en-US', { timeZone: ianatz }));
    const diff = date.getTime() - invdate.getTime();
    return new Date(date.getTime() - diff);
  }

  getSunPositionByElevationAndAzimuth = (azimuthAngle, elevateAngle, distance = 150) => {
    const x = Math.cos(azimuthAngle) * Math.cos(elevateAngle) * distance;
    const z = Math.sin(azimuthAngle) * Math.cos(elevateAngle) * distance;
    const y = Math.sin(elevateAngle) * distance;
    return new THREE.Vector3(x, y, z);
  }

  getSunPosition = (time, distance = 150) => {
    const [el, az] = sunPosition(time, this.coordinates[0], this.coordinates[1]);
    const { azimuthAngle, elevateAngle } = { azimuthAngle: az - Math.PI / 2, elevateAngle: el };
    return this.getSunPositionByElevationAndAzimuth(azimuthAngle, elevateAngle, distance);
  }

  getSunPositionByTime = (m, d, h, minute) => {
    const date = new Date(Date.UTC(new Date().getFullYear(), m, d, h, minute, 0, 0));
    const sunTimes = SunCalc.getTimes(date, this.coordinates[0], this.coordinates[1]);
    const time = new Date(sunTimes.nadir);
    time.setTime(time.getTime() + (h * 60 * 60 * 1000) + (minute * 60 * 1000));
    const [altitude, azimuth] = sunPosition(time, this.coordinates[0], this.coordinates[1]);
    return [altitude, azimuth];
  }

  getNormalByAngles = (az, el) => {
    const [azx, elx] = [(az * Math.PI) / 180, (el * Math.PI) / 180];
    const { azimuthAngle, elevateAngle } = { azimuthAngle: azx - Math.PI / 2, elevateAngle: elx };
    const timeNormal = this.getSunPositionByElevationAndAzimuth(azimuthAngle, elevateAngle);
    timeNormal.normalize();
    return timeNormal;
  }

  getSunDirectionsAndIrradiances = () => {
    if (!this.weatherData.dn) return null;

    const normalAndIrradiance = [];
    const months = Object.keys(this.weatherData.dn);
    months.forEach((m) => {
      const monthIrradiance = [];
      const mIndex = monthNames.indexOf(m);
      const days = Object.keys(this.weatherData.dn[m]);
      days.forEach((d) => {
        const dIndex = Number(d);
        const hours = Object.keys(this.weatherData.dn[m][d]);
        hours.forEach((h) => {
          const hIndex = Number(h);
          const irradiance = this.weatherData.dn[m][d][h];
          const diffuseIrradiance = this.weatherData.df[m][d][h];
          if (irradiance || diffuseIrradiance) {
            const [altitude, azimuth] = this.getSunPositionByTime(mIndex, dIndex, hIndex, 30);
            const timeNormal = this.getSunPositionByElevationAndAzimuth(azimuth - Math.PI / 2, altitude);
            timeNormal.normalize();
            monthIrradiance.push([timeNormal, irradiance, diffuseIrradiance]);
          }
        });
      });
      normalAndIrradiance.push([mIndex, monthIrradiance]);
    });

    return normalAndIrradiance;
  }

  getPOAIrradiance = (timeNormal, normal, beemIrradiance, diffuseIrradiance) => {
    let irradiance = Math.max(0, timeNormal.dot(normal)) * beemIrradiance;
    const r = (Math.PI - ((Math.PI / 2) - Math.asin(normal.y))) / (Math.PI * 2);
    irradiance += r * diffuseIrradiance;
    return irradiance;
  }

  optimalPOAPitchAndAzimuth = () => {
    if (!this.weatherData.dn) {
      const azimuth = 180;
      const elevation = 60;
      const normal = this.getNormalByAngles(azimuth, elevation);
      return {
        normal, elevation, azimuth, insolation: 0,
      };
    }

    const initElevation = (90 - this.coordinates[0]);
    const initAzimuth = 180;
    const dirsAndIrradiances = this.getSunDirectionsAndIrradiances();

    const getTotalInsolation = (normal) => dirsAndIrradiances.map(([, irradiances]) => {
      const monthIrradiances = irradiances.map(([tn, bi, di]) => this.getPOAIrradiance(tn, normal, bi, di));
      return monthIrradiances.length ? monthIrradiances.reduce((a, b) => a + b) : 0;
    }).reduce((a, b) => a + b);

    let bestElevation = initElevation;
    let bestAzimuth = initAzimuth;
    let bestNormal = this.getNormalByAngles(bestAzimuth, bestElevation);
    let bestInsolation = getTotalInsolation(bestNormal);

    const precision = 0.01;
    let azimuthDelta = precision;
    let elevationDelta = precision;
    let found = false;
    do {
      if (!found) {
        azimuthDelta = precision;
        elevationDelta = precision;
      }
      found = false;
      let bestAzimuthDirection = 0;
      let bestElevationDirection = 0;
      for (let azimuthDeltaDirection = -1; azimuthDeltaDirection <= 1; azimuthDeltaDirection += 1) {
        for (let elevationDeltaDirection = -1; elevationDeltaDirection <= 1; elevationDeltaDirection += 1) {
          if (azimuthDeltaDirection || elevationDeltaDirection) {
            const azimuthGradiant = azimuthDelta * azimuthDeltaDirection;
            const elevationGradiant = elevationDelta * elevationDeltaDirection;
            const testAzimuth = bestAzimuth + azimuthGradiant;
            const testElevation = bestElevation + elevationGradiant;
            const testNormal = this.getNormalByAngles(testAzimuth, testElevation);
            const testInsolation = getTotalInsolation(testNormal);
            if (testInsolation > bestInsolation) {
              bestInsolation = testInsolation;
              bestElevation = testElevation;
              bestAzimuth = testAzimuth;
              bestNormal = testNormal;
              bestAzimuthDirection = azimuthDeltaDirection;
              bestElevationDirection = elevationDeltaDirection;
              found = true;
            }
          }
        }
      }
      if (found) {
        if (bestAzimuthDirection) azimuthDelta *= 2;
        if (bestElevationDirection) elevationDelta *= 2;
      }
    } while (found || azimuthDelta > precision || elevationDelta > precision);

    return {
      elevation: bestElevation, azimuth: bestAzimuth, normal: bestNormal, insolation: bestInsolation,
    };
  }

  getPOAirradianceByNormalVector = (normal) => {
    if (normal === null) normal = this.optimalNormal;
    const dirsAndIrradiances = this.getSunDirectionsAndIrradiances();

    const totalIrradiance = dirsAndIrradiances.map(([, irradiances]) => {
      const monthIrradiances = irradiances.map(([tn, bi, di]) => this.getPOAIrradiance(tn, normal, bi, di));
      return monthIrradiances.length ? monthIrradiances.reduce((a, b) => a + b) : 0;
    });

    return totalIrradiance;
  }

  getSunPositionWeekly = (m, hourIndex, minute, weekIndex, distance = 150) => {
    const days = weekDays[weekIndex];
    let [sumElevationX, sumElevationY, sumAzimuthX, sumAzimuthY] = [0, 0, 0, 0];

    days.forEach((d) => {
      const [altitude, azimuth] = this.getSunPositionByTime(m, d, hourIndex, minute);
      sumElevationX += Math.cos(altitude);
      sumAzimuthX += Math.cos(azimuth);
      sumElevationY += Math.sin(altitude);
      sumAzimuthY += Math.sin(azimuth);
    });

    const daysCount = days.length;

    const el = Math.atan2(sumElevationY / daysCount, sumElevationX / daysCount);
    const az = Math.atan2(sumAzimuthY / daysCount, sumAzimuthX / daysCount);

    const { azimuthAngle, elevateAngle } = { azimuthAngle: az - Math.PI / 2, elevateAngle: el };
    return this.getSunPositionByElevationAndAzimuth(azimuthAngle, elevateAngle, distance);
  }

  getInsolation = (m, week, hour, type) => {
    const monthName = monthNames[m - 1];
    let dn = 0;
    weekDays[week - 1].forEach((day) => {
      const d = (day - 1); // in this.weatherData[type][monthName] ? (day - 1) : Math.max(...Object.keys(this.weatherData[type][monthName]));
      if (d in this.weatherData[type][monthName])
        dn += this.weatherData[type][monthName][d][hour];
    });
    return dn;
  }

  getInsolationWeekly = (m, type) => {
    const df = [];
    weekDays.forEach((week, weekIndex) => {
      const dfWeek = [];
      for (let hour = 0; hour < 24; hour += 1) {
        const insolation = this.getInsolation(m, weekIndex + 1, hour, type);
        dfWeek.push(insolation);
      }
      df.push(dfWeek);
    });
    return df;
  }

  getInsolationByElevationAndAzimuth = (az, el) => {
    const [azx, elx] = [(az * Math.PI) / 180, (el * Math.PI) / 180];
    const { azimuthAngle, elevateAngle } = { azimuthAngle: azx - Math.PI / 2, elevateAngle: elx };
    const timeNormal = this.getSunPositionByElevationAndAzimuth(azimuthAngle, elevateAngle);
    timeNormal.normalize();
    return this.getPOAirradianceByNormalVector(timeNormal);
  }

  getAnglesByNormals = (normal) => {
    const el = Math.asin(normal.y);
    const az = (Math.PI / 2) + Math.atan2(normal.z, normal.x);
    return [(az * 180) / Math.PI, (el * 180) / Math.PI];
  }

  drawInsolation = () => {
    const canvas = document.createElement('canvas');
    canvas.width = 360;
    canvas.height = 90;
    const ctx = canvas.getContext('2d');
    const imageData = ctx.createImageData(canvas.width, canvas.height);
    for (let i = 0; i < canvas.width; i += 1) {
      const azimuth = i;
      for (let j = 0; j < canvas.height; j += 1) {
        const elevation = j;
        const index = (j * canvas.width + i) * 4;
        const intensity = this.getInsolationByElevationAndAzimuth(azimuth, elevation);
        const gray = intensity.reduce((a, b) => a + b) / 225;
        const color = gray2gradient(gray);
        [
          imageData.data[index + 0],
          imageData.data[index + 1],
          imageData.data[index + 2],
        ] = color;
        imageData.data[index + 3] = 255;
      }
    }
    ctx.putImageData(imageData, 0, 0);
    downloadCanvas(canvas, 'OptimalInsolation.png');
  }

  getOptPitchAndAzimuth = () => {
    const { normal: n, elevation: el, azimuth } = this.optimalPOAPitchAndAzimuth();
    const pitch = 90 - el;
    const normal = n;
    const insolations = this.getPOAirradianceByNormalVector(normal);
    return [pitch, azimuth, normal, insolations];
  }

  calcOptPitchAndAzimuth = () => {
    const [pitch, azimuth, normal, insolations] = this.getOptPitchAndAzimuth();
    this.optPitchAndAzimuth = [pitch, azimuth];
    this.optimalNormal = normal;
    this.optimalInsolations = insolations;
  }

  getDn = (m, week, hour) => this.getInsolation(m, week, hour, 'dn')

  getDf = (m, week, hour) => this.getInsolation(m, week, hour, 'df')

  getDnWeekly = (m) => this.getInsolationWeekly(m, 'dn')

  getDfWeekly = (m) => this.getInsolationWeekly(m, 'df')
}
