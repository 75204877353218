import React, {
  useState, useContext, useRef, useEffect,
} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Loading from 'components/Loading';
import LeadProvider, { LeadContext } from 'Leads/Details/hooks/context';
import AuthProvider from 'api/context';

import Designer from 'IFrame/Designer';
import Proposal from 'IFrame/Proposal';
import Home from 'IFrame/Home';
import Error from 'IFrame/Error';

import { inchToCm } from 'Leads/Details/DesignTool/utils';

import AuthManager, { client } from 'api/auth';

import { retry } from 'Leads/Details/DesignTool/utils';

import {
  assert, string, object, number, nonempty, integer, optional, boolean, max, enums, define, size, min, defaulted, array, nullable, union, pattern,
} from 'superstruct';

const coordinatesRegexExp = /^((-?|\+?)?\d+(\.\d+)?),\s*((-?|\+?)?\d+(\.\d+)?)$/gi;
const coordinates = () => define('coordinates', (value) => coordinatesRegexExp.test(value));

const IFrameCoverageAPIScheme = object({
  coordinates: pattern(nonempty(string()), /^((-?|\+?)?\d+(\.\d+)?),\s*((-?|\+?)?\d+(\.\d+)?)$/),
});

const IFrameNewLeadContactsScheme = object({
  first_name: optional(string()),
  last_name: optional(string()),
  email: optional(string()),
  phone: optional(string()),
});

const IFrameNewLeadPropertyProfile = object({
  roof_type: optional(string()),
  roof_age: optional(number())
});

const IFrameNewLeadUtilityScheme = object({
  provider: optional(string()),
  rate: optional(string()),
  fixed_charge_units: optional(string()), // $/month
  usage: number(),
  peak_usage: optional(number()),
  fixed_charge: optional(number()),
  rate_amount: optional(number()),
});

const IFrameNewLeadParamsScheme = object({
  contacts: optional(IFrameNewLeadContactsScheme),
  coordinates: pattern(nonempty(string()), /^((-?|\+?)?\d+(\.\d+)?),\s*((-?|\+?)?\d+(\.\d+)?)$/),
  electricity_usage: size(number(), 1000, Infinity),
  utility: optional(IFrameNewLeadUtilityScheme),
  monthly_bill: optional(number()),
  property_profile: optional(IFrameNewLeadPropertyProfile),
  type: optional(enums(['AI', 'Manual'])),
  rgb: optional(string()),
  dsm: optional(string()),
  resolution: optional(number()),
});

const IFrameRerunParamsScheme = object({
  uid: nonempty(string()),
});

const IFrameConfigScheme = object({
  setback: optional(union([integer(), array(number())])),
  buffer: optional(number()),
  panelOrientation: optional(number()),
  panelLayout: optional(number()),
  panelTilt: optional(number()),
  rowSpacing: optional(number()),
  companyPanelID: nullable(string()),
  companyInverterID: nullable(string()),
  losses: optional(object({
    soiling: size(number(), 0.0, 99.99),
    // shading: size(number(), 0.01, 99.99),
    snow: size(number(), 0.01, 99.99),
    mismatch: size(number(), 0.0, 99.99),
    wiring: size(number(), 0.01, 99.99),
    connections: size(number(), 0.0, 99.99),
    lid: size(number(), 0.0, 99.99),
    nameplate_rating: size(number(), 0.01, 99.99),
    age: size(number(), 0.0, 99.99),
    availability: size(number(), 0.0, 99.99),
  })),
  panelType: optional(object({
    manufacturer: object({ name: nonempty(string()) }),
    length: number(),
    width: number(),
    degradation: size(number(), 0.01, 99.99),
    efficiency: size(number(), 0.01, 99.99),
    has_micro: boolean(),
    model: nonempty(string()),
    power: integer(),
  })),
  panelDefault: optional(string()),
  inverter: optional(object({
    capacity: integer(),
    efficiency: size(number(), 0.01, 99.99),
    model: nonempty(string()),
    type: enums(['Micro', 'Central']),
  })),
  defaultBtnView: optional(object({
    ShadingSimulator: boolean(),
    Ground: boolean(),
    Trees: boolean(),
    FireSetbacks: boolean(),
    Panels: boolean(),
    House: boolean(),
    ShadingGradients: boolean(),
    DSM: boolean(),
    HouseDSM: boolean(),
    Annotation: optional(boolean())
  })),
});

const IFrame = () => {
  const designer = useRef();
  const proposal = useRef();

  const [errors, setErrors] = useState();
  const [auth] = useState(AuthManager);

  const [checkGradientsInterval, setCheckGradientsInterval] = useState();
  const [checkPVWattsInterval, setCheckPVWattsInterval] = useState();
  const [checkOnlyPVWattsInterval, setCheckOnlyPVWattsInterval] = useState();

  const location = useLocation();
  const { uid } = useParams();

  const pid = new URLSearchParams(location.search).get('id');
  const chain = new URLSearchParams(location.search).get('chain');
  const apiKey = new URLSearchParams(location.search).get('access-key');
  const designFrame = new URLSearchParams(location.search).get('design');

  const removeShadingGradients = (uid) => {
    if (uid === undefined) return;
    const api_key = auth.getApiKey();
    const headers = {
      Authorization: `Api-Key ${api_key}`,
    };

    client.post('designer/gradients/remove/', {
      uid,
    }, {
      headers,
    }).then((res) => {
      console.log(`Old shading gradients are removed: ${res.status}`);
    }).catch((err) => {
      console.log(`Error in removing shading gradients: ${err}`);
    });
  };

  const checkPVWatts = (uid, params) => {
    if (uid === undefined) return;
    const api_key = auth.getApiKey();
    const headers = {
      Authorization: `Api-Key ${api_key}`,
    };

    client.post('designer/pvwatts/check/', {
      uid,
    }, {
      headers,
    }).then(async (res) => {
      const state = res.data;

      if ((state === 'ok' && !designer.current.checkIsShadingRunning()) || designer.current.checkIsPVWattsHandled()) {
        setCheckPVWattsInterval((interval) => clearInterval(interval));
        await designer.current.handleShading().then(res => {
          const interval = setInterval(() => checkShadingGradients(params?.uid, params, true), 5000);
          setCheckGradientsInterval(interval);
        }).catch((err) => {
          console.log(`iframe shading error: ${err}`);
          return window.parent.postMessage({ callbackType: `Error`, data: { code: '52', message: 'Error in shading simulation' } }, '*');
        });

      } else { // PENDING / FAILURE
        console.log(`pvwatts state: ${state}`);
      }
    }).catch((err) => {
      console.log(`Shading gradients file state is unknown: ${err}`);
    });
  };

  const checkOnlyPVWatts = (uid) => {
    if (uid === undefined) return;
    const api_key = auth.getApiKey();
    const headers = {
      Authorization: `Api-Key ${api_key}`,
    };

    client.post('designer/pvwatts/check/', {
      uid,
    }, {
      headers,
    }).then(async (res) => {
      const state = res.data;

      if ((state === 'ok' && !designer.current.checkIsShadingRunning()) || designer.current.checkIsPVWattsHandled()) {
        setCheckOnlyPVWattsInterval((interval) => clearInterval(interval));
        await designer.current.handleShading().then(res => {}).catch((err) => {
          console.log(err);
          if (window.parent) return window.parent.postMessage({ callbackType: `Error`, data: { code: '52', message: 'Error in shading simulation' } }, '*');
        });

      } else { // PENDING / FAILURE
        console.log(`pvwatts state: ${state}`);
      }
    }).catch((err) => {
      console.log(`Shading gradients file state is unknown: ${err}`);
    });
  };

  const checkShadingGradients = (uid, params=null, loadAfter=false) => {
    if (uid === undefined) return;
    const api_key = auth.getApiKey();
    const headers = {
      Authorization: `Api-Key ${api_key}`,
    };

    client.post('designer/gradients/check/', {
      uid,
    }, {
      headers,
    }).then(async (res) => {
      const state = res.data;

      if (state === 'ok') {
        setCheckGradientsInterval((interval) => clearInterval(interval));
        try {
          await designer.current.handleProposal(params, loadAfter);
        } catch (err) {
          console.log(err);
          if (window.parent) return window.parent.postMessage({ callbackType: `Error`, data: { code: '53', message: 'Error in creating proposal' } }, '*');
        }

      } else { // PENDING / FAILURE
        console.log(`shading gradient state: ${state}`);
      }
    }).catch((err) => {
      console.log(`Shading gradients file state is unknown: ${err}`);
    });
  };

  const renameConfigKeys = (config) => {
    const renamedConfig = {};
    Object.keys(config).map((key) => {
      try {
        if (key === 'panelType' || key === 'panel') {
          const panelType = {};
          Object.keys(config[key]).map((panelKey) => {
            // if (panelKey === 'dimensions') {
            //   panelType.length = config[key].dimensions.length;
            //   panelType.width = config[key].dimensions.width;
            // } else if (panelKey === 'manufacturer') {
            //   const manufacturer = { name: config[key].manufacturer };
            //   panelType.manufacturer = manufacturer;
            // } else {
            //   panelType[panelKey] = config[key][panelKey];
            // }
            panelType[panelKey] = config[key][panelKey];
          });
          renamedConfig.panelType = panelType;
        } else {
          renamedConfig[key] = config[key];
        }
      } catch {
        designer.current.displayValidationErrors(`A Error exists in Config - Key: ${key}, Value: ${config[key]}`);
      }
    });
    return renamedConfig;
  };

  const callback = async (callbackType, data) => {
    if (chain === 'true' || callbackType === 'NewLead') {
      switch (callbackType) {
        case 'NewLead':
          designer.current.loadNewLead(data, true);
          break;
        case 'Loaded':
          await new Promise((r) => setTimeout(r, 6000));

          const emptySegments = designer.current.handleCurrentAccordion('segments');

          await new Promise((r) => setTimeout(r, 2000));

          if (!emptySegments) {
            if (window.parent) {
              return window.parent.postMessage({ callbackType: `Error`, data: { code: '42', message: '0 number of predicted roof segments' } }, '*');
            }
            return;
          }

          designer.current.runEdgeDetectionHandler(true);

          await new Promise((r) => setTimeout(r, 3000));

          // designer.current.handleMeasure();
          designer.current.handleCurrentAccordion('shading');

          await new Promise((r) => setTimeout(r, 500));

          try {
            const response = await retry(designer.current.handlePVWatts, 3);
          } catch (err) {
            console.log(`error in handling pvwatts - iframe: ${err}`);
            window.parent.postMessage({ callbackType: `Error`, data: { code: '51', message: 'Error in PVWatts request' } }, '*');
            break;
          }

          const interval = setInterval(() => checkOnlyPVWatts(uid), 4000);
          setCheckOnlyPVWattsInterval(interval);

          // designer.current.handleShading().then(res => {}).catch((err) => {
          //   return window.parent.postMessage({ callbackType: `Error`, data: { code: '52', message: 'Error in shading simulation' } }, '*');
          // });
          break;
        // case 'Measure':
        //   designer.current.handleCurrentAccordion('segments');
        //   // designer.current.runEdgeDetectionHandler(true);
        //   designer.current.runOptimizationHandler(false);
        //   break;
        // case 'EdgeLabeling':
        //   designer.current.handleCurrentAccordion('segments');
        //   designer.current.runOptimizationHandler(false);
        //   break;
        // case 'Optimize':
        //   designer.current.handleCurrentAccordion('shading');
        //   designer.current.handleShading();
        //   break;
        case 'Shading':
          designer.current.handleCurrentAccordion('revise');

          await new Promise((r) => setTimeout(r, 2500));

          try {
            await designer.current.handlePlacement(data);
          } catch (err) {
            return window.parent.postMessage({ callbackType: `Error`, data: { code: '54', message: 'Error in panel placement' } }, '*');
          }

          await new Promise((r) => setTimeout(r, 11000));

          // const shadingInterval = setInterval(() => checkShadingGradients(uid, null, false), 4000);
          // setCheckGradientsInterval(shadingInterval);

          try {
            await designer.current.handleProposal()
          } catch (err) {
            return window.parent.postMessage({ callbackType: `Error`, data: { code: '53', message: 'Error in creating proposal' } }, '*');
          }
          break;
        case 'ShadingCalcError':
          designer.current.handleCurrentAccordion('revise');
          await new Promise((r) => setTimeout(r, 500));

          try {
            await designer.current.handlePlacement(data);
          } catch (err) {
            return window.parent.postMessage({ callbackType: `Error`, data: { code: '54', message: 'Error in panel placement' } }, '*');
          }

          await new Promise((r) => setTimeout(r, 3000));

          try {
            await designer.current.handleProposal()
          } catch (err) {
            return window.parent.postMessage({ callbackType: `Error`, data: { code: '53', message: 'Error in creating proposal' } }, '*');
          }
          break;
        /*
        case 'PanelPlacement':
          setTimeout(() => designer.current.handleProposal(), 5250);
          // designer.current.handleProposal();
          break;
        */
        case 'NewProposal':
          proposal.current.loadProposal(uid, data);
          break;
        default:
          break;
      }
    } else if (callbackType === 'Error') {
      // setErrors(data);
      if (uid) {
        const api_key = auth.getApiKey();
        const headers = {
          Authorization: `Api-Key ${api_key}`,
        };
        client.post('iframe/debug/', {
          uid, data,
        }, {
          headers,
        })
          .catch((err) => {
            console.log('Error in sending back debug msg:', err);
          });
      }
    }
  };

  const [ctx, setCtx] = useState(() => {
    try {
      const cached = JSON.parse(localStorage.getItem('iframe.config'));
      return cached;
    } catch { return {}; }
  });

  const postMessage = (cmd, data) => {
    window.parent.postMessage({ callbackType: `${cmd}`, data }, '*');
  };

  const handleUICommand = (cmd, params, event) => {
    const d = designer?.current?.designer?.current;
    if (!d) return postMessage('Error', { message: 'lead not loaded' });
    switch (cmd) {
      case 'ui.switch_view':
        d.switchView(params.viewMode);
        break;
      case 'ui.get_state':
        postMessage('State', d.getUIState());
        break;
      case 'ui.set_current_accordion':
        d.handleCurrentAccordion(params.accordion);
        break;
      case 'ui.set_pitch_unit':
        break;
      case 'ui.measure':
        d.handleMeasure();
        break;
      case 'ui.optimize':
        d.runOptimizationHandler(false);
        break;
      case 'ui.edge_detection':
        d.runEdgeDetectionHandler(true);
        break;
      case 'ui.run_ai':
        d.runAIHandler();
        break;
      case 'ui.set_pitch':
        d.handleSegmentPitchAndArea(params.index, params.pitch);
        break;
      case 'ui.set_azimuth':
        d.handleSegmentAzimuth(params.index, params.azimuth);
        break;
      case 'ui.set_eave_height':
        d.handleSegmentEaveHeight(params.index, params.eave_height);
        break;
      case 'ui.set_all_pitch':
        d.handleSegmentPitchAndArea(-1, params.pitch);
        break;
      case 'ui.set_all_eave_height':
        d.handleSegmentEaveHeight(-1, params.eave_height);
        break;
      case 'ui.add_point':
        postMessage('State', d.getUIState());
        break;
      case 'ui.remove_point':
        postMessage('State', d.getUIState());
        break;
      case 'ui.remove_obj':
        d.handleDeleteShape(params.id);
        break;
      case 'ui.clear_labels':
        d.clearLabelsHandler();
        break;
      case 'ui.label_mode':
        d.handleSelectedLabel(params.label);
        break;
      case 'ui.mode':
        d.handleMode(params.mode);
        break;
      case 'ui.move_mode':
        d.handleMoveMode(params.move_mode);
        break;
      case 'ui.view_mode':
        d.switchView(params.view_mode);
        break;
      case 'ui.google_location':
        d.mapsLink();
        break;
      case 'ui.set_zoom_1x':
        d.handleResetScale();
        break;
      case 'ui.increase_zoom':
        d.handleScaleUp();
        break;
      case 'ui.decrease_zoom':
        d.handleScaleDown();
        break;
      case 'ui.save':
        d.handleSaveAll();
        break;
      case 'ui.clear_canvas':
        d.cleanCanvas();
        break;
      case 'ui.system_losses':
        if (params?.losses) d.setPVwattsLosses(params.losses);
        break;
      case 'ui.shading':
        d.handleShading();
        break;
      case 'ui.panel_placement':
        if (params?.solar_panel) d.changePanelHardware(params.solar_panel);
        // console.log(`ui.panel_placement params: ${JSON.stringify(params)}`);
        d.handlePlacement(params);
        break;
      case 'ui.shape':
        d.handleTool(params.shape);
        break;
      case 'ui.set_obstacle_height':
        d.handleObstacleHeight(params.index, params.obstacle_height);
        break;
      case 'ui.set_obstacle_radius':
        d.handleObstacleRadius(params.index, params.obstacle_radius);
        break;
      case 'ui.set_obstacle_setback':
        d.handleObstacleSetback(params.index, params.obstacle_setback);
        break;
      case 'ui.update_tree':
        d.handleTreeUpdate(params.tree, params.index);
        break;
      case 'ui.remove_annotation':
        d.removeAnnotation(params.index);
        break;
      case 'ui.set_setback_mode':
        d.handleSetbackMode(params.setback_mode);
        break;
      case 'ui.setback_value':
        d.handleFireSetbacks(params.setback, params.label);
        break;
      case 'ui.set_solar_panel':
        d.changePanelHardware(params.solar_panel);
        break;
      case 'ui.set_buffer':
        d.handleBuffer(params.buffer);
        break;
      case 'ui.set_panel_azimuth':
        d.handlePanelAzimuth(params.index, params.azimuth);
        break;
      case 'ui.set_panel_tilt':
        d.handlePanelTilt(params.panelTilt);
        break;
      case 'ui.auto_azimuth':
        d.automaticAzimuthMode(params.autoAzimuth);
        break;
      case 'ui.set_panel_row_spacing':
        d.handleRowSpacing(params.rowSpacing);
        break;
      case 'ui.capture_image':
        d.capture_image().then((data) => {
          if (window.parent) window.parent.postMessage({ callbackType: 'ui.capture_image', data }, '*');
        });
        break;
      default:
        break;
    }
  };

  const handleMessage = async (event) => {
    // if (event.origin !== null || !designer.current) return;
    const { cmd, params = null, config = null } = event.data;

    let amendedConfig = config

    if (cmd !== undefined) {
      if (cmd.startsWith('proposal.') && cmd === 'proposal.LoadProposal') {
        if (config) {
          amendedConfig = { ...config, companyPanelID: null, companyInverterID: null };
        }
      }
    }

    if (cmd !== undefined) {
      if (cmd.startsWith('ui.')) {
        handleUICommand(cmd, params, event);
        return;
      }
    }

    if (config) {
      // iframe config validation
      try {
        if (cmd === 'proposal.LoadProposal') {
          assert(amendedConfig, IFrameConfigScheme);
          localStorage.setItem('iframe.config', JSON.stringify(amendedConfig));
          setCtx(amendedConfig);
        } else {
          const renamed = renameConfigKeys(config);
          assert(renamed, IFrameConfigScheme);
          if (cmd === 'generate' || cmd === 'rerun' || cmd === 'rerun_panel_placement') {
            if (renamed?.companyPanelID === null || renamed?.companyPanelID === undefined) {
              if (renamed?.panelType) {

              } else {
                if (renamed?.panelDefault !== 'default') {
                  return window.parent.postMessage({ callbackType: `Error`, data: { code: '445', message: "Design couldn't start- iframe panel config is not set properly!" } }, '*');
                }
              }
            }
          }
          // convert buffer value from inch to mm
          renamed.buffer = inchToCm(renamed.buffer) * 10;
          localStorage.setItem('iframe.config', JSON.stringify(renamed));
          setCtx(renamed);
        }
      } catch (err) {
        return designer.current.displayValidationErrors(err);
      }
    }

    setErrors(null);

    // 'initiated' callbacks are for confirmation of received messaged
    if (cmd !== undefined) {
      if (cmd.startsWith('designer.') && designer.current) {
        if (window.parent) window.parent.postMessage({ callbackType: `${cmd}`, data: 'initiated' }, '*');
        designer.current.handleMessage(event);
      } else if (cmd.startsWith('proposal.') && proposal.current) {
        if (window.parent) window.parent.postMessage({ callbackType: `${cmd}`, data: 'initiated' }, '*');
        proposal.current.handleMessage(event);
      } else if (cmd === 'generate' && designer.current) {
        if (window.parent) window.parent.postMessage({ callbackType: `${cmd}`, data: 'initiated' }, '*');
        try {
          assert(params, IFrameNewLeadParamsScheme);
          // designer.current.loadLead(params.uid, true);
          designer.current.newLead(params);
        } catch (err) {
          designer.current.displayValidationErrors(err);
        }
      } else if (cmd === 'rerun' && designer.current) {
        if (window.parent) window.parent.postMessage({ callbackType: `${cmd}`, data: 'initiated' }, '*');
        try {
          assert(params, IFrameRerunParamsScheme);
          designer.current.runPrediction(params);
        } catch (err) {
          designer.current.displayValidationErrors(err);
        }
      } else if (cmd === 'rerun_panel_placement' && designer.current) {
        // method to rerun design only for changing panel fillMode
        if (window.parent) window.parent.postMessage({ callbackType: `${cmd}`, data: 'initiated' }, '*');
        designer.current.loadLeadPanelPlacement(params?.uid);

        await new Promise((r) => setTimeout(r, 5000));

        let tryCount = 3;
        while(true) {
          try {
            while (!designer.current?.checkIsLoaded()) {
              console.log('loading');
              await new Promise((r) => setTimeout(r, 1000));
            }
            break;
          } catch(err) {
            console.log(err);
          }
          await new Promise((r) => setTimeout(r, 5000));
          tryCount -= 1
        }

        designer.current.handleCurrentAccordion('revise');

        await new Promise((r) => setTimeout(r, 1500));

        try {
          await designer.current.handlePlacement();
        } catch (err) {
          console.log(err);
          if (window.parent) return window.parent.postMessage({ callbackType: `Error`, data: { code: '54', message: 'Error in panel placement' } }, '*');
        }

        await new Promise((r) => setTimeout(r, 5000));

        try {
          await designer.current.handleProposal(params, true)
        } catch (err) {
          console.log(err);
          if (window.parent) return window.parent.postMessage({ callbackType: `Error`, data: { code: '53', message: 'Error in creating proposal' } }, '*');
        }
      } else if (cmd === 'run_shading_and_create_proposal' && designer.current) {
        // method to run design from a pre-created lead that only needs shading to create proposal
        if (window.parent) window.parent.postMessage({ callbackType: `${cmd}`, data: 'initiated' }, '*');

        removeShadingGradients(params?.uid);

        designer.current.loadLeadPanelPlacement(params?.uid);

        await new Promise((r) => setTimeout(r, 5000));

        let tryCount = 3;
        while(true) {
          try {
            while (!designer.current?.checkIsLoaded()) {
              console.log('loading');
              await new Promise((r) => setTimeout(r, 1000));
            }
            break;
          } catch(err) {
            console.log(err);
          }
          await new Promise((r) => setTimeout(r, 5000));
          tryCount -= 1
        }

        const emptySegments = designer.current.handleCurrentAccordion('segments');

        await new Promise((r) => setTimeout(r, 1000));

        if (!emptySegments) {
          if (window.parent) {
            return window.parent.postMessage({ callbackType: `Error`, data: { code: '42', message: '0 number of predicted roof segments' } }, '*');
          }
          return;
        }

        designer.current.handleCurrentAccordion('shading');

        try {
          const response = await retry(designer.current.handlePVWatts, 3);
        } catch (err) {
          console.log(`error in handling pvwatts - iframe: ${err}`);
          return window.parent.postMessage({ callbackType: `Error`, data: { code: '51', message: 'Error in PVWatts request' } }, '*');
        }

        const interval = setInterval(() => checkPVWatts(params?.uid, params), 4000);
        setCheckPVWattsInterval(interval);

        // designer.current.handleShading().then(res => {
        //   const interval = setInterval(() => checkShadingGradients(params?.uid, params), 2500);
        //   setCheckGradientsInterval(interval);
        // }).catch((err) => {
        //   console.log(`iframe shading error: ${err}`);
        //   return window.parent.postMessage({ callbackType: `Error`, data: { code: '52', message: 'Error in shading simulation' } }, '*');
        // });

        // await new Promise((r) => setTimeout(r, 10000));


        // try {
        //   await designer.current.handleProposal(params, true);
        // } catch (err) {
        //   return window.parent.postMessage({ callbackType: `Error`, data: { code: '53', message: 'Error in creating proposal' } }, '*');
        // }

        // designer.current.handleProposal(params, true);
      } else if (cmd === 'coverage' && designer.current) {
        if (window.parent) window.parent.postMessage({ callbackType: `${cmd}`, data: 'initiated' }, '*');
        assert(params, IFrameCoverageAPIScheme);
        designer.current.checkCoverage(params);
      } else if (cmd === 'Ping' && designer.current) {
        designer.current.callbackReady();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  // returns only if loaded in iframe
  if (window.location !== window.parent.location) {
    return (
      <AuthProvider iframe apiKey={apiKey}>
        <LeadProvider>
          <LeadContext.Consumer>
            {({ lead, user }) => {
              const loaded = Boolean(lead);
              const hasError = Boolean(errors);
              if (user) {
                return (
                  <>
                    <Error visible={hasError} errors={errors} />
                    <Home visible={!loaded && !hasError} companyName={user?.company.name} />
                    <Proposal visible={loaded && !hasError && pid !== null} context={ctx} callback={callback} companyName={user?.company.name} pid={pid} ref={proposal} />
                    <Designer visible={loaded && !hasError && pid === null} context={ctx} callback={callback} companyName={user?.company.name} designFrame={designFrame} ref={designer} />
                  </>
                );
              }
            }}
          </LeadContext.Consumer>
        </LeadProvider>
      </AuthProvider>
    );
  }
};

export default IFrame;
