import React, { createContext } from 'react';
import { CanvasPool } from '../algorithms/utils';

export const CanvasPoolContext = createContext({ canvasPool: new CanvasPool() });

export const CanvasPoolProvider = ({ children }) => {
  return <CanvasPoolContext.Provider>{children}</CanvasPoolContext.Provider>;
};

export default { CanvasPoolProvider, CanvasPoolContext };
