import React, {
  useState, useMemo, useRef, useEffect, useContext, useLayoutEffect, forwardRef, useImperativeHandle,
} from 'react';
import * as THREE from 'three';
// import randomColor from 'randomcolor';
import { useFrame, useThree } from '@react-three/fiber';

import { DesignContext } from '../contexts/designContext.js';
import { shrinkPolygon, borderPolygon } from '../algorithms/geometry.js';

const points = [];

const Roof = forwardRef(({
  index = -1,
  vertices,
  uv,
  eave,
  type,
  pitch = 0,
  azimuth = 0,
  eaveHeight = 0,
  selectable = false,
  isSelected = false,
  panels = null,
  fireSetbacks = null,
  shapeType,
  getParentRoof,
}, forwardedRef) => {
  const {
    texture,
    selectedTexture,
    setback,
    removeFromSelectedObjects,
    addToSelectedObjects,
    selectedObjects,
    showSunLight,
    showPanels,
    panelSelection,
    showSetbacks,
    setbackSelection,
    moveSelection,
    insertObject,
    accordion,
    moveStatus,
    readonly,
    viewerMode,
    panelVisibility,
    viewState,
  } = useContext(DesignContext);

  const mesh = useRef(null);
  const geometry = useRef();
  const panelRefs = React.useRef([]);
  const setBackRefs = React.useRef([]);
  const shape = useMemo(() => new THREE.Shape(vertices), [vertices]);
  // const rndColor = useMemo(() => randomColor(), []);
  const isRoof = type === 'roof';
  const isObstacle = type === 'obstacle';
  const tilt = panels?.tilt ?? 0;
  const lineShape = shapeType === "Line";

  const setbacks = useMemo(() => {
    if (isObstacle) return borderPolygon(vertices, Math.min(1, Math.abs(fireSetbacks)), Math.abs(fireSetbacks), fireSetbacks > 0).map((vs) => new THREE.Shape([...vs]));
    return shrinkPolygon(vertices, fireSetbacks ?? vertices.map((v, vi) => setback))
      .map((vs) => new THREE.Shape([...vs]));
  }, [vertices, fireSetbacks]);

  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (panels && panels.projectedPoints) panelRefs.current = panelRefs.current.slice(0, panels.projectedPoints.length);
  }, [panels]);

  useEffect(() => {
    setBackRefs.current = setBackRefs.current.slice(0, vertices.length);
  }, [vertices]);

  const [hasEave, eaveType, eaveDirection, mx, mxr, eh, mxpanel] = useMemo(() => {
    const [a, b, eaveTypex] = eave;
    const hasEavex = a.clone().sub(b).length() > 0;
    // const hasEavex = eave && eave[1];
    let ehx = eaveHeight;
    if (isRoof && ehx === 0 && pitch === 0) ehx = 0.5;
    const eaveDirectionx = hasEavex ? b.clone().sub(a).normalize() : new THREE.Vector3(1, 0, 0);
    // const eaveTypex = eave[2];
    // const a = eave[0];
    if (isRoof && hasEavex) {
      const z = new THREE.Vector3(0, 0, 1);
      const u = b.clone().sub(a).cross(z).normalize();
      const q = new THREE.Quaternion().setFromAxisAngle(eaveDirectionx, pitch * (Math.PI / 180.0) * (false && eaveTypex === 'ridge' ? -1 : 1));
      const qroll = new THREE.Quaternion().setFromAxisAngle(u, 0 * (Math.PI / 180.0));
      const mxx = new THREE.Matrix4().makeTranslation(-a.x, -a.y, -a.z).premultiply(
        new THREE.Matrix4().makeRotationFromQuaternion(qroll).premultiply(
          new THREE.Matrix4().makeRotationFromQuaternion(q).premultiply(
            new THREE.Matrix4().makeTranslation(a.x, a.y, a.z).premultiply(
              new THREE.Matrix4().makeTranslation(0, 0, -ehx),
            ),
          ),
        ),
      );
      const qp = new THREE.Quaternion().setFromAxisAngle(eaveDirectionx, tilt * (Math.PI / 180.0));
      const mxpanel = new THREE.Matrix4().makeRotationFromQuaternion(qp);
      return [hasEavex, eaveTypex, eaveDirectionx, mxx, null, ehx, mxpanel];
    }

    const mxx = new THREE.Matrix4().makeTranslation(0, 0, -ehx);
    return [hasEavex, eaveTypex, eaveDirectionx, mxx, null, ehx];
    // if (eaveTypex === 'ridge') {
    //   const v3 = vertices.map((v) => -v.clone().applyMatrix4(mxx).z);
    //   const mh = ehx - Math.min(...v3);
    //   const mxxr = new THREE.Matrix4().makeTranslation(0, 0, -mh);
    //   return [hasEavex, eaveTypex, eaveDirectionx, mxx, mxxr, ehx];
    // }
  }, [eave, eaveHeight, isRoof, pitch, azimuth, tilt]);

  const panelShapes = useMemo(() => {
    if (!isRoof) return null;
    const [a, b, eaveTypex, ai, bi] = eave;
    const azimuthDir = b.clone().sub(a).normalize();
    const edgeDir = vertices[bi].clone().sub(vertices[ai]).normalize();
    const alpha = Math.atan2(azimuthDir.y, azimuthDir.x) - Math.atan2(edgeDir.y, edgeDir.x); // azimuthDir.angleTo(edgeDir);
    const mrotate = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(0, 0, 1), -alpha);
    const hs = panels?.scale ?? 1;
    const shapes = ((panels && panels.projectedPoints)
      ? panels.projectedPoints.map(([,, w, h]) => (new THREE.Shape([
        new THREE.Vector3(0, 0).applyMatrix4(mrotate),
        new THREE.Vector3(0, h).applyMatrix4(mrotate),
        new THREE.Vector3(-w * hs, h).applyMatrix4(mrotate),
        new THREE.Vector3(-w * hs, 0).applyMatrix4(mrotate),
      ]))) : null);
    return shapes;
  }, [panels]);

  useLayoutEffect(() => {
    if (!mesh.current) return;
    mesh.current.matrix.identity();
    // if (isRoof && hasEave) {
    mesh.current.applyMatrix4(mx);
    if (mxr) mesh.current.applyMatrix4(mxr);
    // } else {
    //   mesh.current.applyMatrix4(new THREE.Matrix4().makeTranslation(0, 0, -eh));
    // }
  }, [eh, hasEave, isRoof, mx, mxr, vertices]);

  useEffect(() => {
    if (!mesh.current) return;

    const applyMatrix = (mxx) => {
      if (setBackRefs?.current) setBackRefs.current.forEach((sbRef) => { if (sbRef?.applyMatrix4) sbRef.applyMatrix4(mxx); });
    };

    if (setBackRefs?.current) {
      setBackRefs.current.forEach((sbRef, ri) => {
        if (sbRef?.matrix) {
          sbRef.matrix.identity();
          sbRef.position.set(0, 0, -0.1 - (0.01 * ri));
          sbRef.rotation.set(0, 0, 0);
          sbRef.updateMatrix();
        }
      });
    }

    if (isRoof && hasEave) {
      applyMatrix(mx);
      if (mxr) applyMatrix(mxr);
    } else {
      let parent = getParentRoof && getParentRoof(index);
      if (parent) parent = applyMatrix(parent.mx);
      else applyMatrix(new THREE.Matrix4().makeTranslation(0, 0, -eh));
    }
  }, [eh, hasEave, isRoof, mx, mxr, fireSetbacks]);

  useEffect(() => {
    if (!mesh.current) return;

    // points.forEach((p)=> { scene.remove(p); });
    // points.length = 0;

    const applyMatrix = (mxx) => {
      if (panelRefs?.current) panelRefs.current.forEach((pRef) => {
        if (pRef?.applyMatrix4) {
          // const px = mxx.clone();// .premultiply(new THREE.Matrix4().makeTranslation(0, 0, -5));
          pRef.applyMatrix4(mxx);
          // const geometry = new THREE.SphereGeometry(0.5, 12, 8);
          // const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
          // const sphere = new THREE.Mesh(geometry, material);
          // sphere.position.set(pRef.position.x, -pRef.position.z, pRef.position.y);
          // points.push(shape);
          // scene.add(sphere);
        }
      });
    };

    if (panelRefs?.current) {
    
      const [a, b, eaveTypex] = eave;
      let ehx = eaveHeight;
      if (isRoof && ehx === 0 && pitch === 0) ehx = 0.5;
      const isRidge = eaveTypex === 'ridge';

      panelRefs.current.forEach((pRef, refIndex) => {
        if (pRef?.matrix) {
          const [x, y, w, h] = panels.projectedPoints[refIndex];
          const ty = panels.points[refIndex][4];
          const o = ((ty === 'h' ? h : w) + 0.5) * Math.sin(tilt / 180.0 * Math.PI);

          pRef.matrix.identity();
          const panelTr = new THREE.Matrix4().makeRotationZ(Math.atan2(eaveDirection.y, eaveDirection.x) + Math.PI / 2).premultiply(
            mxpanel
          ).premultiply(
            new THREE.Matrix4().makeTranslation(x, y, -0.21 + (isRidge ? -o: 0))
          );
          pRef.applyMatrix4(panelTr);

          // pRef.matrix.identity();
          // pRef.position.set(x, y, -0.21);
          // pRef.updateMatrix();
          // const mold1 = pRef.matrix.clone();
          // pRef.matrix.identity();
          // pRef.rotation.set(0, 0, Math.atan2(eaveDirection.y, eaveDirection.x) + Math.PI / 2);
          // pRef.updateMatrix();
          // const mold2 = pRef.matrix.clone();
          // pRef.matrix.identity();
          // pRef.applyMatrix4(mold2);
          // pRef.applyMatrix4(mold1);
          // pRef.applyMatrix4(new THREE.Matrix4().makeTranslation(0, 0, -5));
        }
      });
    }

    if (isRoof && hasEave) {
      applyMatrix(mx);
      if (mxr) applyMatrix(mxr);
    } else applyMatrix(new THREE.Matrix4().makeTranslation(0, 0, -eh));
  }, [eaveDirection.x, eaveDirection.y, eh, hasEave, isRoof, mx, mxr, panels, mxpanel, eave, eaveHeight]);

  // useLayoutEffect(() => {
  //   if (!mesh.current) return;

  //   let eh = eaveHeight;
  //   if (isRoof && eh === 0 && pitch === 0) eh = 0.5;

  //   const applyMatrix = (mx) => {
  //     mesh.current.applyMatrix4(mx);
  //     if (panelRefs?.current) panelRefs.current.forEach((pRef) => { if (pRef?.applyMatrix4) pRef.applyMatrix4(mx); });
  //     if (setBackRefs?.current) setBackRefs.current.forEach((sbRef) => { if (sbRef?.applyMatrix4) sbRef.applyMatrix4(mx); });
  //   };

  //   const eaveDirection = hasEave ? eave[1].clone().sub(eave[0]).normalize() : new THREE.Vector3(1, 0, 0);

  //   mesh.current.matrix.identity();
  //   if (panelRefs?.current) {
  //     panelRefs.current.forEach((pRef, refIndex) => {
  //       if (pRef?.matrix) {
  //         const [x, y] = panels.projectedPoints[refIndex];
  //         pRef.matrix.identity();
  //         pRef.position.set(x, y, -0.21);
  //         pRef.rotation.set(0, 0, Math.atan2(eaveDirection.y, eaveDirection.x) + Math.PI / 2);
  //         pRef.updateMatrix();
  //       }
  //     });
  //   }

  //   if (setBackRefs?.current) {
  //     setBackRefs.current.forEach((sbRef, ri) => {
  //       if (sbRef?.matrix) {
  //         sbRef.matrix.identity();
  //         sbRef.position.set(0, 0, -0.1 - (0.01 * ri));
  //         sbRef.rotation.set(0, 0, 0);
  //         sbRef.updateMatrix();
  //       }
  //     });
  //   }

  //   if (isRoof && hasEave) {
  //     const eaveType = eave[2];
  //     const point = eave[0];
  //     const q = new THREE.Quaternion().setFromAxisAngle(eaveDirection, pitch * (Math.PI / 180.0) * (eaveType === 'ridge' ? -1 : 1));
  //     const mx = new THREE.Matrix4().makeTranslation(-point.x, -point.y, -point.z).premultiply(
  //       new THREE.Matrix4().makeRotationFromQuaternion(q).premultiply(
  //         new THREE.Matrix4().makeTranslation(point.x, point.y, point.z).premultiply(
  //           new THREE.Matrix4().makeTranslation(0, 0, -eh),
  //         ),
  //       ),
  //     );

  //     applyMatrix(mx);
  //     if (eaveType === 'ridge') {
  //       const v3 = vertices.map((v) => -v.clone().applyMatrix4(mx).z);
  //       const mh = Math.max(...v3) - Math.min(...v3);
  //       applyMatrix(new THREE.Matrix4().makeTranslation(0, 0, -mh));
  //     }
  //   } else {
  //     applyMatrix(
  //       new THREE.Matrix4().makeTranslation(0, 0, -eh),
  //     );
  //   }
  // }, [eave, pitch, eaveHeight, panels, fireSetbacks]);

  useEffect(() => {
    if (selectable && isSelected !== selected) setSelected(isSelected);
  }, [isSelected, index]);

  // useEffect(() => {
  //   if (selectable) {
  //     const newSelected = [...selectedSetbacks];
  //     selectedObjects.fireSetbacks.forEach((ssb) => {
  //       const [ri, sbi] = ssb.split('-');
  //       if (ri === index) selectedSetbacks[sbi] = true;
  //     });
  //     setSelected(isSelected);
  //   }
  // }, [selectedObjects.fireSetbacks]);

  useEffect(() => {
    if (geometry.current) geometry.current.setAttribute('uv', new THREE.Float32BufferAttribute(new Float32Array(uv), 2));
  }, [uv]);

  const changeSelection = (e, selection) => {
    if (selectable && !insertObject && !readonly && viewState!="annotations") {
      e.stopPropagation();
      setSelected(selection);
      const isRevise = accordion === 'revise';

      if (selection) addToSelectedObjects(index, 'roofSegments', isRevise);
      else removeFromSelectedObjects(index, 'roofSegments');
    }
  };

  const setbackChangeSelection = (e, sbi, selection) => {
    if (setbackSelection && !readonly && viewState!="annotations") {
      e.stopPropagation();
      if (isRoof) {
        if (selection) addToSelectedObjects(`${index}-${sbi}`, 'fireSetbacks');
        else removeFromSelectedObjects(`${index}-${sbi}`, 'fireSetbacks');
      } else {
        setbacks.map((sb, sbi) => {
          if (selection) addToSelectedObjects(`${index}-${sbi}`, 'fireSetbacks');
          else removeFromSelectedObjects(`${index}-${sbi}`, 'fireSetbacks');
        });
      }
    }
  };

  const panelChangeSelection = (e, pi, selection) => {
    const enabled = panelSelection || (readonly && (panelVisibility || !selection));
    if (enabled) e.stopPropagation();
    if (!viewerMode && enabled && !insertObject && viewState!="annotations" && (moveStatus === 0 || moveStatus === 1)) {
      if (selection) addToSelectedObjects(`${index}-${pi}`, 'panels');
      else removeFromSelectedObjects(`${index}-${pi}`, 'panels');
    }
  };

  const matSelected = useMemo(() => {
    // if (!selectedTexture) return null;
    return (showSunLight ? (
      <meshStandardMaterial
        color="rgb(255,150,150)"
        attach="material"
        wireframe={false}
        side={THREE.BackSide}
        map={selectedTexture}
        castShadow
        receiveShadow
      />
    ) : (
      <meshBasicMaterial
        color="rgb(255,150,150)"
        attach="material"
        wireframe={false}
        side={THREE.BackSide}
        map={selectedTexture}
      />
    ))
  }, [selectedTexture, showSunLight]);

  const matFront = useMemo(() => (showSunLight ? (
    <meshStandardMaterial
      attach="material"
      wireframe={false}
      side={THREE.BackSide}
      map={texture}
      castShadow
      receiveShadow
    />
  ) : (
    <meshBasicMaterial
      attach="material"
      wireframe={false}
      side={THREE.BackSide}
      map={texture}
    />
  )), [texture, showSunLight]);

  const matBack = useMemo(() => {
    const mb = (showSunLight ? (
      <meshStandardMaterial
        attach="material"
        color="rgb(250,0,0)"
        wireframe={false}
        side={THREE.FrontSide}
        castShadow
        receiveShadow
      />
    ) : (
      <meshBasicMaterial
        attach="material"
        color="rgb(250,0,0)"
        wireframe={false}
        side={THREE.FrontSide}
      />
    ));
    return [matFront, mb];
  }, [matFront, showSunLight]);

  const panelMat = useMemo(() => (showSunLight ? (
    <meshStandardMaterial
      name="mat_panel"
      attach="material"
      color="rgb(0,0,0)"
      wireframe={false}
      side={THREE.FrontSide}
      transparent
      opacity="0.2"
      castShadow
      receiveShadow
    />
  ) : (
    <meshBasicMaterial
      name="mat_panel"
      attach="material"
      color="rgb(0,0,0)"
      transparent
      opacity="0.2"
      wireframe={false}
      side={THREE.FrontSide}
    />
  )), [showSunLight]);

  const selectedPanelMat = useMemo(() => (showSunLight ? (
    <meshStandardMaterial
      name="mat_selected_panel"
      attach="material"
      color="rgb(0,0,0)"
      wireframe={false}
      side={THREE.FrontSide}
      transparent
      opacity="0.7"
      castShadow
      receiveShadow
    />
  ) : (
    <meshBasicMaterial
      name="mat_selected_panel"
      attach="material"
      color="rgb(0,0,0)"
      transparent
      opacity="0.7"
      wireframe={false}
      side={THREE.FrontSide}
    />
  )), [showSunLight]);

  // if (!hasEave && !selectable) return null;

  let currentMat = null;
  if (selected && selectable) currentMat = matSelected;
  else if (isRoof || isObstacle) currentMat = matFront;
  else currentMat = matBack;

  
  useImperativeHandle(forwardedRef,
    () => ({
      eave,
      type,
      pitch,
      azimuth,
      eaveHeight,    
      mx,
    }));

  return (
    <group name={isRoof ? type : ''} index={index}>
      <mesh
        name="roofPlane"
        index={index}
        ref={mesh}
        castShadow
        receiveShadow
        position={[0, 0, 0]}
        matrixAutoUpdate={false}
        visible={!lineShape}
        onClick={(e) => changeSelection(e, !selected)}
      >
        <shapeBufferGeometry attach="geometry" args={[shape]} ref={geometry} />
        {currentMat}
      </mesh>
      {isRoof && panelShapes && panels && panels.projectedPoints.map((p, pi) => (
        <group
          name="panel"
          index={pi}
          visible={showPanels && (!readonly || (panelVisibility || (index in selectedObjects.panels && selectedObjects.panels[index].includes(pi))))}
          ref={(el) => { panelRefs.current[pi] = el; }}
          castShadow
          receiveShadow
          matrixAutoUpdate={false}
        >
          <mesh
            name="panelBox"
            rotation={[0, 0, -(panels.azimuthShift * Math.PI) / 180]}
            onClick={(e) => panelChangeSelection(e, pi, !selectedObjects.panels[index]?.includes(pi))}
          >
            <extrudeGeometry
              attach="geometry"
              args={[panelShapes && panelShapes[pi], { steps: 1, depth: 0.2, bevelEnabled: false }]}
            />
            {(panelSelection || readonly) && selectedObjects.panels[index]?.includes(pi) ? selectedPanelMat : panelMat}
          </mesh>
          <line rotation={[0, 0, -(panels.azimuthShift * Math.PI) / 180]}>
            <extrudeGeometry attach="geometry" args={[panelShapes && panelShapes[pi], { steps: 1, depth: 0.2, bevelEnabled: false }]} />
            <lineBasicMaterial attach="material" color="white" transparent opacity={0.3} linewidth={2} />
          </line>
        </group>
      ))}
      {setbacks && setbacks.map((sb, sbi) => (
        <mesh
          name="setback"
          visible={showSetbacks}
          ref={(el) => { setBackRefs.current[sbi] = el; }}
          receiveShadow
          position={[0, 0, 0]}
          matrixAutoUpdate={false}
          onClick={(e) => setbackChangeSelection(e, sbi, !selectedObjects.fireSetbacks[index]?.includes(sbi))}
        >
          <shapeBufferGeometry attach="geometry" args={[sb]} />
          <meshBasicMaterial
            attach="material"
            color="orange"
            transparent
            opacity={selectedObjects.fireSetbacks[index]?.includes(sbi) ? 0.8 : 0.5}
            wireframe={false}
            side={THREE.DoubleSide}
          />
        </mesh>
      ))}
    </group>
  );
});

export default Roof;
