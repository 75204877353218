import Tool from './tool';

export const CIRCLE = 'Circle';

const circle = { ...Tool };

circle.name = 'Circle';

circle.onMouseDown = function onMouseDown(start, options) {
  if (!this.state) {
    this.state = {
      ...this.state,
      initialCircle: this.createStartPoint(start, 3),
      startPoint: start,
      options,
    };
  }
  if (!this.state.pathData) {
    this.state.pathData = [];
  }
  this.setInitSettings({ start, options });
};

circle.onTouchStart = function onTouchStart(start, options) {
  if (!this.state) {
    this.state = {
      ...this.state,
      initialCircle: this.createStartPoint(start, 3),
      startPoint: start,
      options,
    };
  }
  if (!this.state.pathData) {
    this.state.pathData = [];
  }
  this.setInitSettings({ start, options });
};

circle.onMouseMove = function onMouseMove(position) {
  if (!this.state || !this.state.started) return;
  const radius = Math.hypot(
    this.state.start.x - position.x,
    this.state.start.y - position.y,
  );
  this.draw(this.state.start, radius);
};

circle.onTouchMove = function onTouchMove(position) {
  if (!this.state || !this.state.started) return;
  const radius = Math.hypot(
    this.state.start.x - position.x,
    this.state.start.y - position.y,
  );
  this.draw(this.state.start, radius);
};

circle.draw = function createStartPoint(start, radius) {
  const path = new Path2D();
  path.arc(start.x, start.y, radius, 0, 2 * Math.PI);
  if (this.ctx) {
    this.ctx.strokeStyle = this.state.options.lineColor;
    this.ctx.fillStyle = 'rgba(255, 0, 0, 0.5)';
    this.ctx.fill(path);
    this.ctx.stroke(path);
  }
};

circle.onMouseUp = function onMouseUp(position, callback) {
  if (!this.state) return null;
  callback();
  const data = [
    [this.state.start.x, this.state.start.y],
    [position.x, position.y],
  ];
  this.state.pathData.push(data);
  this.state.canvasData.push(data);
  const { start } = this.state;
  const { options } = this.state;
  this.resetState();
  return {
    data,
    canvas: {
      start,
      end: position,
      options,
    },
  };
};

circle.onTouchEnd = function onTouchEnd(position, callback) {
  if (!this.state) return null;
  callback();
  const data = [
    [this.state.start.x, this.state.start.y],
    [position.x, position.y],
  ];
  this.state.pathData.push(data);
  this.state.canvasData.push(data);
  const { start } = this.state;
  const { options } = this.state;
  this.resetState();
  return {
    data,
    canvas: {
      start,
      end: position,
      options,
    },
  };
};

export default circle;
