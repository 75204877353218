import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Form, Button, ButtonGroup, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Trash, Pencil, InfoCircle } from 'react-bootstrap-icons';

import { AuthContext } from 'api/context';
import Save from 'components/Save';
import ValidationForm from 'components/Form/ValidationForm';

const Loans = () => {
  const { newEndpoint, displaySnack } = useContext(AuthContext);
  const endpoint = newEndpoint('hardware/loans/');

  const [loans, setLoans] = useState([]);
  const [newLoan, setNewLoan] = useState();
  const [editingLoanId, setEditingLoanId] = useState(null);

  console.log('loans', loans);
  useEffect(() => {
    endpoint.list()
      .then((data) => {
        console.log('loans data', data);
        setLoans(data)
      })
      .catch((e) => console.log(e));
  }, []);

  const handleUpdateField = (value, field, index) => {
    const updated = Array.from(loans);
    updated[index][field] = value;
    setLoans(updated);
  };

  const handleUpdate = (loan) => {
    // Create a clean loan object with all required fields
    const updatedLoan = {
      id: loan.id,
      loaner: loan.loaner,
      rate: loan.rate,
      term: loan.term,
      fees: loan.fees,
      button_url: loan.button_url || '',  // Include button_url even if empty
      button_text: loan.button_text || '', // Include button_text even if empty
    };

    console.log('Updating loan with:', updatedLoan); // Debug log

    endpoint.update(loan.id, updatedLoan)
      .then(() => {
        displaySnack({ variant: 'success', message: `Updated ${loan.loaner}.` });
        // Refresh the loans list after update
        return endpoint.list();
      })
      .then((data) => {
        console.log('Updated loans data:', data); // Debug log
        setLoans(data);
      })
      .catch(() => displaySnack({
        variant: 'danger',
        message: `Failed update ${loan.loaner}.`
      }));
  };

  const handleDelete = (index) => {
    const updated = Array.from(loans);
    const rm = updated.splice(index, 1)[0];
    endpoint.remove(rm.id)
      .then(() => { setLoans(updated); displaySnack({ variant: 'success', message: `Deleted ${rm.loaner}.` }); })
      .catch(() => displaySnack({ variant: 'warning', message: `Failed to delete ${rm.loaner}` }));
  };

  const initLoan = () => setNewLoan({
    loaner: '',
    rate: 0.00,
    term: 0,
    fees: 0.00,
    button_text: '',
    button_url: ''
  });
  const handleCreate = () => {
    // send POST request for new credit
    endpoint.create(newLoan)
      .then(() => endpoint.list().then((data) => setLoans(data)))
      .then(() => {
        displaySnack({ variant: 'success', message: `Added ${newLoan.loaner}.` });
        setNewLoan();
      })
      .catch(() => displaySnack({
        variant: 'danger',
        message: `Failed to add new ${newLoan.loaner}.`,
      }));
  };

  return (
    <>
      <div className="mb-4">

        <p className="text-muted">
          Enter all loan details below.
        </p>
      </div>

      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th style={{ width: '15%' }}>Loan Name</th>
              <th style={{ width: '10%' }}>Rate (%)</th>
              <th style={{ width: '10%' }}>Term (years)</th>
              <th style={{ width: '12%' }}>Dealer Fees (%)</th>
              <th style={{ width: '20%' }}>Lender Portal Link</th>
              {/*  <th style={{ width: '15%' }}>Button Text</th> */}
              <th style={{ width: '15%' }} className="text-right">Action</th>
            </tr>
          </thead>
          <tbody>
            {loans.map((item, index) => (
              <tr key={item.id} className="align-middle">
                <td>{item.loaner}</td>
                <td>{item.rate}</td>
                <td>{item.term}</td>
                <td>{item.fees}</td>
                <td>{item.button_url || '-'}</td>
                {/* <td>{item.button_text || '-'}</td> */}
                <td className="text-right">
                  <ButtonGroup size="sm">
                    <Button
                      variant="link"
                      className="p-1"
                      onClick={() => setEditingLoanId(item.id)}
                    >
                      <Pencil className="text-dark" />
                    </Button>
                    <Button
                      variant="link"
                      className="p-1"
                      onClick={() => handleDelete(index)}
                    >
                      <Trash className="text-dark" />
                    </Button>
                  </ButtonGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Edit Form */}
      {editingLoanId && (
        <ValidationForm onSave={() => {
          const loanToUpdate = loans.find(loan => loan.id === editingLoanId);
          handleUpdate(loanToUpdate);
          setEditingLoanId(null);
        }}>
          <div className="mt-4 p-3 border rounded" style={{ maxWidth: '500px' }}>
            <Row className="mb-2">
              <Col sm={5} className="d-flex align-items-center">
                <Form.Label className="mb-0">Loan Name</Form.Label>
              </Col>
              <Col sm={7}>
                <Form.Control
                  value={loans.find(loan => loan.id === editingLoanId)?.loaner || ''}
                  onChange={(e) => handleUpdateField(e.target.value, 'loaner', loans.findIndex(loan => loan.id === editingLoanId))}
                  required
                />
              </Col>
            </Row>

            <Row className="mb-2">
              <Col sm={5} className="d-flex align-items-center">
                <Form.Label className="mb-0">Rate ($)</Form.Label>
              </Col>
              <Col sm={7}>
                <Form.Control
                  type="number"
                  value={loans.find(loan => loan.id === editingLoanId)?.rate || ''}
                  onChange={(e) => handleUpdateField(e.target.value, 'rate', loans.findIndex(loan => loan.id === editingLoanId))}
                  required
                />
              </Col>
            </Row>

            <Row className="mb-2">
              <Col sm={5} className="d-flex align-items-center">
                <Form.Label className="mb-0">Term</Form.Label>
              </Col>
              <Col sm={7}>
                <Form.Control
                  type="number"
                  value={loans.find(loan => loan.id === editingLoanId)?.term || ''}
                  onChange={(e) => handleUpdateField(e.target.value, 'term', loans.findIndex(loan => loan.id === editingLoanId))}
                  required
                />
              </Col>
            </Row>

            <Row className="mb-2">
              <Col sm={5} className="d-flex align-items-center">
                <Form.Label className="mb-0">Dealer Fees ($)</Form.Label>
              </Col>
              <Col sm={7}>
                <Form.Control
                  type="number"
                  value={loans.find(loan => loan.id === editingLoanId)?.fees || ''}
                  onChange={(e) => handleUpdateField(e.target.value, 'fees', loans.findIndex(loan => loan.id === editingLoanId))}
                  required
                />
              </Col>
            </Row>

            <Row className="mb-2">
              <Col sm={5} className="d-flex align-items-center">
                <Form.Label className="mb-0">Lender Portal Link</Form.Label>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Enter the domain without 'https://' - it will be added automatically</Tooltip>}
                >
                  <InfoCircle className="ms-2 text-muted" style={{ cursor: 'help' }} />
                </OverlayTrigger>
              </Col>
              <Col sm={7}>
                <InputGroup>
                  <InputGroup.Text>https://</InputGroup.Text>
                  <Form.Control
                    value={(loans.find(loan => loan.id === editingLoanId)?.button_url || '').replace('https://', '')}
                    onChange={(e) => handleUpdateField(`https://${e.target.value}`, 'button_url', loans.findIndex(loan => loan.id === editingLoanId))}
                    placeholder="example.com/lender-portal"
                  />
                </InputGroup>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col sm={5} className="d-flex align-items-center">
                <Form.Label className="mb-0">Button Text</Form.Label>
              </Col>
              <Col sm={7}>
                <Form.Control
                  value={loans.find(loan => loan.id === editingLoanId)?.button_text || ''}
                  onChange={(e) => handleUpdateField(e.target.value, 'button_text', loans.findIndex(loan => loan.id === editingLoanId))}
                />
              </Col>
            </Row>

            <div className="mt-4">
              <Button variant="dark" type="submit" className="mr-2">Save</Button>
              <Button variant="secondary" onClick={() => setEditingLoanId(null)}>Cancel</Button>
            </div>
          </div>
        </ValidationForm>
      )}

      {/* Add Loan section */}
      {newLoan ? (
        <ValidationForm onSave={handleCreate}>
          <div className="mt-4 p-3 border rounded" style={{ maxWidth: '500px' }}>
            <Row className="mb-2">
              <Col sm={5} className="d-flex align-items-center">
                <Form.Label className="mb-0">Loan Name</Form.Label>
              </Col>
              <Col sm={7}>
                <Form.Control
                  value={newLoan.loaner || ''}
                  onChange={(e) => setNewLoan({ ...newLoan, loaner: e.target.value })}
                  required
                />
              </Col>
            </Row>

            <Row className="mb-2">
              <Col sm={5} className="d-flex align-items-center">
                <Form.Label className="mb-0">Rate (%)</Form.Label>
              </Col>
              <Col sm={7}>
                <Form.Control
                  type="number"
                  value={newLoan.rate || ''}
                  onChange={(e) => setNewLoan({ ...newLoan, rate: e.target.value })}
                  required
                />
              </Col>
            </Row>

            <Row className="mb-2">
              <Col sm={5} className="d-flex align-items-center">
                <Form.Label className="mb-0">Term (years)</Form.Label>
              </Col>
              <Col sm={7}>
                <Form.Control
                  type="number"
                  value={newLoan.term || ''}
                  onChange={(e) => setNewLoan({ ...newLoan, term: e.target.value })}
                  required
                />
              </Col>
            </Row>

            <Row className="mb-2">
              <Col sm={5} className="d-flex align-items-center">
                <Form.Label className="mb-0">Dealer Fees (%)</Form.Label>
              </Col>
              <Col sm={7}>
                <Form.Control
                  type="number"
                  value={newLoan.fees || ''}
                  onChange={(e) => setNewLoan({ ...newLoan, fees: e.target.value })}
                  required
                />
              </Col>
            </Row>

            <Row className="mb-2">
              <Col sm={5} className="d-flex align-items-center">
                <Form.Label className="mb-0">Lender Portal Link</Form.Label>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Enter the domain without 'https://' - it will be added automatically</Tooltip>}
                >
                  <InfoCircle className="ms-2 text-muted ml-1" style={{ cursor: 'help' }} />
                </OverlayTrigger>
              </Col>
              <Col sm={7}>
                <InputGroup>
                  <InputGroup.Text>https://</InputGroup.Text>
                  <Form.Control
                    value={(newLoan.button_url || '').replace('https://', '')}
                    onChange={(e) => setNewLoan({ ...newLoan, button_url: `https://${e.target.value}` })}
                    placeholder="example.com"
                  />
                </InputGroup>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col sm={5} className="d-flex align-items-center">
                <Form.Label className="mb-0">Button Text</Form.Label>
              </Col>
              <Col sm={7}>
                <Form.Control
                  value={newLoan.button_text || ''}
                  onChange={(e) => setNewLoan({ ...newLoan, button_text: e.target.value })}
                />
              </Col>
            </Row>

            <div className="mt-4">
              <Button variant="dark" type="submit" className="mr-2">Save</Button>
              <Button variant="secondary" onClick={() => setNewLoan()}>Cancel</Button>
            </div>
          </div>
        </ValidationForm>
      ) : (
        <div className="mt-4">
          <Button variant="dark" onClick={initLoan}>
            + Add Loan
          </Button>
        </div>
      )}
    </>
  );
};

export default Loans;
