import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from 'react-bootstrap';

function ControlsBottom({
  saveCanvas,
  cleanCanvas,
  disabled,
}) {
  return (
    <div className="w-100 d-flex justify-content-end">
      <div className="d-flex justify-content-end">
        <ButtonGroup size="sm mt-1">
          <Button
            onClick={saveCanvas}
            variant="outline-primary"
            disabled={disabled}
          >
            Save
          </Button>
          <Button
            onClick={cleanCanvas}
            variant="outline-primary"
            disabled={disabled}
          >
            Clean Canvas
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
}

ControlsBottom.defaultProps = {
  disabled: true,
};

ControlsBottom.propTypes = {
  cleanCanvas: PropTypes.func.isRequired,
  saveCanvas: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default ControlsBottom;
