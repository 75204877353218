import React, {
    useState, useContext, useEffect, useLayoutEffect, useRef, useMemo, useCallback,
} from 'react';
import { Vector3, Frustum } from 'three';
import * as THREE from 'three';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { Html } from '@react-three/drei';
import { DesignContext } from '../contexts/designContext.js';

import { TbSunElectricity } from "react-icons/tb";
import { LiaYCombinator } from "react-icons/lia";
import { MdOutlineMedicalServices } from "react-icons/md";
import { LuThermometerSun } from "react-icons/lu";
import { LuUtilityPole } from "react-icons/lu";
import { AiOutlineQuestionCircle, AiOutlineDisconnect } from "react-icons/ai";
import { LuGalleryHorizontal } from "react-icons/lu";

const raycaster = new THREE.Raycaster();
const mouse = new THREE.Vector2();
const pointGeometry = new THREE.SphereGeometry(1.0, 16, 16);
const pointMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000 });
const annotationPointer = new THREE.Mesh(pointGeometry, pointMaterial);
annotationPointer.name = "annotationPointer";
var mouseDownPosition = null;

const type_mapping = {
  "custom": "Custom",
  "inverter": "Inverter",
  "combiner_panel": "Combiner Panel",
  "service_panel": "Service Panel",
  "production_meter": "Production Meter",
  "utility_meter": "Utility Meter",
  "disconnect": "Disconnect",
  "sub_panel": "Sub Panel",
};

const type_color_mapping = {
  "Custom": "blue",
  "Inverter": "brown",
  "Combiner Panel": "green",
  "Service Panel": "yellow",
  "Production Meter": "red",
  "Utility Meter": "purple",
  "Disconnect": "orange",
  "Sub Panel": "pink",
};

const Annotations = ({
    canvas, controls, enabled,
  }) => {
  const {
      accordion,
      viewState,
      mode,
      annotations,
      setAnnotations,
      newAnnotation,
      iframeCallback,
      designFrame,
    } = useContext(DesignContext);
  
    const {
      gl, camera, scene, size: canvasSize,
    } = useThree();

    // const rearTarget = useRef();
    // const [annotationTexture, setAnnotationTexture] = useState()
    // useLayoutEffect(() => {
    //   try {
    //     new THREE.TextureLoader().load("/aerialytic-logo.png", (tx) => {
    //       setAnnotationTexture(tx);
    //     });
    //   } catch { console.log('error in load annotation texture'); }
    // }, []);
  
    const get3dPosition = (e) => {
      mouse.set((e.offsetX / canvasSize.width) * 2 - 1, -(e.offsetY / canvasSize.height) * 2 + 1, 0.5);
      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObjects(scene.children);
      for (let i = 0; i < intersects.length; i++) {
        const intersect = intersects[i];
        if (intersect.object.name != "annotationPointer") {
          let ratio = 1.0;
          if (camera.type === 'PerspectiveCamera') ratio = Math.max(0.1, Math.min(1.0, camera.position.length() / 100)).toFixed(2);
          else ratio = Math.max(0.2, Math.min(1.0, 4.0 / camera.zoom)).toFixed(2);
          return [intersect.point, ratio];
          break;
        }
      }
      return [null, 1.0];
    };

    const canvasMouseDown = useCallback((e) => {
      if (!enabled || viewState != 'annotations' || mode == '') return;

      e.preventDefault();
      e.stopPropagation();

      const [point, ratio] = get3dPosition(e);
      mouseDownPosition = point;
  
    }, [controls, canvas, mouse, canvasSize.width, canvasSize.height, camera, scene, viewState, mode, enabled]);
  
    const canvasMouseUp = useCallback((e) => {
      if (!enabled || viewState != 'annotations' || mode == '' || !mouseDownPosition) return;

      e.preventDefault();
      e.stopPropagation();

      const [point, ratio] = get3dPosition(e);
      const dist = mouseDownPosition.distanceTo(point);

      if (newAnnotation && dist <= 1 && ((mode in type_mapping) || (viewState === 'annotations' && iframeCallback && !designFrame))) newAnnotation(annotationPointer.position, type_mapping[mode]);
  
    }, [controls, canvas, mouse, canvasSize.width, canvasSize.height, camera, scene, viewState, mode, enabled]);

    const canvasMouseMove = useCallback((e) => {
      if (!enabled || viewState != 'annotations' || mode == '') return;

      e.preventDefault();
      e.stopPropagation();

      const [point, ratio] = get3dPosition(e);
      if (point) {
        annotationPointer.position.copy(point);
        annotationPointer.scale.set(ratio, ratio, ratio);
      }
    }, [controls, canvas, mouse, canvasSize.width, canvasSize.height, camera, scene, viewState, mode, enabled]);

    useEffect(() => {
      if (viewState == 'annotations' &&  mode != '') {
        canvas.current.style.cursor = 'crosshair';
        scene.add(annotationPointer);
      } else {
        canvas.current.style.cursor = 'default';
        scene.remove(annotationPointer);
      }
    }, [viewState]);

    // useEffect(() => {
    //   scene.add(annotationPointer);
    //   return () => { scene.remove(annotationPointer); };
    // },[]);
  
    useEffect(() => {
      const canvasRef = canvas.current;
      if (canvasRef) {
        canvas.current.addEventListener('mousedown', canvasMouseDown, false);
        canvas.current.addEventListener('mouseup', canvasMouseUp, false);
        canvas.current.addEventListener('mousemove', canvasMouseMove, false);
      }
      return () => {
        if (canvasRef) {
          canvasRef.removeEventListener('mousedown', canvasMouseDown, false);
          canvasRef.removeEventListener('mouseup', canvasMouseUp, false);
          canvasRef.removeEventListener('mousemove', canvasMouseMove, false);
        }
      };
    }, [canvas, canvasMouseDown, canvasMouseMove, canvasMouseUp]);

  const getIcon = (type) => {
    switch (type) {
      case "Inverter":
        return <TbSunElectricity style={{ color: type_color_mapping[type], position: 'relative', top: "-2px", left: "1px" }} />;
      case "Combiner Panel":
        return <LiaYCombinator style={{ color: type_color_mapping[type], position: 'relative', top: "-2px", left: "1px" }} />;
      case "Service Panel":
        return <MdOutlineMedicalServices style={{ color: type_color_mapping[type], position: 'relative', top: "-2px", left: "1px" }} />;
      case "Production Meter":
        return <LuThermometerSun style={{ color: type_color_mapping[type], position: 'relative', top: "-2px", left: "1px" }} />;
      case "Utility Meter":
        return <LuUtilityPole style={{ color: type_color_mapping[type], position: 'relative', top: "-2px", left: "1px" }} />;
      case "Disconnect":
        return <AiOutlineDisconnect style={{ color: type_color_mapping[type], position: 'relative', top: "-2px", left: "1px" }} />;
      case "Sub Panel":
        return <LuGalleryHorizontal style={{ color: type_color_mapping[type], position: 'relative', top: "-2px", left: "1px" }} />;
      default:
        return <AiOutlineQuestionCircle style={{ color: type_color_mapping["custom"], position: 'relative', top: "-2px", left: "1px" }} />;
    };
  }
    
    return (
    <group>
        {((accordion == 'annotations') || (viewState === 'annotations' && !designFrame) ) && annotations && annotations.filter((annotation) => 'x' in annotation.position).map((annotation) => (
        <Html
          key={"annotate_" + annotation.text}
          position={[annotation.position.x, annotation.position.y, annotation.position.z]}
          style={{ pointerEvents: 'none' }}
        >
          <div 
            style={{ 
              position: 'relative', 
              top: '-30px',
              pointerEvents: 'none'
            }} 
            tabIndex={-1}
          >
            <img 
              src='/annotation_place.png' 
              style={{ 
                position: 'relative', 
                left: "-9px", 
                top: "16px", 
                pointerEvents: 'none' 
              }} 
              width={24} 
              height={24} 
              tabIndex={-1}
            />
            <div className="line" tabIndex={-1} style={{ pointerEvents: 'none' }}></div>
            <div 
              className="annotation" 
              style={{ 
                whiteSpace: "pre", 
                pointerEvents: 'none' 
              }} 
              tabIndex={-1}
            >
              {getIcon(annotation.type)}{" " + annotation.text}
            </div>
          </div>
        </Html>
        // <>
        //   {/* <Html tabindex="-1" key={"annotate_" + annotation.text} position={[annotation.position.x, annotation.position.y, annotation.position.z]}>
        //     <div style={{position: 'relative', top: '-30px'}} tabindex="-1">
        //       <img src='/annotation_place.png' style={{ position: 'relative', left: "-9px", top: "16px" }} width={24} height={24}  tabindex="-1"/>
        //       <div className="line" tabindex="-1"></div>
        //       <div className="annotation" style={{ whiteSpace: "pre" }} tabindex="-1">{getIcon(annotation.type)}{" " + annotation.text}</div>
        //     </div>
        //   </Html> */}
        //   {/* <mesh position={[annotation.position.x, annotation.position.y + 1, annotation.position.z]}>
        //     <sphereBufferGeometry args={[1, 24, 24]} />
        //     <meshStandardMaterial color={"blue"} />
        //   </mesh> */}
        // </>
      ))}
    </group>
    );
  };

  export default Annotations;
