import * as THREE from 'three';

import {
  dist,
  counterClockWise,
  calculateEave,
  getParentsPolygon,
  projectTo3DFromOrgVertices,
  pointInConcavePolygon,
} from './geometry.js';

export const updateXYRanges = (state) => {
  state.ranges = [];
  let [minx, miny, maxx, maxy] = [1E5, 1E5, 0, 0];
  state.segments.forEach((sgement) => {
    if (sgement.geometry && sgement.geometry.length) {
      sgement.geometry.forEach(([x, y]) => {
        minx = Math.min(minx, x);
        miny = Math.min(miny, y);
        maxx = Math.max(maxx, x);
        maxy = Math.max(maxy, y);
      });
    }
  });
  [minx, miny, maxx, maxy].forEach((r) => state.ranges.push(Math.round(r)));
};

export const getCenter = (state) => {
  const [minx, miny, maxx, maxy] = state.ranges;
  const [cx, cy, c] = state.center;
  let [ncx, ncy, nc] = [cx, cy, c];
  if (cx === 0 && cy === 0) {
    const { width, height } = state?.rect ?? { width: 0, height: 0 };
    [ncx, ncy, nc] = state.segments.length && state.mode !== 'draw' ? [(maxx + minx) / 2, (maxy + miny) / 2, 1] : [~~(width / 2), ~~(height / 2), 1];
  }
  return [ncx, ncy, nc];
};

export const copyStateProps = (fromState, toState) => {
  toState.segments = fromState.segments;
  toState.rect = fromState.rect;
  toState.scale = fromState.scale;
  toState.resolution = fromState.resolution;
  toState.inchePerUnit = fromState.inchePerUnit;
  toState.footPerUnit = fromState.footPerUnit;
  toState.center = fromState.center;
};

export const initState = (state, segments, scale, resolution, height, width) => {
  state.segments = segments;
  state.rect = { height, width };
  state.scale = scale;
  state.resolution = resolution;
  state.inchePerUnit = parseFloat(resolution) / 2.54;
  state.footPerUnit = parseFloat(resolution) / 30.48;
  updateXYRanges(state);
  if (!state.center || state.mode === 'select') state.center = [0, 0, 1];
  state.center = getCenter(state);
};

export const updateParents = (state) => {
  state.parents = [];
  state.orgVertices
    .forEach((poly, polyIndex) => {
      const isObstacle = state.verticesType[polyIndex] === 'obstacle';
      const parentSegs = getParentsPolygon(state.orgVertices, polyIndex, !isObstacle);
      state.parents.push(parentSegs);
    });
};

export const updateStateFromSegments = (updatedSegments, newState, oldState = null, ncx = 0, ncy = 0, nc = 1) => {
  newState.orgVertices = [];
  newState.vertices = [];
  newState.vertices3D = [];
  newState.verticesType = [];
  newState.eaves = [];
  newState.pitches = [];
  newState.azimuths = [];
  newState.eaveHeights = [];
  newState.UV = [];
  newState.changeClockwise = [];

  const { width, height } = newState?.rect ?? { width: 1, height: 1 };
  updatedSegments.forEach((seg, segIndex) => {
    const { pitch, azimuth } = seg;
    const segHeight = (seg.height / newState.footPerUnit) * newState.scale;
    const poly = [];
    const uv = [];
    const [geometry,, changeCW] = counterClockWise(seg.geometry, seg.lines);
    for (let i = 0; i < geometry.length; i += 1) {
      const v1 = geometry[i];
      const p1 = new THREE.Vector3((v1[0] - ncx / nc) * newState.scale, (v1[1] - ncy / nc) * newState.scale, 0);
      poly.push(p1);
      uv.push(v1[0] / width); uv.push((height - v1[1]) / height);
    }

    const [eave, orientation] = calculateEave(poly, azimuth);
    seg.panelOrientation = orientation;

    if (
      oldState
    && JSON.stringify(oldState.orgVertices[segIndex]) === JSON.stringify(poly)
    && JSON.stringify(oldState.eaves[segIndex]) === JSON.stringify(eave)
    && oldState.pitches[segIndex] === pitch
    && oldState.eaveHeights[segIndex] === segHeight
    && JSON.stringify(oldState.UV[segIndex]) === JSON.stringify(uv)
    ) {
      newState.orgVertices.push(oldState.orgVertices[segIndex]);
      newState.vertices.push(oldState.vertices[segIndex]);
      newState.vertices3D.push(oldState.vertices3D[segIndex]);
      newState.verticesType.push(oldState.verticesType[segIndex]);
      newState.eaves.push(oldState.eaves[segIndex]);
      newState.pitches.push(oldState.pitches[segIndex]);
      newState.azimuths.push(oldState.azimuths[segIndex]);
      newState.eaveHeights.push(oldState.eaveHeights[segIndex]);
      newState.UV.push(oldState.UV[segIndex]);
      newState.changeClockwise.push(oldState.changeClockwise[segIndex]);
      return;
    }

    newState.orgVertices.push(poly);
    newState.vertices.push(null);
    newState.vertices3D.push(null);
    newState.verticesType.push(seg.type === 'obstacle' ? 'obstacle' : 'roof');
    newState.eaves.push(eave);
    newState.pitches.push(pitch);
    newState.azimuths.push(azimuth);
    newState.eaveHeights.push(segHeight);
    newState.UV.push(uv);
    newState.changeClockwise.push(changeCW);
  });

  updateParents(newState);
};

export const updateZRanges = (state) => {
  let maxz = 0;
  state.segments.forEach((sgement, sgementIndex) => {
    if (sgement.geometry && sgement.geometry.length) {
      sgement.geometry.forEach((_, vi) => {
        if (state.vertices3D[sgementIndex][vi]) {
          const { z } = state.vertices3D[sgementIndex][vi];
          maxz = Math.max(maxz, z);
        }
      });
    }
  });
  state.ranges.push(maxz / state.scale);
};

export const magnetizeVertices = (forSegments) => {
  const pointClusters = [];

  forSegments.forEach((seg) => {
    seg.geometry.forEach((g) => {
      let addedToCluster = false;
      pointClusters.forEach((pc) => {
        if (addedToCluster) return;
        const sumPc = pc.reduce((avg, item) => [avg[0] + item[0], avg[1] + item[1]]);
        sumPc[0] /= pc.length;
        sumPc[1] /= pc.length;
        if (dist(sumPc, g) < 1.5) { pc.push(g); addedToCluster = true; }
      });
      if (!addedToCluster) pointClusters.push([g]);
    });
  });

  pointClusters.forEach((pc) => {
    const sumPc = pc.reduce((avg, item) => [avg[0] + item[0], avg[1] + item[1]]);
    sumPc[0] /= pc.length;
    sumPc[1] /= pc.length;
    pc.forEach((p) => {
      forSegments.forEach((seg) => {
        seg.geometry.forEach((g, gi) => {
          if (g[0] === p[0] && g[1] === p[1]) {
            seg.geometry[gi] = [...sumPc];
            // g = sumPc;
          }
        });
      });
    });
  });
};

export const getObstacleSegments = (state, obstacles) => {
  const obstacleSegments = obstacles.map((o) => {
    let g = [...o.geometry];
    const r = (o.radius ? o.radius : 8) / state.inchePerUnit;
    if (g.length === 1) {
      const [x, y] = g[0];
      g = [];
      for (let i = 0; i < 360; i += 45) {
        g.push([x + Math.cos((i * Math.PI) / 180) * r, y + Math.sin((i * Math.PI) / 180) * r]);
      }
    }

    return {
      geometry: g,
      pitch: 0,
      lines: new Array(g.length),
      height: ((o.height || o.height == 0 ? o.height : 12) / state.inchePerUnit) * state.scale,
      type: 'obstacle',
    };
  });

  return obstacleSegments;
};

export const preserveOnlyChanges = (newState, oldState) => {
  newState.orgVertices.forEach((poly, polyIndex) => {
    if (
      JSON.stringify(oldState.orgVertices[polyIndex]) === JSON.stringify(newState.orgVertices[polyIndex])
      && JSON.stringify(oldState.eaves[polyIndex]) === JSON.stringify(newState.eaves[polyIndex])
      && oldState.pitches[polyIndex] === newState.pitches[polyIndex]
      && oldState.azimuths[polyIndex] === newState.azimuths[polyIndex]
      && oldState.eaveHeights[polyIndex] === newState.eaveHeights[polyIndex]
      && JSON.stringify(oldState.UV[polyIndex]) === JSON.stringify(newState.UV[polyIndex])
    ) {
      newState.orgVertices[polyIndex] = oldState.orgVertices[polyIndex];
      newState.vertices[polyIndex] = oldState.vertices[polyIndex];
      newState.vertices3D[polyIndex] = oldState.vertices3D[polyIndex];
      newState.verticesType[polyIndex] = oldState.verticesType[polyIndex];
      newState.eaves[polyIndex] = oldState.eaves[polyIndex];
      newState.pitches[polyIndex] = oldState.pitches[polyIndex];
      newState.azimuths[polyIndex] = oldState.azimuths[polyIndex];
      newState.eaveHeights[polyIndex] = oldState.eaveHeights[polyIndex];
      newState.UV[polyIndex] = oldState.UV[polyIndex];
    }
  });
};

export const reconstruct3d = (segments, obstacles, height, width, scale, resolution, oldState = null) => {
  const newState = {
    orgVertices: [], vertices: [], vertices3D: [], verticesType: [], eaves: [], pitches: [], azimuths: [], eaveHeights: [], UV: [], changeClockwise: [], ranges: [], parents: [], center: oldState?.center, mode: oldState?.mode,
  };

  magnetizeVertices(segments);
  initState(newState, segments, scale, resolution, height, width);
  const obstacleSegments = getObstacleSegments(newState, obstacles);
  const [ncx, ncy, nc] = newState.center;
  updateStateFromSegments(segments.concat(obstacleSegments), newState, oldState, ncx, ncy, nc);
  projectTo3DFromOrgVertices(newState);
  if (oldState != null) preserveOnlyChanges(newState, oldState);
  updateZRanges(newState);

  return newState;
};

export const getBorders = (state, roofs, posAndNegPnts = true) => {
  const z = new THREE.Vector3(0, 0, 1);
  const results = [];
  const oneMeter = 100 / (state.resolution / state.scale);
  roofs.forEach((s, si) => {
    s.forEach((v1, vi1) => {
      const vi2 = (vi1 + 1) % s.length;
      const v2 = s[vi2];
      const e = v2.clone().sub(v1);
      const elen = e.length();
      e.normalize();
      const xOffset = elen / 5;
      const yOffset = Math.min(1, elen / 15);
      const d = e.clone().multiplyScalar(xOffset);
      const p1 = v1.clone().add(d);
      const p2 = v2.clone().sub(d);
      const n = e.clone().cross(z).normalize().multiplyScalar(yOffset);
      const p11 = p1.clone().add(n);
      const p12 = p1.clone().sub(n);
      const p21 = p2.clone().add(n);
      const p22 = p2.clone().sub(n);

      const outs = [[p11, p21], [p12, p22]].map(([pi1, pi2]) => roofs.every((roof) => {
        const in11 = pointInConcavePolygon(roof, pi1);
        const in12 = pointInConcavePolygon(roof, pi2);
        return !in11 && !in12;
      }));

      const ins = [[p11, p21], [p12, p22]].map(([pi1, pi2]) => roofs.some((roof) => {
        const in11 = pointInConcavePolygon(roof, pi1);
        const in12 = pointInConcavePolygon(roof, pi2);
        return in11 || in12;
      }));

      const noutrnin = (outs[0] && ins[1]);
      const ninrnout = (outs[1] && ins[0]);

      if (noutrnin || ninrnout) {
        const pntsPos = [];
        const pntsNeg = [];
        if (posAndNegPnts) {
          [1, -1].forEach((dir) => {
            for (let k = oneMeter / 4; k < elen / 2; k += oneMeter) {
              const ds = e.clone().multiplyScalar(k);
              const pnt = (dir === 1) ? v1.clone().add(ds) : v2.clone().sub(ds);
              [0.2, 1].forEach((dn) => {
                const pin = pnt.clone().add(n.clone().multiplyScalar(ninrnout ? dn : -dn));
                const pout = pnt.clone().add(n.clone().multiplyScalar(ninrnout ? -dn : dn));
                pntsPos.push(pin);
                pntsNeg.push(pout);
              });
            }
          });
        }
        results.push([si, vi1, vi2, pntsPos, pntsNeg]);
      }
    });
  });

  return results;
};

export const getInchePerUnit = (resolution) => parseFloat(resolution) / 2.54;
export const getFootPerUnit = (resolution) => parseFloat(resolution) / 30.48;
export const sceneUnitToFoot = (resolution, scale, unit) => ((Number(resolution) / 30.48) / scale) * unit;
export const sceneUnitToInche = (resolution, scale, unit) => ((Number(resolution) / 2.54) / scale) * unit;
export const footToSceneUnit = (resolution, scale, foot) => (foot * 30.48 * scale) / Number(resolution);

export const getSetbackList = (segments, changeClockwise, setback, inchePerUnit, scale, poly, index) => {
  const setbacks = segments[index]?.setbacks?.map((sb) => ((sb / inchePerUnit) * scale)) ?? poly.map(() => (setback / inchePerUnit) * scale);
  if (changeClockwise[index]) {
    setbacks.reverse();
  }
  return setbacks;
};
